import { Autocomplete, Box, Button, Collapse, Drawer, IconButton, List, ListItemButton, ListItemText, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { Colors } from '../../../../Styles/Colors'
import { CommonPatientDetails, ToolsFieldWithMic } from '../../../../Components/Common Components/CommonComponents'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { getCachevalue, localGetItem } from '../../../../Utility/Services/CacheProviderService'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { ImagePaths } from '../../../../Utility/ImagePaths'
import CollabHistory from './CollabHistory'
import CollabDocNotes from './CollabDocNotes'
import CollabCharts from './CollabCharts'
import CollabSurgeryNotes from './CollabSurgeryNotes'
import CollabDischargeSum from './CollabDischargeSum'
import TriggerApiOnView from './TriggerApiOnView'
import { Nutrition, Intensity_Level, Made_Of_Delivery, Gestational_Age } from '../../../../Utility/Constants';
import { formatDate } from '../../../../Components/CommonFunctions/CommonFunctions'


const Headers = ['Doctor Notes', 'History', 'Laboratory', 'Radiology', 'Charts', 'Surgery Notes', 'Discharge Summary']
const datalist = {
    labTestData: [],
    radiologyData: [],
}
const createMapFromArray = (array, keySelector, valueSelector) => {
    return new Map(array.map(item => [keySelector(item), valueSelector(item)]));
};
const nutritionMap = createMapFromArray(Nutrition, (item => +item.value), (item => item.label));
const intensityMap = createMapFromArray(Intensity_Level, (item => +item.value), (item => item.label));
const deliveryModeMap = createMapFromArray(Made_Of_Delivery, (item => item.value), (item => item.label));
const gestationAgeMap = createMapFromArray(Gestational_Age, (item => item.value), (item => item.label));
const patient_Type = [
    { patient_type: 'Out Patient', value: 'OP' },
    { patient_type: 'IP Patient', value: 'IP' },
]

const history = {
    bloodGrp: '',
    medicalHis: [],
    HospitalHis: [],
    socialHis: [],
    familyHis: [],
    diabeticHis: {
        diabetic_his: {},
        nutrition_his: {},
        physical_his: {},
        blood_glu_his: {},
        menstuaral_his: {}
    },
    obgynHis: {
        obgyn_menstrual_his: {},
        obstetrical_his: [],
        birth_control_his: {},
        gynaec_his: {},
        papsmear_his: {},
        obgyn_surgery: []
    },
    pdHis: {
        birth_his: {},
        medical_his: []
    }
}

export default function DocCollaborativeSummary() {
    const divRefs = useRef([])
    const [patientDetails, setpatientDetails] = useState({})
    const [visitDate, setvisitDate] = useState([])
    const [patient_id, setpatient_id] = useState(null)
    const [appoinmentID, setappoinmentID] = useState(null)
    const [errorMsg, seterrorMsg] = useState(false);
    const [errorMsgText, seterrorMsgText] = useState('');
    const [successMsg, setsuccessMsg] = useState(false);
    const [successMsgText, setsuccessMsgText] = useState('');
    const [clinicID, setclinicID] = useState(null);
    const [doctorNameData, setdoctorNameData] = useState([]);
    const [SelectedDoctor, setSelectedDoctor] = useState(null);
    const [selectSpeci, setselectSpeci] = useState(null);
    const [patientType, setpatientType] = useState(null);
    const [fromDate, setfromDate] = useState(new Date());
    const [toDate, settoDate] = useState(new Date());
    const [DatePickerOpen, setDatePickerOpen] = useState(false);
    const [expendDate, setexpendDate] = useState('');
    const [expandedID, setexpandedID] = useState(null);
    const [filterOpen, setfilterOpen] = useState(null);
    const [selectHead, setselectHead] = useState(null);
    // state to check the scroll is from scrollintoview;
    const [isProgrammaticScroll, setIsProgrammaticScroll] = useState(false);
    const [summaryDetails, setSummaryDetails] = useState({});
    const [visitType, setvisitType] = useState('');
    const [TestData, setTestData] = useState(datalist);
    const [HeadList, setHeadList] = useState([]);
    const [specList, setspecList] = useState([]);
    const [selectedIndex, setselectedIndex] = useState([]);
    const userData = useRef({});
    const [HisData, setHisData] = useState(history);
    useEffect(() => {
        try {
            const cachedData = getCachevalue('DoctorPatientData');
            const clinicId = getCachevalue('SelectedDoctorClinic')
            const appointData = JSON.parse(cachedData);
            const query = `?patient_id=${appointData.patient_id}`;
            userData.current = JSON.parse(localGetItem("loggedInUserInfo") || "{}");
            let modifiedHeaders = [...Headers];
            if (appointData?.patient_id) {
                setpatient_id(appointData.patient_id);
                setappoinmentID(appointData.appointment_id);
                fetchData(
                    "DOC_MEDICAL_HISTORY", query,
                    (data) => {
                        setHisData((prevData) => ({
                            ...prevData,
                            medicalHis: data.patient_medical_history || [],
                            bloodGrp: data.blood_group || ''
                        }));
                    }
                );
                fetchData(
                    "DOC_HOSPITALIZATION_HISTORY", query,
                    (data) => {
                        setHisData((prevData) => ({
                            ...prevData,
                            HospitalHis: data || []
                        }))
                    }
                );
                fetchData(
                    "DOC_FAMILY_HISTORY", query,
                    (data) => {
                        setHisData((prevData) => ({
                            ...prevData,
                            familyHis: data || []
                        }))
                    }
                );
                fetchData(
                    "DOC_SOCIAL_HISTORY", query,
                    (data) => {
                        setHisData((prevData) => ({
                            ...prevData,
                            socialHis: data || []
                        }))
                    }
                );
                if (userData.service_type === "DB") {
                    fetchData(
                        "DOC_DIABETIC_HISTORY", query,
                        (data) => {
                            setHisData((prevData) => ({
                                ...prevData,
                                diabeticHis: {
                                    ...prevData.diabeticHis,
                                    diabetic_his: Object.keys(data)?.length > 0 ? data : {}
                                }
                            }))
                        }
                    );
                    fetchData(
                        "DOC_NUTRITION_HISTORY",
                        query,
                        (data) => {
                            setHisData((prevData) => ({
                                ...prevData,
                                diabeticHis: {
                                    ...prevData.diabeticHis,
                                    nutrition_his: Object.keys(data)?.length > 0 ? {
                                        ...data,
                                        nutrition_type: nutritionMap?.get(data?.nutrition_type) || ""
                                    } : {}
                                }
                            }))
                        }
                    );
                    fetchData(
                        "DOC_PHYSICAL_HISTORY_GET",
                        query,
                        (data) => {
                            setHisData((prevData) => ({
                                ...prevData,
                                diabeticHis: {
                                    ...prevData.diabeticHis,
                                    physical_his: Object.keys(data)?.length > 0 ? {
                                        ...data,
                                        exercise_intensity: intensityMap?.get(data?.exercise_intensity) || ""
                                    } : {}
                                }
                            }))
                        }
                    );
                    fetchData(
                        "DOC_BLOOD_GLUCOSE_HISTORY_GET",
                        query,
                        (data) => {
                            setHisData((prevData) => ({
                                ...prevData,
                                diabeticHis: {
                                    ...prevData.diabeticHis,
                                    blood_glu_his: Object.keys(data)?.length > 0 ? data : {}
                                }
                            }))
                        }
                    );
                    fetchData(
                        "DOC_BLOOD_MENSURAL_HISTORY_GET",
                        query,
                        (data) => {
                            setHisData((prevData) => ({
                                ...prevData,
                                diabeticHis: {
                                    ...prevData.diabeticHis,
                                    menstuaral_his: Object.keys(data)?.length > 0 ? data : {}
                                }
                            }))
                        }
                    );
                }
                if (userData.service_type === "OBGYN") {
                    fetchData(
                        "MENSTRUAL_HISTORY",
                        query,
                        (data) => {
                            setHisData((prevData) => ({
                                ...prevData,
                                obgynHis: {
                                    ...prevData.obgynHis,
                                    obgyn_menstrual_his: Object.keys(data?.menstrual_history)?.length > 0 ? data?.menstrual_history : {}
                                }
                            }))
                        }
                    );
                    fetchData(
                        "OBSTETRICAL_HISTORY_GET",
                        query,
                        (data) => {
                            setHisData((prevData) => ({
                                ...prevData,
                                obgynHis: {
                                    ...prevData.obgynHis,
                                    obstetrical_his: data?.obstetrical_history?.length > 0 ? data?.obstetrical_history : []
                                }
                            }))
                        }
                    );
                    fetchData(
                        "BIRTH_CONTROL_GET",
                        query,
                        (data) => {
                            setHisData((prevData) => ({
                                ...prevData,
                                obgynHis: {
                                    ...prevData.obgynHis,
                                    birth_control_his: Object.keys(data)?.length > 0 ? data : {}
                                }
                            }))
                        }
                    );
                    fetchData(
                        "GYNAECOLOGICAL_HISTORY",
                        query,
                        (data) => {
                            setHisData((prevData) => ({
                                ...prevData,
                                obgynHis: {
                                    ...prevData.obgynHis,
                                    gynaec_his: Object.keys(data?.gynaecology_history)?.length > 0 ? data?.gynaecology_history : {}
                                }
                            }))
                        }
                    );
                    fetchData(
                        "PAP_HISTORY",
                        query,
                        (data) => {
                            setHisData((prevData) => ({
                                ...prevData,
                                obgynHis: {
                                    ...prevData.obgynHis,
                                    papsmear_his: Object.keys(data?.pap_mammogram_history)?.length > 0 ? data?.pap_mammogram_history : {}
                                }
                            }))
                        }
                    );
                    fetchData(
                        "OBGYN_SURGERY_GET_POST",
                        query,
                        (data) => {
                            setHisData((prevData) => ({
                                ...prevData,
                                obgynHis: {
                                    ...prevData.obgynHis,
                                    obgyn_surgery: [...data?.obstetrical_surgery_history, ...data?.obstetrical_additional_surgery_history]
                                }
                            }))
                        }
                    );
                }
                if (userData.service_type === "PD") {
                    fetchData(
                        "PD_HISTORY_GET",
                        query,
                        (data) => {
                            setHisData((prevData) => ({
                                ...prevData,
                                pdHis: {
                                    ...prevData.pdHis,
                                    birth_his: Object.keys(data)?.length > 0 ? {
                                        ...data,
                                        mode_of_delivery: deliveryModeMap.get(data?.mode_of_delivery),
                                        gestational_age: gestationAgeMap.get(data?.gestational_age),
                                    } : {}
                                }
                            }))
                        }
                    );
                    fetchData(
                        "PD_MEDICAL_HIS",
                        query,
                        (data) => {
                            setHisData((prevData) => ({
                                ...prevData,
                                pdHis: {
                                    ...prevData.pdHis,
                                    medical_his: data?.length > 0 ? data : []
                                }
                            }))
                        }
                    );
                }
            }
            if (clinicId) {
                setclinicID(clinicId)
            }
            if (patient_id) {
                getPatientDetailData();
                getVisitDateData();
            }
            if (userData.current.service_type === 'GP') {
                modifiedHeaders = Headers.filter(header => header !== 'Charts');
                setHeadList(modifiedHeaders)
            } else {
                setHeadList(Headers)
            }
            if (clinicID) {
                getDoctorListData();
                getSpecializationData();
            }
            if(patient_id && clinicID){
                fetchData(
                    "DOC_PRINT_ORDER_REPORT",
                    `?patient_id=${patient_id}&clinic_id=${clinicID}&test=Radiologytest`,
                    (data) => {
                        setTestData((prevData) => ({
                            ...prevData,
                            radiologyData: data || []
                        }))
                    }
                );
                fetchData(
                    "DOC_DOCTOR_TEST_REPORT",
                    `?patient_id=${patient_id}&clinic_id=${clinicID}`,
                    (data) => {
                        setTestData((prevData) => ({
                            ...prevData,
                            labTestData: data || []
                        }))
                    }
                );
            }
        } catch (error) {
            errorMessage(error.message)
        }
    }, [patient_id, clinicID]);

    const getSpecializationData = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPSPECIALIZATION + `?clinic_id=${clinicID}`)
                .then((response => {
                    if (response.data.status === 'success') {
                        setspecList(response.data.data)
                    }
                })).catch(e => {
                    if (e?.response?.data?.message) {
                        errorMessage(e.response.data.message)
                    } else {
                        errorMessage(e.message)
                    }

                })
        } catch (e) {
            errorMessage(e.message)
        }
    }

    const getVisitDateData = () => {
        try {
            RestAPIService.getAll(Serviceurls.COLLAB_VISIT_DATE_GET + `?patient_id=${patient_id}&appointment_id=${appoinmentID}&visit_type=${patientType?.value || ''}&specialization_id=${selectSpeci?.id || ''}&doc_id=${SelectedDoctor?.doctor_id || ''}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        setvisitDate(response.data.data || [])
                        setfilterOpen(false)
                    }
                }).catch((error) => {
                    setfilterOpen(false)
                    if (error?.response?.data?.message) {
                        errorMessage(error.response.data.message)
                    } else {
                        errorMessage(error.message)
                    }
                })
        } catch (error) {
            setfilterOpen(false)
            errorMessage(error.message)
        }
    }
    const getDoctorListData = () => {
        try {
            RestAPIService.getAll(Serviceurls.HOME_GET_CLINIC_DOCTORS + `?clinic_id=${clinicID}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        setdoctorNameData(response.data.data || [])
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        errorMessage(error.response.data.message)
                    } else {
                        errorMessage(error.message)
                    }
                })
        } catch (error) {
            errorMessage(error.message)
        }
    }

    const getPatientDetailData = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_PATIENT_DETAIL_GET + `?patient_id=${patient_id}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        setpatientDetails(response.data.data || {})
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        errorMessage(error.response.data.message)
                    } else {
                        errorMessage(error.message)
                    }
                })
        } catch (error) {
            errorMessage(error.message)
        }
    }

    const msgClose = () => {
        seterrorMsg(false);
        seterrorMsgText('');
        setsuccessMsg(false);
        setsuccessMsgText('');
    }
    const errorMessage = (msg) => {
        seterrorMsg(true)
        seterrorMsgText(msg)
    }

    const successMessage = (msg) => {
        setsuccessMsg(true)
        setsuccessMsgText(msg)
    }

    const textautoComplete = (label, options, val, name) => {
        return (
            <Box display={'flex'} justifyContent={'center'}>
                {name === 'fromDate' || name === 'toDate' ?
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            open={DatePickerOpen}
                            onOpen={() => { setDatePickerOpen(true) }}
                            onClose={() => { setDatePickerOpen(false) }}
                            value={val}
                            name={name}
                            inputFormat='DD-MM-YYYY'
                            views={["year", "month", "day"]}
                            onChange={(newDate) => { }}
                            renderInput={(params) => <TextField
                                onKeyDown={(e) => e.preventDefault()}
                                sx={{ marginRight: '1vw', width: '25vw', marginTop: '1vw' }}
                                size='small'
                                {...params}
                                onClick={() => { setDatePickerOpen(true) }}
                                label={label} />}
                        />
                    </LocalizationProvider> :
                    <Autocomplete
                        size='small'
                        sx={{ width: '25vw', marginRight: '1vw', marginTop: '1vw' }}
                        name={name}
                        options={options}
                        getOptionLabel={(option) => name === 'patientType' ? option?.patient_type : name === 'selectSpeci' ? option.name : option?.first_name}
                        value={val}
                        autoComplete='off'
                        onChange={(event, newValue) => { onChangeHandle(event, newValue, name) }}
                        renderInput={(params) => <TextField {...params} label={label} />}
                    />
                }
            </Box>
        )
    }
    const onChangeHandle = (e, val, name) => {
        switch (name) {
            case "selectSpeci":
                setselectSpeci(val)
                break;
            case "SelectedDoctor":
                setSelectedDoctor(val)
                break;
            case "patientType":
                setpatientType(val)
                break;
            default:
                break;
        }
    }

    const filterBox = () => {
        return (
            <Box className='eMed_Canteen_FilterBox'>
                <Box className='eMed_CanteenFilter_Head'>
                    <Typography variant='h6' fontWeight={600}>Filter</Typography>
                    <Tooltip>
                        <Button onClick={() => { setfilterOpen(false) }}>
                            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_Canteen_CloseImg' />
                        </Button>
                    </Tooltip>
                </Box>
                <Box className='eMed_Canteen_fil_content'>
                    {textautoComplete('Select Specialization *', specList, selectSpeci, 'selectSpeci')}
                    {textautoComplete('Select Doctor *', doctorNameData, SelectedDoctor, 'SelectedDoctor')}
                    {textautoComplete('Select Patient Type *', patient_Type, patientType, 'patientType')}
                    {/* {textautoComplete('From Date', [], fromDate, 'fromDate')}
                    {textautoComplete('To Date', [], toDate, 'toDate')} */}
                </Box>
                <Box>
                    <Stack spacing={2} direction="row" justifyContent={'center'}>
                        <Button size='small' variant='outlined' className='eMed_Canteen_BtnText' onClick={() => {setselectSpeci(null); setSelectedDoctor(null); setpatientType(null) }}>Clear</Button>
                        <Button size='small' variant='contained' className='eMed_Canteen_BtnText' onClick={() => {getVisitDateData() }}>Search</Button>
                    </Stack>
                </Box>
            </Box>
        )
    }

    const handleScrollIntoView = (index, data) => {
        setselectHead(data)
        setselectedIndex(index)
        if (divRefs.current[index]) {
            setIsProgrammaticScroll(true);

            divRefs.current[index].scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'center',
            });

            const handleScrollEnd = () => {
                if (isElementInView(divRefs.current[index])) {
                    setIsProgrammaticScroll(false);
                    divRefs.current[index].removeEventListener('scroll', handleScrollEnd);
                }
            };

            divRefs.current[index].addEventListener('scroll', handleScrollEnd);
        }
    };

    const isElementInView = (element) => {
        const rect = element.getBoundingClientRect();
        return rect.top >= 0 && rect.bottom <= window.innerHeight;
    };

    const collabSummaryView = () => {
        let Head = visitType === 'OP' ? HeadList.slice(0, -2) : HeadList
        return (
            <Box>
                <Box sx={{ height: '68.5vh', display: 'flex', flexDirection: 'row', }}>
                    <Box sx={{ width: '28vw', borderRight: '1px solid #E3E2E2', overflowY: 'scroll' }}>
                        {visitDate.length > 0 ? visitDate?.map((item) => {
                            let list = item.visit_type === 'OP' ? HeadList.slice(0, -2) : HeadList
                            return (
                                <List key={item.id}>
                                    <ListItemButton
                                        sx={{ height: '3vw', borderBottom: '1px solid #E3E2E2' }}
                                        onClick={() => {
                                            setexpandedID(expandedID === item.id ? null : item.id);
                                            setexpendDate(item.visit_type === 'OP' ? `${item.visit_date} - OutPatient` : `${item.visit_date} - InPatient`)
                                            setvisitType(item.visit_type)
                                            setSummaryDetails(item)
                                            console.log(expandedID)
                                            console.log(item)
                                        }}
                                        selected={expandedID === item.id}
                                    >
                                        <ListItemText
                                            primary={item.visit_type === 'OP' ? `OP Visit Date: ${item.visit_date}` : `IP Admission Date: ${item.visit_date}`}
                                            secondary={item.visit_type === 'OP' ? `OP No: ${item.visit_number}` : `IP No: ${item.visit_number}`}
                                        />
                                        {expandedID === item.id ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                    {list?.map((data, index) => (
                                        <Collapse in={expandedID === item.id} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                <ListItemButton sx={{ pl: 4, height: '2vw' }}
                                                    selected={selectedIndex === index}
                                                    onClick={() => { handleScrollIntoView(index, data) }}
                                                >
                                                    <ListItemText primary={data} />
                                                </ListItemButton>
                                            </List>
                                        </Collapse>
                                    ))}
                                </List>)
                        }): 
                        <Box display={'flex'} height={'68vh'} justifyContent={'center'} alignItems={'center'}>{'No Records to Be Shown'}</Box>}
                    </Box>
                    {expendDate ?
                        <Box sx={{ width: '72vw' }}>
                            <Box sx={{ borderBottom: '1px solid #E3E2E2' }}>
                                <Typography fontWeight={'bold'} margin={'.5vw'} fontSize={'.8vw'}>{`Date: ${expendDate}`}</Typography>
                            </Box>
                            <Box component={'div'} sx={{ height: '63.8vh', overflowY: 'scroll' }}>
                                {Head.map((item, i) => {
                                    const isScroll = isProgrammaticScroll && selectHead === item;
                                    const commonProps = {
                                        id: expandedID, isProgrammaticScroll: isScroll,
                                        details: summaryDetails, userData: userData.current,
                                        historyData: HisData
                                    }
                                    return (
                                        <Box ref={(el) => (divRefs.current[i] = el)} key={`${i}_${expandedID}`}>
                                            {item === 'History' ? <CollabHistory {...commonProps} /> :
                                                item === 'Doctor Notes' ? <CollabDocNotes {...commonProps} /> :
                                                    item === 'Laboratory' ? labTestContainer(commonProps) :
                                                        item === 'Radiology' ? RadTestContainer(commonProps) :
                                                            item === 'Charts' ? <CollabCharts {...commonProps} /> :
                                                                item === 'Surgery Notes' ? <CollabSurgeryNotes {...commonProps} /> :
                                                                    <CollabDischargeSum {...commonProps} />}
                                        </Box>
                                    )
                                })}
                            </Box>
                        </Box> :
                        <Box sx={{ width: '72vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {'No Records To Be Shown'}
                        </Box>}
                </Box>
            </Box>
        )
    }
    const fetchData = (url, query, handleResponse) => {
        RestAPIService.getAll(`${Serviceurls[url]}${query}`)
            .then((response) => {
                if (response.data.status === 'success') {
                    handleResponse(response.data.data);
                }
            }).catch((error) => {
                if (error?.response?.data?.message) {
                    errorMessage(error.response.data.message)
                } else {
                    errorMessage(error.message)
                }
            })
    }
    
    const getTestReportPrint = (resultSummaryId, labId) => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_PATIENT_RESULT_PRINT + `?result_summary_id=${resultSummaryId}&laboratory_id=${labId}`)
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        errorMessage(error.response.data.message)
                    } else {
                        errorMessage(error.message)
                    }
                })
        } catch (error) {
            errorMessage(error.message)
        }
    }
    const labTestContainer = () => {
        let tests = TestData?.labTestData.filter((item) => item.status === "Result Completed")
        return (
            <Box marginLeft={'.5vw'}>
                <Typography fontSize={'1vw'} fontWeight={'bold'}>Laboratry Test</Typography>
                    <Box id={`labData_${expandedID}`} marginLeft={'1vw'}>
                        <Paper>
                            <TableContainer>
                                <Table size='small' aria-label="stictbl">
                                    <TableHead sx={{ backgroundColor: '#FAF9F9' }}>
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: 'bold', width: '20vw' }}>Reported Date</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', width: '20vw' }}>Report Type</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', width: '20vw' }}>Test Details</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', width: '15vw' }}>Result</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tests.length > 0 ? tests.map((item) => (
                                            <TableRow>
                                                <TableCell sx={{ width: '20vw' }}>{item.report_date ? formatDate(item.report_date) : '-'}</TableCell>
                                                <TableCell sx={{ width: '20vw' }}>{item.report_type || '-'}</TableCell>
                                                <TableCell sx={{ width: '20vw' }}>{
                                                    item.test_name.length > 20 ?
                                                        <Tooltip title={item.test_name} placement='top'><Typography>{item.test_name.slice(0, 20) + '...' || '-'}</Typography></Tooltip> : item.test_name || '-'
                                                }</TableCell>
                                                <TableCell sx={{ width: '15vw' }}>
                                                    <IconButton size='small' onClick={() => { getTestReportPrint(item.id, item.laboratory_id) }}>
                                                        <img src={ImagePaths.PrintIcons.default} style={{ width: '1.2vw', height: '1.2vw' }} alt='print' />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )) :
                                            <TableRow>
                                                <TableCell colSpan={3}>{`No Records to Be Shown`}</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Box>
            </Box>
        )
    }
    const RadTestContainer = () => {
        return (
            <Box marginTop={'1vw'}>
                <Typography fontSize={'1vw'} fontWeight={'bold'}>Radiology Test</Typography>
                    <Box id={`radiology_${expandedID}`}>
                        {TestData.radiologyData.map((item) => (
                            <ToolsFieldWithMic
                                FromTestReport={true}
                                isFrom={"CollabSummary"}
                                EditorDisable={true}
                                Title={"Result"}
                                EditorRef={item.result_data || ''}
                                overallHeight={"auto"}
                                EditorHeight={"auto"}
                                micTextEditDisabled={true}
                                errorMsg={errorMessage.bind(this)}
                            />
                        ))}
                    </Box>
            </Box>
        )
    }

    return (
        <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'79.5vh'} p={'0.5vw'}>
            <Box component={'div'} sx={{ height: '9vh', display: 'flex', justifyContent: 'space-between' }}>
                <Box component={'div'} sx={{
                    backgroundColor: 'white', borderRadius: '5px',
                    boxShadow: '0px 0px 3px gray', width: '23vw', height: '3.5vw', padding: '0.2vw',
                }}>
                    <CommonPatientDetails data={patientDetails} showDetailed={true} fromDoctor={true} />
                </Box>
                <Box component={'div'} sx={{ display: 'flex' }}>
                    <Tooltip title="Filter" placement='top' arrow>
                        <Button onClick={() => { setfilterOpen(true) }}>
                            <img src={ImagePaths.Filter.default} alt="filter" className='eMed_Canteen_img' />
                        </Button>
                    </Tooltip>
                </Box>
            </Box>
            <Box component={'div'} sx={{ height: '68.5vh', backgroundColor: 'white', marginTop: '.3vw', border: '1px solid #E3E2E2', borderRadius: '1px' }}>
                {collabSummaryView()}
            </Box>
            {
                errorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={errorMsgText}
                        msgPop={msgClose.bind(this)}
                    />
                    : null
            }
            {
                successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={successMsgText}
                        msgPop={msgClose.bind(this)}
                    />
                    : null
            }
            <Drawer
                anchor={'right'}
                open={filterOpen}
                onClose={() => setfilterOpen(false)}
            >
                {filterBox()}
            </Drawer>
        </Box >
    )
}