import { Autocomplete, Box, Button, Drawer, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import '../reports.css';
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards'
import { ImagePaths } from '../../../../Utility/ImagePaths';
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { CommonGridToolBarWithFilterText, CommonGridToolBarWithFilterTextCustom, CommonPatientDetails, CommonTimeRangePicker } from "../../../../Components/Common Components/CommonComponents";
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { AmountFormat, formatDate, timeOnlyConvert } from "../../../../Components/CommonFunctions/CommonFunctions";
import { DateTime } from 'luxon';
import { CurrencySymbol, gstPercentage } from '../../../../Utility/Constants';


class GSTReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            doctorName: [],
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: 1,
            gstReportData: [],
            serviceTotalAmount: 0,
            gstTotalAmount: 0,
            totalAmount: 0,
            FilteredFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilteredToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilterDatas: {
                "startTime": null,
                "endTime": null,
                "onError": false,
                "searchUhid": '',
                "patientName": '',
                "serviceType": [],
                "serviceName": [],
                "gstPercent": []
            },
            timeError: false,
            isFromIP: this.props?.isFromIP,
            FilterOpen: false,
            isLoader: false,
            serviceNameAndType: [],
            GSTList : gstPercentage || []
        }
    }

    componentDidMount() {
        // if (this.props?.isFromIP) {
        //     this.setState({ isFromIP: true })
        // }
        this.getServiceNameAndTypeFilterList()
        this.getGstReportData()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        }, () => {
            this.state.FilterDatas.endTime = null
            this.setState({
                FilterDatas: this.state.FilterDatas
            })
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    getGstReportData = () => {
        try {
            this.LoaderFunction(true)
            var { FilterDatas } = this.state
            let serviceName = []; 
            let serviceType = [];
            let GSTPercent = [];
            this.state.FilterDatas?.serviceName?.forEach(element => serviceName.push(element))
            this.state.FilterDatas?.serviceType?.forEach(element => serviceType.push(element))
            this.state.FilterDatas?.gstPercent?.forEach(element => GSTPercent.push(element?.value))
            let start_time = FilterDatas.startTime ? new Date(FilterDatas.startTime) : ''
            let end_time = FilterDatas.endTime ? new Date(FilterDatas.endTime) : ''
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time)?.toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time)?.toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let url = ''
            if (this.state.isFromIP) {
                url = Serviceurls.FO_GST_REPORT + "?module_type=IP"
            } else {
                url = Serviceurls.FO_GST_REPORT + "?module_type=OP"
            }
            RestAPIService.getAll(url + `&patient_name=${FilterDatas.patientName}&uhid=${FilterDatas.searchUhid}&service_type=${serviceType}&service_name=${serviceName}&from_date=${this.state.fromDate}&to_date=${this.state.toDate}&start_time=${setStartTime}&end_time=${setEndTime}&gst_percentage=${GSTPercent}`).
                then((response) => {
                    if (response.data?.status === "success") {
                        this.setState({
                            gstReportData: response.data.data ? response.data.data : [],
                            serviceTotalAmount: response.data?.service_amount ? response.data.service_amount : 0,
                            gstTotalAmount: response.data?.gst_amount ? response.data.gst_amount : 0,
                            totalAmount: response.data?.total_amount ? response.data.total_amount : 0,
                            FilteredFromDate: this.state.fromDate,
                            FilteredToDate: this.state.toDate,
                        }, () => { this.LoaderFunction(false) })
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }
    printReports = () => {
        try {
            var { FilterDatas } = this.state
            let serviceName = []; 
            let serviceType = [];
            let GSTPercent = [];
            this.state.FilterDatas?.serviceName?.forEach(element => serviceName.push(element))
            this.state.FilterDatas?.serviceType?.forEach(element => serviceType.push(element))
            this.state.FilterDatas?.gstPercent?.forEach(element => GSTPercent.push(element?.value))
            let start_time = FilterDatas.startTime ? new Date(FilterDatas.startTime) : ''
            let end_time = FilterDatas.endTime ? new Date(FilterDatas.endTime) : ''
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let url = ''
            if (this.state.isFromIP) {
                url = Serviceurls.FO_GST_REPORT + "?module_type=IP"
            } else {
                url = Serviceurls.FO_GST_REPORT + "?module_type=OP"
            }
            RestAPIService.getAll(url + `&patient_name=${FilterDatas.patientName}&uhid=${FilterDatas.searchUhid}&service_type=${serviceType}&service_name=${serviceName}&from_date=${this.state.fromDate}&to_date=${this.state.toDate}&start_time=${setStartTime}&end_time=${setEndTime}&gst_percentage=${GSTPercent}&export_type=pdf`)
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getServiceNameAndTypeFilterList = () => {
        try {
            RestAPIService.getAll(this.props?.isFromIP ? Serviceurls.FO_CONFIG_IPSRCNAME : Serviceurls.FO_CONFIG_OPSRCNAME)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            serviceNameAndType: response.data.data,
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }


    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }

    selectedTime = (start, end, error) => {
        this.state.FilterDatas.startTime = start
        this.state.FilterDatas.endTime = end
        this.setState({ FilterDatas: this.state.FilterDatas, timeError: error })
    }

    renderTextField = (label, value, key) => {
        let states = this.state
        return (
            <Box className='eMed_rts_dropdown'>
                <TextField
                    size='small'
                    sx={{ width: "20vw" }}
                    label={label}
                    name={label}
                    value={value}
                    onChange={(e) => {
                        this.state.FilterDatas[key] = e.target.value
                        this.setState({
                            states
                        })
                    }}
                    inputProps={{ emed_tid: `gstReport_filter_${key}`}}
                />
            </Box>
        )
    }

    renderFilterScreen = () => {
        const { t } = this.props
        let serviceTypeList = []; let NewServiceType = [];
        this.state.serviceNameAndType?.map((item) => item?.data?.map((data) => serviceTypeList?.push(data.service_name)))
        this.state.serviceNameAndType?.map((item) => NewServiceType?.push(item.service_type))
        let uniqueArray = []
        let serviceTypeUnique = []
        uniqueArray = [...new Set(serviceTypeList)]
        serviceTypeUnique = [...new Set(NewServiceType)]
        let states = this.state
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
                    <Button onClick={() => { this.setState({ FilterOpen: false }) }}  emed_tid='gstReport_filter_close_btn' className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)}
                            HideAllMenu={true} />
                    </Box>
                    <Box sx={{ width: '22vw' }}>
                        <CommonTimeRangePicker
                            startTime={this.state.FilterDatas?.startTime}
                            endTime={this.state.FilterDatas?.endTime}
                            selectedTime={this.selectedTime.bind(this)}
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            onError={this.state.FilterDatas.onError}
                        />
                    </Box>
                    {this.renderTextField("UHID", this.state.FilterDatas?.searchUhid, "searchUhid")}
                    {this.renderTextField("Patient Name", this.state.FilterDatas?.patientName, "patientName")}
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            options={uniqueArray ? uniqueArray : []}
                            getOptionLabel={(option) => (option)}
                            value={this.state.FilterDatas?.serviceName}
                            onChange={(event, newValue) => {
                                this.state.FilterDatas.serviceName = newValue
                                this.setState({ states })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("selectService")}
                                    inputProps={{...params.inputProps, 'emed_tid': 'gstReport_filter_selectServiceName' }}
                                />
                            )}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            options={serviceTypeUnique ? serviceTypeUnique : []}
                            getOptionLabel={(option) => (option)}
                            value={this.state.FilterDatas?.serviceType}
                            onChange={(event, newValue) => {
                                this.state.FilterDatas.serviceType = newValue
                                this.setState({ states })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("Select Service Type")}
                                    inputProps={{...params.inputProps, 'emed_tid': 'gstReport_filter_selectServiceType' }}
                                />
                            )}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            size='small'
                            multiple
                            limitTags={1}
                            options={this.state.GSTList ? this.state.GSTList : []}
                            getOptionLabel={(option) => (typeof (option) === "string" ?  option : option?.label)}
                            value={this.state.FilterDatas?.gstPercent}
                            onChange={(event, newValue) => {
                                this.state.FilterDatas.gstPercent = newValue
                                this.setState({ FilterDatas: this.state.FilterDatas })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={"Select GST (%)"}
                                    InputProps={{
                                        ...params.InputProps,
                                        emed_tid: "gstReport_filter_selectServiceType"
                                    }}
                                />
                            )}
                        />
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small" emed_tid='gstReport_filter_clearBtn'
                        onClick={() => {
                            this.setState({
                                doctorName: [],
                                serviceName: [],
                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                dateIndex: 1,
                                FilterDatas: {
                                    "startTime": null,
                                    "endTime": null,
                                    "onError": false,
                                    "searchUhid": '',
                                    "patientName": '',
                                    "serviceType": [],
                                    "serviceName": [],
                                    "gstPercent": []
                                },
                                timeError: false,
                            }, () => this.getGstReportData())
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small" emed_tid='gstReport_filter_searchBtn'
                        disabled={this.state.FilterDatas.startTime ? (this.state.FilterDatas.startTime && this.state.FilterDatas.endTime && this.state.timeError != true) ? false : true : false}
                        onClick={() => {
                            this.getGstReportData()
                            this.setState({ FilterOpen: false })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box >
        )
    }
    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }
    gridToolBar = () => {
        var { t } = this.props
        let start_time = this.state.FilterDatas.startTime ? new Date(this.state.FilterDatas.startTime) : ''
        let end_time = this.state.FilterDatas.endTime ? new Date(this.state.FilterDatas.endTime) : ''
        let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.FilteredFromDate) },
            { label: "To Date", value: formatDate(this.state.FilteredToDate) },
        ]
        if (this.state.FilterDatas.startTime !== null && this.state.FilterDatas.endTime !== null) {
            TextArray.push(
                { label: "Start Time", value: timeOnlyConvert(setStartTime) },
                { label: "End Time", value: timeOnlyConvert(setEndTime) }
            )
        }
        var ExportData = [];
            this.state.gstReportData?.length > 0 && this.state.gstReportData.map((item) => {
                const data = {
                    "Patient Name": item?.patient_name,
                    "UHID": item?.uhid,
                    "Bill Details": item.invoice_number,
                    "Service Type": item.service_type,
                    "Service Name": item.service_name,
                    "Service Amount": item.service_amount || 0,
                    "GST (%)": item.gst_percentage,
                    "GST Amount":  item.gst_amount || 0,
                    "Total Amount":  item.total_amount || 0,
                };
                ExportData.push(data);
            });
        return (
            <Box>
                <CommonGridToolBarWithFilterTextCustom data={ExportData} title={this.props?.isFromIP ? "IP GST Report" : "OP GST Report"} FilterTextArray={TextArray} filename={this.props?.isFromIP ? "IP GST Report" : "OP GST Report"} />
            </Box>
        )
    }
    render() {
        this.state.gstReportData?.forEach((element, index) => element.sno = index + 1)
        const { t } = this.props
        const columns = [
            {
                field: "patient_name", headerName: t("PatientDetails"), flex: 0.132, density: 'compact',
                renderCell: ({ row }) => (<Box component={'div'}><CommonPatientDetails disablePhoto={true} data={row} /></Box>)
            },
            {
                field: "invoice_number", headerName: t("Bill Details"), flex: 0.133,
                renderCell: (params) => (
                    <Box component={'div'}>{params?.row?.invoice_number ?
                        <Tooltip placement='top' title={params?.row?.invoice_number} arrow>
                            <Box>
                                <Typography fontWeight={600} fontSize={'0.8vw'}>{params?.row?.invoice_number ? "..." + params?.row?.invoice_number.slice(3) : params?.row?.invoice_number}</Typography>
                                <Box display={'flex'}>
                                    <Typography fontSize={'0.8vw'}>{params?.row?.invoice_date ? params?.row?.invoice_date : '-'}</Typography>
                                </Box>
                            </Box>
                        </Tooltip> : "-"}
                    </Box>)
            },
            {
                field: "service_type", headerName: t("ServiceType"), flex: 0.123,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.service_type ? params?.row?.service_type?.length > 20 ?
                    <Tooltip placement='top' title={params?.row?.service_type} arrow><div>{params?.row?.service_type.slice(0, 20) + "..."}</div></Tooltip>
                    : params?.row?.service_type : "-"}</Box>)
            },
            {
                field: "service_name", headerName: t("ServiceName"), flex: 0.123,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.service_name ? params?.row?.service_name?.length > 20 ?
                    <Tooltip placement='top' title={params?.row?.service_name} arrow><div>{params?.row?.service_name.slice(0, 20) + "..."}</div></Tooltip>
                    : params?.row?.service_name : "-"}</Box>)
            },
            {
                field: "service_amount", headerName: `${t("ServiceAmount")} (${CurrencySymbol})`, flex: 0.1, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.service_amount ? AmountFormat(params?.row?.service_amount).replace(`${CurrencySymbol}`, "") : "0"}</Box>)
            },
            {
                field: "gst_percentage", headerName: `${t("GST (%)")}`, flex: 0.1, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.gst_percentage ? params?.row?.gst_percentage : "0"}</Box>)
            },
            {
                field: "gst_amount", headerName: `${t("GST Amount")} (${CurrencySymbol})`, flex: 0.1, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.gst_amount ? AmountFormat(params?.row?.gst_amount).replace(`${CurrencySymbol}`, "") : "0"}</Box>)
            },
            {
                field: "total_amount", headerName: `${t("Total Amount")} (${CurrencySymbol})`, flex: 0.1, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.total_amount ? AmountFormat(params?.row?.total_amount).replace(`${CurrencySymbol}`, "") : "0"}</Box>)
            }
        ]
        return (
            <Box component={'div'} className='eMed_rts_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'>
                        {AmountsCard(t("ServiceAmount"), this.state.serviceTotalAmount)}
                        {AmountsCard(t("GST Amount"), this.state.gstTotalAmount)}
                        {AmountsCard(t("TotalAmount"), this.state.totalAmount)}
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container'>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Button className='eMed_usrconf_btn' emed_tid='gstReport_filter_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </Button>
                        </Box>
                        <Button className='eMed_usrconf_btn' emed_tid='gstReport_print_btn' onClick={() => { this.printReports() }}>
                            <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                        </Button>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_rts_table'>
                    <DataGrid
                        rows={this.state.gstReportData}
                        columns={columns}
                        getRowId={(row) => row['sno']}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                    />
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}


export default withTranslation()(GSTReport)
