import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import TriggerApiOnView from './TriggerApiOnView'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { getCachevalue } from '../../../../Utility/Services/CacheProviderService';
import { ToolsFieldWithMic } from '../../../../Components/Common Components/CommonComponents';


export default function CollabDischargeSum(props) {
    const { id, isProgrammaticScroll, userData, details } = props;

    const [errorMsg, seterrorMsg] = useState(false);
    const [errorMsgText, seterrorMsgText] = useState('');
    const [dislayOut, setdislayOut] = useState([]);
    const [disSumData, setdisSumData] = useState([]);
    const [patient_id, setpatient_id] = useState(null);
    const [valDisSum, setvalDisSum] = useState({});
    useEffect(() => {
        try {
            const cachedData = getCachevalue('DoctorPatientData');
            const appointData = JSON.parse(cachedData);
            if (appointData?.patient_id) {
                setpatient_id(appointData.patient_id);
            }
        } catch (error) {
            errorMessage(error.message)
        }
    }, []);

    const getDisSumLayout = () => {
        try {
            RestAPIService.getAll(`${Serviceurls.DIS_SUM_LAYOUT}?clinic_id=${getCachevalue('SelectedDoctorClinic')}`)
                .then(response => {
                    if (response.data.status === 'success') {
                        setdislayOut(response.data.data)
                        getDischargeSum(response.data.data)
                    }
                }).catch(e => {
                    if (e.response?.data?.status === "fail") {
                        errorMessage(e.response.data.message)
                    } else {
                        errorMessage(e.message)
                    }
                })
        } catch (e) {
            errorMessage(e.message)
        }
    }

    const getDischargeSum = (layout) => {
        try {
            RestAPIService.getAll(`${Serviceurls.IP_DIS_SUM_POST}?clinic_id=${getCachevalue('SelectedDoctorClinic')}&patient_id=${patient_id}&ip_admission_id=${details?.admission_id}`)
                .then(response => {
                    if (response.data.status === 'success') {
                        setdisSumData(response.data.data || [])
                        appendData(response.data.data, layout)
                    }
                }).catch(e => {
                    if (e.response?.data?.status === "fail") {
                        errorMessage(e.response.data.message)
                    } else {
                        errorMessage(e.message)
                    }
                })
        } catch (e) {
            errorMessage(e.message)
        }
    }

    const appendData = (data, layout) => {
        if (data.length == 1) {
            var tem_json = data[0].template_data
            var data1 = layout.filter((item) => (item.id == data.map((item) => item.layout_id)))
            var keys = {}
            data1[0]?.dis_sum_json?.forEach((element) => {
                keys[element.field_name] = tem_json[element.field_name]
            })
            setvalDisSum(keys)
        }
    };


    const getDisSumData = (section) => {
        switch (section) {
            case "DisSummary":
                getDisSumLayout()
                break;
            default:
                break;
        }
    }

    const msgClose = () => {
        seterrorMsg(false);
        seterrorMsgText('');
    }
    const errorMessage = (msg) => {
        seterrorMsg(true)
        seterrorMsgText(msg)
    }
    const textView = (label, val) => {
        return (
            <ToolsFieldWithMic
                FromTestReport={true}
                isFrom={"dischargeSummary"}
                EditorDisable={true}
                Title={label}
                EditorRef={val || ''}
                overallHeight={"auto"}
                EditorHeight={"auto"}
                micTextEditDisabled={true}
                errorMsg={errorMessage.bind(this)}
            />
        )
    }
    let datalabel = []
    if(dislayOut.length > 0 && disSumData.length > 0){
         datalabel = dislayOut.filter((item) => (item.id == disSumData.map((item) => item.layout_id)))
    }
    return (
        <Box>
            <Typography fontSize={'1vw'} fontWeight={'bold'}>Discharge Summary</Typography>
            <TriggerApiOnView id={`DisSummary_${id}`} onEnterView={(id) => getDisSumData(id)} isProgrammaticScroll={isProgrammaticScroll}>
                <Box id={`DisSummary_${id}`} marginLeft={'1vw'}>
                    {datalabel.length === 1 ? datalabel[0]?.dis_sum_json?.map((item) => {
                        if (item.label === "Medication" && item.is_active === true) {
                            return (
                                <Box marginTop={'.5vw'}>
                                    <Typography fontSize={'1vw'} fontWeight={'bold'} >{'Medication'}</Typography>
                                    <Paper>
                                        <TableContainer>
                                            <Table size='small' aria-label="stictbl">
                                                <TableHead sx={{ backgroundColor: '#FAF9F9' }}>
                                                    <TableRow>
                                                        <TableCell sx={{ fontWeight: 'bold', width: '5vw' }}>s.No</TableCell>
                                                        <TableCell sx={{ fontWeight: 'bold', width: '25vw' }}>Medication Name</TableCell>
                                                        <TableCell sx={{ fontWeight: 'bold', width: '20vw' }}>Dosage/Strength</TableCell>
                                                        <TableCell sx={{ fontWeight: 'bold', width: '10vw' }}>Timing</TableCell>
                                                        <TableCell sx={{ fontWeight: 'bold', width: '20vw' }}>Route</TableCell>
                                                        <TableCell sx={{ fontWeight: 'bold', width: '20vw' }}>Instruction</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {valDisSum.medication?.length > 0 ? valDisSum.medication?.map((list, i) => (
                                                        <TableRow>
                                                            <TableCell sx={{ width: '5vw' }}>{i + 1}</TableCell>
                                                            <TableCell sx={{ width: '25vw' }}>{list.medicinename || '-'}</TableCell>
                                                            <TableCell sx={{ width: '20vw' }}>{list.dosage || '-'}</TableCell>
                                                            <TableCell sx={{ width: '10vw' }}>{list.maen || '-'}</TableCell>
                                                            <TableCell sx={{ width: '20vw' }}>{list.route || '-'}</TableCell>
                                                            <TableCell sx={{ width: '20vw' }}>{list.instruction || '-'}</TableCell>
                                                        </TableRow>
                                                    )) : <TableCell colSpan={5}>{`No Records to Be Shown`}</TableCell>}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                </Box>
                            )

                        } else if (item.label === "Discharge Instruction" && item.is_active === true) {
                            return (
                                <Box marginTop={'.5vw'}>
                                    <Typography fontSize={'1vw'} fontWeight={'bold'} >{`Discharge Instruction`}</Typography>
                                    <Box marginLeft={'1vw'}>
                                        <Box>
                                            {textView(`Diet`, valDisSum?.discharge_instruction?.diet)}
                                        </Box>
                                        <Box>
                                            {textView(`Activity`, valDisSum?.discharge_instruction?.acivity)}
                                        </Box>
                                        <Box>
                                            {textView(`Advice on discharge`, valDisSum?.discharge_instruction?.advice_discharge)}
                                        </Box>
                                    </Box>

                                </Box>
                            )
                        } else if (item.label === "In Hospital Medication" && item.is_active === true) {
                            return (
                                <Box marginTop={'.5vw'}>
                                    <Typography fontSize={'1vw'} fontWeight={'bold'} >{'In Hospital Medication'}</Typography>
                                    <Paper>
                                        <TableContainer>
                                            <Table size='small' aria-label="stictbl">
                                                <TableHead sx={{ backgroundColor: '#FAF9F9' }}>
                                                    <TableRow>
                                                        <TableCell sx={{ fontWeight: 'bold', width: '5vw' }}>s.No</TableCell>
                                                        <TableCell sx={{ fontWeight: 'bold', width: '25vw' }}>Medication Name</TableCell>
                                                        <TableCell sx={{ fontWeight: 'bold', width: '20vw' }}>Dosage/Strength</TableCell>
                                                        <TableCell sx={{ fontWeight: 'bold', width: '10vw' }}>Timing</TableCell>
                                                        <TableCell sx={{ fontWeight: 'bold', width: '20vw' }}>Route</TableCell>
                                                        <TableCell sx={{ fontWeight: 'bold', width: '20vw' }}>Instruction</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {valDisSum.in_hospital_medication?.length > 0 ? valDisSum.in_hospital_medication?.map((list, i) => (
                                                        <TableRow>
                                                            <TableCell sx={{ width: '5vw' }}>{i + 1}</TableCell>
                                                            <TableCell sx={{ width: '25vw' }}>{list.medicinename || '-'}</TableCell>
                                                            <TableCell sx={{ width: '20vw' }}>{list.dosage || '-'}</TableCell>
                                                            <TableCell sx={{ width: '10vw' }}>{list.maen || '-'}</TableCell>
                                                            <TableCell sx={{ width: '20vw' }}>{list.route || '-'}</TableCell>
                                                            <TableCell sx={{ width: '20vw' }}>{list.instruction || '-'}</TableCell>
                                                        </TableRow>
                                                    )) : <TableCell colSpan={5}>{`No Records to Be Shown`}</TableCell>}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                </Box>

                            )
                        } else if (item.label === 'Admission Diagnosis' && item.is_active === true) {
                            return (
                                <Box marginTop={'.5vw'}>
                                    {valDisSum.admission_diagnosis?.length > 0 ? valDisSum.admission_diagnosis.map((data) => (
                                        <Box>
                                            <Typography fontSize={'1vw'} fontWeight={'bold'} >{item.label}</Typography>
                                            <Typography >{`${data.icd_code}|${data.icd_short_desc}|${data.icd_desc}`}</Typography>
                                        </Box>
                                    )) : null}
                                    <Box>
                                        {textView(`Admission Provisional Dignosis`, disSumData.map((item) => item.template_data.provisional_diagnosis))}
                                    </Box>
                                </Box>
                            )
                        } else if (item.label === 'Discharge Diagnosis' && item.is_active === true) {
                            return (
                                <Box marginTop={'.5vw'}>
                                    <Typography fontSize={'1vw'} fontWeight={'bold'} >{item.label}</Typography>
                                    {valDisSum.discharge_diagnosis?.length > 0 ? valDisSum.discharge_diagnosis.map((data) => (
                                        <Typography>{`${data.icd_code}|${data.icd_short_desc}|${data.icd_desc}`}</Typography>
                                    )) : '-'}
                                </Box>
                            )
                        }
                        else if (item.label === 'Investigation and Test Report' && item.is_active === true) {
                            return (
                                <Box marginTop={'.5vw'}>
                                    {textView(`Invetigation`, valDisSum?.investigation_and_test_report?.investigation)}
                                </Box>
                            )
                        }
                        else if (item.label === 'Name Anaesthetist and Type of Anaesthesia' && item.is_active === true) {
                            return (
                                <Box marginTop={'.5vw'}>
                                    <Box>
                                        {textView(`Type of Anaesthesia`, valDisSum?.name_anaesthetist_and_type_of_anaesthesia?.type_of_anaesthesia)}
                                    </Box>
                                    <Box>
                                        {textView(`Name of Anaesthetist`, valDisSum?.name_anaesthetist_and_type_of_anaesthesia?.name_of_anaesthetist)}
                                    </Box>
                                </Box>
                            )
                        }
                        else {
                            let vals = item.field_name
                            return (
                                <Box marginTop={'.5vw'}>
                                    {textView(item.label, valDisSum[vals])}
                                </Box>
                            )
                        }
                    }) : <Box className='eMed_DisSum_Tem_Nolayout'>{'-'}</Box>}
                </Box>
            </TriggerApiOnView>
            {errorMsg && <ToastMsg
                severity={'error'}
                msg={errorMsgText}
                msgPop={msgClose.bind(this)}
            />}
        </Box>
    )
}
