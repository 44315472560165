import { Box, Checkbox, FormControlLabel, Stack, TextField, Button, Tooltip, Typography, Autocomplete } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Colors } from '../../../Styles/Colors'
import { DataGrid } from '@mui/x-data-grid'
import { CommonDeleteButton, CommonEditButton, CommonGridToolBarWithFilterText, DeletePopup } from '../../../Components/Common Components/CommonComponents'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import { CurrencySymbol } from '../../../Utility/Constants'
import { AmountFormat } from '../../../Components/CommonFunctions/CommonFunctions'

const drug_Type = ['Consumable', 'Non Consumable']
const drugList = {
    drugType: null,
    drugName: '',
    dosageType: null,
    strength: '',
    amount: '',
}
export default function DrugMaster() {
    const [DrugData, setDrugData] = useState([]);
    const [DoseType, setDoseType] = useState([]);
    const [page, setpage] = useState(0);
    const [pageSize, setpageSize] = useState(10);
    const [drugStates, setdrugStates] = useState(drugList);
    const [errorMsg, seterrorMsg] = useState(false);
    const [errorMsgText, seterrorMsgText] = useState('');
    const [successMsg, setsuccessMsg] = useState(false);
    const [successMsgText, setsuccessMsgText] = useState('');
    const [isActive, setisActive] = useState(true);
    const [EditID, setEditID] = useState(null);
    const [isDelete, setisDelete] = useState(false);
    const [deletedID, setdeletedID] = useState(null);
    const [delIDs, setdelIDs] = useState([]);
    const [isloader, setisloader] = useState(false);
    const [CheckBoxList, setCheckBoxList] = useState([]);
    

    useEffect(() => {
        getDosageType()
        getDrugMaster()
    }, [])
    const errorMessage = (msg) => {
        seterrorMsg(true)
        seterrorMsgText(msg)
    }
    const successMessage = (msg) => {
        setsuccessMsg(true)
        setsuccessMsgText(msg)
    }
    const getDrugMaster = () => {
        try {
            setisloader(true)
            RestAPIService.getAll(Serviceurls.ASS_GET_DRUG_MASTER)
                .then((response) => {
                    if (response.data.status === 'success') {
                        setDrugData(response?.data?.data || [])
                        setisloader(false)
                    }
                }).catch(e => {
                    setisloader(false)
                    if (e?.response?.data?.status === 'fail') {
                        errorMessage(e.response.data.message)
                    } else {
                        errorMessage(e.message)
                    }
                })
        } catch (e) {
            setisloader(false)
            errorMessage(e.message)
        }
    }
    const getDosageType = () => {
        try {
            setisloader(true)
            RestAPIService.getAll(Serviceurls.PHARMA_DOSAGE_TYPE_LIST)
                .then((response) => {
                    if (response.data.status === 'success') {
                        setDoseType(response?.data?.data || [])
                        setisloader(false)
                    }
                }).catch(e => {
                    setisloader(false)
                    if (e?.response?.data?.status === 'fail') {
                        errorMessage(e.response.data.message)
                    } else {
                        errorMessage(e.message)
                    }
                })
        } catch (e) {
            setisloader(false)
            errorMessage(e.message)
        }
    }

    const postDrugData = () => {
        try {
            setisloader(true)
            if (!drugStates.drugType || !drugStates.drugName || !drugStates.dosageType || !drugStates.strength || !drugStates.amount) {
                errorMessage('Please Enter the All Details')
                setisloader(false)
            } else if(DrugData.some((list)=>(list?.drug_name === drugStates.drugName && list?.drug_type === drugStates.drugType && list?.dosage_type === drugStates.dosageType && list?.drug_strength === drugStates.strength && EditID !== list?.id))){
                errorMessage('Drug Already exists.')
                setisloader(false)
            } else {
                let data = {
                    "drug_type": drugStates.drugType || null,
                    "drug_name": drugStates.drugName || '',
                    "dosage_type": drugStates.dosageType || null,
                    "drug_strength": drugStates.strength || '',
                    "amount": drugStates.amount || 0,
                    "is_active": isActive,
                }
                if (EditID) {
                    data['id'] = EditID
                }
                RestAPIService.create(data, Serviceurls.ASS_GET_DRUG_MASTER)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            successMessage(response.data.message)
                            getDrugMaster()
                            ClearFunc()
                            setisloader(false)
                        }
                    }).catch(e => {
                        setisloader(false)
                        if (e?.response?.data?.status === 'fail') {
                            errorMessage(e.response.data.message)
                        } else {
                            errorMessage(e.message)
                        }
                    })
            }
        } catch (e) {
            setisloader(false)
            errorMessage(e.message)
        }
    }

    const deleteDrug = () => {
        try {
            let data = {
                'id': deletedID ? [deletedID] : (delIDs || [])
            }
            RestAPIService.delete(Serviceurls.ASS_GET_DRUG_MASTER, data)
                .then((response) => {
                    if (response.data.status === 'success') {
                        successMessage(response.data.message)
                        getDrugMaster()
                        setCheckBoxList([]);
                        ClearFunc()
                    }
                }).catch(e => {
                    if (e?.response?.data?.status === 'fail') {
                        errorMessage(e.response.data.message)
                    } else {
                        errorMessage(e.message)
                    }
                })
        } catch (e) {
            errorMessage(e.message)
        }
    }

    const onChangeHandler = (e, name) => {
        let value = e.target.value
        switch (name) {
            case 'drugName':
                if (value === "" || value.length <= 150) {
                    setdrugStates({ ...drugStates, [name]: value })
                }
                break
            case 'strength':
                if (value === "" || value.length <= 50) {
                    setdrugStates({ ...drugStates, [name]: value })
                }
                break
            default:
                let number = CommonValidation.NumberWithDot(value);
                if (value === '' || number && value.length <= 10) {
                    setdrugStates({ ...drugStates, [name]: value })
                }
                break

        }
    }

    const onChangeAuto = (e, val, name) => {
        switch (name) {
            case 'drugType':
                setdrugStates({ ...drugStates, [name]: val });
                break
            default:
                setdrugStates({ ...drugStates, [name]: val })
                break

        }
    }

    const textBox = (label, name, val, list, width) => {
        return (
            <Box>
                {name === 'drugType' || name === 'dosageType' ?
                    <Autocomplete
                        size={'small'}
                        options={list}
                        name={name}
                        getOptionLabel={(option) => option}
                        value={val}
                        sx={{ width: width, marginRight: '1vw' }}
                        onChange={(event, newValue) => { onChangeAuto(event, newValue, name) }}
                        renderInput={(params) => <TextField {...params} label={label} />}
                    />
                    :
                    <TextField
                        size='small'
                        name={name}
                        id="drug-master"
                        value={val}
                        sx={{ width: width, marginRight: '1vw' }}
                        label={label}
                        onChange={(e) => { onChangeHandler(e, name) }}
                        variant="outlined"
                    />
                }
            </Box>
        )
    }

    const grpDelete = () => {
        setisDelete(true)
    }

    const tableView = () => {

        const gridToolBar = () => {
            return (
                <Box>
                    <CommonGridToolBarWithFilterText isDelete={delIDs?.length > 0} removeData={grpDelete.bind(this)} />
                </Box>

            )
        }

        const handleTableCheckbox = (id) => {
            let selectedIDs = new Set(id);
            setCheckBoxList(id)
            let selectedRows = DrugData.filter((row) => selectedIDs.has(row.sno));
            let FinalData = []
            selectedRows.forEach((item) => {
                FinalData.push(item.id)
            })
            setdelIDs(FinalData)
        }
        DrugData?.forEach((element, index) => element.sno = index + 1)
        const columns = [
            {
                field: 'drug_type', headerName: 'Drug Type', flex: 0.15,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.drug_type ? params?.row?.drug_type : '-'}</Box>)

            },
            {
                field: 'drug_name', headerName: 'Drug Name', flex: 0.25,
                renderCell: (params) => (params.row.drug_name ? params.row.drug_name?.length > 15 ?
                    <Tooltip placement="top" title={params.row.drug_name}><Typography fontSize={'1vw'}>{params.row.drug_name?.slice(0, 15) + '...'}</Typography></Tooltip> :
                    <Typography fontSize={'1vw'}>{params.row.drug_name}</Typography> : '-')

            },
            {
                field: 'dosage_type', headerName: 'Dosage Type', flex: 0.15,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.dosage_type ? params?.row?.dosage_type : '-'}</Box>)

            },
            {
                field: 'drug_strength', headerName: 'Strength', flex: 0.15,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.drug_strength ? params?.row?.drug_strength : '-'}</Box>)

            },
            {
                field: 'amount', headerName: 'Amount', flex: 0.15,
                renderCell: (params) => (<Box component={'div'}>{AmountFormat(params?.row?.amount || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)

            },
            {
                field: 'action', headerName: 'Action', flex: 0.1,
                renderCell: (params) => (
                    <Stack direction="row" alignItems="center" spacing={4}>
                        <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={1}>
                            <CommonEditButton size="1.5vw" onClick={() => {
                                setdrugStates({
                                    ...drugStates,
                                    drugType: params.row.drug_type,
                                    dosageType: params.row.dosage_type,
                                    drugName: params.row.drug_name,
                                    strength: params.row.drug_strength,
                                    amount: params.row.amount,
                                })
                                setEditID(params.row.id)
                                setisActive(params.row.isActive)
                            }} />
                            <CommonDeleteButton size="1.5vw" onClick={() => { setisDelete(true); setdeletedID(params?.row?.id); }} />
                        </Stack>
                    </Stack>)

            },
        ]
        return (
            <Box sx={{ height: '69vh' }}>
                <DataGrid
                    checkboxSelection
                    selectionModel={CheckBoxList}
                    onSelectionModelChange={(id) => handleTableCheckbox(id)}
                    rows={DrugData}
                    columns={columns}
                    getRowId={(row) => row['sno']}
                    page={page}
                    pageSize={pageSize}
                    localeText={{
                        toolbarColumns: "",
                        toolbarDensity: "",
                        toolbarExport: "",
                        toolbarFilters: "",
                        toolbarExportPrint: ""
                    }}
                    components={{
                        Toolbar: gridToolBar,
                        NoRowsOverlay: () => (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                {'No Records To Be Shown'}
                            </Box>
                        )
                    }}
                    onPageChange={(newPage) => { setpage(newPage) }}
                    onPageSizeChange={(newPageSize) => { setpageSize(newPageSize) }}
                    rowsPerPageOptions={[10, 20, 30]}
                    pagination
                    headerHeight={40}
                    density='compact'
                    disableSelectionOnClick
                    loading={isloader}
                />
            </Box>
        )
    }

    const ClearFunc = () => {
        setdrugStates(drugList)
        setisActive(true);
        setEditID(null);
        setisDelete(false);
        setdelIDs([]);
    }

    const msgClose = () => {
        seterrorMsg(false);
        seterrorMsgText('');
        setsuccessMsg(false);
        setsuccessMsgText('');
    }

    const PopupClose = () => {
        setisDelete(false);
        setdeletedID(null)
    }
    return (
        <Box component={'div'} sx={{ height: '79.5vh', width: '94vw', backgroundColor: Colors.Background, padding: '.5vw' }}>
            <Box component={'div'} sx={{ height: '9vh', width: '93vw', backgroundColor: 'white', padding: '1vw' }}>
                <Box component={'div'} sx={{ display: 'flex', flexDirection: 'row', alignItems:'center' }}>
                    {textBox("Drug Type", 'drugType', drugStates.drugType, drug_Type, '14vw')}
                    {textBox("Drug Name", 'drugName', drugStates.drugName, '14vw')}
                    {textBox("Dosage Type", 'dosageType', drugStates.dosageType, DoseType, '14vw')}
                    {textBox("Strength", 'strength', drugStates.strength, '14vw')}
                    {textBox("Amount", 'amount', drugStates.amount, '14vw')}
                    <Box sx={{ width: '15vw' }}>
                        <Button size='small' variant='outlined' sx={{ textTransform: 'capitalize', width: '7vw' }} onClick={() => { ClearFunc() }}>Clear</Button>
                        <Button size='small' variant='contained' sx={{ textTransform: 'capitalize', width: '7vw', marginLeft: '1vw' }} disabled={isloader} onClick={() => { postDrugData() }}>Save</Button>
                    </Box>
                </Box>
            </Box>
            <Box component={'div'} sx={{ height: '69vh', width: '93vw', marginTop: '.5vw', backgroundColor: 'white', }}>
                {tableView()}
            </Box>
            {errorMsg ?
                <ToastMsg
                    severity={'error'}
                    msg={errorMsgText}
                    msgPop={msgClose.bind(this)}
                />
                : null}
            {successMsg ?
                <ToastMsg
                    severity={'success'}
                    msg={successMsgText}
                    msgPop={msgClose.bind(this)}
                />
                : null}
            {isDelete ?
                <DeletePopup
                    DeletTitle={`Are you sure, Do you want to delete this item ?`}
                    DeleteNotify={"If you remove this, this could be effect in entire Configuration"}
                    deleteAlertPopupClose={PopupClose.bind(this)}
                    removeData={deleteDrug.bind(this)}
                />
                : null}
        </Box>
    )
}
