import React, { Component } from 'react'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { DataGrid } from '@mui/x-data-grid'
import { Autocomplete, Box, Button, Checkbox, Drawer, FormControlLabel, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import { withTranslation } from 'react-i18next';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import CommonGridHeader, { CommonGridToolBarWithFilterText, CommonPatientDetails } from '../../../../Components/Common Components/CommonComponents'
import { DateTime } from 'luxon'
import dayjs from 'dayjs';
import { Colors } from '../../../../Styles/Colors'
class RefundorCancelledReports extends Component {
  constructor(props) {
    super(props)
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const currentYear = new Date().getFullYear()
    const minDate = dayjs(`2024-04-01`)
    this.state = {
      ref_Can_ReportData: [],
      totalRFAmt: 0,
      cash: 0,
      upi: 0,
      bank_transfer: 0,
      page: 0,
      pageSize: 10,
      isLoader: false,
      FilterOpen: false,
      DatePickerOpen: false,
      datePicker: yesterday,
      minDate: minDate,
      doctorNameList: [],
      PatientName: '',
      AccNo: '',
      MobNo: '',
      doctorName: [],
      isCancel: true,
      isRefund: true,
      isAdvance: true,
      receiptType: ["Cancelled", "Refund", "Advance refund"],
      isErrorMsg: false,
      isErrorMsgText: '',
      printLoad: false
    }
  }

  componentDidMount() {
    this.getRefundCandellData()
  }

  getRefundCandellData = () => {
    try {
      var states = this.state
      let datepic = new Date(states.datePicker)
      let formatDate = DateTime.fromJSDate(datepic).toFormat('yyyy-MM-dd')
      this.setState({ isLoader: true })
      RestAPIService.getAll(Serviceurls.ACC_REF_CANCEL + `?from_date=${formatDate}&to_date=${formatDate}&patient_name=${states.PatientName}&mobile_no=${states.MobNo}&uhid=${states.AccNo}&receipt_for=${states.receiptType}`)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              ref_Can_ReportData: response?.data?.data,
              totalRFAmt: response?.data?.total_refund_amount,
              cash: response?.data?.total_cash_refund,
              upi: response?.data?.total_upi_refund,
              bank_transfer: response?.data?.total_bank_refund,
              isLoader: false,
            })
          }
        }).catch((error) => {
          this.setState({ isLoader: false})
          if (error?.response?.data?.status === 'fail') {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ isLoader: false})
      this.errorMessage(error.message)
    }
  }

  getRefundCandellDataPrint = () => {
    try {
      var states = this.state
      let datepic = new Date(states.datePicker)
      let formatDate = DateTime.fromJSDate(datepic).toFormat('yyyy-MM-dd')
      RestAPIService.getAll(Serviceurls.ACC_REF_CANCEL + `?from_date=${formatDate}&to_date=${formatDate}&patient_name=${states.PatientName}&mobile_no=${states.MobNo}&uhid=${states.AccNo}&receipt_for=${states.receiptType}&export_type=pdf`)
        .then((response) => {
          if (response) {
            //Create a Blob from the PDF Stream
            const file = new Blob(
              [response.data],
              { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
            this.setState({ printLoad: false })
          }
        }).catch((error) => {
          this.setState({ printLoad: false })
          if (error?.response?.data?.status === 'fail') {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ printLoad: false })
      this.errorMessage(error.message)
    }
  }
  gridToolBar = () => {
    var { t } = this.props
    var states = this.state
    let datepic = new Date(states.datePicker)
    let formatDate = DateTime.fromJSDate(datepic).toFormat('dd-MM-yyyy')
    let TextArray = [
      { label: "Date", value: formatDate }
    ]
    return (
      <Box>
        <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
      </Box>
    )
  }
  errorMessage = (msg) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: msg
    })
  }
  closeFilter = () => {
    this.setState({
      FilterOpen: false,
    })
  }

  handleCheckBox = (e, key) => {
    this.state[key] = e.target.checked
    let payMode = []
    if (this.state.isCancel) {
      payMode.push("Cancelled")
    }
    if (this.state.isRefund) {
      payMode.push("Refund")
    }
    if (this.state.isAdvance) {
      payMode.push("Advance refund")
    }
    this.state.receiptType = payMode
    this.setState({
      isCancel: this.state.isCancel,
      isRefund: this.state.isRefund,
      isAdvance: this.state.isAdvance,
      receiptType: this.state.receiptType
    })
  }

  renderFilterScreen = () => {
    const { t } = this.props
    return (
      <Box component={"div"} className='eMed_rts_filter_container'>
        <Box component={"div"} className='eMed_filter_Top'>
          <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
          <Button emed_tid={"FilterOpen_Testid"} onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
          </Button>
        </Box>
        <Box className='eMed_rts_middle' ml={'0.4vw'}>
          <Box mt={'1vw'} display={'flex'} justifyContent={'flex-end'}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                open={this.state.DatePickerOpen}
                onClose={() => { this.setState({ DatePickerOpen: false }) }}
                value={this.state.datePicker}
                name={"datePicker"}
                minDate={this.state.minDate}
                maxDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                inputFormat='DD-MM-YYYY'
                views={["year", "month", "day"]}
                onChange={
                  (newDate) => {
                    this.setState({
                      datePicker: newDate,
                    });
                  }}
                renderInput={(params) => <TextField onKeyDown={(e) => e.preventDefault()} style={{ width: '12vw' }} size='small' {...params} inputProps={{ ...params.inputProps, 'emed_tid': 'RefundTestID_Datepicker', }} onClick={() => { this.setState({ DatePickerOpen: true }) }} />}
              />
            </LocalizationProvider>
          </Box>
          <Box mt={'1vw'}>
            <TextField
              inputProps={{ maxLength: 30, emed_tid: "PatientName_TestID" }}
              size='small'
              sx={{ width: '23.5vw' }}
              label={t("PatientName")}
              value={this.state.PatientName}
              onChange={(e) => {
                let number = CommonValidation.alphabetOnly(e.target.value);
                if (number || e.target.value === "") {
                  this.setState({ PatientName: e.target.value })
                }

              }}
            />
          </Box>
          <Box mt={'1vw'}>
            <TextField
              inputProps={{ maxLength: 15, emed_tid: "UHID_TestID" }}
              sx={{ width: '23.5vw' }}
              size='small'
              label={t("UHID")}
              value={this.state.AccNo}
              onChange={(e) => {
                let number = CommonValidation.gstnumberValidation(e.target.value);
                if (number || e.target.value === "") {
                  this.setState({ AccNo: e.target.value.toUpperCase() })
                }
              }}
            />
          </Box>
          <Box mt={'1vw'}>
            <TextField
              inputProps={{ maxLength: 10, emed_tid: "MobNo_TestID" }}
              sx={{ width: '23.5vw' }}
              size='small'
              label={t("MobNo")}
              value={this.state.MobNo}
              onChange={(e) => {
                let number = CommonValidation.numberOnly(e.target.value);
                if (number || e.target.value === "") {
                  this.setState({ MobNo: e.target.value })
                }
              }}
            />
          </Box>
          <Box mt={'1vw'}>
            <Box className="eMed_Filter_Header">
              <Typography fontWeight={'bold'}>{"Receipt Type"}</Typography>
            </Box>
            <Box>
              <FormControlLabel width={'9vw'} control={<Checkbox emed_tid={'isCancel_TestID'} checked={this.state.isCancel} onChange={(e) => { this.handleCheckBox(e, "isCancel",) }} size='small' />} label={"Cancelled"} />
              <FormControlLabel width={'9vw'} control={<Checkbox emed_tid={'isRefund_TestID'} checked={this.state.isRefund} onChange={(e) => { this.handleCheckBox(e, "isRefund",) }} size='small' />} label={"Refund"} />
              <FormControlLabel width={'9vw'} control={<Checkbox emed_tid={'isAdvance_TestID'} checked={this.state.isAdvance} onChange={(e) => { this.handleCheckBox(e, "isAdvance",) }} size='small' />} label={"Advance Refund"} />
            </Box>
          </Box>
        </Box>
        <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
          <Button variant='outlined' size="small" emed_tid={"Clear_TestID"}
            onClick={() => {
              this.setState({
                datePicker: new Date().setDate(new Date().getDate() - 1),
                PatientName: '',
                AccNo: '',
                MobNo: '',
                isCancel: true,
                isRefund: true,
                isAdvance: true,
                receiptType: ["Cancelled", "Refund", "Advance refund"],
                FilterOpen: false,
              }, () => { this.getRefundCandellData() })
            }}
            id="eMed_Config_btn">{t("Clear")}</Button>
          <Button variant='contained' size="small" emed_tid={"Search_TestID"}
            onClick={() => {
              this.setState({ FilterOpen: false }, () => this.getRefundCandellData())
            }}
            id="eMed_Config_btn">{t("Search")}</Button>
        </Stack>
      </Box>
    )
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
    })
  }
  render() {
    const { t } = this.props
    this.state.ref_Can_ReportData.forEach((element, index) => element.sno = index + 1)
    const columns = [
      {
        field: "patient_name", headerName: 'Patient Details', type: 'string', flex: 0.20,
        renderCell: (params) => {
          let patientName = params?.row?.patient_name + "/" + params?.row?.patient_age
          let accNumAndMob = params?.row?.patient_uhid + "|" + params?.row?.mobile_number
          let gender = params?.row?.patient_gender === 'm' ? "M" : params?.row?.patient_gender === 'f' ? 'F' : params?.row?.patient_gender === 't' ? 'T' : '-'
          return (
            <Box component={'div'} display={'flex'} emed_tid='pat_dtls'>
              {/* <Box component={'div'}>
                <Box component={'img'} mr={'0.5vw'} src={gender === 'M' ? ImagePaths.Male.default : ImagePaths.Female.default} height={'2.5vw'} width={'2.5vw'} borderRadius={'50px'} />
              </Box> */}
              <Box component={'div'}>
                {patientName ? patientName?.length > 15 ?
                  <Tooltip placement='top' title={patientName}><Typography fontSize={'0.9vw'} fontWeight={600}>{patientName?.slice(0, 15) + "..."}</Typography></Tooltip> :
                  <Typography fontSize={'0.9vw'} fontWeight={600}>{patientName}</Typography> : '-'}
                {accNumAndMob ? accNumAndMob?.length > 15 ?
                  <Tooltip placement='top' title={accNumAndMob}><Typography fontSize={'0.8vw'} color={Colors.grayShade}>{accNumAndMob?.slice(0, 15) + "..."}</Typography></Tooltip> :
                  <Typography fontSize={'0.8vw'} color={Colors.grayShade}>{accNumAndMob}</Typography> : '-'}
              </Box>
            </Box>
          )
        }
      },
      {
        field: "invoice_bill_date", headerName: 'Bill Date', flex: 0.10, headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.invoice_bill_date ? params?.row?.invoice_bill_date : "-"}</Box>)
      },
      {
        field: "invoice_bill_no", headerName: 'Receipt No', type: 'string', flex: 0.20,
        renderCell: (params) => (<Box component={'div'}>{params?.row?.invoice_bill_no ? params?.row?.invoice_bill_no : "-"}</Box>)
      },
      {
        field: "refund_amount", headerName: 'Refund Amount', flex: 0.10, type: 'number',
        renderCell: (params) => (<Box component={'div'}>{params?.row?.refund_amount ? params?.row?.refund_amount : "-"}</Box>)
      },
      {
        field: "payment_mode_types", headerName: 'Payment Mode', type: 'string', flex: 0.20,
        renderCell: (params) => (<Box component={'div'}>{params?.row?.payment_mode_types ? params?.row?.payment_mode_types : "-"}</Box>)
      },
      {
        field: "receipt_for", headerName: 'Receipt Type', type: 'string', flex: 0.20,
        renderCell: (params) => (<Box component={'div'}>{params?.row?.receipt_for ? params?.row?.receipt_for : "-"}</Box>)
      },
    ]
    return (
      <Box component={'div'}>
        <Box component={'div'} className='eMed_Main_Container'>
          <Box component={'div'} className='eMed_Header_Container'>
            <Box component={'div'} className='eMed_Header_Rgt_Content'>
              {AmountsCard("Total Refund Amount", this.state.totalRFAmt, false, "white", false, '', 'Refund_cancel_Report_TotalAmt')}
              {AmountsCard("Cash", this.state.cash, false, "white", false, '', 'Refund_cancel_Report_Cash')}
              {AmountsCard("UPI", this.state.upi, false, "white", false, '', 'Refund_cancel_Report_UPI')}
              {AmountsCard("Bank Transfer", this.state.bank_transfer, false, "white", false, '', 'Refund_cancel_Report_Bank')}
            </Box>
            <Box component={'div'} className='eMed_Header_Lft_Content'>
              <Button className='Common_Btn_Min_Width eMed_Header_Btn' emed_tid='filter_btn'
                onClick={() => this.setState({ FilterOpen: true })}
              >
                <Box component={'img'} src={ImagePaths.Filter.default} height={'2vw'} width={'2vw'} />
              </Button>
              <Button className='Common_Btn_Min_Width eMed_Header_Btn' emed_tid='prt_btn' disabled={this.state.printLoad}
                onClick={() => this.setState({ printLoad: true }, () => this.getRefundCandellDataPrint())}
              >
                <Box component={'img'} src={ImagePaths.LabPrintIcon.default} height={'2vw'} width={'2vw'} />
              </Button>
            </Box>
          </Box>
          <Box component={'div'} className='eMed_Table_Content'>
            <DataGrid
              sx={{ bgcolor: 'white' }}
              rows={this.state.ref_Can_ReportData}
              columns={columns}
              getRowId={(row) => row.sno}
              page={this.state.page}
              pageSize={this.state.pageSize}
              localeText={{
                toolbarColumns: "",
                toolbarDensity: "",
                toolbarExport: "",
                toolbarFilters: "",
                toolbarExportPrint: ""
              }}
              components={{
                Toolbar: this.gridToolBar,
                NoRowsOverlay: () => (
                  <Stack className='eMed_conf_nodata'>
                    {t("NoRecordsFound")}
                  </Stack>
                )
              }}
              onPageChange={(newPage) => { this.setState({ page: newPage }) }}
              onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
              rowsPerPageOptions={[10, 20, 30]}
              pagination
              headerHeight={40}
              disableSelectionOnClick
              loading={this.state.isLoader}

            />
          </Box>
          <Drawer
            anchor={'right'}
            open={this.state.FilterOpen}
            ModalProps={{ onBackdropClick: this.closeFilter }}
          >
            {this.renderFilterScreen()}
          </Drawer>
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}
export default withTranslation()(RefundorCancelledReports);