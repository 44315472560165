import { Box, Button, IconButton, Tooltip, Autocomplete, TextField, Typography, FormControl, InputLabel, Select, MenuItem } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import React, { Component } from "react"
import { ImagePaths } from "../../../../Utility/ImagePaths"
import CommonGridHeader, { CommonPatientDetails } from "../../../../Components/Common Components/CommonComponents"
import { Colors } from "../../../../Styles/Colors"
import { Trans, withTranslation } from 'react-i18next'
import { t } from 'i18next'
import RestAPIService from "../../../../Utility/Services/RestAPIService"
import { Serviceurls } from "../../../../Utility/API/Serviceurls"
import ToastMsg from "../../../../Components/ToastMsg/ToastMsg"
import Loader from '../../../../Components/Loader';
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker'
import { getCachevalue, localGetItem } from "../../../../Utility/Services/CacheProviderService"
import PrintPreviewPop from "../../../../Components/Common Components/PrintPreviewPop"
import { CheckAccessFunc } from "../../../../Components/CommonFunctions/CommonFunctions"

class DischargeSummaryReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            disSumData: [],
            page: 0,
            pageSize: 10,
            loading: false,
            isErrorMsg: false,
            successMsg: false,
            isErrorMsgText: '',
            successMsgText: '',
            patientDetails: {},
            patientId: '',
            clinicId: '',
            appointmentId: '',
            loading: false,
            startDate: '',
            endDate: '',
            dateIndex: 0,
            selectedDoctor: "All",
            admissionId: null,
            patientData: {},
            withOutAppointment: {},
            userAccess: false,

        }
    }

    componentDidMount() {
        let userAccess = CheckAccessFunc("front_office", "Home", "Discharge Summary Reports", null, "Tab")?.editAccess
        let userInfo = JSON.parse(localGetItem('loggedInUserInfo'))
        let clinicId = userInfo.clinic_id
        let patientData = this.props.fromIP ? JSON.parse(getCachevalue('IppatientData')) : JSON.parse(getCachevalue('patientData'))
        let appointmentDetail = JSON.parse(getCachevalue("withoutAppointment"));
        let appointmentId = JSON.parse(getCachevalue('DoctorPatientData'))


        this.setState({
            userAccess: userAccess,
            clinicId: clinicId,
            appointmentId: appointmentId?.appointment_id,
            patientData: patientData,
            withOutAppointment: appointmentDetail
        }, () => {
            this.getPatientDetailData()
            this.getDischargeSumData()
            this.getDoctorListData()
        })
    }

    getPatientDetailData = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_PATIENT_DETAIL_GET + '?patient_id=' + this.state.patientData.patient_id)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            patientDetails: response?.data?.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error?.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error?.message)
        }
    }

    getDischargeSumData = () => {
        try {
            this.setState({ loading: true })
            let selectedDoc = this.state.selectedDoctor ? this.state.selectedDoctor === 'All' ? "" : this.state.selectedDoctor?.doctor_id : ''
            RestAPIService.getAll(Serviceurls.DOC_DISCHARGE_SUMMARY_GET + `?patient_id=${this.state.patientData.patient_id}&doctor_id=${selectedDoc}&from_date=${this.state.startDate}&to_date=${this.state.endDate}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            disSumData: response?.data?.data ? response?.data?.data : []
                        }, () => { this.setState({ loading: false }) })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error?.message)
                    }
                    this.setState({ loading: false })
                })
        } catch (error) {
            this.errorMessage(error?.message)
            this.setState({ loading: false })
        }
    }

    getDoctorListData = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_CLINIC_LIST_GET + `?clinic_id=${this.state.clinicId}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({ docDetails: response?.data?.data })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error?.message)
                    }
                })

        } catch (e) {
            this.errorMessage(e?.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    filterDate = (From, To, Index) => {
        this.setState({
            startDate: From,
            endDate: To,
            dateIndex: Index
        })
    }

    onClearDisSumFilter = () => {
        try {
            this.setState({
                startDate: '',
                endDate: '',
                dateIndex: 0,
                selectedDoctor: "All"
            }, () => {
                this.getDischargeSumData()
            })
        } catch (error) {
            this.errorMessage(error?.message)
        }
    }

    ClosePopUp = () => {
        this.setState({ printPreview: false })
    }

    renderLeftSide = () => {
        try {
            const { t } = this.props
            this.state.disSumData?.forEach((element, index) => element.sno = index + 1)
            const columns = [
                {
                    field: 'discharged_date', headerName: t("Discharge Date"), flex: 0.14, headerAlign: 'center', align: "center",
                    renderCell: (params) => (<Box>{params?.row?.discharged_date ? params?.row?.discharged_date : '-'}</Box>)
                },
                {
                    field: 'doctor_name', headerName: t("Doctor Name"), flex: 0.16, headerAlign: 'center', align: "center",
                    renderCell: (params) => (<Box>{params?.row?.doctor_name ? params?.row?.doctor_name.length > 15 ? <Tooltip placement="top" title={params?.row?.doctor_name}><div>{params?.row?.doctor_name.slice(0, 15) + '...'}</div></Tooltip> : params?.row?.doctor_name : '-'}</Box>)
                },
                {
                    field: 'diagnosis', headerName: t("Discharge Diagnosis"), flex: 0.55, headerAlign: 'center', align: "center",
                    renderCell: (params) => (<Box>{params?.row?.diagnosis?.length > 0 ? (params?.row?.diagnosis?.length > 1 || params?.row?.diagnosis[0]?.length > 55) ? <Tooltip placement="top" title={params?.row?.diagnosis?.map((item) => params?.row?.diagnosis?.length > 1 ? <li>{item}</li> : item)} arrow><div>{params?.row?.diagnosis[0]?.slice(0, 55) + '...'}</div></Tooltip> : params?.row?.diagnosis?.map((item) => item) : '-'}</Box>)
                },
                {
                    field: 'action', headerName: t("Action"), flex: 0.10, headerAlign: 'center', align: "center",
                    renderCell: (params) => (<Box>
                        {params?.row?.is_finish ? <IconButton><Tooltip placement='top' title='Print' arrow><div><img className='eMed_upload' src={!this.state.userAccess ? ImagePaths.ViewButtonOff.default : ImagePaths.LabViewButton.default} disabled={!this.state.userAccess} onClick={() => this.setState({ printPreview: true, admissionId: params?.row?.ip_admission_id })} alt='print' /></div></Tooltip></IconButton> : "In progress"
                        }</Box>
                    )
                }
            ]
            return (
                <DataGrid
                    rows={this.state.disSumData}
                    columns={columns}
                    getRowId={(row) => row['sno']}
                    page={this.state.page}
                    pageSize={this.state.pageSize}
                    localeText={{
                        toolbarColumns: "",
                        toolbarDensity: "",
                        toolbarExport: "",
                        toolbarFilters: "",
                        toolbarExportPrint: ""
                    }}
                    sx={{ backgroundColor: Colors.white }}
                    components={{
                        Toolbar: CommonGridHeader.CommonToolBar,
                        NoRowsOverlay: () => (
                            <Box className='eMed_conf_nodata'>
                                {'No Records To Be Shown'}
                            </Box>
                        )
                    }}
                    onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                    onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                    rowsPerPageOptions={[10, 20, 30]}
                    pagination
                    headerHeight={40}
                    disableSelectionOnClick
                    loading={this.state.loading}
                />
            )
        } catch (error) {
            this.errorMessage(error?.message)
        }
    }

    renderRightSide = () => {
        try {
            return (
                <Box>
                    <Box component={'div'} height={'2.5vw'} display={'flex'} pl={'0.5vw'} alignItems={'center'}>
                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Filter</Typography>
                    </Box>
                    <Box component={'div'} height={'23vw'} sx={{ background: Colors.white }} p={'1vw'} display={'flex'} width={'100%'}>
                        <Box component={'div'} width={'45%'} marginTop={'1vw'}>
                            <CommonDatePicker
                                FromDate={this.state.startDate}
                                ToDate={this.state.endDate}
                                DateIndex={this.state.dateIndex}
                                SelectedDates={this.filterDate.bind(this)}
                                Future ={true}
                            />
                        </Box>
                        <Box component={'div'} width={'45%'}>
                            <FormControl size='small' className='eMed_small_textFields'>
                                <InputLabel>{"Select Doctor"}</InputLabel>
                                <Select
                                    value={this.state.selectedDoctor ? this.state.selectedDoctor : ""}
                                    label={"Select Doctor"}
                                    onChange={(e) => { this.setState({ selectedDoctor: e.target.value }) }}
                                    MenuProps={{
                                        style: { maxWidth: '15vw', maxHeight: 350 }
                                    }}
                                >
                                    <MenuItem value={"All"} >{"All"}</MenuItem>
                                    {
                                        this.state.docDetails?.length > 0 ?
                                            this.state.docDetails.map((list, index) => (
                                                <MenuItem key={index} value={list}>{`${(list?.title ? list?.title : "")} ${list?.first_name} ${(list?.initial ? list?.initial : "")}`}</MenuItem>
                                            )) : null
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                    <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'}>
                        <Button
                            sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                            variant='outlined'
                            disabled={!this.state.userAccess}
                            onClick={() => this.onClearDisSumFilter()}
                        >Clear</Button>
                        <Button
                            sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                            variant='contained'
                            disabled={!this.state.userAccess}
                            onClick={() => this.getDischargeSumData()}
                        >Search</Button>
                    </Box>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error?.message)
        }
    }

    render() {
        return (
            <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'74vh'} p={'0.5vw'}>
                <Box component={'div'} display={'flex'}>
                    <Box component={'div'} className='doc_header_card' mr={'0.5vw'}>
                        <CommonPatientDetails data={this.state.patientDetails} showDetailed={true} fromDoctor={true} />
                    </Box>
                </Box>
                <Box component={'div'} display={'flex'} mt={'0.5vw'} height={'61.5vh'}>
                    <Box component={'div'} flex={0.65} overflow={'scroll'}>
                        {this.renderLeftSide()}
                    </Box>
                    <Box component={'div'} flex={0.35} border={'1px solid lightgray'}>
                        {this.renderRightSide()}
                    </Box>
                </Box>
                {this.state.printPreview ?
                    <PrintPreviewPop
                        PRINT_URL={Serviceurls.IP_DIS_SUM_PRINT + `?patient_id=${this.state.patientId}&ip_admission_id=${this.state.admissionId}&clinic_id=${this.state.clinicId}&export=pdf`}
                        Title={t("Discharge Summary")}
                        BillData={this.state.selectedRow}
                        ClosePopUp={this.ClosePopUp.bind(this)}
                        PrintButton={false}
                        history={this.props.history}
                    />
                    : null}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                <Loader loaderOpen={this.state.isLoader} />
            </Box>
        )
    }
}
export default withTranslation()(DischargeSummaryReport)
