import { Autocomplete, Box, Button, Drawer, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import '../reports.css';
import { CommonGridToolBarWithFilterText, CommonPatientDetails, CommonTimeRangePicker } from "../../../../Components/Common Components/CommonComponents";
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards'
import { ImagePaths } from '../../../../Utility/ImagePaths';
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker';
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { AmountFormat, CheckAccessFunc, formatDate, timeOnlyConvert } from "../../../../Components/CommonFunctions/CommonFunctions";
import { DateTime } from 'luxon';
import { CurrencySymbol } from '../../../../Utility/Constants';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';



class RefferalReport extends Component {

    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: 1,
            refferedReportData: [],
            // refferedReportData: null,
            refBillAmt: null,
            FilteredFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilteredToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            disDateTimePicker: null,
            patient_name_filter: "",
            filteredPatientType: null,
            filteredRefferalDetail: null,
            filteredRefferalType: null,
            patientTypeList: ["Out Patient", "In Patient"],
            refTypeData: ["Source", "Doctor", "Staff"],
            refferedType: "",
            refferalOpen: false,
            refSourceData: [],
            refStaffData: [],
            refferalValue: "",

        }
    }

    componentDidMount() {
        this.getRefferedReportData()
        // this.getDoctorNameList()
        this.getReferralDctorData()
        this.getRefSourceData()
        this.getStaffRefferalData()
        let editAccess = CheckAccessFunc("front_office", "Reports", "Referral Reports", "Referral Report", "SubTab")?.editAccess
        this.setState({ userEditAccess: editAccess })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    onChangeHandler = (e) => {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        }, () => {
            // this.state.FilterDatas.endTime = null
            this.setState({
                FilterDatas: this.state.FilterDatas
            })
        })
    }
    getRefferedReportData = () => {
        const { t } = this.props
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.FO_REPORTS_REFERRAl_DETAIL + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&patient_type=${"op,ip"}`)
                .then((response) => {
                    if (response.data.status === "success") {
                        console.log("response", response?.data?.data)
                        this.setState({
                            refferedReportData: response?.data?.data ? response?.data?.data : [],
                            refferedReportDataCount: response.data?.patient_count,
                            refBillAmt: response.data?.total_amount,
                            // FilteredFromDate: this.state.fromDate,
                            // FilteredToDate: this.state.toDate,
                        }, () => { this.LoaderFunction(false) })
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    getFilteredRefferedReportData = () => {
        const { t } = this.props
        try {
            let patientType = []
            this.LoaderFunction(true)
            if (this.state.filteredPatientType === "In Patient") {
                patientType.push("ip")
            } else if (this.state.filteredPatientType === "Out Patient") {
                patientType.push("op")
            } else {
                patientType = ["ip", "op"]
            }
            let date = this.state.disDateTimePicker ? DateTime.fromJSDate(new Date(this.state.disDateTimePicker)).toFormat('yyyy-MM-dd') : ""
            RestAPIService.getAll(Serviceurls.FO_REPORTS_REFERRAl_DETAIL + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&patient_type=${patientType}&patient_name=${this.state.patient_name_filter}&discharge_date=${date}&ref_type=${this.state.refferedType}&ref_detail=${this.state.refferalValue}`)
                .then((response) => {
                    if (response.data.status === "success") {

                        this.setState({
                            refferedReportData: response.data.data ? response.data.data : [],
                            refferedReportDataCount: response.data.patient_count,
                            refBillAmt: response.data.total_amount,
                            FilteredFromDate: this.state.fromDate,
                            FilteredToDate: this.state.toDate,
                        }, () => { this.LoaderFunction(false) })
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    getStaffRefferalData = () => {
        const { t } = this.props
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.FO_CONFIG_REF_Config).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            refStaffData: response.data.data
                        }, () => { this.LoaderFunction(false) })

                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    getReferralDctorData = () => {
        const { t } = this.props
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.FO_CONFIG_REF_DOCTOR).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            refDoctorData: response.data.data
                        }, () => { this.LoaderFunction(false) })

                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    getRefSourceData = () => {
        const { t } = this.props
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.FO_CONFIG_REF_SOURCE).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            refSourceData: response.data.data
                        }, () => { this.LoaderFunction(false) })

                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }
    printReports = () => {
        try {
            let patientType = []
            this.LoaderFunction(true)
            if (this.state.filteredPatientType === "In Patient") {
                patientType.push("ip")
            } else if (this.state.filteredPatientType === "Out Patient") {
                patientType.push("op")
            } else {
                patientType = ["ip", "op"]
            }
            let date = this.state.disDateTimePicker ? DateTime.fromJSDate(new Date(this.state.disDateTimePicker)).toFormat('yyyy-MM-dd') : ""
            RestAPIService.getAll(Serviceurls.FO_REPORTS_REFERRAl_DETAIL + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&patient_type=${patientType}&patient_name=${this.state.patient_name_filter}&discharge_date=${date}&ref_type=${this.state.refferedType}&ref_detail=${this.state.refferalValue}&export_type=pdf`)
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }
    renderFilterScreen = () => {
        const { t } = this.props
        let states = this.state
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
                    <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)}
                            HideAllMenu={true} />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <TextField
                            size='small'
                            variant="outlined"
                            // disabled={true}
                            label="Patient Name"
                            sx={{ width: "20vw" }}
                            inputProps={{ maxLength: 100 }}
                            value={this.state.patient_name_filter}
                            onChange={(event) => {
                                this.setState({
                                    patient_name_filter: event.target.value
                                })
                            }}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            size='small'
                            clearIcon={false}
                            options={this.state.patientTypeList}
                            // getOptionLabel={(option) => (option)}
                            getOptionLabel={(option) => typeof (option) === 'string' ? option : option}
                            value={this.state.filteredPatientType}
                            onChange={(event, newValue) => {
                                this.setState({ filteredPatientType: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('Patient Type')}
                                />
                            )}
                        />
                    </Box>

                    {/* discharge date */}


                    <FormControl size='small' sx={{ width: '20vw', ml: "1vw" }}>
                        <InputLabel id="referredby-select-label">{"Referred Type"}</InputLabel>
                        <Select
                            labelId="referredby-select-label"
                            MenuProps={{ style: { maxHeight: 250 } }}
                            size='small'
                            value={states.refferedType}
                            label={"Referred Type"}
                            onChange={(e) => {
                                this.setState({
                                    refferedType: e.target.value,
                                    refferalOpen: true
                                }
                                )
                            }}
                        >
                            {states.refTypeData?.map((item) => (
                                <MenuItem value={item}>{item}</MenuItem>
                            ))}

                        </Select>
                    </FormControl>

                    <FormControl size='small' sx={{ width: '20vw', margin: "1vw 0vw 0.5vw 1vw" }}>
                        <InputLabel id="referredby-select-label">{this.state.refferedType === "Source" ? "Referred Source" : this.state.refferedType === "Doctor" ? "Referred Doctor" : this.state.refferedType === "Staff" ? "Referred Staff" : "Refferal Detail"}</InputLabel>
                        <Select
                            open={this.state.openPaper || this.state.refferalOpen ? true : false}
                            onOpen={() => {
                                if (this.state.refferalOpen) { return true }
                                this.setState({ openPaper: true })
                            }}
                            onClose={() => {
                                if (this.state.refferalOpen) { return false }
                                this.setState({ openPaper: false })
                            }}
                            labelId="referredby-select-label"
                            MenuProps={{ style: { maxHeight: 250 } }}
                            size='small'
                            disabled={!states.refferedType}
                            value={states.refferalValue}
                            label={this.state.refferedType === "Source" ? "Referred Source" : this.state.refferedType === "Doctor" ? "Doctor" : this.state.refferedType === "Staff" ? "Referred Staff" : "Refferal Detail"}
                            onChange={(e) => {
                                this.setState({
                                    refferalValue: e.target.value,
                                    refferalOpen: false
                                })
                            }}
                        >
                            {/* <MenuItem>{this.state.refferedType === "Source" ? "Referred Source":this.state.refferedType === "Doctor" ? "Referred Doctor": "Referred Staff"}</MenuItem> */}
                            {
                                this.state.refferedType === "Doctor" ? states.refDoctorData?.map((item, index) => (
                                    <MenuItem value={item.name}>{item.name}</MenuItem>
                                )) : this.state.refferedType === "Source" ? states.refSourceData?.map((item, index) => (
                                    <MenuItem value={item.source_name}>{item.source_name}</MenuItem>
                                )) : this.state.refferedType === "Staff" ? states.refStaffData?.map((item, index) => (
                                    <MenuItem value={item?.id}>{item?.name}</MenuItem>
                                )) : null
                            }

                        </Select>
                    </FormControl>


                    <Box className='eMed_rts_dropdown'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                value={this.state.disDateTimePicker}
                                name={"datePicker"}
                                // readOnly={(states.isFinish === true || fromBillSummary) ? true : false}

                                inputFormat='DD-MM-YYYY'
                                label={t('Discharge Date')}
                                onChange={
                                    (newDate) => {
                                        if (newDate === null) {
                                            this.setState({
                                                disDateTimePicker: null,
                                                // DischargeTime: null,
                                            });
                                        } else {
                                            this.setState({
                                                disDateTimePicker: newDate,
                                            });
                                        }
                                    }}
                                renderInput={(params) => <TextField sx={{ width: "20vw" }} size='small' {...params} />}
                            />
                        </LocalizationProvider>
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small" disabled={!this.state.userEditAccess}
                        onClick={() => {
                            this.setState({
                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                dateIndex: 1,
                                refferalValue: "",
                                refferedType: "",
                                patient_name_filter: "",
                                filteredPatientType: null,
                                filteredRefferalDetail: null,
                                filteredRefferalType: null,
                                disDateTimePicker: null,
                                FilteredFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                FilteredToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                            },
                                // () => this.getRefferedReportData()
                            )
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small" disabled={!this.state.userEditAccess}
                        // disabled={this.state.FilterDatas.startTime ? (this.state.FilterDatas.startTime && this.state.FilterDatas.endTime && this.state.timeError != true) ? false : true : false}
                        onClick={() => {
                            this.getFilteredRefferedReportData()
                            this.setState({ FilterOpen: false })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }
    gridToolBar = () => {
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.FilteredFromDate) },
            { label: "To Date", value: formatDate(this.state.FilteredToDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
            </Box>

        )
    }
    render() {
        const { t } = this.props
        this.state.refferedReportData?.forEach((element, index) => element.sno = index + 1)
        const columns = [

            {
                field: "patient_names", headerName: t("Patient Details"), flex: 0.182,
                renderCell: (params) => (<CommonPatientDetails data={params?.row} showDetailed={false} />)
            },
            {
                field: "patient_type", headerName: t("Patient Type"), flex: 0.142,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.patient_type ? params?.row?.patient_type?.length > 30 ?
                    <Tooltip placement='top' title={params?.row?.patient_type} arrow><div>{params?.row?.patient_type.slice(0, 30) + "..."}</div></Tooltip> : params?.row?.patient_type : "-"}</Box>)
            },
            {
                field: "discharge_date", headerName: t("Discharge Date"), flex: 0.142, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.discharge_date ? params?.row?.discharge_date : "-"}</Box>)
            },
            {
                field: "refferal_type", headerName: t("Reffered Type"), flex: 0.142,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.refferal_type ? params?.row?.refferal_type?.length > 30 ?
                    <Tooltip placement='top' title={params?.row?.refferal_type} arrow><div>{params?.row?.refferal_type.slice(0, 30) + "..."}</div></Tooltip> : params?.row?.refferal_type : "-"}</Box>)
            },
            // {
            //     field: "refferal_count", headerName: t("Refferal count"), flex: 0.142, type: "number",
            //     renderCell: (params) => (<Box component={'div'}>{params?.row?.patient_count ? params?.row?.patient_count : "-"}</Box>)
            // },
            {
                field: "referral_details", headerName: t("Refferal Details"), flex: 0.142,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.referral_details ? params?.row?.referral_details?.length > 30 ?
                    <Tooltip placement='top' title={params?.row?.referral_details} arrow><div>{params?.row?.referral_details.slice(0, 30) + "..."}</div></Tooltip> : params?.row?.referral_details : "-"}</Box>)
            },

            {
                field: "commission", headerName: `${t("Commission")} (${CurrencySymbol})`, flex: 0.102, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.commission ? AmountFormat(params?.row?.commission).replace(`${CurrencySymbol}`, "") : "0.00"}</Box>)
            },
            {
                field: "total_amount", headerName: `${t("Amount Details")} (${CurrencySymbol})`, flex: 0.142, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.total_amount ? AmountFormat(params?.row?.total_amount).replace(`${CurrencySymbol}`, "") : "0.00"}</Box>)
            }
        ]
        return (
            <Box component={'div'} className='eMed_rts_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'>
                        {AmountsCard(t("TotalAmount"), this.state.refBillAmt)}
                        {AmountsCard(t("TotalPatients"), this.state.refferedReportDataCount, true)}
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container'>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </Button>
                        </Box>
                        <Button className='eMed_usrconf_btn' onClick={() => { this.printReports() }}>
                            <img src={this.state.userEditAccess ? ImagePaths.LabPrintIcon.default : ImagePaths.LabPrintIconOff.default} alt="print" className='eMed_action_img' />
                        </Button>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_rts_table'>
                    <DataGrid
                        rows={this.state.refferedReportData}
                        columns={columns}
                        getRowId={(row) => row['sno']}
                        page={this.state.page}
                        loading={this.state.isLoader}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""

                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                    />
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }
}


export default withTranslation()(RefferalReport)