import { Box, Button, Checkbox, FormControlLabel, Grid, Paper, Stack, Tooltip, Typography } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import CommonGridHeader, { CommonEditButton } from '../../../Components/Common Components/CommonComponents';
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';
import { DataGrid } from '@mui/x-data-grid';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { Colors } from '../../../Styles/Colors';
import Loader from '../../../Components/Loader'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { BILL_LAYOUT_LIST } from '../../../Utility/Constants';

const BillLayoutConfig = () => {
    const [layoutList, setLayoutList] = useState([]);
    const [rightData, setRightData] = useState({});
    const [rightDataList, setRightDataList] = useState([]);
    const [isLoader, setIsLoader] = useState(false)
    const [errorData, setErrorData] = useState({});
    const [SuccessData, setSuccessData] = useState({});
    const dragItem = useRef(null);
    const dragOverItem = useRef(null);

    useEffect(() => {
        getLayoutlist()
    }, [])

    const getLayoutlist = () => {
        try{
            setIsLoader(true);
            RestAPIService.getAllTwo(Serviceurls.BILL_LAYOUT_CONFIG + "?module=")
            .then(response => {
                if (response?.data?.status === 'success') {
                    const list = (response?.data?.data).length > 0 ? response.data.data : BILL_LAYOUT_LIST
                    setLayoutList(list)
                    setIsLoader(false);
                }
            }).catch(e => {
                setIsLoader(false)
                if (e?.response?.data?.status === 'fail') {
                errorMessage(e.response.data.message)
                } else {
                errorMessage(e.message)
                }
            })
        }catch(error){
            errorMessage(error)
        }
    }

    const postLayout = () => {
        try{
            const data = {
                id: rightData?.id,
                module: rightData?.module,
                layout_detail: rightDataList
            }
            RestAPIService.create(data,Serviceurls.BILL_LAYOUT_CONFIG)
            .then(response => {
                if (response?.data?.status === 'success') {
                    successMessage(response.data.message)
                    setIsLoader(false);
                }
            }).catch(e => {
                setIsLoader(false)
                if (e?.response?.data?.status === 'fail') {
                errorMessage(e.response.data.message)
                } else {
                errorMessage(e.message)
                }
            })
        }catch(error) {
            errorMessage(error)
            setIsLoader(false);
        }
    }

    const renderLeft = () => {
        try {
            layoutList.forEach((element, index) => { element.sno = index + 1 })
            const Column = [
                { field: "sno", sortable: false, flex: 0.10, headerName: "S.No" },
                {
                    field: "module", flex: 0.30, headerName: "Layout Section",
                    renderCell: (params) => (<Box component={'div'}>{(params?.row?.module ? params.row.module : "-")}</Box>)
                },
                {
                    field: "modified_by", flex: 0.40, headerName: "Modified By",
                    renderCell: (params) =>
                        <Box component={'div'}>
                            {(params?.row?.modified_by + params?.row?.modified_date).length > 30 ?
                                <Tooltip title={params?.row?.modified_by + ' On ' + params?.row?.modified_dates} placement='top' arrow>
                                    <div>{params.row.modified_by && params.row.modified_date ? (params.row.modified_by + " On " + params.row.modified_date).slice(0, 30) + '...' : '-'}</div>
                                </Tooltip>
                                : params?.row?.modified_by && params?.row?.modified_date ? (params.row.modified_by + " On " + params.row.modified_dates) : '-'}
                        </Box>
                },
                {
                    field: "action", flex: 0.20, headerName: "Action", headerAlign: "center", align: "center", sortable: false, hideable: false,
                    renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                        <CommonEditButton disable={!CheckAccessFunc("front_office", "Settings", "Layout Configuration", "Billing Layout", "SubTab")?.editAccess} size={"1.5vw"} onClick={() => onEditLeft(params?.row)} />
                    </Box>
                }
            ]
            return (
                <Stack component={"div"}>
                    <Paper className='eMed_common_Tbl_left' >
                        <DataGrid
                            rows={layoutList}
                            columns={Column}
                            getRowId={(row) => row['sno']}
                            hideFooterSelectedRowCount
                            hideFooter
                            localeText={{
                                toolbarColumns: "",
                                toolbarDensity: "",
                                toolbarExport: "",
                                toolbarFilters: "",
                                toolbarExportPrint: "",
                            }}
                            headerHeight={40}
                            // density='compact'
                            components={{
                                Toolbar: CommonGridHeader.CommonToolBar,
                                NoRowsOverlay: () => (
                                    <Stack className='eMed_conf_nodata'>
                                        {"No Records Found"}
                                    </Stack>
                                )
                            }}
                            disableSelectionOnClick
                            loading={isLoader}
                        />
                    </Paper>
                </Stack>
            )
        } catch(error) {
            errorMessage(error)
        }
    }

    const onEditLeft = (row) => {
        setRightData(row)
        setRightDataList(row.layout_detail)

    }

    const clearRight = () => {
        setRightData({})
        setRightDataList([])
    }

    const renderRight = () => {
        return (
            <Stack component={"div"}>
                <Paper className='eMed_common_Tbl_right' elevation={2}>
                    <Typography className='eMed_refdoc_title'>{Object.keys(rightData).length > 0 ? rightData.module + " Billing Layout Configuration" : ""}</Typography>
                    <Box component={"div"} className='eMed_Print_Option_Con' overflow={'scroll'}>
                        {(rightDataList).map((item, index) => {
                            return (
                                renderRightList(item, index)
                            )
                        })}
                    </Box>
                    <Stack className='eMed_DisSum_right_Btn' spacing={2} direction="row" sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center" height={"6vh"}>
                        <Button variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { clearRight() }}>{"Clear"}</Button>
                        <Button variant='contained' size="small" id="eMed_Config_btn" disabled={CheckAccessFunc("front_office", "Settings", "Layout Configuration", "Billing Layout", "SubTab")?.editAccess ? (Object.keys(rightData).length == 0 ? true : false) : true} onClick={() => { postLayout(); setIsLoader(true) }}>{"Save"}</Button>
                    </Stack>
                </Paper>
            </Stack>
        )
    }

    const handleDragStart = (e, index) => {
        e.dataTransfer.setData('index', index);
    };

    const handleDrop = (e, dropIndex) => {
        const draggedIndex = e.dataTransfer.getData('index');
        const draggedItem = rightDataList[draggedIndex];

        const updatedSections = [...rightDataList];
        updatedSections.splice(draggedIndex, 1);
        updatedSections.splice(dropIndex, 0, draggedItem);

        const reorderedSections = updatedSections.map((section, index) => ({
            ...section,
            order: index + 1
        }));

        setRightDataList(reorderedSections);
        dragItem.current = null;
        dragOverItem.current = null;
    };

    const handleCheckClick = (i) => {
        const updatedSections = [...rightDataList];
        const section = updatedSections[i].is_active;
        const activeCount = updatedSections.filter(s => s.is_active).length;
        if (!section && activeCount >= 3) {
            errorMessage("You can only have a maximum of 3 active labels")
        }else if(section && activeCount <= 1){
            errorMessage("You should select minimum one active label")
        }else {
            updatedSections[i].is_active = !updatedSections[i].is_active;
            setRightDataList(updatedSections);
        }
    }
    const renderRightList = (data, index) => {
        return (
            <div
                draggable
                onDragOver={(e) => {
                    e.preventDefault();
                    dragOverItem.current = index;
                }}
                onDragStart={(e) => handleDragStart(e, index)}
                onDrop={(e) => handleDrop(e, index)}
                id={data.value}
                className="eMed_demolayout_right2_field">
                <img className="eMed_demolayout_right2_field_img" src={ImagePaths.DragIndicator.default} alt="" />
                <FormControlLabel control={<Checkbox id={data.value} onChange={() => handleCheckClick(index)} checked={data.is_active} />} label={data.label} />
            </div>
        )
    }

    const errorMessage = (message) => {
        setErrorData({ isError: true, errNote: message })
        setSuccessData({})
    }

    const successMessage = (message) => {
        setSuccessData({ isSuccess: true, succNote: message })
        setErrorData({})
    }

    const msgClose = () => {
        setErrorData({});
        setSuccessData({});
    }

    return (
        <Box component={"div"} className='eMed_usrconf_container'>
            <Grid container className='eMed_refdoc_content' >
                <Grid item xs={7.5}>
                    {renderLeft()}
                </Grid>
                <Grid item xs={4.5}>
                    {renderRight()}
                </Grid>
            </Grid>
            {Object.keys(errorData).length > 0 ?
                <ToastMsg
                    severity={'error'}
                    msg={errorData.errNote}
                    msgPop={() => msgClose()}
                />
                : null}
            {Object.keys(SuccessData).length > 0 ?
                <ToastMsg
                    severity={'success'}
                    msg={SuccessData.succNote}
                    msgPop={() => msgClose()}
                />
                : null}
            <Loader loaderOpen={isLoader} />
        </Box>
    )
}

export default BillLayoutConfig;