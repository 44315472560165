import React, { useState, useEffect } from 'react';
import { Box, Button, Drawer, Stack, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { withTranslation } from 'react-i18next';
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker';
import { CommonGridToolBarWithFilterTextCustom } from '../../../../Components/Common Components/CommonComponents';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DateTime } from 'luxon';
import { formatDate, AmountFormat, splitDate } from '../../../../Components/CommonFunctions/CommonFunctions';
import { Colors } from '../../../../Styles/Colors';
import { CurrencySymbol } from '../../../../Utility/Constants';

const OTSalesTaxWise = (props) => {
    const [state, setState] = useState({
        salesTaxWiseData: [],
        page: 0,
        pageSize: 10,
        filterDatas: {
            'fromDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            'toDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            "dateIndex": 1,
            "days": 30,
        },
        filterOpen: false,
        gstAmt: 0,
        netAmount: 0,
        taxableAmount: 0,
        isLoader: false,
        disableBtn: false,
        isErrorMsg: false,
        isErrorMsgText: "",
        triggerAction: null,
    })

    useEffect(() => {
        getsalesTaxWiseData();
    }, []);

    useEffect(() => {
        if (state.triggerAction) {
            getsalesTaxWiseData();
            setState((prevState) => ({
                ...prevState,
                triggerAction: null
            }))
        }
    }, [state.triggerAction])

    const generateUrl = () => {
        const { filterDatas } = state;

        return Serviceurls.OT_SALES_TAX_WISE + `?from_date=${filterDatas?.fromDate}&to_date=${filterDatas?.toDate}`
    }

    const getsalesTaxWiseData = () => {
        try {
            LoaderFunction(true);
            const url = generateUrl();
            RestAPIService.getAll(url)
                .then((response) => {
                    if (response.data.status === 'success') {
                        LoaderFunction(false);
                        const Data = response?.data?.data;
                        Data?.result?.forEach((element, index) => {
                            element.sno = index + 1;
                        })
                        setState((prevState) => ({
                            ...prevState,
                            salesTaxWiseData: response?.data?.data || [],
                            netAmount: response?.data?.total_net_amount || 0,
                            taxableAmount: response?.data?.total_taxable_amount || 0,
                            gstAmt: response?.data?.total_gst_amount || 0,
                        }))
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        LoaderFunction(false);
                        errorMessage(error.response.data.message)
                    } else {
                        LoaderFunction(false);
                        errorMessage(error.message)
                    }
                })
        } catch (e) {
            errorMessage(e.message)
            LoaderFunction(false)
        }
    }

    const getsalesTaxWisePrint = () => {
        try {
            setDisableBtn(true);
            const url = generateUrl();
            RestAPIService.getAll(`${url}&export_type=pdf`)
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    setDisableBtn(false);
                }).catch((error) => {
                    setDisableBtn(false);
                    if (error?.response?.data?.message) {
                        errorMessage(error?.response?.data?.message);
                    } else {
                        errorMessage(error?.message);
                    }
                })
        } catch (error) {
            errorMessage(error.message)
            setDisableBtn(false);
        }
    }

    const getsalesTaxWiseExcel = () => {
        try {
            setDisableBtn(true);
            const url = generateUrl();
            RestAPIService.excelGet(`${url}&export_type=excel`)
                .then((response) => {
                    if (response.data) {
                        const pom = document.createElement('a');
                        const csvContent = response.data; //here we load our csv data 

                        const filename = response.headers["content-disposition"].split("filename=")[1]

                        const blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' });
                        const url = URL.createObjectURL(blob);
                        pom.href = url;
                        pom.setAttribute('download', filename ? filename : 'registry.xlsx');
                        pom.click();
                        setDisableBtn(false);
                    }
                }).catch((error) => {
                    if (error.response?.data?.message) {
                        errorMessage(error.response?.data?.message);
                    } else {
                        errorMessage(error.message);
                    }
                    setDisableBtn(false);
                })
        } catch (error) {
            errorMessage(error.message);
            setDisableBtn(false);
        }
    }

    const setDisableBtn = (key) => {
        setState((prevState) => ({
            ...prevState,
            disableBtn: key
        }))
    }

    const LoaderFunction = (key) => {
        setState((prevState) => ({
            ...prevState,
            isLoader: key
        }))
    }

    const errorMessage = (message) => {
        setState((prevState) => ({
            ...prevState,
            isErrorMsg: true,
            isErrorMsgText: message
        }))
    }

    const msgClose = () => {
        setState((prevState) => ({
            ...prevState,
            isErrorMsg: false,
            isErrorMsgText: ""
        }))
    }

    const gridToolBar = () => {
        const TextArray = [
            { label: "From Date", value: formatDate(state.filterDatas.fromDate) },
            { label: "To Date", value: formatDate(state.filterDatas.toDate) },
        ]
        const ExportData = state.salesTaxWiseData?.length > 0 && state.salesTaxWiseData?.map((item) => ({
            "S No": item.s_no,
            "GST %": item.gst_percentage,
            "Taxable Amount": item.taxable_amount,
            "SGST %": item.sgst_percentage,
            "SGST Amount": item.sgst_amount,
            "CGST %": item.cgst_percentage,
            "CGST Amount": item.cgst_amount,
            "Total Amount": item.total_value,
        }))

        return (
            <Box>
                <CommonGridToolBarWithFilterTextCustom FilterTextArray={TextArray} data={ExportData} filename={"Sales Bill Tax Wise"} title={"Sales Bill Tax Wise"} />
            </Box>
        )
    }

    const closeFilter = (fromSrch) => {
        setState((prevState) => ({
            ...prevState,
            filterOpen: false,
            triggerAction: fromSrch ? "search" : null
        }))
    }

    const FilterDate = (from, to, index) => {
        setState((prevState) => ({
            ...prevState,
            filterDatas: {
                ...prevState.filterDatas,
                fromDate: from,
                toDate: to,
                dateIndex: index
            }
        }))
    }

    const renderFilterScreen = () => {
        const { filterDatas } = state;
        const { t } = props;

        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
                    <Button onClick={() => { closeFilter() }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={filterDatas?.fromDate}
                            ToDate={filterDatas?.toDate}
                            DateIndex={filterDatas?.dateIndex}
                            SelectedDates={FilterDate.bind(this)}
                            HideAllMenu={true} />
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"
                        onClick={() => {
                            setState((prevState) => ({
                                ...prevState,
                                filterDatas: {
                                    ...prevState.filterDatas,
                                    fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                    toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                    dateIndex: 1,
                                },
                                triggerAction: "clear"
                            }));
                        }}
                        disabled={state.triggerAction === "clear"}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small"
                        disabled={state.triggerAction === "search"}
                        onClick={() => { closeFilter(true) }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    const { t } = props;

    const cardList = [
        { label: "Taxable Amount", amount: state.taxableAmount, id: 'ot_rpt_salebilltax_tax_amt' },
        { label: "Total GST Amount", amount: state.gstAmt, id: 'ot_rpt_salebilltax_cgst' },
        { label: "Total Amount", amount: state.netAmount, id: 'ot_rpt_salebilltax_sgst' },
    ];

    const columns = [
        {
            field: 'gst_percentage', headerName: "GST %", flex: 0.1, type: "number",
            renderCell: ({ row }) => (<Box component={'div'}>{row?.gst_percentage ? row?.gst_percentage : '0'}</Box>)
        },
        {
            field: 'taxable_amount', headerName: `${"Taxable Amount"} (${CurrencySymbol})`, flex: 0.1, type: "number",
            renderCell: ({ row }) => (<Box component={'div'}>{row?.taxable_amount ? AmountFormat(row?.taxable_amount).replace(`${CurrencySymbol}`, "") : "0.00"}</Box>)
        },
        {
            field: 'sgst_percentage', headerName: "SGST %", flex: 0.1, type: "number",
            renderCell: ({ row }) => (<Box component={'div'}>{row?.sgst_percentage ? row?.sgst_percentage : "0"}</Box>)
        },
        {
            field: 'sgst_amount', headerName: `${"SGST Amount"} (${CurrencySymbol})`, flex: 0.1, type: "number",
            renderCell: ({ row }) => (<Box component={'div'}>{row?.sgst_amount ? AmountFormat(row?.sgst_amount).replace(`${CurrencySymbol}`, "") : "0.00"}</Box>)
        },
        {
            field: 'cgst_percentage', headerName: "CGST %", flex: 0.1, type: "number",
            renderCell: ({ row }) => (<Box component={'div'}>{row?.cgst_percentage ? row?.cgst_percentage : "0"}</Box>)
        },
        {
            field: 'cgst_amount', headerName: `${"CGST Amount"} (${CurrencySymbol})`, flex: 0.1, type: "number",
            renderCell: ({ row }) => (<Box component={'div'}>{row?.cgst_amount ? AmountFormat(row?.cgst_amount).replace(`${CurrencySymbol}`, "") : "0.00"}</Box>)
        },
        {
            field: 'total', headerName: `${"Total Amount"} (${CurrencySymbol})`, flex: 0.1, type: "number",
            renderCell: ({ row }) => (<Box component={'div'}>{row?.total ? AmountFormat(row?.total).replace(`${CurrencySymbol}`, "") : "0.00"}</Box>)
        }
    ]
    return (
        <Box component={'div'} className='eMed_rts_container'>
            <Box component={"div"} className='eMed_Pharma_rts_header'>
                <Box component={"div"} flex={0.8} display={'flex'} >
                    {cardList?.map(({ label, amount, id }) => (
                        <div
                            key={id}
                        >
                            {AmountsCard(label, amount, false, "white", false, Colors.Primary, id, "10dvw")}
                        </div>
                    ))}
                </Box>
                <Box component={"div"} flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5dvw'}>
                    <Button emed_tid="ot_rpt_salebilltax_flt" className='eMed_usrconf_btn' onClick={() => { setState((prevState) => ({ ...prevState, filterOpen: true })) }}>
                        <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                    </Button>
                    <Button emed_tid="ot_rpt_saleOver_print" className='eMed_usrconf_btn' disabled={state.disableBtn} onClick={() => getsalesTaxWisePrint()}>
                        <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                    </Button>
                    <Button emed_tid="ot_rpt_salebilltax_export" className='eMed_Pharma_rts_btn' disabled={state.disableBtn} onClick={() => getsalesTaxWiseExcel()}>
                        <img src={ImagePaths.ExportIcon.default} alt='export' className='eMed_action_img' />
                    </Button>
                </Box>
            </Box>
            <Box component={'div'} className='eMed_Pharma_rts_tbl_card'>
                <DataGrid
                    className='eMed_Pharma_table'
                    rows={state.salesTaxWiseData}
                    columns={columns}
                    getRowId={(row) => row.s_no}
                    page={state.page}
                    pageSize={state.pageSize}
                    localeText={{
                        toolbarColumns: "",
                        toolbarDensity: "",
                        toolbarExport: "",
                        toolbarFilters: "",
                        toolbarExportPrint: ""
                    }}
                    components={{
                        Toolbar: gridToolBar,
                        NoRowsOverlay: () => (
                            <Stack className='eMed_conf_nodata'>
                                {t("NoRecordsFound")}
                            </Stack>
                        )
                    }}
                    rowCount={state?.rowCount}
                    onPageChange={(newPage) => { setState((prevState) => ({ ...prevState, page: newPage })) }}
                    onPageSizeChange={(newPageSize) => setState((prevState) => ({ ...prevState, pageSize: newPageSize }))}
                    rowsPerPageOptions={[10, 20, 30]}
                    pagination
                    headerHeight={40}
                    disableSelectionOnClick
                    loading={state.isLoader}
                />
            </Box>
            <Drawer
                anchor={'right'}
                open={state.filterOpen}
                ModalProps={{ onBackdropClick: closeFilter }}
            >
                {renderFilterScreen()}
            </Drawer>
            {state.isErrorMsg && <ToastMsg
                severity={'error'}
                msg={state.isErrorMsgText}
                msgPop={msgClose.bind(this)}
            />}
        </Box>
    )
}

export default withTranslation()(OTSalesTaxWise)