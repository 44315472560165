import React, { Component } from 'react'
import '../Canteen.css'
import { Box, Button, Paper, Tooltip, Stack, Typography, Drawer, TextField, FormControlLabel, Checkbox, IconButton, Menu, MenuItem } from '@mui/material';
import { Colors } from '../../../Styles/Colors';
import { AmountsCard } from '../../../Components/CommonCards/CommonCards';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { CommonGridToolBarWithFilterText } from '../../../Components/Common Components/CommonComponents';
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker';
import { formatDate } from '../../../Components/CommonFunctions/CommonFunctions';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { setCachevalue } from '../../../Utility/Services/CacheProviderService';
import { AmountFormat } from '../../../Components/CommonFunctions/CommonFunctions';
import { CurrencySymbol } from '../../../Utility/Constants';

class CanteenOverallBill extends Component {
  constructor(props) {
    super(props)
    this.state = {
      overallBillData: [],
      noofBills: '',
      totalAmt: '',
      page: 0,
      pageSize: 10,
      isLoader: false,
      filterOpen: false,
      fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      dateIndex: 1,
      billFrom: '',
      billTo: '',
      isCompleted: true,
      isCancelled: true,
      isCredit: true,
      bill_Type: ['completed', 'cancelled', 'credit'],
      isErrorMsg: false,
      isErrorMsgText: '',
    }
  }

  componentDidMount() {
    this.getCanteenOverallBill()
  }

  getCanteenOverallBill = () => {
    try {
      let states = this.state
      this.LoaderFunction(true)
      RestAPIService.getAll(Serviceurls.CANTEEN_OVERALL + `?from_date=${states.fromDate}&to_date=${states.toDate}&bill_status=${states.bill_Type}&bill_no_from=${states.billFrom}&bill_no_to=${states.billTo}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.LoaderFunction(false)
            this.setState({
              overallBillData: response.data.data,
              noofBills: response.data?.no_of_bills,
              totalAmt: response.data?.total_amount,
            })
          }
        }).catch((e) => {
          this.LoaderFunction(false)
          if (e?.response?.data?.status === 'fail') {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })
    } catch (e) {
      this.LoaderFunction(false)
      this.errorMessage(e.message)
    }
  }

  PrintOverallBill(id) {
    try {
      RestAPIService.PrintPDF(Serviceurls.CANTEEN_BILLING + `?id=${id ? id : null}&export_type=pdf`).
        then((response) => {
          if (response) {
            //Create a Blob from the PDF Stream
            const file = new Blob(
              [response.data],
              { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  errorMessage = (msg) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: msg
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  gridToolBar = () => {
    let TextArray = [
      { label: "From Date", value: formatDate(this.state.fromDate) },
      { label: "To Date", value: formatDate(this.state.toDate) },
    ]
    return (
      <Box>
        <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
      </Box>

    )
  }
  FilterDate = (from, to, index) => {
    this.setState({
      fromDate: from,
      toDate: to,
      dateIndex: index
    })
  }
  clearFilter = () => {
    this.setState({
      filterOpen: false,
      fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      dateIndex: 1,
      billFrom: '',
      billTo: '',
      isCompleted: true,
      isCancelled: true,
      isCredit: true,
      bill_Type: ['completed', 'cancelled', 'credit'],
    }, () => {
      this.getCanteenOverallBill()
    })
  }
  searchFilter = () => {
    let states = this.state
    if (states.billFrom !== '') {
      if (states.billTo !== '' && states.billFrom <= states.billTo) {
        this.getCanteenOverallBill()
        this.setState({ filterOpen: false, });
      } else {
        if (states.billTo === '') {
          this.errorMessage("Please Enter Bill To Number");
        } else {
          this.errorMessage("Bill To No is Greater than Bill From No");
        }
      }
    } else if (states.billTo !== '') {
      if (states.billFrom !== '' && states.billFrom <= states.billTo) {
        this.getCanteenOverallBill()
        this.setState({ filterOpen: false, });
      } else {
        if (states.billFrom === '') {
          this.errorMessage("Please Enter Bill From Number");
        } else {
          this.errorMessage("Bill To No is Greater than Bill From No");
        }
      }
    } else {
      this.getCanteenOverallBill()
      this.setState({ filterOpen: false, });
    }
  }
  renderFilterScreen = () => {
    return (
      <Box className='eMed_Canteen_FilterBox' >
        <Box className='eMed_CanteenFilter_Head'>
          <Typography variant='h6' fontWeight={600}>Filter</Typography>
          <Tooltip>
            <Button onClick={() => { this.setState({ filterOpen: false }) }}>
              <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_Canteen_CloseImg' />
            </Button>
          </Tooltip>
        </Box>
        <Box className='eMed_Canteen_fil_content'>
          <Box className='eMed_Canteen_Date'>
            <CommonDatePicker
              FromDate={this.state.fromDate}
              ToDate={this.state.toDate}
              DateIndex={this.state.dateIndex}
              SelectedDates={this.FilterDate.bind(this)}
              HideAllMenu={true} />
          </Box>
          <Box className='eMed_Canteen_fil_text'>
            <TextField
              inputProps={{ maxLength: 10 }}
              className="eMed_Bill_Filter_TextFeild_small"
              sx={{ marginRight: '1vw' }}
              size='small'
              label={"Bill No From"}
              value={this.state.billFrom}
              autoComplete='off'
              onChange={(e) => {
                let value = e.target.value
                if (CommonValidation.numberOnly(value) || value === '') {
                  this.setState({
                    billFrom: value
                  })
                }
              }}
            />
            <TextField
              inputProps={{ maxLength: 10 }}
              className="eMed_Bill_Filter_TextFeild_small"
              size='small'
              label={"Bill No To"}
              value={this.state.billTo}
              onChange={(e) => {
                let value = e.target.value
                if (CommonValidation.numberOnly(value) || value === '') {
                  this.setState({
                    billTo: value
                  })
                }
              }}
            />
          </Box>
          <Box marginTop={'1.5vw'}>
            <Box className="eMed_Filter_Header">
              <Typography marginLeft={"1vw"}>{"Status"}</Typography>
            </Box>
            <Box className="eMed_CheckBox_Group">
              <FormControlLabel className='eMed_Canteen_CheckBoxDiv' control={<Checkbox checked={this.state.isCompleted} onChange={(e) => { this.handleCheckBox(e, "isCompleted",) }} size='small' />} label={"Completed"} />
              <FormControlLabel className='eMed_Canteen_CheckBoxDiv' control={<Checkbox checked={this.state.isCancelled} onChange={(e) => { this.handleCheckBox(e, "isCancelled",) }} size='small' />} label={"Cancelled"} />
              <FormControlLabel className='eMed_Canteen_CheckBoxDiv' control={<Checkbox checked={this.state.isCredit} onChange={(e) => { this.handleCheckBox(e, "isCredit",) }} size='small' />} label={"Credit"} />
            </Box>
          </Box>
        </Box>
        <Box>
          <Stack spacing={2} direction="row" justifyContent={'center'}>
            <Button size='small' variant='outlined' className='eMed_Canteen_BtnText' onClick={() => { this.clearFilter() }}>Clear</Button>
            <Button size='small' variant='contained' disabled={this.state.bill_Type.length === 0} className='eMed_Canteen_BtnText' onClick={() => { this.searchFilter() }}>Search</Button>
          </Stack>
        </Box>
      </Box >
    )
  }

  handleCheckBox = (e, key) => {
    this.state[key] = e.target.checked
    let billType = []
    if (this.state.isCompleted) {
      billType.push('completed')
    }
    if (this.state.isCancelled) {
      billType.push('cancelled')
    }
    if (this.state.isCredit) {
      billType.push('credit')
    }
    this.state.bill_Type = billType
    this.setState({
      isCompleted: this.state.isCompleted,
      isCancelled: this.state.isCancelled,
      isCredit: this.state.isCredit,
      bill_Type: this.state.bill_Type
    })
  }
  MenuItem = (data) => {
    const { t } = this.props
    const multi_option = [
      { value: "CancelBill", label: "Cancel Bill" },
    ]
    return (
      <div>
        <Tooltip title={t("More")} placement='top' arrow>
          <IconButton
            disabled={data?.row?.bill_type === 'cancelled'}
            onClick={(e) => {
              this.setState({
                anchorEl: e.currentTarget,
                selectedRow: data.id,
                isSelected: true
              })
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        {(this.state.isSelected && this.state.selectedRow == data.id) ?
          <Menu
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={() => {
              this.setState({ anchorEl: null, })
            }}>
            {multi_option.map((option) => (
              <MenuItem key={option} onClick={() => {
                this.setState({ anchorEl: null }, () => {
                  setCachevalue(true, 'isCanteenOverall')
                  this.props.history.push({ pathname: "/CanteenHome", state: { Patient_ID: data?.row?.patient_id, Bill_ID: data?.row?.id } })
                })
              }}>
                {t(option.label)}
              </MenuItem>
            ))}
          </Menu> : null}
      </div>
    )
  }

  renderTbl = () => {
    const { t } = this.props
    this.state.overallBillData.forEach((element, index) => element.sno = index + 1)
    const columns = [
      {
        field: "invoice_date", headerName: "Date", flex: 0.20, align: "center", headerAlign: "center",
        renderCell: (params) => (params?.row?.invoice_date ? params?.row?.invoice_date : '-')
      },
      {
        field: "bill_number", headerName: "Bill Number", flex: 0.20, align: "center", headerAlign: "center",
        renderCell: (params) => {
          let BillNumber = params?.row?.bill_number ? params?.row?.bill_number : "";
          return (
            <Tooltip placement='top' title={BillNumber} arrow>
              <div>{BillNumber ? '...' + BillNumber?.slice(-10) : "-"}</div>
            </Tooltip>
          )
        }
      },
      {
        field: "amount", headerName: `Amount (${CurrencySymbol})`, flex: 0.15, type: "number",
        renderCell: (params) => (AmountFormat(params?.row?.amount || 0))
      },
      {
        field: "bill_type", headerName: "Bill Type", flex: 0.15, type: "string",
        renderCell: (params) => (params?.row?.bill_type ? (params?.row?.bill_type === "regular" ? "Regular" : params?.row?.bill_type === "credit" ? "Credit" : 
        params?.row?.bill_type === "cancelled" ? "Cancelled" : '-') : '-'
        )
      },
      {
        field: "payment_status", headerName: "Payment Status", flex: 0.15, type: "string",
        renderCell: (params) => (params?.row?.payment_status ? (params?.row?.payment_status === "completed" ? "Completed" : params?.row?.payment_status === "pending" ? "Pending" : 
        params?.row?.payment_status === "partial" ? "Partial" : '-') : '-'
        )
      },
      {
        field: "action", headerName: "Action", flex: 0.15, align: "center", headerAlign: "center",
        renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
          <Tooltip title={t("PrintBill")} placement="top" arrow>
            <Button onClick={()=>{
              this.PrintOverallBill(params.row?.id)
            }} className='eMed_usrconf_btn'>
              <img src={ImagePaths.PrintIcons.default} alt="print" className='eMed_action_img' />
            </Button></Tooltip>
          {this.MenuItem(params)}
        </Box>
      },
    ]
    return (
      <Box height={'64vh'}>
        <DataGrid
          rows={this.state.overallBillData}
          columns={columns}
          getRowId={(row) => row['sno']}
          page={this.state.page}
          pageSize={this.state.pageSize}
          hideFooterSelectedRowCount
          localeText={{
            toolbarColumns: "",
            toolbarDensity: "",
            toolbarExport: "",
            toolbarFilters: "",
            toolbarExportPrint: ""
          }}
          headerHeight={40}
          components={{
            Toolbar: this.gridToolBar,
            NoRowsOverlay: () => (
              <Stack className='eMed_conf_nodata'>
                {t("NoRecordsFound")}
              </Stack>
            )
          }}
          rowsPerPageOptions={[10, 20, 30]}
          disableSelectionOnClick
          pagination
          onPageChange={(newPage) => { this.setState({ page: newPage }) }}
          onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
          loading={this.state.isLoader}
        />
      </Box>
    )
  }
  closeFilter = () => {
    this.setState({ filterOpen: false })
  }
  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
    })
  }
  render() {
    return (
      <Box className='eMed_Canteen_Menuitem_Con' sx={{ backgroundColor: Colors.Background }}>
        <Box className='eMed_Canteen_TopCon'>
          <Box className='eMed_Canteen_CardCon'>
            {AmountsCard('Total Bill', this.state.noofBills ? this.state.noofBills : 0, true, "white", false, null, 'camteen_no_bill')}
            {AmountsCard('Total Amount', this.state.totalAmt ? this.state.totalAmt : 0, false, "white", false, null, 'canteen_no_amount')}
          </Box>
          <Box className='eMed_Canteen_FilterCon'>
            <Tooltip title="Filter" placement='top' arrow>
              <Button onClick={() => { this.setState({ filterOpen: true }) }}>
                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_Canteen_img' />
              </Button>
            </Tooltip>
            {/* <Box sx={{ borderRight: '1px solid #616161', height: '2vw', marginBottom: '.4vw' }}></Box> */}
            {/* <Tooltip title="Print" placement='top' arrow>
              <Button>
                <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_Canteen_img' />
              </Button>
            </Tooltip> */}
          </Box>
        </Box>
        <Paper className='eMed_Canteen_SecondCon' elevation={2}>
          {this.renderTbl()}
        </Paper>
        <Drawer
          anchor={'right'}
          open={this.state.filterOpen}
          ModalProps={{ onBackdropClick: this.closeFilter }}
        >
          {this.renderFilterScreen()}
        </Drawer>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          /> : null}
      </Box>
    )
  }
}
export default withTranslation()(CanteenOverallBill);