import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import TriggerApiOnView from './TriggerApiOnView'
import React, { useEffect, useState } from 'react'
import { getCachevalue } from '../../../../Utility/Services/CacheProviderService'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'


const doctorNote = {
    symptoms: [],
    patientCate: [],
    vitals: [],
    allergys: [],
    examination: [],
    diagnosis: [],
    prescription: [],
    plan: [],
    genadvice: '',
    counselling: {},
    reviewPlan: {},
    complaints: [],
    IP_symptom: [],
    IP_examination: [],
    IP_proviDig: [],
    IP_Dignosis: [],
    IP_notes: [],
}
export default function CollabDocNotes(props) {
    const [patient_id, setpatient_id] = useState(null)
    const [docNotes, setdocNotes] = useState(doctorNote);
    const [errorMsg, seterrorMsg] = useState(false);
    const [errorMsgText, seterrorMsgText] = useState('');
    const [clinicID, setclinicID] = useState(null);

    useEffect(() => {
        try {
            const cachedData = getCachevalue('DoctorPatientData');
            const appointData = JSON.parse(cachedData);
            const clinicId = getCachevalue('SelectedDoctorClinic')
            if (appointData?.patient_id) {
                setpatient_id(appointData.patient_id);
            }
            if (clinicId) {
                setclinicID(clinicId)
            }
        } catch (error) {
            errorMessage(error.message)
        }
    }, []);

    const msgClose = () => {
        seterrorMsg(false);
        seterrorMsgText('');
    }
    const errorMessage = (msg) => {
        seterrorMsg(true)
        seterrorMsgText(msg)
    }

    const fetchData = (url, query, handleResponse) => {
        RestAPIService.getAll(`${Serviceurls[url]}${query}`)
            .then((response) => {
                if (response.data.status === 'success') {
                    handleResponse(response.data.data);
                }
            }).catch((error) => {
                if (error?.response?.data?.message) {
                    errorMessage(error.response.data.message)
                } else {
                    errorMessage(error.message)
                }
            })
    }

    const { id, isProgrammaticScroll, userData, details } = props;

    const getNoteData = (section) => {
        const idKey = details?.visit_type !== "OP" ? "ip_admission_id=" + details?.admission_id : "appointment_id=" + details?.appointment_id;
        const query = section === 'counselling' ? `?patient_id=${patient_id}&${idKey}&clinic_id=${clinicID}` : `?patient_id=${patient_id}&${idKey}`;
        switch (section) {
            case "symptoms":
                fetchData(
                    "DOC_DOCTOR_NOTES_SYMPTOMS",
                    query,
                    (data) => {
                        setdocNotes((prevData) => ({
                            ...prevData,
                            symptoms: data || [],
                        }));
                    }
                );
                break;
            case "patientCategory":
                fetchData(
                    "DOC_DOCTOR_NOTES_PATIENT_CATEGORY",
                    query,
                    (data) => {
                        setdocNotes((prevData) => ({
                            ...prevData,
                            patientCate: data || [],
                        }));
                    }
                );
                break;
            case "vitals":
                fetchData(
                    "DOC_DOCTOR_NOTES_VITALS",
                    query,
                    (data) => {
                        setdocNotes((prevData) => ({
                            ...prevData,
                            vitals: data || [],
                        }));
                    }
                );
                break;
            case "allergy":
                fetchData(
                    "DOC_DOCTOR_NOTES_ALLERGIES",
                    query,
                    (data) => {
                        setdocNotes((prevData) => ({
                            ...prevData,
                            allergys: data || [],
                        }));
                    }
                );
                break;
            case "examination":
                fetchData(
                    "DOC_DOCTOR_NOTES_EXAMINATION_GET",
                    `${query}&new_system=true`,
                    (data) => {
                        setdocNotes((prevData) => ({
                            ...prevData,
                            examination: data.examinations || [],
                        }));
                    }
                );
                break;
            case "diagnosis":
                fetchData(
                    "DOC_DOCTOR_NOTES_ASSESSEMENT",
                    query,
                    (data) => {
                        setdocNotes((prevData) => ({
                            ...prevData,
                            diagnosis: data.icd_codes || [],
                        }));
                    }
                );
                break;
            case "prescription":
                fetchData(
                    "DOC_DOCTOR_NOTES_PRESCRIPTION",
                    query,
                    (data) => {
                        setdocNotes((prevData) => ({
                            ...prevData,
                            prescription: data || [],
                        }));
                    }
                );
                break;
            case "plan":
                fetchData(
                    "DOC_DOCTOR_NOTES_PLAN_GET",
                    query,
                    (data) => {
                        setdocNotes((prevData) => ({
                            ...prevData,
                            plan: data || [],
                        }));
                    }
                );
                break;
            case "genralAdvice":
                fetchData(
                    "DOC_DOCTOR_NOTES_ASSESSEMENT",
                    query,
                    (data) => {
                        setdocNotes((prevData) => ({
                            ...prevData,
                            genadvice: data.advice_given || '',
                        }));
                    }
                );
                break;
            case "counselling":
                fetchData(
                    "DOC_DOCTOR_NOTES_SURGERY_DETAILS",
                    query,
                    (data) => {
                        setdocNotes((prevData) => ({
                            ...prevData,
                            counselling: data.surgery_details || {},
                        }));
                    }
                );
                break;
            case "reviewplan":
                fetchData(
                    "DOC_OPT_REVIEW_PALN",
                    query,
                    (data) => {
                        setdocNotes((prevData) => ({
                            ...prevData,
                            reviewPlan: data.review_plan || {},
                        }));
                    }
                );
                break;
            case "complaints":
                fetchData(
                    "DOC_DOCTOR_NOTES_COMPLAINTS_GET",
                    query,
                    (data) => {
                        setdocNotes((prevData) => ({
                            ...prevData,
                            complaints: data || [],
                        }));
                    }
                );
                break;
            case "symptomsIP":
                fetchData(
                    "IP_DOCNOTES_SYMPTOMS",
                    query,
                    (data) => {
                        setdocNotes((prevData) => ({
                            ...prevData,
                            IP_symptom: data || [],
                        }));
                    }
                );
                break;
            case "examinationIP":
                fetchData(
                    "IP_DOCNOTES_EXAMINATION",
                    query,
                    (data) => {
                        setdocNotes((prevData) => ({
                            ...prevData,
                            IP_examination: data || [],
                        }));
                    }
                );
                break;
            case "proviDiagIP":
                fetchData(
                    "IP_DOCNOTES_PROVISIONAL_DIAG",
                    query,
                    (data) => {
                        setdocNotes((prevData) => ({
                            ...prevData,
                            IP_proviDig: data || [],
                        }));
                    }
                );
                break;
            case "diagnosisIP":
                fetchData(
                    "IP_DOCNOTES_DIAGNOSIS",
                    query,
                    (data) => {
                        setdocNotes((prevData) => ({
                            ...prevData,
                            IP_Dignosis: data || [],
                        }));
                    }
                );
                break;
            case "disNoteIP" || "FamilyCounIP" || "medicationIP":
                fetchData(
                    "IP_DOC_WRITE_NOTES",
                    query,
                    (data) => {
                        setdocNotes((prevData) => ({
                            ...prevData,
                            IP_notes: data || [],
                        }));
                    }
                );
                break;
            default:
                break;
        }
    };
    return (
        <Box marginLeft={'.5vw'} borderBottom={'1px solid #E3E2E2'} paddingBottom={"1vw"}>
            {details.visit_type === 'OP' ?
                <Box>
                    <TriggerApiOnView id={`symptoms_${id}`} onEnterView={(id) => getNoteData(id)} isProgrammaticScroll={isProgrammaticScroll} >
                        <Box id={`symptoms_${id}`} marginTop={'1vw'}>
                            <Typography fontWeight={'bold'} fontSize={'1vw'}>Symptoms</Typography>
                            {docNotes.symptoms.length > 0 ? docNotes.symptoms.map((item, index) => (
                                <Box>
                                    <Typography fontSize={'.9vw'} fontWeight={'bold'} color={'#616161'}>{`Symptoms ${index + 1}`}</Typography>
                                    <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                        <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                            <Typography fontSize={'1vw'}>{`Symptom Name`}</Typography>
                                            <Typography fontSize={'1vw'}>{`:`}</Typography>
                                        </Box>
                                        <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{item.symptom || '-'}</Typography>
                                    </Box>
                                    <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'}>
                                        <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                            <Typography fontSize={'1vw'}>{`Duration`}</Typography>
                                            <Typography fontSize={'1vw'}>{`:`}</Typography>
                                        </Box>
                                        <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{item?.duration ? `${item?.duration} - ${item?.duration_mode === '1' ? 'Days' : item?.duration_mode === '2' ? 'Weeks' : item?.duration_mode === '3' ? 'Months' : 'Years'}` : '-'}</Typography>
                                    </Box>
                                    <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'}>
                                        <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                            <Typography fontSize={'1vw'}>{`Comments`}</Typography>
                                            <Typography fontSize={'1vw'}>{`:`}</Typography>
                                        </Box>
                                        <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{item.symptom_desc || '-'}</Typography>
                                    </Box>
                                </Box>
                            )) : null}
                        </Box>
                    </TriggerApiOnView>
                    <Box marginTop={'1vw'}>
                        <Typography fontWeight={'bold'} fontSize={'1vw'}>Patient Category</Typography>
                        <TriggerApiOnView id={`patientCategory_${id}`} onEnterView={(id) => getNoteData(id)} isProgrammaticScroll={isProgrammaticScroll} >
                            <Box id={`patientCategory_${id}`} marginTop={'.5vw'}>
                                {docNotes.patientCate.length > 0 ? docNotes.patientCate.map((item) => (
                                    <Box display={'flex'} marginTop={'.5vw'}>
                                        <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                            <Typography fontSize={'1vw'}>{`Patient Category`}</Typography>
                                            <Typography fontSize={'1vw'}>{`:`}</Typography>
                                        </Box>
                                        <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{item.category}</Typography>
                                    </Box>
                                )) : null}
                            </Box>
                        </TriggerApiOnView>
                    </Box>
                    <Box marginTop={'1vw'}>
                        <TriggerApiOnView id={`vitals_${id}`} onEnterView={(id) => getNoteData(id)} isProgrammaticScroll={isProgrammaticScroll} >
                            <Box id={`vitals_${id}`}>
                                <Typography fontWeight={'bold'} fontSize={'1vw'}>Vitals</Typography>
                                {docNotes.vitals.length > 0 ? docNotes.vitals.map((item) => (
                                    <Box>
                                        <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'}>
                                            <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                                <Typography fontSize={'1vw'}>{`Height`}</Typography>
                                                <Typography fontSize={'1vw'}>{`:`}</Typography>
                                            </Box>
                                            <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{`${item.height_cm} CM`}</Typography>
                                        </Box>
                                        <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'}>
                                            <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                                <Typography fontSize={'1vw'}>{`Weight`}</Typography>
                                                <Typography fontSize={'1vw'}>{`:`}</Typography>
                                            </Box>
                                            <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{`${item.weight_kg} Kg`}</Typography>
                                        </Box>
                                        <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'}>
                                            <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                                <Typography>{`BMI`}</Typography>
                                                <Typography>{`:`}</Typography>
                                            </Box>
                                            <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{item.bmi}</Typography>
                                        </Box>
                                        <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'}>
                                            <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                                <Typography>{`Temperature`}</Typography>
                                                <Typography>{`:`}</Typography>
                                            </Box>
                                            <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{`${item.temperature} F`}</Typography>
                                        </Box>
                                        <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'}>
                                            <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                                <Typography>{`Puls Rate`}</Typography>
                                                <Typography>{`:`}</Typography>
                                            </Box>
                                            <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{`${item.pulse_rate}/min`}</Typography>
                                        </Box>
                                        <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'}>
                                            <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                                <Typography>{`BP | D>S`}</Typography>
                                                <Typography>{`:`}</Typography>
                                            </Box>
                                            <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{`${item.blood_pressure_systolic} | ${item.blood_pressure_systolic} mm/Hg`}</Typography>
                                        </Box>
                                        <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'}>
                                            <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                                <Typography>{`SpO2`}</Typography>
                                                <Typography>{`:`}</Typography>
                                            </Box>
                                            <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{`${item.pulse_oximetry}%`}</Typography>
                                        </Box>
                                        <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'}>
                                            <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                                <Typography>{`Blood Glucose`}</Typography>
                                                <Typography>{`:`}</Typography>
                                            </Box>
                                            <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'} >{`${item.blood_sugar} mg/DL`}</Typography>
                                        </Box>
                                    </Box>
                                )) : null}
                            </Box>
                        </TriggerApiOnView>
                    </Box>
                    <Box marginTop={'1vw'}>
                        <TriggerApiOnView id={`allergy_${id}`} onEnterView={(id) => getNoteData(id)} isProgrammaticScroll={isProgrammaticScroll} >
                            <Box id={`allergy_${id}`}>
                                <Typography fontWeight={'bold'} fontSize={'1vw'}>Allergies</Typography>
                                {docNotes.allergys.length > 0 ? docNotes.allergys.map((item, i) => (
                                    <Box>
                                        <Typography fontSize={'.9vw'} fontWeight={'bold'} color={'#616161'}>{`Allergy ${i + 1}`}</Typography>
                                        <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                            <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                                <Typography fontSize={'1vw'}>{`Allergy Type`}</Typography>
                                                <Typography fontSize={'1vw'}>{`:`}</Typography>
                                            </Box>
                                            <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{item.allergy_type === 1 ? `Medication Allergy` : `Non-Medication Allergy`}</Typography>
                                        </Box>
                                        <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'}>
                                            <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                                <Typography fontSize={'1vw'}>{`Allergy Name`}</Typography>
                                                <Typography fontSize={'1vw'}>{`:`}</Typography>
                                            </Box>
                                            <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{item.name || ''}</Typography>
                                        </Box>
                                        <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'}>
                                            <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                                <Typography fontSize={'1vw'}>{`Status`}</Typography>
                                                <Typography fontSize={'1vw'}>{`:`}</Typography>
                                            </Box>
                                            <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{`Active`}</Typography>
                                        </Box>
                                        <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'}>
                                            <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                                <Typography fontSize={'1vw'}>{`Comments`}</Typography>
                                                <Typography fontSize={'1vw'}>{`:`}</Typography>
                                            </Box>
                                            <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{item.comments || ''}</Typography>
                                        </Box>
                                    </Box>
                                )) : null}
                            </Box>
                        </TriggerApiOnView>
                    </Box>
                    <Box marginTop={'1vw'}>
                        <Typography fontWeight={'bold'} fontSize={'1vw'}>Examination</Typography>
                        <TriggerApiOnView id={`examination_${id}`} onEnterView={(id) => getNoteData(id)} isProgrammaticScroll={isProgrammaticScroll} >
                            <Box id={`examination_${id}`}>
                                {docNotes.examination.length > 0 ? docNotes.examination.map((item, i) => (
                                    <Box>
                                        <Typography fontSize={'.9vw'} fontWeight={'bold'} color={'#616161'}>{`Examination ${i + 1}`}</Typography>
                                        <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                            <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                                <Typography fontSize={'1vw'}>{`Systematic Examination`}</Typography>
                                                <Typography fontSize={'1vw'}>{`:`}</Typography>
                                            </Box>
                                            <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{item.group_name || '-'}</Typography>
                                        </Box>
                                        <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'}>
                                            <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                                <Typography fontSize={'1vw'}>{`Signs / Symptoms`}</Typography>
                                                <Typography fontSize={'1vw'}>{`:`}</Typography>
                                            </Box>
                                            <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{item.examination_name || '-'}</Typography>
                                        </Box>
                                        <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'}>
                                            <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                                <Typography fontSize={'1vw'}>{`Notes`}</Typography>
                                                <Typography fontSize={'1vw'}>{`:`}</Typography>
                                            </Box>
                                            <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{item.value || '-'}</Typography>
                                        </Box>
                                    </Box>
                                )) : null}
                            </Box>
                        </TriggerApiOnView>
                    </Box>
                    <Box marginTop={'1vw'}>
                        <Typography fontWeight={'bold'} fontSize={'1vw'}>Diagnosis</Typography>
                        <TriggerApiOnView id={`diagnosis_${id}`} onEnterView={(id) => getNoteData(id)} isProgrammaticScroll={isProgrammaticScroll} >
                            <Box id={`diagnosis_${id}`}>
                                {docNotes.diagnosis.length > 0 ? docNotes.diagnosis.map((item, o) => (
                                    <Box>
                                        <Typography fontSize={'.9vw'} fontWeight={'bold'} color={'#616161'}>{`Diagnosis ${o + 1}`}</Typography>
                                        <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                            <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                                <Typography fontSize={'1vw'}>{`ICD Code`}</Typography>
                                                <Typography fontSize={'1vw'}>{`:`}</Typography>
                                            </Box>
                                            <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{item.icd_code || '-'}</Typography>
                                        </Box>
                                        <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'}>
                                            <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                                <Typography fontSize={'1vw'}>{`Short Description`}</Typography>
                                                <Typography fontSize={'1vw'}>{`:`}</Typography>
                                            </Box>
                                            <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{item.short_desc || '-'}</Typography>
                                        </Box>
                                        <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'}>
                                            <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                                <Typography fontSize={'1vw'}>{`Long Description`}</Typography>
                                                <Typography fontSize={'1vw'}>{`:`}</Typography>
                                            </Box>
                                            <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{item.desc || '-'}</Typography>
                                        </Box>
                                    </Box>
                                )) : null}
                            </Box>
                        </TriggerApiOnView>
                    </Box>
                    <Box marginTop={'1vw'}>
                        <Typography fontWeight={'bold'} fontSize={'1vw'}>Prescription</Typography>
                        <TriggerApiOnView id={`prescription_${id}`} onEnterView={(id) => getNoteData(id)} isProgrammaticScroll={isProgrammaticScroll} >
                            <Box id={`prescription_${id}`} marginTop={'.5vw'}>
                                <Paper>
                                    <TableContainer>
                                        <Table size='small' aria-label="stictbl">
                                            <TableHead sx={{ backgroundColor: '#FAF9F9' }}>
                                                <TableRow>
                                                    <TableCell sx={{ fontWeight: 'bold', width: '10vw' }}>s.No</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold', width: '35vw' }}>Drug Name</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold', width: '35vw' }}>Dosage Type</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold', width: '20vw' }}>Dosage Strength</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {docNotes.prescription.map((item, i) => {
                                                    return (
                                                        item.prescription_lines.length > 0 ? item.prescription_lines.map((data, index) => (
                                                            <TableRow>
                                                                <TableCell sx={{ width: '10vw' }}>{index + 1}</TableCell>
                                                                <TableCell sx={{ width: '35vw' }}>{data.brand_name}</TableCell>
                                                                <TableCell sx={{ width: '35vw' }}>{data.dosage_type}</TableCell>
                                                                <TableCell sx={{ width: '20vw' }}>{data.dosage_strength}</TableCell>
                                                            </TableRow>
                                                        )) :
                                                            <TableRow>
                                                                <TableCell colSpan={4} align="center">{'No Records To Be Shown'}</TableCell>
                                                            </TableRow>)
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Box>
                        </TriggerApiOnView>
                    </Box>
                    <Box marginTop={'1vw'}>
                        <Typography fontWeight={'bold'} fontSize={'1vw'}>Plan</Typography>
                        <TriggerApiOnView id={`plan_${id}`} onEnterView={(id) => getNoteData(id)} isProgrammaticScroll={isProgrammaticScroll} >
                            <Box id={`plan_${id}`} marginTop={'.5vw'}>
                                <Paper>
                                    <TableContainer>
                                        <Table size='small' aria-label="stictbl">
                                            <TableHead sx={{ backgroundColor: '#FAF9F9' }}>
                                                <TableRow>
                                                    <TableCell sx={{ fontWeight: 'bold', width: '10vw' }}>s.No</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold', width: '17vw' }}>Treatment</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold', width: '16vw' }}>Dosage</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold', width: '17vw' }}>Route</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold', width: '15vw' }}>Procedure</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold', width: '15vw' }}>Description</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold', width: '10vw' }}>Status</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {docNotes.plan.length > 0 ? docNotes.plan.map((item, i) => (
                                                    <TableRow>
                                                        <TableCell sx={{ width: '10vw' }}>{i + 1}</TableCell>
                                                        <TableCell sx={{ width: '17vw' }}>{item.treatment_name}</TableCell>
                                                        <TableCell sx={{ width: '16vw' }}>{item.comments}</TableCell>
                                                        <TableCell sx={{ width: '17vw' }}>{item.route}</TableCell>
                                                        <TableCell sx={{ width: '15vw' }}>{item.procedure_name}</TableCell>
                                                        <TableCell sx={{ width: '15vw' }}>{item.description}</TableCell>
                                                        <TableCell sx={{ width: '10vw' }}>{item.is_completed ? 'Completed' : 'Pending'}</TableCell>
                                                    </TableRow>
                                                )) :
                                                    <TableRow>
                                                        <TableCell colSpan={7} align="center">{'No Records To Be Shown'}</TableCell>
                                                    </TableRow>}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Box>
                        </TriggerApiOnView>
                    </Box>
                    <Box marginTop={'1vw'}>
                        <Typography fontWeight={'bold'} fontSize={'1vw'}>General Advice</Typography>
                        <TriggerApiOnView id={`genralAdvice_${id}`} onEnterView={(id) => getNoteData(id)} isProgrammaticScroll={isProgrammaticScroll} >
                            <Box id={`genralAdvice_${id}`} marginTop={'.5vw'} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                {docNotes.genadvice ?
                                    <Box display={'flex'}>
                                        <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                            <Typography fontSize={'1vw'}>{`General Advice`}</Typography>
                                            <Typography fontSize={'1vw'}>{`:`}</Typography>
                                        </Box>
                                        <Box width={'50vw'}>
                                            <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{docNotes.genadvice || '-'}</Typography>
                                        </Box>
                                    </Box> : null}
                            </Box>
                        </TriggerApiOnView>
                    </Box>
                    <Box marginTop={'1vw'}>
                        <Typography fontWeight={'bold'} fontSize={'1vw'}>Counselling</Typography>
                        <TriggerApiOnView id={`counselling_${id}`} onEnterView={(id) => getNoteData(id)} isProgrammaticScroll={isProgrammaticScroll} >
                            <Box id={`counselling_${id}`}>
                                <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                    <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                        <Typography fontSize={'1vw'}>{`Surgery`}</Typography>
                                        <Typography fontSize={'1vw'}>{`:`}</Typography>
                                    </Box>
                                    <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{docNotes.counselling.surgery_name || '-'}</Typography>
                                </Box>
                                <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                    <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                        <Typography fontSize={'1vw'}>{`Package Name`}</Typography>
                                        <Typography fontSize={'1vw'}>{`:`}</Typography>
                                    </Box>
                                    <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{docNotes.counselling.package_name || '-'}</Typography>
                                </Box>
                                <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                    <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                        <Typography fontSize={'1vw'}>{`Surgery Time`}</Typography>
                                        <Typography fontSize={'1vw'}>{`:`}</Typography>
                                    </Box>
                                    <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{docNotes.counselling.surgery_time || '-'}</Typography>
                                </Box>
                                <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                    <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                        <Typography fontSize={'1vw'}>{`Surgeon Name`}</Typography>
                                        <Typography fontSize={'1vw'}>{`:`}</Typography>
                                    </Box>
                                    <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{docNotes.counselling.surgeon_name || '-'}</Typography>
                                </Box>
                                <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                    <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                        <Typography fontSize={'1vw'}>{`Anaesthetist`}</Typography>
                                        <Typography fontSize={'1vw'}>{`:`}</Typography>
                                    </Box>
                                    <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{docNotes.counselling.anaesthetist || '-'}</Typography>
                                </Box>
                                <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                    <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                        <Typography fontSize={'1vw'}>{`Counsellor`}</Typography>
                                        <Typography fontSize={'1vw'}>{`:`}</Typography>
                                    </Box>
                                    <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{docNotes.counselling.counsellor_name || '-'}</Typography>
                                </Box>
                            </Box>
                        </TriggerApiOnView>
                    </Box>
                    <Box marginTop={'1vw'}>
                        <Typography fontWeight={'bold'} fontSize={'1vw'}>Review Plan</Typography>
                        <TriggerApiOnView id={`reviewplan_${id}`} onEnterView={(id) => getNoteData(id)} isProgrammaticScroll={isProgrammaticScroll} >
                            <Box id={`reviewplan_${id}`}>
                                <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                    <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                        <Typography fontSize={'1vw'}>{`Duration`}</Typography>
                                        <Typography fontSize={'1vw'}>{`:`}</Typography>
                                    </Box>
                                    <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{(docNotes.reviewPlan.duration && docNotes.reviewPlan.duration_type) ? `${docNotes.reviewPlan.duration || '-'} ${docNotes.reviewPlan.duration_type || ''}` : '-'}</Typography>
                                </Box>
                                <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                    <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                        <Typography fontSize={'1vw'}>{`Review Date & Time`}</Typography>
                                        <Typography fontSize={'1vw'}>{`:`}</Typography>
                                    </Box>
                                    <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{(docNotes.reviewPlan.review_date && docNotes.reviewPlan.appointment_time) ? `${docNotes.reviewPlan.review_date || '-'} & ${docNotes.reviewPlan.appointment_time || ''}` : '-'}</Typography>
                                </Box>
                                <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                    <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                        <Typography fontSize={'1vw'}>{`Comments`}</Typography>
                                        <Typography fontSize={'1vw'}>{`:`}</Typography>
                                    </Box>
                                    <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{docNotes.reviewPlan.comments || '-'}</Typography>
                                </Box>
                            </Box>
                        </TriggerApiOnView>
                    </Box>
                    <Box marginTop={'1vw'}>
                        <Typography fontWeight={'bold'} fontSize={'1vw'}>Compaints</Typography>
                        <TriggerApiOnView id={`complaints_${id}`} onEnterView={(id) => getNoteData(id)} isProgrammaticScroll={isProgrammaticScroll} >
                            <Box id={`complaints_${id}`} marginTop={'.5vw'}>
                                <Paper>
                                    <TableContainer>
                                        <Table size='small' aria-label="stictbl">
                                            <TableHead sx={{ backgroundColor: '#FAF9F9' }}>
                                                <TableRow>
                                                    <TableCell sx={{ fontWeight: 'bold', width: '10vw' }}>s.No</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold', width: '17vw' }}>Complaints</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold', width: '16vw' }}>Duration</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold', width: '17vw' }}>Comments</TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {docNotes.complaints.length > 0 ? docNotes.complaints.map((item, i) => (
                                                    <TableRow>
                                                        <TableCell sx={{ width: '10vw' }}>{i + 1}</TableCell>
                                                        <TableCell sx={{ width: '17vw' }}>{item.complaint_description}</TableCell>
                                                        <TableCell sx={{ width: '16vw' }}>{`${item.duration_time} ${item?.duration_type == 1 ? "Days" : item?.duration_type == 2 ? "Weeks" : item?.duration_type == 3 ? "Months" : "Years"}`}</TableCell>
                                                        <TableCell sx={{ width: '17vw' }}>{item.comments}</TableCell>
                                                    </TableRow>
                                                )) :
                                                    <TableRow>
                                                        <TableCell colSpan={4} align="center">{'No Records To Be Shown'}</TableCell>
                                                    </TableRow>}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Box>
                        </TriggerApiOnView>
                    </Box>
                </Box> :
                <Box>
                    <Box marginTop={'1vw'}>
                        <Typography fontWeight={'bold'} fontSize={'1vw'}>Symptoms</Typography>
                        <TriggerApiOnView id={`symptomsIP_${id}`} onEnterView={(id) => getNoteData(id)} isProgrammaticScroll={isProgrammaticScroll} >
                            <Box id={`symptomsIP_${id}`}>
                                {docNotes.IP_symptom.length > 0 ? docNotes.IP_symptom.map((item, i) => (
                                    <Box>
                                        <Typography fontSize={'.9vw'} fontWeight={'bold'} color={'#616161'}>{`Symptom ${i + 1}`}</Typography>
                                        <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                            <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                                <Typography fontSize={'1vw'}>{`Doctor Name`}</Typography>
                                                <Typography fontSize={'1vw'}>{`:`}</Typography>
                                            </Box>
                                            <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{item.doctor_name || '-'}</Typography>
                                        </Box>
                                        <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'}>
                                            <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                                <Typography fontSize={'1vw'}>{`Symptom Name`}</Typography>
                                                <Typography fontSize={'1vw'}>{`:`}</Typography>
                                            </Box>
                                            <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{item.symptoms_name || '-'}</Typography>
                                        </Box>
                                        <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'}>
                                            <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                                <Typography fontSize={'1vw'}>{`Duration`}</Typography>
                                                <Typography fontSize={'1vw'}>{`:`}</Typography>
                                            </Box>
                                            <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{`${item.duration} ${item.duration_type}`}</Typography>
                                        </Box>
                                        <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'}>
                                            <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                                <Typography fontSize={'1vw'}>{`Description`}</Typography>
                                                <Typography fontSize={'1vw'}>{`:`}</Typography>
                                            </Box>
                                            <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{item.description || '-'}</Typography>
                                        </Box>
                                    </Box>
                                )) : null}
                            </Box>
                        </TriggerApiOnView>
                    </Box>
                    <Box marginTop={'1vw'}>
                        <Typography fontWeight={'bold'} fontSize={'1vw'}>Examination</Typography>
                        <TriggerApiOnView id={`examinationIP_${id}`} onEnterView={(id) => getNoteData(id)} isProgrammaticScroll={isProgrammaticScroll} >
                            <Box id={`examinationIP_${id}`}>
                                {docNotes.IP_examination.length > 0 ? docNotes.IP_examination.map((item, i) => (
                                    <Box>
                                        <Typography fontSize={'.9vw'} fontWeight={'bold'} color={'#616161'}>{`Symptom ${i + 1}`}</Typography>
                                        <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                            <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                                <Typography fontSize={'1vw'}>{`Doctor Name`}</Typography>
                                                <Typography fontSize={'1vw'}>{`:`}</Typography>
                                            </Box>
                                            <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{item.doctor_name || '-'}</Typography>
                                        </Box>
                                        <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'}>
                                            <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                                <Typography fontSize={'1vw'}>{`Examination Name`}</Typography>
                                                <Typography fontSize={'1vw'}>{`:`}</Typography>
                                            </Box>
                                            <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{item.exam_name || '-'}</Typography>
                                        </Box>
                                        <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'}>
                                            <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                                <Typography fontSize={'1vw'}>{`Description`}</Typography>
                                                <Typography fontSize={'1vw'}>{`:`}</Typography>
                                            </Box>
                                            <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{item.description || '-'}</Typography>
                                        </Box>
                                    </Box>
                                )) : null}
                            </Box>
                        </TriggerApiOnView>
                    </Box>
                    <Box marginTop={'1vw'}>
                        <Typography fontWeight={'bold'} fontSize={'1vw'}>Provisional Diagnosis</Typography>
                        <TriggerApiOnView id={`proviDiagIP_${id}`} onEnterView={(id) => getNoteData(id)} isProgrammaticScroll={isProgrammaticScroll} >
                            <Box id={`proviDiagIP_${id}`}>
                                {docNotes.IP_proviDig.length > 0 ? docNotes.IP_proviDig.map((item, i) => (
                                    <Box>
                                        <Typography >{item.notes || '-'}</Typography>
                                    </Box>
                                )) : null}
                            </Box>
                        </TriggerApiOnView>
                    </Box>
                    <Box marginTop={'1vw'}>
                        <Typography fontWeight={'bold'} fontSize={'1vw'}>Diagnosis</Typography>
                        <TriggerApiOnView id={`diagnosisIP_${id}`} onEnterView={(id) => getNoteData(id)} isProgrammaticScroll={isProgrammaticScroll} >
                            <Box id={`diagnosisIP_${id}`}>
                                {docNotes.IP_Dignosis.length > 0 ? docNotes.IP_Dignosis.map((item, o) => (
                                    <Box>
                                        <Typography fontSize={'.9vw'} fontWeight={'bold'} color={'#616161'}>{`Diagnosis ${o + 1}`}</Typography>
                                        <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                            <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                                <Typography fontSize={'1vw'}>{`Doctor Name`}</Typography>
                                                <Typography fontSize={'1vw'}>{`:`}</Typography>
                                            </Box>
                                            <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{item.doctor_name || '-'}</Typography>
                                        </Box>
                                        <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                            <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                                <Typography fontSize={'1vw'}>{`ICD Code`}</Typography>
                                                <Typography fontSize={'1vw'}>{`:`}</Typography>
                                            </Box>
                                            <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{item.icd_code || '-'}</Typography>
                                        </Box>
                                        <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'}>
                                            <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                                <Typography fontSize={'1vw'}>{`Short Description`}</Typography>
                                                <Typography fontSize={'1vw'}>{`:`}</Typography>
                                            </Box>
                                            <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{item.icd_short_desc || '-'}</Typography>
                                        </Box>
                                        <Box marginTop={'.5vw'} display={'flex'} flexDirection={'row'}>
                                            <Box display={'flex'} width={'20vw'} justifyContent={'space-between'}>
                                                <Typography fontSize={'1vw'}>{`Long Description`}</Typography>
                                                <Typography fontSize={'1vw'}>{`:`}</Typography>
                                            </Box>
                                            <Typography fontSize={'1vw'} fontWeight={'bold'} marginLeft={'1vw'}>{item.icd_desc || '-'}</Typography>
                                        </Box>
                                    </Box>
                                )) : null}
                            </Box>
                        </TriggerApiOnView>
                    </Box>
                    <Box marginTop={'1vw'}>
                        <Typography fontWeight={'bold'} fontSize={'1vw'}>Discharge Notes</Typography>
                        <TriggerApiOnView id={`disNoteIP_${id}`} onEnterView={(id) => getNoteData(id)} isProgrammaticScroll={isProgrammaticScroll} >
                            <Box id={`disNoteIP_${id}`}>
                                {docNotes.IP_notes.length > 0 ? docNotes.IP_notes.map((item, i) => (
                                    <Box>
                                        <Typography >{item.discharge_notes || '-'}</Typography>
                                    </Box>
                                )) : null}
                            </Box>
                        </TriggerApiOnView>
                    </Box>
                    <Box marginTop={'1vw'}>
                        <Typography fontWeight={'bold'} fontSize={'1vw'}>Family Counselling</Typography>
                        <TriggerApiOnView id={`FamilyCounIP_${id}`} onEnterView={(id) => getNoteData(id)} isProgrammaticScroll={isProgrammaticScroll} >
                            <Box id={`FamilyCounIP_${id}`}>
                                {docNotes.IP_notes.length > 0 ? docNotes.IP_notes.map((item, i) => (
                                    <Box>
                                        <Typography >{item.family_counselling || '-'}</Typography>
                                    </Box>
                                )) : null}
                            </Box>
                        </TriggerApiOnView>
                    </Box>
                    <Box marginTop={'1vw'}>
                        <Typography fontWeight={'bold'} fontSize={'1vw'}>Medication & Instructions</Typography>
                        <TriggerApiOnView id={`medicationIP_${id}`} onEnterView={(id) => getNoteData(id)} isProgrammaticScroll={isProgrammaticScroll} >
                            <Box id={`medicationIP_${id}`}>
                                {docNotes.IP_notes.length > 0 ? docNotes.IP_notes.map((item, i) => (
                                    <Box>
                                        <Typography >{item.instructions || '-'}</Typography>
                                    </Box>
                                )) : null}
                            </Box>
                        </TriggerApiOnView>
                    </Box>
                </Box>}
            {errorMsg && <ToastMsg
                severity={'error'}
                msg={errorMsgText}
                msgPop={msgClose.bind(this)}
            />}
        </Box>
    )
}