import {
    Button, Table, TableCell,
    TableContainer, TableHead, TableRow,
    TableBody, Modal, Typography, Box, Select,
    MenuItem, TextField, IconButton,
    FormControl, InputLabel, Tooltip, Autocomplete,
    Menu, FormGroup, FormControlLabel, Checkbox,
    Tabs, Tab,
    Stack,
    Paper
} from '@mui/material';
import React, { Component } from 'react'
import './home.css';
import { ImagePaths } from '../../../Utility/ImagePaths'
import { Trans, withTranslation } from 'react-i18next';
import ImageUploading from 'react-images-uploading';
import { Close } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { t } from 'i18next';
import { Colors } from '../../../Styles/Colors';
import CommonGridHeader, { CommonDeleteButton, CommonEditButton } from '../../../Components/Common Components/CommonComponents';
import { Bucket_Name } from '../../../Utility/Constants';
import TestHistory from './TestHistory';
import { DateTime } from 'luxon';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { DataGrid } from '@mui/x-data-grid';
import PrintPreviewPop from '../../../Components/Common Components/PrintPreviewPop';

var AWS = require('aws-sdk');
var s3 = new AWS.S3({
    region: "ap-south-1"
});

const PosiNega = [
    { result_name: "Positive", value: "Positive" },
    { result_name: "Negative", value: "Negative" },
    { result_name: "Others", value: "Others" }
]

const PresAbsen = [
    { result_name: "Present", value: "Present" },
    { result_name: "Absent", value: "Absent" },
    { result_name: "Others", value: "Others" }
]

class Result extends Component {

    constructor(props) {
        super(props)
        this.state = {
            patient_status: {},
            patientResultStatus: [],
            methodOneResultText: "",
            methodOneResultTextUnit: "",
            selectDropDownValue: "",
            methodThreeResult: [],
            methodThreeBacteriaResult: "",
            antibacteria: null,
            bacteria: null,
            Interpretation: "",
            autoZone: "",
            zoneResult: "",
            breakPoint: "",
            breakPointResult: "",
            selectedSubIndex: null,
            page: 0,
            rowsPerPage: 10,
            methodFourPara: [],
            methodFourImpress: [],
            metodFourTable: [],
            methodFourResult: [],
            methodFourData: [],
            method: [],
            bacteriaList: [],
            antiBacteriaList: [],
            isErrorMsg: false,
            isErrorMsgText: "",
            checkList: [],
            deleteData: true,
            uploadedImages: [],
            selectedImage: {},
            selectedImageName: [],
            deleteDataList: [],
            selectedDeletedIndex: [],
            selectedImageIndex: null,
            selectedMethodThreeMainIndex: '',
            selected_save_data: null,
            selected_parse_data: null,
            selectedImageRowId: null,
            SaveChangesClick: false,
            showPrintBtn: false,
            microscopy: "",
            colonyCount: "",
            EditCulture: false,
            EditedIndex: "",
            EditedSubIndex: "",
            signatureList: [],
            ActiveDropDown: [],
            disableLeft: false,
            disableCentre: false,
            disableRight: false,
            LeftDrop: null,
            CentreDrop: null,
            RightDrop: null,
            viewPrintLst: false,
            PrintTestList: [],
            isPrintClicked: false,
            checkboxState: [],
            cul_method: '',
            cul_comments: '',
            showHistory: false,
            fromTechnician: false,
            enableFinishRpt: false,
            disableBtn: false,
            EditOrgCulture: false,
            SelectedTab: 'one',
            SelectedSource: 'Inhouse',
            selectedRows: [],
            testDatas: [],
            pageSize: 10,
            page: 0,
            total: 0,
            isLoader: false,
            selectedData: [],
            DeletedBacteriaList: [],
            DeletedAntiBacList: [],
            selectedOutsourceData: [],
            isComment: false,
            clinicCmt: '',
            overAllCmt: '',
            pdfData: '',
            pdfPreview: false
        }
        this.uploadImage = React.createRef();
    }

    componentDidMount() {
        this.getSignatureDetails()
        this.getDropDownDetails()
        let { location } = this.props?.history;
        let { resultList, fromTechnician, fromTestReport } = location?.state;
        if (location?.state?.resultList) {
            this.setState({
                patient_status: resultList,
                fromTechnician: fromTechnician,
                FromTestReport: fromTestReport ? true : false
            }, () => {
                this.getPatientResultStatus(true)
                this.getCompletedTests()
            })
        }
    }

    getCompletedTests = () => {
        try {
            let id = this.state.FromTestReport ? this.state.patient_status?.result_summary_id : this.state.patient_status?.id ? this.state.patient_status?.id : null
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.LAB_COMPLETED_TEST + `?result_summary_id=${id}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.LoaderFunction(false)
                        this.setState({
                            testDatas: response?.data?.data?.test_data ? response?.data?.data?.test_data : []
                        })
                    }
                })
                .catch(e => {
                    this.LoaderFunction(false)
                    if (e.response.data.status === 'fail') {
                        this.setState({
                            isErrorMsg: true,
                            isErrorMsgText: e?.response?.data?.message
                        })
                    } else {
                        this.setState({
                            isErrorMsg: true,
                            isErrorMsgText: e?.message
                        })
                    }
                })
        } catch (e) {
            this.LoaderFunction(false)
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e?.message
            })
        }
    }

    getResultCompletePrint = (id) => {
        try {
            let resultSumID = this.state.FromTestReport ? this.state.patient_status?.result_summary_id : this.state.patient_status?.id ? this.state.patient_status?.id : null
            RestAPIService.getAll(Serviceurls.LAB_PATIENT_RESULT_PRINT + `?result_summary_id=${resultSumID}&result_item_id=${id}&is_completed=${true}`).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.setState({ isPrintClicked: false })
                }).catch((e) => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.message
                    })
                })
        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    getSignatureDetails = () => {
        RestAPIService.getAll(Serviceurls.LAB_E_SIGNATURE_CONFIG).
            then((response) => {
                this.setState({ signatureList: response.data.data })
            }).catch((error) =>
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.message
                }))
    }

    GetCultureTestMasters = () => {
        RestAPIService.getAll(`${Serviceurls.LAB_ORGANISM_MASTER}`).
            then((response) => {
                this.setState({ bacteriaList: response.data.data?.filter((list) => list?.is_active) })
            }).catch((error) =>
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.message
                }))
        RestAPIService.getAll(`${Serviceurls.LAB_ORGANISM_VS_ANTIBIO_MASTER}`).
            then((response) => {
                this.setState({ FullantiBacteriaList: response.data.data?.filter((list) => list?.is_active) })
            }).catch((error) =>
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.message
                }))
    }

    getDropDownDetails = () => {
        RestAPIService.getAll(Serviceurls.LAB_RESULT_SIGNATURE).
            then((response) => {
                this.setState({
                    disableLeft: response.data.config?.esign_left,
                    disableCentre: response.data.config?.esign_center,
                    disableRight: response.data.config?.esign_right
                })
            }).catch((error) =>
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.message
                }))
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            isSuccessMsg: false,
            isSuccessMsgText: ''
        })
    }

    methodThreehandleOpen = (index) => {
        this.setState({
            methodThreeOpen: true,
            selectedSubIndex: index
        })
    }
    methodThreehandleClose = () => {
        this.setState({
            methodThreeOpen: false
        })
    }

    methodFourhandleOpen = (index) => {
        this.setState({
            methodFourOpen: true,
            selectedSubIndex: index
        })

    }
    methodFourHandleClose = (result_list) => {
        // let {patientResultStatus} = this.state
        // if(result_list.id === this.state.selectedRowId){
        //     if (result_list?.result?.value) {
        //         result_list.result.value = []
        //     } else {
        //         result_list.result = { value: [] }
        //     }
        // }
        this.setState({
            methodFourOpen: false,
            bacteria: null,
            antibacteria: null,
            Interpretation: "",
            autoZone: "",
            zoneResult: "",
            breakPoint: "",
            breakPointResult: "",
            methodThreeBacteriaResult: "",
            methodThreeResult: [],
            deleteData: false,
            microscopy: "",
            colonyCount: "",
            EditCulture: false,
            EditedIndex: "",
            EditedSubIndex: "",
            cul_method: '',
            cul_comments: '',
            // patientResultStatus
        })
    }

    async sourceOpen(list, subIndex) {
        let tempArr = [];
        let image = list.result_image
        if (image !== null) {
            for (let j = 0; j < image?.length; j++) {
                let doc_key = image[j];
                let id = list.id;
                let getData = await this.getS3Key(doc_key, id)
                tempArr.push(getData)
            }
        }
        this.setState({
            sourceOpen: true,
            selectedImageIndex: subIndex,
            selectedUploadData: list,
            uploadedImages: tempArr,
            selectedImage: null
        })
    }
    sourceClose = () => {
        this.setState({ sourceOpen: false })
    }

    onChangeHandler = (event, key) => {

        this.setState({
            [key]: event.target.value
        }, () => {
            if (key == "Interpretation" && this.state.bacteria != null && this.state.antibacteria != null) {
                var bacteriaID = ""; this.state.bacteriaList.forEach((element) => { if (element.name == this.state.bacteria?.name) { bacteriaID = element.id } })
                var antibioticID = ""; this.state.antiBacteriaList.forEach((element) => { if (element.name == this.state.antibacteria?.name) { antibioticID = element.id } })
                this.setState({
                    BacteriaId: bacteriaID,
                    AntibioticId: antibioticID
                }, () => { this.getInterpretationdata() })
            }
        })
    }

    onChange = (imageList, addUpdateIndex) => {
        this.setState({
            uploadedImages: [...this.state.uploadedImages, ...imageList.target.files]
        })
    };

    handlePageChange = (event, newPage) => {
        this.setState({
            page: newPage
        })
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: (+event.target.value),
            page: 0

        })
    }

    getInterpretationdata = () => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_CULTURE_TEST_CONFIG + "?bacteria_id=" + this.state.BacteriaId + "&antibiotic_id=" + this.state.AntibioticId + "&interpretation=" + this.state.Interpretation + "&id=" + this.state.selectedRowId).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            autoZone: response.data.data.zone,
                            breakPoint: response.data.data.mic
                        })
                    }
                }).catch((e) => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.message
                    })
                })
        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }

    DeleteItem = (result_list, selectedItem) => {
        let updatedData = result_list.result.value.filter(item => item.bacteria !== selectedItem.bacteria);
        result_list.result.value = updatedData
        this.setState({
            methodThreeResult: updatedData,
            methodThreeBacteriaResult: "",
        });
    };

    submitBacteria = (dataSet, result_list) => {
        const { t } = this.props
        let bacteriaResult = dataSet
        let bacteriaData = {
            "bacteria": this.state.bacteria?.organism_name ? this.state.bacteria?.organism_name : "",
            "organism_master_id": this.state.bacteria?.id,
            "colony_count": this.state.colonyCount,
            "microscopy": this.state.microscopy,
            "method_type": this.state.cul_method,
            "comments": this.state.cul_comments,
            "antibacteria": [{
                "antibiotic": this.state.antibacteria?.antibiotic_name ? this.state.antibacteria?.antibiotic_name : "",
                "mic_break_point_std": this.state.breakPoint,
                "mic_break_point": this.state.breakPointResult,
                "zone_size_std": this.state.autoZone,
                "zone_size": this.state.zoneResult,
                "interpretation": this.state.Interpretation,
                "methodThreeBacteriaResult": this.state.methodThreeBacteriaResult,
            }]
        }

        if (this.state.EditCulture) {
            bacteriaData["id"] = this.state.selectedId
            if (this.state.selectedId) {
                bacteriaData["organism_master_id"] = dataSet.find((list) => (list?.id === this.state.selectedId))?.organism_master_id
            }
        }

        if (!bacteriaData.bacteria && !bacteriaData.antibacteria[0].antibiotic && !bacteriaData.antibacteria[0].methodThreeBacteriaResult && !bacteriaData.antibacteria[0].interpretation && this.state.EditOrgCulture === false) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: t("ManitoryFields")
            })
        }
        else if (!bacteriaData.bacteria && this.state.EditOrgCulture === false) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: t("Select Bacteria")
            })
        }
        // } else if (!bacteriaData.antibacteria[0].antibiotic) {
        //     this.setState({
        //         isErrorMsg: true,
        //         isErrorMsgText: t("Select AntiBiotic")
        //     })
        // } else if (!bacteriaData.antibacteria[0].interpretation) {
        //     this.setState({
        //         isErrorMsg: true,
        //         isErrorMsgText: t("Select Interpretation")
        //     })
        // } else if (!bacteriaData.antibacteria[0].methodThreeBacteriaResult) {
        //     this.setState({
        //         isErrorMsg: true,
        //         isErrorMsgText: t("Enter Efficiency Ratio")
        //     })
        // } 
        else {
            let duplicate = this.checkDuplicate()
            if (duplicate && !this.state.EditCulture && this.state.EditOrgCulture === false) {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: t("bacteriaExist")
                })
            } else {
                // var existingEntry = bacteriaResult.find(entry => entry?.bacteria?.toLowerCase() === bacteriaData?.bacteria?.toLowerCase());
                var existingEntry = this.state.EditCulture ? bacteriaResult.find(entry => entry?.id === bacteriaData?.id) : bacteriaResult.find(entry => (entry?.bacteria?.toLowerCase() === bacteriaData?.bacteria?.toLowerCase() && !entry?.completed_date));

                if (existingEntry) {
                    if (this.state.EditCulture || this.state.EditOrgCulture) {
                        existingEntry.bacteria = this.state.bacteria?.organism_name
                        existingEntry.microscopy = this.state.microscopy
                        existingEntry.colony_count = this.state.colonyCount
                        existingEntry.method_type = this.state.cul_method
                        existingEntry.comments = this.state.cul_comments
                        if (this.state.EditedSubIndex || this.state.EditedSubIndex === 0) {
                            existingEntry.antibacteria[this.state.EditedSubIndex] = {
                                "antibiotic": this.state.antibacteria?.antibiotic_name ? this.state.antibacteria?.antibiotic_name : "",
                                "mic_break_point_std": this.state.breakPoint,
                                "zone_size_std": this.state.autoZone,
                                "mic_break_point": this.state.breakPointResult,
                                "zone_size": this.state.zoneResult,
                                "interpretation": this.state.Interpretation,
                                "methodThreeBacteriaResult": this.state.methodThreeBacteriaResult,
                                "antibiotic_master_id": this.state.antibacteria?.id ? this.state.antibacteria?.id : null,
                                "id": this.state.selectedAntiBioId?.id ? this.state.selectedAntiBioId?.id : null
                            };
                        } else {
                            existingEntry.antibacteria.push({
                                "antibiotic": this.state.antibacteria?.antibiotic_name ? this.state.antibacteria?.antibiotic_name : "",
                                "mic_break_point_std": this.state.breakPoint,
                                "zone_size_std": this.state.autoZone,
                                "mic_break_point": this.state.breakPointResult,
                                "zone_size": this.state.zoneResult,
                                "interpretation": this.state.Interpretation,
                                "antibiotic_master_id": this.state.antibacteria?.id ? this.state.antibacteria?.id : null,
                                "methodThreeBacteriaResult": this.state.methodThreeBacteriaResult,
                            });
                        }

                    } else {
                        existingEntry.bacteria = this.state.bacteria?.organism_name ? this.state.bacteria?.organism_name : ""
                        existingEntry.microscopy = this.state.microscopy
                        existingEntry.colony_count = this.state.colonyCount
                        existingEntry.method_type = this.state.cul_method
                        existingEntry.comments = this.state.cul_comments
                        existingEntry.antibacteria.push({
                            "antibiotic": this.state.antibacteria?.antibiotic_name ? this.state.antibacteria?.antibiotic_name : "",
                            "mic_break_point_std": this.state.breakPoint,
                            "zone_size_std": this.state.autoZone,
                            "mic_break_point": this.state.breakPointResult,
                            "zone_size": this.state.zoneResult,
                            "interpretation": this.state.Interpretation,
                            "antibiotic_master_id": this.state.antibacteria?.id ? this.state.antibacteria?.id : null,
                            "methodThreeBacteriaResult": this.state.methodThreeBacteriaResult,
                        });
                    }
                } else {
                    bacteriaResult.push(bacteriaData)
                }
                if (result_list.id === this.state.selectedRowId) {
                    if (result_list?.result?.value) {
                        result_list.result.value = bacteriaResult
                    } else {
                        result_list.result = { value: bacteriaResult }
                    }
                }
                this.setState({
                    methodThreeResult: bacteriaResult,
                    methodThreeBacteriaResult: "",
                    antibacteria: null,
                    Interpretation: "",
                    autoZone: "",
                    zoneResult: "",
                    breakPoint: "",
                    breakPointResult: "",
                    EditCulture: false,
                    EditedIndex: "",
                    EditedSubIndex: ""
                }, () => {
                    if (this.state.EditOrgCulture) {
                        this.clearCulture()
                    }
                })
            }
        }
    }

    checkDuplicate = () => {
        let { bacteriaList, antiBacteriaList } = this.state;
        let bacteriaHash = {};
        let antibacteriaHash = {};
        let duplicate = false;
        bacteriaList?.forEach(element => {
            bacteriaHash[element.organism_name] = element.organism_name
        })
        antiBacteriaList?.forEach(element => {
            antibacteriaHash[element.antibiotic_name] = element.antibiotic_name
        })
        let newArray = [];
        this.state.checkList?.forEach(element => {
            for (let i = 0; i < element.antibacteria.length; i++) {
                let desiredValue = element.antibacteria[i].antibiotic;
                newArray.push(desiredValue);
            }
            if (bacteriaHash[element.bacteria] === this.state.bacteria?.organism_name && antibacteriaHash[newArray] === this.state.antibacteria?.antibiotic_name) {
                duplicate = true
            }
        })
        return duplicate;
    }

    checkMethodFourValues = (result_list) => {
        const { t } = this.props
        if (result_list.id === this.state.selectedMethodFourRowId) {
            let row = result_list?.result?.value
            let paragraph = []; let table = []; let tableRow = []
            if (row) {
                if (row.impressions.length > 0) {
                    row.paragraph.map((item) => (paragraph = item.data))
                    if (paragraph.length > 0) {
                        row.table.map((item) => (item.noRows.map((val) => (
                            tableRow.push(item.noRows), val.value ? table.push(val.value) : ""))))
                        if (table.length === tableRow.length) {
                            this.setState({ methodThreeOpen: false })
                        } else {
                            this.setState({
                                isErrorMsg: true,
                                isErrorMsgText: t("ManitoryFields")
                            })
                        }
                    }
                }
            } else {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: t("ManitoryFields")
                })
            }
        }
    }

    async renderImage(imageData) {
        let tempArr = [];
        for (let i = 0; i < imageData.length; i++) {
            let image = imageData[i].result_image
            if (image !== null) {
                for (let j = 0; j < image.length; j++) {
                    let doc_key = image[j];
                    let id = imageData[i].id;
                    let getData = await this.getS3Key(doc_key, id)
                    tempArr.push(getData)
                }
            }
        }
        this.setState({
            uploadedImages: tempArr,
            selectedImage: null
        })
    }
    async getS3Key(doc_key, id) {
        try {
            const response = await RestAPIService.getAllTwo(Serviceurls.AWS_URL_GET + "?doc_key=" + encodeURIComponent(doc_key));
    
            if (response.data.status === "success") {
                const Data = response.data;
                return {
                    data_url: Data.data,
                    id: id,
                    type: doc_key?.split('.')?.pop() === "pdf" ? "pdf" : "image"
                };
            }
        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: "Error fetching S3 URL"
            });
        }
    }

    getPatientResultStatus = (defualtTab) => {
        try {
            if (!this.state.isImageSucc) {
                this.setState({ patientResultStatus: [], PrintTestList: [] })
            }
            let result_summary_id = this.state.patient_status?.result_summary_id ? this.state.patient_status?.result_summary_id : this.state.patient_status?.id;
            let setName = this.state.patient_status.set_name === null ? '' : encodeURIComponent(this.state.patient_status?.set_name)
            let testName = this.state.patient_status.test_name === null ? '' : encodeURIComponent(this.state.patient_status?.test_name)
            let querParams = this.state.fromTechnician ? "?result_summary_id=" + result_summary_id + "&test_name=" + testName + "&set_name=" + setName : `?result_summary_id=${result_summary_id}`
            RestAPIService.getAll(Serviceurls.LAB_PATIENT_RESULT_GET + querParams)
                .then((response) => {
                    if (response.data.status === "success") {

                        let newData = response.data.data
                        let imageData = []
                        newData.map((item) => {
                            item.data.forEach(element => {
                                if (element.is_outsource) {
                                    imageData.push(element)
                                }
                            })
                        })
                        if (imageData.length > 0) {
                            this.renderImage(imageData)
                        } else {
                            this.setState({
                                uploadedImages: []
                            })
                        }
                        // if(!this.state.isImageSucc){
                        this.setState({
                            patientResultStatus: response.data.data,
                            OrgPatientResultStatus: JSON.parse(JSON.stringify(response.data.data)),
                            LeftDrop: response.data?.esign?.esign_left && Object.keys(response.data?.esign?.esign_left).length > 0 ? response.data?.esign?.esign_left : null,
                            CentreDrop: response.data?.esign?.esign_center && Object.keys(response.data?.esign?.esign_center).length > 0 ? response.data?.esign?.esign_center : null,
                            RightDrop: response.data?.esign?.esign_right && Object.keys(response.data?.esign?.esign_right).length > 0 ? response.data?.esign?.esign_right : null,
                        }, () => {
                            let { patientResultStatus, showPrintBtn, PrintTestList } = this.state;
                            let is_outSource_test = false;
                            let print_list = patientResultStatus.map(item => (
                                item?.data?.length > 0 && item.data.filter(li => (li?.result?.value || li?.result_image != null) && li?.is_specimen_collected).map(test => {
                                    if (
                                        test?.result !== null &&
                                        typeof test?.result === "object" &&
                                        ("value" in test?.result) && test?.is_outsource === false
                                    ) {
                                        test["isChecked"] = true;
                                        return test; // Keep the test object in the mapped array
                                    } else if ((test?.result_image !== null && test?.result_image?.length !== 0)
                                        && test?.is_outsource) {
                                        is_outSource_test = true;
                                    }
                                })
                            ))
                            showPrintBtn = print_list?.some(item => item[0] !== undefined && item?.length > 0)
                            const initialCheckboxState = print_list?.map((group) =>
                                group?.map((item) => ({
                                    isChecked: item?.isChecked,
                                    id: item?.id,
                                }))
                            );
                            let InHouseCount = this.state.patientResultStatus.filter((list) => (!list.is_outsource));
                            let OutSourseCount = this.state.patientResultStatus.filter((list) => (list.is_outsource));
                            this.setState({
                                showPrintBtn,
                                enableFinishRpt: is_outSource_test,
                                PrintTestList: print_list,
                                checkboxState: initialCheckboxState,
                            },)
                            if(defualtTab){
                                this.setState({
                                    SelectedSource: InHouseCount.length > 0 ? 'Inhouse' : OutSourseCount.length > 0 ? 'Outsource' : 'Inhouse'
                                })
                            }
                        })
                        if (this.state.isImageSucc) {
                            let result = JSON.parse(JSON.stringify(this.state.patientResultStatus))
                            result.map((item) => (
                                item.data.forEach((element, index) => {
                                    if (element.is_outsource) {
                                        imageData.forEach((imgElement, imgIndex) => {
                                            if (element.id === imgElement.id) {
                                                element.result_image = imageData[imgIndex].result_image
                                            }
                                        })
                                    }
                                })
                            ))
                            this.setState({
                                patientResultStatus: result,
                                LeftDrop: response.data?.esign?.esign_left ? response.data?.esign?.esign_left : null,
                                CentreDrop: response.data?.esign?.esign_center ? response.data?.esign?.esign_center : null,
                                RightDrop: response.data?.esign?.esign_right ? response.data?.esign?.esign_right : null,
                            })
                        }
                    } else if (response.data.status === "fail") {
                        this.setState({
                            isErrorMsg: true,
                            isErrorMsgText: response.data.message
                        })
                    }
                }).catch((error) => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: error.message
                    })
                })
        } catch (error) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: t("ManitoryFields")
            })
        }
    }



    postPatientResultStatus = () => {
        try {
            this.setState({ disableBtn: true })
            let resultValue = [];
            if (this.state.selectedRows.length > 0) {
                this.state.selectedData?.map((item) => (
                    item.data.map((result) => (
                        result.is_outsource ? "" :
                            result.method === "Positive and Negative Results" || result.method === "Present and Absent Result" ?
                                resultValue.push({ "result_item_id": result.id, "result": { "value": result.result?.value ? result.result?.value : null, "others": result.result?.others ? result.result?.others : "", "comment": result?.result?.comment ? result?.result?.comment : "" }, 'is_finished': true, is_culture_test: false })
                                : result.method === "Radiology" ? resultValue.push({ "result_item_id": result.id, "result": { "value": result.result?.value ? result.result?.value : result.result }, 'is_finished': true, is_culture_test: false })
                                    : result.method === "Culture Test Result" ? resultValue.push({ "result_item_id": result.id, "result": { "value": result.result?.value ? result.result?.value : null, "comment": result?.result?.comment ? result?.result?.comment : "" }, 'is_finished': true, is_culture_test: true })
                                        : resultValue.push({ "result_item_id": result.id, "result": { "value": result.result?.value ? result.result?.value : null, "comment": result?.result?.comment ? result?.result?.comment : "" }, 'is_finished': true, is_culture_test: false })
                    ))
                ))
            } else {
                // this.state.patientResultStatus?.map((item) => (
                //     item.data.map((result) => (
                //         result.is_outsource ? "" :
                //             result.method === "Positive and Negative Results" || result.method === "Present and Absent Result" ?
                //                 resultValue.push({ "result_item_id": result.id, "result": { "value": result.result?.value ? result.result?.value : null, "comment": result?.result?.comment ? result?.result?.comment : "" }, 'is_finished': false })
                //                 :
                //                 result.method === "Radiology" ?
                //                     resultValue.push({ "result_item_id": result.id, "result": { "value": result.result?.value ? result.result?.value : result.result }, 'is_finished': false })
                //                     :
                //                     resultValue.push({ "result_item_id": result.id, "result": { "value": result.result?.value?.length > 0 ? result.result?.value : null }, 'is_finished': false })
                //     ))
                // ))
                this.state.patientResultStatus?.forEach((item) => {
                    item.data.filter((result) => !result.is_finished)
                        .forEach((result) => {
                            if (!result.is_outsource) {
                                if (result.method === "Positive and Negative Results" || result.method === "Present and Absent Result") {
                                    resultValue.push({ result_item_id: result.id, result: { value: result.result?.value || null, others: result?.result?.others || "", comment: result?.result?.comment || "" }, is_finished: false, is_culture_test: false });
                                } else if (result.method === "Radiology") {
                                    resultValue.push({ result_item_id: result.id, result: { value: result.result?.value || result.result }, is_finished: false, is_culture_test: false });
                                } else if (result.method === "Culture Test Result") {
                                    resultValue.push({ result_item_id: result.id, result: { value: result.result?.value?.length > 0 ? result.result?.value : null }, is_finished: false, is_culture_test: true, });
                                } else {
                                    resultValue.push({ result_item_id: result.id, result: { value: result.result?.value ? result.result?.value : null, comment: result?.result?.comment || "" }, is_finished: false, is_culture_test: false });
                                }
                            }
                        });
                });
            }
            let data = {
                "result_summary_id": this.state.patient_status?.result_summary_id ? this.state.patient_status?.result_summary_id : this.state.patient_status?.id,
                "result_line_items": resultValue,
                "esign_center": this.state.CentreDrop?.id ? this.state.CentreDrop?.id : null,
                "esign_left": this.state.LeftDrop?.id ? this.state.LeftDrop?.id : null,
                "esign_right": this.state.RightDrop?.id ? this.state.RightDrop?.id : null,
                "del_culture_summary_ids": this.state.DeletedBacteriaList,
                "del_culture_items_ids": this.state.DeletedAntiBacList,
                "clinical_remarks": this.state.clinicCmt || '',
                "overall_comments": this.state.overAllCmt || '',
                
            }
            RestAPIService.create(data, Serviceurls.LAB_PATIENT_RESULT_POST).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            isSuccessMsg: true,
                            isSuccessMsgText: response.data.message,
                            isComment: false,
                            clinicCmt: '',
                            overAllCmt: '',
                        })
                        this.getPatientResultStatus()
                        this.setState({ SaveChangesClick: true, disableBtn: false, DeletedAntiBacList: [], DeletedBacteriaList: [] })
                        if (this.state.selectedRows.length > 0) {
                            setTimeout(() => {
                                let { history } = this.props
                                history.push({ pathname: "/MainHome", state: { selectedScreen: "TestReport", selectedTabID: 2 } })
                            }, 500);
                        }
                    } else {
                        this.setState({
                            isErrorMsg: true,
                            isErrorMsgText: response.data.message,
                            disableBtn: false,
                        })
                    }
                }).catch(error => this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.response.data.message,
                    disableBtn: false,
                }))
        } catch (error) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: t("ManitoryFields"),
                disableBtn: false,
            })
        }
    }

    putPatientResultStatus = () => {
        try {
            let data = { "result_summary_id": this.state.patient_status?.result_summary_id ? this.state.patient_status?.result_summary_id : this.state.patient_status?.id,
                "clinical_remarks": this.state.clinicCmt || '',
                "overall_comments": this.state.overAllCmt || '',
             }
            this.setState({ disableBtn: true })
            RestAPIService.updateWithOutId(data, Serviceurls.LAB_PATIENT_RESULT_PUT).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            isComment: false,
                            clinicCmt: '',
                            overAllCmt: '',
                            isSuccessMsg: true,
                            isSuccessMsgText: response.data.message
                        }, () => {
                            setTimeout(() => {
                                this.setState({ isSuccessMsg: false, disableBtn: false })
                                // var { history } = this.props
                                // history.push({ pathname: "/MainHome" })
                                let { history } = this.props
                                history.push({ pathname: "/MainHome", state: { selectedScreen: "TestReport", selectedTabID: 2 } })
                            }, 500);
                        })
                        this.setState({
                            SaveChangesClick: false
                        })
                    } else {
                        this.setState({
                            isErrorMsg: true,
                            isErrorMsgText: response.data.message,
                            disableBtn: false,
                        })
                    }
                }).catch(error => this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.response.data.message,
                    disableBtn: false,
                }))
        } catch (error) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: t("ManitoryFields"),
                disableBtn: false,
            })
        }
    }

    dataURItoBlob = (dataURI) => {
        var byteString = atob(dataURI.split(',')[1]);
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    }

    uploadOutsourceImage = (list, isFinished = false) => {
        try {
            let result_item_id = [];
            if (isFinished) {
                list?.map((item) => (
                    item.data.map((result) => (
                        result.is_outsource ? (result_item_id.push(result.id)) : null
                    ))
                ))
            }
            const dataForm = new FormData();
            var { uploadedImages } = this.state;
            let i = 0
            if (!isFinished) {
                for (i; i < uploadedImages.length; i++) {
                    if (!uploadedImages[i].id) {
                        dataForm.append("result_images", uploadedImages[i])
                    }
                }
            }
            dataForm.append("result_item_id", isFinished ? `[${result_item_id}]` : list.id)
            dataForm.append("is_finished", isFinished)

            RestAPIService.patch(dataForm, Serviceurls.LAB_PATIENT_OUTSOURCE_PATCH).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            isSuccessMsg: true,
                            isSuccessMsgText: response.data.mesage,
                            isImageSucc: true
                        })
                        if (this.state.isImageSucc && !isFinished) {
                            this.getPatientResultStatus()
                        } else if (isFinished) {
                            let { history } = this.props
                            history.push({ pathname: "/MainHome", state: { selectedScreen: "TestReport", selectedTabID: 2 } })
                        }
                    }
                }).catch((error) => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: error.response.data.message
                    })
                })
        } catch (error) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: t("ImageError")
            })
        }
    }

    deleteUploadImages = (index, list) => {
        try {
            this.state.selectedDeletedIndex.push(index)
            let result_item_id;
            this.state.patientResultStatus?.map((item) => (
                item.data.map((result) => (
                    result.is_outsource ? (result_item_id = result.id, this.state.deleteDataList = result.result_image) : ""
                ))
            ))
            let daletedData = list.result_image.splice(index, 1)
            let data = {
                "result_item_id": list?.id,
                "result_images": list?.result_image?.length > 0 ? list.result_image : null
            }
            RestAPIService.delete(Serviceurls.LAB_PATIENT_RESULT_DELETE, data).
                then((response) => {
                    if (response.data.status === "success") {
                        this.onImageRemove(index);
                        this.setState({
                            isSuccessMsg: true,
                            isSuccessMsgText: response.data.message,
                            selectedImage: null
                        })
                    }
                }).catch((error) => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: error.response.data.message,
                        selectedImage: null
                    })
                })
        } catch (error) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: t("ImageError")
            })
        }
    }

    getResultPrint = () => {
        let { checkboxState } = this.state;
        let testIds = []
        this.setState({ isPrintClicked: true })
        checkboxState
            .flat()
            .filter((item) => item?.isChecked)
            .map((item) => testIds.push(item?.id));
        try {
            let result_summary_id = this.state.patient_status?.result_summary_id ? this.state.patient_status?.result_summary_id : this.state.patient_status?.id
            RestAPIService.getAll(Serviceurls.LAB_PATIENT_RESULT_PRINT + `?result_summary_id=${result_summary_id}&result_item_id=${testIds}`).
                then((response) => {
                    this.handleCancel()
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.setState({ isPrintClicked: false })
                }).catch((error) => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: error.message,
                        isPrintClicked: false,
                    })
                })
        } catch (error) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: t("PrintError"),
                isPrintClicked: false,
            })
        }
    }

    methodFourButtonPopUp = (result_list, isFinish = false, isSpeciman) => {
        try {
            const { t } = this.props
            const { patientResultStatus } = this.state
            let methodFour = result_list?.result?.value ? result_list.result.value : result_list.result
            return (
                <>
                    <Button variant='contained'
                        onClick={() => {
                            this.setState({
                                selectedMethodFourRowId: result_list.id,
                                selected_res: JSON.stringify(result_list)
                            })
                            this.methodThreehandleOpen()
                        }}
                        disabled={isSpeciman}
                        sx={{ textTransform: "capitalize" }}
                    >{methodFour !== "" ? t("updateResult") : t("EnterResult")}</Button>
                    {result_list.id === this.state.selectedMethodFourRowId ?
                        <Modal
                            open={this.state.methodThreeOpen}
                            onClose={this.methodThreehandleClose}
                        >
                            <Box className='emedhub_popup'>
                                <div className=''>
                                    <div className='emedhub_popup_title'>
                                        <label className='emedhub_popup_title_left'>{t("Test Report")}</label>
                                        <div className='emedhub_card_close_button'>
                                            <img className='emedhub_popup_title_right'
                                                src={ImagePaths.LabCloseButton.default}
                                                onClick={this.methodThreehandleClose}
                                                alt="Close"
                                            />
                                        </div>
                                    </div>
                                    <div className='emedhub_popup_title_2'>
                                        <Typography className='emedhub_popup_title_Header'>{t("Radiology")}</Typography>
                                        {/* <Autocomplete
                                className='emedhub_popup_title_dropdown'
                                size='small'
                                options={list.map((option) => option.sourceName)}
                                renderInput={(params) => <TextField {...params} label={t("Select Template")} />}
                            /> */}
                                    </div>
                                </div>
                                <div className='emedhub_card'>

                                    <div className='emedhub_popup_Impression_card'>
                                        <Typography className='emedhub_card_title'><b>{t("Impression")}</b></Typography>

                                        {((this.state.patient_status.status === "Result Completed") || (this.state.patient_status.status === "Result Delivered") || isFinish || isSpeciman || this.state.FromTestReport) ?
                                            methodFour?.impressions.map((item, impIndex) => (
                                                <div className='emedhub_card_input'>
                                                    <TextField
                                                        label={t("ImpressionLine") + ` ${impIndex + 1}`}
                                                        sx={{ marginBottom: "1vw", marginTop: "0.5vw" }}
                                                        multiline
                                                        value={methodFour["impressions"][impIndex]}
                                                        disabled
                                                    />
                                                </div>
                                            )) : methodFour?.impressions.map((item, impIndex) => (
                                                <div className='emedhub_card_input'>
                                                    <TextField
                                                        label={t("ImpressionLine") + ` ${impIndex + 1}`}
                                                        sx={{ marginBottom: "1vw", marginTop: "0.5vw" }}
                                                        multiline
                                                        value={methodFour["impressions"][impIndex]}
                                                        onChange={(e) => {
                                                            let Data = e.target.value
                                                            methodFour["impressions"][impIndex] = Data
                                                            if (result_list?.result?.value) {
                                                                result_list.result.value = methodFour
                                                            } else {
                                                                result_list.result = { value: methodFour }
                                                            }
                                                            this.setState({ patientResultStatus })
                                                        }}
                                                    />
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className='emedhub_popup_Paragraph_card'>
                                        <Typography className='emedhub_card_title'>
                                            <b>{t("Paragraph")}</b>
                                        </Typography>
                                        {((this.state.patient_status.status === "Result Completed") || (this.state.patient_status.status === "Result Delivered") || isFinish || isSpeciman || this.state.FromTestReport) ?
                                            methodFour?.paragraph.map((para, paraMainIndex) => (
                                                <div className='emedhub_card_input'>
                                                    <label><b>{para.title}</b></label>
                                                    {para.data.map((paraLine, paraIndex) => (
                                                        <TextField
                                                            sx={{ marginBottom: "0.5vw", marginTop: "1vw" }}
                                                            label={t("Paragraph") + ` ${paraIndex + 1}`}
                                                            multiline
                                                            value={methodFour["paragraph"][paraMainIndex]["data"][paraIndex]}
                                                            disabled
                                                        />
                                                    ))}
                                                </div>
                                            )) :
                                            methodFour?.paragraph.map((para, paraMainIndex) => (
                                                <div className='emedhub_card_input'>
                                                    <label><b>{para.title}</b></label>
                                                    {para.data.map((paraLine, paraIndex) => (
                                                        <TextField
                                                            sx={{ marginBottom: "0.5vw", marginTop: "1vw" }}
                                                            label={t("Paragraph") + ` ${paraIndex + 1}`}
                                                            multiline
                                                            value={methodFour["paragraph"][paraMainIndex]["data"][paraIndex]}
                                                            onChange={(e) => {
                                                                let Data = e.target.value
                                                                methodFour["paragraph"][paraMainIndex]["data"][paraIndex] = Data
                                                                if (result_list?.result?.value) {
                                                                    result_list.result.value = methodFour
                                                                    this.state.method.push(methodFour)
                                                                } else {
                                                                    result_list.result = { value: methodFour }
                                                                    this.state.method.push({ value: methodFour })
                                                                }
                                                                this.setState({ patientResultStatus })
                                                            }}
                                                        />
                                                    ))}
                                                </div>
                                            ))
                                        }

                                    </div>
                                    <div className='emedhub_popup_Table_card'>
                                        <Typography className='emedhub_card_title'><b>{t("Table")}</b></Typography>
                                        {((this.state.patient_status.status === "Result Completed") || (this.state.patient_status.status === "Result Delivered") || isFinish || isSpeciman || this.state.FromTestReport) ?
                                            methodFour?.table.map((tab, tabIndex) => (
                                                <div className=''>
                                                    <div style={{ margin: "0.5vw" }}>
                                                        <label><b>{tab.title}</b></label>
                                                    </div>
                                                    {tab.noRows.map((tabRow, rowIndex) => (
                                                        <div className='table_row_header'>
                                                            <div className='table_row'>
                                                                <label>{tabRow?.heading ? tabRow?.heading : tabRow}</label>
                                                            </div>
                                                            <div className='table_row_input'>
                                                                <TextField
                                                                    size='small'
                                                                    style={{ marginBottom: "0.5vw" }}
                                                                    value={methodFour["table"][tabIndex]["noRows"][rowIndex].value ? methodFour["table"][tabIndex]["noRows"][rowIndex].value : ""}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )) : methodFour?.table.map((tab, tabIndex) => (
                                                <div className=''>
                                                    <div style={{ margin: "0.5vw" }}>
                                                        <label><b>{tab.title}</b></label>
                                                    </div>
                                                    {tab.noRows.map((tabRow, rowIndex) => (
                                                        <div className='table_row_header'>
                                                            <div className='table_row'>
                                                                <label>{tabRow?.heading ? tabRow?.heading : tabRow}</label>
                                                            </div>
                                                            <div className='table_row_input'>
                                                                <TextField
                                                                    size='small'
                                                                    style={{ marginBottom: "0.5vw" }}
                                                                    value={methodFour["table"][tabIndex]["noRows"][rowIndex].value ? methodFour["table"][tabIndex]["noRows"][rowIndex].value : ""}
                                                                    onChange={(e) => {
                                                                        let Data = e.target.value
                                                                        let obj = { id: (rowIndex + 1), value: Data, heading: tabRow.heading ? tabRow.heading : tabRow }
                                                                        methodFour["table"][tabIndex]["noRows"][rowIndex] = obj
                                                                        if (result_list?.result?.value) {
                                                                            result_list.result.value = methodFour
                                                                            this.state.method.push(methodFour)
                                                                        } else {
                                                                            result_list.result = { value: methodFour }
                                                                            this.state.method.push({ value: methodFour })
                                                                        }
                                                                        this.setState({ patientResultStatus })
                                                                    }}

                                                                />
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                {((this.state.patient_status.status === "Result Completed") || (this.state.patient_status.status === "Result Delivered") || isFinish || isSpeciman || this.state.FromTestReport) ? ""
                                    : <div className='emedhub_popup_button '>
                                        <Button
                                            variant='outlined'
                                            sx={{ marginRight: "1vw", textTransform: "capitalize" }}
                                            onClick={() => {
                                                if (result_list.id === this.state.selectedMethodFourRowId) {
                                                    let res = JSON.parse(this.state.selected_res)
                                                    result_list.result = res.result
                                                    this.setState({ patientResultStatus })
                                                }
                                            }}
                                        >{t("Reset")}</Button>
                                        <Button
                                            variant='contained'
                                            sx={{ marginRight: "1vw", textTransform: "capitalize" }}
                                            onClick={() => this.checkMethodFourValues(result_list)}
                                        >{t("SaveChanges")}</Button>
                                    </div>}
                            </Box>
                        </Modal> : null
                    }
                </>
            )
        } catch (error) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: t("ManitoryFields")
            })
        }
    }

    deleteBacteria = (index, result_list, OrgItem) => {
        let DeletedBacteriaList = this.state.DeletedBacteriaList
        DeletedBacteriaList.push(OrgItem?.id)
        result_list.result.value.splice(index, 1);
        let dataArray = result_list.result.value
        this.setState({
            methodThreeResult: dataArray,
            methodThreeBacteriaResult: "",
            DeletedBacteriaList: DeletedBacteriaList
        }, () => { this.clearCulture() });
    }

    deleteData = (parentIndex, dataIndex, result_list, AntiBioItem) => {
        let DeletedAntiBacList = this.state.DeletedAntiBacList
        DeletedAntiBacList.push(AntiBioItem?.id)
        result_list.result.value[parentIndex].antibacteria.splice(dataIndex, 1);
        let dataArray = result_list.result.value
        this.setState({
            methodThreeResult: dataArray,
            methodThreeBacteriaResult: "",
            DeletedAntiBacList: DeletedAntiBacList,
        }, () => { this.clearCulture() });
    }

    EditData = (Data, SubData, Index, subIndex) => {
        let bacteriaData = {}
        let antibacteriaData = {}
        if (Data?.bacteria) {
            bacteriaData = this.state.bacteriaList.find((list) => list.organism_name === Data.bacteria)
        } else { bacteriaData = null }
        if (SubData?.antibiotic) {
            antibacteriaData = this.state.FullantiBacteriaList.find((list) => (list?.organism_name === Data.bacteria && list?.antibiotic_name === SubData.antibiotic))
        } else { antibacteriaData = null }
        this.setState({
            EditCulture: true,
            EditedIndex: Index,
            EditedSubIndex: subIndex,
            bacteria: bacteriaData,
            selectedId: Data?.id,
            colonyCount: Data.colony_count,
            microscopy: Data.microscopy,
            antibacteria: antibacteriaData,
            breakPointResult: SubData.mic_break_point,
            zoneResult: SubData.zone_size,
            Interpretation: SubData.interpretation,
            methodThreeBacteriaResult: SubData.methodThreeBacteriaResult,
            breakPoint: SubData.mic_break_point_std,
            autoZone: SubData.zone_size_std,
            cul_method: Data.method_type,
            cul_comments: Data.comments,
            selectedAntiBioId: SubData,
            antiBacteriaList: this.state.FullantiBacteriaList.filter((list) => (list?.organism_name === Data.bacteria))
        })
    }

    EditOrgData = (Index, Data) => {
        let bacteriaData = {}
        if (Data?.bacteria) {
            bacteriaData = this.state.bacteriaList.find((list) => list.organism_name === Data.bacteria)
        } else { bacteriaData = null }
        this.setState({
            EditOrgCulture: true,
            EditedIndex: Index,
            bacteria: bacteriaData,
            selectedId: Data?.id,
            colonyCount: Data.colony_count,
            microscopy: Data.microscopy,
            cul_method: Data.method_type,
            cul_comments: Data.comments,
            antiBacteriaList: this.state.FullantiBacteriaList?.filter((list) => list?.organism_name === Data.bacteria)
        })
    }

    methodThreeButtonPopUp = (result_list, isFinish = false, isSpeciman) => {
        try {
            const { t } = this.props
            let states = this.state
            const Interpretation = [
                {
                    result_name: "Sensitive", value: 0
                }, {
                    result_name: "Intermediate", value: 1
                }, {
                    result_name: "Resistant", value: 2
                }
            ]

            let { patientResultStatus, methodThreeResult, selectedSubIndex } = this.state;
            var dataSet = [];
            if (result_list?.result != null && result_list.result?.value?.length > 0) {
                dataSet = result_list?.result?.value;
                this.state.checkList = result_list.result?.value;
            } else {
                dataSet = methodThreeResult;
                this.state.checkList = methodThreeResult;
            }
            return (
                <>
                    <Button
                        variant='contained'
                        onClick={() => {
                            this.setState({
                                selectedRowId: result_list?.id,
                                selected_res_three: JSON.stringify(result_list)
                            })
                            this.methodFourhandleOpen()
                            this.GetCultureTestMasters()
                        }}
                        disabled={isSpeciman}
                        sx={{ textTransform: "capitalize" }}
                    >{((this.state.patient_status?.status === "Result Completed") || (this.state.patient_status?.status === "Result Delivered") || isFinish) ? "View Result" : result_list?.result?.value?.length > 0 ? t("updateResult") : t("EnterResult")}</Button>
                    {result_list.id === this.state.selectedRowId ?
                        <Modal
                            open={this.state.methodFourOpen}
                            onClose={() => this.methodFourHandleClose()}>
                            <Box className='emedhub_popup' sx={{ width: "80vw" }}>
                                <div className=''>
                                    <div className='emedhub_popup_title'>
                                        <label className='emedhub_popup_title_left'>{t("TestReport")}</label>
                                        <div className='emedhub_card_close_button'>
                                            <img
                                                className='emedhub_popup_title_right'
                                                src={ImagePaths.LabCloseButton.default}
                                                onClick={() => {
                                                    if (result_list.id === this.state.selectedRowId) {
                                                        let res = JSON.parse(this.state.selected_res_three)
                                                        result_list.result = res.result
                                                        this.setState({ patientResultStatus })
                                                    }
                                                    this.setState({
                                                        methodThreeResult: [],
                                                        DeletedAntiBacList: [],
                                                        DeletedBacteriaList: [],
                                                        patientResultStatus
                                                    }, () => {
                                                        this.clearCulture()
                                                        this.methodFourHandleClose()
                                                    })
                                                }}
                                                alt='close'
                                            />
                                        </div>
                                    </div>
                                    <div className='emedhub_popup_title_2' style={{ marginTop: "0.5vw" }}>
                                        <Typography className='emedhub_popup_title_Header'>{t("CulptureTest")}</Typography>
                                    </div>
                                </div>
                                <div style={{ width: "77.5vw", height: "33.5vw", marginTop: "1vw" }}>
                                    {((this.state.patient_status?.status === "Result Completed") || (this.state.patient_status?.status === "Result Delivered") || isFinish || isSpeciman || this.state.FromTestReport) ?
                                        <Typography sx={{ margin: "auto" }}></Typography> :
                                        <div className='emedhub_method_four_card_item'>
                                            <div className='emedhub_culture_screen' >
                                                <div>
                                                    <Autocomplete
                                                        size='small'
                                                        options={this.state.bacteriaList}
                                                        getOptionLabel={(option) => (option?.organism_name)}
                                                        disabled={this.state.EditCulture || this.state.EditOrgCulture}
                                                        value={this.state?.bacteria}
                                                        onChange={(event, newValue) => {
                                                            states.bacteria = newValue
                                                            states.antibacteria = null
                                                            states.antiBacteriaList = this.state.FullantiBacteriaList?.filter((list) => list?.organism_id === newValue?.id)
                                                            this.setState({ states })
                                                            // this.setState({ states }, () => {
                                                            //     this.setState({ Interpretation: "", autoZone: "", breakPoint: "", microscopy: "", colonyCount: "", cul_comments: '', cul_method: '', })
                                                            // })
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} style={{ width: "20vw" }}
                                                                label={'Select Isolated Organism*'}
                                                            // onChange={(e) => {
                                                            //     this.setState({ Interpretation: "", autoZone: "", breakPoint: "", microscopy: "", colonyCount: "" },
                                                            //         () => {
                                                            //             let data = { id: "", name: e.target.value }
                                                            //             this.setState({ bacteria: data })
                                                            //         })
                                                            // }}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div>
                                                    <TextField
                                                        size='small'
                                                        label={t("Microscopy")}
                                                        disabled={this.state.EditCulture}
                                                        value={this.state.microscopy}
                                                        onChange={(e) => this.onChangeHandler(e, "microscopy")}
                                                    />
                                                </div>
                                                <div>
                                                    <TextField
                                                        size='small'
                                                        label={t("Method Type")}
                                                        inputProps={{ maxLength: 50 }}
                                                        value={this.state.cul_method}
                                                        disabled={this.state.EditCulture}
                                                        onChange={(e) => this.onChangeHandler(e, "cul_method")}
                                                    />
                                                </div>
                                                <div>
                                                    <TextField
                                                        size='small'
                                                        label={t("Colony Count")}
                                                        value={this.state.colonyCount}
                                                        disabled={this.state.EditCulture}
                                                        onChange={(e) => this.onChangeHandler(e, "colonyCount")}
                                                    />
                                                </div>
                                                <div>
                                                    <TextField
                                                        size='small'
                                                        label={t("Comments")}
                                                        inputProps={{ maxLength: 100 }}
                                                        value={this.state.cul_comments}
                                                        disabled={this.state.EditCulture}
                                                        onChange={(e) => this.onChangeHandler(e, "cul_comments")}
                                                    />
                                                </div>
                                            </div>
                                            <div className='emedhub_culture_screen' style={{ marginTop: "1vw" }}>
                                                <div>
                                                    <Autocomplete
                                                        size='small'
                                                        options={this.state?.antiBacteriaList}
                                                        disabled={!this.state?.bacteria?.id}
                                                        getOptionLabel={(option) => (option?.antibiotic_name)}
                                                        value={this.state?.antibacteria}
                                                        onChange={(event, newValue) => {
                                                            states.antibacteria = newValue
                                                            this.setState({ states, Interpretation: "" })
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} style={{ width: "16vw" }}
                                                                label={'Select Antibiotic'}
                                                            // onChange={(e) => {
                                                            //     let data = { id: "", name: e.target.value }
                                                            //     this.setState({ antibacteria: data })
                                                            // }}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div>
                                                    <FormControl size='small' sx={{ width: "11vw" }}>
                                                        <InputLabel>{t("Interpretation")}</InputLabel>
                                                        <Select
                                                            label={t("Interpretation")}
                                                            value={this.state.Interpretation}
                                                            onChange={(e) => this.onChangeHandler(e, "Interpretation")}
                                                            MenuProps={{
                                                                style: { maxHeight: 350 },
                                                            }}
                                                        >
                                                            {Interpretation.map((item) => {
                                                                return <MenuItem value={item?.result_name} key={item?.value}>{item?.result_name}</MenuItem>
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                <TextField
                                                    size='small'
                                                    label={t("Zone Size (MM)")}
                                                    value={this.state.zoneResult}
                                                    onChange={(e) => {
                                                        let value = e.target.value
                                                        if (value.length <= 15) {
                                                            this.setState({ zoneResult: e.target.value })
                                                        }
                                                    }}
                                                />
                                                <TextField
                                                    size='small'
                                                    label={t("MIC Break Point")}
                                                    value={this.state.breakPointResult}
                                                    onChange={(e) => {
                                                        let value = e.target.value
                                                        if (value.length <= 15) {
                                                            this.setState({ breakPointResult: e.target.value })
                                                        }
                                                    }}
                                                />
                                                <div>
                                                    <TextField
                                                        size='small'
                                                        label={t("Efficacy Ratio")}
                                                        value={this.state.methodThreeBacteriaResult}
                                                        onChange={(e) => this.onChangeHandler(e, "methodThreeBacteriaResult")}
                                                    />
                                                </div>
                                                <Button
                                                    variant='contained'
                                                    style={{ height: "2.5vw" }}
                                                    onClick={() => {
                                                        this.submitBacteria(dataSet, result_list)
                                                    }}
                                                >{t("Add")}</Button>
                                                <Button
                                                    variant='outlined'
                                                    style={{ height: "2.5vw" }}
                                                    onClick={() => {
                                                        this.clearCulture()
                                                    }}
                                                >{t("Clear")}</Button>
                                            </div>
                                        </div>
                                    }
                                    <div style={{ height: ((this.state.patient_status?.status === "Result Completed") || (this.state.patient_status?.status === "Result Delivered")) ? "34vw" : "26vw", overflow: "scroll", marginTop: "1vw" }}>
                                        {result_list?.result?.value?.length > 0 ? result_list?.result?.value?.map((items, indexs) => (
                                            <div className="emedhub_card_method_4_table">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "75vw", marginTop: "1.3vw", marginBottom: "1vw" }}>
                                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                        <Box component={'div'}>
                                                            <Typography sx={{ fontSize: '.8vw' }}>{`ORGANISM ISOLATED 0${indexs + 1} : `}</Typography>
                                                            <Typography sx={{ fontWeight: 600, }}>
                                                                {items?.bacteria ? items?.bacteria?.length > 25 ?
                                                                    <Tooltip placement='top' title={items?.bacteria}>
                                                                        <Typography sx={{ fontSize: '.8vw', fontWeight: 600, }}>
                                                                            {items?.bacteria.slice(0, 25) + '...'}
                                                                        </Typography>
                                                                    </Tooltip> :
                                                                    <Typography sx={{ fontSize: '.8vw', fontWeight: 600, }}>{items?.bacteria}</Typography> : '-'}
                                                            </Typography>
                                                        </Box>
                                                    </div>
                                                    <Box component={'div'}>
                                                        <Typography sx={{ fontSize: '.8vw' }}>{`MICROSCOPY : `}</Typography>
                                                        <Typography sx={{ fontWeight: 600, }}>
                                                            {items?.microscopy ? items?.microscopy?.length > 25 ?
                                                                <Tooltip placement='top' title={items?.microscopy}>
                                                                    <Typography sx={{ fontSize: '.8vw', fontWeight: 600, }}>
                                                                        {items?.microscopy.slice(0, 25) + '...'}
                                                                    </Typography>
                                                                </Tooltip> :
                                                                <Typography sx={{ fontSize: '.8vw', fontWeight: 600, }}>{items?.microscopy}</Typography> : '-'}
                                                        </Typography>
                                                    </Box>
                                                    <Box component={'div'}>
                                                        <Typography sx={{ fontSize: '.8vw' }}>{`COLONY COUNT : `}</Typography>
                                                        <Typography sx={{ fontWeight: 600, }}>
                                                            {items?.colony_count ? items?.colony_count?.length > 20 ?
                                                                <Tooltip placement='top' title={items?.colony_count}>
                                                                    <Typography sx={{ fontSize: '.8vw', fontWeight: 600, }}>
                                                                        {items?.colony_count.slice(0, 20) + '...'}
                                                                    </Typography>
                                                                </Tooltip> :
                                                                <Typography sx={{ fontSize: '.8vw', fontWeight: 600, }}>{items?.colony_count}</Typography> : '-'}
                                                        </Typography>
                                                    </Box>
                                                    <Box component={'div'}>
                                                        <Typography sx={{ fontSize: '.8vw' }}>{`METHOD : `}</Typography>
                                                        <Typography sx={{ fontWeight: 600, }}>
                                                            {items?.method_type ? items?.method_type?.length > 25 ?
                                                                <Tooltip placement='top' title={items?.method_type}>
                                                                    <Typography sx={{ fontSize: '.8vw', fontWeight: 600, }}>
                                                                        {items?.method_type.slice(0, 25) + '...'}
                                                                    </Typography>
                                                                </Tooltip> :
                                                                <Typography sx={{ fontSize: '.8vw', fontWeight: 600, }}>{items?.method_type}</Typography> : '-'}
                                                        </Typography>
                                                    </Box>
                                                    <Box component={'div'}>
                                                        <Typography sx={{ fontSize: '.8vw', marginLeft: ".3vw" }}>{`Completed Date & Time`}</Typography>
                                                        <Typography sx={{ fontSize: '.8vw', fontWeight: 600, marginLeft: "0.3vw" }}>{items?.completed_date ? DateTime.fromJSDate(new Date(items?.completed_date)).toFormat('dd-MM-yyyy hh:mm a') : '-'}</Typography>
                                                    </Box>
                                                    <Box component={'div'} sx={{ display: "flex", }}>
                                                        <div style={{ marginRight: "0.5vw" }}><CommonEditButton disable={((this.state.patient_status?.status === "Result Completed") || (this.state.patient_status?.status === "Result Delivered") || result_list.result.value[indexs].completed_date || isFinish || isSpeciman || this.state.FromTestReport || this.state.EditCulture) ? true : false} size="1.5vw" onClick={() => { this.EditOrgData(indexs, items) }} /></div>
                                                        <div style={{ marginRight: "0.5vw" }}><CommonDeleteButton disable={((this.state.patient_status?.status === "Result Completed") || (this.state.patient_status?.status === "Result Delivered") || result_list.result.value[indexs].completed_date || isFinish || isSpeciman || this.state.FromTestReport) ? true : false} size="1.5vw" onClick={() => { this.deleteBacteria(indexs, result_list, items) }} /></div>
                                                        {isFinish || isSpeciman || this.state.FromTestReport ? null : <div style={{ marginRight: "0.5vw" }}>
                                                            <Tooltip title="Complete" placement="top" arrow>
                                                                <Button className="eMed_Edit_btn eMed_usrconf_btn" disabled={result_list.result.value[indexs].completed_date} size='small'>
                                                                    <img src={result_list.result.value[indexs].completed_date ? ImagePaths.disableTick.default : ImagePaths.enableTick.default} style={{ height: "1.5vw", width: "1.5vw" }} alt='Delete'
                                                                        onClick={() => {
                                                                            let CompleTime = DateTime.fromJSDate(new Date()).toLocaleString(DateTime.TIME_24_SIMPLE);
                                                                            result_list.result.value[indexs].completed_date = `${DateTime.fromJSDate(new Date()).toFormat("yyyy-MM-dd")} ${CompleTime}:00`
                                                                            this.setState({ patientResultStatus }, () => { this.clearCulture() })
                                                                        }} />
                                                                </Button>
                                                            </Tooltip>
                                                        </div>}
                                                    </Box>
                                                </div>
                                                <TableContainer style={{ maxHeight: "35vh" }}>
                                                    <Table style={{ border: "1px solid gray" }} stickyHeader>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("SNo")}</b></TableCell>
                                                                <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("Antibiotic")}</b></TableCell>
                                                                <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("MIC Result/Range")}</b></TableCell>
                                                                <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("Zone Result/Range")}</b></TableCell>
                                                                <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("Interpretaion")}</b></TableCell>
                                                                <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("Efficacy Ratio")}</b></TableCell>
                                                                <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("Action")}</b></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {items?.antibacteria?.map((item, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell>{index + 1}</TableCell>
                                                                    <TableCell>
                                                                        {item?.antibiotic ? item?.antibiotic?.length > 20 ?
                                                                            <Tooltip placement='top' title={item?.antibiotic}>
                                                                                <Typography>
                                                                                    {item?.antibiotic.slice(0, 20) + '...'}
                                                                                </Typography>
                                                                            </Tooltip> :
                                                                            <Typography>{item?.antibiotic}</Typography> : '-'}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                                                            <Typography>{item?.mic_break_point ? item?.mic_break_point?.length > 20 ?
                                                                                <Tooltip placement='top' title={item?.mic_break_point}>
                                                                                    <div>{item?.mic_break_point.slice(0, 20) + '...' + " "}</div>
                                                                                </Tooltip> : (item?.mic_break_point + " ") : '-'}</Typography>
                                                                            {item?.mic_break_point_std ?
                                                                                <Typography style={{ marginLeft: "0.25vw" }}>{item?.mic_break_point_std ? item?.mic_break_point_std?.length > 20 ?
                                                                                    <Tooltip placement='top' title={item?.mic_break_point_std}>
                                                                                        <div>{" / " + item?.mic_break_point_std.slice(0, 20) + '...'}</div>
                                                                                    </Tooltip> : (" / " + item?.mic_break_point_std) : '-'}</Typography> : null}
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                                                            <Typography>{item?.zone_size ? item?.zone_size?.length > 20 ?
                                                                                <Tooltip placement='top' title={item?.zone_size}>
                                                                                    <div>{item?.zone_size.slice(0, 20) + '...' + " "}</div>
                                                                                </Tooltip> : (item?.zone_size + " ") : '-'}</Typography>
                                                                            {item?.zone_size_std ?
                                                                                <Typography style={{ marginLeft: "0.25vw" }}>{item?.zone_size_std ? item?.zone_size_std?.length > 20 ?
                                                                                    <Tooltip placement='top' title={item?.zone_size_std}>
                                                                                        <div>{" / " + item?.zone_size_std.slice(0, 20) + '...'}</div>
                                                                                    </Tooltip> : (" / " + item?.zone_size_std) : '-'}</Typography> : null
                                                                            }
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item?.interpretation ? item?.interpretation?.length > 20 ?
                                                                            <Tooltip placement='top' title={item?.interpretation}>
                                                                                <Typography>
                                                                                    {item?.interpretation.slice(0, 20) + '...'}
                                                                                </Typography>
                                                                            </Tooltip> :
                                                                            <Typography>{item?.interpretation}</Typography> : '-'}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item?.methodThreeBacteriaResult ? item?.methodThreeBacteriaResult?.length > 20 ?
                                                                            <Tooltip placement='top' title={item?.methodThreeBacteriaResult}>
                                                                                <Typography>
                                                                                    {item?.methodThreeBacteriaResult.slice(0, 20) + '...'}
                                                                                </Typography>
                                                                            </Tooltip> :
                                                                            <Typography>{item?.methodThreeBacteriaResult}</Typography> : '-'}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <CommonEditButton disable={((this.state.patient_status?.status === "Result Completed") || (this.state.patient_status?.status === "Result Delivered") || result_list.result.value[indexs].completed_date || isFinish || isSpeciman || this.state.FromTestReport) ? true : false} size="1.5vw" onClick={() => { this.EditData(items, item, indexs, index) }} />
                                                                        <CommonDeleteButton disable={((this.state.patient_status?.status === "Result Completed") || (this.state.patient_status?.status === "Result Delivered") || result_list.result.value[indexs].completed_date || isFinish || isSpeciman || this.state.FromTestReport) ? true : false} size="1.5vw" onClick={() => { this.deleteData(indexs, index, result_list, item) }} />
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </div>
                                        )) : <div style={{ marginTop: "1.5vw", width: "75vw", marginLeft: "1.25vw", height: "50vh" }}>
                                            <TableContainer style={{ border: "1px solid black" }}>
                                                <Table stickyHeader>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("SNo")}</b></TableCell>
                                                            <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("Antibiotic")}</b></TableCell>
                                                            <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("MIC Result/Range")}</b></TableCell>
                                                            <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("Zone Result/Range")}</b></TableCell>
                                                            <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("Interpretaion")}</b></TableCell>
                                                            <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("Efficacy Ratio")}</b></TableCell>
                                                            <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("Action")}</b></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                </Table>
                                            </TableContainer>
                                            <div style={{ marginTop: "10vw", marginLeft: "32vw" }}>
                                                <div>No Records To Be Shown</div>
                                            </div>
                                        </div>}
                                    </div>
                                </div>


                                {((this.state.patient_status?.status === "Result Completed") || (this.state.patient_status?.status === "Result Delivered") || isFinish || isSpeciman || this.state.FromTestReport) ?
                                    "" :
                                    <div className='emedhub_popup_button method_four'>
                                        <Button
                                            variant='outlined'
                                            sx={{ marginRight: "1vw", textTransform: "capitalize" }}
                                            onClick={() => {
                                                if (result_list.id === this.state.selectedRowId) {
                                                    let res = JSON.parse(this.state.selected_res_three)
                                                    result_list.result = res.result
                                                    this.setState({ patientResultStatus })
                                                }
                                                this.setState({
                                                    methodThreeResult: [],
                                                    patientResultStatus
                                                }, () => { this.clearCulture() })
                                            }}
                                        >{t("Reset")}</Button>
                                        <Button variant='contained'
                                            onClick={() => {
                                                this.setState({
                                                    methodFourOpen: false,
                                                    methodThreeResult: []
                                                }, () => { this.clearCulture() })
                                            }}
                                            sx={{ textTransform: "capitalize" }}>{t("Continue")}</Button>
                                    </div>
                                }
                            </Box>
                        </Modal> : null}
                </>
            )
        } catch (error) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: t("ManitoryFields")
            })
        }
    }

    clearCulture = () => {
        this.setState({
            bacteria: null,
            antibacteria: null,
            microscopy: "",
            colonyCount: "",
            EditCulture: false,
            EditedIndex: "",
            EditedSubIndex: "",
            Interpretation: "",
            autoZone: "",
            zoneResult: "",
            breakPoint: "",
            breakPointResult: "",
            methodThreeBacteriaResult: "",
            cul_method: '',
            cul_comments: '',
            EditOrgCulture: false,
            selectedAntiBioId: null
        })
    }

    onImageRemove = (index) => {
        let deletedArr = [...this.state.uploadedImages];
        deletedArr.splice(index, 1);
        this.setState({
            uploadedImages: [...deletedArr]
        })
    }

    fetchAndConvertToBase64 = async (url) => {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const blob = await response.blob();
            const reader = new FileReader();
            return new Promise((resolve, reject) => {
                reader.onloadend = () => resolve(reader?.result);
                reader.onerror = () => reject(reader?.error);
                reader.readAsDataURL(blob);
            });
        } catch (error) {
            console.error('Error fetching or converting file:', error);
        }
    }


    renderSourcePopUp = (list, subIndex) => {
        const { t } = this.props
        return (
            <>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Button
                        variant='contained'
                        sx={{ textTransform: "capitalize" }}
                        disabled={list?.outsource_status !== "Received" && list?.outsource_status !== "Result Uploaded"}
                        onClick={() => {
                            this.setState({ selectedImageRowId: list.id })
                            this.sourceOpen(list, subIndex)
                        }}>{list?.is_finished || this.state.FromTestReport ? t("View PDF") : t("Upload PDF")}
                    </Button>
                    {list?.outsource_status && list?.outsource_status !== "Received" ? <Typography sx={{ fontSize: '0.8vw', color: 'gray', textAlign: 'center', textTransform: "capitalize", marginLeft: "2vw" }}>{`Note : ${list?.outsource_status ? list?.outsource_status : "-"}`}</Typography> : null}
                </Box>
                <Modal
                    open={this.state.sourceOpen && list.id == this.state.selectedImageRowId}
                    onClose={this.sourceClose}
                >
                    <Box className='emedhub_popup_outsource'>
                        <div className=''>
                            <div className='emedhub_popup_title'>
                                <label className='emedhub_popup_title_left'>{t("TestReport")}</label>
                                <div className='emedhub_card_close_button'>
                                    <img
                                        className='emedhub_popup_title_right'
                                        src={ImagePaths.LabCloseButton.default}
                                        onClick={this.sourceClose}
                                        alt="close"
                                    />
                                </div>
                            </div>
                            {/* <div className='emedhub_popup_title_2'>
                                <Typography className='emedhub_popup_title_Header'>{t("SculptureTest")}</Typography>
                            </div> */}
                        </div>
                        <div className='emedhub_print_popup'  >
                            <input
                                type="file"
                                multiple
                                // accept="application/pdf, image/jpg, image/jpeg, image/png"
                                accept="application/pdf"
                                onChange={(e) => this.onChange(e)}
                                ref={this.uploadImage}
                                style={{ display: "none" }}
                            />
                            {/* <ImageUploading
                                multiple
                                value={this.state.uploadedImages}
                                onChange={this.onChange}
                                maxNumber={5}
                                dataURLKey="data_url"
                            >
                                {({
                                    imageList,
                                    onImageUpload,
                                    onImageRemove
                                }) => ( */}
                            <div>
                                <div className='image_top_header'>
                                    <label className='test_name_style'><b>{t("TestName")}:</b>
                                        <span>{" " + this.state.selectedUploadData?.test_name}</span></label>


                                    {((this.state.patient_status.status === "Result Completed") || (this.state.patient_status.status === "Result Delivered") || list?.is_finished || list?.is_finished || this.state.FromTestReport) ? "" :
                                        <div className='emedhub_image_upload_button'>
                                            <button className='image_upload_button' onClick={() => this.uploadImage.current.click()}>{t("Upload PDF")}</button></div>}

                                </div>
                                {/* <div>
                                            <div className='emedhub_image_size'>
                                                {this.state.uploadedImages?.length > 0 ? <img src={this.state.selectedImage} className='emedhub_image_resolution' alt='' /> : ""}
                                            </div>
                                        </div> */}
                                <div style={{ display: "flex", width: "100%", overflow: "auto", overflowY: "hidden" }}>
                                    {this.state.uploadedImages?.map((item, index) => (
                                        <div key={index} className='emedhub_multiple_image_size'>
                                            <img
                                                alt="image"
                                                src={item?.type === "image" ? item["data_url"] : (item?.type === "application/pdf" || item?.type === "pdf") ? ImagePaths.DocumentPreview.default : URL.createObjectURL(item)}
                                                className='emedhub_multiple_image_resolution'
                                                onClick={() => {
                                                    if (item?.type === "pdf") {
                                                        this.fetchAndConvertToBase64(item["data_url"]).then((base64) => {
                                                            base64 = base64?.replace("data:binary/octet-stream;", "data:application/pdf;");
                                                            this.setState({ pdfPreview: true, pdfData: base64 })
                                                        });
                                                    } else if (item?.type === "application/pdf") {
                                                        this.setState({ pdfPreview: true, pdfData: URL.createObjectURL(item) })
                                                    } 
                                                    this.setState({
                                                        selectedImage: item?.type === "image" ?
                                                            item["data_url"] : item?.type === "pdf"
                                                                ? ImagePaths.DocumentPreview.default : URL.createObjectURL(item)
                                                    })
                                                }} />
                                            {(this.state.patient_status.status === "Result Completed") || (this.state.patient_status.status === "Result Delivered") || list?.is_finished || this.state.FromTestReport ? "" :
                                                <div>
                                                    <IconButton onClick={() => {
                                                        this.setState({
                                                            selectedImage: null
                                                        })
                                                        if (item?.type === "image" || item?.type === "pdf") { // delete api call
                                                            this.deleteUploadImages(index, this.state.selectedUploadData)
                                                        } else {
                                                            this.onImageRemove(index);
                                                        }
                                                    }}
                                                        size='small'
                                                        className='emedhub_icon_style'
                                                    ><Close fontSize='small' /></IconButton>
                                                </div>}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {/* )}
                            </ImageUploading> */}
                        </div>
                        {((this.state.patient_status.status === "Result Completed") || (this.state.patient_status.status === "Result Delivered") || list?.is_finished || this.state.FromTestReport) ? "" :
                            <div className='emedhub_popup_button method_four'>
                                <Button variant='outlined'
                                    onClick={() => {
                                        let image = [];
                                        this.state.patientResultStatus?.map((item) => (
                                            item.data.forEach(element => {
                                                if (element.id === this.state.selectedImageRowId) {
                                                    image = element.result_image
                                                }
                                            })
                                        ))
                                        if (image?.length !== this.state.uploadedImages?.length) {
                                            let removeImg = this.state.uploadedImages.pop()
                                            this.setState({ uploadedImages: this.state.uploadedImages })
                                        }
                                    }}
                                    sx={{ marginRight: "1vw", textTransform: "capitalize" }}>{t("Reset")}</Button>
                                <Button
                                    variant='contained'
                                    sx={{ textTransform: "capitalize" }}
                                    disabled={this.state.uploadedImages?.length === 0}
                                    onClick={() => {
                                        this.uploadOutsourceImage(this.state.selectedUploadData)
                                        this.setState({ sourceOpen: false })
                                    }}
                                >{t("Save")}</Button>
                            </div>}
                    </Box>
                </Modal>
            </>
        )
    }

    resultMethodTypeList = (method, mainIndex, subIndex, list, result_list, isFinish = false, isSpeciman = false) => {
        let { patientResultStatus } = this.state
        let values = (patientResultStatus[mainIndex]?.['data']?.[subIndex]?.['result']?.value) ?
            patientResultStatus[mainIndex]['data'][subIndex]['result'].value : ''
        let others = (patientResultStatus[mainIndex]?.['data']?.[subIndex]?.['result']?.others) ?
            patientResultStatus[mainIndex]['data'][subIndex]['result'].others : ''
        let comment = (patientResultStatus[mainIndex]?.['data']?.[subIndex]?.['result']?.comment) ?
            patientResultStatus[mainIndex]['data'][subIndex]['result'].comment : ''
        const possibleItems = patientResultStatus[mainIndex]?.['data']?.[subIndex]?.['possible_result_items'] || []
        return (
            method === "Range Based Result" || method === "Lesser and Greater Result" ?
                <div style={{ display: "flex", flexDirection: "row" }}>
                    {(this.state.patient_status.status === "Result Completed") || (this.state.patient_status.status === "Result Delivered") ?
                        <>
                            <TextField
                                sx={{ width: "10vw", marginRight: "0.5vw" }}
                                size='small'
                                label={'Result'}
                                value={list?.value}
                                disabled
                            />
                            <TextField
                                sx={{ width: "15vw", marginRight: "0.5vw" }}
                                size='small'
                                label={"Comments"}
                                value={list?.comment}
                                disabled
                            />
                        </>
                        :
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ marginBottom: "0.5vw" }}>
                                <TextField
                                    sx={{ width: "10vw", marginRight: "0.5vw" }}
                                    size='small'
                                    value={values}
                                    label={'Result'}
                                    disabled={isFinish || isSpeciman || this.state.FromTestReport}
                                    onChange={(e) => {
                                        let Data = e.target.value
                                        if (Data !== '') {
                                            if (patientResultStatus[mainIndex]['data'][subIndex]['result']?.value) {
                                                patientResultStatus[mainIndex]['data'][subIndex]['result']['value'] = Data
                                            } else {
                                                patientResultStatus[mainIndex]['data'][subIndex]['result'] = { value: Data }
                                            }
                                            this.setState({
                                                patientResultStatus
                                            })
                                        } else {
                                            patientResultStatus[mainIndex]['data'][subIndex]['result'] = null
                                            this.setState({ patientResultStatus })
                                        }
                                    }}
                                />
                                <TextField
                                    sx={{ width: "8vw", marginLeft: "0.5vw" }}
                                    size='small'
                                    disabled={isFinish || isSpeciman || this.state.FromTestReport}
                                    value={comment}
                                    label='Comments'
                                    inputProps={{
                                        maxLength: 300,
                                    }}
                                    onChange={(e) => {
                                        if (patientResultStatus[mainIndex]['data'][subIndex]['result']?.value) {
                                            let Data = e.target.value
                                            if (patientResultStatus[mainIndex]['data'][subIndex]['result']?.comment) {
                                                patientResultStatus[mainIndex]['data'][subIndex]['result']['comment'] = Data
                                            } else {
                                                patientResultStatus[mainIndex]['data'][subIndex]['result']["comment"] = Data
                                            }
                                            this.setState({
                                                patientResultStatus
                                            })
                                        } else {
                                            this.setState({
                                                isErrorMsg: true,
                                                isErrorMsgText: "Enter Result"
                                            })
                                        }
                                    }}
                                />
                            </div>
                            {patientResultStatus[mainIndex]?.['data']?.[subIndex]?.previous_result ?
                                <Typography sx={{ fontSize: "0.85vw", color: "#a8a5a5", ml: "0.5vw" }}>{patientResultStatus[mainIndex]?.['data']?.[subIndex]?.previous_result ? patientResultStatus[mainIndex]?.['data']?.[subIndex]?.previous_result?.length > 50 ?
                                    <Tooltip placement='top' title={patientResultStatus[mainIndex]?.['data']?.[subIndex]?.previous_result}>
                                        <div sx={{ fontSize: "0.85vw", color: "#a8a5a5" }}>{patientResultStatus[mainIndex]?.['data']?.[subIndex]?.previous_result.slice(0, 50) + '...' + " "}</div>
                                    </Tooltip> : (patientResultStatus[mainIndex]?.['data']?.[subIndex]?.previous_result + " ") : '-'}</Typography>
                                : null}
                        </div>
                    }
                </div> :
                method === "Positive and Negative Results" || method === "Present and Absent Result" ?
                    <div>
                        {(this.state.patient_status.status === "Result Completed") || (this.state.patient_status.status === "Result Delivered") ?
                            <div className='emedhub_lab_Result'>
                                <TextField
                                    sx={{ width: "10vw", marginRight: "0.5vw" }}
                                    size='small'
                                    label={'Result'}
                                    value={list?.value}
                                    disabled
                                />
                                <TextField
                                    sx={{ width: "15vw", marginRight: "0.5vw" }}
                                    size='small'
                                    label={"Comments"}
                                    value={list?.comment}
                                    disabled
                                />
                            </div>
                            :
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <div className='emedhub_lab_Result'>
                                    <FormControl size='small' sx={{ width: "7.5vw" }}>
                                        <InputLabel>Select</InputLabel>
                                        <Select
                                            label='Select'
                                            disabled={isFinish || isSpeciman || this.state.FromTestReport}
                                            value={values}
                                            onChange={(e) => {
                                                let Data = e.target.value
                                                if (patientResultStatus[mainIndex]?.['data']?.[subIndex]?.['result']?.value) {
                                                    patientResultStatus[mainIndex]['data'][subIndex]['result']['value'] = Data
                                                } else {
                                                    patientResultStatus[mainIndex]['data'][subIndex]['result'] = { value: Data }
                                                }
                                                this.setState({
                                                    patientResultStatus
                                                })
                                            }}
                                        >
                                            {method === "Present and Absent Result" ?
                                                PresAbsen.map((item) => {
                                                    return <MenuItem value={item?.result_name} key={item?.value}>{item?.result_name}</MenuItem>
                                                }) :
                                                PosiNega.map((item) => {
                                                    return <MenuItem value={item?.result_name} key={item?.value}>{item?.result_name}</MenuItem>
                                                })}
                                        </Select>
                                    </FormControl>
                                    {((values != "Positive" || values != "Negative") && method === "Positive and Negative Results") ?
                                        // <Tooltip placement='top' title={others ? others : null}>
                                        <TextField
                                            sx={{ width: "7.5vw", marginLeft: "0.75vw" }}
                                            size='small'
                                            value={others}
                                            label={"Result"}
                                            disabled={isFinish || isSpeciman || this.state.FromTestReport ? true : false}
                                            onChange={(e) => {
                                                if (patientResultStatus[mainIndex]?.['data']?.[subIndex]?.['result']?.value) {
                                                    let Data = e.target.value;
                                                    if (patientResultStatus[mainIndex]?.['data']?.[subIndex]?.['result']?.others) {
                                                        patientResultStatus[mainIndex]['data'][subIndex]['result']['others'] = Data
                                                    } else {
                                                        patientResultStatus[mainIndex]['data'][subIndex]['result']['others'] = Data
                                                    }
                                                    this.setState({
                                                        patientResultStatus
                                                    })
                                                } else {
                                                    this.setState({
                                                        isErrorMsg: true,
                                                        isErrorMsgText: "Select Positive / Negative"
                                                    })
                                                }
                                            }}
                                        />
                                        // </Tooltip>
                                        : null}
                                    {((values != "Present" || values != "Absent") && method === "Present and Absent Result") ?
                                        // <Tooltip placement='top' title={others ? others : null}>
                                        <TextField
                                            sx={{ width: "7.5vw", marginLeft: "0.75vw" }}
                                            size='small'
                                            label={"Result"}
                                            value={others}
                                            disabled={isFinish || isSpeciman || this.state.FromTestReport ? true : false}
                                            onChange={(e) => {
                                                if (patientResultStatus[mainIndex]?.['data']?.[subIndex]?.['result']?.value) {
                                                    let Data = e.target.value;
                                                    if (patientResultStatus[mainIndex]?.['data']?.[subIndex]?.['result']?.others) {
                                                        patientResultStatus[mainIndex]['data'][subIndex]['result']['others'] = Data
                                                    } else {
                                                        patientResultStatus[mainIndex]['data'][subIndex]['result']['others'] = Data
                                                    }
                                                    this.setState({
                                                        patientResultStatus
                                                    })
                                                } else {
                                                    this.setState({
                                                        isErrorMsg: true,
                                                        isErrorMsgText: "Select Present / Absent"
                                                    })
                                                }
                                            }}
                                        />
                                        // </Tooltip>
                                        : null}
                                    <TextField
                                        sx={{ width: "8vw", marginLeft: "0.5vw" }}
                                        size='small'
                                        value={comment}
                                        label={"Comments"}
                                        disabled={isFinish || isSpeciman || this.state.FromTestReport}
                                        inputProps={{
                                            maxLength: 300,
                                        }}
                                        onChange={(e) => {
                                            if (patientResultStatus[mainIndex]?.['data']?.[subIndex]?.['result']?.value) {
                                                let Data = e.target.value
                                                if (patientResultStatus[mainIndex]?.['data']?.[subIndex]?.['result']?.comment) {
                                                    patientResultStatus[mainIndex]['data'][subIndex]['result']['comment'] = Data
                                                } else {
                                                    patientResultStatus[mainIndex]['data'][subIndex]['result']["comment"] = Data
                                                }
                                                this.setState({
                                                    patientResultStatus
                                                })
                                            } else {
                                                this.setState({
                                                    isErrorMsg: true,
                                                    isErrorMsgText: method === "Positive and Negative Results" ? "Select Positive / Negative" : "Select Present / Absent"
                                                })
                                            }
                                        }}
                                    />
                                </div>
                                {patientResultStatus[mainIndex]?.['data']?.[subIndex]?.previous_result ?
                                    <Typography sx={{ fontSize: "0.85vw", color: "#a8a5a5", ml: "0.5vw" }}>{patientResultStatus[mainIndex]?.['data']?.[subIndex]?.previous_result ? patientResultStatus[mainIndex]?.['data']?.[subIndex]?.previous_result?.length > 50 ?
                                        <Tooltip placement='top' title={patientResultStatus[mainIndex]?.['data']?.[subIndex]?.previous_result}>
                                            <div sx={{ fontSize: "0.85vw", color: "#a8a5a5" }}>{patientResultStatus[mainIndex]?.['data']?.[subIndex]?.previous_result.slice(0, 50) + '...' + " "}</div>
                                        </Tooltip> : (patientResultStatus[mainIndex]?.['data']?.[subIndex]?.previous_result + " ") : '-'}</Typography>
                                    : null}
                            </div>
                        }
                    </div>
                    :
                    method === "Culture Test Result" ? this.methodThreeButtonPopUp(result_list, isFinish, isSpeciman) :
                        method === "Radiology" ? this.methodFourButtonPopUp(result_list, isFinish, isSpeciman) :
                            method === 'Free Text Based Result' ? <div style={{ display: "flex", flexDirection: "row" }}>
                                {(this.state.patient_status.status === "Result Completed") || (this.state.patient_status.status === "Result Delivered") ?
                                    <Box>
                                        <TextField
                                            sx={{ width: "25vw", marginRight: "0.5vw" }}
                                            size='small'
                                            label={'Result'}
                                            value={list?.value}
                                            disabled
                                        />
                                        <TextField
                                            sx={{ width: "15vw", marginTop: "0.5vw" }}
                                            size='small'
                                            label='Comments'
                                            value={list?.comment}
                                            disabled
                                        />
                                    </Box>
                                    :
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <Box component={'div'}>
                                            <Autocomplete
                                                size='small'
                                                freeSolo
                                                options={possibleItems ? possibleItems : []}
                                                getOptionLabel={(option) => typeof (option) === 'string' ? option : (`${option?.possible_result}`)}
                                                disabled={isFinish || isSpeciman || this.state.FromTestReport}
                                                value={values}
                                                onChange={(event, newValue) => {
                                                    let Data = newValue?.possible_result
                                                    if (Data !== '') {
                                                        if (patientResultStatus[mainIndex]['data'][subIndex]['result']?.value) {
                                                            patientResultStatus[mainIndex]['data'][subIndex]['result']['value'] = Data
                                                        } else {
                                                            patientResultStatus[mainIndex]['data'][subIndex]['result'] = { value: Data }
                                                        }
                                                        this.setState({
                                                            patientResultStatus
                                                        })
                                                    } else {
                                                        patientResultStatus[mainIndex]['data'][subIndex]['result'] = null
                                                        this.setState({ patientResultStatus })
                                                    }
                                                }}
                                                onInputChange={(e, value) => {
                                                    let Data = value
                                                    if (Data !== '') {
                                                        if (patientResultStatus[mainIndex]['data'][subIndex]['result']?.value) {
                                                            patientResultStatus[mainIndex]['data'][subIndex]['result']['value'] = Data
                                                        } else {
                                                            patientResultStatus[mainIndex]['data'][subIndex]['result'] = { value: Data }
                                                        }
                                                        this.setState({
                                                            patientResultStatus
                                                        })
                                                    } else {
                                                        patientResultStatus[mainIndex]['data'][subIndex]['result'] = null
                                                        this.setState({ patientResultStatus })
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField {...params}
                                                        style={{ width: "25vw", marginRight: "0.5vw" }}
                                                        label={'Result'}
                                                        multiline
                                                        rows={3}
                                                    />
                                                )}
                                            />
                                            <TextField
                                                sx={{ width: "25vw", marginTop: "0.5vw" }}
                                                size='small'
                                                value={comment}
                                                label={"Comments"}
                                                inputProps={{
                                                    maxLength: 300,
                                                }}
                                                disabled={isFinish || isSpeciman || this.state.FromTestReport}
                                                onChange={(e) => {
                                                    if (patientResultStatus[mainIndex]['data'][subIndex]['result']?.value) {
                                                        let Data = e.target.value
                                                        if (patientResultStatus[mainIndex]['data'][subIndex]['result']?.comment) {
                                                            patientResultStatus[mainIndex]['data'][subIndex]['result']['comment'] = Data
                                                        } else {
                                                            patientResultStatus[mainIndex]['data'][subIndex]['result']["comment"] = Data
                                                        }
                                                        this.setState({
                                                            patientResultStatus
                                                        })
                                                    } else {
                                                        this.setState({
                                                            isErrorMsg: true,
                                                            isErrorMsgText: "Enter Result"
                                                        })
                                                    }
                                                }}
                                            />
                                        </Box>
                                        {patientResultStatus[mainIndex]?.['data']?.[subIndex]?.previous_result ?
                                            <Typography sx={{ fontSize: "0.85vw", color: "#a8a5a5", ml: "0.5vw" }}>{patientResultStatus[mainIndex]?.['data']?.[subIndex]?.previous_result ? patientResultStatus[mainIndex]?.['data']?.[subIndex]?.previous_result?.length > 50 ?
                                                <Tooltip placement='top' title={patientResultStatus[mainIndex]?.['data']?.[subIndex]?.previous_result}>
                                                    <div sx={{ fontSize: "0.85vw", color: "#a8a5a5" }}>{patientResultStatus[mainIndex]?.['data']?.[subIndex]?.previous_result.slice(0, 50) + '...' + " "}</div>
                                                </Tooltip> : (patientResultStatus[mainIndex]?.['data']?.[subIndex]?.previous_result + " ") : '-'}</Typography>
                                            : null}
                                    </div>
                                }
                            </div> : null
        )
    }

    // handleCheckboxChange = (index, item) => {
    //     const { selectedRows, selectedData } = this.state;
    //     const isSelected = selectedRows.includes(index);
    //     const updatedSelection = isSelected
    //         ? selectedRows.filter((i) => i !== index)
    //         : [...selectedRows, index];
    //     let updatedData;

    //     if (isSelected) {
    //         updatedData = selectedData.filter((dataItem) => dataItem.data?.[0]?.id !== item.data?.[0]?.id);
    //     } else {
    //         updatedData = [...selectedData, item];
    //     }
    //     this.setState({
    //         selectedRows: updatedSelection,
    //         selectedData: updatedData,
    //         selectedOutsourceData: updatedData,
    //     });
    // };

    handleCheckboxChange = (index, item) => {
        const { selectedRows, selectedData, patientResultStatus, SelectedSource } = this.state;
        const isTestInvalid = (test) => {
            if (test?.is_outsource) {
                return !test?.result_image?.length > 0;
            }
            if (!test?.result || test?.result === null || test?.result?.value === null) {
                return true;
            }
            if (test?.method === "Culture Test Result") {
                return test?.result?.value?.length === 0;
            }
            return !test?.result?.value;
        };

        const isSelected = selectedRows.includes(index);
        const updatedSelection = isSelected
            ? selectedRows.filter((i) => i !== index)
            : [...selectedRows, index];

        const updatedData = isSelected
            ? selectedData.filter((dataItem) => dataItem.data?.[0]?.id !== item.data?.[0]?.id)
            : [...selectedData, item];

        const validRows = patientResultStatus.map((_, idx) => idx).filter((idx) => {
            const row = patientResultStatus[idx];
            const isResultInComplete = row.data?.some(isTestInvalid);
            if (SelectedSource === "Outsource") {
                return row.is_outsource && !isResultInComplete && !row?.is_finished;
            } else {
                return !row.is_outsource && !isResultInComplete && !row?.is_finished;
            }
        });

        const isAllSelected = updatedSelection.length === validRows.length;

        this.setState({
            selectedRows: updatedSelection,
            selectedData: updatedData,
            selectedOutsourceData: updatedData,
            isAllSelected
        });
    };



    handleSelectAllChange = (e) => {
        const isChecked = e.target.checked;
        const { patientResultStatus, SelectedSource } = this.state;
        const isTestInvalid = (test) => {
            if (test?.is_outsource) {
                return !test?.result_image?.length > 0;
            }
            if (!test?.result || test?.result === null || test?.result?.value === null) {
                return true;
            }
            if (test?.method === "Culture Test Result") {
                return test?.result?.value?.length === 0;
            }
            return !test?.result?.value;
        };

        let updatedRows = [];
        let updatedData = [];

        if (isChecked) {
            updatedRows = patientResultStatus.map((_, index) => index).filter((index) => {
                const item = patientResultStatus[index];
                const isResultInComplete = item.data?.some(isTestInvalid);
                if (SelectedSource === "Outsource") {
                    return item.is_outsource && !isResultInComplete && !item?.is_finished;
                } else {
                    return !item.is_outsource && !isResultInComplete && !item?.is_finished;
                }
            });

            updatedData = updatedRows.map((index) => patientResultStatus[index]);
        }

        this.setState({
            selectedRows: updatedRows,
            selectedData: updatedData,
            selectedOutsourceData: updatedData,
            isAllSelected: isChecked
        });
    };



    renderNewResultTable = () => {
        const { selectedRows } = this.state;
        let { location } = this.props?.history;
        const isTestInvalid = (test) => {
            if (test?.is_outsource) {
                return !test?.result_image?.length > 0;
            }
            if (!test?.result || test?.result === null || test?.result?.value === null) {
                return true;
            }
            if (test?.method === "Culture Test Result") {
                return test?.result?.value?.length === 0;
            }
            return !test?.result?.value;
        };
        try {
            return (
                <Box component={'div'} border={'1px solid lightgray'} height={'53vh'} overflow={'scroll'}>
                    <Table size='small' stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: '600', fontSize: '0.9vw', backgroundColor: Colors.Background, color: Colors.SecondaryText, width: '17.5vw' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                        {this.state.fromTechnician ? null : <Checkbox
                                            indeterminate={
                                                selectedRows.length > 0 &&
                                                selectedRows.length < this.state.patientResultStatus.filter((item, index) => {
                                                    const isResultInComplete = item.data?.some(isTestInvalid);
                                                    if (this.state.SelectedSource === "Outsource") {
                                                        return item.is_outsource && !isResultInComplete && !item?.is_finished;
                                                    } else {
                                                        return !item.is_outsource && !isResultInComplete && !item?.is_finished;
                                                    }
                                                }).length
                                            }
                                            checked={selectedRows.length > 0 && selectedRows.length === this.state.patientResultStatus.filter((item, index) => {
                                                const isResultInComplete = item.data?.some(isTestInvalid);
                                                if (this.state.SelectedSource === "Outsource") {
                                                    return item.is_outsource && !isResultInComplete && !item?.is_finished;
                                                } else {
                                                    return !item.is_outsource && !isResultInComplete && !item?.is_finished;
                                                }
                                            }).length}
                                            onChange={this.handleSelectAllChange}
                                        />}

                                        <span>Service</span>
                                    </Box>
                                </TableCell>
                                <TableCell sx={{ fontWeight: '600', fontSize: '0.9vw', backgroundColor: Colors.Background, color: Colors.SecondaryText, width: '17.5vw' }}>Test Name</TableCell>
                                <TableCell sx={{ fontWeight: '600', fontSize: '0.9vw', backgroundColor: Colors.Background, color: Colors.SecondaryText, width: '25vw' }}>Reference Range</TableCell>
                                <TableCell sx={{ fontWeight: '600', fontSize: '0.9vw', backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Result</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.patientResultStatus?.length > 0 ? this.state.patientResultStatus?.map((item, index) => {
                                let isResultInComplete = item.data?.some(isTestInvalid);
                                return (item?.is_outsource && this.state.SelectedSource === "Outsource" ?
                                    <TableRow sx={{ overflow: 'scroll' }}>
                                        <TableCell colSpan={4} sx={{ padding: '0vw' }}>
                                            <Table>
                                                <TableCell sx={{ width: '17.5vw', borderRight: '1px solid lightgray' }}>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                                        {this.state.fromTechnician ? null : <Checkbox
                                                            checked={
                                                                (() => {
                                                                    if ((selectedRows.includes(index) || item?.is_finished) && !isResultInComplete) {
                                                                        return true;
                                                                    }
                                                                    if (selectedRows.includes(index) && isResultInComplete) {
                                                                        this.handleCheckboxChange(index, item);
                                                                    }
                                                                    return false;
                                                                })()
                                                            }
                                                            onChange={() => this.handleCheckboxChange(index, item)}
                                                            disabled={isResultInComplete || item?.is_finished}
                                                        />}
                                                        <Typography>{item?.result_description ? item?.result_description : '-'}</Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell sx={{ padding: "0vw" }}>
                                                    {item?.data?.map((list, secondIndex) => (
                                                        <Table>
                                                            <TableCell sx={{ width: '17.5vw', borderRight: '1px solid lightgray' }}>
                                                                <Typography>{list?.test_name ? list?.test_name?.length > 25 ?
                                                                    <Tooltip placement='top' title={list?.test_name}>
                                                                        <div>{list?.test_name.slice(0, 30) + '...'}</div>
                                                                    </Tooltip> : list?.test_name : '-'}</Typography>
                                                            </TableCell>
                                                            {list.method == "Culture Test Result" ?
                                                                <TableCell sx={{ width: '20vw', borderRight: '1px solid lightgray' }}>
                                                                    -
                                                                </TableCell> :
                                                                list?.method === "Lesser and Greater Result" ?
                                                                    <TableCell sx={{ width: '20vw', borderRight: '1px solid lightgray' }}>
                                                                        {((list?.normal_range?.length > 0) && Array.isArray(list?.normal_range)) ? list?.normal_range?.map((range, rangeIndex) => (
                                                                            <Table key={rangeIndex}>
                                                                                <TableCell sx={{ padding: '0vw', borderBottom: 'none' }}>
                                                                                    {(range?.reference_type + range?.reference_range + range?.unit)?.length > 25 ?
                                                                                        <Tooltip placement='top' title={`${range?.reference_type ? range?.reference_type : ''}  ${range?.reference_range ? range?.reference_range : ''} (${range?.unit ? range?.unit : ''})`}>
                                                                                            <Typography component={'div'}>{`${range?.reference_type ? range?.reference_type : ''}  ${range?.reference_range ? range?.reference_range : ''} (${range?.unit ? range?.unit : ''})`}</Typography>
                                                                                        </Tooltip> :
                                                                                        <Typography>{`${range?.reference_type ? range?.reference_type : ''} ${range?.reference_range ? range?.reference_range : ''} (${range?.unit ? range?.unit : ''})`}</Typography>}
                                                                                </TableCell>
                                                                            </Table>
                                                                        )) : '-'}
                                                                    </TableCell> :
                                                                    <TableCell sx={{ width: '20vw', borderRight: '1px solid lightgray' }}>
                                                                        {((list?.normal_range?.length > 0) && Array.isArray(list?.normal_range)) ? list?.normal_range?.map((range, rangeIndex) => (
                                                                            <Table key={rangeIndex}>
                                                                                <TableCell sx={{ padding: '0vw', borderBottom: 'none' }}>
                                                                                    {(range?.reference_from + range?.reference_to + range?.unit)?.length > 25 ?
                                                                                        <Tooltip placement='top' title={`${range?.reference_from ? range?.reference_from : ''} (${range?.unit ? range?.unit : ''}) to ${range?.reference_to ? range?.reference_to : ''} (${range?.unit ? range?.unit : ''})`}>
                                                                                            <Typography component={'div'}>{`${range?.reference_from ? range?.reference_from : ''} (${range?.unit ? range?.unit : ''}) to ${range?.reference_to ? range?.reference_to : ''} (${range?.unit ? range?.unit : ''})`}</Typography>
                                                                                        </Tooltip> :
                                                                                        <Typography>{`${range?.reference_from ? range?.reference_from : ''} ${range?.unit ? "(" + range?.unit + ")" : ''} ${range?.unit ? "to" : "-"} ${range?.reference_to ? range?.reference_to : ''} ${range?.unit ? "(" + range?.unit + ")" : ''}`}</Typography>}
                                                                                </TableCell>
                                                                            </Table>
                                                                        )) : '-'}
                                                                    </TableCell>}
                                                            <TableCell>
                                                                {list?.is_outsource ?
                                                                    this.renderSourcePopUp(list, secondIndex) :
                                                                    this.resultMethodTypeList(list?.method, index, secondIndex, list?.result, list, list?.is_finished, !list?.is_specimen_collected)}
                                                            </TableCell>
                                                        </Table>
                                                    ))}
                                                </TableCell>
                                            </Table>
                                        </TableCell>
                                    </TableRow>
                                    :
                                    (!item?.is_outsource && this.state.SelectedSource === "Inhouse" ?
                                        <TableRow sx={{ overflow: 'scroll' }}>
                                            <TableCell colSpan={4} sx={{ padding: '0vw' }}>
                                                <Table>
                                                    <TableCell sx={{ width: '17.5vw', borderRight: '1px solid lightgray' }}>
                                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                                            {this.state.fromTechnician ? null : <Checkbox
                                                                checked={
                                                                    (() => {
                                                                        if ((selectedRows.includes(index) || item?.is_finished) && !isResultInComplete) {
                                                                            return true;
                                                                        }
                                                                        if (selectedRows.includes(index) && isResultInComplete) {
                                                                            this.handleCheckboxChange(index, item);
                                                                        }
                                                                        return false;
                                                                    })()
                                                                }
                                                                onChange={() => this.handleCheckboxChange(index, item)}
                                                                disabled={isResultInComplete || item?.is_finished}
                                                            />}
                                                            <Typography>{item?.result_description ? item?.result_description : '-'}</Typography>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell sx={{ padding: "0vw" }}>
                                                        {item?.data?.map((list, secondIndex) => (
                                                            <Table>
                                                                <TableCell sx={{ width: '17.5vw', borderRight: '1px solid lightgray' }}>
                                                                    <Typography>{list?.test_name ? list?.test_name?.length > 35 ?
                                                                        <Tooltip placement='top' title={list?.test_name}>
                                                                            <div>{list?.test_name.slice(0, 30) + '...'}</div>
                                                                        </Tooltip> : list?.test_name : '-'}</Typography>
                                                                </TableCell>
                                                                {list.method == "Culture Test Result" ?
                                                                <TableCell sx={{ width: '20vw', borderRight: '1px solid lightgray' }}>
                                                                    -
                                                                </TableCell> :
                                                                list?.method === "Lesser and Greater Result" ?
                                                                    <TableCell sx={{ width: '20vw', borderRight: '1px solid lightgray' }}>
                                                                        {((list?.normal_range?.length > 0) && Array.isArray(list?.normal_range)) ? list?.normal_range?.map((range, rangeIndex) => (
                                                                            <Table key={rangeIndex}>
                                                                                <TableCell sx={{ padding: '0vw', borderBottom: 'none' }}>
                                                                                    {(range?.reference_type + range?.reference_range + range?.unit)?.length > 25 ?
                                                                                        <Tooltip placement='top' title={`${range?.reference_type ? range?.reference_type : ''}  ${range?.reference_range ? range?.reference_range : ''} (${range?.unit ? range?.unit : ''})`}>
                                                                                            <Typography component={'div'}>{`${range?.reference_type ? range?.reference_type : ''}  ${range?.reference_range ? range?.reference_range : ''} (${range?.unit ? range?.unit : ''})`}</Typography>
                                                                                        </Tooltip> :
                                                                                        <Typography>{`${range?.reference_type ? range?.reference_type : ''} ${range?.reference_range ? range?.reference_range : ''} (${range?.unit ? range?.unit : ''})`}</Typography>}
                                                                                </TableCell>
                                                                            </Table>
                                                                        )) : '-'}
                                                                    </TableCell> :
                                                                    <TableCell sx={{ width: '20vw', borderRight: '1px solid lightgray' }}>
                                                                        {((list?.normal_range?.length > 0) && Array.isArray(list?.normal_range)) ? list?.normal_range?.map((range, rangeIndex) => (
                                                                            <Table key={rangeIndex}>
                                                                                <TableCell sx={{ padding: '0vw', borderBottom: 'none' }}>
                                                                                    {(range?.reference_from + range?.reference_to + range?.unit)?.length > 25 ?
                                                                                        <Tooltip placement='top' title={`${range?.reference_from ? range?.reference_from : ''} (${range?.unit ? range?.unit : ''}) to ${range?.reference_to ? range?.reference_to : ''} (${range?.unit ? range?.unit : ''})`}>
                                                                                            <Typography component={'div'}>{`${range?.reference_from ? range?.reference_from : ''} (${range?.unit ? range?.unit : ''}) to ${range?.reference_to ? range?.reference_to : ''} (${range?.unit ? range?.unit : ''})`}</Typography>
                                                                                        </Tooltip> :
                                                                                        <Typography>{`${range?.reference_from ? range?.reference_from : ''} ${range?.unit ? "(" + range?.unit + ")" : ''} ${range?.unit ? "to" : "-"} ${range?.reference_to ? range?.reference_to : ''} ${range?.unit ? "(" + range?.unit + ")" : ''}`}</Typography>}
                                                                                </TableCell>
                                                                            </Table>
                                                                        )) : '-'}
                                                                    </TableCell>}
                                                                <TableCell>
                                                                    {list?.is_outsource ?
                                                                        this.renderSourcePopUp(list, secondIndex) :
                                                                        this.resultMethodTypeList(list?.method, index, secondIndex, list?.result, list, list?.is_finished, !list?.is_specimen_collected)}
                                                                </TableCell>
                                                            </Table>
                                                        ))}
                                                    </TableCell>
                                                </Table>
                                            </TableCell>
                                        </TableRow>
                                        : null)
                                )
                            })
                                : <TableRow>
                                    <TableCell sx={{ borderBottom: 'none' }}></TableCell>
                                    <TableCell align='center' sx={{ borderBottom: 'none', height: '20vh', margin: 'auto' }}>No Records To Be Shown</TableCell>
                                    <TableCell sx={{ borderBottom: 'none' }}></TableCell>
                                    <TableCell sx={{ borderBottom: 'none' }}></TableCell>
                                </TableRow>}
                        </TableBody>
                    </Table>
                </Box>
            )
        } catch (error) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: error.message
            })
        }
    }

    handleCancel = () => {
        let { OrgPatientResultStatus } = this.state;
        let print_list = OrgPatientResultStatus.map(item => (
            item?.data?.length > 0 && item?.data?.filter(li => (li?.result?.value && li?.is_specimen_collected)).map(test => {
                if (
                    test?.result !== null &&
                    typeof test?.result === "object" &&
                    ("value" in test?.result) && test?.is_outsource === false
                ) {
                    test["isChecked"] = true;
                    return test; // Keep the test object in the mapped array
                } else {
                    // return null;
                }
            })
        ))
        const initialCheckboxState = print_list?.map((group) =>
            group.map((item) => ({
                isChecked: item?.isChecked,
                id: item?.id,
            }))
        );
        this.setState({
            viewPrintLst: false,
            isPrintClicked: false,
            PrintTestList: print_list,
            checkboxState: initialCheckboxState,
        })
    }

    handleParentCheckboxChange = (groupIndex) => {
        this.setState((prevState) => {
            const updatedState = [...prevState.checkboxState];
            updatedState[groupIndex] = updatedState[groupIndex].map((item) => ({
                ...item,
                isChecked: !prevState.checkboxState[groupIndex][0].isChecked,
            }));

            this.getCheckedIds(); // Invoke getCheckedIds on checkbox change
            return { checkboxState: updatedState };
        });
    };

    handleChildCheckboxChange = (groupIndex, childIndex) => {
        this.setState((prevState) => {
            const updatedState = [...prevState.checkboxState];
            updatedState[groupIndex][childIndex] = {
                ...prevState.checkboxState[groupIndex][childIndex],
                isChecked: !prevState.checkboxState[groupIndex][childIndex].isChecked,
            };

            this.getCheckedIds(); // Invoke getCheckedIds on checkbox change
            return { checkboxState: updatedState };
        });
    };

    getCheckedIds = () => {
        const checkedIds = this.state.checkboxState
            .flat()
            .filter((item) => item.isChecked)
            .map((item) => item.id);
    };

    ShowTestHistory = () => {
        return (
            <TestHistory PatientDetails={this.state.patient_status} Source={this.state.SelectedSource} />
        )
    }

    showCompletedTest = () => {
        this.state.testDatas.forEach((element, index) => element.sno = index + 1)
        const columns = [
            {
                field: 'sno', headerName: t("SNo"), sortable: false, flex: 0.05,
            },
            {
                field: 'result_completed_date', headerName: t('Result Completed Date'), sortable: false, flex: 0.145, headerAlign: "center", align: "center",
                renderCell: (params) => (<div>{params.row.result_completed_date ? params.row.result_completed_date : "-"}</div>)
            },
            {
                field: 'test_count', headerName: t("No Of Test"), sortable: false, flex: 0.12, headerAlign: "center", align: "center",
                renderCell: (params) => (<div>{params?.row?.test_count ? params?.row?.test_count : "-"}</div>)
            },
            {
                field: 'test_details', headerName: t('Test Details'), sortable: false, flex: 0.150,
                renderCell: (params) => (<div>
                {params?.row?.test_details ? params?.row?.test_details?.length > 38 ?
                    <Tooltip placement='top' title={params?.row?.test_details}>
                        <Typography>
                            {params?.row?.test_details.slice(0, 35) + '...'}
                        </Typography>
                    </Tooltip> :
                    <Typography>{params?.row?.test_details}</Typography> : '-'}
                </div>
                )
            },
            {
                field: 'action', headerName: t('Action'), sortable: false, flex: 0.08, headerAlign: "center", align: "center",
                renderCell: (params) => (<div>
                    <IconButton disabled={params?.row?.test_ids === null} className="eMed_bill_print" onClick={() => { this.getResultCompletePrint(params?.row?.test_ids) }}>
                        <img src={params?.row?.test_ids === null ? ImagePaths.LabPrintIconOff.default : ImagePaths.PrintIcons.default} alt='print' />
                    </IconButton>
                </div>)
            }
        ]

        return (
            <div className='emedhub_result_card' style={{ height: "76vh" }}>
                <div style={{ borderBottom: "1px solid #d3d3d3", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                    <div>
                        <div style={{ fontSize: "0.85vw", fontWeight: 600, marginLeft: "0.5vw", color: "#666464", marginBottom: "0.25vw", marginTop: "0.5vw" }}>PATIENT INFORMATION</div>
                        <div style={{ display: "flex", flexDirection: "column", marginLeft: "0.5vw" }}>
                            <label className='emedhub_result_header_title'>{this.state.patient_status?.patient_name + ` (${this.state.patient_status?.patient_age} / ${this.state.patient_status?.patient__gender} )`.toUpperCase()}</label>
                            <label className='emedhub_result_header_text'>{`${t("UHID")} : ${this.state.patient_status?.ip_number ? `${this.state.patient_status?.patient_account_number ? this.state.patient_status?.patient_account_number : this.state.patient_status?.patient__patient_account_number} (IP No : ${this.state.patient_status?.ip_number})` : (this.state.patient_status?.patient_account_number ? this.state.patient_status?.patient_account_number : this.state.patient_status?.patient__patient_account_number)}`}</label>
                            <label className='emedhub_result_header_text'>{`${t("MobileNo")} : ${this.state.patient_status?.patient__mobile_number}`}</label>
                            <label className='emedhub_result_header_text'>{`${"Barcode Number"} : ${this.state.patient_status?.barcode ? this.state.patient_status?.barcode : "-"}`}</label>
                        </div>
                    </div>
                </div>
                <div className='reports_table_card'>
                    <DataGrid
                        rows={this.state.testDatas}
                        columns={columns}
                        getRowId={(row) => row['sno']}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                    />
                </div>
            </div>
        )
    }

    finishPopup = () => {
        return (
            <Box>
                <Modal open={true}>
                    <Paper elevation={3} id="emedhub_Transit_mainBox" style={{width: '40vw', height: '40vh'}}>
                        <Box component={'div'} id="emedhub_vendorDetails_header">
                            <Typography>{'Comments'}</Typography>
                            <IconButton size="small" onClick={() => { this.setState({ isComment: false, clinicCmt: '', overAllCmt: '', }) }}><img className="emedhub_transit_img" src={ImagePaths.LabCloseButton.default} alt={"close"} /></IconButton>
                        </Box>
                        <Box sx={{ marginTop: '1vw', display: 'flex' }}>
                            <TextField
                                size="small"
                                multiline
                                rows={6}
                                id="overallComt"
                                inputProps={{ maxLength: 250 }}
                                value={this.state.overAllCmt}
                                label={"Overall Comments"}
                                onChange={(event) => { this.setState({overAllCmt: event.target.value})}}
                                style={{ width: '19vw', marginRight: '1vw' }}
                                variant="outlined" />
                                <TextField
                                size="small"
                                multiline
                                rows={6}
                                id="overallComt"
                                inputProps={{ maxLength: 250 }}
                                value={this.state.clinicCmt}
                                label={"Clinical Remarks"}
                                onChange={(event) => {this.setState({clinicCmt: event.target.value}) }}
                                style={{ width: '19vw' }}
                                variant="outlined" />
                        </Box>
                        <Box sx={{ marginTop: '2vw', }}>
                            <Stack direction="row" spacing={2} sx={{ width: '100%', justifyContent: 'flex-end' }}>
                                <Button size="small" sx={{ width: '10vw', textTransform: 'capitalize' }} variant="outlined" disabled={this.state.disableBtn} onClick={() => {
                                    if (this.state.selectedRows.length > 0) {
                                        this.postPatientResultStatus()
                                    } else if ((this.state.patient_status.status === "Result Completed") || (this.state.patient_status.status === "Result Delivered")) {
                                        let { history } = this.props
                                        history.push({ pathname: "/MainHome", state: { selectedScreen: "TestReport", selectedTabID: 2 } })
                                    } else {
                                        this.putPatientResultStatus()
                                    }
                                }}>{'Skip & Finish Report'}</Button>
                                <Button size="small" sx={{ width: '10vw', textTransform: 'capitalize' }} variant="contained" disabled={this.state.disableBtn} onClick={() => {
                                    if(!this.state.overAllCmt || !this.state.clinicCmt){
                                        this.setState({
                                            isErrorMsg: true,
                                            isErrorMsgText: "Please Enter the Overall and Clinic Comments"
                                        })
                                    }else{
                                        if (this.state.selectedRows.length > 0) {
                                            this.postPatientResultStatus()
                                        } else if ((this.state.patient_status.status === "Result Completed") || (this.state.patient_status.status === "Result Delivered")) {
                                            let { history } = this.props
                                            history.push({ pathname: "/MainHome", state: { selectedScreen: "TestReport", selectedTabID: 2 } })
                                        } else {
                                            this.putPatientResultStatus()
                                        }
                                    }
                                }}>{"Finish Report"}</Button>
                            </Stack>
                        </Box>
                    </Paper>
                </Modal>
            </Box>
        )
    }
    ClosePopUp = () => {
        this.setState({ pdfPreview: false, pdfData: '' })
    }

    render() {
        const { t } = this.props
        let { page, rowsPerPage, showPrintBtn, PrintTestList, viewPrintLst, checkboxState } = this.state
        let states = this.state
        let DisableCancel = this.state.patientResultStatus.filter((list) => (!list.is_finished && !list.is_outsource))
        let InHouseCount = this.state.patientResultStatus.filter((list) => (!list.is_outsource));
        let OutSourseCount = this.state.patientResultStatus.filter((list) => (list.is_outsource));
        return (
            <>
                <Box component={'div'} mb={'0.5vw'} position={'sticky'}>
                    <Box display={'flex'} alignItems={'center'} height={'3vw'}>
                        <Tooltip title="Back" placement='top' arrow>
                            <IconButton emed_tid="back_button"
                                onClick={() => {
                                    if (this.state.showHistory) {
                                        this.setState({
                                            showHistory: false
                                        })
                                    }
                                    else if (this.state.fromTechnician) {
                                        let { history } = this.props
                                        history.push({ pathname: "/MainHome", state: { selectedScreen: "ForTechnicians", selectedTabID: 3, fromResultSaved: true } })
                                    } else if ((this.state.patient_status.status === "Result Completed") || (this.state.patient_status.status === "Result Delivered")) {
                                        let { history } = this.props
                                        history.push({ pathname: "/MainHome", state: { selectedScreen: "TestReport", selectedTabID: 2 } })
                                    } else {
                                        if ((this.state.patient_status.status === "Result Completed") || (this.state.patient_status.status === "Result Delivered")) {
                                            let { history } = this.props
                                            history.push({ pathname: "/MainHome", state: { selectedScreen: "TestReport", selectedTabID: 2 } })
                                        }
                                        else if (this.state?.FromTestReport) {
                                            let { history } = this.props
                                            history.push({ pathname: "/MainHome", state: { selectedScreen: "TestReport", selectedTabID: 2 } })
                                        } else {
                                            let { history } = this.props
                                            history.push({ pathname: "/MainHome", state: { selectedScreen: "Status", selectedTabID: 1 } })
                                        }
                                    }
                                }}>
                                <ChevronLeftIcon color='primary' />
                            </IconButton>
                        </Tooltip>
                        <Tabs
                            value={this.state.SelectedTab}
                            onChange={(e, newValue) => {
                                this.setState({
                                    SelectedTab: newValue
                                })
                            }}
                        >
                            <Tab value="one" label={"Test Result"} emed_tid='outOfOffice_tab' className='eMed_tab_header' />
                            <Tab value="two" label={"Completed Test"} emed_tid='history_tab' className='eMed_tab_header' />
                        </Tabs>
                    </Box>
                </Box>
                <Box margin={"1vw"}>
                    {states?.SelectedTab === "one" ?
                        <div className='emedhub_result_card' style={{ height: "76vh" }}>
                            <div>
                                <div style={{ display: "flex", height: "10vh" }}>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%", justifyContent: "space-between", borderBottom: "1px solid #d3d3d3" }}>
                                        <div style={{ display: "flex", flexDirection: "column", marginLeft: "0.5vw" }}>
                                            <label className='emedhub_result_header_title'>{this.state.patient_status?.patient_name + ` (${this.state.patient_status?.patient_age} / ${this.state.patient_status?.patient__gender} )`.toUpperCase()}</label>
                                            <label className='emedhub_result_header_text'>{`${t("UHID")} : ${this.state.patient_status?.ip_number ? `${this.state.patient_status?.patient_account_number ? this.state.patient_status?.patient_account_number : this.state.patient_status?.patient__patient_account_number} (IP No : ${this.state.patient_status?.ip_number})` : (this.state.patient_status?.patient_account_number ? this.state.patient_status?.patient_account_number : this.state.patient_status?.patient__patient_account_number)}`}</label>
                                            <label className='emedhub_result_header_text'>{`${t("MobileNo")} : ${this.state.patient_status?.patient__mobile_number}`}</label>
                                            <label className='emedhub_result_header_text'>{`${"Barcode Number"} : ${this.state.patient_status?.barcode ? this.state.patient_status?.barcode : "-"}`}</label>
                                        </div>

                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginRight: "0.5vw" }}>
                                            {this.state.disableLeft ?
                                                <Autocomplete
                                                    size='small'
                                                    sx={{ display: this.state.showHistory ? "none" : "block" }}
                                                    options={this.state.signatureList ? this.state.signatureList : []}
                                                    getOptionLabel={(option) => (`${option?.name} - ${option?.role}`)}
                                                    disabled={(this.state.patient_status.status === "Result Completed") || (this.state.patient_status.status === "Result Delivered")}
                                                    value={this.state?.LeftDrop && Object.keys(this.state?.LeftDrop).length > 0 ? this.state?.LeftDrop : null}
                                                    onChange={(event, newValue) => {
                                                        states.LeftDrop = newValue
                                                        this.setState({ states })
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} style={{ width: "13vw" }} label={this.state.disableLeft} />
                                                    )}
                                                /> : null}
                                            {this.state.disableCentre ?
                                                <Autocomplete
                                                    size='small'
                                                    sx={{ display: this.state.showHistory ? "none" : "block" }}
                                                    options={this.state.signatureList}
                                                    getOptionLabel={(option) => (`${option?.name} - ${option?.role}`)}
                                                    disabled={(this.state.patient_status.status === "Result Completed") || (this.state.patient_status.status === "Result Delivered")}
                                                    value={this.state?.CentreDrop && Object.keys(this.state?.CentreDrop).length > 0 ? this.state?.CentreDrop : null}
                                                    onChange={(event, newValue) => {
                                                        states.CentreDrop = newValue
                                                        this.setState({ states })
                                                    }}
                                                    style={{ marginLeft: "0.5vw" }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} style={{ width: "13vw" }} label={this.state.disableCentre} />
                                                    )}
                                                /> : null}
                                            {this.state.disableRight ?
                                                <Autocomplete
                                                    size='small'
                                                    sx={{ display: this.state.showHistory ? "none" : "block" }}
                                                    options={this.state.signatureList}
                                                    getOptionLabel={(option) => (`${option?.name} - ${option?.role}`)}
                                                    disabled={(this.state.patient_status.status === "Result Completed") || (this.state.patient_status.status === "Result Delivered")}
                                                    value={this.state?.RightDrop && Object.keys(this.state?.RightDrop).length > 0 ? this.state?.RightDrop : null}
                                                    onChange={(event, newValue) => {
                                                        states.RightDrop = newValue
                                                        this.setState({ states })
                                                    }}
                                                    style={{ marginLeft: "0.5vw" }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} style={{ width: "13vw" }} label={this.state.disableRight} />
                                                    )}
                                                /> : null}

                                            <Tooltip title={"Print Result"} placement='top' arrow>
                                                <IconButton sx={{ display: this.state.showHistory ? 'none' : "block" }} size="small" disabled={!showPrintBtn} onClick={() => this.setState({ viewPrintLst: true })}>
                                                    <img
                                                        className='eMed_action_img'
                                                        src={showPrintBtn ? ImagePaths.LabPrintIcon.default : ImagePaths.LabPrintIconOff.default}
                                                        alt="print"
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={"Patient Test History"} placement='top' arrow>
                                                <IconButton sx={{ display: this.state.showHistory ? 'none' : "block" }} size="small" onClick={() => this.setState({ showHistory: true })}>
                                                    <img
                                                        className='eMed_action_img'
                                                        src={ImagePaths.ResultHistory.default}
                                                        alt="history"
                                                    />
                                                </IconButton>
                                            </Tooltip>

                                            {viewPrintLst && PrintTestList.length > 0 ?
                                                <Menu
                                                    anchorEl={viewPrintLst}
                                                    sx={{ position: 'absolute', top: '9vw', right: "1vw" }}
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                    PaperProps={{
                                                        style: {
                                                            maxHeight: 350,
                                                            overflowY: "scroll",
                                                        },
                                                    }}
                                                    open={viewPrintLst}
                                                    onClose={() => this.handleCancel()}
                                                >
                                                    <Box component={'div'} p={'1vw'} sx={{ height: '40vh', overflowY: 'scroll' }}>

                                                        {this.state.PrintTestList.map((group, groupIndex) => (
                                                            <div key={groupIndex}>
                                                                {(group?.length > 0 && group[0]) ? group[0]?.set_name && (
                                                                    <FormGroup>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    checked={checkboxState[groupIndex]?.every((item) => item.isChecked)}
                                                                                    onChange={() => this.handleParentCheckboxChange(groupIndex)}
                                                                                />
                                                                            }
                                                                            label={`${group[0]?.set_name}`}
                                                                        />

                                                                        {group?.length > 0 ? group.map((item, childIndex) => (
                                                                            <FormControlLabel
                                                                                sx={{ marginLeft: '1vw' }}
                                                                                key={childIndex}
                                                                                control={
                                                                                    <Checkbox
                                                                                        checked={checkboxState[groupIndex][childIndex]?.isChecked}
                                                                                        onChange={() => this.handleChildCheckboxChange(groupIndex, childIndex)}
                                                                                    />
                                                                                }
                                                                                label={`${item?.test_name}`}
                                                                            />
                                                                        )) : null}
                                                                    </FormGroup>
                                                                ) : null}

                                                                {group.length > 0 && group[0] ? !group[0]?.set_name && (
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={checkboxState[groupIndex][0]?.isChecked}
                                                                                onChange={() => this.handleChildCheckboxChange(groupIndex, 0)}
                                                                            />
                                                                        }
                                                                        label={`${group[0]?.test_name}`}
                                                                    />
                                                                ) : null}

                                                                {group.length > 0 && group[0] ? <hr /> : null}
                                                            </div>
                                                        ))}
                                                    </Box>
                                                    <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                        <Button
                                                            size="small"
                                                            sx={{ mr: '1vw', textTransform: 'capitalize' }}
                                                            variant='contained'
                                                            disabled={this.state.isPrintClicked}
                                                            onClick={() => this.getResultPrint()}
                                                        >
                                                            Print
                                                        </Button>
                                                        <Button
                                                            variant='outlined'
                                                            size="small"
                                                            sx={{ textTransform: 'capitalize' }}
                                                            onClick={() => this.handleCancel()}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </Box>
                                                </Menu> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Tabs
                                value={this.state.SelectedSource}
                                onChange={(e, newValue) => {
                                    this.setState({
                                        SelectedSource: newValue,
                                        selectedRows: [],
                                        selectedData: [],
                                        selectedOutsourceData: []
                                    })
                                }}
                            >
                                <Tab
                                    label={this.state.showHistory ? 'InHouse Test' :
                                        <Box sx={{ display: 'flex', flexDirection: 'row', fontSize: '.85vw' }}>
                                            {'InHouse Test'} <span style={{
                                                backgroundColor: '#D32F2F', border: '1px solid #D32F2F', borderRadius: '12px', padding: '2px',
                                                color: '#FFFFFF', display: 'inline-block', minWidth: '20px', textAlign: 'center', fontSize: '.7vw', marginLeft: '.2vw'
                                            }}>
                                                {InHouseCount?.length}
                                            </span>
                                        </Box>
                                    }
                                    value="Inhouse" emed_tid='outOfOffice_tab' className='eMed_tab_header' />
                                <Tab
                                    label={this.state.showHistory ? 'Outsource Test' :
                                        <Box sx={{ display: 'flex', flexDirection: 'row', fontSize: '.85vw' }}>
                                            {'Outsource Test'} <span style={{
                                                backgroundColor: '#D32F2F', border: '1px solid #D32F2F', borderRadius: '12px', padding: '2px',
                                                color: '#FFFFFF', display: 'inline-block', minWidth: '20px', textAlign: 'center', fontSize: '.7vw', marginLeft: '.2vw'
                                            }}>
                                                {OutSourseCount?.length}
                                            </span>
                                        </Box>
                                    }
                                    value="Outsource" emed_tid='history_tab' className='eMed_tab_header' />
                            </Tabs>
                            {this.state.showHistory ? this.ShowTestHistory() : this.renderNewResultTable()}
                            <div style={{ marginTop: '0.5vw', display: this.state.showHistory ? 'none' : "inline" }}>
                                {(this.state.patient_status.status === "Result Completed") || (this.state.patient_status.status === "Result Delivered") || (this.state.FromTestReport) ? "" :
                                    <div className='emedhub_main_button' style={{ top: "0.3vw" }}>
                                        {this.state.SelectedSource === "Inhouse" ?
                                            <div>
                                                <Button
                                                    variant='contained'
                                                    sx={{ marginRight: "0.5vw", textTransform: "capitalize" }}
                                                    emed_tid='Result_SaveChanes_Btn'
                                                    disabled={this.state.selectedRows.length > 0 || this.state.disableBtn || DisableCancel.length <= 0}
                                                    onClick={() => {
                                                        this.postPatientResultStatus()
                                                    }}
                                                >{t("SaveChanges")}</Button>
                                                {this.state.fromTechnician ? null : <Button
                                                    emed_tid='Result_FinishReport_Btn'
                                                    disabled={this.state.disableBtn ? true : this.state.selectedRows.length <= 0 ? true : (this.state.showPrintBtn || this.state.enableFinishRpt) ? false : this.state.selectedRows.length > 0 ? false : true}
                                                    variant='contained'
                                                    sx={{ textTransform: "capitalize" }}
                                                    onClick={() => {
                                                        this.setState({isComment: true})
                                                        // if (this.state.selectedRows.length > 0) {
                                                        //     this.postPatientResultStatus()
                                                        // } else if ((this.state.patient_status.status === "Result Completed") || (this.state.patient_status.status === "Result Delivered")) {
                                                        //     let { history } = this.props
                                                        //     history.push({ pathname: "/MainHome", state: { selectedScreen: "TestReport", selectedTabID: 2 } })
                                                        // } else {
                                                        //     this.putPatientResultStatus()
                                                        // }
                                                    }}
                                                >{t("FinishReport")}</Button>}
                                            </div> : null}
                                        {this.state.SelectedSource === "Outsource" ?
                                            <Button
                                                emed_tid='Result_FinishReport_Btn'
                                                disabled={this.state.disableBtn ? true : this.state?.selectedOutsourceData?.length <= 0 ? true : (this.state.showPrintBtn || this.state.enableFinishRpt) ? false : true}
                                                variant='contained'
                                                sx={{ textTransform: "capitalize" }}
                                                onClick={() => {
                                                    this.uploadOutsourceImage(this.state.selectedOutsourceData, true)
                                                }}
                                            >{t("FinishReport")}</Button>
                                            : null}
                                    </div>
                                }
                            </div>
                        </div >
                        : this.showCompletedTest()}
                </Box>
                {
                    this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : this.state.isSuccessMsg ?
                            <ToastMsg
                                severity={'success'}
                                msg={this.state.isSuccessMsgText}
                                msgPop={this.msgClose.bind(this)}
                            /> : null
                }
                {this.state.isComment ? this.finishPopup() : null}
                {
                    this.state.pdfPreview ?
                        <PrintPreviewPop
                            Data={this.state.pdfData}
                            Title={t("PDF Preview")}
                            ClosePopUp={this.ClosePopUp.bind(this)}
                            history={this.props.history}
                        /> : null
                }
            </>
        )
    }
}


export default withTranslation()(Result)