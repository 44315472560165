import { useEffect, useRef } from 'react';

const TriggerApiOnView = ({ id, onEnterView, isProgrammaticScroll, children }) => {

  // Ref to track already triggered IDs
  const triggeredIdsRef = useRef(new Set());

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !triggeredIdsRef.current.has(entry.target.id)) {
          // Trigger the api call when the element is in view
          const newId = entry.target.id?.substring(0, id.lastIndexOf('_'));
          onEnterView(newId);
          triggeredIdsRef.current.add(entry.target.id);
        }
      });
    }, {
      root: null,
      rootMargin: '0px',
      threshold: 0.25,
    });

    // Observe the element
    const targetElement = document.getElementById(id);
    if (targetElement) {
      observer.observe(targetElement);
    }

    // Cleanup the observer on unmount or the `id` changes
    return () => {
      if (targetElement) {
        observer.unobserve(targetElement);
      }
    };
  }, [onEnterView, id, isProgrammaticScroll]);

  return children;
};

export default TriggerApiOnView;