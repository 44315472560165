import { Autocomplete, Box, Button, Chip, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import '../../../FrontOffice/IPPatients/PatientsDetails/ClinicalNotes/DoctorNotes.css'
import { DeletePopup } from '../../../../Components/Common Components/CommonComponents';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { Colors } from '../../../../Styles/Colors';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { Days, Months, Weeks, Year } from "../../../../Utility/Constants";

class OtherDoccomplaintsRight extends Component {
    constructor(props) {
        super(props)
        this.state = {
            patientId: this.props.patientId,
            clinicId: this.props.clinicId,
            complainDescrp: "",
            appointmentId: this.props.appointmentId,
            CategoryText: "",
            CategoryAddText: "",
            CategoryList: [],
            AddCategory: false,
            SelectedID : null,
            isDeleteClicked: false,
            SelectedCetegory: {},
            Complaints: "",
            selectedDays: '1',
            tempData: [],
            durationDays: Days,
            durationWeeks: Weeks,
            durationMonts: Months,
            durationYears: Year,
            isDisable : false,
        }
    }
    componentDidMount() {
        this.getDocComplaintList()
    }
    componentDidUpdate(prevProps) {
        if (prevProps.EditComplaintData !== this.props.EditComplaintData && this.props.EditComplaintData && Object.keys(this.props.EditComplaintData).length !== 0) {
            this.setState({
                Complaints: this.props.EditComplaintData?.complaint_description,
                selectedDuration: this.props.EditComplaintData?.duration_time,
                selectedDays: String(this.props.EditComplaintData?.duration_type),
                SelectedID: this.props.EditComplaintData?.id,
                complainDescrp: this.props.EditComplaintData?.comments,
            }, () => { this.props.ClearPropsData() })
        }
    }

    getDocComplaintList = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_PATIENT_CATEGORY_WISE)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            CategoryList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }
    getComplainssuggestion = (key) => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_COMPLAINTS_SUGGESTION_GET + `?search_key=${key}&doctor_id=${this.props.PatientData.doctor_id}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            tempData: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }
    checkValidation = () => {
        if (!this.state.Complaints) {
            this.errorMessage("Enter Complaints")
            this.setState({ postLoading: false, isDisable: false })
        }
        else {
            this.addComplaint()
        }
    }
    onClearHandler = () => {
        if(this.state.SelectedID !== null){
            this.props.CallgetApi('complaints')
        }
        this.setState({
            complainDescrp: "",
            selectedDays: '1',
            selectedDuration: '',
            eyeType: null,
            Complaints: null,
            tempData: [],
            SelectedID : null,
        })
    }
    addComplaint = () => {
        let states = this.state
        try {
            let data = {
                "id": states.SelectedID ? states.SelectedID : null,
                "appointment_id": this.props?.PatientData?.appointment_id,
                "complaint_description": states.Complaints,
                "duration_type": states.selectedDays ? states.selectedDays : null,
                "duration_time": states.selectedDuration ? states.selectedDuration : null,
                "comments": states.complainDescrp
            }
            this.setState({ postLoading: true })
            RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_COMPLAINTS_POST)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.successMessage(response?.data?.message)
                        this.onClearHandler()
                        this.setState({ postLoading: false, isEdit: false, isDisable: false })
                        this.props.CallgetApi('complaints')
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ postLoading: false, isDisable: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ postLoading: false, isDisable: false })
                        this.errorMessage(error.message)
                    }
                })

        } catch (error) {
            this.setState({ postLoading: false, isDisable: false })
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message,
            isDeleteClicked: false,
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }
    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    popupClose() {
        this.setState({ isDeleteClicked: false })
    }

    render() {
        const { t } = this.props
        let states = this.state
        return (
            <Box>
                <Box height={"61.3vh"}>
                    <Typography className='eMed_refdoc_title'>{t("Complaints")}</Typography>
                    <Box component={'div'} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '1vw', mt: "1.5vw", gap: "0.5vw" }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '1vw' }}>
                            <Autocomplete
                                size='small'
                                sx={{ width: '18vw' }}
                                options={this.state.tempData}
                                getOptionLabel={(options) => options}
                                value={this.state?.Complaints}
                                onChange={(e, newValue) => {
                                    states["Complaints"] = newValue
                                    this.setState({
                                        states
                                    })
                                }}
                                renderInput={(params) => <TextField  {...params}
                                inputProps={{ ...params.inputProps,maxLength: 100 }}   
                                autoComplete='off'                                 
                                placeholder="Complaints*"
                                    onChange={(e) => {
                                        if (e.target.value != "") {
                                            this.setState({
                                                Complaints: e.target.value
                                            }, () => { this.getComplainssuggestion(e.target.value) })
                                        } else {
                                            this.setState({ tempData: [] })
                                        }
                                    }}
                                />}
                            />
                        </Box>
                        <Box component={'div'} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                            <FormControl size='small' style={{ marginRight: "1vw" }}>
                                <InputLabel>Select Duration</InputLabel>
                                <Select
                                    sx={{ width: '9vw' }}
                                    label='Select Duration'
                                    value={this.state.selectedDays}
                                    onChange={(e) => this.setState({ selectedDays: e.target.value, selectedDuration: '' })}
                                >
                                    <MenuItem value={'1'}>Days</MenuItem>
                                    <MenuItem value={'2'}>Weeks</MenuItem>
                                    <MenuItem value={'3'}>Months</MenuItem>
                                    <MenuItem value={'4'}>Years</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>

                    <Box className="eMed_DocNotes_FreqChips_Wrapp">
                        <Box component={'div'} mt={'1vw'}>
                            <Typography sx={{ marginLeft: '0.7vw', marginBottom: "0.7vh" }} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Duration</Typography>

                            {this.state.selectedDays === '1' ?
                                this.state.durationDays?.map((item, index) => (
                                    <Chip
                                        className={`eMed_chip ${item.value === this.state.selectedDuration ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                        label={item.label}
                                        variant={item.value === this.state.selectedDuration ? 'contained' : 'outlined'}
                                        key={index}
                                        sx={{ marginLeft: '0.7vw' }}
                                        onClick={() => { this.setState({ selectedDuration: item?.value }) }}
                                        clickable
                                    />
                                )) : this.state.selectedDays === '2' ?
                                    this.state.durationWeeks?.map((item, index) => (
                                        <Chip
                                            className={`eMed_chip ${item.value === this.state.selectedDuration ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                            label={item.label}
                                            sx={{ marginLeft: '0.7vw' }}
                                            variant={item.value === this.state.selectedDuration ? 'contained' : 'outlined'}
                                            key={index}
                                            onClick={() => { this.setState({ selectedDuration: item?.value }) }}
                                            clickable
                                        />
                                    )) : this.state.selectedDays === '3' ?
                                        this.state.durationMonts?.map((item, index) => (
                                            <Chip
                                                className={`eMed_chip ${item.value === this.state.selectedDuration ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                label={item.label}
                                                variant={item.value === this.state.selectedDuration ? 'contained' : 'outlined'}
                                                key={index}
                                                sx={{ marginLeft: '0.7vw' }}
                                                onClick={() => { this.setState({ selectedDuration: item?.value }) }}
                                                clickable
                                            />
                                        )) : this.state.durationYears?.map((item, index) => (
                                            <Chip
                                                className={`eMed_chip ${item.value === this.state.selectedDuration ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                label={item.label}
                                                variant={item.value === this.state.selectedDuration ? 'contained' : 'outlined'}
                                                key={index}
                                                sx={{ marginLeft: '0.7vw' }}
                                                onClick={() => { this.setState({ selectedDuration: item?.value }) }}
                                                clickable
                                            />
                                        ))
                            }

                        </Box>
                        <Box component={'div'} style={{ display: "flex", flexDirection: "column", paddingLeft: "0.5vw", marginTop: "0.5vw" }}>
                            <Box component={'div'} height={'2vw'} display={'flex'} alignItems={'center'}>
                                <Typography style={{ marginLeft: "0.5vw" }} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Description</Typography>
                            </Box>
                            <Box component={'div'} style={{ marginLeft: "0.5vw" }}>
                                <TextField
                                    multiline
                                    label='Description'
                                    rows={3}
                                    sx={{ width: '26.7vw', marginTop: "0.5vw" }}
                                    value={this.state.complainDescrp}
                                    onChange={(e) => {
                                        let value = e.target.value
                                        if (value?.length < 250) {
                                            this.setState({ complainDescrp: e.target.value })
                                        } else {
                                            this.errorMessage('Allowed only 250 characters')
                                        }
                                    }}
                                />
                            </Box>
                        </Box>

                    </Box>


                </Box>
                <Stack spacing={2} direction="row" sx={{ backgroundColor: Colors.DataTblHeaderbg }} marginTop={"1vh"} alignItems="center" justifyContent="center" height={"6vh"}>
                    <Button variant='outlined' size="small" _id="eMed_Config_btn" onClick={() => { this.onClearHandler() }}>{t("Clear")}</Button>
                    <Button variant='contained' size="small" id="eMed_Config_btn" disabled ={this.state.isDisable} onClick={() => { 
                        this.setState({isDisable : true},()=>{ this.addComplaint()})
                        }}>{t("Add")}</Button>
                </Stack>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.isDeleteClicked ? <DeletePopup
                    DeletTitle={`${t("deleteMsg")}  ${t("Category")} ${this.state.SelectedCetegory ? this.state.SelectedCetegory : "-"} From Suggestion List ?`}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this.DeleteCategory.bind(this)}
                /> : null}
            </Box>
        )
    }
}
export default withTranslation()(OtherDoccomplaintsRight)

