import { Autocomplete, Box, Button, Drawer, FormControlLabel, Checkbox, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { CommonGridToolBarWithFilterText} from "../../../../Components/Common Components/CommonComponents";
import '../reports.css';
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards'
import { ImagePaths } from '../../../../Utility/ImagePaths';
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { AmountFormat, formatDate} from "../../../../Components/CommonFunctions/CommonFunctions";
import { DateTime } from 'luxon';
import { CurrencySymbol } from '../../../../Utility/Constants';
import { localGetItem } from '../../../../Utility/Services/CacheProviderService';

class CreditSettlementReport extends Component {

    constructor(props) {
        super(props)
        let LoggedData = localGetItem("loggedInUserInfo") ? JSON.parse(localGetItem("loggedInUserInfo")) : null
        this.state = {
            page: 0,
            pageSize: 10,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            appointmentType: [],
            dateIndex: 1,
            creditAdjustedData: [],
            appointmentTypeCardList: [],
            totalAppointment: null,
            totalAmount: null,
            appointmentTypeList: [],
            FilteredFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilteredToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilterDatas: {
                "startTime": null,
                "endTime": null,
                "onError": false,
            },
            timeError: false,
            creditType: [],
            opSelected: true,
            ipSelected: true,
            labSelected: true,
            pharmacySelected: true,
            OtSelected: true,
            RadiologySelected: localGetItem("Show_Radiology_Bills_In_FO") === "true",
            PharmacyData: [],
            LabData: []
        }
    }

    componentDidMount() {
        let LoggedUserData = JSON.parse(localGetItem("loggedInUserInfo"))
        let pharmaDetails = JSON.parse(JSON.stringify((LoggedUserData?.pharmacy_data)))
        let labDetails = JSON.parse(JSON.stringify((LoggedUserData?.laboratory_data)))
        pharmaDetails?.forEach((item) => (
            item["isChecked"] = true
        ))
        labDetails?.forEach((item) => (
            item["isChecked"] = true
        ))
        this.setState({
            PharmacyData: pharmaDetails,
            LabData: labDetails
        }, () => { this.getCreditAdjustedData() })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    onChangeHandler = (e) => {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        }, () => {
            this.state.FilterDatas.endTime = null
            this.setState({
                FilterDatas: this.state.FilterDatas
            })
        })
    }

    getCreditAdjustedData = () => {
        try {
            let LabId = this.state.PharmacyData?.map((item)=> item?.pharmacy_id)
            let PharmacyId = this.state.LabData?.map((item)=> item?.laboratory_id)
            let moduleType = []
            if (this.state.opSelected) {
                moduleType.push("op")
            }
            if (this.state.ipSelected) {
                moduleType.push("ip")
            }
            if (this.state.OtSelected) {
                moduleType.push("ot")
            }
            if (this.state.RadiologySelected) {
                moduleType.push("radiology")
            }
            if (localGetItem("multiLaboratory") === "true" || localGetItem("multiPharmacy") === "true") {
                let selectedLab = this.state.LabData?.filter((item) => item?.isChecked);
                let selectedPharmacy = this.state.PharmacyData?.filter((item) => item?.isChecked);
                LabId = selectedLab?.length > 0 ? selectedLab?.map((item) => item?.laboratory_id) : ""
                PharmacyId = selectedPharmacy?.length > 0 ? selectedPharmacy?.map((item) => item?.pharmacy_id) : ""
                if (LabId !== "") {
                    moduleType.push("lab")
                }
                if (PharmacyId !== "") {
                    moduleType.push("pharmacy")
                }
            } else {
                if (this.state.labSelected) {
                    moduleType.push("lab")
                }
                if (this.state.pharmacySelected) {
                    moduleType.push("pharmacy")
                }
            }
            this.LoaderFunction(true)
            var { FilterDatas } = this.state
            RestAPIService.getAll(Serviceurls.FO_REPORT_CREDIT_ADJUSTED_REPORTS + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&credit_type=${this.state.creditType ? this.state.creditType : ''}&module_type=${moduleType}&laboratory_id=${LabId}&pharmacy_id=${PharmacyId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            creditAdjustedData: response.data.data,
                            totalAmount: response.data.bill_amount,
                            receiptAmount: response.data.receipt_amount,
                            FilteredFromDate: this.state.fromDate,
                            FilteredToDate: this.state.toDate,

                        }, () => { this.LoaderFunction(false) })
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }
    printReports = () => {
        try {
            let LabId = this.state.PharmacyData?.map((item)=> item?.pharmacy_id)
            let PharmacyId = this.state.LabData?.map((item)=> item?.laboratory_id)
            let moduleType = []
            if (this.state.opSelected) {
                moduleType.push("op")
            }
            if (this.state.ipSelected) {
                moduleType.push("ip")
            }
            if (this.state.OtSelected) {
                moduleType.push("ot")
            }
            if (this.state.RadiologySelected) {
                moduleType.push("radiology")
            }
            if (localGetItem("multiLaboratory") === "true" || localGetItem("multiPharmacy") === "true") {
                let selectedLab = this.state.LabData?.filter((item) => item?.isChecked);
                let selectedPharmacy = this.state.PharmacyData?.filter((item) => item?.isChecked);
                LabId = selectedLab?.length > 0 ? selectedLab?.map((item) => item?.laboratory_id) : ""
                PharmacyId = selectedPharmacy?.length > 0 ? selectedPharmacy?.map((item) => item?.pharmacy_id) : ""
                if (LabId !== "") {
                    moduleType.push("lab")
                }
                if (PharmacyId !== "") {
                    moduleType.push("pharmacy")
                }
            } else {
                if (this.state.labSelected && PharmacyId !== "") {
                    moduleType.push("lab")
                }
                if (this.state.pharmacySelected) {
                    moduleType.push("pharmacy")
                }
            }
            var { FilterDatas } = this.state
            RestAPIService.getAll(Serviceurls.FO_REPORT_CREDIT_ADJUSTED_REPORTS + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&credit_type=${this.state.creditType ? this.state.creditType : ''}&module_type=${moduleType}&laboratory_id=${LabId}&pharmacy_id=${PharmacyId}&export_type=pdf`)
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    handleCheckBox = (e, data) => {
        let states = this.state
        data["isChecked"] = e.target.checked
        this.setState({ states })
    }

    renderFilterScreen = () => {
        const { t } = this.props
        let CreditTypeList = [{
            label: "Corprate credit",
            value: "Corprate credit"
        }, {
            label: "Patient credit",
            value: "Patient credit"
        }, {
            label: "Insurance credit",
            value: "Insurance credit"
        }]
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
                    <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)}
                            HideAllMenu={true}/>
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            options={CreditTypeList.map((item) => (item.value))}
                            getOptionLabel={(option) => (option)}
                            value={this.state.creditType}
                            onChange={(event, newValue) => {
                                this.setState({ creditType: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('Credit Type')}
                                />
                            )}
                        />
                    </Box>
                    <Box component={"div"}>
                        <Box className="eMed_Filter_Header">
                            <Typography marginLeft={"1vw"} >Module Type</Typography>
                        </Box>
                        <Box className="eMed_CheckBox_Group">
                            <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.opSelected} onChange={(e) => { this.setState({ opSelected: e.target.checked }) }} size='small' />} label={t("Out Patient")} />
                            <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.ipSelected} onChange={(e) => { this.setState({ ipSelected: e.target.checked }) }} size='small' />} label={t("In Patient")} />
                            <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.OtSelected} onChange={(e) => { this.setState({ OtSelected: e.target.checked }) }} size='small' />} label={t("OT")} />
                            <FormControlLabel disabled={localGetItem("Show_Radiology_Bills_In_FO") !== "true"} className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.RadiologySelected} onChange={(e) => { this.setState({ RadiologySelected: e.target.checked }) }} size='small' />} label={t("Radiology")} />
                            {localGetItem('multiLaboratory') === "true" ?
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    {this.state.LabData?.map((item, i) => (
                                        <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={item?.isChecked} onChange={(e) => { this.handleCheckBox(e, item) }} size='small' />} label={item?.laboratory_name} />
                                    ))}
                                </Box>
                                : <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.labSelected} onChange={(e) => { this.setState({ labSelected: e.target.checked }) }} size='small' />} label={t("Lab")} />
                            }
                            {localGetItem('multiPharmacy') === "true" ?
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    {this.state.PharmacyData?.map((item, i) => (
                                        <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={item?.isChecked} onChange={(e) => { this.handleCheckBox(e, item) }} size='small' />} label={item?.pharmacy_name} />
                                    ))}
                                </Box>
                                : <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.pharmacySelected} onChange={(e) => { this.setState({ pharmacySelected: e.target.checked }) }} size='small' />} label={t("Pharmacy")} />
                            }
                        </Box>
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"
                        onClick={() => {
                            this.setState({
                                creditType: [],
                                optionType: [],
                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                dateIndex: 1,
                                FilterDatas: {
                                    "startTime": null,
                                    "endTime": null,
                                    "onError": false
                                },
                                timeError: false,
                                opSelected: true,
                                ipSelected: true,
                                labSelected: true,
                                pharmacySelected: true,
                                OtSelected: true,
                                RadiologySelected: localGetItem("Show_Radiology_Bills_In_FO") === "true",
                            }, () => this.getCreditAdjustedData())
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small"
                        disabled={this.state.FilterDatas.startTime ? (this.state.FilterDatas.startTime && this.state.FilterDatas.endTime && this.state.timeError != true) ? false : true : false}
                        onClick={() => {
                            this.getCreditAdjustedData()
                            this.setState({
                                FilterOpen: false
                            })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }
    gridToolBar = () => {
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.FilteredFromDate) },
            { label: "To Date", value: formatDate(this.state.FilteredToDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
            </Box>

        )
    }
    render() {
        this.state.creditAdjustedData.forEach((element, index) => element.sno = index + 1)
        const { t } = this.props
        const columns = [
            {
                field: "invoice_number", headerName: t("Bill No"), flex: 0.15, align: "center", headerAlign: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.invoice_number ? params?.row?.invoice_number?.length > 15 ?
                    <Tooltip placement='top' title={params?.row?.invoice_number} arrow><div>{"..." + params?.row?.invoice_number.slice(-15)}</div></Tooltip> : params?.row?.invoice_number : "-"}</Box>)
            },
            {
                field: "invoice_date", headerName: t("Bill Date"), flex: 0.15, align: "center" , headerAlign: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.invoice_date ? params?.row?.invoice_date?.length > 30 ?
                    <Tooltip placement='top' title={params?.row?.invoice_date} arrow><div>{params?.row?.invoice_date.slice(0, 30) + "..."}</div></Tooltip> : params?.row?.invoice_date : "-"}</Box>)
            },
            {
                field: "net_amount", headerName: t("Bill Amount"), flex: 0.15, align: "right", headerAlign: "right",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.net_amount ? params?.row?.net_amount?.length > 30 ?
                    <Tooltip placement='top' title={params?.row?.net_amount} arrow><div>{params?.row?.net_amount.slice(0, 30) + "..."}</div></Tooltip> : params?.row?.net_amount : "0.00"}</Box>)
            },
            {
                field: "credit_type", headerName: t("Credit type"), flex: 0.12,  align: "center",  headerAlign: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.credit_type ? params?.row?.credit_type?.length > 30 ?
                    <Tooltip placement='top' title={params?.row?.credit_type} arrow><div>{params?.row?.credit_type.slice(0, 30) + "..."}</div></Tooltip> : params?.row?.credit_type : "-"}</Box>)
            },
            {
                field: "receipt_number", headerName: t("Receipt No"), flex: 0.13, align: "center", headerAlign: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.receipt_number ? params?.row?.receipt_number?.length > 15 ?
                    <Tooltip placement='top' title={params?.row?.receipt_number} arrow><div>{"..." + params?.row?.receipt_number.slice(-15)}</div></Tooltip> : params?.row?.receipt_number : "-"}</Box>)
            },
            {
                field: "receipt_date", headerName: t("Receipt Date"), flex: 0.15,  align: "center",  headerAlign: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.receipt_date ? params?.row?.receipt_date?.length > 30 ?
                    <Tooltip placement='top' title={params?.row?.receipt_date} arrow><div>{params?.row?.receipt_date.slice(0, 30) + "..."}</div></Tooltip> : params?.row?.receipt_date : "-"}</Box>)
            },
            {
                field: "receipt_amount", headerName: `${t("Receipt Amount")} (${CurrencySymbol})`, flex: 0.15, type: "number",
                renderCell: (params) => {
                    let amount = params.row.receipt_amount && AmountFormat(params?.row?.receipt_amount).replace(`${CurrencySymbol}`, "");
                    return (<Box component={'div'}>{amount?.length > 30 ?
                        <Tooltip placement='top' title={amount} arrow><div>{amount.slice(0, 30) + "..."}</div></Tooltip> : amount ? amount : "0.00"}</Box>)
                }
            }
        ]
        return (
            <Box component={'div'} className='eMed_rts_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'>
                        {AmountsCard(t("Bill Amount"), this.state.totalAmount)}
                        {
                            AmountsCard(t("Receipt Amount"), this.state.receiptAmount)
                        }
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container'>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </Button>
                        </Box>
                        <Button className='eMed_usrconf_btn' onClick={() => { this.printReports() }}>
                            <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                        </Button>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_rts_table'>
                    <DataGrid
                        rows={this.state.creditAdjustedData}
                        columns={columns}
                        getRowId={(row) => row.sno}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                    />
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }
}


export default withTranslation()(CreditSettlementReport)