import React from "react";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Colors } from "../Styles/Colors";

class BillingTransaction extends React.Component {

    render() {
        return (
            <div>
                <Backdrop
                    sx={{ color: Colors.ThemeColor, zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.props.loaderOpen || false}
                    onClick={this.dropHandleClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        )
    }
}

export default BillingTransaction;