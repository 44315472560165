import React, { Component } from 'react';
import './Billings.css';
import {
    IconButton, Box, Stack, Button, Autocomplete, Typography, TextField,
    Modal, Tooltip, InputAdornment, FormControlLabel, Checkbox, FormHelperText
} from '@mui/material';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { withTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTime } from "luxon";
import { Bucket_Name, CurrencySymbol } from '../../../Utility/Constants';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import { Colors } from '../../../Styles/Colors';
import CommonValidation from "../../../Components/CommonFunctions/CommonValidation";
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { localGetItem } from "../../../Utility/Services/CacheProviderService";

let AWS = require('aws-sdk');
let s3 = new AWS.S3({
    region: "ap-south-1"
});

const paymentLookup = {
    1: "Cash",
    2: "Card",
    3: "UPI",
    4: "Cheque",
    5: "BankTransfer"
}

class ExpensePopUp extends Component {
    constructor(props) {
        super(props)
        let viewData = this.props.View && this.props.viewData !== undefined && Object.keys(this.props.viewData)?.length > 0 ? this.props.viewData : {};
        let modifyData = this.props.Modify && this.props.modifyData !== undefined && Object.keys(this.props.modifyData)?.length > 0 ? this.props.modifyData : {};
        this.state = {
            isAdd: this.props.Add ? this.props.Add : false,
            isView: this.props.View ? this.props.View : false,
            isModify: this.props.Modify ? this.props.Modify : false,
            expCategoryList: this.props.expCategoryList?.length > 0 ? this.props.expCategoryList : [],
            addExp_date: new Date(),
            add_category: "",
            category_id: null,
            add_amount: 0,
            add_payment: "Cash",
            add_payment_mode: 1,
            add_notes: "",
            imgUpload: null,
            modify_id: null,
            viewDetail: viewData,
            modifyDetails: modifyData,
            cash: false,
            card: false,
            upi: false,
            bank: false,
            cheque: false,
            newMultiImageList: [],
            CashAmount: "",
            CardAmount: "",
            CardTransactionNumber: "",
            UPINumber: "",
            UPIAmount: "",
            BankTfrAmount: "",
            BankTfrNumber: "",
            ChequeNumber: "",
            ChequeAmount: "",
            selectedImage: null,
            deletedImages: [],
            LoggedData: {},
            previewImg: [],
        }
        this.uploadImage = React.createRef();
        this.multiUploadImage = React.createRef();
    }

    componentDidMount() {
        let userInfo = JSON.parse(localGetItem("loggedInUserInfo") || "{}");
        this.setState({
            LoggedData: userInfo
        }, () => {
            this.assignDetails();
        })
    }

    lodaerFunction = (data) => {
        this.props.LoaderFunction(data)
    }

    successMessage = (msg) => {
        this.props.successMessage(msg);
    }
    errorMessage = (msg) => {
        this.props.errorMessage(msg);
    }

    async getS3Key(doc_key) {
        try {
            const response = await RestAPIService.getAllTwo(Serviceurls.AWS_URL_GET + "?doc_key=" + encodeURIComponent(doc_key));
    
            if (response.data.status === "success") {
                const Data = response.data;
                return {
                    "data_url": Data.data,
                    "image_title": doc_key
                };
            }
        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: "Error fetching S3 URL"
            });
        }
    }

    async renderImage(image) {
        if (image !== null) {
            let doc_key = image;
            let getData = await this.getS3Key(doc_key)
            getData["type"] = image.split('.').pop() === "pdf" ? "pdf" : "image"
            const imgList = [...this.state.newMultiImageList, getData];
            this.setState({
                imgUpload: getData,
                newMultiImageList: imgList,
                selectedImage: (imgList?.length > 0 && this.state.isView) ? (imgList[0]?.data_url || ImagePaths.ImagePreview.default) : null
            })
        }
    }

    assignDetails = () => {

        let states = this.state;
        let availData = {}
        if (states?.isAdd === false && states?.isView && !this.props.fromExpenseApproval && Object.keys(states.viewDetail)?.length > 0) {
            availData = { ...states.viewDetail }
            // if (states.viewDetail?.file_name) {
            //     this.renderImage(states.viewDetail?.file_name)
            // }
            if (states.viewDetail?.file_name?.length > 0) {
                states.viewDetail?.file_name?.forEach(element => this.renderImage(element))
            }
        } else if (states?.isAdd === false && states?.isModify && !this.props.fromExpenseApproval && Object.keys(states.modifyDetails)?.length > 0) {
            availData = { ...states.modifyDetails }
            if (states.modifyDetails?.file_name) {
                states.modifyDetails.file_name?.forEach(element => this.renderImage(element))
            }
            // if (states.modifyDetails?.file_name?.length > 0) {
            //     states.modifyDetails?.file_name?.forEach(element => this.renderImage(element))
            // }
        }
        if (this.props.fromExpenseApproval && states?.isView && Object.keys(states.viewDetail)?.length > 0) {
            const { request_data } = { ...states.viewDetail };
            const imgList = request_data?.document? JSON.parse(request_data?.document || "[]") : [];
            this.setState({
                modify_id: request_data.id,
                addExp_date: request_data?.bill_date ? new Date(request_data.bill_date) : new Date(),
                add_category: states?.viewDetail?.expense_category,
                category_id: request_data?.expense_category,
                add_amount: +(request_data.amount),
                add_payment: states?.viewDetail?.payment_mode ? states?.viewDetail?.payment_mode : "Cash",
                add_payment_mode: request_data.payment_mode ? request_data.payment_mode : 1,
                add_notes: request_data.description,
                cash: request_data?.cash_amount ? true : false,
                card: request_data?.card_amount ? true : false,
                upi: request_data?.upi_amount ? true : false,
                bank: request_data?.bank_amount ? true : false,
                cheque: request_data?.cheque_amount ? true : false,
                CashAmount: request_data?.cash_amount ? request_data?.cash_amount : '',
                CardAmount: request_data?.card_amount ? request_data?.card_amount : '',
                UPIAmount: request_data?.upi_amount ? request_data?.upi_amount : '',
                BankTfrAmount: request_data?.bank_amount ? request_data?.bank_amount : '',
                ChequeAmount: request_data?.cheque_amount ? request_data?.cheque_amount : '',
                CardTransactionNumber: request_data?.card_ref_no ? request_data?.card_ref_no : '',
                BankTfrNumber: request_data?.bank_ref_no ? request_data?.bank_ref_no : '',
                UPINumber: request_data?.upi_ref_no ? request_data?.upi_ref_no : '',
                ChequeNumber: request_data?.cheque_ref_no ? request_data?.cheque_ref_no : '',
                newMultiImageList: imgList,
                selectedImage: imgList?.length > 0 ? (imgList[0]?.data_url || ImagePaths.ImagePreview.default) : null
            })
        } else {
            this.setState({
                modify_id: availData.id,
                addExp_date: availData?.bill_date ? new Date(availData.bill_date) : new Date(),
                add_category: availData.category_name,
                category_id: availData.category_id,
                add_amount: +(availData.amount),
                add_payment: availData.payment_type ? availData.payment_type : "Cash",
                add_payment_mode: availData.payment_mode ? availData.payment_mode : 1,
                add_notes: availData.description,
                cash: availData?.cash_amount ? true : false,
                card: availData?.card_amount ? true : false,
                upi: availData?.upi_amount ? true : false,
                bank: availData?.bank_transfer_amount ? true : false,
                cheque: availData?.cheque_amount ? true : false,
                CashAmount: availData?.cash_amount ? availData?.cash_amount : '',
                CardAmount: availData?.card_amount ? availData?.card_amount : '',
                UPIAmount: availData?.upi_amount ? availData?.upi_amount : '',
                BankTfrAmount: availData?.bank_transfer_amount ? availData?.bank_transfer_amount : '',
                ChequeAmount: availData?.cheque_amount ? availData?.cheque_amount : '',
                CardTransactionNumber: availData?.card_ref_no ? availData?.card_ref_no : '',
                BankTfrNumber: availData?.bank_transfer_amount ? availData?.bank_transfer_amount : '',
                UPINumber: availData?.upi_ref_no ? availData?.upi_ref_no : '',
                ChequeNumber: availData?.cheque_ref_no ? availData?.cheque_ref_no : ''
            })
        }
    }

    expenseBillRequest = (reqData) => {
        const states = this.state;
        const to_date = new Date();
        const startDate = new Date(states.addExp_date);
        const Aptime = DateTime.fromJSDate(startDate).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        const toTime = DateTime.fromJSDate(to_date).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        const addExpDate = states.addExp_date ? `${DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd")} ${Aptime}` : "";
        const toDate = toTime ? `${DateTime.fromJSDate(to_date).toFormat("yyyy-MM-dd")} ${toTime}`: ""; 
        let data = {
            'request_date': states.addExp_date ? addExpDate : toDate,
            'from_bill_amount': states.add_amount ? states.add_amount : 0,
            'to_bill_amount': 0,
            'request_data': reqData,
            'approved_data': "",
            'status': "Pending",
            'remarks': states.add_notes ? states.add_notes : "",
            'payment_mode': reqData?.payment_mode?.length > 0 ? reqData?.payment_mode?.map(value => paymentLookup[value])?.join(', ') : "",
            'expense_for': states.modify_id ? "Modify" : "Add",
            'expense_category': reqData?.expense_category ? states.expCategoryList?.find(item => item.id === reqData?.expense_category)?.name || "" : "",
        }
        RestAPIService.create(data, Serviceurls.EXPENSE_REQUEST_POST)
            .then((response) => {
                if (response.data.status === "success") {
                    this.props.getDetails();
                    this.props.getMakerCount(true);
                    this.handleClose();
                    this.lodaerFunction(false)
                    this.successMessage(response.data.message);
                    this.setState({ disableBtn: false })
                }
            }).catch((error) => {
                this.lodaerFunction(false)
                this.setState({ disableBtn: false })
                if (error?.response?.data?.status === "fail") {
                    this.errorMessage(error.response.data.message)
                } else {
                    this.errorMessage(error.message)
                }
            })
    }

    CheckAllFields = () => {
        let states = this.state;
        if (states.addExp_date === "Invalid Date" || !states.category_id || !states.add_amount || !states.add_notes) {
            return "Fillmandatory";
        }
        return "";
    }

    handlePayExpense = () => {
        try {
            let states = this.state;
            let isAvl = this.CheckAllFields();
            let to_date = new Date();
            let method = "create";
            let StateDate = new Date(states.addExp_date)
            let paymentMode = [];
            if (states.cash) {
                paymentMode?.push(1)
            }
            if (states.card) {
                paymentMode?.push(2)
            }
            if (states.upi) {
                paymentMode?.push(3)
            }
            if (states.cheque) {
                paymentMode?.push(4)
            }
            if (states.bank) {
                paymentMode?.push(5)
            }

            if (isAvl === "") {
                if (states.cash || states.card || states.upi || states.bank || states.cheque) {
                    let amount = (+states.CashAmount) + (+states.CardAmount) + (+states.UPIAmount) + (+states.BankTfrAmount) + (+states.ChequeAmount)
                    if (+amount !== +states.add_amount) {
                        this.errorMessage("Amount dosen't match with Total Amount")
                    } else if (
                        (states.card && !states.CardAmount) || (states.upi && !states.UPIAmount) ||
                        (states.bank && !states.BankTfrAmount) || (states.cheque && !states.ChequeAmount)
                    ) {
                        this.errorMessage("Enter amount in all payment type selected")
                    } else if (
                        (states.card && states.CardAmount && !states.CardTransactionNumber) ||
                        (states.upi && states.UPIAmount && !states.UPINumber) ||
                        (states.bank && states.BankTfrAmount && !states.BankTfrNumber) ||
                        (states.cheque && states.ChequeAmount && !states.ChequeNumber)
                    ) {
                        this.errorMessage("Enter Transaction Number")
                    } else {
                        const dataForm = new FormData();
                        const billDate = states.addExp_date ? DateTime.fromJSDate(StateDate).toFormat('yyyy-MM-dd') :
                            DateTime.fromJSDate(to_date).toFormat('yyyy-MM-dd');
                        let deletedFile = [];
                        this.state.deletedImages?.forEach(element => {
                            deletedFile?.push(element?.image_title)
                        });
                        if (this.state.LoggedData?.is_user && !this.state.LoggedData?.permission_access?.common?.add_expense_access) {
                            const requestData = {
                                "receiver_name": "",
                                "bill_date": billDate,
                                "payment_type": paymentMode ? paymentMode : [],
                                "payment_mode": paymentMode ? paymentMode : [],
                                "amount": states.add_amount ? states.add_amount : 0,
                                "description": states.add_notes ? states.add_notes : "",
                                "expense_category": states.category_id ? states.category_id : null,
                                'cash_amount': states.CashAmount ? states.CashAmount : 0,
                                'card_amount': states.CardAmount ? states.CardAmount : 0,
                                'upi_amount': states.UPIAmount ? states.UPIAmount : 0,
                                'bank_amount': states.BankTfrAmount ? states.BankTfrAmount : 0,
                                'cheque_amount': states.ChequeAmount ? states.ChequeAmount : 0,
                                'card_ref_no': states.CardTransactionNumber ? states.CardTransactionNumber : '',
                                'upi_ref_no': states.UPINumber ? states.UPINumber : '',
                                'bank_ref_no': states.BankTfrNumber ? states.BankTfrNumber : '',
                                'cheque_ref_no': states.ChequeNumber ? states.ChequeNumber : '',
                                'document': states.modify_id ?
                                    (this.state.newMultiImageList.length > 0 ? JSON.stringify(this.state.newMultiImageList) : undefined) :
                                    (this.state.previewImg?.length > 0 ? JSON.stringify(this.state.previewImg) : undefined),
                                'id': states.modify_id ? states.modify_id : undefined,
                                'deleted_file': deletedFile?.length > 0 ? deletedFile : []
                            };
                            this.lodaerFunction(true)
                            this.setState({ disableBtn: true })
                            this.expenseBillRequest(requestData)
                        } else {
                        // dataForm.append("document", states.imgUpload ? states.imgUpload : "")
                        dataForm.append("receiver_name", "");
                        dataForm.append("bill_date", billDate);
                        dataForm.append("payment_type", paymentMode ? paymentMode : []);
                        dataForm.append("payment_mode", paymentMode ? paymentMode : []);
                        dataForm.append("amount", states.add_amount ? states.add_amount : 0);
                        dataForm.append("description", states.add_notes ? states.add_notes : "");
                        dataForm.append("expense_category", states.category_id ? states.category_id : null);
                        dataForm.append('cash_amount', states.CashAmount ? states.CashAmount : 0)
                        dataForm.append('card_amount', states.CardAmount ? states.CardAmount : 0)
                        dataForm.append('upi_amount', states.UPIAmount ? states.UPIAmount : 0)
                        dataForm.append('bank_amount', states.BankTfrAmount ? states.BankTfrAmount : 0)
                        dataForm.append('cheque_amount', states.ChequeAmount ? states.ChequeAmount : 0)
                        dataForm.append('card_ref_no', states.CardTransactionNumber ? states.CardTransactionNumber : '')
                        dataForm.append('upi_ref_no', states.UPINumber ? states.UPINumber : '')
                        dataForm.append('bank_ref_no', states.BankTfrNumber ? states.BankTfrNumber : '')
                        dataForm.append('cheque_ref_no', states.ChequeNumber ? states.ChequeNumber : '')
                        dataForm.append('deleted_file', deletedFile ? deletedFile : [])

                        for (let i = 0; i < this.state.newMultiImageList.length; i++) {
                            if (this.state.newMultiImageList[i]["type"] === "application/pdf") {
                                if (this.state.newMultiImageList[i]["data_url"]) {
                                    dataForm.append("document", this.state.newMultiImageList[i]?.image_title)
                                } else {
                                    dataForm.append("document", this.state.newMultiImageList[i])
                                }
                            }
                            if (this.state.newMultiImageList[i]["type"] !== "application/pdf") {
                                // dataForm.append("report_file", newMultiImageList[i])
                                if (this.state.newMultiImageList[i]["data_url"]) {
                                    dataForm.append("document", this.state.newMultiImageList[i]?.image_title)
                                } else {
                                    dataForm.append("document", this.state.newMultiImageList[i])
                                }
                            }
                        }

                        if (states.modify_id) {
                            dataForm.append("id", states.modify_id ? states.modify_id : null);
                            method = "updateWithOutId"
                        }

                        this.lodaerFunction(true)
                        this.setState({ disableBtn: true })
                        RestAPIService[method](dataForm, Serviceurls.EXPENSE_BILL_GET)
                            .then(response => {
                                if (response.data.status === "success") {
                                    this.handleClose();
                                    this.props.getDetails();
                                    this.lodaerFunction(false)
                                    this.successMessage(response.data.message);
                                    this.setState({ disableBtn: false })
                                }
                            })
                            .catch(error => {
                                if (error?.response?.data?.status === "fail") {
                                    this.lodaerFunction(false)
                                    this.setState({ disableBtn: false })
                                    this.errorMessage(error.response.data.message);
                                }
                            });
                        }
                    }
                } else {
                    this.errorMessage("Please Select Payment type")
                }
            } else {
                this.errorMessage(isAvl)
            }
        } catch (e) {
            this.errorMessage(e.message);
        }
    }

    renderImgCard = () => {
        const { t } = this.props;
        let states = this.state;

        return (
            <Stack className="eMed_IP_ExpPop_imgDiv" justifyContent="center" alignItems="center"
                sx={{ backgroundColor: (states.isView || states.isModify) ? "" : Colors.Background }}>
                <input
                    type="file"
                    accept="application/pdf, image/jpg, image/jpeg, image/png"
                    onChange={(e) => {
                        this.setState({ imgUpload: e.target.files[0] })
                        e.target.value = ''
                    }}
                    ref={this.uploadImage}
                    style={{ display: "none" }}
                />
                {
                    states.imgUpload === null || !states.imgUpload || states.imgUpload === undefined ?
                        <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
                            <Stack className="eMed_IP_ExpPop_imgSubDiv" justifyContent="center" alignItems="center" spacing={0.5}>
                                <IconButton size="small" disabled={states.isView ? true : false} onClick={() => this.uploadImage.current.click()}>
                                    <img id="eMed_upload_img" src={states.isView ? ImagePaths.LabUploadButton.default : ImagePaths.Export.default} alt="Upload" />
                                </IconButton>
                                <Typography id="eMed_img_lblTxt" >{t("ClkUploadFile")}</Typography>
                                <Typography id="eMed_img_subTxt" sx={{ marginLeft: "0.5vw" }}>{t("imgFormat")}</Typography>
                            </Stack>
                        </Stack> :
                        states.imgUpload || ((states.isView || states.isModify) && states.imgUpload !== undefined) ?
                            <Box>
                                <div className={states.imgUpload?.type === "image" ? "eMed_IP_ExpPop_img" : "eMed_Testrep_Img"}>
                                    <Tooltip title={typeof (states.imgUpload) === "object" ? null : states.imgUpload?.name} arrow placement='top'>
                                        <img
                                            className={states.imgUpload?.type === "image" ? "eMed_IP_ExpPop_img" : 'eMed_Testrep_Img'}
                                            onClick={() => {
                                                if (states.imgUpload?.type === "pdf") {
                                                    window.open(states.imgUpload?.data_url)
                                                }
                                            }}
                                            src={states.imgUpload?.type === "image" ? states.imgUpload?.data_url : (states.imgUpload?.type === "image/jpeg" || states.imgUpload?.type === "image/png") ?
                                                ImagePaths.ImagePreview.default : ImagePaths.DocumentPreview.default}
                                        />
                                    </Tooltip>
                                    {states.isView ? null : <div>
                                        <IconButton id={states.imgUpload?.type === "image" ? "eMed_IP_ExpPop_Icon" : "eMed_Testrep_Icon"}
                                            size="small"
                                            onClick={() => {
                                                this.setState({ imgUpload: null })
                                            }}>
                                            <CancelTwoToneIcon fontSize='small' color="error" />
                                        </IconButton>
                                    </div>}
                                </div>
                            </Box>
                            : null
                }
            </Stack>
        )
    }

    renderDatePicker = (label, statekey) => {
        let states = this.state;
        const { t } = this.props;
        return (
            <div className="eMed_IP_ExpPop_txtbox">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        value={states[statekey] ? states[statekey] : new Date()}
                        inputFormat={"dd-MM-yyyy"}
                        label={t(label) + " *"}
                        readOnly={states.isView}
                        autoComplete="off"
                        onChange={
                            (newDate) => {
                                states[statekey] = newDate
                                this.setState({
                                    states
                                });
                            }}
                        renderInput={(params) => <TextField sx={{ width: "13vw" }} size='small' autoComplete='off' {...params} />}
                    />
                </LocalizationProvider>
            </div>
        )
    }

    renderAutoSlt = (label, stateKey1, stateKey2, showkey, valueKey, Data = [], width) => {
        let states = this.state;
        const { t } = this.props;
        return (
            <div className="eMed_IP_ExpPop_txtbox">
                <Autocomplete
                    freeSolo={states.isView}
                    size='small'
                    clearIcon={false}
                    readOnly={states.isView}
                    sx={{ width: width ? width : '13.8vw' }}
                    options={Data}
                    getOptionLabel={(option) => (typeof (option[showkey]) === "string" ? option[showkey] : option)}
                    value={states[stateKey1] ? states[stateKey1] : ""}
                    ListboxProps={{ style: { maxHeight: 300 } }}
                    onChange={(event, newValue) => {
                        states[stateKey1] = newValue[showkey] // value to be shown
                        states[stateKey2] = newValue[valueKey] // actual value
                        this.setState({ states })
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t(label) + " *"}
                            size="small"
                        />
                    )}
                />
            </div>
        )
    }

    handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value.trimStart();
        let states = this.state;
        switch (name) {
            case "add_amount":
                let isValid = CommonValidation.NumberWithDot(value);
                if (value === "" || (value > 0 && isValid)) {
                    states[name] = +(value);
                }
                break;
            default:
                if (value === "" || value.length <= 150) {
                    states[name] = value;
                }
                break;

        }
        this.setState({ states });
    }

    renderTextBox(label, statekey, width) {
        const { t } = this.props;
        let states = this.state;
        return (
            <div className="eMed_IP_ExpPop_txtbox">
                <TextField
                    sx={{ width: width }}
                    fullWidth
                    size="small"
                    autoComplete="off"
                    label={t(label)}
                    name={statekey}
                    required
                    inputProps={{ readOnly: states.isView }}
                    InputProps={{
                        endAdornment: statekey === "add_amount" ? (
                            <InputAdornment position="end">
                                {`${CurrencySymbol}`}
                            </InputAdornment>
                        ) : null
                    }}
                    multiline={statekey === "add_notes"}
                    rows={3}
                    value={states[statekey] ? states[statekey] : ""}
                    onChange={this.handleChange}
                />
            </div>
        )
    }

    handleClear = () => {
        this.setState({
            addExp_date: new Date(),
            add_category: null,
            add_amount: 0,
            add_category: "",
            category_id: null,
            add_amount: 0,
            add_payment: "Cash",
            add_payment_mode: 1,
            add_notes: "",
            imgUpload: null,
            modify_id: null,
            cash: false,
            card: false,
            upi: false,
            bank: false,
            cheque: false,
            CardAmount: '',
            CashAmount: '',
            UPIAmount: '',
            BankTfrAmount: '',
            ChequeAmount: '',
            CardTransactionNumber: '',
            UPINumber: '',
            BankTfrNumber: '',
            ChequeNumber: '',
            selectedImage: null,
            newMultiImageList: []
        })
    }

    handleClose = () => {
        this.handleClear();
        this.props.popUpClose();
    }

    onChangeCheckBox = (e, key) => {
        try {
            let value = e.target.checked
            let states = this.state
            states[key] = value
            this.setState({ states }, () => {
                this.setState({
                    CardAmount: '', CashAmount: '', UPIAmount: '',
                    BankTfrAmount: '', ChequeAmount: '',
                    CardTransactionNumber:'',BankTfrNumber:'',
                    ChequeNumber:'',UPINumber:''
                })
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    // Convert a File object to base64
    convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    onImageHandler = async (e) => {
        try {
            const acceptedTypes = ["application/pdf", "image/jpg", "image/jpeg", "image/png", "pdf", "image"];
            const imageFiles = [...this.state.newMultiImageList, ...e.target.files];
            const validFiles = [];

            for (const file of imageFiles) {
                if (acceptedTypes.includes(file.type) || acceptedTypes.includes(file.name.split('.').pop())) {
                    validFiles.push(file);
                } else {
                    this.errorMessage("Only PDF, JPG, JPEG, and PNG files are allowed.");
                    return;
                }
            }

            const clearedArr = validFiles?.filter((image, i) => {
                const fileName = image?.name ? image.name : image?.data_url;
                return String(fileName).toLowerCase() !== String(validFiles[i + 1]?.name ? validFiles[i + 1]?.name : validFiles[i + 1]?.data_url).toLowerCase();
            });
            // convert images to base64;
            if (clearedArr.length > 0) {
                const base64Images = await Promise.all(
                    clearedArr?.map(async (file) => {
                        if (!file?.image_title) {
                            const base64Value = await this.convertToBase64(file);
                            return {
                                data_url: base64Value,
                                name: file.name,
                                lastModified: file.lastModified,
                                size: file.size,
                                type: file.type,
                            };
                        } else {
                            return file;
                        }
                    })
                );
                this.setState({ newMultiImageList: validFiles, previewImg: base64Images });
            }
            e.target.value = '';
        } catch (error) {
            this.errorMessage(error.message);
        }
    };

    multipleImgUpload = () => {
        try {
            return (
                <Box component={'div'} height={'35dvh'} width={'27.5dvw'}
                    className="eMed_IP_ExpPop_txtbox"
                    display={(this.state.isView && this.state.newMultiImageList?.length === 0) ? "none" : "block"}>
                    {this.state.isView ?
                        <Box component={'div'} height={'23dvh'} display={'flex'} justifyContent={'center'} alignContent={'center'}>
                            <Box component={'img'} src={this.state.selectedImage ? this.state.selectedImage : null} p={'0.5vw'} sx={{ objectFit: 'contain' }} />
                        </Box>
                        :
                        <Box component={'div'} height={'23dvh'} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
                            <Box component={'input'}
                                multiple
                                type="file"
                                accept="application/pdf, image/jpg, image/jpeg, image/png"
                                ref={this.multiUploadImage}
                                hidden
                                emed_tid={"imgUpload__TestID08"}
                                onChange={(e) => { this.onImageHandler(e) }}
                            />
                            <Button
                                sx={{ textTransform: 'capitalize' }}
                                onClick={() => this.multiUploadImage.current.click()}
                                emed_tid={'image_upload_export'}
                            >
                                <Box component={'div'} height={'20dvh'} width={'24dvh'} border={'1px dotted gray'} borderRadius={'10px'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                                    <Box component={'div'}>
                                        <Box component={'img'} src={ImagePaths.Export.default} height={'2vw'} width={'2vw'} alt='upload' />
                                    </Box>
                                    <Box component={'div'}>
                                        <Typography fontSize={'0.8vw'} color={'gray'} fontWeight={600}>Click Here To Upload Files</Typography>
                                        <Typography fontSize={'0.7vw'} color={Colors.grayShade}>Supported Formats JPEG,JPG,PNG and PDF</Typography>
                                    </Box>
                                </Box>
                            </Button>
                            <FormHelperText>Total Upload Limit: 15 MB (across all files)</FormHelperText>
                        </Box>
                    }
                    <Box component={'div'} height={'15dvh'} width={'27vw'} display={'flex'} alignItems={'center'} overflow={'auto'}>
                        {this.state.newMultiImageList?.length > 0 ? this.state.newMultiImageList?.map((item, index) => {
                            let type = item?.type?.split("/")[0] || item?.type
                            return (
                                <Box component={'div'} key={index} height={'2vw'} ml={'1vw'}>
                                    {type === 'image' ?
                                        <Box component={'div'}>
                                            <Tooltip placement='top' title={item?.name} arrow>
                                                <Box component={'img'} src={item?.data_url ? item?.data_url : ImagePaths.ImagePreview.default} height={'3vw'} width={'3vw'}
                                                    sx={{ cursor: this.state.isView ? 'pointer' : '' }}
                                                    onClick={() => {
                                                        if (this.state.isView) {
                                                            this.setState({ selectedImage: item?.data_url })
                                                        }
                                                    }}
                                                />
                                            </Tooltip>
                                        </Box>
                                        : <Tooltip placement='top' title={item?.name} arrow>
                                            <Box component={'img'} src={ImagePaths.DocumentPreview.default} height={'3vw'} width={'3vw'}
                                                sx={{ cursor: this.state.isView ? 'pointer' : '' }}
                                                // onClick={() => { window.open(item.data_url) }}
                                                onClick={() => {
                                                    if (item.data_url) {
                                                        const link = document.createElement('a');
                                                        link.href = item.data_url;
                                                        link.download = item.name || 'document.pdf';
                                                        document.body.appendChild(link);
                                                        link.click(); 
                                                        document.body.removeChild(link);
                                                    }
                                                }}
                                                 />
                                        </Tooltip>}
                                    {this.state.isView ? null :
                                        <Box component={'div'} sx={{ position: 'relative', bottom: '3.5vw', left: '2.2vw', cursor: 'pointer' }}
                                            emed_tid={'image_del_btn'}
                                            onClick={() => {
                                                if (this.state.newMultiImageList[index]?.image_title) {
                                                    this.state.deletedImages?.push(this.state.newMultiImageList[index])
                                                    this.setState({ deletedImages: this.state.deletedImages }, () => {
                                                        this.state.newMultiImageList?.splice(index, 1)
                                                        this.setState({ newMultiImageList: this.state.newMultiImageList })
                                                    })
                                                } else {
                                                    this.state.newMultiImageList?.splice(index, 1)
                                                    this.setState({ newMultiImageList: this.state.newMultiImageList })
                                                }
                                            }}
                                        >
                                            <CancelTwoToneIcon fontSize='small' color="error" />
                                        </Box>
                                    }
                                </Box>
                            )
                        }) : null}
                    </Box>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderAmountTextFields = (label, key, Icon) => {
        var states = this.state
        let { t } = this.props;
        return (
            <TextField
                sx={{ padding: 0, margin: 0, width: "10vw" }}
                className='eMed_CrdPop_Amount_TextFields'
                size='small'
                // disabled={this.state.forCancel}
                label={t(label)}
                autoComplete='off'
                InputProps={{
                    readOnly: this.state.isView,
                    startAdornment: (
                        <InputAdornment position="start">
                            <img src={Icon} alt={label} className='eMed_action_img_small' />
                        </InputAdornment>
                    ),
                    emed_tid: label
                }}
                value={states[key]}
                onChange={(e) => {
                    let number = CommonValidation.NumberWithDot(e.target.value);
                    if ((number && +e.target.value > 0) || e.target.value === "") {
                        states[key] = e.target.value
                        this.setState({
                            states
                        })
                    }
                }}
            />
        )
    }

    renderNumberTextFields = (label, key, Right = false) => {
        var states = this.state
        let { t } = this.props;
        return (
            <TextField
                className='eMed_CrdPop_Amount_TextFields'
                sx={{ marginLeft: Right ? "1vw" : 0, width: "10vw" }}
                inputProps={{ maxLength: 20 }}
                // disabled={this.state.forCancel}
                size='small'
                label={t(label)}
                value={states[key]}
                autoComplete='off'
                InputProps={{ readOnly: this.state.isView }}
                onChange={(e) => {
                    let number = CommonValidation.gstnumberValidation(e.target.value);
                    if (number || e.target.value === "") {
                        states[key] = e.target.value.toUpperCase()
                        this.setState({
                            states
                        })
                    }
                }}
            />
        )
    }

    render() {
        const { t } = this.props;
        let states = this.state;
        let disablebtn = this.CheckAllFields();
        const {is_user, permission_access} = this.state.LoggedData;

        return (
            <Modal open={true}>
                <Box className="eMed_IP_ExpPop_MainDiv" 
                sx= {{height: (this.state.isView && this.state.newMultiImageList?.length === 0) ? "fit-content" : "39.8dvw"}}>
                    <Box component={'div'} className="eMed_IP_ExpPop_Header">
                        <Typography id="eMed_IP_ExpPop_TltTxt">{t("ExpenseBilling")}</Typography>
                        <IconButton size="small" onClick={this.handleClose}>
                            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                        </IconButton>
                    </Box>
                    <Box component={'div'} className="eMed_IP_ExpPop_wrapper"
                    sx= {{height: (this.state.isView && this.state.newMultiImageList?.length === 0) ? "fit-content" : "33.9dvw"}}>
                        <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
                            {this.renderDatePicker("Date", "addExp_date")}
                            {this.renderAutoSlt("ExpenseCategory", "add_category", "category_id", "name", "id", states.expCategoryList)}
                        </Stack>
                        <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
                            {this.renderTextBox("Total Amount", "add_amount", "13vw")}
                            {/* {this.renderAutoSlt("PaymentMode", "add_payment", "add_payment_mode", "label", "value", paymentList)} */}
                        </Stack>
                        <Box component={'div'} pl={'0.5vw'}>
                            <FormControlLabel control={<Checkbox checked={this.state.cash} size='small' onChange={(e) => this.onChangeCheckBox(e, 'cash')} disabled={this.state.isView} />} label="Cash" emed_tid='cash_check' />
                            <FormControlLabel control={<Checkbox checked={this.state.card} size='small' onChange={(e) => this.onChangeCheckBox(e, 'card')} disabled={this.state.isView} />} label="Card" emed_tid='card_check' />
                            <FormControlLabel control={<Checkbox checked={this.state.upi} size='small' onChange={(e) => this.onChangeCheckBox(e, 'upi')} />} disabled={this.state.isView} label="UPI" emed_tid='upi_check' />
                            <FormControlLabel control={<Checkbox checked={this.state.bank} size='small' onChange={(e) => this.onChangeCheckBox(e, 'bank')} />} disabled={this.state.isView} label="Bank TFR" emed_tid='bank_check' />
                            <FormControlLabel control={<Checkbox checked={this.state.cheque} size='small' onChange={(e) => this.onChangeCheckBox(e, 'cheque')} disabled={this.state.isView} />} label="Cheque" emed_tid='cheque_check' />
                        </Box>
                        <Box component={'div'}>
                            {
                                this.state.cash ?
                                    <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                        {this.renderAmountTextFields("CashAmount", "CashAmount", ImagePaths.CashIcon.default)}
                                    </Box> : null
                            }
                            {
                                this.state.card ?
                                    <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                        {this.renderAmountTextFields("CardAmount", "CardAmount", ImagePaths.CardIcon.default)}
                                        {this.renderNumberTextFields("CardTransactionNumber", "CardTransactionNumber", true)}
                                    </Box>
                                    : null
                            }
                            {
                                this.state.upi ?
                                    <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                        {this.renderAmountTextFields("UPIAmount", "UPIAmount", ImagePaths.UPIIcon.default)}
                                        {this.renderNumberTextFields("UPITransactionNumber", "UPINumber", true)}
                                    </Box>
                                    : null
                            }
                            {
                                this.state.bank ?
                                    <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                        {this.renderAmountTextFields("BankTransferAmount", "BankTfrAmount", ImagePaths.BankTransferIcon.default)}
                                        {this.renderNumberTextFields("BankTransactionNumber", "BankTfrNumber", true)}
                                    </Box>
                                    : null
                            }
                            {
                                this.state.cheque ?
                                    <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                        {this.renderAmountTextFields("ChequeAmount", "ChequeAmount", ImagePaths.ChequeIcon.default)}
                                        {this.renderNumberTextFields("ChequeTransactionNumber", "ChequeNumber", true)}
                                    </Box>
                                    : null
                            }
                        </Box>
                        <Stack marginY={"0.5dvw"}>
                            {this.renderTextBox("Notes", "add_notes")}
                        </Stack>

                        {/* {this.renderImgCard()} */}
                        {(this.state.isView && this.state.newMultiImageList?.length === 0) ? null : this.multipleImgUpload()}

                    </Box>
                    <Stack component={'div'} className="eMed_IP_ExpPop_btnBox" spacing={3} direction={"row"} justifyContent={"center"}
                        alignItems={"center"} sx={{ display: (this.props.fromExpenseApproval || !states.isView) ? "flex" : "none" }}>
                        <Button variant='contained' size="small" id="eMed_Config_btn"
                            sx={{ display: states.isView ? "none" : "block" }}
                            onClick={() => { this.handleClear() }}>
                            {t("Clear")}
                        </Button>
                        {
                            this.props.fromExpenseApproval ?
                                <Stack component={'div'} className="eMed_IP_ExpPop_btnBox" spacing={3} direction={"row"} justifyContent={"center"} alignItems={"center"}>
                                    <Button variant='contained' size="small" id="eMed_Config_btn" emed_tid="Expense_reject_Btn"
                                        color="error"
                                        disabled={states.disableBtn}
                                        onClick={() => { this.props.PostApprovalData("Cancelled") }}>
                                        {t("Reject")}
                                    </Button>
                                    <Button variant='contained' size="small" id="eMed_Config_btn" emed_tid="Expense_approve_Btn"
                                        disabled={states.disableBtn}
                                        onClick={() => { this.props.PostApprovalData("Approved") }}>
                                        {t("Approve")}
                                    </Button>
                                </Stack> : null
                        }

                        <Button variant='contained' size="small" id="eMed_Config_btn" emed_tid="Expense_PayExpense_Btn"
                            sx={{ marginRight: states.isView ? "1.5vw" : "0vw", display: states.isView ? "none" : "block" }}
                            disabled={(states.disableBtn || disablebtn.length > 0) ? true : false}
                            onClick={() => { this.handlePayExpense() }}>
                            {(is_user && !permission_access?.common?.add_expense_access) ?
                                t("Send Request") :
                                (states.isModify ? t("ModifyExpense") : t("PayExpense"))}
                        </Button>
                    </Stack>
                </Box>
            </Modal>
        )
    }
}
export default withTranslation()(ExpensePopUp);