import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, IconButton, Modal, Paper, Radio, RadioGroup, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Tooltip, Typography } from '@mui/material';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import { PhotoCamera } from '@material-ui/icons'
import CancelIcon from '@mui/icons-material/Cancel';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Webcam from 'react-webcam';
import './DoctorProfile.css'
import { Colors } from '../../../../Styles/Colors';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import DocScheduleConfig from './DocScheduleConfig';
import DocSessionConfig from './DocSessionConfig';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { localGetItem } from '../../../../Utility/Services/CacheProviderService';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { DateTime } from 'luxon';
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker';
import { convertDateSlashToHyphen, formatDate } from '../../../../Components/CommonFunctions/CommonFunctions';
import { DataGrid } from '@mui/x-data-grid';
import { DeletePopup } from '../../../../Components/Common Components/CommonComponents';
import Loader from '../../../../Components/Loader'


class DoctorProfile extends Component {
    constructor(props) {
        super(props)
        this.webcamRef = React.createRef();
        this.state = {
            temp_patient_img: null,
            SelectedTab: "one",
            DoctorName: "",
            DoctorRegno: "",
            DoctorSpec: "",
            DocMobile: "",
            DocEmail: "",
            profile: "",
            DocSlotType: "INTERVAL",
            isOutofOffice: false,
            SelectedSession: 'one',
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: 1,
            OFFSessions: [],
            SessionList: [],
            page: 0,
            prePage: 0,
            pageSize: 10,
            prePageSize: 10,
            HistoryList: [],
            DeletePopup: false,
            GetSchedule: false,
            OFFDescription: "",
            selectedIds: [],
            historyUpdate: false,
            SessionType: "all_sessions",
            disableBtn: false,
            isFrontCamera: true,
            facingMode: 'user',
            devices: [],
        }
    }

    componentDidMount() {
        this.GetDoctordetails()
        this.GetOFFHistory()
        this.WebSocketConnention()
        this.getConnectedDevices()
    }

    WebSocketConnention = () => {
        let userData = JSON.parse(localGetItem("loggedInUserInfo"))
        let outOfficeChannel = userData?.out_channel_name
        this.OutOfOfficeSocket = new WebSocket(`${Serviceurls.WEB_SOCKET_DOMAIN}${Serviceurls.DOC_OUTOFOFFICE_WEBSOCKET}${outOfficeChannel}/`);
    
        const socketMapping = {
          OutOfOfficeSocket: this.OutOfOfficeSocket,
        };
    
        Object.entries(socketMapping)?.forEach(([name, socket]) => {
          socket.onopen = () => {
            console.log(`${name} WebSocket connected`);
          }
          socket.onclose = () => {
            console.log(`${name} WebSocket disconnected`);
          }
          socket.onerror = (error) => {
            console.log(`${name} WebSocket connected`, error);
            socket.close()
          }
          socket.onmessage = (e) => {
            const data = JSON.parse(e.data);
            console.log(`${name} WebSocket Message`, data);
          }
        })
      }

    GetDoctordetails() {
        try {
            this.LoaderFunction(true)
            let Docdetail = JSON.parse(localGetItem("loggedInUserInfo"))
            RestAPIService.getAll(Serviceurls.DOC_DETAILS_PROFILE + '?doctor_id=' + (Docdetail?.doctor_id ? Docdetail?.doctor_id : null)).
                then((response) => {
                    if (response.data.status === "success") {
                        this.LoaderFunction(false)
                        let Response = response.data.data ? response.data.data : {}
                        this.setState({
                            DoctorName: `${Response?.title ? Response?.title : ""} ${Response?.first_name ? Response?.first_name : ""} ${Response?.middle_name ? Response?.middle_name : ""} ${Response?.initial ? Response?.initial : ""}`,
                            DoctorRegno: Response?.registration_proof_number ? Response?.registration_proof_number : "",
                            DoctorSpec: Response?.specialization_name ? Response?.specialization_name : "",
                            DocMobile: Response?.mobile_number ? Response?.mobile_number : "",
                            DocEmail: Response?.email ? Response?.email : "",
                            DocSlotType: Response?.slot_based_on ? Response?.slot_based_on : "",
                            profile: Response?.photo_url ? Response?.photo_url : "",

                        })
                    }
                    else {
                        this.LoaderFunction(false)
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.LoaderFunction(false)
            this.errorMessage(e.message)
        }
    }

    GetSessionList(FromDate, ToDate) {
        try {
            this.LoaderFunction(true)
            let Docdetail = JSON.parse(localGetItem("loggedInUserInfo"))
            let ClinicID = JSON.parse(localGetItem("SelectedDoctorClinicId")) ? JSON.parse(localGetItem("SelectedDoctorClinicId")) : Docdetail?.clinic_id
            let Default = DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd')
            RestAPIService.getAll(Serviceurls.DOC_SESSION_LIST + `?doctor_id=${Docdetail?.doctor_id ? Docdetail?.doctor_id : null}&clinic_id=${ClinicID ? ClinicID : null}&from_date=${FromDate ? FromDate : Default}&to_date=${ToDate ? ToDate : Default}`).
                then((response) => {
                    if (response.data.status === "success") {
                        let Response = response.data.data ? response.data.data : {}
                        this.setState({
                            SessionList: Response
                        }, () => {
                            this.LoaderFunction(false)
                            this.setState({ isOutofOffice: true })
                        })
                    }
                    else {
                        this.LoaderFunction(false)
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.LoaderFunction(false)
            this.errorMessage(e.message)
        }
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    DeleteOFFHistory = () => {
        try {
            this.LoaderFunction(true)
            let Docdetail = JSON.parse(localGetItem("loggedInUserInfo"))
            let ClinicID = JSON.parse(localGetItem("SelectedDoctorClinicId")) ? JSON.parse(localGetItem("SelectedDoctorClinicId")) : Docdetail?.clinic_id
            RestAPIService.delete(`${Serviceurls.DOC_SCHEDULE_OUTOFOFFICE}?id=${this.state.deleteID}&doctor_id=${Docdetail?.doctor_id ? Docdetail?.doctor_id : null}&clinic_id=${ClinicID ? ClinicID : null}`)
                .then(response => {
                    if (response.data.status === "success") {
                        this.successMessage(response.data.message);
                        this.LoaderFunction(false)
                        this.setState({
                            DeletePopup: false,
                            historyUpdate: true
                        }, () => { this.GetOFFHistory() })
                    }
                })
                .catch(error => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message);
                    }
                });
        } catch (e) {
            this.LoaderFunction(false)
            this.errorMessage(e.message, false);
        }
    }

    GetOFFHistory() {
        try {
            let Docdetail = JSON.parse(localGetItem("loggedInUserInfo"))
            let ClinicID = JSON.parse(localGetItem("SelectedDoctorClinicId")) ? JSON.parse(localGetItem("SelectedDoctorClinicId")) : Docdetail?.clinic_id
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.DOC_SCHEDULE_OUTOFOFFICE + '?doctor_id=' + (Docdetail?.doctor_id ? Docdetail?.doctor_id : null) + '&clinic_id=' + (ClinicID ? ClinicID : null)).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            HistoryList: response.data.data ? response.data.data : null
                        }, () => { this.LoaderFunction(false) })
                    }
                    else {
                        this.LoaderFunction(false)
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.LoaderFunction(false)
            this.errorMessage(e.message)
        }
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index,
            OFFSessions: [],
            SessionType: 'all_sessions'
        }, () => {
            this.GetSessionList(from, to)
        })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message,
            disableBtn: false,
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message,
            disableBtn: false,
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    PopupClose = () => {
        this.setState({
            DeletePopup: false
        })
    }

    UploadImage = (e, key) => {
        let states = this.state
        var files = e.target.files[0]
        var url = ""
        this.getBase64(files, (result) => {
            states[key] = result
            this.setState({ states }, () => {
                this.setState({
                    open: false
                })
            })
        });
    }

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    getConnectedDevices = () => {
        navigator.mediaDevices.enumerateDevices()
          .then(devices => {
            const videoDevices = devices?.filter(device => device.kind === 'videoinput');
            this.setState({
              devices: videoDevices,
            });
          })
          .catch(error => {
            console.error('Error enumerating devices:', error);
          });
      }
    
      videoConstraints = () => ({
        facingMode: this.state.isFrontCamera ? 'user' : 'environment',
      });
    
      toggleCameraButton = () => {
        this.setState({
          isFrontCamera: !this.state.isFrontCamera,
          temp_patient_img: null
        }, () => { this.videoConstraints() })
      }

    OpenWebCamPopUP = () => {
        try {
            return (
                <Box>
                    <Modal open={true}>
                        <Box className="eMed_WebCamPop_Main">
                            <Box component={'div'} className="eMed_WebCam_Header">
                                <Typography variant='h6'>{"Patient Profile "}</Typography>
                                <Button emed_tid='profile_webCamPopup_close_btn' onClick={() => { this.setState({ temp_patient_img: this.state.patient_img, webCamOpen: false }) }} >
                                    <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                                </Button>
                            </Box>
                            <Box component={'div'}>
                                {this.state.temp_patient_img === null ?
                                    <Webcam
                                        className='eMed_WebCam_Div'
                                        audio={false}
                                        mirrored={true}
                                        ref={this.webcamRef}
                                        screenshotFormat="image/jpeg"
                                        videoConstraints={this.videoConstraints()}
                                    /> :
                                    <img className='eMed_WebCam_Img_Div' src={this.state.temp_patient_img ? this.state.temp_patient_img : this.state.profile ? this.state.profile : ImagePaths.Male.default} />}
                            </Box>
                            <Box component={'div'} className="eMed_WebCam_Btn_div" width={"70%"}>
                                <Button disabled={this.state.temp_patient_img !== null} variant='contained' size='small' id="eMed_Config_btn" emed_tid='profile_webCamPopup_Capture_btn' onClick={() => { this.setState({ temp_patient_img: this.webcamRef.current.getScreenshot() }) }}>Capture</Button>
                                <Button variant='contained' size='small' id="eMed_Config_btn" emed_tid='profile_webCamPopup_Retake_btn' onClick={() => { this.setState({ temp_patient_img: null }) }}>Retake</Button>
                                <Button disabled={this.state.temp_patient_img === null} variant='contained' emed_tid='profile_webCamPopup_setProfile_btn' size='small' id="eMed_Config_btn" onClick={() => { this.setState({ profile: this.state.temp_patient_img, webCamOpen: false }) }}>Set Profile</Button>
                                {this.state.devices?.length > 1 && <Button variant='contained' size='small' id="eMed_Config_btn" sx={{width: '10.5vw'}} onClick={() => { this.toggleCameraButton() }} >
                                    Switch to {this.state.isFrontCamera ? "Back" : "Front"} Camera
                                </Button>}
                            </Box>
                        </Box>
                    </Modal>
                </Box>
            );
        } catch (error) {
            this.errorMessgae(error.message)
        }
    };


    dataURItoBlob = (dataURI) => {
        var byteString = atob(dataURI.split(",")[1]);
        var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    };

    sendDocuments(key) {
        let states = this.state;
        if (states[key] === "" || states[key] === null) {
            return ""
        }
        else {
            if (states[key]?.length > 500) {
                return this.dataURItoBlob(states[key])
            }
            else {
                return ""
            }
        }
    }

    PostDortorDetails = () => {
        try {
            var states = this.state
            let Docdetail = JSON.parse(localGetItem("loggedInUserInfo"))
            this.LoaderFunction(true)
            const dataForm = new FormData();
            dataForm.append("doctor_id", Docdetail?.doctor_id ? Docdetail?.doctor_id : "")
            dataForm.append("mobile_number", states?.DocMobile ? states?.DocMobile : "")
            dataForm.append("email", states?.DocEmail ? states?.DocEmail : "")
            dataForm.append("about_myself", "")
            dataForm.append("photo_url", (this.state.profile === null || this.state.profile === "" || this.state.profile.includes("https://s3-ap-south-1.amazonaws.com")) ? "" : this.sendDocuments("profile"))
            RestAPIService.create(dataForm, Serviceurls.DOC_DETAILS_PROFILE).
                then((response) => {
                    if (response.data.status === "success") {
                        this.LoaderFunction(false)
                        this.GetDoctordetails()
                        this.successMessage(response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(response.data.message)
                    }
                }).catch(error => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    PostOutOfOffice = () => {
        try {
            let Docdetail = JSON.parse(localGetItem("loggedInUserInfo"))
            let ClinicID = JSON.parse(localGetItem("SelectedDoctorClinicId")) ? JSON.parse(localGetItem("SelectedDoctorClinicId")) : Docdetail?.clinic_id
            var states = this.state
            let sessionData = ""
            let fromDate = states.fromDate
            let toDate = states.toDate
            if (states?.OFFSessions?.length > 0) {
                let OFFSessionsValue = JSON.parse(JSON.stringify(states?.OFFSessions))
                let sessionArray = []

                OFFSessionsValue.sort((a, b) => {
                    let dateA = new Date(convertDateSlashToHyphen(a?.date))
                    let dateB = new Date(convertDateSlashToHyphen(b?.date))
                    return dateA - dateB;
                });

                OFFSessionsValue?.forEach((item) => {
                    sessionArray.push(item?.session_name)
                })

                fromDate = OFFSessionsValue[0]?.date
                toDate = OFFSessionsValue[OFFSessionsValue.length - 1]?.date
                fromDate = fromDate ? convertDateSlashToHyphen(fromDate) : ""
                toDate = toDate ? convertDateSlashToHyphen(toDate) : ""
                sessionData = String(sessionArray)
            }
            var data = {
                "clinic_id": ClinicID,
                "doctor_id": Docdetail?.doctor_id ? Docdetail?.doctor_id : null,
                "from_date": fromDate,
                "to_date": toDate,
                "session": sessionData,
                "out_reason": states?.OFFDescription
            }
            this.LoaderFunction(true)
            RestAPIService.create(data, Serviceurls.DOC_SCHEDULE_OUTOFOFFICE).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            isOutofOffice: false,
                            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                            dateIndex: 1,
                            SelectedSession: 'one',
                            OFFDescription: "",
                            OFFSessions: [],
                            SessionType: 'all_sessions',
                        }, () => {
                            this.OutOfOfficeSocket.send(JSON.stringify({ id: response?.data?.data?.id }))
                            this.setState({ GetSchedule: true, historyUpdate: true }) 
                            })
                        this.LoaderFunction(false)
                        this.successMessage(response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(response.data.message)
                    }
                }).catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.LoaderFunction(false)
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderHistoryScreen = () => {
        try {
            const { t } = this.props
            let currenDate = DateTime.fromJSDate(new Date()).toFormat("yyyy-MM-dd")
            const columns = [
                {
                    field: 'from_date', headerName: 'From Date', flex: 0.35,
                    renderCell: (params) => (<Box>{params?.row?.from_date ? params?.row?.from_date : '-'}</Box>)
                },
                {
                    field: 'to_date', headerName: 'To Date', flex: 0.35,
                    renderCell: (params) => (<Box>{params?.row?.to_date ? params?.row?.to_date : '-'}</Box>)
                },
                {
                    field: 'out_reason', headerName: 'Reason', flex: 0.35,
                    renderCell: (params) => (
                        <Box component={'div'}>
                            {params?.row?.out_reason ? params?.row?.out_reason?.length > 12 ?
                                <Tooltip placement="top" title={params?.row?.out_reason}><Typography fontSize={'0.9vw'}>{params?.row?.out_reason?.slice(0, 12) + '...'}</Typography></Tooltip> :
                                <Typography fontSize={'0.9vw'}>{params?.row?.out_reason}</Typography> : '-'}
                        </Box>)
                },
                {
                    field: 'action', headerName: 'Action', flex: 0.25, align: "center",
                    renderCell: (params) => {
                        let disable = params?.row?.to_date?.split("-")?.reverse()?.join("-") < currenDate
                        return (<Box component={'div'}>
                            <div className='eMed_button' emed_tid="history_popUp_cancelBtn" style={{ backgroundColor: (!params?.row?.is_active || disable) ? "#bab4b4" : "#f52828" }} onClick={() => { if (params?.row?.is_active && !disable) { this.setState({ DeletePopup: true, deleteID: params?.row?.id }) } }}>
                                {params?.row?.is_active ? "Cancel" : "Cancelled"}
                            </div>
                        </Box>)
                    }
                }
            ]
            return (
                <Box component={'div'} className='eMed_Presc_History_pop_table' sx={{ width: "33vw", height: "20vw" }}>
                    <DataGrid
                        checkboxSelection
                        onSelectionModelChange={(newSelection) => {
                            const activeIds = newSelection.filter((id) => {
                                const selectedRow = this.state.HistoryList.find(row => row.id === id);
                                return selectedRow && selectedRow.is_active;
                            });
                            this.setState({ selectedIds: activeIds });
                        }}
                        isRowSelectable={(params) => params?.row?.is_active}
                        rows={this.state.HistoryList}
                        columns={columns}
                        getRowId={(row) => row['id']}
                        pageSize={this.state.prePageSize}
                        page={this.state.prePage}
                        onPageChange={(page) => this.setState({ prePage: page })}
                        onPageSizeChange={(pageSize) => this.setState({ prePageSize: pageSize })}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata' sx={{ height: "30vh" }}>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        rowsPerPageOptions={[10, 20, 30]}
                        headerHeight={40}
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                    />
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    OutofOfficePopup = () => {
        try {
            const { t } = this.props
            let states = this.state
            return (
                <Modal open={states.isOutofOffice}>
                    <Box className='eMed_New_Ip_Popup' sx={{ width: states?.SelectedSession === "one" ? "30vw" : "36vw", height: states?.SelectedSession === "one" ? (states?.SessionType === "all_sessions" ? "50vh" : "55vh") : "29vw" }}>
                        <Box component={'div'} mb={'0.5vw'} position={'sticky'}>
                            <Box display={'flex'} justifyContent={'space-between'} paddingLeft={'0.5vw'} alignItems={'center'} height={'3vw'}>
                                <Tabs
                                    value={this.state.SelectedSession}
                                    onChange={(e, newValue) => {
                                        if (newValue === "two") {
                                            this.GetOFFHistory()
                                        }
                                        this.setState({
                                            SelectedSession: newValue
                                        })
                                    }}
                                >
                                    <Tab value="one" label={t("Out Of Office")} emed_tid='outOfOffice_tab' className='eMed_tab_header' />
                                    <Tab value="two" label={t("History")} emed_tid='history_tab' className='eMed_tab_header' />
                                </Tabs>
                            </Box>
                        </Box>
                        <Box margin={"1vw"}>
                            {states?.SelectedSession === "one" ?
                                <Box>
                                    <Box>
                                        <CommonDatePicker
                                            FromDate={this.state.fromDate}
                                            ToDate={this.state.toDate}
                                            DateIndex={this.state.dateIndex}
                                            SelectedDates={this.FilterDate.bind(this)}
                                            Past={true}
                                            Future={true}
                                            OutOfOffice={true}
                                            HideAllMenu={true} />
                                    </Box>
                                    <FormControl sx={{ marginLeft: "1vw" }}>
                                        <RadioGroup
                                            value={this.state.SessionType}
                                            sx={{ display: 'flex', flexDirection: 'row' }}
                                            onChange={(e) => {
                                                this.setState({ SessionType: e.target.value, OFFSessions: [] })
                                            }}
                                        >
                                            <FormControlLabel value="all_sessions" control={<Radio size='small' inputProps={{ 'emed_tid': "outofoffice_popUp_all_sessions_radioBtn" }} />} label="All Sessions" />
                                            <FormControlLabel value="specific_sessions" control={<Radio size='small' inputProps={{ 'emed_tid': "outofoffice_popUp_specific_sessions_radioBtn" }} />} label="Specific Sessions" />
                                        </RadioGroup>
                                    </FormControl>
                                    {this.state.SessionType === "specific_sessions" ?
                                        <Autocomplete
                                            multiple
                                            disableClearable
                                            size='small'
                                            value={this.state.OFFSessions}
                                            sx={{ width: "26.7vw", marginY: "0.5vw" }}
                                            options={this.state.SessionList}
                                            getOptionLabel={(option) => (typeof (option) === 'string') ? option : option?.session_name}
                                            onChange={(event, newValue) => {
                                                this.setState({
                                                    OFFSessions: newValue
                                                })
                                            }}
                                            renderInput={(params) => (<TextField {...params} inputProps={{...params.inputProps, 'emed_tid': 'outofoffice_selectSession_inputField' }} label={t('Select Session')} />)} />
                                        : null}
                                    <hr />
                                    <TextField
                                        multiline
                                        label='Reason *'
                                        rows={3}
                                        sx={{ width: '26.7vw', marginTop: "0.5vw" }}
                                        value={this.state.OFFDescription}
                                        inputProps={{ emed_tid: "outofoffice_reason_field" }}
                                        onChange={(e) => {
                                            let value = e.target.value
                                            if (value?.length < 250) {
                                                this.setState({ OFFDescription: e.target.value })
                                            } else {
                                                this.errorMessage('Allowed only 250 characters')
                                            }
                                        }}
                                    />
                                </Box> :
                                <Box>{this.renderHistoryScreen()}</Box>
                            }
                        </Box>
                        <Box component={'div'}>
                            <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} p={'0.5vw'}>
                                <Button
                                    sx={{ height: '2vw', width: '5vw', mr: '1vw' }}
                                    variant='outlined'
                                    emed_tid='outofoffice_cancel_btn'
                                    onClick={() => {
                                        this.setState({ isOutofOffice: false }, () => {
                                            this.setState({
                                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                                dateIndex: 1,
                                                SelectedSession: 'one',
                                                OFFDescription: "",
                                                OFFSessions: [],
                                                selectedIds: [],
                                                SessionType: 'all_sessions'
                                            })
                                        })
                                    }}
                                >Cancel</Button>
                                <Button
                                    sx={{ height: '2vw', width: '5vw', mr: '1vw' }}
                                    variant='contained'
                                    emed_tid='outofoffice_sendDelete_btn'
                                    disabled={this.state.SelectedSession === "two" && this.state.selectedIds.length == 0}
                                    onClick={() => {
                                        if (this.state.SelectedSession === "one") {
                                            if (this.state.OFFDescription === "") {
                                                this.errorMessage("Enter Out Of Office Reason")
                                            } else if(this.state.SessionType === "specific_sessions" && this.state.OFFSessions?.length === 0){
                                                this.errorMessage("Select the Sessions for Out of Office")
                                            } else {
                                                this.PostOutOfOffice()
                                            }
                                        } else {
                                            this.setState({ DeletePopup: true, deleteID: this.state.selectedIds })
                                        }
                                    }}
                                >{this.state.SelectedSession === "one" ? "Send" : "Delete"}</Button>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    render() {
        const { t } = this.props
        return (
            <Box>
                <Box className='eMed_OP_AptHead' sx={{ alignItems: "center", justifyContent: 'flex-start' }}>
                    <Tooltip title="Back" placement='top' arrow>
                        <Button emed_tid="back_credit_bills" className='eMed_Filter_Btns' onClick={() => { window.history.back() }} startIcon={<ChevronLeftIcon />}>Back</Button>
                    </Tooltip>
                    <Typography sx={{ marginLeft: "1vw", fontWeight: 600 }}>{t('Doctor Profile')}</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <Paper elevation={3} sx={{ flex: 0.2, marginY: "0.5vw", marginRight: "0.5vw", height: "77vh", display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: "center" }}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '1vw' }}>
                                <img src={this.state.profile ? this.state.profile : ImagePaths.Male.default} alt='profile'
                                    className={this.state.profile ? 'eMed_new_img_res_ui' : 'eMed_new_profile_ui'} />
                                {this.state.open ? null :
                                    <img
                                        src={ImagePaths.AddUserButton.default}
                                        alt='add'
                                        className='eMed_Useradd_btn_ui'
                                        onClick={() => this.setState({ open: true })}
                                    />}

                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                {this.state.open ?
                                    <Box component={'div'}>
                                        <IconButton color="primary" onClick={() => this.setState({ webCamOpen: true },()=>{this.getConnectedDevices()})}>
                                            <PhotoCamera />
                                        </IconButton>
                                        <IconButton color="primary" aria-label="upload picture" component="label">
                                            <input hidden accept="image/*" type="file" onChange={(e) => { this.UploadImage(e, "profile") }} />
                                            <UploadFileIcon />
                                        </IconButton>
                                        <IconButton color="primary"
                                            onClick={() => this.setState({ open: false })}>
                                            <CancelIcon />
                                        </IconButton>
                                    </Box> : null}
                            </Box>
                            <Box margin={'1.2vw 0.5vw 0.5vw 0.5vw'}>
                                <TextField
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    disabled
                                    size='small'
                                    variant='standard'
                                    value={this.state.DoctorName ? this.state.DoctorName : ""}
                                    label={"Doctor Name"}
                                    placeholder='Name'
                                    sx={{ width: "17vw", fontSize: '0.8vw' }}
                                />
                            </Box>
                            <Box margin={'1.2vw 0.5vw 0.5vw 0.5vw'}>
                                <TextField
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    disabled
                                    size='small'
                                    variant='standard'
                                    value={this.state.DoctorRegno ? this.state.DoctorRegno : ""}
                                    label={"Reg No"}
                                    sx={{ width: "17vw", fontSize: '0.8vw' }}
                                />
                            </Box>
                            <Box margin={'1.2vw 0.5vw 0.5vw 0.5vw'}>
                                <TextField
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    disabled
                                    size='small'
                                    variant='standard'
                                    value={this.state.DoctorSpec ? this.state.DoctorSpec : ""}
                                    label={"Specilazation"}
                                    sx={{ width: "17vw", fontSize: '0.8vw' }}
                                />
                            </Box>
                            <Box margin={'1.2vw 0.5vw 0.5vw 0.5vw'}>
                                <TextField
                                    size='small'
                                    variant='standard'
                                    value={this.state.DocMobile ? this.state.DocMobile : ""}
                                    inputProps={{ maxLength: 10 }}
                                    label={"Mobile"}
                                    sx={{ width: "17vw", fontSize: '0.8vw' }}
                                    onChange={(e) => {
                                        let numOnly = CommonValidation.numberOnly(e.target.value);
                                        if (numOnly || e.target.value === '') {
                                            this.setState({
                                                DocMobile: e.target.value
                                            })
                                        }
                                    }}
                                />
                            </Box>
                            <Box margin={'1.2vw 0.5vw 0.5vw 0.5vw'}>
                                <TextField
                                    size='small'
                                    inputProps={{ maxLength: 30 }}
                                    variant='standard'
                                    value={this.state.DocEmail ? this.state.DocEmail : ""}
                                    label={"E Mail"}
                                    sx={{ width: "17vw", fontSize: '0.8vw' }}
                                    onChange={(e) => {
                                        let numOnly = CommonValidation.PasswordInput(e.target.value);
                                        if (numOnly || e.target.value === '') {
                                            this.setState({
                                                DocEmail: e.target.value
                                            })
                                        }
                                    }} />
                            </Box>
                            <Button size='small' variant='contained' disabled={this.state.disableBtn} onClick={() => {
                                let Email = this.state.DocEmail !== "" ? CommonValidation.emailValidation(this.state.DocEmail) : true;
                                if ((this.state.DocMobile?.length === 10 || this.state.DocMobile === "") && Email) {
                                    this.setState({ disableBtn: true }, () => this.PostDortorDetails() )
                                } else {
                                    if (this.state.DocMobile?.length !== 10) {
                                        this.errorMessage("Invalid Mobile Number")
                                    } else {
                                        this.errorMessage("Invalid Email")
                                    }
                                }
                            }}>Save</Button>
                        </Box>

                    </Paper>
                    <Paper elevation={3} sx={{ flex: 0.8, marginY: "0.5vw", height: "77vh" }}>
                        <Typography component={'div'} sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                            <Tabs
                                value={this.state.SelectedTab}
                                onChange={(e, newValue) => this.setState({
                                    SelectedTab: newValue
                                })}
                            >
                                <Tab value="one" label={t("Schedule")} className='eMed_tab_header' />
                                <Tab value="two" label={t("Session")} className='eMed_tab_header' />
                            </Tabs>
                            {this.state?.SelectedTab === "one" ? <Button size='small' sx={{ marginRight: "1vw" }} onClick={() => { this.GetSessionList() }} variant='contained'>Out Of Office</Button> : null}
                        </Typography>
                        {this.state.SelectedTab === "one" ? <DocScheduleConfig DocScheduleGet={this.state.GetSchedule} renderScreenAgain={this.state.historyUpdate} rendered={() => this.setState({ historyUpdate: false })} /> : <DocSessionConfig DocSlotType={this.state.DocSlotType} />}
                    </Paper>
                </Box>
                {this.state.webCamOpen ? this.OpenWebCamPopUP() : null}
                {this.OutofOfficePopup()}
                {this.state.DeletePopup ? <DeletePopup
                    DeletTitle={`Are you sure you want to cancel?`}
                    deleteAlertPopupClose={this.PopupClose.bind(this)}
                    removeData={this.DeleteOFFHistory.bind(this)}
                    ButtonText={"Confirm"}
                /> : null}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                <Loader loaderOpen={this.state.isLoader} />
            </Box>
        )
    }
}

export default withTranslation()(DoctorProfile);