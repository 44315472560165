import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import {
    Accordion, AccordionSummary, IconButton, Typography, Autocomplete,
    TextField, Box, InputAdornment, AccordionDetails, TableContainer,
    Table, TableRow, TableCell, TableHead, TableBody,
    Modal,
    Button,
    Tooltip
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Serviceurls } from "../../../../Utility/API/Serviceurls";
import RestAPIService from "../../../../Utility/Services/RestAPIService";
import { localGetItem } from "../../../../Utility/Services/CacheProviderService";
import { CurrencySymbol } from "../../../../Utility/Constants";
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';
import { ImagePaths } from "../../../../Utility/ImagePaths";
import { Colors } from "../../../../Styles/Colors";
import { AmountFormat } from "../../../../Components/CommonFunctions/CommonFunctions";

const lineItem = {
    test_name: "",
    amount: ""
}

class MultiLabBilling extends Component {
    constructor(props) {
        super(props);
        this.testNameRef = React.createRef();
        this.state = {
            openAccordion: false,
            labDetails: null,
            labList: [],
            testConfigData: [],
            testTempDataList: [],
            testGroupDataList: [],
            testPackageDataList: [],
            testTemplate: null,
            netAmount: 0.00,
            roundOff: 0.00,
            discountPercentage: 0,
            discountAmount: 0.00,
            totalBillAmount: 0,
            billSummaryList: [lineItem],
            errorMessages: [""],
            showCode: false,
            srchTestSrvList: [],
            discountReason: "",
            discountPercentage_w_r: 0
        }
    }

    componentDidMount = () => {
        const logInInfo = JSON.parse(localGetItem("loggedInUserInfo") || "{}");
        const show_code = JSON.parse(localGetItem('lab_test_code'));
        const lab_list = logInInfo?.laboratory_data || [];

        this.setState((prevState) => ({
            ...prevState,
            labList: lab_list,
            labDetails: (lab_list?.length > 0 && lab_list[0]) ? lab_list[0] : null,
            showCode: show_code ? show_code : logInInfo?.lab_test_code,
        }), () => {
            const lab_id = (lab_list?.length > 0 && lab_list[0]) ? lab_list[0]?.laboratory_id : null;
            if (lab_id) {
                this.getTestData(lab_id);
                this.getTestGroupData(lab_id);
                this.getPackageData(lab_id);
                this.getTemplateData(lab_id);
            }
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState !== this.state) {
            this.props.onStateChange(this.state);
        }
        if (prevProps.triggerSignal !== this.props.triggerSignal) {
            this.setState({
                openAccordion: false
            }, () => {
                this.onClearHandler();
            })
        }
        if (prevProps.LabTransmittedItems !== this.props.LabTransmittedItems && this.props?.LabTransmittedItems?.length) {
            let BillItems = []
            this.props.LabTransmittedItems?.forEach((item) => {
                if (item?.set_id) {
                    BillItems.push({
                        "lab_set_id": item?.set_id,
                        "amount": item?.amount,
                        "test_name": item?.set_name,
                        "transmit_summary_id": item?.transmit_summary_id,
                    })
                } else if (item?.package_id) {
                    BillItems.push({
                        "package_id": item?.package_id,
                        "amount": item?.amount,
                        "test_name": item?.package_name,
                        "transmit_summary_id": item?.transmit_summary_id,
                    })
                } else if (item?.test_id) {
                    BillItems.push({
                        "lab_test_id": item?.test_id,
                        "amount": item?.amount,
                        "test_name": item?.test_name,
                        "transmit_summary_id": item?.transmit_summary_id,
                    })
                }
            })

            BillItems.push(lineItem)
            setTimeout(() => {
                this.setState({
                    openAccordion: true,
                    billSummaryList: BillItems,
                    labDetails: this.state.labList?.find((lab) => lab?.laboratory_id === this.props?.TransmittedLabId)
                }, () => {
                    this.calculateTtlAmount();
                })
            }, 1000)
        }
    }

    getTestData = (id) => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_TEST_CONFIG_GET + '?laboratory_id=' + id)
                .then(response => {
                    if (response.data.status === "success") {
                        const testData = response?.data?.data || [];
                        this.setState((prevState) => {
                            const list = testData?.map((item) => ({
                                ...item,
                                listOf: "testData",
                                display_name: `${(prevState?.showCode && item?.test_code) ? `${item.test_code} / ` : ''}${item.test_name} - [${item.grp_name}] - ${CurrencySymbol}${item.test_amt || 0}`
                            }))
                            return {
                                ...prevState,
                                testConfigData: [...list],
                                srchTestSrvList: [...prevState.srchTestSrvList, ...list]
                            }
                        })
                    }
                }).catch(e => {
                    this.props.errorMessage(e.message);
                })

        } catch (e) {
            this.props.errorMessage(e.message);
        }
    }

    getTemplateData = (id) => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_TEMPLATE_CONFIGURATION + "?for_billing=true" + '&laboratory_id=' + id)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            testTempDataList: response?.data?.data || []
                        })
                    }
                }).catch(e => {
                    this.props.errorMessage(e.message);
                })

        } catch (e) {
            this.props.errorMessage(e.message);
        }
    }

    getTestGroupData = (id) => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_TEST_GROUP_GET + '?laboratory_id=' + id)
                .then(response => {
                    if (response.data.status === "success") {
                        const grpData = response?.data?.data || [];
                        this.setState((prevState) => {
                            const list = grpData?.map((item) => ({
                                ...item,
                                listOf: "groupData",
                                display_name: `${(prevState?.showCode && item?.grp_test_code) ? `${item?.grp_test_code} / ` : ''} ${item?.name}`
                            }))
                            return {
                                ...prevState,
                                testGroupDataList: [...list],
                                srchTestSrvList: [...prevState?.srchTestSrvList, ...list]
                            }
                        })
                    }
                }).catch(e => {
                    this.props.errorMessage(e.message);
                })

        } catch (e) {
            this.props.errorMessage(e.message);
        }
    }

    getPackageData = (id) => {
        try {
            RestAPIService.getAll(Serviceurls.NEW_LAB_PACKAGE_CONFIGURATION + '?laboratory_id=' + id)
                .then(response => {
                    if (response.data.status === "success") {
                        const pckData = response?.data?.data || [];
                        this.setState((prevState) => {
                            const list = pckData?.map((item) => ({
                                ...item,
                                listOf: "packageData",
                                display_name: `${(prevState?.showCode && item?.package_test_code) ? `${item?.package_test_code} / ` : ''}${item?.package_name}`
                            }))
                            return {
                                ...prevState,
                                testPackageDataList: [...list],
                                srchTestSrvList: [...prevState?.srchTestSrvList, ...list]
                            }
                        })
                    }
                }).catch(e => {
                    this.props.errorMessage(e.message);
                })

        } catch (e) {
            this.props.errorMessage(e.message);
        }
    }

    onClearHandler = () => {
        this.setState({
            testTemplate: null,
            netAmount: 0.00,
            roundOff: 0.00,
            discountPercentage: 0,
            discountAmount: 0.00,
            totalBillAmount: 0,
            billSummaryList: [lineItem],
            errorMessages: [""],
            showCode: false,
            discountReason: "",
            discountPercentage_w_r: 0
        })
    }

    roundOffCalculation = () => {
        const { discountAmount } = this.state;
        const roundOffAmount = discountAmount % 1;
        const roundOff = roundOffAmount >= 0.5
            ? (1 - roundOffAmount).toFixed(2)
            : (-roundOffAmount).toFixed(2);
        this.setState({ roundOff });
    };

    payableAmountCalculation = () => {
        const { discountAmount, netAmount, totalBillAmount } = this.state;

        const totalPayableAmount = discountAmount && netAmount > 0
            ? netAmount - parseInt(discountAmount)
            : +discountAmount === 0
                ? netAmount
                : totalBillAmount;

        this.setState({
            totalBillAmount: Math.round(totalPayableAmount),
        });
    };

    calculateDiscountAmount = (key) => {
        const { discountAmount, discountPercentage, netAmount } = this.state;

        if (((discountPercentage > 0 && netAmount > 0) || discountPercentage == 0) && key == "discountPercentage") {
            var amount = (netAmount * (+discountPercentage)) / 100
            this.setState({
                discountAmount: +(amount).toFixed(2)
            }, () => {
                this.roundOffCalculation()
                this.payableAmountCalculation()
            })
        } else if ((netAmount > 0 || discountAmount == 0) && key == "discountAmount") {
            var percentage = discountAmount ? (+(discountAmount) * 100) / netAmount : 0
            this.setState({
                discountPercentage: percentage ? +(percentage) : 0,
                discountPercentage_w_r: percentage ? +(percentage).toFixed(2) : 0,
            }, () => {
                this.roundOffCalculation()
                this.payableAmountCalculation()
            })
        }
    }

    onChangeDiscount = (event) => {
        let { name, value } = event.target;
        const isNum = CommonValidation.numberOnly(value)
        value = isNum ? +value : value;
        if (name === "discountPercentage_w_r" && ((value >= 0 && value <= 100 && isNum) || value === "")) {
            this.setState({
                discountPercentage: value % 1 === 0 ? value : value ? value : "",
                discountPercentage_w_r: value % 1 === 0 ? value : value.toFixed(2) ? value.toFixed(2) : "",
                cashCollected: 0,
                onlinePaidValue: 0
            }, () => {
                this.calculateDiscountAmount("discountPercentage")
            })
        } else if (name === "discountAmount" && value >= 0 && ((isNum && value <= this.state.netAmount) || value === "")) {
            this.setState({
                discountAmount: value % 1 === 0 ? value : value.toFixed(2) ? value.toFixed(2) : "",
                cashCollected: 0,
                onlinePaidValue: 0
            }, () => {
                this.calculateDiscountAmount("discountAmount")
            })

        }
    }

    calculateTtlAmount = (fromDlt = false) => {
        this.setState((prevState) => {
            const totalBillAmount = prevState?.billSummaryList?.length > 0 &&
                prevState?.billSummaryList?.reduce((prev, curr) => (prev + (+curr?.amount || 0)), 0) || 0;
            return {
                netAmount: totalBillAmount || 0,
                totalBillAmount: totalBillAmount || 0,
            }
        }, () => {
            this.payableAmountCalculation()
            if (fromDlt) {
                this.calculateDiscountAmount()
                this.roundOffCalculation()
            } else {
                this.calculateDiscountAmount("discountAmount")
            }
        })
    }

    setTemplateDataToTbl = (data) => {
        const { t, errorMessage } = this.props;
        this.setState((prevState) => {
            const list = prevState.billSummaryList.filter(item => item.test_name !== "");
            if (this.validCheck(data)) {
                errorMessage(t(`Test Already Added`));
                return {
                    ...prevState,
                };
            }
            data?.test_details?.forEach((item) => {
                if (item.grp_id) {
                    list.push({
                        "test_name": item.grp_name,
                        "lab_set_id": item.grp_id,
                        "amount": item.grp_amt ? item.grp_amt : 0
                    })
                } else {
                    list.push({
                        "test_name": item.test_name,
                        "lab_test_id": item.test_id,
                        "amount": item.test_amt ? item.test_amt : 0
                    })
                }
            })
            let discount_amt = data?.discount_amt ? +data?.discount_amt : 0;
            discount_amt += (+prevState.discountAmount);
            return {
                ...prevState,
                discountAmount: discount_amt,
                billSummaryList: [...list, lineItem],
                testTemplate: null,
            }
        }, () => {
            this.calculateTtlAmount();
        })
    }

    renderAutoComplete = (label, statekey, list, showValue) => {
        const { t } = this.props;
        return (
            <Autocomplete
                disableClearable
                openOnFocus
                value={this.state[statekey] ? this.state[statekey] : null}
                sx={{ width: "16dvw" }}
                size="small"
                disabled={this.props?.PatientDetails === null}
                options={list?.length > 0 ? list : []}
                getOptionLabel={option => typeof (option) === "string" ? option : option[showValue]}
                onChange={(event, newValue) => {
                    this.setState({
                        [statekey]: newValue,
                    }, () => {
                        switch (statekey) {
                            case "labDetails":
                                if (newValue?.laboratory_id) {
                                    this.setState({
                                        srchTestSrvList: [],
                                        billSummaryList: [lineItem],
                                        errorMessages: [""],
                                        netAmount: 0.00,
                                        roundOff: 0.00,
                                        discountPercentage: 0,
                                        discountAmount: 0.00,
                                        totalBillAmount: 0,
                                    })
                                    this.getTestData(newValue?.laboratory_id);
                                    this.getTestGroupData(newValue?.laboratory_id);
                                    this.getPackageData(newValue?.laboratory_id);
                                    this.getTemplateData(newValue?.laboratory_id);
                                }
                                break;
                            case "testTemplate":
                                this.setTemplateDataToTbl(newValue);
                                break;
                            default:
                                break;
                        }

                    })
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t(label)}
                        autoComplete="off"
                        size="small"
                        InputProps={{
                            ...params.InputProps,
                            emed_tid: `multiBilling_${label}`
                        }}
                    />
                )}
            />
        )
    }

    renderInput = (statekey, placeholder) => {
        const { billSummaryList } = this.state;
        return (
            <TextField
                sx={{ width: "6dvw" }}
                name={statekey}
                size="small"
                value={this.state[statekey] ? this.state[statekey] : ""}
                InputProps={{
                    endAdornment: <InputAdornment position="end">{placeholder}</InputAdornment>
                }}
                disabled={(billSummaryList?.length > 0 && billSummaryList[0]?.test_name === "") || this.state.netAmount === 0}
                onChange={this.onChangeDiscount}
                onBlur={this.concessionManditoryPopup}
                type={"text"}
            />
        )
    }

    resetBillSummary = () => {
        this.setState({
            billSummaryList: [lineItem],
            errorMessages: [""],
            totalBillAmount: 0,
            netAmount: 0,
            roundOff: 0,
            discountAmount: 0,
            discountPercentage: 0,
            netAmount: 0,
        })
    }

    validCheck = (data) => {
        const { billSummaryList } = this.state;
        let duplicate = false;
        if (billSummaryList?.length > 0) {
            for (let i = 0; i < billSummaryList.length; i++) {
                if (billSummaryList[i].lab_test_id && billSummaryList[i].lab_test_id == data.id) {
                    duplicate = true
                }
                if (billSummaryList[i].lab_set_id && billSummaryList[i].lab_set_id == data.id) {
                    duplicate = true
                }
                if (billSummaryList[i].package_id && billSummaryList[i].package_id == data.package_id) {
                    duplicate = true
                }
                if (data.template_id) {
                    data.test_details?.map((item) => {
                        if (!item.grp_id && billSummaryList[i].lab_test_id == item.test_id) {
                            duplicate = true
                        } else if (!item.test_id && billSummaryList[i].lab_set_id == item.grp_id) {
                            duplicate = true
                        }
                    })
                }
            }
        }
        return duplicate;
    }

    handleChangeSrchTest = (index, value) => {
        const { t } = this.props;
        this.setState((prevState) => {
            let list = [...prevState.billSummaryList];
            const errors = [...prevState.errorMessages];
            if (this.validCheck(value)) {
                errors[index] = t(`${value.listOf === 'testData' ?
                    "Test" : value.listOf === 'groupData' ?
                        "Group" : "Package"} Already Added`);
                return {
                    ...prevState,
                    errorMessages: errors
                };
            }
            errors[index] = "";
            switch (value?.listOf) {
                case "testData":
                    list[index] = {
                        "test_name": value?.name ? value?.name : value?.test_name,
                        "lab_test_id": value?.id,
                        "amount": value?.test_amt ? value?.test_amt : 0,
                        "grp_name": value?.grp_name ? value?.grp_name : null
                    }
                    break;
                case "groupData":
                    list[index] = {
                        "test_name": value?.name,
                        "lab_set_id": value?.id,
                        "amount": value?.amount ? value?.amount : 0
                    }
                    break;
                case "packageData":
                    list[index] = {
                        "test_name": value?.package_name,
                        "package_id": value?.package_id,
                        "amount": value?.package_amt ? value?.package_amt : 0
                    }
                    break;
                default:
                    break;
            }

            if (index === list?.length - 1) {
                list = [...list, lineItem]
            }
            return {
                ...prevState,
                billSummaryList: list,
                errorMessages: errors
            }
        }, () => {
            this.calculateTtlAmount();
        })
    }



    renderSearchTestInput = (index, item) => {
        const { t } = this.props;
        const { errorMessages, srchTestSrvList } = this.state;
        return (
            <Autocomplete
                disableClearable
                value={item?.test_name ? item?.test_name : null}
                sx={{ width: "90%" }}
                size="small"
                options={srchTestSrvList?.length > 0 ? srchTestSrvList : []}
                getOptionLabel={option => typeof (option) === "string" ? option : option?.display_name}
                onChange={(event, newValue) => {
                    this.handleChangeSrchTest(index, newValue)
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={(index === this.state.billSummaryList?.length - 1) ? t("Search Test, Group, Packages") : ""}
                        autoComplete="off"
                        size="small"
                        InputProps={{
                            ...params.InputProps,
                            emed_tid: `multiBilling_tstSrch`
                        }}
                        error={!!errorMessages[index]}
                        helperText={errorMessages[index] || ""}
                        variant="standard"
                    />
                )}
                renderOption={(props, item) => (
                    <Box {...props}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignContent: "flex-start" }}>
                            <Typography sx={{ fontSize: "0.9vw" }}>{item?.display_name}</Typography>
                            <Typography sx={{ fontSize: "0.8vw", fontWeight: 600, color: item?.listOf === "packageData" ? "#1E972A" : item?.listOf === "groupData" ? "#29B6F6" : item?.listOf === "testData" ? "#F1584A" : "black" }}>{`${item?.listOf === "packageData" ? "Package" : item?.listOf === "groupData" ? "Group" : item?.listOf === "testData" ? "Test" : ""}`}</Typography>
                        </Box>
                    </Box>
                )}
            />
        )
    }

    discountReasonPopup = () => {
        try {
            return (
                <Modal open={this.state.discountReasonPopup}>
                    <Box className='eMed_Discount_Reason'>
                        <Box component={'div'} height={'2vw'} display={'flex'} m={'1vw'} justifyContent={'space-between'}>
                            <Typography color={Colors.SecondaryText} fontWeight={600}>Laboratory Bill Concession Reason</Typography>
                        </Box>
                        <Box m={'1vw'}>
                            <TextField
                                fullWidth
                                multiline
                                label='Reason *'
                                rows={3}
                                value={this.state.discountReason}
                                onChange={(e) => {
                                    this.setState({ discountReason: e.target.value })
                                }}
                            />
                        </Box>
                        <Box m={'1vw'} display={'flex'} justifyContent={'flex-end'}>
                            <Button
                                variant='outlined'
                                sx={{ width: '5vw', height: '2vw', mr: '1vw' }}
                                onClick={() => {
                                    this.setState({ discountReasonPopup: false })
                                    if (!this.state.isSavedReason) {
                                        this.setState({ discountReason: '' })
                                    }
                                }}
                            >Close</Button>
                            <Button
                                variant='contained'
                                sx={{ width: '5vw', height: '2vw' }}
                                onClick={() => {
                                    if (this.state.discountReason !== '') {
                                        this.setState({ discountReasonPopup: false, isSavedReason: true })
                                    } else {
                                        this.props.errorMessage("Reason is mandatory", 'error')
                                    }
                                }}
                            >Save</Button>
                        </Box>
                    </Box>
                </Modal>
            )
        } catch (error) {
            this.props.errorMessage(error.message, 'error')
        }
    }

    concessionManditoryPopup = () => {
        if ((this.state.discountAmount != "0.00" || this.state.discountPercentage) && (this.state.discountReason === "")) {
            this.setState({ discountReasonPopup: true })
        }
    }

    render() {
        console.log(this.state);

        const { t } = this.props;
        const { openAccordion, labList, testTempDataList, totalBillAmount } = this.state;
        return (
            <Accordion expanded={openAccordion} sx={{ border: '1px solid #F5F8F7', marginX: "0.3vw" }}>
                <AccordionSummary sx={{
                    flexDirection: 'row-reverse',
                    '& .MuiAccordionSummary-content': {
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }
                }}
                    expandIcon={
                        <IconButton color='primary'
                            onClick={() => {
                                if (this.props?.PatientDetails === null) {
                                    this.props?.errorMessage("Select the patient for billing.", 'error')
                                } else {
                                    this.setState((prevState) => ({ openAccordion: !prevState.openAccordion }))
                                }
                            }}
                            emed_tid="emedhub_expandBtn_testID">
                            <ExpandMoreIcon />
                        </IconButton>}
                >
                    <Typography fontWeight={"bold"} fontSize={"1dvw"} color={"#616161"}>Laboratory</Typography>
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"} columnGap={"10px"}>
                        {this.renderAutoComplete('selectedLab', "labDetails", labList, "laboratory_name")}
                        {this.renderAutoComplete('Select Template', "testTemplate", testTempDataList, "template_name")}
                        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} columnGap={"5px"}>
                            <Typography fontWeight={"bold"} fontSize={"1dvw"} color={"#616161"}>Concession:</Typography>
                            {this.renderInput("discountPercentage_w_r", "%")}
                            {this.renderInput("discountAmount", CurrencySymbol)}
                        </Box>
                        {this.state.discountPercentage || this.state.discountAmount != "0.00" ?
                            <Tooltip placement='top' title={this.state.discountReason ? this.state.discountReason : ""}>
                                <Box onClick={() => this.setState({ discountReasonPopup: true, discountReason: this.state.discountReason ? this.state.discountReason : "" })} component={'img'} src={ImagePaths.LabReportOff.default} width={'1.5vw'} height={'1.5vw'} sx={{ cursor: 'pointer' }} alignSelf={'center'} />
                            </Tooltip> :
                            <Box component={'img'} src={ImagePaths.BillTransUser.default} width={'1vw'} height={'1vw'} alignSelf={'center'} />
                        }
                        <Typography fontWeight={"bold"} fontSize={"1dvw"} color={"#616161"} sx={{ width: "9vw", textAlign: 'end' }}>{AmountFormat(totalBillAmount ? totalBillAmount : 0)}</Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <TableContainer sx={{ borderRadius: "0.3dvw" }}>
                        <Table stickyHeader size="small">
                            <TableHead id='Lab_Home_Table_Header' sx={{ height: "4dvh" }} >
                                <TableRow key={"multi_bill_labtbl_header"}>
                                    <TableCell sx={{ flex: 0.7 }} id="lab_home_table_Head" align="left" >
                                        <IconButton size='small' sx={{ marginRight: "0.625dvw" }}>
                                            {<img src={ImagePaths.Delete.default} className='Lab_Home_DeleteIcon' alt='Delete'
                                                onClick={() => {
                                                    this.setState({
                                                        billSummaryList: [{
                                                            test_name: "",
                                                            amount: ""
                                                        }],
                                                        netAmount: 0,
                                                        totalBillAmount: 0,
                                                        discountAmount: 0,
                                                        discountPercentage: 0
                                                    })
                                                }} />
                                            }
                                        </IconButton>
                                        {t("Service Description")}
                                    </TableCell>
                                    <TableCell sx={{ flex: 0.3 }} id="lab_home_table_Head" align="right" >{t("GrossValue")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="emed_multilab_tblbody">
                                {this.state.billSummaryList?.length > 0 ?
                                    this.state.billSummaryList?.map((item, index) => {
                                        return (
                                            <TableRow>
                                                <TableCell sx={{ flex: 0.7, display: "flex", columnGap: "10px" }} align="left">
                                                    <IconButton size='small' sx={{ visibility: (item?.lab_test_id || item?.lab_set_id || item?.package_id) ? "visible" : "hidden" }}>
                                                        {<img src={ImagePaths.Delete.default} className='Lab_Home_Delete' alt='Delete'
                                                            onClick={() => {
                                                                const { billSummaryList } = this.state
                                                                if (billSummaryList.length === 1) {
                                                                    this.resetBillSummary()
                                                                } else if (index === billSummaryList.length - 1) {
                                                                    this.setState((prevState) => ({
                                                                        billSummaryList: [...prevState.billSummaryList, lineItem],
                                                                        errorMessages: [...prevState.errorMessages, ""],
                                                                    }), () => {
                                                                        this.calculateTtlAmount(true);
                                                                    })
                                                                } else {
                                                                    billSummaryList.splice(index, 1)
                                                                    this.setState({
                                                                        billSummaryList
                                                                    }, () => {
                                                                        this.calculateTtlAmount(true);
                                                                    })
                                                                }
                                                            }} />
                                                        }
                                                    </IconButton>
                                                    {this.renderSearchTestInput(index, item)}
                                                </TableCell>
                                                <TableCell sx={{ flex: 0.3 }} align="right">{AmountFormat(item.amount || 0)}</TableCell>
                                            </TableRow>)
                                    }) :
                                    <TableRow className="Lab_Home_No_record">
                                        <TableCell sx={{ flex: 0.7 }} align="center">{t("NoRecordsFound")}</TableCell>
                                        <TableCell sx={{ flex: 0.3 }} align="right">{""}</TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </AccordionDetails>
                {this.discountReasonPopup()}
            </Accordion>
        )
    }
}
export default withTranslation()(MultiLabBilling);