import { Box, Button, Chip, Stack, TextField, Typography } from '@mui/material';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import '../../../FrontOffice/IPPatients/PatientsDetails/ClinicalNotes/DoctorNotes.css'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { Colors } from '../../../../Styles/Colors';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';

class DocClinicalHistoryRight extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ClinicalHistoryText: "",
            TemplateName: "",
            TemplateAdvice: "",
            AddTemplate: false,
        }
    }

    componentDidMount() {
        if (this.props.EditClinicalHistText) {
            this.setState({
                ClinicalHistoryText: this.props.EditClinicalHistText,
            })
            this.props.ClearPropsData()
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.EditClinicalHistText !== this.props.EditClinicalHistText && this.props.EditClinicalHistText) {
            this.setState({
                ClinicalHistoryText: this.props.EditClinicalHistText,
            })
            this.props.ClearPropsData()
        }
    }

    ClearData() {
        this.setState({
            ClinicalHistoryText: "",
            TemplateName: "",
            TemplateAdvice: "",
            AddTemplate: false,
        })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    PostClinicalHistory() {
        try {
            var states = this.state
            // if (CommonValidation.removeSpace(states.ClinicalHistoryText) !== "") {
            if ((states.ClinicalHistoryText) !== "") {
                let data = {
                    "history": this.state.ClinicalHistoryText ? CommonValidation.removeSpace(this.state.ClinicalHistoryText) : "",
                    "appointment_id": this.props?.PatientData?.appointment_id,
                    "patient_id": this.props?.PatientData?.patient_id,
                    "doctor_id": this.props?.PatientData?.doctor_id,
                }

                RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_CLINICAL_HISTORY).
                    then((response) => {
                        if (response.data.status === "success") {
                            this.successMessage(response.data.message)
                            this.ClearData()
                            this.props.CallgetApi('clinical_history')
                        } else {
                            this.errorMessage(response.data.message)
                        }
                    }).catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                this.errorMessage("Enter Provisional Diagnosis")
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }
    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }


    render() {
        const { t } = this.props
        return (
            <Box>
                <Box height={"61.3vh"}>
                    <Typography className='eMed_refdoc_title'>{t("Clinical History")}</Typography>
                    <TextField
                        size='small'
                        className='eMed_DocNotes_TextFields'
                        multiline={true}
                        rows={12}
                        label={t("Clinical History")}
                        inputProps={{ maxLength: 500 }}
                        value={this.state.ClinicalHistoryText}
                        onChange={(e) => {
                            // if (CommonValidation.ALPHA_NUM_SPLCHAR(e.target.value) || e.target.value === "") {
                            this.setState({ ClinicalHistoryText: e.target.value })
                            // }
                        }}
                        helperText={`( ${500 - (this.state.ClinicalHistoryText?.length || 0)} / 500 )`}
                    />
                </Box>
                <Stack spacing={2} direction="row" sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center" height={"6vh"}>
                    <Button variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.ClearData() }}>{t("Clear")}</Button>
                    <Button variant='contained' size="small" disabled={CommonValidation.removeSpace(this.state.ClinicalHistoryText) === ""} id="eMed_Config_btn" onClick={() => { this.PostClinicalHistory() }}>{t("Add")}</Button>
                </Stack>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
export default withTranslation()(DocClinicalHistoryRight)
