import { Box, Chip, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import LineChartView from '../../../ChartDashboard/CommonCharts/LineChartView'
import TriggerApiOnView from './TriggerApiOnView';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { formatDate } from '../../../../Components/CommonFunctions/CommonFunctions';
import { getCachevalue } from '../../../../Utility/Services/CacheProviderService';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import { Line } from 'react-chartjs-2';
import ExpandPopUp from '../../../ChartDashboard/CommonCharts/ExpandPopUp';
import { Growth_chart } from '../../../../Utility/Constants';
import { Colors } from '../../../../Styles/Colors';

const GROWTH_CHART_XAXIS_AGE_UPTO_FIVE = ["Birth", "", "", "3", "", "", "6", "", "", "9", "", "", "1 year", "", "", "3", "", "", "6", "", "", "9", "", "", "2 year", "", "", "3", "", "", "6", "", "", "9", "", "", "3 year", "", "", "3", "", "", "6", "", "", "9", "", "", "4 year", "", "", "3", "", "", "6", "", "", "9", "", "", "5 year"];
const GROWTH_CHART_XAXIS_AGE_FROM_FIVE_EXTRA_LABEL = [
    "5", "", "", "", "", "", "6months", "", "", "", "", "", "6", "", "", "", "", "", "6months", "", "", "", "", "",
    "7", "", "", "", "", "", "6months", "", "", "", "", "", "8", "", "", "", "", "", "6months", "", "", "", "", "",
    "9", "", "", "", "", "", "6months", "", "", "", "", "", "10", "", "", "", "", "", "6months", "", "", "", "", "",
    "11", "", "", "", "", "", "6months", "", "", "", "", "", "12", "", "", "", "", "", "6months", "", "", "", "", "",
    "13", "", "", "", "", "", "6months", "", "", "", "", "", "14", "", "", "", "", "", "6months", "", "", "", "", "",
    "15", "", "", "", "", "", "6months", "", "", "", "", "", "16", "", "", "", "", "", "6months", "", "", "", "", "",
    "17", "", "", "", "", "", "6months", "", "", "", "", "", "18", "", "", "", "", "", "6months", "", "", "", "", "", "19"];
const AGE_DIGITS_UPTO_FIVE = [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 0.10, 0.11,
    1.0, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 1.10, 1.11,
    2.0, 2.1, 2.2, 2.3, 2.4, 2.5, 2.6, 2.7, 2.8, 2.9, 2.10, 2.11,
    3.0, 3.1, 3.2, 3.3, 3.4, 3.5, 3.6, 3.7, 3.8, 3.9, 3.10, 3.11,
    4.0, 4.1, 4.2, 4.3, 4.4, 4.5, 4.6, 4.7, 4.8, 4.9, 4.10, 4.11,
    5.0
];
const AGE_DIGITS_FROM_FIVE_EXTRA_POINTS = [
    5.0, 5.1, 5.2, 5.3, 5.4, 5.5, 5.6, 5.7, 5.8, 5.9, 5.10, 5.11,
    6.0, 6.1, 6.2, 6.3, 6.4, 6.5, 6.6, 6.7, 6.8, 6.9, 6.10, 6.11,
    7.0, 7.1, 7.2, 7.3, 7.4, 7.5, 7.6, 7.7, 7.8, 7.9, 7.10, 7.11,
    8.0, 8.1, 8.2, 8.3, 8.4, 8.5, 8.6, 8.7, 8.8, 8.9, 8.10, 8.11,
    9.0, 9.1, 9.2, 9.3, 9.4, 9.5, 9.6, 9.7, 9.8, 9.9, 9.10, 9.11,
    10.0, 10.1, 10.2, 10.3, 10.4, 10.5, 10.6, 10.7, 10.8, 10.9, 10.10, 10.11,
    11.0, 11.1, 11.2, 11.3, 11.4, 11.5, 11.6, 11.7, 11.8, 11.9, 11.10, 11.11,
    12.0, 12.1, 12.2, 12.3, 12.4, 12.5, 12.6, 12.7, 12.8, 12.9, 12.10, 12.11,
    13.0, 13.1, 13.2, 13.3, 13.4, 13.5, 13.6, 13.7, 13.8, 13.9, 13.10, 13.11,
    14.0, 14.1, 14.2, 14.3, 14.4, 14.5, 14.6, 14.7, 14.8, 14.9, 14.10, 14.11,
    15.0, 15.1, 15.2, 15.3, 15.4, 15.5, 15.6, 15.7, 15.8, 15.9, 15.10, 15.11,
    16.0, 16.1, 16.2, 16.3, 16.4, 16.5, 16.6, 16.7, 16.8, 16.9, 16.10, 16.11,
    17.0, 17.1, 17.2, 17.3, 17.4, 17.5, 17.6, 17.7, 17.8, 17.9, 17.10, 17.11,
    18.0, 18.1, 18.2, 18.3, 18.4, 18.5, 18.6, 18.7, 18.8, 18.9, 18.10, 18.11, 19.0
];

const chart = {
    hba1cChart: [],
    glucoseChart: [],
}
const PD_grow = {
    labels: [],
    datasets: []
}
export default function CollabCharts(props) {
    const { id, isProgrammaticScroll, userData, details } = props;

    const [chartData, setchartData] = useState(chart);
    const [listOfLabels, setlistOfLabels] = useState([]);
    const [listOfValues, setlistOfValues] = useState([]);
    const [errorMsg, seterrorMsg] = useState(false);
    const [errorMsgText, seterrorMsgText] = useState('');
    const [patient_id, setpatient_id] = useState(null);
    const [isExpandCliked, setisExpandCliked] = useState(false);
    const [title, settitle] = useState('');
    const [immunizationData, setimmunizationData] = useState([]);
    const [filterItem, setfilterItem] = useState(Growth_chart);
    const [filter_data, setfilter_data] = useState('height');
    const [growthChart, setgrowthChart] = useState([]);
    const [PD_data, setPD_data] = useState(PD_grow);
    const [FollicularPhase, setFollicularPhase] = useState('');
    const [OvulatoryPhase, setOvulatoryPhase] = useState('');
    const [LutealPhase, setLutealPhase] = useState('');
    const [Comments, setComments] = useState('');
    const [SelectedOvulation, setSelectedOvulation] = useState({});
    const [PrevOvulaData, setPrevOvulaData] = useState([]);
    const [OvulationHeader, setOvulationHeader] = useState({});
    const [OvulationTable, setOvulationTable] = useState([]);

    useEffect(() => {
        try {
            const cachedData = getCachevalue('DoctorPatientData');
            const appointData = JSON.parse(cachedData);
            const clinicId = getCachevalue('SelectedDoctorClinic')
            if (appointData?.patient_id) {
                setpatient_id(appointData.patient_id);
            }
        } catch (error) {
            errorMessage(error.message)
        }
    }, []);

    useEffect(() => {
        if(OvulationTable && OvulationTable.length > 0){
            setOvulationTable(OvulationTable)
        }
    }, [OvulationTable]);

    const msgClose = () => {
        seterrorMsg(false);
        seterrorMsgText('');
    }
    const errorMessage = (msg) => {
        seterrorMsg(true)
        seterrorMsgText(msg)
    }
    const getH1bacData = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_CHART_HBA1C + `?patient_id=${patient_id}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        let data = response?.data?.data
                        let listOfLabels = [];
                        let listOfValues = [];
                        data.forEach(item => {
                            let chart_date = "";
                            chart_date = item.chart_date ? formatDate(item.chart_date) : item.chart_date;
                            listOfLabels.push(chart_date);
                            listOfValues.push(item.chart_value);
                        })
                        setchartData((prevData) => ({
                            ...prevData,
                            hba1cChart: data || [],
                        }));
                        setlistOfLabels(listOfLabels || [])
                        setlistOfValues(listOfValues || [])
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        errorMessage(error.response.data.message)
                    } else {
                        errorMessage(error.message)
                    }
                })
        } catch (error) {
            errorMessage(error.message)
        }
    }

    const getGlucouseData = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_GLUCOUSE_LOG + `?patient_id=${patient_id}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        setchartData((prevData) => ({
                            ...prevData,
                            glucoseChart: response.data.data || [],
                        }));
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        errorMessage(error.response.data.message)
                    } else {
                        errorMessage(error.message)
                    }
                })
        } catch (error) {
            errorMessage(error.message)
        }
    }

    const getImmunizationData = () => {
        try {
            RestAPIService.getAll(Serviceurls.PD_IMMUNIZATION_GET + `?patient_id=${patient_id}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        let data = response.data.data
                        let transformedData = {};
                        data?.forEach((item) => {
                            var { patient_age, ...rest } = item;
                            if (!transformedData[patient_age]) {
                                transformedData[patient_age] = {
                                    patient_age,
                                    data: [rest],
                                };
                            } else {
                                transformedData[patient_age].data.push(rest);
                            }
                        });
                        setimmunizationData(Object.values(transformedData))
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        errorMessage(error.response.data.message)
                    } else {
                        errorMessage(error.message)
                    }
                })
        } catch (error) {
            errorMessage(error.message)
        }
    }

    const PrevOvulationData = () => {
        try {
            RestAPIService.getAll(Serviceurls.OVULATION_INDUCTION_LIST + `?patient_id=${patient_id}`).
                then((response) => {
                    if (response.data.status === "success") {
                        setPrevOvulaData(response.data.data)
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        errorMessage(error.response.data.message)
                    } else {
                        errorMessage(error.message)
                    }
                })
        }
        catch (e) {
            errorMessage(e.message)
        }
    }

    const GetOvulationData = (InductID = null) => {
        try {
            let APIUrl = ""
            if (InductID) {
                APIUrl = Serviceurls.OVULATION_INDUCTION_DATA_GET + `?patient_id=${patient_id}&ovulation_induction_id=${InductID}`
            } else {
                APIUrl = Serviceurls.OVULATION_INDUCTION_DATA_GET + `?patient_id=${patient_id}`
            }
            RestAPIService.getAll(APIUrl).
                then((response) => {
                    if (response.data.status === "success") {
                        setOvulationHeader(response.data.data?.ovulation_induction_header || {})
                        setOvulationTable(response.data.data?.ovulation_induction_values || [])
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        errorMessage(error.response.data.message)
                    } else {
                        errorMessage(error.message)
                    }
                })
        }
        catch (e) {
            errorMessage(e.message)
        }
    }

    const getchartData = (section) => {
        switch (section) {
            case "hba1ccharts":
                getH1bacData()
                break;
            case "glugosecharts":
                getGlucouseData()
                break;
            case "immunization":
                getImmunizationData()
                break;
            case "growth":
                getGrowthchart()
                break;
            case "ovulation":
                PrevOvulationData()
                break;
            default:
                break;
        }

    }

    let grapharray = []
    chartData.glucoseChart.map((item) => {
        let fasting = item.fasting ? item.fasting : 0
        let postBreakfast = item.number_2_hrs_post_bfast ? item.number_2_hrs_post_bfast : 0
        let postglucose = item.number_2_post_glucose ? item.number_2_post_glucose : 0
        let Lunch = item.lunch ? item.lunch : 0
        let PostLunch = item.number_2_hrs_post_lunch ? item.number_2_hrs_post_lunch : 0
        let Pre_Dinner = item.pre_dinner ? item.pre_dinner : 0
        let Dinner = item.number_2_hrs_post_dinner ? item.number_2_hrs_post_dinner : 0
        grapharray.push(fasting, postBreakfast, Lunch, PostLunch, Pre_Dinner, Dinner, postglucose)
    })
    grapharray = grapharray.map(Number)

    let minVal = Math.min(...grapharray);
    let MaxVal = Math.max(...grapharray);

    const options = {
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        stacked: false,
        plugins: {
            title: {
                display: true,
                text: 'BLOOD GLUCOSE LEVEL',
            },
        },
        scales: {
            y: {
                display: true,
                position: 'left',
                min: minVal,
                max: MaxVal,

            },
            y1: {
                type: 'linear',
                display: false,
                position: 'right',
                min: minVal,
                max: MaxVal,

                grid: {
                    drawOnChartArea: false,
                },
            },
            y2: {
                type: 'linear',
                display: false,
                position: 'left',
                min: minVal,
                max: MaxVal,

                grid: {
                    drawOnChartArea: false,
                },
            },
            y3: {
                type: 'linear',
                display: false,
                position: 'right',
                min: minVal,
                max: MaxVal,

                grid: {
                    drawOnChartArea: false,
                },
            },
            y4: {
                type: 'linear',
                display: false,
                position: 'left',
                min: minVal,
                max: MaxVal,

                grid: {
                    drawOnChartArea: false,
                },
            },
            y5: {
                type: 'linear',
                display: false,
                position: 'right',
                min: minVal,
                max: MaxVal,

                grid: {
                    drawOnChartArea: false,
                },
            },
        },
    };
    let glucoseLabel = chartData.glucoseChart.map((item) => item.activity_date ? item.activity_date.split("-").reverse().join("-") : 0)

    const data = {
        labels: glucoseLabel,
        datasets: [
            {
                label: "Fasting",
                data: chartData.glucoseChart.map((item) => item.fasting ? item.fasting : 0),
                borderColor: '#1E972A',
                backgroundColor: '#1E972A',
                yAxisID: 'y',
            },
            {
                label: "2 Hours Post Glucose Blood Sugar",
                data: chartData.glucoseChart.map((item) => item.number_2_post_glucose ? item.number_2_post_glucose : 0),
                borderColor: '#DE7D24',
                backgroundColor: '#DE7D24',
                yAxisID: 'y1',
            },
            {
                label: "2 Hours Post Breakfast",
                data: chartData.glucoseChart.map((item) => item.number_2_hrs_post_bfast ? item.number_2_hrs_post_bfast : 0),
                borderColor: '#202020',
                backgroundColor: '#202020',
                yAxisID: 'y1',
            },
            {
                label: "Pre Lunch",
                data: chartData.glucoseChart.map((item) => item.lunch ? item.lunch : 0),
                borderColor: '#0461B7',
                backgroundColor: '#0461B7',
                yAxisID: 'y2',
            },
            {
                label: "Post Lunch",
                data: chartData.glucoseChart.map((item) => item.number_2_hrs_post_lunch ? item.number_2_hrs_post_lunch : 0),
                borderColor: 'rgba(98, 129, 91, 0.41)',
                backgroundColor: 'rgba(98, 129, 91, 0.41)',
                yAxisID: 'y3',
            },
            {
                label: "Pre Dinner",
                data: chartData.glucoseChart.map((item) => item.pre_dinner ? item.pre_dinner : 0),
                borderColor: '#AF2591',
                backgroundColor: '#AF2591',
                yAxisID: 'y4',
            },
            {
                label: "2 hours Post Dinner",
                data: chartData.glucoseChart.map((item) => item.number_2_hrs_post_dinner ? item.number_2_hrs_post_dinner : 0),
                borderColor: '#29B6F6',
                backgroundColor: '#29B6F6',
                yAxisID: 'y5',
            },
        ],
    };
    const PopupClose = () => {
        setisExpandCliked(false)
    }

    const getGrowthchart = () => {
        try {
            RestAPIService.getAll(Serviceurls.PD_GROWTH_CHART + `?patient_id=${patient_id}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        setgrowthChart(response?.data?.data)
                        setPlotHeightChart(response?.data?.data, filter_data);

                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        errorMessage(error.response.data.message)
                    } else {
                        errorMessage(error.message)
                    }
                })
        } catch (error) {
            errorMessage(error.message)
        }
    }

    const setPlotHeightChart = (response, type) => {

        let patientGrowth = response.patient_growth;
        let patient = response.patient;

        let patientData;
        let standardData;

        var standard_growth_chart = Object.keys(response).length > 0 && Object.keys(response.standard_growth_chart).length > 0 ?

            response.standard_growth_chart : {}

        if (type === "height") {
            patientData = patientGrowth && patientGrowth.height ? patientGrowth.height : [];
            standardData = Object.keys(standard_growth_chart).length > 0 && standard_growth_chart.height ? standard_growth_chart.height : 0;
        }
        else if (type === "weight") {
            patientData = patientGrowth && patientGrowth.weight ? patientGrowth.weight : [];
            standardData = Object.keys(standard_growth_chart).length > 0 && standard_growth_chart.weight ? standard_growth_chart.weight : 0;
        }
        else if (type === "bmi") {
            patientData = patientGrowth && patientGrowth.bmi ? patientGrowth.bmi : [];
            standardData = Object.keys(standard_growth_chart).length > 0 && standard_growth_chart.bmi ? standard_growth_chart.bmi : 0;
        }
        else if (type === "head circumference") {
            patientData = patientGrowth && patientGrowth.head_circumference ? patientGrowth.head_circumference : [];
            standardData = Object.keys(standard_growth_chart).length > 0 && standard_growth_chart.head_circumference ? standard_growth_chart.head_circumference : 0;
        }
        var dataFields = PD_data;

        var heightList = standardData;

        let age = "";

        if (patient) {

            let dataArr = (patient.age).split(",")
            let lenArr = dataArr.length;

            if (lenArr > 0) {

                age = parseInt(dataArr[0]);

                let m = parseInt(dataArr[1]);
                let d = parseInt(dataArr[2]);

                if (m > 0 || d > 0) {
                    age = age + 1;
                }
            }
        }

        let patientAge = age;

        dataFields.labels = parseInt(patientAge) <= 5 ? GROWTH_CHART_XAXIS_AGE_UPTO_FIVE : GROWTH_CHART_XAXIS_AGE_FROM_FIVE_EXTRA_LABEL;

        var colorCode = [
            "rgba(255, 0, 0, 0.75)",
            "rgba(0, 255, 0, 0.75)",
            "rgba(0, 0, 255, 0.75)"
        ];


        if (parseInt(patientAge) > 5) {


            let len = heightList.length;

            for (var outer = 0; outer < len; outer++) {

                let newHeights = [];
                let item = heightList[outer];
                let innerLen = item.value.length;

                for (var n = 0; n < innerLen; n++) {

                    if (n === 0 || n === (innerLen - 1)) {

                        newHeights.push(heightList[outer]["value"][n]);
                    } else {

                        let diff = heightList[outer]["value"][n] - heightList[outer]["value"][n - 1];
                        let colDiff = diff / 6;

                        for (let nC = 1; nC <= 5; nC++) {

                            newHeights.push(heightList[outer]["value"][n - 1] + colDiff * nC)
                        }

                        newHeights.push(heightList[outer]["value"][n]);
                    }

                }

                heightList[outer]["value"] = newHeights;

            }

        }

        var sample =
            heightList &&
            heightList.length > 0 &&
            heightList.map((item, i) => {

                let color = "";
                if (i === 0 || i === 4) { color = "#cc33ff"; }
                if (i === 1 || i === 3) { color = "#00ccff"; }
                if (i === 2) { color = "#00ff99"; }
                if (i === 6) { color = "#ff0066"; }


                return {
                    label: item.label,
                    data: item.value,
                    backgroundColor: "rgba(255, 255, 255, 0)",
                    borderColor: color,  // colorCode[i % 3]
                    fill: false,
                    lineTension: 0.5,
                    //highlightEnabled: false,
                    axisY: {
                        stripLines: {}
                    },
                    pointRadius: 0,
                };
            });

        if (sample) {
            sample.pop();


            var stringOne = "Length / height for age";
            var stringTwo = patient.gender === "m" ? " Boys " : " Girls ";

            var stringThree = patientAge <= 5 ? "0-5 years" : "5-19 years";
            var stringFour = " (Percentile)";
            dataFields.datasets = sample;

            var patient_record_count = patientGrowth && patientGrowth.age ? patientGrowth.age.length : 0;
            var patient_rec = [];

            var extra_digits = parseInt(patientAge) <= 5 ? AGE_DIGITS_UPTO_FIVE : AGE_DIGITS_FROM_FIVE_EXTRA_POINTS
            var lables_count = extra_digits.length;

            for (var j = 0; j < lables_count; j++) {

                var is_captured = false;
                for (var i = 0; i < patient_record_count; i++) {

                    if (extra_digits[j] == patientGrowth.age[i]) {

                        patient_rec.push(patientData[i]);
                        is_captured = true;
                    }
                }

                if (!is_captured) {
                    patient_rec.push('');
                }
            }

            var patient_height_dataset = {
                label: type,
                data: patient_rec,
                backgroundColor: "rgba(255, 255, 255, 0)",
                type: 'bar',
                backgroundColor: '#FF0000',
                borderColor: '#FF0000',  //'#71B37C',
                fill: false,
                lineTension: 0.5
            };

            dataFields.datasets.push(patient_height_dataset);


            setPD_data(dataFields)
        }
    }
    const renderChip = () => {
        try {
            return (
                <Box component={'div'}>
                    <Box component={'div'}>
                        <Box component={'div'}>
                            {filterItem?.map((item, index) => {
                                return (
                                    <Chip
                                        className={`eMed_chip ${(item.label).toLowerCase() === filter_data ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                        label={item.label}
                                        variant={item.label === filter_data ? 'contained' : 'outlined'}
                                        key={index}
                                        onClick={() => {
                                            setfilter_data(item?.value)
                                            getGrowthchart()
                                        }}
                                        // color={'primary'}
                                        clickable
                                    />
                                )
                            })}
                        </Box>
                    </Box>
                </Box>
            )
        } catch (error) {
            errorMessage(error.message)
        }
    }

    const renderGrowChart = () => {
        let data = PD_data

        let chartFont = "Open Sans";
        const options = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: true
                },
                title: {
                    display: false
                },
                tooltip: {
                    titleFont: {
                        family: chartFont,
                    },
                    bodyFont: {
                        family: chartFont,
                    },
                }
            },
            scales: {
                x: {
                    ticks: {
                        align: 'center',
                        font: {
                            weight: 'bold',
                            family: chartFont,
                            size: 12
                        }
                    }
                },
                y: {
                    stepSize: 1,
                    ticks: {
                        align: 'center',
                        font: {
                            weight: 'bold',
                            family: chartFont
                        },
                    }
                },
            },
            animations: {
                tension: {
                    duration: 1000,
                    easing: 'linear',
                    from: 0.2,
                    to: 0,
                    loop: true
                }
            }
        };
        try {
            let chartFont = "Open Sans";
            return (
                <Box component={'div'}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: '2vw' }}>
                            <Typography sx={{ rotate: "270deg" }} >{returnchartText(filter_data)}</Typography>
                        </Box>
                        <Box component={'div'} p={'0.5vw'} sx={{ height: '57vh', width: '57vw' }}>
                            <Line
                                options={options}
                                data={data}
                            />
                        </Box>
                    </Box>

                </Box>
            )
        } catch (error) {
            errorMessage(error.message)
        }
    }
    const returnchartText = (text) => {
        switch (text) {
            case "height": return "Height_(cms)"
            case "weight": return "Weight_(Kgs)"
            case "bmi": return "BMI"
            case "head circumference": return "Head_Circumference_(cms)"
            default: return ""
        }
    }
    const renderMultiLineText = (label, val, stateKey) => {

        return (
            <TextField
                label={label}
                sx={{ marginLeft: "1vw", marginY: "0.5vw", width: "30vw" }}
                multiline={true}
                InputProps={{ readOnly: true }}
                inputProps={{ maxLength: 250 }}
                value={val || ''}
                rows={2} />

        )
    }
    return (
        <Box marginLeft={'.5vw'}>
            <Typography fontSize={'1vw'} fontWeight={'bold'}>Charts</Typography>
            {userData.service_type === 'DB' ?
                <Box >
                    <Grid container spacing={1} >
                        <Grid item xs={6} >
                            <TriggerApiOnView id={`hba1ccharts_${id}`} onEnterView={(id) => getchartData(id)} isProgrammaticScroll={isProgrammaticScroll}>
                                <Box id={`hba1ccharts_${id}`} height={'20vw'} columnGap={'1vw'}>
                                    <LineChartView
                                        title={"HBA1C Chart"}
                                        chartLabel={listOfLabels}
                                        chartData={listOfValues}
                                    />
                                </Box>
                            </TriggerApiOnView>
                        </Grid>
                        <Grid item xs={6} >
                            <TriggerApiOnView id={`glugosecharts_${id}`} onEnterView={(id) => getchartData(id)} isProgrammaticScroll={isProgrammaticScroll}>
                                <Box id={`glugosecharts_${id}`} height={'20vw'} columnGap={'.5vw'}>
                                    <Paper className='eMed_chart_wrapper' elevation={2} sx={{ height: '20vw' }}>
                                        <Box className="eMed_revenueTbl_titleDiv">
                                            <Typography id="eMed_chart_headTxt">{`Glucose Chart`}</Typography>
                                            {chartData.glucoseChart.length > 0 ? <IconButton size='small' onClick={() => { setisExpandCliked(true); settitle('Glucose Chart') }}>
                                                <img className='emedhub_expand_img' src={ImagePaths.ExpandView.default} alt="Expand" />
                                            </IconButton> : null}
                                        </Box>
                                        <Box className="eMed_Line_chartDiv" sx={{ cursor: "default" }}>
                                            {chartData.glucoseChart.length > 0 ? <Line options={options} data={data} />
                                                : <Box sx={{ display: 'grid', justifyContent: 'center', alignItems: 'center', height: '15vw' }}>{'No Records to be Shown'}</Box>}
                                        </Box>
                                    </Paper>
                                </Box>
                            </TriggerApiOnView>
                        </Grid>
                    </Grid>
                </Box> :
                userData.service_type === 'PD' ?
                    <Box marginLeft={'1vw'}>
                        <Typography fontSize={'1vw'} fontWeight={'bold'}>Immunization</Typography>
                        <TriggerApiOnView id={`immunization_${id}`} onEnterView={(id) => getchartData(id)} isProgrammaticScroll={isProgrammaticScroll}>
                            <Box id={`immunization_${id}`}>
                                <Paper>
                                    <TableContainer>
                                        <Table size='small' aria-label="stictbl">
                                            <TableHead sx={{ backgroundColor: '#FAF9F9' }}>
                                                <TableRow>
                                                    <TableCell sx={{ fontWeight: 'bold', width: '10vw' }}>Age</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold', width: '10vw' }}>Vaccination</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold', width: '10vw' }}>Dose</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold', width: '10vw' }}>Route</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold', width: '10vw' }}>Due Date</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold', width: '10vw' }}>Given Date</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold', width: '10vw' }}>Comments</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {immunizationData.map((group, groupIndex) => (
                                                    group.data.map((row, rowIndex) => (
                                                        <TableRow key={rowIndex}>
                                                            {rowIndex === 0 && (
                                                                <TableCell rowSpan={group.data.length} sx={{ width: '8vw', borderRight: '1px solid #ddd' }}>{group.patient_age ? group.patient_age : '-'}</TableCell>
                                                            )}
                                                            <TableCell sx={{ width: '22vw' }}>{row?.vaccine_name?.length > 50 ?
                                                                <Tooltip title={row?.vaccine_name} placement='top'><Typography component={'div'} fontSize={"0.8rem"}>{(row.vaccine_name).slice(0, 50) + '...'}</Typography></Tooltip> :
                                                                row?.vaccine_name ? <Typography component={'div'} fontSize={"0.8rem"}>{row?.vaccine_name}</Typography> : '-'}</TableCell>
                                                            <TableCell sx={{ width: '10vw' }}>{row.dose ? row.dose : '-'}</TableCell>
                                                            <TableCell sx={{ width: '10vw' }}>{row.route ? row.route : '-'}</TableCell>
                                                            <TableCell sx={{ width: '14vw' }}>{row.due_date ? row.due_date : '-'}</TableCell>
                                                            <TableCell sx={{ width: '13vw' }}>{row.given_date ? row.given_date : '-'}</TableCell>
                                                            <TableCell sx={{ width: '17vw' }}>{
                                                                row?.comments?.length > 12 ?
                                                                    <Tooltip title={row?.comments} placement='top'><Box>{(row.comments).slice(0, 12) + '...'}</Box></Tooltip> :
                                                                    row?.comments ? row?.comments : '-'
                                                            }</TableCell>
                                                        </TableRow>
                                                    ))
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Box>
                        </TriggerApiOnView>
                        {growthChart.length > 0 ?
                            <TriggerApiOnView id={`growth_${id}`} onEnterView={(id) => getchartData(id)} isProgrammaticScroll={isProgrammaticScroll}>
                                <Box id={`growth_${id}`}>
                                    <Typography fontSize={'1vw'} fontWeight={'bold'} marginTop={'.5vw'}>{`Growth Chart`}</Typography>
                                    <Box>
                                        <Box>
                                            {renderChip()}
                                            {renderGrowChart()}
                                        </Box>
                                    </Box>
                                </Box>
                            </TriggerApiOnView> : null}
                    </Box> :
                    userData.service_type === 'OBGYN' ?
                        <TriggerApiOnView id={`ovulation_${id}`} onEnterView={(id) => getchartData(id)} isProgrammaticScroll={isProgrammaticScroll}>
                            <Box id={`ovulation_${id}`}>
                                <Box>
                                    <Box className="eMed_DocNotes_FreqChips_Wrapp">
                                        {
                                            PrevOvulaData?.length > 0 ? PrevOvulaData.map((item, index) => (
                                                <Chip
                                                    size='small'
                                                    className={`eMed_chip ${SelectedOvulation?.last_menstrual_period === item?.last_menstrual_period ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                    key={index}
                                                    label={item?.last_menstrual_period}
                                                    variant={SelectedOvulation?.last_menstrual_period === item?.last_menstrual_period ? 'contained' : "outlined"}
                                                    onClick={() => {
                                                        setSelectedOvulation(item);
                                                        setTimeout(() => {
                                                            GetOvulationData(item.id)
                                                        }, 1000);
                                                    }}
                                                />
                                            )) : null
                                        }
                                    </Box>
                                </Box>
                                <Box sx={{ border: '1px solid #888888', borderRadius: '1px', height: 'auto' }}>
                                    <Box sx={{ backgroundColor: '#FAF9F9', height: '2vw', paddingLeft: '1vw' }}>
                                        <Typography fontSize={'1vw'} fontWeight={'bold'}>{`Ovulation Chart`}</Typography>
                                    </Box>
                                    <Box>
                                        <Box display={'flex'} justifyContent={'space-between'} padding={'.5vw'}>
                                            <Box>
                                                <Typography fontWeight={'bold'} fontSize={'1vw'}>{`Last Menstrual Period`}</Typography>
                                                <Typography fontSize={'1vw'}>{formatDate(OvulationHeader?.last_menstrual_period)}</Typography>
                                            </Box>
                                            <Box>
                                                <Typography fontWeight={'bold'} fontSize={'1vw'}>{`Therapy`}</Typography>
                                                <Typography fontSize={'1vw'}>{OvulationHeader?.therapy}</Typography>
                                            </Box>
                                            <Box>
                                                <Typography fontWeight={'bold'} fontSize={'1vw'}>{`Procedure`}</Typography>
                                                <Typography fontSize={'1vw'}>{OvulationHeader?.procedure}</Typography>
                                            </Box>
                                        </Box>
                                        <Box display={'flex'} justifyContent={'space-between'} padding={'.5vw'}>
                                            <Paper>
                                                <TableContainer>
                                                    <Table size='small' aria-label="stictbl">
                                                        <TableHead sx={{ backgroundColor: '#FAF9F9' }}>
                                                            <TableRow>
                                                                <TableCell sx={{ fontWeight: 'bold', width: '15vw' }}>Date</TableCell>
                                                                <TableCell sx={{ fontWeight: 'bold', width: '15vw' }}>Days</TableCell>
                                                                <TableCell sx={{ fontWeight: 'bold', width: '20vw' }}>Right Ovary</TableCell>
                                                                <TableCell sx={{ fontWeight: 'bold', width: '20vw' }}>Left Overy</TableCell>
                                                                <TableCell sx={{ fontWeight: 'bold', width: '15vw' }}>Endometrium</TableCell>
                                                                <TableCell sx={{ fontWeight: 'bold', width: '15vw' }}>Pouch of Douglas</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {OvulationTable?.length > 0 ? OvulationTable?.map((item, index) => (
                                                                <TableRow>
                                                                    <TableCell sx={{ width: '20vw' }}>{item?.date_of_visit ? formatDate(item?.date_of_visit) : "-"}</TableCell>
                                                                    <TableCell sx={{ width: '20vw' }}>{item?.day || "-"}</TableCell>
                                                                    <TableCell sx={{ width: '20vw' }}>{item?.right_ovary || "-"}</TableCell>
                                                                    <TableCell sx={{ width: '15vw' }}>{item?.left_ovary || "-"}</TableCell>
                                                                    <TableCell sx={{ width: '25vw' }}>{item?.endometrium || "-"}</TableCell>
                                                                    <TableCell sx={{ width: '25vw' }}>{item?.pouch_of_douglas || "-"}</TableCell>
                                                                </TableRow>
                                                            )) :
                                                                <TableRow>
                                                                    <TableCell colSpan={5} sx={{ width: '20vw' }}>{`No Records to Be Shown`}</TableCell>
                                                                </TableRow>}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Paper>
                                        </Box>
                                        <Box display={'flex'} justifyContent={'space-between'}>
                                            <Box>
                                                {renderMultiLineText("Follicular Phase", OvulationHeader?.follicular_phase, "FollicularPhase")}
                                                {renderMultiLineText("Ovulatory Phase", OvulationHeader?.ovulatory_phase, "OvulatoryPhase")}
                                            </Box>
                                            <Box>
                                                {renderMultiLineText("Luteal Phase", OvulationHeader?.luteal_phase, "LutealPhase")}
                                                {renderMultiLineText("Comments", OvulationHeader?.comments, "Comments")}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </TriggerApiOnView>
                        : null}
            {isExpandCliked ?
                <ExpandPopUp
                    hideview={true}
                    title={title}
                    chartView={<Line options={options} data={data} />}
                    popUpClose={PopupClose.bind(this)}
                /> : null
            }
        </Box>
    )
}
