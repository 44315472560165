import React, { Component } from 'react'
import { Box, Button, CircularProgress, IconButton, Modal, Paper, TextField, Tooltip, Typography } from "@mui/material";
import { Colors } from '../../../../Styles/Colors';
import ShowComponents from '../Common/ShowComponent';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import AddIcon from "@material-ui/icons/AddOutlined";
import SprayIcon from "@material-ui/icons/BlurCircular";
import UndoIcon from "@material-ui/icons/UndoOutlined";
import RedoIcon from "@material-ui/icons/RedoOutlined";
import CreateIcon from "@material-ui/icons/Create";
import LineIcon from "@material-ui/icons/BorderColor";
import RectIcon from "@material-ui/icons/CropLandscape";
import CircleIcon from "@material-ui/icons/RadioButtonUnchecked";
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { ClinicalExamImage } from '../../../../Utility/Constants';
import { fabric } from "fabric";

const styles = {
    control: {
        width: "24px",
        height: "24px",
    },
    control_space_1: {
        width: "24px",
    },
}

let sketch = null
export default class DocClinicExamENTLeft extends Component {
    constructor(props) {
        super(props)
        this.state = {
            clinicExamData: {},
            lineColor: "#000000",
            fillColor: "red",
            backgroundColor: "#FFF",
            appointmentId: this.props.appointmentId,
            patientId: this.props.PatientData?.patient_id,
            notesData: this.props.clinicalExamNotes ? this.props.clinicalExamNotes : '',
            popupNotesData: this.props.clinicalExamNotes ? this.props.clinicalExamNotes : '',
            examinePopup: false,
            tool: null,
            isLoader: false,
            isRedo: false,
            isUndo: false,
        }
        this.componentRef = React.createRef()
        this.intersectionObserver = null
        this.undoStack = [];
        this.redoStack = [];
    }

    apiCalls = () => {
        this.getClinicExamENT()
    }

    componentDidUpdate = (prevProps) => {
        if (this.props !== prevProps) {
            if (this.props.examinePopup && this.state.examinePopup !== this.props.examinePopup) {
                this.setState({
                    examinePopup: true
                }, () => this.getClinicExamENT())
            }
        }
    }

    setCanvasBgImage = (Image) => {
        try {
            sketch = new fabric.Canvas("ENTCanvas", {
                backgroundColor: "transparent",
            })
            fabric.Image.fromURL(Image, (img) => {
                img.set({ crossOrigin: "anonymous" });
                sketch.setBackgroundImage(img, sketch.renderAll.bind(sketch));
                this.saveState()
                sketch.on('object:added', this.saveState);
                sketch.on('object:modified', this.saveState);
                sketch.on('object:removed', this.saveState);
            }, { crossOrigin: "anonymous" });

        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    getClinicExamENT = () => {
        try {
            this.setState({ isLoader: true })
            RestAPIService.getAll(Serviceurls.DOC_ENT_CLINICAL_EXAM_GET + `?appointment_id=${this.state.appointmentId}&patient_id=${this.state.patientId}`)
                .then((response => {
                    if (response?.data?.status === 'success') {
                        let clinicExamData = response.data.data ? response.data.data : {}
                        this.setState({
                            notesData: clinicExamData?.notes ? clinicExamData?.notes : '',
                            popupNotesData: clinicExamData?.notes ? clinicExamData?.notes : ''
                        })
                        try {
                            if (clinicExamData?.clinicexam_image_key) {
                                this.setBackgroundImage(clinicExamData?.clinicexam_image_key);
                            } else if (ClinicalExamImage) {
                                this.setCanvasBgImage(ClinicalExamImage)
                                this.setState({ ENTImage: ClinicalExamImage, isLoader: false })
                            }
                        } catch (e) {
                            if (ClinicalExamImage) {
                                this.setCanvasBgImage(ClinicalExamImage)
                            }
                            this.setState({ isLoader: false })
                        }
                    }
                })).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                    this.setState({ isLoader: false })
                })
        } catch (error) {
            this.setState({ isLoader: false })
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message,
            isLoader: false
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message,
            isLoader: false
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    renderClinicExamImage = (fromPopup = false) => {
        const screenWidth = window.innerWidth;
        const containerWidth = screenWidth >= 1440 ? "40vw" : screenWidth >= 768 ? "56vw" : "90vw";
        const sketchWidth = Math.min(screenWidth * 0.8, 570);
        const sketchHeight = (sketchWidth / 570) * 530;
        return (
            <div style={{ height: '90%', width: containerWidth, overflow: 'auto', display: 'flex', flexDirection: 'column', alignItems: (screenWidth <= 768) ? "flex-start" : 'center', justifyContent: 'center' }}>
                {fromPopup ?
                    <canvas
                        id="ENTCanvas"
                        width={sketchWidth}
                        height={sketchHeight}
                    ></canvas>
                    :
                    <img src={this.state.ENTImage} alt='ENT Image' />
                }
            </div>
        )
    }

    async setBackgroundImage(doc_key) {
        try {
            const response = await RestAPIService.getAllTwo(Serviceurls.AWS_URL_GET + "?doc_key=" + encodeURIComponent(doc_key));
            if (response.data.status === "success") {
                const Data = response?.data;
                this.setCanvasBgImage(Data?.data)
                this.setState({ ENTImage: Data?.data, isLoader: false })
            } else {
                this.setState({ isLoader: false })
            }
        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isLoader: false,
                isErrorMsgText: "Error fetching S3 URL"
            });
        }
    }

    dataURItoBlob = (dataURI) => {
        var byteString = atob(dataURI.split(",")[1]);
        var mimeString = dataURI
            .split(",")[0]
            .split(":")[1]
            .split(";")[0];
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], { type: mimeString });
    };

    clear = () => {
        sketch.off('object:added');
        sketch.off('object:modified');
        sketch.off('object:removed');
        sketch.clear()
        this.undoStack = [];
        this.redoStack = [];
        this.tempShape = null;
        fabric.Image.fromURL(ClinicalExamImage, (img) => {
            sketch.setBackgroundImage(img, sketch.renderAll.bind(sketch));
            this.saveState();
            sketch.on('object:added', this.saveState);
            sketch.on('object:modified', this.saveState);
            sketch.on('object:removed', this.saveState);
        });
        this.setState({
            tool: '',
            canUndo: false,
            canRedo: false,
            text: '',
            popupNotesData: '',
            lineColor: "#000000",
            isRedo: false,
            isUndo: false,
        });
    };

    changeColor = (color) => {
        this.setState({ lineColor: color }, () => {
            if (sketch.freeDrawingBrush) {
                sketch.freeDrawingBrush.color = color;
            }
        });
    };

    handleSave = () => {
        try {
            const sketchData = sketch.toDataURL()
            if (sketchData) {
                let data = new FormData();
                data.append('appointment_id', this.state.appointmentId);
                data.append('patient_id', this.state.patientId);
                data.append('image', this.dataURItoBlob(sketchData));
                data.append('image_json', JSON.stringify(sketch))
                data.append('notes', this.state.popupNotesData)
                RestAPIService.create(data, Serviceurls.DOC_ENT_CLINICAL_EXAM_GET)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.successMessage(response?.data?.message)
                            this.props.isDataSaved()
                            this.clear()
                            this.setState({
                                examinePopup: false
                            }, () => { this.getClinicExamENT() })
                        }
                    }).catch((error) => {
                        this.errorMessage(error.message)
                        this.props.isDataSaved()
                        this.setState({ examinePopup: false })
                    });
            }
        } catch (e) {
            this.errorMessage(e.message);
            this.props.isDataSaved();
            this.setState({ examinePopup: false });
        }
    };

    handleArrow = (x1, y1, x2, y2, direction) => {
        sketch.isDrawingMode = false;

        const line = new fabric.Line([x1, y1, x2, y2], {
            fill: this.state.lineColor,
            stroke: this.state.lineColor,
            strokeWidth: 2,
        });

        const arrowHead = new fabric.Triangle({
            fill: this.state.lineColor,
            width: 15,
            height: 20,
            left: x2,
            top: y2,
            originX: "center",
            originY: "center",
        });

        switch (direction) {
            case "up":
                arrowHead.set({ angle: 0 });
                break;
            case "down":
                arrowHead.set({ angle: 180 });
                break;
            case "left":
                arrowHead.set({ angle: -90 });
                break;
            case "right":
                arrowHead.set({ angle: 90 });
                break;
            default:
                break;
        }
        const group = new fabric.Group([line, arrowHead]);
        sketch.add(group);

    }

    handleMouseDown = (event) => {
        const pointer = sketch.getPointer(event.e);
        this.startCoords = { x: pointer.x, y: pointer.y };
        this.isDrawing = true;

        if (this.state.tool === "line") {
            this.tempLine = new fabric.Line([pointer.x, pointer.y, pointer.x, pointer.y], {
                stroke: this.state.lineColor,
                strokeWidth: 2,
                selectable: false,
                evented: false,
                hasBorders: false,
                hasControls: false,
            });
            this.tempShape = this.tempLine
            sketch.add(this.tempLine)
        }
    };

    handleMouseMove = (event) => {
        if (!this.isDrawing || !this.tempShape) return;
        const pointer = sketch.getPointer(event.e);
        if (this.state.tool === "line") {
            this.tempShape.set({ x2: pointer.x, y2: pointer.y });
            sketch.renderAll();
        }
    };

    handleMouseUp = () => {
        if (!this.isDrawing) return;

        this.isDrawing = false;
        if (this.tempShape) {
            this.tempShape.set({
                selectable: true,
                evented: true,
                hasBorders: false,
                hasControls: true,
            });
            this.tempShape = null;
        }
    };

    saveState = () => {
        const currentState = JSON.stringify(sketch.toJSON());
        if (!this.undoStack?.includes(currentState) && !this.state.isUndo && !this.state.isRedo) {
            this.undoStack.push(currentState);
        }
    }

    undo = () => {
        if (this.undoStack.length <= 1) return;
        const currentState = this.undoStack.pop()
        this.redoStack.unshift(currentState)
        const previousState = this.undoStack[this.undoStack.length - 1]
        this.loadState(previousState)
    };

    redo = () => {
        if (this.redoStack.length === 0) return
        const nextState = this.redoStack.shift()
        this.undoStack.push(nextState)
        this.loadState(nextState)
    }

    loadState = (state) => {
        if (state) {
            sketch.clear()
            sketch.loadFromJSON(state, () => {
                sketch.renderAll()
                this.setState({ isUndo: false, isRedo: false })
            })
        }
    }

    setTool = (tool) => {
        sketch.isDrawingMode = false;
        this.setState({ tool: tool }, () => {
            switch (tool) {
                case "spray":
                    sketch.freeDrawingBrush = new fabric.SprayBrush(sketch);
                    sketch.isDrawingMode = true;
                    sketch.freeDrawingBrush.width = 10;
                    this.changeColor("#000000");
                    break;
                case "pencil":
                    sketch.isDrawingMode = true;
                    sketch.freeDrawingBrush = new fabric.PencilBrush(sketch);
                    sketch.freeDrawingBrush.width = 3;
                    this.changeColor("#000000");
                    break;
                case "brush":
                    sketch.isDrawingMode = true;
                    sketch.freeDrawingBrush = new fabric.PencilBrush(sketch);
                    sketch.freeDrawingBrush.width = 10;
                    this.changeColor("#000000");
                    break;
                case "eraser":
                    sketch.isDrawingMode = true;
                    sketch.freeDrawingBrush = new fabric.PencilBrush(sketch);
                    sketch.freeDrawingBrush.width = 10;
                    this.changeColor("#FFFFFF");
                    break;
                case "line":
                    sketch.selection = false;
                    // sketch.on("mouse:down", this.handleMouseDown);
                    // sketch.on("mouse:move", this.handleMouseMove);
                    // sketch.on("mouse:up", this.handleMouseUp);

                    const line = new fabric.Line([50, 100, 300, 100], {
                        stroke: this.state.lineColor,
                        strokeWidth: 5,
                        selectable: true,
                    });
                    sketch.add(line);
                    break;
                case "rectangle":
                    const rect = new fabric.Rect({
                        left: 260, top: 220, width: 60, height: 60,
                        fill: 'transparent', stroke: this.state.lineColor,
                        strokeWidth: 2,
                    });
                    sketch.isDrawingMode = false;
                    sketch.add(rect);
                    break;
                case "circle":
                    const circle = new fabric.Circle({
                        left: 240,
                        top: 200,
                        radius: 50,
                        fill: 'transparent',
                        stroke: this.state.lineColor,
                        strokeWidth: 2,
                    });
                    sketch.isDrawingMode = false;
                    sketch.add(circle);
                    break;
                case "text":
                    const text = new fabric.Text(this.state.text ? this.state.text : '', {
                        left: 270,
                        top: 240,
                        fontFamily: 'Arial',
                        fontSize: 14,
                        fill: this.state.lineColor,
                    })
                    sketch.add(text)
                    this.setState({ text: '' })
                    break;
            }
        });
    };


    renderExaminePopup = () => {
        const screenWidth = window.innerWidth
        return (
            <Modal open={this.state.examinePopup}>
                <Paper elevation={3} id="emedhub_popup_mainBox" sx={{ width: screenWidth >= 1440 ? "70dvw" : '90dvw', height: '98dvh', overflowY: 'auto' }}>
                    <Box component={'div'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} borderBottom={'1px solid lightgray'}>
                        <Typography fontSize={'0.9vw'} fontWeight={600}>Clinical Examination</Typography>
                        <Button
                            className="Common_Btn_Min_Width"
                            onClick={() => {
                                this.props.isDataSaved()
                                this.setState({ examinePopup: false })
                                this.clear()
                            }}
                        >
                            <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                        </Button>
                    </Box>
                    <Box component={'div'} display={'flex'}>
                        <Box width={"66%"}>
                            <Box component={'div'} style={{ display: "flex", justifyContent: "center", width: '100%' }}>
                                {this.renderClinicExamImage(true)}
                            </Box>
                        </Box>
                        <Box width={"33%"}>
                            <Box sx={{ height: '100%', display: 'flex', flexDirection: "column", gap: '1vw', justifyContent: 'space-between', p: '0.5vw' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1.5vw' }}>
                                    <div style={{ width: "100%", display: "flex", alignItems: "center", marginTop: "0.5vw", flexDirection: "column", justifyContent: "center", backgroundColor: Colors.Background }}>
                                        <div style={{ display: 'flex', margin: '0.5vw' }}>
                                            <Button className='Common_Btn_Min_Width' style={{ border: this.state.lineColor == "#000000" ? "1px solid black" : null }} onClick={() => { this.changeColor("#000000") }}>
                                                <Box component={'img'} src={ImagePaths.Black.default} height={'1.5vw'} width={'1.5vw'} />
                                            </Button>
                                            <Button className='Common_Btn_Min_Width' style={{ border: this.state.lineColor == "#F19C38" ? "1px solid black" : null }} onClick={() => { this.changeColor("#F19C38") }}>
                                                <Box component={'img'} src={ImagePaths.Orange.default} height={'1.5vw'} width={'1.5vw'} />
                                            </Button>
                                            <Button className='Common_Btn_Min_Width' style={{ border: this.state.lineColor == "#4A95EC" ? "1px solid black" : null }} onClick={() => { this.changeColor("#4A95EC") }}>
                                                <Box component={'img'} src={ImagePaths.Blue.default} height={'1.5vw'} width={'1.5vw'} />
                                            </Button>
                                            <Button className='Common_Btn_Min_Width' style={{ border: this.state.lineColor == "#67AC5B" ? "1px solid black" : null }} onClick={() => { this.changeColor("#67AC5B") }}>
                                                <Box component={'img'} src={ImagePaths.Green.default} height={'1.5vw'} width={'1.5vw'} />
                                            </Button>
                                            <Button className='Common_Btn_Min_Width' style={{ border: this.state.lineColor == "#E15241" ? "1px solid black" : null }} onClick={() => { this.changeColor("#E15241") }}>
                                                <Box component={'img'} src={ImagePaths.Red.default} height={'1.5vw'} width={'1.5vw'} />
                                            </Button>
                                            <Button className='Common_Btn_Min_Width' style={{ border: this.state.lineColor == "#74574A" ? "1px solid black" : null }} onClick={() => { this.changeColor("#74574A") }}>
                                                <Box component={'img'} src={ImagePaths.Brown.default} height={'1.5vw'} width={'1.5vw'} />
                                            </Button>
                                            <Button className='Common_Btn_Min_Width' style={{ border: this.state.lineColor == "#FFFFFF" ? "1px solid black" : null }} onClick={() => { this.changeColor("#FFFFFF") }}>
                                                <Box component={'img'} src={ImagePaths.White.default} height={'1.5vw'} width={'1.5vw'} />
                                            </Button>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: '1vw' }}>
                                            <Tooltip placement="top" title={'Spray'}>
                                                <IconButton
                                                    size='small'
                                                    color="primary"
                                                    onClick={() => {
                                                        this.setTool("spray")
                                                    }}
                                                >
                                                    <SprayIcon style={styles.control} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip placement="top" title={"Pencil"}>
                                                <IconButton
                                                    size='small'
                                                    color="primary"
                                                    onClick={() => this.setTool("pencil")}
                                                >
                                                    <CreateIcon
                                                        style={styles.control}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip placement="top" title={"Line Pencil"}>
                                                <IconButton
                                                    size='small' sx={{ marginTop: "0.5vw" }} color="primary"
                                                    onClick={() => { this.setTool("line") }}
                                                >
                                                    <LineIcon
                                                        style={styles.control}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip placement="top" title={"Rectangle"}>
                                                <IconButton
                                                    size='small'
                                                    color="primary"
                                                    onClick={() => { this.setTool("rectangle") }}
                                                >
                                                    <RectIcon
                                                        style={styles.control}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip placement="top" title={"Brush"}>
                                                <IconButton
                                                    size='small'
                                                    color="primary"
                                                    style={{ marginLeft: "-0.5vw" }}
                                                    onClick={() => { this.setTool("brush") }}
                                                >
                                                    <Box component={'img'} src={ImagePaths.Brush.default} height={'1.4vw'} />
                                                </IconButton>
                                            </Tooltip>

                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: '1vw' }}>
                                            <Tooltip placement="top" title={"Arrow Left"}>
                                                <IconButton
                                                    size='small'
                                                    color="primary"
                                                    style={{ marginLeft: "-0.5vw" }}
                                                    onClick={() => this.handleArrow(350, 200, 250, 200, "left")}
                                                >
                                                    <Box component={'img'} src={ImagePaths.ArrowLeft.default} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip placement="top" title={"Arrow Right"}>
                                                <IconButton
                                                    size='small'
                                                    color="primary"
                                                    style={{ marginLeft: "-0.5vw" }}
                                                    onClick={() => this.handleArrow(50, 200, 150, 200, "right")}
                                                >
                                                    <Box component={'img'} src={ImagePaths.ArrowRight.default} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip placement="top" title={"Arrow Down"}>
                                                <IconButton

                                                    color="primary"
                                                    style={{ marginLeft: "-0.5vw" }}
                                                    onClick={() => this.handleArrow(200, 50, 200, 150, "down")}
                                                >
                                                    <Box component={'img'} src={ImagePaths.ArrowDown.default} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip placement="top" title={"Arrow Up"}>
                                                <IconButton
                                                    size='small'
                                                    color="primary"
                                                    style={{ marginLeft: "-0.5vw" }}
                                                    onClick={() => this.handleArrow(200, 350, 200, 250, "up")}
                                                >
                                                    <Box component={'img'} src={ImagePaths.ArrowUp.default} />
                                                </IconButton>
                                            </Tooltip>


                                            <Tooltip placement="top" title={"Circle"}>
                                                <IconButton
                                                    size='small'
                                                    color="primary"
                                                    onClick={() => { this.setTool("circle") }}
                                                >
                                                    <CircleIcon
                                                        style={styles.control}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", justifyContent: "center" }}>
                                            <Tooltip placement="top" title={"Eraser"}>
                                                <Button
                                                    size='small'
                                                    color="primary"
                                                    onClick={() => { this.setTool("eraser") }}
                                                >
                                                    <Box component={'img'} src={ImagePaths.Eraser.default} height={'1.2vw'} width={'1.2vw'} />
                                                </Button>
                                            </Tooltip>
                                            <Tooltip placement="top" title={"Undo"}>
                                                <Button
                                                    size='small'
                                                    color="primary"
                                                    disabled={(this.undoStack?.length <= 1 || this.state.isUndo) ? true : false}
                                                    onClick={() => this.setState({ isUndo: true }, () => this.undo())}
                                                >
                                                    <UndoIcon
                                                        style={styles.control}
                                                    />
                                                </Button>
                                            </Tooltip>
                                            <Tooltip placement="top" title={"Redo"}>

                                                <Button
                                                    size='small'
                                                    color="primary"
                                                    disabled={(this.redoStack?.length === 0 || this.state.isRedo) ? true : false}
                                                    onClick={() => this.setState({ isRedo: true }, () => this.redo())}
                                                >
                                                    <RedoIcon
                                                        style={styles.control}
                                                    />
                                                </Button>
                                            </Tooltip>

                                            <div style={{ justifyContent: "center" }}>
                                                <TextField id="standard-basic" variant="standard" value={this.state.text} onChange={(e) => { this.setState({ text: e.target.value }) }} />
                                                <div style={{ fontSize: '0.8vw', color: "grey", marginTop: 5 }} >Enter text to add</div>
                                            </div>
                                            <Tooltip placement="top" title={"Add Text"}>

                                                <Button
                                                    size='small'
                                                    color="primary"
                                                    onClick={() => { this.setTool("text") }}
                                                >
                                                    <AddIcon
                                                        style={styles.control}
                                                    />
                                                </Button>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div>
                                        <TextField
                                            size='small'
                                            sx={{ width: '95%' }}
                                            inputProps={{ maxLength: 350 }}
                                            multiline={true}
                                            label={"Clinical Examination Notes"}
                                            rows={4}
                                            value={this.state.popupNotesData ? this.state.popupNotesData : ''}
                                            onChange={(e) => this.setState({ popupNotesData: e.target.value })}
                                        />
                                    </div>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1vw' }}>
                                    <Button
                                        size='small'
                                        variant='outlined'
                                        onClick={() => { this.clear() }}
                                    >{"Clear"}</Button>
                                    <Button
                                        size='small'
                                        variant='contained'
                                        onClick={() => this.handleSave()}
                                    >{"Save"}</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </Modal>
        )
    }

    render() {
        return (
            <ShowComponents onIntersection={this.apiCalls.bind(this)}>
                {this.state.isLoader ?
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '2vw' }}>
                        <CircularProgress color="primary" />
                    </Box>
                    :
                    <Box>
                        <Box component={'div'} p={'0.5vw'}>
                            <Box component={'div'} style={{ display: "flex", justifyContent: "center", width: '100%' }}>
                                {this.renderClinicExamImage()}
                            </Box>
                        </Box>
                        <Box component={'div'} p={'0.5vw'}>
                            <div style={{ display: 'flex', gap: '0.5vw' }}>
                                <TextField
                                    size='small'
                                    sx={{ width: '95%' }}
                                    inputProps={{ maxLength: 350 }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    multiline={true}
                                    label={"Clinical Examination Notes"}
                                    rows={4}
                                    value={this.state.notesData ? this.state.notesData : ''}
                                />
                            </div>
                        </Box>
                    </Box>}
                {this.renderExaminePopup()}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </ShowComponents >
        )
    }
}
