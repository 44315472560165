import React, { useState, useEffect } from 'react';
import {
  Autocomplete, Box, Button, Drawer, Stack, TextField, Tooltip,
  Typography, Grow, FormControlLabel, Checkbox
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { withTranslation } from 'react-i18next';
import { AmountsCard, AmountsCardWithCount } from '../../../../Components/CommonCards/CommonCards';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker';
import { CommonGridToolBarWithFilterText } from '../../../../Components/Common Components/CommonComponents';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DateTime } from 'luxon';
import { formatDate, AmountFormat, splitDate } from '../../../../Components/CommonFunctions/CommonFunctions';
import { Colors } from '../../../../Styles/Colors';
import { CurrencySymbol } from '../../../../Utility/Constants';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';

const OTSalesDetailed = (props) => {
  const [state, setState] = useState({
    salesDetailedData: [],
    page: 0,
    pageSize: 10,
    filterDatas: {
      'fromDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      'toDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      "dateIndex": 1,
      "patientName": '',
      "drugName": '',
      'schedule': '',
      'groupName': null,
      "isSheduleOnly": false,
      "days": null,
      'billFrom': '',
      'billTo': '',
      "dosageType": [],
    },
    filterOpen: false,
    totalAmount: 0,
    cgst: 0,
    sgst: 0,
    discountAmount: 0,
    netAmount: 0,
    isLoader: false,
    scheduled_Group: "",
    drug_schedule: "",
    ipCount: 0,
    showBox: false,
    ipNetAmt: 0,
    rowCount: 0,
    disableBtn: false,
    isErrorMsg: false,
    isErrorMsgText: "",
    triggerAction: null,
    groupData: [],
    dosageTypeList: [],
  })

  useEffect(() => {
    getSalesDetailedReportData();
    getGroupData();
    getDosageTypeListData();
  }, []);

  useEffect(() => {
    if (state.triggerAction) {
      getSalesDetailedReportData();
      setState((prevState) => ({
        ...prevState,
        triggerAction: null
      }))
    }
  }, [state.triggerAction])

  const generateUrl = () => {
    const {filterDatas} = state;
    const brandName = filterDatas?.drugName?.replaceAll('&', '%26');
    const group = filterDatas?.groupName?.individual_drug_master__group || '';

    return Serviceurls.OT_SALES_DETAILED_REPORT +
     `?from_date=${filterDatas.fromDate}&to_date=${filterDatas.toDate}&patient_name=${filterDatas.patientName}&drug_name=${brandName}&is_scheduled=${filterDatas.isSheduleOnly}&schedule=${filterDatas.schedule}&group_name=${group}&bill_no_from=${filterDatas.billFrom}&bill_no_to=${filterDatas.billTo}&dosage_type=${filterDatas.dosageType}`
  }

  const getSalesDetailedReportData = () => {
    try {
      LoaderFunction(true);
      const url = generateUrl();
      RestAPIService.getAll(url)
        .then((response) => {
          if (response.data.status === 'success') {
            LoaderFunction(false);
            const data = response.data.data;
            data?.result?.forEach((element, index) => {
              element.sno = index + 1;
              element.scheduled_Group = `${element?.drug_schedule ? element?.drug_schedule : '-'} / ${element?.group ? element?.group : '-'}`
            })
            setState((prevState) => ({
              ...prevState,
              salesDetailedData: data?.result,
              totalAmount: data?.total_amount,
              cgst: data?.total_cgst_amount,
              sgst: data?.total_sgst_amount,
              discountAmount: data?.total_discount_amount,
              netAmount: data?.total_net_amount,
              rowCount: data?.result?.length || 0,
              ipCount: data?.total_ip_count || 0,
              ipNetAmt: data?.total_ip_net_amount,
              roundOff: data?.total_round_off_amount,
            }))
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            LoaderFunction(false);
            errorMessage(error.response.data.message)
          } else {
            LoaderFunction(false);
            errorMessage(error.message)
          }
        })
    } catch (error) {
      LoaderFunction(false);
      errorMessage(error.message)
    }
  }

  const fetchAndSetState = async (url, stateKey) => {
    try {
      const response = await RestAPIService.getAll(url);
      if (response.data.status === 'success') {
        setState((prevState) => ({
          ...prevState,
          [stateKey]: response.data.data || [],
        }));
      }
    } catch (error) {
      const errorMsg = error?.response?.data?.message || error.message;
      errorMessage(errorMsg);
    }
  };

  const getGroupData = () => fetchAndSetState(Serviceurls.OT_GROUP_FILTER, 'groupData');
  const getDosageTypeListData = () => fetchAndSetState(Serviceurls.PHARMA_DOSAGE_TYPE_LIST, 'dosageTypeList');

  const getSalesDetailedPrint = () => {
    try {
      const url = generateUrl();
      setDisableBtn(true);
      RestAPIService.PrintPDF(`${url}&export=pdf`)
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          setDisableBtn(false);
        }).catch((error) => {
          if (error.response?.data?.message) {
            errorMessage(error.response?.data?.message);
          } else {
            errorMessage(error.message);
          }
          setDisableBtn(false);
        })
    } catch (error) {
      errorMessage(error.message)
      setDisableBtn(false);
    }
  }

  const getSalesDetailedExcel = () => {
    try {
      setDisableBtn(true);
      const url = generateUrl();
      RestAPIService.excelGet(`${url}&export=excel`)
        .then((response) => {
          if (response.data) {
            const pom = document.createElement('a');
            const csvContent = response.data; //here we load our csv data 

            const filename = response.headers["content-disposition"].split("filename=")[1]

            const blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' });
            const url = URL.createObjectURL(blob);
            pom.href = url;
            pom.setAttribute('download', filename ? filename : 'registry.xlsx');
            pom.click();
            setDisableBtn(false);
          }
        }).catch((error) => {
          if (error.response?.data?.message) {
            errorMessage(error.response?.data?.message);
          } else {
            errorMessage(error.message);
          }
          setDisableBtn(false);
        })
    } catch (error) {
      errorMessage(error.message);
      setDisableBtn(false);
    }
  }

  const setDisableBtn = (key) => {
    setState((prevState) => ({
      ...prevState,
      disableBtn: key
    }))
  }

  const LoaderFunction = (key) => {
    setState((prevState) => ({
      ...prevState,
      isLoader: key
    }))
  }

  const errorMessage = (message) => {
    setState((prevState) => ({
      ...prevState,
      isErrorMsg: true,
      isErrorMsgText: message
    }))
  }

  const msgClose = () => {
    setState((prevState) => ({
      ...prevState,
      isErrorMsg: false,
      isErrorMsgText: ""
    }))
  }

  const gridToolBar = () => {
    const TextArray = [
      { label: "From Date", value: formatDate(state.filterDatas.fromDate) },
      { label: "To Date", value: formatDate(state.filterDatas.toDate) },
    ]
    return (
      <Box>
        <CommonGridToolBarWithFilterText FilterTextArray={TextArray} noExport={true} />
      </Box>
    )
  }

  const closeFilter = (fromSrch) => {
    setState((prevState) => ({
      ...prevState,
      filterOpen: false,
      triggerAction: fromSrch ? "search" : null
    }))
  }

  const FilterDate = (from, to, index) => {
    setState((prevState) => ({
      ...prevState,
      filterDatas: {
        ...prevState.filterDatas,
        fromDate: from,
        toDate: to,
        dateIndex: index
      }
    }))
  }

  const onChangeHandler = (e) => {
    const { name, value } = e.target;

    let isValid = false;

    switch (name) {
      case 'billNumber':
      case 'billFrom':
      case 'billTo':
        isValid = CommonValidation.numberOnly(value) || value === '';
        break;
      case 'drugName':
        isValid = CommonValidation.ALPHA_NUM_SPLCHAR(value) || value === '';
        break;
      case 'schedule':
        isValid = true;
        break;
      default:
        isValid = CommonValidation.alphabetOnly(value) || value === '';
        break;
    }

    if (isValid) {
      setState((prevState) => ({
        ...prevState,
        filterDatas: {
          ...prevState.filterDatas,
          [name]: value
        }
      }));
    }
  };

  const renderTextField = (label, stateKey) => {
    return (
      <TextField
        size='small'
        autoComplete='off'
        name={stateKey}
        fullWidth
        value={state.filterDatas[stateKey] || ""}
        label={label}
        inputProps={{ emed_tid: label }}
        onChange={(e) => { onChangeHandler(e) }}
      />
    )
  }

  const renderFilterScreen = () => {
    const { filterDatas } = state;
    const { t } = props;

    return (
      <Box component={"div"} className='eMed_rts_filter_container'>
        <Box component={"div"} className='eMed_filter_Top'>
          <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
          <Button onClick={() => { closeFilter() }} className='eMed_usrconf_btn'>
            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
          </Button>
        </Box>
        <Box className='eMed_rts_middle'>
          <Box className='eMed_date_filter'>
            <CommonDatePicker
              FromDate={filterDatas.fromDate}
              ToDate={filterDatas.toDate}
              DateIndex={filterDatas.dateIndex}
              SelectedDates={FilterDate.bind(this)}
              HideAllMenu={true} />
          </Box>
          <Box display={"grid"} gridTemplateColumns="repeat(2, 1fr)" gap={"0.5dvw"}>
            {renderTextField("Patient Name", 'patientName')}
            {renderTextField("Drug Name", 'drugName')}
            {renderTextField('Schedule', 'schedule')}
            <Autocomplete
              size='small'
              fullWidth
              options={state.groupData}
              getOptionLabel={(option) => option?.individual_drug_master__group}
              value={state.filterDatas.groupName}
              onChange={(event, newValue) => {
                setState((prevState) => ({
                  ...prevState,
                  filterDatas: {
                    ...prevState.filterDatas,
                    groupName: newValue,
                  },
                }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('Select Group Name')}
                />
              )}
            />
            {renderTextField('Bill No From', 'billFrom')}
            {renderTextField('Bill No To', 'billTo')}
            <Autocomplete
              freeSolo
              size='small'
              fullWidth
              multiple
              limitTags={1}
              disableCloseOnSelect
              disablePortal
              options={state.dosageTypeList}
              getOptionLabel={(option) => option}
              value={filterDatas.dosageType}
              onFocus={() => setState((prevState) => ({ ...prevState, isTextFocused: true }))}
              onBlur={() => setState((prevState) => ({ ...prevState, isTextFocused: false }))}
              onChange={(e, value) => {
                if (value.length <= 7) {
                  setState((prevState) => ({
                    ...prevState,
                    filterDatas: {
                      ...prevState.filterDatas,
                      dosageType: value,
                    },
                  }));
                }
              }}
              renderInput={(params) =>
                <TextField
                  {...params}
                  label='Select Dosage Type'
                  helperText={state.isTextFocused && filterDatas.dosageType?.length === 7 ? 'Maximum Options Selected' : ''}
                  FormHelperTextProps={{
                    style: {
                      color: 'red',
                    },
                  }}
                />}
            />
            <FormControlLabel
              sx={{ margin: '0.1dvw' }}
              control={<Checkbox checked={filterDatas.isSheduleOnly}
                onChange={(e) => {
                  setState((prevState) => ({
                    ...prevState,
                    filterDatas: {
                      ...prevState.filterDatas,
                      isSheduleOnly: e.target.checked,
                    },
                  }));
                }} size='small' />} label="Schedule Only" />
          </Box>
        </Box>
        <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
          <Button variant='outlined' size="small"
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                filterDatas: {
                  ...prevState.filterDatas,
                  fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                  toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                  dateIndex: 1,
                  patientName: '',
                  drugName: '',
                  schedule: '',
                  groupName: null,
                  isSheduleOnly: false,
                  days: null,
                  billFrom: '',
                  billTo: '',
                  dosageType: [],
                },
                triggerAction: "clear"
              }));
            }}
            disabled={state.triggerAction === "clear"}
            id="eMed_Config_btn">{t("Clear")}</Button>
          <Button variant='contained' size="small"
            disabled={state.triggerAction === "search"}
            onClick={() => {
              if (state.filterDatas.billTo < state.filterDatas.billFrom) {
                errorMessage("Bill To No is less than Bill From No");
              } else {
                closeFilter(true);
              }
            }}
            id="eMed_Config_btn">{t("Search")}</Button>
        </Stack>
      </Box>
    )
  }

  const { t } = props;

  const cardList = [
    { label: "In Patient", amount: state.ipNetAmt, count: state.ipCount, test_id: "ot_rpt_saleDetail_ip" },
  ];

  const growCardConfig = [
    { label: "Taxable Amount", amount: state.totalAmount, id: 'ot_rpt_saleDetail_tax_amt' },
    { label: "CGST", amount: state.cgst, id: 'ot_rpt_saleDetail_cgst' },
    { label: "SGST", amount: state.sgst, id: 'ot_rpt_saleDetail_sgst' },
    { label: "Discount Amount", amount: state.discountAmount, id: 'ot_rpt_saleDetail_dis_amt' },
  ];

  const columns = [
    {
      field: 'invoice_number', headerName: "Bill No", flex: 0.066,
      renderCell: ({ row }) => (<Box component={'div'}>
        {row?.invoice_number?.length > 8 ? <Tooltip placement='top' title={row?.invoice_number} arrow>
          <div>{'...' + row?.invoice_number?.slice(10, 20)}</div></Tooltip> :
          row?.invoice_number ? row?.invoice_number : '-'}
      </Box>)
    },
    {
      field: 'invoice_date', headerName: "Bill Date", flex: 0.066, headerAlign: "center", align: "center",
      renderCell: ({ row }) => {
        let invoice_date = row?.invoice_date ? splitDate(row?.invoice_date) : "-";
        return (<Box component={'div'}>
          {invoice_date?.length > 8 ? <Tooltip placement='top' title={invoice_date} arrow>
            <div>{invoice_date?.slice(0, 8) + '...'}</div></Tooltip> :
            row?.invoice_date ? invoice_date : '-'}
        </Box>)
      }
    },
    {
      field: 'patient_name', headerName: "Patient Details", flex: 0.066,
      renderCell: ({ row }) => {
        let patientName = row?.patient_name ? row?.patient_name : "-"
        let accNumAndMob = (row?.uhid ? row?.uhid : "-") + "|" + (row?.mobile_no ? row?.mobile_no : "-")
        return (
          <Box component={'div'} display={'flex'} emed_tid='emed_phar_pat_dtls' >
            <Box component={'div'}>
              {patientName ? patientName?.length > 10 ?
                <Tooltip placement='top' title={patientName} arrow>
                  <Typography fontSize={'0.9dvw'} fontWeight={600}>{patientName?.slice(0, 10) + "..."}</Typography>
                </Tooltip> :
                <Typography fontSize={'0.9dvw'} fontWeight={600}>{patientName}</Typography> : '-'}
              {accNumAndMob ? accNumAndMob?.length > 10 ?
                <Tooltip placement='top' title={accNumAndMob} arrow>
                  <Typography fontSize={'0.8dvw'} color={Colors.grayShade}>{accNumAndMob?.slice(0, 10) + "..."}</Typography>
                </Tooltip> :
                <Typography fontSize={'0.8dvw'} color={Colors.grayShade}>{accNumAndMob}</Typography> : '-'}
            </Box>
          </Box>
        )
      }
    },
    {
      field: 'module_type', headerName: "Patient Type", flex: 0.066,
      renderCell: ({ row }) => (<Box component={'div'}>{row?.module_type ? row?.module_type : '-'}</Box>)
    },
    {
      field: 'doctor_name', headerName: "Doctor Name", flex: 0.066,
      renderCell: ({ row }) => (<Box component={'div'}>
        {row?.doctor_name?.length > 8 ? <Tooltip placement='top' title={row?.doctor_name} arrow>
          <div>{row?.doctor_name?.slice(0, 8) + '...'}</div></Tooltip> :
          row?.doctor_name ? row?.doctor_name : '-'}
      </Box>)
    },
    {
      field: 'brand_name', headerName: 'Brand Name', flex: 0.066,
      renderCell: ({ row }) => (<Box component={'div'}>
        {row?.brand_name?.length > 8 ? <Tooltip placement='top' title={row?.brand_name} arrow>
          <div>{row?.brand_name?.slice(0, 8) + '...'}</div></Tooltip> :
          row?.brand_name ? row?.brand_name : '-'}
      </Box>)
    },
    { field: 'dosage_strength', headerName: 'Dosage Strength', flex: 0.066, align: "center", headerAlign: "center" },
    {
      field: 'dosage_type', headerName: 'Dosage Type', flex: 0.066,
      renderCell: ({ row }) => (<Box component={'div'}>
        {row?.dosage_type?.length > 8 ? <Tooltip placement='top' title={row?.dosage_type} arrow>
          <div>{row?.dosage_type?.slice(0, 8) + '...'}</div></Tooltip> :
          row?.dosage_type ? row?.dosage_type : '-'}
      </Box>)
    },
    {
      field: 'drug_schedule', headerName: 'Schedule/Group', flex: 0.066,
      renderCell: ({ row }) => (
        (row.group || row.drug_schedule) ? row.scheduled_Group.length > 11 ?
          <Tooltip placement='top' title={row?.scheduled_Group} arrow>
            <div>{row?.scheduled_Group?.slice(0, 11) + "..."}</div></Tooltip> :
          row.scheduled_Group : "-")
    },
    {
      field: 'hsn_code', headerName: 'HSN Code', flex: 0.066, align: "center", headerAlign: "center",
      renderCell: ({ row }) => (<Box component={'div'}>
        {row?.hsn_code?.length > 5 ? <Tooltip placement='top' title={row?.hsn_code} arrow>
          <div>{row?.hsn_code?.slice(0, 8) + '...'}</div></Tooltip> :
          row?.hsn_code ? row?.hsn_code : '-'}
      </Box>)
    },
    { field: 'batch_no', headerName: 'Batch No', flex: 0.066, align: "center", headerAlign: "center" },
    {
      field: 'expiry_date', headerName: 'Expiry Date', flex: 0.066, headerAlign: "center", align: "center",
      renderCell: ({ row }) => (<Box component={'div'}>{row?.expiry_date ? (row?.expiry_date) : '-'}</Box>)
    },
    { field: 'quantity', headerName: 'Quantity', flex: 0.066, type: 'number' },
    {
      field: 'total_dicount_amount', headerName: `${"Discount Amount"} (${CurrencySymbol})`, flex: 0.066, type: 'number',
      renderCell: ({ row }) => (<Box>{AmountFormat(row?.total_dicount_amount || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
    },
    {
      field: 'total_net_amount', headerName: `${"Net Amount"} (${CurrencySymbol})`, flex: 0.066, type: 'number',
      renderCell: ({ row }) => (<Box>{AmountFormat(row?.total_net_amount || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
    },
  ]
  return (
    <Box component={'div'} className='eMed_rts_container'>
      <Box component={"div"} className='eMed_Pharma_rts_header'>
        <Box component={"div"} flex={0.8} display={'flex'} >
          <Box sx={{ cursor: 'pointer' }}
            onClick={() => { setState((prevState) => ({ ...prevState, showBox: !prevState.showBox, })) }}>
            {AmountsCardWithCount(t("Net Amount"), state.netAmount, false,
              state.showBox ? Colors.Primary : "white", true, state.showBox ? "white" :
              Colors.Primary, 'rec_recev_amt', "10dvw", state.rowCount, true)}
          </Box>
          {cardList?.map((detail) => (<div key={detail.test_id}>
            {AmountsCardWithCount(detail.label, detail.amount, false, "white", false, null, detail.test_id, "10dvw", detail.count, true)}
          </div>))}
          <Box component={'div'} sx={{ width: '30dvw', overflow: 'auto', marginLeft: '1dvw', height: "5.5dvw", display: 'flex', alignItems: 'center' }} >
            {state.showBox &&
              <Box sx={{ display: 'flex' }}>
                {growCardConfig.map(({ label, amount, id }, index) => (
                  <Grow
                    key={index}
                    in={state.showBox}
                    style={{ transformOrigin: '0 0 0' }}
                    {...(state.showBox ? { timeout: 500 + index * 500 } : {})}
                  >
                    {AmountsCard(label, amount, false, "white", false, Colors.Primary, id, "10dvw")}
                  </Grow>
                ))}
              </Box>}
          </Box>
        </Box>
        <Box component={"div"} flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5dvw'}>
          <Button emed_tid="ot_rpt_saleDetail_flt" className='eMed_usrconf_btn' onClick={() => { setState((prevState) => ({ ...prevState, filterOpen: true })) }}>
            <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
          </Button>
          <Button emed_tid="ot_rpt_saleDetail_print" className='eMed_usrconf_btn' disabled={state.disableBtn} onClick={getSalesDetailedPrint}>
            <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
          </Button>
          <Button emed_tid="ot_rpt_saleDetail_export" className='eMed_Pharma_rts_btn' disabled={state.disableBtn} onClick={getSalesDetailedExcel}>
            <img src={ImagePaths.ExportIcon.default} alt='export' className='eMed_action_img' />
          </Button>
        </Box>
      </Box>
      <Box component={'div'} className='eMed_Pharma_rts_tbl_card'>
        <DataGrid
          className='eMed_Pharma_table'
          rows={state.salesDetailedData}
          columns={columns}
          getRowId={(row) => row.sno}
          page={state.page}
          pageSize={state.pageSize}
          localeText={{
            toolbarColumns: "",
            toolbarDensity: "",
            toolbarExport: "",
            toolbarFilters: "",
            toolbarExportPrint: ""
          }}
          components={{
            Toolbar: gridToolBar,
            NoRowsOverlay: () => (
              <Stack className='eMed_conf_nodata'>
                {t("NoRecordsFound")}
              </Stack>
            )
          }}
          onPageChange={(newPage) => { setState((prevState) => ({ ...prevState, page: newPage })) }}
          onPageSizeChange={(newPageSize) => setState((prevState) => ({ ...prevState, pageSize: newPageSize }))}
          rowsPerPageOptions={[10, 20, 30]}
          pagination
          rowCount={state.salesDetailedData?.length}
          headerHeight={40}
          disableSelectionOnClick
          loading={state.isLoader}
        />
      </Box>
      <Drawer
        anchor={'right'}
        open={state.filterOpen}
        ModalProps={{ onBackdropClick: closeFilter }}
      >
        {renderFilterScreen()}
      </Drawer>
      {state.isErrorMsg && <ToastMsg
        severity={'error'}
        msg={state.isErrorMsgText}
        msgPop={msgClose.bind(this)}
      />}
    </Box>
  )
}

export default withTranslation()(OTSalesDetailed)