import { Box, Stack, TextField, Button, Tooltip, Typography, Autocomplete } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Colors } from '../../../Styles/Colors'
import { DataGrid } from '@mui/x-data-grid'
import { CommonDeleteButton, CommonEditButton, CommonGridToolBarWithFilterText } from '../../../Components/Common Components/CommonComponents'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { AmountFormat } from '../../../Components/CommonFunctions/CommonFunctions'
import { CurrencySymbol } from '../../../Utility/Constants'

const drug_Type = ['Consumable', 'Non Consumable']
const drugList = {
    drugType: 'Consumable',
    drugName: null,
    dosageType: '',
    strength: '',
    Qty: '',
    rateUnit: '',
    totalAmount: ''
}
export default function DrugPackageAdd() {
    const history = useHistory();
    const location = useLocation();
    const [packList, setpackList] = useState([]);
    const [DrugData, setDrugData] = useState([]);
    const [drugNameList, setdrugNameList] = useState([]);
    const [page, setpage] = useState(0);
    const [pageSize, setpageSize] = useState(10);
    const [packageName, setpackageName] = useState('');
    const [drugStates, setdrugStates] = useState(drugList);
    const [errorMsg, seterrorMsg] = useState(false);
    const [errorMsgText, seterrorMsgText] = useState('');
    const [successMsg, setsuccessMsg] = useState(false);
    const [successMsgText, setsuccessMsgText] = useState('');
    const [EditID, setEditID] = useState(null);
    const [EditListID, setEditListID] = useState(null);
    const [DeleteIds, setDeleteIds] = useState([]);
    const [isloader, setisloader] = useState(false);


    useEffect(() => {
        getDrugMaster()
    }, [])

    useEffect(() => {
        if (location.states) {
            let data = location.states.line_items
            setpackList(data)
            setEditListID(location.states.id)
            setpackageName(location.states.pack_name)
        }
    }, []);


    const errorMessage = (msg) => {
        seterrorMsg(true)
        seterrorMsgText(msg)
    }
    const successMessage = (msg) => {
        setsuccessMsg(true)
        setsuccessMsgText(msg)
    }

    const getDrugMaster = () => {
        try {
            setisloader(true)
            RestAPIService.getAll(Serviceurls.ASS_GET_DRUG_MASTER)
                .then((response) => {
                    if (response.data.status === 'success') {
                        const filData = response.data.data.filter((item) => drugStates.drugType === item.drug_type);
                        setDrugData(response.data.data || [])
                        setdrugNameList(filData || [])
                        setisloader(false)
                    }
                }).catch(e => {
                    setisloader(false)
                    if (e?.response?.data?.status === 'fail') {
                        errorMessage(e.response.data.message)
                    } else {
                        errorMessage(e.message)
                    }
                })
        } catch (e) {
            setisloader(false)
            errorMessage(e.message)
        }
    }

    const onChangeAuto = (e, val, name) => {
        switch (name) {
            case 'drugType':
                setdrugStates({ ...drugStates, [name]: val, drugName: null, dosageType: '', strength: '', rateUnit : "", Qty : "", totalAmount: '' });
                const filData = DrugData.filter((item) => val === item.drug_type);
                setdrugNameList(filData || []);
                break
            case 'drugName':
                setdrugStates({ ...drugStates, [name]: val, dosageType:val?.dosage_type || '', strength: val?.drug_strength || '', Qty: '1', rateUnit:val.amount || '', totalAmount: val.amount});
                break
            default:
                setdrugStates({ ...drugStates, [name]: val })
                break

        }
    }

    const onChangeHandler = (e, name) => {
        let value = e.target.value
        switch (name) {
            case 'Qty':
                if (value === "" || (CommonValidation.numberOnly(value) && value?.length <= 50)) {
                    setdrugStates({ ...drugStates, [name]: value, totalAmount : value ? +(+drugStates?.rateUnit * +value).toFixed(2) : '' })
                }
                break
            case 'rateUnit':
                if (value === "" || CommonValidation.RangeDecimalNumber(value)) {
                    setdrugStates({ ...drugStates, [name]: value, totalAmount : value ? +(+drugStates?.Qty * +value).toFixed(2) : '' })
                }
                break
            case 'packageName':
                if (value === "" || value.length <= 100) {
                    setpackageName(value)
                }
                break
            default:
                let number = CommonValidation.NumberWithDot(value);
                if (value === '' || number && value.length <= 10) {
                    setdrugStates({ ...drugStates, [name]: value })
                }
                break
        }
    }

    const textBox = (label, name, val, list, width) => {
        return (
            <Box>
                {name === 'drugType' || name === 'drugName' ?
                    <Autocomplete
                        size={'small'}
                        options={list}
                        name={name}
                        getOptionLabel={(option) => name === 'drugName' ? `${option.drug_name} | ${option.dosage_type} | ${option.drug_strength}` : option}
                        value={val}
                        onChange={(event, newValue) => { onChangeAuto(event, newValue, name) }}
                        renderInput={(params) => <TextField {...params} label={label} sx={{ width: width, marginRight: '1vw' }} />}
                    /> :
                    <TextField
                        size='small'
                        name={name}
                        disabled={(name === 'dosageType' || name === 'strength' || name === "totalAmount") ? true : false}
                        value={val ? val : ""}
                        id="drug-master"
                        sx={{ width: width, marginRight: '1vw' }}
                        label={label}
                        onChange={(e) => { onChangeHandler(e, name) }}
                        variant="outlined"
                    />}
            </Box>
        )
    }

    const tableView = () => {

        const gridToolBar = () => {
            return (
                <Box>
                    <CommonGridToolBarWithFilterText />
                </Box>

            )
        }

        packList?.forEach((element, index) => element.sno = index + 1)
        const columns = [
            {
                field: 'drug_type', headerName: 'Drug Type', flex: 0.12,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.drug_type ? params?.row?.drug_type : '-'}</Box>)

            },
            {
                field: 'drug_name', headerName: 'Drug Name', flex: 0.20,
                renderCell: (params) => (params.row.drug_name ? params.row.drug_name?.length > 15 ?
                    <Tooltip placement="top" title={params.row.drug_name}><Typography fontSize={'0.9vw'}>{params.row.drug_name?.slice(0, 15) + '...'}</Typography></Tooltip> :
                    <Typography fontSize={'0.9vw'}>{params.row.drug_name}</Typography> : '-')

            },
            {
                field: 'dosage_type', headerName: 'Dosage Type', flex: 0.15,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.dosage_type ? params?.row?.dosage_type : '-'}</Box>)

            },
            {
                field: 'drug_strength', headerName: 'Strength', flex: 0.12,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.drug_strength ? params?.row?.drug_strength : '-'}</Box>)

            },
            {
                field: 'quantity', headerName: 'Quantity', flex: 0.13,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.quantity ? params?.row?.quantity : '-'}</Box>)

            },
            {
                field: 'amount_per_unit', headerName: 'Rate Per Unit', flex: 0.13,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.amount_per_unit ? params?.row?.amount_per_unit : '-'}</Box>)

            },
            {
                field: 'amount', headerName: 'Amount', flex: 0.12,
                renderCell: (params) => (<Box component={'div'}>{AmountFormat(params?.row?.amount || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)

            },
            {
                field: 'action', headerName: 'Action', flex: 0.1,
                renderCell: (params) => (
                    <Stack direction="row" alignItems="center" spacing={4}>
                        <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={1}>
                            <CommonEditButton size="1.5vw" onClick={() => {
                                setdrugStates({
                                    ...drugStates,
                                    drugType: params.row.drug_type,
                                    dosageType: params.row.dosage_type,
                                    drugName: DrugData.find((list)=>(list?.id === params.row.drug_config_id)),
                                    strength: params.row.drug_strength,
                                    rateUnit: params.row.amount_per_unit,
                                    Qty: params.row.quantity,
                                    totalAmount: params.row.amount
                                })
                                const filData = DrugData.filter((item) => params.row.drug_type === item.drug_type);
                                setdrugNameList(filData || []);
                                setEditID(params.row.drug_config_id)
                            }} />
                            <CommonDeleteButton size="1.5vw" onClick={() => {
                                const deletedId = params.row.id ? params.row.id : params.row.sno;
                                setDeleteIds(prev => {
                                    if (!prev.includes(deletedId)) {
                                        return [...prev, deletedId];
                                    }
                                    return prev;
                                });
                                const updatedTbl = packList.filter(item => (item.id ? item.id !== deletedId : item.sno !== deletedId));
                                setpackList(updatedTbl);
                            }} />
                        </Stack>
                    </Stack>)

            },
        ]
        return (
            <Box sx={{ height: '54vh', marginTop: '.5vw' }}>
                <DataGrid
                    rows={packList}
                    columns={columns}
                    getRowId={(row) => row['sno']}
                    page={page}
                    pageSize={pageSize}
                    localeText={{
                        toolbarColumns: "",
                        toolbarDensity: "",
                        toolbarExport: "",
                        toolbarFilters: "",
                        toolbarExportPrint: ""
                    }}
                    components={{
                        Toolbar: gridToolBar,
                        NoRowsOverlay: () => (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                {'No Records To Be Shown'}
                            </Box>
                        )
                    }}
                    onPageChange={(newPage) => { setpage(newPage) }}
                    onPageSizeChange={(newPageSize) => { setpageSize(newPageSize) }}
                    rowsPerPageOptions={[10, 20, 30]}
                    pagination
                    headerHeight={40}
                    density='compact'
                    disableSelectionOnClick
                    loading={isloader}
                />
            </Box>
        )
    }

    const msgClose = () => {
        seterrorMsg(false);
        seterrorMsgText('');
        setsuccessMsg(false);
        setsuccessMsgText('');
    }

    const clearFunc = () => {
        setdrugStates({ ...drugList, ...{ drugType: drugStates?.drugType } });
        setEditID(null);
        const filData = DrugData.filter((item) => drugStates?.drugType === item.drug_type);
        setdrugNameList(filData || []);
    }

    const validation = () => {
        let duplicate = false
        for (let i = 0; i < packList.length; i++) {
            if (packList[i].drug_config_id === drugStates?.drugName?.id) {
                duplicate = true
                break
            } else {
                duplicate = false
            }
        }
        return duplicate
    }

    const addDrugDetails = () => {
        try{
            let duplicate = validation()
            if (!drugStates.drugName || !drugStates.drugType || !drugStates.dosageType || !drugStates.strength || !drugStates.rateUnit || !drugStates.Qty || !drugStates.totalAmount) {
                errorMessage('Please Enter All Drug Details')
            } else if (duplicate && !EditID) {
                errorMessage('Already Drug is Added')
            } else {
    
                let data = {
                    "drug_config_id": drugStates.drugName.id || EditID || null,
                    "quantity": +drugStates.Qty || 0,
                    "amount": +drugStates.totalAmount || 0,
                    "amount_per_unit": +drugStates.rateUnit || 0,
                    "drug_type": drugStates.drugType || 'Consumable',
                    "drug_name": drugStates.drugName.drug_name,
                    "dosage_type": drugStates.dosageType || '',
                    "drug_strength": drugStates.strength || '',
                }
                if (EditID) {
                    const editPackList = packList.map((item) =>
                        item.drug_config_id === EditID ? { ...item, ...data } : item
                    );
                    setpackList(editPackList);
    
                } else {
                    setpackList([...packList, data])
                }
                clearFunc()
        }
        }catch(e){
            errorMessage(e.message)
        }
    }
    const addPackageData = () => {
        try {
            setisloader(true)
            if (packageName && packList.length > 0) {
                let totalAmt = packList.reduce((sum, drug) => sum + parseFloat(drug.amount), 0);
                let data = {
                    "pack_name": packageName,
                    "pack_amount": totalAmt,
                    "line_items": packList,
                    "delete_ids": DeleteIds || [],
                }
                if (EditListID) {
                    data['id'] = EditListID
                }
                RestAPIService.create(data, Serviceurls.ASS_GET_DRUG_PACKAGE)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            setisloader(false)
                            setpackageName('')
                            history.push({ pathname: "/Settings/IPService/DrugPackage", states : { alertmsg : response.data.message ? response.data.message : ""} })
                            setEditListID(null)
                            setDeleteIds([])
                        }
                    }).catch(e => {
                        setisloader(false)
                        if (e?.response?.data?.status === 'fail') {
                            errorMessage(e.response.data.message)
                        } else {
                            errorMessage(e.message)
                        }
                    })
            } else {
                setisloader(false)
                if (!packageName) {
                    errorMessage('Please Enter the Package Name')
                } else {
                    errorMessage("At least one drug should be added.")
                }
            }
        } catch (e) {
            setisloader(false)
            errorMessage(e.message)
        }
    }

    return (
        <Box component={'div'} sx={{ height: '79.5vh', width: '94vw', backgroundColor: Colors.Background, padding: '.5vw' }}>
            <Box component={'div'} sx={{ height: '16.5vh', width: '93vw', backgroundColor: 'white', padding: '.6vw', }}>
                <Box component={'div'} sx={{ display: 'flex', flexDirection: 'row', }}>
                    <Box component={'div'} sx={{ display: 'flex', flexDirection: 'row', }}>
                        {textBox("Package Name", 'packageName', packageName, [] ,'18vw')}
                    </Box>
                    <Box sx={{ borderLeft: '1px solid lightgray', marginRight: '.8vw' }}></Box>
                    <Box component={'div'} sx={{ display: 'flex', flexDirection: 'row' }}>
                        {textBox("Drug Type", 'drugType', drugStates.drugType, drug_Type, '12vw')}
                        {textBox("Drug Name", 'drugName', drugStates.drugName, drugNameList, '20vw')}
                        {textBox("Dosage Type", 'dosageType', drugStates.dosageType,[], '12vw')}
                        {textBox("Strength", 'strength', drugStates.strength,[], '8vw')}
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', marginTop: '1vw', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex' }}>
                        {textBox("Quantity", 'Qty', drugStates.Qty, '6vw')}
                        {textBox("Rate / Unit", 'rateUnit', drugStates.rateUnit, [], '12vw')}
                        {textBox("Amount", 'totalAmount', drugStates.totalAmount, [], '12vw')}
                    </Box>
                    <Box sx={{ gap: '1vw' }}>
                        <Button size='small' variant='outlined' sx={{ textTransform: 'capitalize', width: '7vw' }} onClick={() => { clearFunc() }}>Clear</Button>
                        <Button size='small' variant='contained' sx={{ textTransform: 'capitalize', width: '7vw', marginLeft: '1vw' }} onClick={() => { addDrugDetails() }}>Add</Button>
                    </Box>
                </Box>
            </Box>
            <Box component={'div'} sx={{ height: '63vh', width: '93vw', marginTop: '.5vw', backgroundColor: 'white', }}>
                {tableView()}
                <Box component={'div'} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '.5vw' }}>
                    <Box sx={{ width: '9vw' }}>
                        <Button size='small' variant='contained' sx={{ textTransform: 'capitalize', width: '7vw', marginLeft: '1vw' }} disabled={isloader} onClick={() => { addPackageData() }}>Save</Button>
                    </Box>
                </Box>
            </Box>
            {errorMsg ?
                <ToastMsg
                    severity={'error'}
                    msg={errorMsgText}
                    msgPop={msgClose.bind(this)}
                />
                : null}
            {successMsg ?
                <ToastMsg
                    severity={'success'}
                    msg={successMsgText}
                    msgPop={msgClose.bind(this)}
                />
                : null}
        </Box>
    )
}
