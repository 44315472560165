import {
  TextField, Button, Stack, Typography, Autocomplete, FormControl, InputLabel, Select,
  MenuItem, Paper, List, ListItem, ListItemButton, ListItemText, Modal, OutlinedInput, Box, Tooltip,
  Grid, Tabs, Tab, InputAdornment,
  IconButton
} from '@mui/material'
import React, { Component } from 'react'
import "../PharmacyPurchase/PharmaPurchase.css";
import { withTranslation } from 'react-i18next';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DateTime } from "luxon";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CurrencySymbol } from "../../../Utility/Constants";
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import CircularProgress from '@mui/material/CircularProgress';
import { Expiry_period, gstPercentage } from '../../../Utility/Constants';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import {ImagePaths} from '../../../Utility/ImagePaths';

const DEFAULT_SET = {
  "brand_name": "",
  "generic_name": "",
  "stock_id": "",
  "dosage_type": "",
  "dosage_strength": "",
  "batch_no": "",
  "expiry_date": null,
  "schedule": "",
  "group": "",
  "hsn_code": "",
  "bar_code": "",
  "pack_of_drugs": "",
  "sales_unit": "",
  "buying_price": "",
  "free_quantity": "",
  "discount_percentage": "",
  "discount_amount": "",
  "sales_discount": "",
  "sales_discount_amount": "",
  "gst_percentage": 0,
  "mrp_per_pack": "",
  "paise":"",
  "round_off": "",
  "is_default_group": false,
  "is_default_schedule": false,
  "is_default_hsn": false,
  "is_default_expiryalt": false,
  "is_default_reorder": false,
  "is_default_boxno": false,

  "total_buying_price": 0,
  "mrp_per_quantity": 0,
  "stock_in_quantity": 0,
  "total_quantity": 0,

  "expiry_alert_period": "",
  "reorder_threshold": "",
  "physical_box_no": "",
  "description": "",
  "manufacturer_name": "",
  "recommended_by": "",
  // excess
  "total_gst_amount": 0,
  "drug_id": null,

  "isGenericSelected": false,
  // "previousPriceDetails": null,
  "sales_discount_for_bill": 0,

  // free qty states
  "free_qty_per_unit": 0,
  "free_no_of_unit": 0,
  "free_mrp_unit": 0,
  "free_sales_discount_bills": 0,
  "free_batch_no": "",
  "free_expiry_date": null,
  'is_free_quantity': false,
  'is_updated': false,
  'purchase_per_qty': 0
}
class PharmaISRequestDetailRight extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: { ...DEFAULT_SET },
      Tabvalue: "one",
      brand_suggestionList: {},
      EPRValue: 0,
      is_sales_amount_changed: false,
      is_discount_amount_changed: false,
      isDifferentBatchExist: false,
      existBatchList: [],
      selectedBatchNo: "",
      genericSuggestionList: [],
      purchase_status: "",
      EditData: {},
      open: false,
      openBatch: true,
      defaultReadOnly: false,
      isBrandLoader: false,
      isBrandEdit: false,
      is_group: false,
      PurchaseID: null,
      is_schedule: false,
      is_hsn: false,
      is_expiryalt: false,
      is_reorder: false,
      is_boxno: false,
      openBrand: false,
      openGSt: false,
      previousBatchNo: null,
      drug_from_dc: false,
      BatchDetailsSelected: false,
      FreeBatchDetailsSelected: false,
      BatchDetailsList: [],
      openAutoCom: false,
      openAutoCombrand: false,
      manufacturerName: [],
      drugMsid: null,
      rightEditableData: {},
      stockDetail: [],
      batchDetails: [],
      selectedIndex: null,
      po_line_item_id: null,
      isAllDisabled: false,
      isFromEdit : false,
      changedPaiseVal: true,
      changePaise : true,
      isInitiated : false,
      isEdit : false,
    }
    this.numberOfRefs = 3;
    this.textInputDropDownRefs = Array.from({ length: this.numberOfRefs }, () =>
      React.createRef()
    );
  }

  successMessage = (msg) => {
    this.props?.successMessage(msg);
  }
  errorMessage = (msg) => {
    this.props?.errorMessage(msg);
  }

  LoaderFunction = (key) => {
    this.props?.LoaderFunction(key);
  }

  brandLoader = (key) => {
    this.setState({
      isBrandLoader: key
    })
  }

  componentDidUpdate = (prevProps) => {
    if (this.props !== prevProps) {
      this.setState({
         isInitiated : this.props.isInitiated ? this.props.isInitiated : false,
         isEdit : this.props.isEdit ? this.props.isEdit : false,

      })
      if(this.props.isAllDisabled !==  this.state.isAllDisabled){
        this.setState({
            isAllDisabled:  this.props.isAllDisabled ? this.props.isAllDisabled : false
        })

    }
      if (this.props?.editData && Object.keys(this.props?.editData)?.length > 0 && JSON.stringify(this.props?.editData) !== JSON.stringify(this.state.rightEditableData)) {
        console.log("EditData:",this.props?.editData)
        this.setState({
          isFromEdit : true,
          rightEditableData: this.props?.editData,
          selectedIndex: this.props.selectedIndexData,
          isAllDisabled: this.props.isAllDisabled ? this.props.isAllDisabled : false,
          Tabvalue: this.props?.editData?.tabValue === "two" ? "two" : 'one'
        },
          () => {
             if(this.props?.editData?.batch_no || this.props?.editData?.free_batch_no){
              let { data, rightEditableData } = this.state
              data["brand_name"] = rightEditableData?.brand_name ? rightEditableData?.brand_name : ""
              data["generic_name"] = rightEditableData?.generic_name ? rightEditableData?.generic_name : ""
              data["dosage_type"] = rightEditableData?.dosage_type ? rightEditableData?.dosage_type :""
              data["dosage_strength"] = rightEditableData?.dosage_strength ? rightEditableData?.dosage_strength:""
              data["batch_no"] = rightEditableData?.batch_no ? rightEditableData?.batch_no:""
              data["free_batch_no"] = rightEditableData?.free_batch_no ? rightEditableData?.free_batch_no : ""
              data["expiry_date"] = rightEditableData?.expiry_date ? rightEditableData?.expiry_date : null
              data["gst_percentage"] = rightEditableData?.gst_percentage ? rightEditableData?.gst_percentage : 0
              data["pack_of_drugs"] = rightEditableData?.pack_of_drugs ? rightEditableData?.pack_of_drugs : ""
              data["sales_unit"] = rightEditableData?.sales_unit ? rightEditableData?.sales_unit : ""
              data["buying_price"] = rightEditableData?.buying_price ? rightEditableData?.buying_price : ""
              data["free_quantity"] = rightEditableData?.free_quantity ? rightEditableData?.free_quantity : ""
              data["discount_percentage"] = rightEditableData?.discount_percentage ? rightEditableData?.discount_percentage : ""
              data["discount_amount"] = rightEditableData?.discount_amount ? rightEditableData?.discount_amount : ""
              data["sales_discount"] = rightEditableData?.sales_discount ? rightEditableData?.sales_discount : ""
              data["sales_discount_amount"] = rightEditableData?.sales_discount_amount ? rightEditableData?.sales_discount_amount : ""
              data["mrp_per_pack"] = rightEditableData?.mrp_per_pack ? rightEditableData?.mrp_per_pack : ""
              data["sales_discount_for_bill"] = rightEditableData?.sales_discount_for_bill ? rightEditableData?.sales_discount_for_bill : 0
              data["purchase_per_qty"] = rightEditableData?.purchase_per_qty ? rightEditableData?.purchase_per_qty : 0
              data['drug_id'] = rightEditableData?.drug_id ? rightEditableData?.drug_id : rightEditableData?.drug_ms_id ? rightEditableData?.drug_ms_id : null
              data["stock_id"] = rightEditableData?.stock_id ? rightEditableData?.stock_id : ""
              data["total_buying_price"] = rightEditableData?.total_buying_price ? rightEditableData?.total_buying_price : 0
              data["cgst_amount"] = rightEditableData?.cgst_amount ? rightEditableData?.cgst_amount : ""
              data["cgst_percentage"] = rightEditableData?.cgst_percentage ? rightEditableData?.cgst_percentage : ""
              data["epr_value"] = rightEditableData?.epr_value ? rightEditableData?.epr_value : ""
              data["schedule"] = rightEditableData?.schedule ? rightEditableData?.schedule : ""
              data["group"] = rightEditableData?.group ? rightEditableData?.group : ""
              data["bar_code"] = rightEditableData?.bar_code ? rightEditableData?.bar_code : ""
              data["hsn_code"] = rightEditableData?.hsn_code ? rightEditableData?.hsn_code : ""
              data["total_gst_amount"] = rightEditableData?.total_gst_amount ? rightEditableData?.total_gst_amount : 0
              data["total_quantity"] = rightEditableData?.total_quantity ? rightEditableData?.total_quantity : 0
              data["mrp_per_quantity"] = rightEditableData?.mrp_per_quantity ? rightEditableData?.mrp_per_quantity : ""
              data["expiry_alert_period"] = rightEditableData?.expiry_alert_period ? rightEditableData?.expiry_alert_period : ""
              data["reorder_threshold"] = rightEditableData?.reorder_threshold ? rightEditableData?.reorder_threshold : ""
              data["physical_box_no"] = rightEditableData?.physical_box_no ? rightEditableData?.physical_box_no : ""
              data["description"] = rightEditableData?.instructions ? rightEditableData?.instructions : ""
              data["manufacturer_name"] = rightEditableData?.manufacturer_name ? rightEditableData?.manufacturer_name : ""
              data["recommended_by"] = rightEditableData?.recommended_by ? rightEditableData?.recommended_by : ""
              data["free_expiry_date"] = rightEditableData?.free_expiry_date ? rightEditableData?.free_expiry_date : ""
              data["free_qty_per_unit"] = rightEditableData?.free_qty_per_unit ? rightEditableData?.free_qty_per_unit : ""
              data["free_mrp_unit"] = rightEditableData?.free_mrp_unit ? rightEditableData?.free_mrp_unit : ""
              data["free_sales_discount_bills"] = rightEditableData?.free_sales_discount_bills ? rightEditableData?.free_sales_discount_bills : ""
              data["free_no_of_unit"] = rightEditableData?.free_no_of_unit ? rightEditableData?.free_no_of_unit : ""
              // data["paise"] = rightEditableData?.paise ? rightEditableData?.paise : ""
              // data["round_off"] = rightEditableData?.round_off ? rightEditableData?.round_off : ""
              this.setState({ 
                data,
                previousBatchNo: rightEditableData?.batch_no
              })
            } 
            this.getDrugDetails()
          }
        )
      }
      if (this.props?.selectedIndex && this.props?.selectedIndex !== this.state.selectedIndex) {
        this.setState({
          selectedIndex: this.props?.selectedIndex
        })
      }
    }
  }

  getDrugDetails = (DrugId) => {
    try {
      let drugMsid = this.state.rightEditableData?.drug_ms_id ? this.state.rightEditableData?.drug_ms_id : this.state.rightEditableData?.drug_id ? this.state.rightEditableData?.drug_id : DrugId ? DrugId  :  null
       this.brandLoader(true);
      RestAPIService.getAll(`${Serviceurls.INTERNALSALES_DRUGSTOCK_DETAILS}?drug_ms_id=${drugMsid ? drugMsid : null}`)
        .then(response => {
          if (response.data.status === "success") {
            this.brandLoader(false);
            if((!this.state.rightEditableData?.batch_no) && (!this.state.rightEditableData?.free_batch_no)){
              this.setState({
                data: response?.data?.data ? response?.data?.data : {},
              })
            }
            this.setState({
              po_line_item_id: response?.data?.data ? response?.data?.data : null,
              stockDetail: response?.data?.data?.stock_detail ? response?.data?.data?.stock_detail : []
            }, () => {
              let { data } = this.state
              let batchDetails = []
              this.state.stockDetail?.map((item) => {
                batchDetails?.push(item.batch_no)
              })
              if((!this.state.rightEditableData?.batch_no) && (!this.state.rightEditableData?.free_batch_no) ){
                data['gst_percentage'] = 0
                data['total_gst_amount'] = 0
                data['total_buying_price'] = 0
                data['mrp_per_quantity'] = 0
                data['total_quantity'] = 0
              }
              this.setState({
                batchDetails: batchDetails,
                data
              })
            })
          }
        })
        .catch(error => {
          if (error?.response?.data?.status === "fail") {
            this.brandLoader(false);
            this.errorMessage(error?.response?.data?.message);
          } else {
            this.brandLoader(false);
            this.errorMessage(error?.message);
          }
        });
    } catch (e) {
      this.errorMessage(e?.message);
    }

  }

  _getBrandSuggestionList = (brandName) => {
    try {
      this.brandLoader(true);
      // let searchbrandName = brandName?.replaceAll('&','%26')
      RestAPIService.getAll(`${Serviceurls.PURCHASE_BRAND_NAME_GET}?brand_name=${brandName ? encodeURIComponent(brandName) : ""}`)
        .then(response => {
          let data = response?.data;
          if (response?.data?.status === "success") {
            this.brandLoader(false);
            this.setState({
              brand_suggestionList: data?.drug_list ?  data?.drug_list :[]
            })
          }
        })
        .catch(error => {
          if (error.response?.data?.status === "fail") {
            this.brandLoader(false);
            this.errorMessage(error?.response?.data?.message);
          } else {
            this.brandLoader(false);
            this.errorMessage(error?.message);
          }
        });
    } catch (e) {
      this.errorMessage(e?.message);
    }
  }
  removeGenericDuplicates(data) {
    let uniqueArr = [], uniqueObj = {};
    for (let i in data) {
        let objDisplayName = data[i]['display_name'];
        uniqueObj[objDisplayName] = data[i];
    }
    for (let key in uniqueObj) {
        uniqueArr.push(uniqueObj[key]);
    }
    return uniqueArr;
}
  _getBrandDetails = () => {
    let { data } = this.state;
    // let brandName = data?.brand_name?.replaceAll('&','%26')
    try {
      RestAPIService.getAll(`${Serviceurls.PURCHASE_DRUG_DETAILS_GET}?brand_name=${data?.brand_name ? encodeURIComponent(data?.brand_name) : ""}`)
        .then(response => {
          if (response.data.status == "success") {
            let uniqueArr = this.removeGenericDuplicates(response.data.data);
            if (uniqueArr.length === 1) {
              data.drug_id = uniqueArr[0]["drug_id"];
              data.generic_name = uniqueArr[0]["generic_name"];
              data.dosage_type = uniqueArr[0]["dosage_type"];
              data.dosage_strength = uniqueArr[0]["dosage_strength"];
              data.manufacturer_name = uniqueArr[0]["manufacturer_name"];
              data.manufacturer_name = uniqueArr[0]["manufacturer_name"];
              data.description = uniqueArr[0]["instructions"];
              data.physical_box_no = uniqueArr[0]["physical_box_no"];
              data.expiry_alert_period = uniqueArr[0]["expiry_alert_period"] ? uniqueArr[0]["expiry_alert_period"] : "";
              data.reorder_threshold = uniqueArr[0]["reorder_threshold"];
              data.hsn_code = uniqueArr[0]["hsn_code"];
              data.bar_code = uniqueArr[0]["bar_code"];
              data.gst_percentage =  uniqueArr[0]["gst_percentage"];
               data.recommended_by = uniqueArr[0]["recommended_by"];
              data.schedule = uniqueArr[0]["schedule"];
              data.group = uniqueArr[0]["group"];
              data.is_default_group = uniqueArr[0]["group"] ? true : false;
              data.is_default_schedule = uniqueArr[0]["schedule"] ? true : false;
              data.is_default_hsn = uniqueArr[0]["hsn_code"] ? true : false;
              data.is_default_expiryalt = uniqueArr[0]["expiry_alert_period"] ? true : false;
              data.is_default_reorder = uniqueArr[0]["reorder_threshold"] ? true : false;
              data.is_default_boxno = uniqueArr[0]["physical_box_no"] ? true : false;
              this.setState({
                data,
                is_group: data.is_default_group,
                is_schedule: data.is_default_schedule,
                is_hsn: data.is_default_hsn,
                is_expiryalt: data.is_default_expiryalt,
                is_reorder: data.is_default_reorder,
                is_boxno: data.is_default_boxno,
              }, () => {
                this._calculateTransaction();
                this.getDrugDetails(data.drug_id)
              })
            } else {
              this.setState({
                genericSuggestionList: uniqueArr,
                isGenericSelected: true
              })
            }
          }
        })
        .catch(error => {
          if (error?.response?.data?.status == "fail") {
            this.errorMessage(error?.response?.data?.message);
          }
        });
    } catch (e) {
      this.errorMessage(e?.message);
    }
  }

  renderBrandSearch = () => {
    const { t } = this.props;
    let { data, brand_suggestionList, defaultReadOnly, isBrandEdit, drug_from_dc } = this.state;
    return (
      <div id="eMed_pro_date_picker">
        <Autocomplete
          open={this.state.openAutoCombrand}
          onOpen={() => { this.setState({ openAutoCombrand: true }) }}
          onClose={() => { this.setState({ openAutoCombrand: false }) }}
          disableClearable
          name={"brand_name"}
          value={data?.brand_name ? data?.brand_name : ""}
          sx={{ width: "30vw" }}
          disabled={(isBrandEdit || this.state.isAllDisabled) ? true : false}
          options={brand_suggestionList?.length > 0 ? brand_suggestionList : []}
          getOptionLabel={(option) => (typeof (option?.drug_name) === "string" ? option?.drug_name : option)}
          onChange={(event, newBrand) => {
            data.brand_name = newBrand?.drug_name ? newBrand?.drug_name: "";
            this.setState({
              data
            }, () => {
              this._getBrandDetails()
            })
          }}
          // disabled={  false}
          onInputChange={(event, newBrand) => {
            let previousBrand_name = JSON.stringify(data.brand_name);
            data.brand_name = newBrand;
            if (previousBrand_name != JSON.stringify(newBrand)) {
              data = { ...data, ...DEFAULT_SET }
            }
            this.setState({
              data,
              brand_suggestionList: [],

            })
            if (newBrand?.length > 2) {
              this._getBrandSuggestionList(newBrand);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("BrandName") + " *"}
              size="small"
              InputProps={{
                ...params.InputProps,
                emed_tid: "BrandName",
                endAdornment: this.state.isBrandLoader ?
                  <InputAdornment position="end"><CircularProgress size={15} /></InputAdornment>
                  : null,
              }}
            />
          )}
        />
      </div>
    )
  }
  getDoctorsList() {
    try {
      RestAPIService.getAll(Serviceurls.PHARMA_DOCTOR_LIST)
        .then((response) => {
          if (response.data.status === "success") {
            let DocList = []
            response.data?.data.forEach((item) => {
              item.displayKey = `${item?.name} | ${item?.doctor_reg_no}`
              DocList.push(item)
            })
            this.setState({
              DoctorsList: response.data?.data
            })
          }
          else {
            this.errorMessage(response.data?.message)
          }
        }).catch((error) => {
          this.errorMessage(error?.message)
        })
    }
    catch (e) {
      this.errorMessage(e?.message)
    }
  }

  getManufacturerList = (manufacturer) => {
    try {
      this.brandLoader(true);
      RestAPIService.getAll(`${Serviceurls.PURCHASE_MANUFACTURER_GET}?manufacturer_name=${manufacturer ? manufacturer : ""}`)
        .then(response => {
           if (response.data.status === "success") {
            this.brandLoader(false);
            this.setState({
              manufacturerName: response?.data?.data ? response?.data?.data : []
            })
          }
        })
        .catch(error => {
          if (error.response?.data.status === "fail") {
            this.brandLoader(false);
            this.errorMessage(error?.response?.data?.message);
          } else {
            this.brandLoader(false);
            this.errorMessage(error?.message);
          }
        });
    } catch (e) {
      this.errorMessage(e?.message);
    }
  }

  _calculateTransaction() {
    let { data, Tabvalue, is_sales_amount_changed, is_discount_amount_changed } = this.state;
    let pack_of_drugs = parseInt(data?.pack_of_drugs) || 0;
    let sales_unit = parseInt(data?.sales_unit) || 0;
    let buying_price = Tabvalue === "two" ? 0 : parseFloat(data?.buying_price) || 0;
    let free_quantity = +data?.free_quantity || 0;
    let discount_percentage = Tabvalue === "two" ? 0 : parseFloat(data?.discount_percentage) || 0;
    // let discount_amount = parseFloat(data.discount_amount) || 0;
    let discount_amount = (data?.discount_amount) || 0;
    let seller_disc_percent = Tabvalue === "two" ? 0 : parseFloat(data?.sales_discount) || 0;
    let seller_disc_amount = (data?.sales_discount_amount) || 0;
    let mrp_per_pack = parseFloat(data?.mrp_per_pack) || 0;
    let gst_percentage = Tabvalue === "two" ? 0 : parseFloat(data?.gst_percentage) || 0;

    //Free quantity data
    let free_qty_per_unit = parseInt(data?.free_qty_per_unit) || 0;
    let free_mrp_unit = parseFloat(data?.free_mrp_unit) || 0;

    // calc quantity
    let stock_in_quantity = Tabvalue === "two" ? 0 : pack_of_drugs * sales_unit;
    data.total_quantity = Tabvalue === "two" ? 0 : ((pack_of_drugs * sales_unit) + (pack_of_drugs * free_quantity));

    let total_buying_price = parseFloat((sales_unit * buying_price).toFixed(2));
    let mrp_per_quantity = pack_of_drugs ? (mrp_per_pack / pack_of_drugs).toFixed(2) : 0;
    let free_mrp_per_quantity = free_qty_per_unit ? (free_mrp_unit / free_qty_per_unit).toFixed(2) : 0;

    // normal discount
    if (is_discount_amount_changed) {
      // set discount percentage
      data.discount_percentage = total_buying_price && discount_amount > 0 ? +((100 * discount_amount) / total_buying_price).toFixed(2) : 0;
    } else {
      discount_amount = total_buying_price > 0 && discount_percentage > 0 ? +((total_buying_price * discount_percentage) / 100).toFixed(2) : 0;
    }

    // total buying price after discount deduction
    let total_buying_price_after_discount = total_buying_price - parseFloat(discount_amount);

    if (is_sales_amount_changed) {
      // set sales discount percent
      data.sales_discount = total_buying_price_after_discount && seller_disc_amount > 0 ? +((100 * seller_disc_amount) / total_buying_price_after_discount).toFixed(2) : 0;
    } else {
      // set sales discount amount
      seller_disc_amount = buying_price && seller_disc_percent > 0 ? +((total_buying_price_after_discount * seller_disc_percent) / 100).toFixed(2) : 0;
    }

    // set data
    data.sales_discount_amount = seller_disc_amount;
    data.discount_amount = discount_amount;
    data.stock_in_quantity = +stock_in_quantity;
    data.mrp_per_quantity = Tabvalue === "two" ? +free_mrp_per_quantity : +mrp_per_quantity;

    //total buying price calculation if gst not mentioned
    data.total_buying_price = +(parseFloat(total_buying_price.toFixed(2)) - parseFloat(discount_amount) - parseFloat(seller_disc_amount)).toFixed(2);

    //if gst percentage added
    if (gst_percentage === 0) {
      data.total_gst_amount = 0;
    } else {
      let taxable_amount = parseFloat(total_buying_price) - parseFloat(discount_amount) - parseFloat(seller_disc_amount);
      let gst_amount = ((taxable_amount * gst_percentage) / 100).toFixed(2)
      data.total_gst_amount = +gst_amount;

      // add GST to final buying price
      data.total_buying_price = +(parseFloat(data.total_buying_price) + parseFloat(gst_amount)).toFixed(2)

    }
    // EPRValue Calculation start
    var PurchaseRate = parseInt(buying_price)
    let GST = 1 + (parseFloat(gst_percentage) / 100)
    let PurcahseQty = parseInt(sales_unit)
    let PurcahseQtyandfreeQty = PurcahseQty + (free_quantity ? +free_quantity : 0)
    let Discount = (discount_percentage ? parseFloat(discount_percentage) : 0) + (seller_disc_percent ? parseFloat(seller_disc_percent) : 0)
    let TotalDis = 1 - (Discount / 100)
    let EPRTotalValue = (PurchaseRate * GST / mrp_per_pack) * (PurcahseQty / PurcahseQtyandfreeQty) * (TotalDis)

    this.setState({
      data,
      EPRValue: (EPRTotalValue && EPRTotalValue != Infinity) ? ((EPRTotalValue * 100).toFixed(2)) : 0
    })
  }
  
  multiplyBy2 = (value) => {
    return value * 2;
}

  handleGenericSuggestion = (list) => {
    let { data } = this.state;

    if (list) {
       data.drug_id = list?.drug_id ? list?.drug_id : null;
      data.generic_name = list?.generic_name ? list?.generic_name : "";
      data.dosage_type = list?.dosage_type ?  list?.dosage_type : "";
      data.dosage_strength = list?.dosage_strength ? list?.dosage_strength : null;
      data.manufacturer_name = list?.manufacturer_name ? list?.manufacturer_name :"";
      data.physical_box_no = list?.physical_box_no ? list?.physical_box_no: null;
      data.expiry_alert_period = list?.expiry_alert_period ? list?.expiry_alert_period : "";
      data.reorder_threshold = list?.reorder_threshold ? list?.reorder_threshold :"";
      data.hsn_code = list?.hsn_code ? list?.hsn_code:"";
      data.bar_code = list?.bar_code ? list?.bar_code: null;
      data.gst_percentage =   list.gst_percentage;
      data.manufacturer_name = list?.manufacturer_name ? list?.manufacturer_name:"";
      data.recommended_by = list?.recommended_by ? list?.recommended_by :"";
      data.schedule = list?.schedule ?  list?.schedule :"";
      data.group = list?.group ? list?.group:"";
      data.is_default_group = list?.group ? true : false;
      data.is_default_schedule = list?.schedule ? true : false;
      data.is_default_hsn = list?.hsn_code ? true : false;
      data.is_default_expiryalt = list?.expiry_alert_period ? true : false;
      data.is_default_reorder = list?.reorder_threshold ? true : false;
      data.is_default_boxno = list?.physical_box_no ? true : false;
      let freeQty = (data?.free_quantity && data?.pack_of_drugs) ? (+data?.free_quantity * +data?.pack_of_drugs) : data?.free_quantity;
      let QtyPerUnit = (data?.total_buying_price - (data?.cgst_amount * 2))
      let overAllQty = (data?.sales_unit * data?.pack_of_drugs) + freeQty
      let purPerQty = QtyPerUnit / overAllQty
      data.purchase_per_qty = purPerQty ? purPerQty : 0

      this.setState({
        data,
        isGenericSelected: false,
        is_group: data.is_default_group,
        is_schedule: data.is_default_schedule,
        is_hsn: data.is_default_hsn,
        is_expiryalt: data.is_default_expiryalt,
        is_reorder: data.is_default_reorder,
        is_boxno: data.is_default_boxno,
      }, () => {
        this.getDrugDetails(data.drug_id)
        this._calculateTransaction();
      })
    }
  }
  handleClick = (statekey, value) => {
    let states = this.state;
    states[statekey] = value;
    this.setState({
      states
    })
  }

  handleAdd = () => {
    
    
    let { data, Tabvalue } = this.state

    if(this.state.Tabvalue === "two"){
      data["sales_unit"] = ''
      data['pack_of_drugs'] = ''
    } else {   
    }

      data["epr_value"] = +this.state.EPRValue ? +this.state.EPRValue : null
      data["rate_per_unit"] = (+data.mrp_per_pack)
      data["po_line_item_id"] = this.state.rightEditableData?.id ? +this.state.rightEditableData?.id : this.state.rightEditableData?.po_line_item_id ? +this.state.rightEditableData?.po_line_item_id : null
      data["quantity"] = this.state.Tabvalue === "two" ? 0 : (+this.state.rightEditableData.quantity)
    this.setState({ data, isEdit : false }, () => {
      let isValid = this._validateData(data);
      if (isValid) {

        this.props?.sendToList(data, false, this.state.Tabvalue, this.state.selectedIndex,this.state.isFromEdit,this.state.isEdit)
         this.handleClear(true);
      }

    })
  }

  _validateData = (data) => {
    const { t } = this.props
    let isValid = true;
    let msg = "";
    let qty = +((+data?.pack_of_drugs) * (+data?.sales_unit))?.toFixed(2)
    let freeQty = +((+data?.pack_of_drugs) * (+data?.free_quantity))?.toFixed(2)
    let totalQty = qty + freeQty
 
    if (!data?.brand_name) { isValid = false; msg = "EnterBrandname"; }
    else if (!data?.generic_name) { isValid = false; msg = "EnterGenericname"; }
    else if (!data?.dosage_type) { isValid = false; msg = "EnterDosagetype"; }
    else if (this.state.Tabvalue === "one") {
      if (!data?.batch_no) { isValid = false; msg = "EnterBatchnumber"; }
      else if (!data?.expiry_date) { isValid = false; msg = "Enterexpirydate"; }
      else if (!data?.pack_of_drugs) { isValid = false; msg = "EnterQtyPerUnit"; }
      else if (!data?.sales_unit) { isValid = false; msg = "EnterNoOfUnit"; }
      else if (!data?.mrp_per_pack) { isValid = false; msg = "EnterMRP"; }
      else if (!data?.buying_price) { isValid = false; msg = "Enterbuyingprice" }
      else if (totalQty < data?.stock_in_quantity) {
        isValid = false
        msg = "requested qty is greater than stock in qty"
      }
    }
    // free quantity with different batch no
    else if (this.state.Tabvalue === "two") {
      if (!data?.free_batch_no) { isValid = false; msg = "EnterBatchnumber"; }
      else if (!data?.free_expiry_date) { isValid = false; msg = "Enterexpirydate"; }
      else if (!data?.free_qty_per_unit) { isValid = false; msg = "EnterQtyPerUnit"; }
      else if (!data?.free_no_of_unit) { isValid = false; msg = "EnterNoOfUnit"; }
      else if (!data?.free_mrp_unit) { isValid = false; msg = "EnterMRP"; }
      else if (this.state.previousBatchNo === data?.free_batch_no){
          isValid = false; msg = "Same Batch No Already Exist"
      }
    }

    if (!isValid) {
      this.errorMessage(t(msg))
    }
    return isValid;
  }


  renderDatePicker = (label, statekey, openMenu, width, mandatory = false, refIndex) => {
    const { t } = this.props;
    let states = this.state;
    let { data, defaultReadOnly, drug_from_dc, BatchDetailsSelected, FreeBatchDetailsSelected } = this.state;
    return (
      <div id="eMed_pharmadrctpo_txtbox">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            ref={this.textInputDropDownRefs[refIndex]}
            open={states[openMenu]}
            disabled={true}
            onOpen={() => this.handleClick(openMenu, true)}
            minDate={new Date().setMonth(new Date().getMonth() + 1)}
            onClose={() => this.handleClick(openMenu, false)}
            views={['year', 'month']}
            inputFormat="MM-yyyy"
            label={t(label)}
            name={statekey}
            value={data[statekey] ? data[statekey] : null}
            autoComplete="off"
            onChange={
              (value) => {
                data[statekey] = DateTime?.fromJSDate(value)?.toFormat("yyyy-MM-dd")
                this.setState({
                  data
                })
              }
            }
            renderInput={(params) => <TextField
              required={mandatory}
              onKeyDown={(e) => e.preventDefault()}
              autoComplete="off"
              size="small"
              sx={{ width: width ? width : "14.5vw" }}
              {...params}
              inputProps={{
                ...params.inputProps,
                emed_tid: label,
              }}
              onClick={() => this.handleClick(openMenu, true)} />}
          />
        </LocalizationProvider>
      </div>
    )
  }
  handleChange = (e) => {
    let { name, value } = e.target;
    let { data } = this.state;
    let states = this.state;
    // validation
    let isNum = CommonValidation.numberOnly(value);
    let isDecimal = CommonValidation.DecimalNumber(value);
    let isNumWithDot = CommonValidation.NumberWithDot(value);
    let isNumDotThree = CommonValidation.NumberWithDotThree(value)
    let totalPrice = data?.buying_price * data?.sales_unit
    let salesTotalPrice = (data?.buying_price * data?.sales_unit) - data?.discount_amount
    let paiseValue = 0
    let roundOffValueIncrement = 0 
    let roundOffValueDecrement = 0
    if (name === "paise" || name === "mrp_per_pack" || name === "round_off") {
      let mrpString = data["mrp_per_pack"]?.toString();
      // mrpValue = mrpString?.includes('.') ? mrpString?.split('.')[0] : mrpString
      let mrpValue =   mrpString
       paiseValue = +e.target.value ? +e.target.value/100 : 0
      //  paiseValue = +paiseValue.toFixed()
      roundOffValueIncrement =  (+mrpValue) + (paiseValue)
      roundOffValueDecrement =  (+mrpValue) - ((paiseValue))
    } 

    if (name === "sales_unit" || name === "free_quantity" || name === "reorder_threshold" || name === "pack_of_drugs" || name === "free_qty_per_unit" || name === "free_no_of_unit") {
      if (value === "" || (isNum && value > 0 && value.length <= 7)) {
        data[name] = +value?.trimStart();
      }
    } else if (name === "sales_discount_for_bill" || name === "free_sales_discount_bills") {
      if (value === "" || (isDecimal && +value <= 100)) {
        data[name] = value?.trimStart();
      }
    } else if (name === "mrp_per_pack" || name === "free_mrp_unit") {
      if (value === "" || (isNumWithDot && value > 0)) {
        data[name] = value?.trimStart();
      }
    }else if(name === "paise"){
      data[name] = value?.trimStart();
      data["round_off"] = this.state.changePaise ? roundOffValueIncrement.toFixed(2) : roundOffValueDecrement.toFixed(2)
    }
     else if (name === "buying_price") {
      if (value === "" || (isNumDotThree && value)) {
        data[name] = value?.trimStart();
      }
    } else if (name === "discount_percentage") {
      if (value === "" || (isDecimal && +value <= 100)) {
        data.discount_percentage = value?.trimStart();
        states.is_discount_amount_changed = false;
      }
    } else if (name === "discount_amount") {
      if (value === "" || (isNumWithDot && value <= totalPrice)) {
        data.discount_amount = value?.trimStart();
        states.is_discount_amount_changed = true;
      }
    } else if (name === "sales_discount") {
      if (value === "" || (isDecimal && +value <= 100)) {
        data.sales_discount = value?.trimStart();
        states.is_sales_amount_changed = false;
      }
    } else if (name === "sales_discount_amount") {
      if (value === "" || (isNumWithDot && value <= salesTotalPrice)) {
        data.sales_discount_amount = value?.trimStart();
        states.is_sales_amount_changed = true;
      }
    } else if (name === "physical_box_no") {
      if (value.length <= 50) {
        data.physical_box_no = value;
      }
    } else {
      data[name] = value
    }

    this.setState({
      data,
      states
    }, () => {
      let trigger_calc_keys = ['pack_of_drugs', 'sales_unit', 'buying_price', 'free_quantity', 'discount_percentage', 'discount_amount', 'sales_discount', 'sales_discount_amount', 'mrp_per_pack', 'gst_percentage', 'free_no_of_unit'];
      let sameInList = trigger_calc_keys?.includes(name);
      if (sameInList) {
        this._calculateTransaction();
      }
      let freeQty = (data?.free_quantity && data?.pack_of_drugs) ? (+data?.free_quantity * +data?.pack_of_drugs) : (data?.free_quantity ? +data?.free_quantity : 0);
      let QtyPerUnit = (data?.total_buying_price - (data?.total_gst_amount))
      let overAllQty = (data?.sales_unit * data?.pack_of_drugs) + +freeQty
      let purPerQty = QtyPerUnit / overAllQty
      data.purchase_per_qty = +purPerQty?.toFixed(2)
      this.setState({ data })
    })

  }

  renderTextBox(label, statekey, mandatory = false, noshow = false, width,margin) {
    const { t } = this.props
    let { data, genericSuggestionList, isGenericSelected, defaultReadOnly } = this.state
    let mrpValue = data["mrp_per_pack"]
    let paiseValue = 0
    let roundOffValueIncrement = 0 
    let roundOffValueDecrement = 0
    if (statekey === "paise" || statekey === "mrp_per_pack" || statekey === "round_off") {
      let mrpString = data["mrp_per_pack"]?.toString();
      // mrpValue = mrpString?.includes('.') ? mrpString?.split('.')[0] : mrpString
      let mrpValue =   mrpString
       paiseValue = mrpString?.includes('.') ? mrpString?.split('.')[1] : "00"
      //  paiseValue = +paiseValue.toFixed()
      roundOffValueIncrement =  (+mrpValue) + (+(data["paise"]/100))
      roundOffValueDecrement =  (+mrpValue) - (+(data["paise"]/100) )
    }
    return (
      <div id="eMed_pharmadrctpo_txtbox" style={{margin: (statekey === "paise" || statekey === "mrp_per_pack" || statekey === "round_off") ? "0.7vw 0vw" : '0.7vw'}}>
        {
          statekey === "buying_price"   ?
               <TextField
                label={t(label) + "*"}
                sx={{ width: width ? width : "14.5vw"}}
                size="small"
                autoComplete="off"
                inputProps={{ emed_tid: label }}
                disabled={this.state.isAllDisabled ? true : false}
                name={statekey}
                value={data[statekey] ? data[statekey] : ""}
                onChange={this.handleChange}
              />
             :statekey === "mrp_per_pack" || statekey === "paise"||statekey === "round_off"?
            <TextField
            label={t(label)}
            required={mandatory || statekey === "mrp_per_pack" ? true : false}
            sx={{ width: width ? width : "14.5vw"}}
            disabled={(noshow || this.state.isAllDisabled || defaultReadOnly) && statekey !== "paise"}
            inputProps={{ emed_tid: label }}
            size="small"
            autoComplete="off"
            name={statekey}
            InputProps={{
              endAdornment: statekey === "paise" ?
                  <InputAdornment sx={{ margin: '-1vw' }}>
                      <IconButton sx={{ width: '1.3vw', height: '1vw' }} onClick={() => this.setState({changePaise : !this.state.changePaise},()=>{
                        let changedPaiseVal = this.state.changePaise ?  roundOffValueIncrement: roundOffValueDecrement  
                        data["round_off"] = changedPaiseVal.toFixed(2)
                        this.setState({data})
                      } 
                      )}>
                          { this.state.changePaise ? <img sx={{ width: '1vw', height: '1vw' }} src={ImagePaths.PlusIcon.default} alt="Plus Icon" /> : <img sx={{ width: '1vw', height: '1vw' }} src={ImagePaths.MinusIcon.default} alt="Minus Icon" />}
                      </IconButton>
                  </InputAdornment> : null
          }}
            value={statekey === "mrp_per_pack" ? (mrpValue ? mrpValue : '') :  data[statekey] ? data[statekey] : ""}
            onChange={this.handleChange}
          />:
              <TextField
                label={t(label)}
                required={mandatory || statekey === "mrp_per_pack" ? true : false}
                sx={{ width: width ? width : "14.5vw" }}
                disabled={(noshow || this.state.isAllDisabled || defaultReadOnly) || (statekey === "generic_name" && !isGenericSelected)}
                inputProps={{ emed_tid: label }}
                size="small"
                autoComplete="off"
                name={statekey}
                value={data[statekey] ? data[statekey] : ""}
                onChange={this.handleChange}
              />
        }
        {statekey === "generic_name" && isGenericSelected && genericSuggestionList.length > 1 ?
          <Paper elevation={2} sx={{ maxHeight: "30vw", overflowY: "scroll" }}>
            <List
              sx={{ width: "100%", bgcolor: 'background.paper' }}
            >
              {genericSuggestionList && genericSuggestionList.length > 0 ? genericSuggestionList?.map((item, index) => {
                return (<ListItem  disablePadding key={index} >
                  <ListItemButton  emed_tid="drt_gen_sug" onClick={() => { this.handleGenericSuggestion(item) }}>
                    <ListItemText  primary={item.display_name} />
                  </ListItemButton>
                </ListItem>)
              }) : null}
            </List>
          </Paper> : null}
      </div>
    )
  }

  renderDropDown = (label, statekey, showkey, valueKey, Data = [], width, mandatory = false, noShow = false, openMenu, refIndex) => {
    let { data, defaultReadOnly, drug_from_dc, BatchDetailsSelected } = this.state;
    let states = this.state
    const { t } = this.props
     return (
      <div id="eMed_pharmadrctpo_txtbox">
        {statekey === "expiry_alert_period" ?
          <FormControl sx={{ width: width ? width : "14.5vw" }} size="small">
            <InputLabel>{t(label)}</InputLabel>
            <Select
              value={data[statekey] ? data[statekey] : ""}
              name={statekey}
              label={t(label)}
               disabled={(this.state.isAllDisabled || noShow) ? true : false}
              // disabled={defaultReadOnly ? true : false}
              onChange={(e) => {
                data[statekey] = e.target.value;
                this.setState({ data })
              }}
              inputProps={{ emed_tid: label }}
            >
              {
                Data.map((list, index) => (
                  <MenuItem emed_tid="drt_exp_period" key={index} value={list[valueKey]} >{list[showkey]}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
          :
          ((statekey === "manufacturer_name") || (statekey === "recommended_by")) ?
            <Autocomplete
              freeSolo
              disableClearable
              value={data[statekey] ? data[statekey] : ""}
              // disabled={this.state.isAllDisabled  ? true :false}
              options={Data}
              disabled={(this.state.isAllDisabled || noShow) ? true : false}
              getOptionLabel={(option) => (typeof (option[showkey]) === "string" ? option[showkey] : option?.manufacturer_name ? option?.manufacturer_name : option?.name ? option?.name : option)}
              sx={{ width: width ? width : "14.5vw" }}
              onChange={(e, newValue) => {
                if (newValue) {
                  if (statekey === "recommended_by") {
                    data[statekey] = newValue?.name
                  } else {
                    data[statekey] = newValue?.manufacturer_name
                  }
                  this.setState({
                    data
                  })
                }
              }}
              renderInput={(params) => <TextField
                {...params}
                label={t(label)}
                required={mandatory}
                placeholder={statekey === "recommended_by" ? 'Enter recommended Doctor' : 'Enter Manufacture Name'}
                onChange={(e, newValue) => {
                  data[statekey] = e.target.value
                  if (newValue)
                    this.setState({
                      data
                    })
                }}
                autoComplete='off'
                size="small"
                InputProps={{ ...params.InputProps, emed_tid: label }} />}
            />
            :
            ((statekey === "manufacturer_name") || (statekey === "recommended_by")) ?
              <Autocomplete
                disableClearable
                value={data[statekey] ? data[statekey] : ""}
                options={Data}
                disabled={(this.state.isAllDisabled || noShow) ? true : false}
                getOptionLabel={(option) => (typeof (option[showkey]) === "string" ? option[showkey] : option?.manufacturer_name ? option?.manufacturer_name : option?.name ? option?.name : option)}
                sx={{ width: width ? width : "14.5vw" }}
                onChange={(e, newValue) => {
                  if (newValue) {
                    if (statekey === "recommended_by") {
                      data[statekey] = newValue?.name
                    } else {
                      data[statekey] = newValue?.manufacturer_name
                    }
                    this.setState({
                      data
                    })
                  }
                }}
                onInputChange={(event, newValue) => {
                  if (statekey === "recommended_by") {
                    if (newValue?.length > 3) { this.getDoctorsList(newValue) }
                  }
                  else if (statekey === "manufacturer_name") {
                    if (newValue?.length > 3 || newValue?.length === 0) { this.getManufacturerList(newValue) }

                  }
                }}
                renderInput={(params) => <TextField
                  {...params}
                  label={t(label)}
                  required={mandatory}
                  placeholder={statekey === "recommended_by" ? 'Enter recommended Doctor' : 'Enter Manufacture Name'}
                  onChange={(e, newValue) => {
                    data[statekey] = e.target.value
                    if (newValue)
                      this.setState({
                        data
                      })
                  }}
                  autoComplete='off'
                  size="small"
                  InputProps={{ ...params.InputProps, emed_tid: label }} />}
              /> :

              (statekey === "batch_no" || statekey === "free_batch_no") ?
                <Autocomplete
                  disableClearable
                  value={data[statekey]}
                  options={Data?.length >0 ? Data  : []}
                  disabled={this.state.isAllDisabled ? true : false}
                  getOptionLabel={(option) => (typeof (option[showkey]) === "string" ? option[showkey] : option )}
                  sx={{ width: width ? width : "14.5vw" }}
                  onChange={(e, newValue) => {
                    if (newValue) {                
                        data[statekey] = newValue?.batch_no
                        data["expiry_date"] = newValue?.expiry_date
                        data["buying_price"] = newValue?.buying_price
                        data["pack_of_drugs"] = newValue?.pack_of_drugs
                        data["mrp_per_pack"] = newValue?.mrp_per_pack
                        data["gst_percentage"] = newValue?.gst_percentage
                        data["expiry_alert_period"] = newValue?.expiry_alert_period
                        data["stock_id"] = newValue?.id
                        data["schedule"] = newValue?.schedule
                        data["group"] = newValue?.group
                        data["hsn_code"] = newValue?.hsn_code
                        data["recommended_by"] = newValue?.recommended_by
                        data["manufacturer_name"] = newValue?.manufacturer_name
                        data["discount_percentage"] = newValue?.discount_percentage
                        data["sales_discount"] = newValue?.sales_discount
                        data["reorder_threshold"] = newValue?.reorder_threshold
                        data["physical_box_no"] = newValue?.physical_box_no
                        data["epr_value"] = newValue?.epr_value
                        data["description"] = newValue?.instructions
                        data["sales_discount_for_bill"] = newValue?.sales_discount_for_bill

                        if(this.state.Tabvalue === "two"){
                          data["free_expiry_date"] = newValue?.expiry_date
                          data["free_qty_per_unit"] = newValue?.pack_of_drugs
                          data["free_mrp_unit"] = newValue?.mrp_per_pack
                          data["free_sales_discount_bills"] = newValue?.sales_discount_for_bill
                          data["free_no_of_unit"] = newValue?.free_no_of_unit
                        }
                      this.setState({
                        data
                      })
                    }
                  }}
                  renderOption={(props, item) => (
                    <Box>
                      {props["data-option-index"] === 0 ?
                        <Box className="eMed_App_Doctor_table_head" sx={{ width: "14.5vw" }}>
                          <Typography width={"60%"} fontWeight={'600'} fontSize={"0.8vw"}>Batch No</Typography>
                          {/* <Typography width={"25%"} fontWeight={'600'} fontSize={"0.8vw"}>Expiry Date</Typography> */}
                          <Typography width={"40%"} fontWeight={'600'} fontSize={"0.8vw"}>Available Qty</Typography>
                        </Box> : null}
                      <Box component="li" className='eMed_App_Doctor_table_data' {...props} sx={{ width: '14.5vw' }}>
                        <div style={{ width: "60%" }}>{item?.batch_no.length > 13 ? <Tooltip placement="top" title={item?.batch_no} arrow><div>{item?.batch_no?.slice(0, 10) + "..."}</div></Tooltip> : item?.batch_no ? item?.batch_no : "-"}</div>
                        {/* <Typography width={"25%"} textAlign={"center"} fontWeight={'600'} fontSize={"0.8vw"}>{item.expiry_date}</Typography> */}
                        <Typography width={"40%"} textAlign={"center"} fontWeight={'600'} fontSize={"0.8vw"}>{item.stock_in_quantity}</Typography>
                      </Box>
                    </Box>)}
                  renderInput={(params) => <TextField
                    {...params}
                    label={t(label)}
                    required={mandatory}
                    onChange={(e, newValue) => {
                    }}
                    autoComplete='off'
                    size="small"
                    InputProps={{ ...params.InputProps, emed_tid: label }} />}
                />

                :
                <Autocomplete
                  open={this.state[openMenu] || false}
                  onOpen={() => this.handleClick(openMenu, true)}
                  onClose={() => this.handleClick(openMenu, false)}
                  anchorEl={this.textInputDropDownRefs[refIndex]}
                  disableClearable
                  value={data[statekey] ? data[statekey].toString() : ""}
                  options={Data}
                  disabled={(this.state.isAllDisabled || noShow) ? true : false}
                  getOptionLabel={(option) => (typeof (option[showkey]) === "string" ? option[showkey] : option)}
                  sx={{ width: width ? width : "14.5vw" }}
                  onChange={(e, newValue) => {
                    data[statekey] = newValue?.value ? +newValue?.value : newValue
                    if (newValue)
                      this.setState({
                        data
                      }, () => {
                        if (statekey === "gst_percentage") {
                          this._calculateTransaction();
                        }
                      })
                  }}
                  renderInput={(params) => <TextField
                    {...params}
                    label={t(label)}
                    required={mandatory}
                    size="small"
                    InputProps={{ ...params.InputProps, emed_tid: label }} />}
                />
        }
      </div>
    )
  }
  tileBlock(label, data, width) {
    const { t } = this.props
    return (
      <Stack direction="column" className="eMed_pharmadrctpo_tileBlk" sx={{ width: width ? width : "7.20vw" }}>
        <Typography sx={{ fontSize: '0.78vw', color: "#888888" }}>{t(label)}</Typography>
        <Typography emed_tid={`C_${label}`} sx={{ fontSize: '0.9vw', marginTop: '0.2vw', fontWeight: 500 }}>{data}</Typography>
      </Stack>
    )
  }
  handleClear = (fromAdd = false) => {
    this.setState({
      data: { ...DEFAULT_SET },
      EPRValue: 0,
      isDifferentBatchExist: false,
      isFromEdit: false,
      existBatchList: [],
      selectedBatchNo: "",
      brand_suggestionList: [],
      is_sales_amount_changed: false,
      is_discount_amount_changed: false,
      genericSuggestionList: [],
      Tabvalue: fromAdd ? "one" : this.state.Tabvalue,
      // isBrandEdit: ((this.props.purchase_status === 'Partially Delivered' || this.props.purchase_status === 'Saved') || !this.props.purchase_status) ? false : true,
      is_group: false,
      PurchaseID: null,
      previousBatchNo: null,
      is_schedule: false,
      is_hsn: false,
      is_expiryalt: false,
      is_reorder: false,
      is_boxno: false,
      // defaultReadOnly: ((this.props.purchase_status === 'Partially Delivered' || this.props.purchase_status === 'Saved') || !this.props.purchase_status) ? false : true,
      drug_from_dc: false,
      BatchDetailsList: [],
      BatchDetailsSelected: false,
      FreeBatchDetailsSelected: false,
      isAllDisabled: this.props.isAllDisabled ? true : false,
      rightEditableData: {},
      isEdit : false,

    }, () => {
      console.log("isEdit",this.state.isEdit)
      if (!fromAdd) { this.props.IsClearEdit(); }
    })
  }
  renderPriceDetails = () => {
    var { drug_from_dc } = this.state
    return (
      <div>
        <div className="eMed_pharmadrctpo_rhtCnSubDiv">
          {this.renderTextBox("QtyPerUnit", "pack_of_drugs", true, false)}
          {this.renderTextBox("NoofUnit", "sales_unit", true, false, "14vw")}
        </div>
        <div className="eMed_pharmadrctpo_rhtCnSubDiv">
          {this.renderTextBox("BuyingpriceperUnit", "buying_price", true)}
          {this.renderTextBox("FreeQuantityUnit", "free_quantity", false, false, "14vw")}
        </div>
        <div className="eMed_pharmadrctpo_rhtCnSubDiv">
          {this.renderTextBox("Discount%", "discount_percentage", false, false)}
          {this.renderTextBox("DiscountAmount", "discount_amount", false, false, "14vw")}
        </div>
        <div className="eMed_pharmadrctpo_rhtCnSubDiv">
          {this.renderTextBox("S.Discount%", "sales_discount", false, false)}
          {this.renderTextBox("S.DiscountAmount", "sales_discount_amount", false, false, "14vw")}
        </div>
        <div className="eMed_pharmadrctpo_rhtCnSubDiv">
          {this.renderDropDown("GST%", "gst_percentage", "label", "value", gstPercentage, "", false, true, "openGSt", 2)}
          <div style={{ display: 'flex', width: '14.5vw',gap:"0.1vw"}}>
          {this.renderTextBox("MRPUnit", "mrp_per_pack", true, true, "14vw")}
          {/* {this.renderTextBox("Paise", "paise", false, true, "4vw")} */}
          {/* {this.renderTextBox("RoundOff", "round_off", false, true, "5vw")} */}
          </div>
        </div>
        <div className="eMed_pharmadrctpo_rhtCnSubDiv">
          {this.renderTextBox("SalesDiscount%", "sales_discount_for_bill", false, true)}
          {this.renderTextBox("Purchase/Qty", "purchase_per_qty", false, true)}
        </div>
      </div>
    );
  }
  renderFreeQty = () => {
    var { drug_from_dc } = this.state
    return (
      <div>
        <div className="eMed_pharmadrctpo_rhtCnSubDiv">
          {this.renderTextBox("QtyPerUnit", "free_qty_per_unit", true, false)}
          {this.renderTextBox("NoofUnit", "free_no_of_unit", true, false, "14vw")}
        </div>
        <div className="eMed_pharmadrctpo_rhtCnSubDiv">
          {this.renderTextBox("MRPUnit", "free_mrp_unit", true, false)}
          {this.renderTextBox("SalesDiscount%", "free_sales_discount_bills", false, false, "14vw")}
        </div>
      </div>
    );
  }

  render() {
    const { t } = this.props    
    let { data, isDifferentBatchExist, existBatchList, openBatch, selectedBatchNo, defaultReadOnly, Tabvalue, drug_from_dc } = this.state;
    // edit free qty with different batch tab
    let editFreeQty = (data.edit_index && Tabvalue === "two") ? true : false;
    return (
      <Paper elevation={4} sx={{ height: "79vh" }} >
        <Grid xs={12} item >
          <Tabs
            value={this.state.Tabvalue}
            id="eMed_pharmadrctpo_tabDiv"
            onChange={(e, newValue) => {
              if (newValue === "two" && (this.state.isInitiated || this.props.isFromWanted)) {
                this.setState({
                  isAllDisabled: false
                })
              } else {
                this.setState({
                  isAllDisabled: this.props.isAllDisabled ? true : false
                })
              }
              this.setState({
                Tabvalue: newValue,
                isBrandEdit: data?.edit_index && newValue === "two" ? true : this.state.isBrandEdit
              },
                () => this._calculateTransaction()
              )
            }}
          >
            <Tab emed_tid="drt_prc_dtl" value="one" label={("PriceDetails")} className='eMed_tab_header' />
            <Tab emed_tid="drt_free_qty" value="two" label={("FreeQty(DiffBatch)")} className='eMed_tab_header' />
          </Tabs>
          <div sx={{ height: "69vh" }}>
            <div className='eMed_pharmadrctpo_rhtHead'>
              <p className='eMed_pharmadrctpo_rhtHead_txt'>{t("DrugDetails")}</p>
            </div>
            <div style={{ height: '54.3vh', overflow: 'auto' }} onScroll={() => { this.setState({ openAutoCom: false, openAutoCombrand: false }) }}>
              <div id='eMed_pharmadrctpo_rhtCntBox1' className="eMed_pharmadrctpo_rhtCntBox">
                <Stack spacing={3}>
                  {this.renderBrandSearch()}
                  {this.renderTextBox("GenericName", "generic_name", true, false, "30vw")}
                </Stack>
                <div className="eMed_pharmadrctpo_rhtCnSubDiv">
                  {this.renderTextBox("DosageType", "dosage_type", true, true)}
                  {this.renderTextBox("DosageStrength", "dosage_strength", true, true, "14vw")}
                </div>
                <div className="eMed_pharmadrctpo_rhtCnSubDiv">
                  {/* {this.renderTextBox("BatchNumber", Tabvalue === "one" ? "batch_no" : "free_batch_no", true)} */}
                  {/* {this.renderDropDown("ManufactureName", 'manufacturer_name', "label", "value", this.state.manufacturerName, "", false, false)} */}
                  {this.renderDropDown("BatchNumber", Tabvalue === "one" ? "batch_no" : "free_batch_no", "batch_no", "value", this.state.stockDetail, "", false, false, false, false)}
                  {this.renderDatePicker("ExpiryDate", Tabvalue === "one" ? "expiry_date" : "free_expiry_date", "open", "14vw", true, 1)}
                </div>
                <div className="eMed_pharmadrctpo_rhtCnSubDiv">
                  {this.renderTextBox("Schedule", "schedule", false, true)}
                  {this.renderTextBox("Grouping", "group", false, true, "14vw")}
                </div>
                <div className="eMed_pharmadrctpo_rhtCnSubDiv">
                  {this.renderTextBox("UPC", "bar_code", false, true)}
                  {this.renderTextBox("HSNCode", "hsn_code", false, true, "14vw")}
                </div>
              </div>
              <div>
                <div className='eMed_pharmadrctpo_rhtHead'>
                  <p className='eMed_pharmadrctpo_rhtHead_txt'>{Tabvalue === "one" ? t("PriceDetails") : t("FreeQty")}</p>
                </div>
                <div id='eMed_pharmadrctpo_rhtCntBox2' className="eMed_pharmadrctpo_rhtCntBox">
                  {Tabvalue === "one" ? this.renderPriceDetails() : this.renderFreeQty()}
                  <div className="eMed_pharmadrctpo_rhtCnt_TleBlk">
                    {this.tileBlock("GST%", data.gst_percentage ? data.gst_percentage : 0, "7.10vw")}
                    {this.tileBlock("GSTAmount", data.total_gst_amount, "7.10vw")}
                    {this.tileBlock("TotalBuyingPrice", data.total_buying_price, "14.5vw")}
                  </div>
                  <div className="eMed_pharmadrctpo_rhtCnt_TleBlk">
                    {this.tileBlock("Quantity", data.total_quantity, "14.9vw")}
                    {this.tileBlock("MRPQuantity", Tabvalue === "two" ? 0 : data.mrp_per_quantity, "14.5vw")}
                  </div>
                  <div className="eMed_pharmadrctpo_rhtCnt_TleBlk">
                    {this.tileBlock("EPR", this.state.EPRValue, "14.9vw")}
                    {this.tileBlock("Taxable Amount", Tabvalue === "two" ? 0 : (data.total_buying_price - data.total_gst_amount)?.toFixed(2), "14.5vw")}
                  </div>
                  {/* <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} ml={1} mb= {2.5}>
                                {this.tileBlock("EPR", this.state.EPRValue, "14.9vw")}
                            </Stack> */}
                  <div className="eMed_pharmadrctpo_rhtCnSubDiv">
                    {this.renderDropDown("ExpiryPeriod", 'expiry_alert_period', "label", "value", Expiry_period, "", false, true)}
                    {this.renderTextBox("ReorderThreshold", "reorder_threshold", false, true, "14vw")}
                  </div>
                  <div className="eMed_pharmadrctpo_rhtCnSubDiv">
                    {this.renderTextBox("PhyBox/RoomNo", "physical_box_no", false, true)}
                    {this.renderTextBox("Description", "description", false, true, "14vw")}
                  </div>
                  <div className="eMed_pharmadrctpo_rhtCnSubDiv">
                    {/* {this.renderTextBox("ManufactureName", "manufacturer_name", false, (drug_from_dc || editFreeQty) ? true : false)}
                                {this.renderTextBox("RecommendedBy", "recommended_by", false, (drug_from_dc || editFreeQty) ? true : false, "14vw")} */}
                    {this.renderDropDown("ManufactureName", 'manufacturer_name', "label", "value", this.state.manufacturerName, "", false, true)}
                    {this.renderDropDown("RecommendedBy", 'recommended_by', "label", "value", this.state.DoctorsList, "", false, true)}
                  </div>
                </div>
              </div>
            </div>
            <Stack spacing={2} direction="row" id='eMed_pharmadrctpo_rhtBtnCon' alignItems="center" justifyContent="center" marginTop={"4vh"}  >
              <Button emed_tid="drt_clear" variant='outlined' size="small" id="eMed_Config_btn" disabled={this.state.isAllDisabled ? true : false} onClick={() => { this.handleClear() }}>{t("Clear")}</Button>
              <Button emed_tid="drt_add" variant='contained' size="small"
                disabled={this.state.isAllDisabled ? true : false}
                id="eMed_Config_btn"
                onClick={() => { this.handleAdd() }}>{t("Add")}</Button>
            </Stack>
          </div>
        </Grid>
      </Paper>
    )
  }
}
export default withTranslation()(PharmaISRequestDetailRight)
