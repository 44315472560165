import React, { Component } from 'react'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { CommonDeleteButton, CommonEditButton, DeletePopup, ReasonPopup } from '../../../../Components/Common Components/CommonComponents'
import { Autocomplete, Box, Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import Loader from '../../../../Components/Loader';
import { withTranslation } from 'react-i18next';
import { Colors } from '../../../../Styles/Colors';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { getCachevalue } from '../../../../Utility/Services/CacheProviderService';
import { DateTime } from 'luxon';
import moment from 'moment';
import { formatDate } from '../../../../Components/CommonFunctions/CommonFunctions';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import DeleteIcon from '@mui/icons-material/Delete';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';

let ModeOfDeliveryTypes = [
    { label: "Vaginal Birth", value: "Vaginal Birth" },
    // { label: "Natural Birth", value: "Natural Birth" },
    { label: "Scheduled Caesarean", value: "Scheduled Caesarean" },
    { label: "Unplanned Caesarean", value: "Unplanned Caesarean" },
    { label: "Vaginal Birth after C-Section (VBAC)", value: "Vaginal Birth after C-Section (VBAC)" },
    { label: "Scheduled Induction", value: "Scheduled Induction" },
]

let GenderDataList = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
]

const scanObservation = {
    "date": "",
    "week": "",
    "month": "",
    "month_weeks": "",
    "scan_name": "",
    "scan_findings": "",
    "remarks": "",
    "comments": ""
}
const investigation = {
    "date": "",
    "week": "",
    "month": "",
    "month_weeks": "",
    "investigation_test": [],
    "remarks": "",
    "id": ""
}
const vaccination = {
    "date": "",
    "month": "",
    "week": "",
    "month_weeks": "",
    "vaccination_name": "",
    "remarks": ""
}

let converted_date = new Date();

class AntenatalChart extends Component {
    constructor(props) {
        super(props)
        this.state = {
            SelectedTab: "ObstetricData",
            obstetricDataList: [],
            scanObservationList: [],
            vaccinationList: [],
            investigationChartList: [],
            InvestigationDataList: [],
            selectedLastMenstrualPeriod: "",
            lastMenstrualPeriod: converted_date,
            antenatal_id: null,

            obs_PICME: "",
            obs_Abortion: "",
            obs_LiveBirth: "",
            obs_Para: "",
            obs_Gravida: "",
            obs_ExpectedDeliveryDate: null,
            obs_LastMenstrualPeriod: null,
            // obs_ExpectedDeliveryDate: new Date(new Date().getTime() + 40 * 7 * 24 * 60 * 60 * 1000),
            // obs_LastMenstrualPeriod: new Date(),
            obs_editid: null,

            obshis_AntePeriod: "",
            obshis_ModeOfDelivery: "",
            obshis_sex: "",
            obshis_BirthWght: "",
            obshis_Outcome: "",
            obshis_NeonatalPeriod: "",
            obshis_date: new Date(),
            obshis_editid: null,
            obshis_deleteid: null,

            PhyExam_weight: "",
            PhyExam_height: "",
            PhyExam_BlodPress: "",
            PhyExam_Complaints: "",
            PhyExam_Presentation: "",
            PhyExam_Remarks: "",
            PhyExam_date: new Date(),
            PhyExam_editid: null,

            invest_name: "",
            InvestigationDataList: [],
            invest_Remarks: "",
            selectedMonthWeek: "",
            invest_date: new Date(),
            invest_editid: null,

            scanObs_Remarks: "",
            scanObs_scanName: "",
            scanObs_ScanFinding: "",
            selectedScanMonth: "",
            scanObs_date: new Date(),
            scan_editid: null,

            vacci_name: "",
            vacci_Remarks: "",
            selectedDateMonth: "",
            vacci_date: new Date(),
            vacci_editid: null,
            vacci_deleteid: null,
        }
    }

    componentDidMount() {
        let PatientData = getCachevalue("DoctorPatientData") ? JSON.parse(getCachevalue("DoctorPatientData")) : {}
        this.setState({
            PatientData: PatientData
        }, () => {
            this.GetObstetricData()
            this.GetScanDataList()
        })
    }

    GetScanDataList() {
        try {
            RestAPIService.getAll(Serviceurls.ANTENATAL_SCAN_LIST).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            ScanDataList: response.data.data
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    GetObstetricHistory() {
        try {
            RestAPIService.getAll(Serviceurls.ANTENATAL_OBSTETRIC_HISTORY + "?antenatal_id=" + this.state.antenatal_id).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            ObsHistoryData: response.data.data
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    GetPhysicalExamList() {
        try {
            RestAPIService.getAll(Serviceurls.ANTENATAL_PHYSICAL_EXAMINATION + "?antenatal_id=" + this.state.antenatal_id).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            PhysicalExaminationList: response.data.data
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    GetScanObservationList() {
        try {
            RestAPIService.getAll(Serviceurls.ANTENATAL_SCAN_OBSERVATION + "?antenatal_id=" + this.state.antenatal_id).
                then((response) => {
                    if (response.data.status === "success") {
                        var { scanObservationList } = this.state
                        var data = []
                        data = response.data.data

                        for (let i = 0; i < data.length; i++) {
                            for (let j = 0; j < scanObservationList.length; j++) {
                                if ((data[i].date_of_visit === scanObservationList[j].date_of_visit)) {
                                    scanObservationList[j].remarks = data[i].remarks
                                    scanObservationList[j].id = data[i].id
                                    scanObservationList[j].scan_findings = data[i].scan_findings
                                    scanObservationList[j].comments = data[i].comments
                                    this.setState({
                                        scanObservationList
                                    })
                                } else {
                                    var scanObservationLists = scanObservationList.push({ "date_of_visit": data[i].date_of_visit, "scan_findings": data[i].scan_findings, "scan_name": data[i].scan_name, "id": data[i].id, "remarks": data[i].remarks, "month_weeks": data[i].month_weeks, "comments": data[i].comments })
                                    this.setState({
                                        scanObservationList: scanObservationLists
                                    }, () => {
                                        var scanData = [...new Map(scanObservationList.map((m) =>
                                            [m.date_of_visit, m])).values()];
                                        var original = scanData
                                        const Sorted = original.sort((a, b) => {
                                            return a["date_of_visit"] > b["date_of_visit"] ? 1 : -1
                                        })
                                        var value = this.state
                                        value["scanObservationList"] = Sorted
                                        this.setState({
                                            value
                                        })
                                        this.setState({
                                            scanObservationList: Sorted
                                        })

                                    })
                                    break;
                                }
                            }
                        }
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    GetVaccinationData() {
        try {
            RestAPIService.getAll(Serviceurls.ANTENATAL_VACCINATION + "?antenatal_id=" + this.state.antenatal_id).
                then((response) => {
                    if (response.data.status === "success") {
                        var { vaccinationList } = this.state
                        var data = []
                        data = response.data.data
                        for (let i = 0; i < data.length; i++) {
                            for (let j = 0; j < vaccinationList.length; j++) {
                                if ((data[i].date_of_visit === vaccinationList[j].date_of_visit)) {
                                    vaccinationList[j].id = data[i].id
                                    vaccinationList[j].vaccination_name = data[i].vaccination_name
                                    vaccinationList[j].remarks = data[i].remarks
                                    this.setState({
                                        vaccinationList
                                    })
                                } else {
                                    var vaccinationLists = vaccinationList.push({ "date_of_visit": data[i].date_of_visit, "vaccination_name": data[i].vaccination_name, "remarks": data[i].remarks, "id": data[i].id, "month_weeks": data[i].month_weeks })
                                    this.setState({
                                        vaccinationList: vaccinationLists
                                    }, () => {
                                        var vaccineData = [...new Map(vaccinationList.map((m) =>
                                            [m.date_of_visit, m])).values()];
                                        var original = vaccineData
                                        const Sorted = original.sort((a, b) => {
                                            return a["date_of_visit"] > b["date_of_visit"] ? 1 : -1
                                        })
                                        var value = this.state
                                        value["vaccinationList"] = Sorted
                                        this.setState({
                                            value
                                        })

                                    })
                                    break;
                                }
                            }
                        }
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    GetInvestigationList() {
        try {
            RestAPIService.getAll(Serviceurls.ANTENATAL_INVESTIGATION_CHART + "?antenatal_id=" + this.state.antenatal_id).
                then((response) => {
                    if (response.data.status === "success") {
                        var data = []
                        data = response.data.data
                        var { investigationChartList } = this.state
                        for (let i = 0; i < data.length; i++) {
                            for (let j = 0; j < investigationChartList.length; j++) {
                                if (data[i].date_of_visit === investigationChartList[j].date_of_visit) {
                                    investigationChartList[j].investigation_test = data[i].investigation_test
                                    investigationChartList[j].remarks = data[i].remarks
                                    investigationChartList[j].id = data[i].id
                                    this.setState({
                                        investigationChartList
                                    })
                                } else {
                                    var investigationChartLists = investigationChartList.push({ "date_of_visit": data[i].date_of_visit, "remarks": data[i].remarks, "id": data[i].id, "month_weeks": data[i].month_weeks, "investigation_test": data[i].investigation_test })
                                    this.setState({
                                        investigationChartList: investigationChartLists
                                    }, () => {
                                        var investigationData = [...new Map(investigationChartList.map((m) =>
                                            [m.date_of_visit, m])).values()];
                                        var original = investigationData
                                        const Sorted = original.sort((a, b) => {
                                            return a["date_of_visit"] > b["date_of_visit"] ? 1 : -1
                                        })
                                        var value = this.state
                                        value["investigationChartList"] = Sorted
                                        this.setState({
                                            value
                                        })
                                    })
                                    break;
                                }
                            }

                        }
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    GetObstetricData() {
        try {
            this.setState({ isLoader: true })
            RestAPIService.getAll(Serviceurls.ANTENATAL_OBS_DATA_POST + '?patient_id=' + this.state.PatientData?.patient_id + "&antenatal_status=Assement").
                then((response) => {
                    if (response.data.status === "success") {
                        var data = response.data.data
                        this.setState({
                            obstetricDataList: response.data.data,
                            isLoader: false,
                            isPreviousData: false,
                            ObsHistoryData: [],
                            PhysicalExaminationList: [],
                            scanObservationList: [],
                            vaccinationList: [],
                            investigationChartList: [],
                        }, () => {
                            var { obstetricDataList, scanObservationList, vaccinationList, investigationChartList } = this.state

                            for (let i = 0; i < obstetricDataList.length; i++) {
                                var last_menstrual_period = obstetricDataList[i].antenatal_obstetric.last_menstrual_period
                                this.setState({
                                    selectedLastMenstrualPeriod: last_menstrual_period
                                }, () => {
                                    if (DateTime.fromJSDate(new Date(this.state.vacci_date)).toFormat("yyyy-MM-dd") == DateTime.fromJSDate(new Date(converted_date)).toFormat("yyyy-MM-dd")) {
                                        var dateFirst = new Date(this.state.selectedLastMenstrualPeriod);
                                        var dateSecond = new Date(this.state.vacci_date);
                                        // time difference
                                        var timeDiff = Math.abs(dateSecond.getTime() - dateFirst.getTime());
                                        // days difference
                                        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
                                        var weeks = Math.round(diffDays / 7)
                                        var month = Math.round(weeks / 4)
                                        var selectedMonthWeek = weeks + "Week" + " / " + month + " Month"
                                        this.setState({
                                            selectedDateMonth: selectedMonthWeek

                                        })
                                    }
                                    if (DateTime.fromJSDate(new Date(this.state.invest_date)).toFormat("yyyy-MM-dd") == DateTime.fromJSDate(new Date(converted_date)).toFormat("yyyy-MM-dd")) {
                                        var dateFirst = new Date(this.state.selectedLastMenstrualPeriod);
                                        var dateSecond = new Date(this.state.invest_date);
                                        // time difference
                                        var timeDiff = Math.abs(dateSecond.getTime() - dateFirst.getTime());
                                        // days difference
                                        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
                                        var weeks = Math.round(diffDays / 7)
                                        var month = Math.round(weeks / 4)
                                        var selectedMonthWeek = weeks + "Week" + " / " + month + " Month"
                                        this.setState({
                                            selectedMonthWeek: selectedMonthWeek

                                        })
                                    }
                                    if (DateTime.fromJSDate(new Date(this.state.scanObs_date)).toFormat("yyyy-MM-dd") == DateTime.fromJSDate(new Date(converted_date)).toFormat("yyyy-MM-dd")) {
                                        var dateFirst = new Date(this.state.selectedLastMenstrualPeriod);
                                        var dateSecond = new Date(this.state.scanObs_date);
                                        // time difference
                                        var timeDiff = Math.abs(dateSecond.getTime() - dateFirst.getTime());
                                        // days difference
                                        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
                                        var weeks = Math.round(diffDays / 7)
                                        var month = Math.round(weeks / 4)
                                        var selectedMonthWeek = weeks + "Week" + " / " + month + " Month"
                                        this.setState({
                                            selectedScanMonth: selectedMonthWeek

                                        })
                                    }

                                })
                            }

                            if (last_menstrual_period && this.state.scanObservationList && this.state.scanObservationList.length == 0 && !this.state.isPreviousData) {

                                for (let i = scanObservationList.length; i < 5; i++) {
                                    scanObservationList.push(JSON.parse(JSON.stringify(scanObservation)))
                                }
                                var sixWeek = moment(last_menstrual_period).add(6, 'week').format("YYYY-MM-DD");
                                scanObservationList[0].date_of_visit = sixWeek
                                scanObservationList[0].week = 6
                                scanObservationList[0].month = Math.round((scanObservationList[0].week) / 4)
                                scanObservationList[0].month_weeks = scanObservationList[0].week + " Weeks / " + scanObservationList[0].month + "Months"
                                scanObservationList[0].scan_name = "Ultra sound scan / Early Pregnancy scan"
                                var towelWeek = moment(last_menstrual_period).add(12, 'week').format("YYYY-MM-DD");
                                scanObservationList[1].date_of_visit = towelWeek
                                scanObservationList[1].week = 12
                                scanObservationList[1].month = Math.round((scanObservationList[1].week) / 4)
                                scanObservationList[1].month_weeks = scanObservationList[1].week + " Weeks / " + scanObservationList[1].month + "Months"
                                scanObservationList[1].scan_name = "NT Scan"
                                var sixtenWeek = moment(last_menstrual_period).add(20, 'week').format("YYYY-MM-DD");
                                scanObservationList[2].date_of_visit = sixtenWeek
                                scanObservationList[2].week = 20
                                scanObservationList[2].month = Math.round((scanObservationList[2].week) / 4)
                                scanObservationList[2].month_weeks = scanObservationList[2].week + " Weeks / " + scanObservationList[2].month + "Months"
                                scanObservationList[2].scan_name = "Anomaly scan"
                                var twentyWeek = moment(last_menstrual_period).add(28, 'week').format("YYYY-MM-DD");
                                scanObservationList[3].date_of_visit = twentyWeek
                                scanObservationList[3].week = 28
                                scanObservationList[3].month = Math.round((scanObservationList[3].week) / 4)
                                scanObservationList[3].month_weeks = scanObservationList[3].week + " Weeks / " + scanObservationList[3].month + "Months"
                                scanObservationList[3].scan_name = "Growth scan"
                                var twentyEightWeek = moment(last_menstrual_period).add(36, 'week').format("YYYY-MM-DD");
                                scanObservationList[4].date_of_visit = twentyEightWeek
                                scanObservationList[4].week = 36
                                scanObservationList[4].month = Math.round((scanObservationList[4].week) / 4)
                                scanObservationList[4].month_weeks = scanObservationList[4].week + " Weeks / " + scanObservationList[4].month + "Months"
                                scanObservationList[4].scan_name = "Growth scan"
                                // var theetyTwoWeek = moment(this.state.lastMenstrualPeriod).add(32, 'week').format("YYYY-MM-DD");
                                // scanObservationList[5].date = theetyTwoWeek
                                // scanObservationList[5].week = 32
                                // var theetyFourWeek = moment(this.state.lastMenstrualPeriod).add(34, 'week').format("YYYY-MM-DD");
                                // scanObservationList[6].date = theetyFourWeek
                                // scanObservationList[6].week = 34
                                // var theertySixWeek = moment(this.state.lastMenstrualPeriod).add(36, 'week').format("YYYY-MM-DD");
                                // scanObservationList[7].date = theertySixWeek
                                // scanObservationList[7].week = 36
                            }
                            if (last_menstrual_period && this.state.investigationChartList && this.state.investigationChartList.length == 0 && !this.state.isPreviousData) {
                                for (let i = investigationChartList.length; i < 5; i++) {
                                    investigationChartList.push(JSON.parse(JSON.stringify(investigation)))
                                }
                                let sixWeeks = moment(last_menstrual_period).add(6, 'week').format("YYYY-MM-DD");
                                investigationChartList[0].date_of_visit = sixWeeks
                                investigationChartList[0].week = 6
                                investigationChartList[0].month = Math.round((investigationChartList[0].week) / 4)
                                investigationChartList[0].month_weeks = investigationChartList[0].week + " Weeks / " + investigationChartList[0].month + " Months"
                                investigationChartList[0].investigation_test.push({ "name": "Urine analysis", "value": "" }, { "name": "TSH", "value": "" }, { "name": "RBS", "value": "" }, { "name": "HB", "value": "" })

                                let twealthWeeks = moment(last_menstrual_period).add(12, 'week').format("YYYY-MM-DD");
                                investigationChartList[1].date_of_visit = twealthWeeks
                                investigationChartList[1].week = 12
                                investigationChartList[1].month = Math.round((investigationChartList[1].week) / 4)
                                investigationChartList[1].month_weeks = investigationChartList[1].week + " Weeks / " + investigationChartList[1].month + " Months"
                                investigationChartList[1].investigation_test.push({ "name": "Down Syndrome screening", "value": "" }, { "name": "Double marker", "value": "" })

                                let twentyWeeks = moment(last_menstrual_period).add(20, 'week').format("YYYY-MM-DD");
                                investigationChartList[2].date_of_visit = twentyWeeks
                                investigationChartList[2].week = 20
                                investigationChartList[2].month = Math.round((investigationChartList[2].week) / 4)
                                investigationChartList[2].month_weeks = investigationChartList[2].week + " Weeks / " + investigationChartList[2].month + " Months"
                                investigationChartList[2].investigation_test.push({ "name": "Urine analysis", "value": "" }, { "name": "HB", "value": "" }, { "name": "Blood sugar", "value": "" })

                                let twentyEightWeeks = moment(last_menstrual_period).add(28, 'week').format("YYYY-MM-DD");
                                investigationChartList[3].date_of_visit = twentyEightWeeks
                                investigationChartList[3].week = 28
                                investigationChartList[3].month = Math.round((investigationChartList[3].week) / 4)
                                investigationChartList[3].month_weeks = investigationChartList[3].week + " Weeks / " + investigationChartList[3].month + " Months"
                                investigationChartList[3].investigation_test.push({ "name": "GTT", "value": "" }, { "name": "Thyroid", "value": "" }, { "name": "HB", "value": "" })

                                var theetySixs = moment(last_menstrual_period).add(36, 'week').format("YYYY-MM-DD");
                                investigationChartList[4].date_of_visit = theetySixs
                                investigationChartList[4].week = 36
                                investigationChartList[4].month = Math.round((investigationChartList[4].week) / 4)
                                investigationChartList[4].month_weeks = investigationChartList[4].week + " Weeks / " + investigationChartList[4].month + " Months"
                                investigationChartList[4].investigation_test.push({ "name": "HB", "value": "" }, { "name": "Urine analysis", "value": "" }, { "name": "Blood sugar", "value": "" })

                            }
                            if (last_menstrual_period && this.state.vaccinationList && this.state.vaccinationList.length == 0 && !this.state.isPreviousData) {
                                for (let i = vaccinationList.length; i < 3; i++) {
                                    vaccinationList.push(JSON.parse(JSON.stringify(vaccination)))
                                }
                                var twealthWeek = moment(last_menstrual_period).add(12, 'week').format("YYYY-MM-DD");
                                vaccinationList[0].date_of_visit = twealthWeek
                                vaccinationList[0].week = 12
                                vaccinationList[0].month = Math.round((vaccinationList[0].week) / 4)
                                vaccinationList[0].month_weeks = vaccinationList[0].week + " Weeks / " + vaccinationList[0].month + " Months"
                                vaccinationList[0].vaccination_name = "TT Injection"

                                var twentyEightWeek = moment(last_menstrual_period).add(28, 'week').format("YYYY-MM-DD");
                                vaccinationList[1].date_of_visit = twentyEightWeek
                                vaccinationList[1].week = 28
                                vaccinationList[1].month = Math.round((vaccinationList[1].week) / 4)
                                vaccinationList[1].month_weeks = vaccinationList[1].week + " Weeks / " + vaccinationList[1].month + " Months"
                                vaccinationList[1].vaccination_name = "TDap"

                                var twentyNineWeek = moment(last_menstrual_period).add(28, 'week').format("YYYY-MM-DD");
                                vaccinationList[2].date_of_visit = twentyNineWeek
                                vaccinationList[2].week = 28
                                vaccinationList[2].month = Math.round((vaccinationList[2].week) / 4)
                                vaccinationList[2].month_weeks = vaccinationList[2].week + " Weeks / " + vaccinationList[2].month + " Months"
                                vaccinationList[2].vaccination_name = "Covid"
                            }

                            this.setState({
                                scanObservationList,
                                vaccinationList,
                                investigationChartList,
                            })
                            for (let i = 0; i < data.length; i++) {
                                this.setState({
                                    antenatal_id: data[i].antenatal_obstetric.id,
                                    updatedLastMenstrualPeriod: data[i].antenatal_obstetric.last_menstrual_period
                                }, () => {
                                    if (this.state.antenatal_id != "") {
                                        this.GetObstetricHistory()
                                        this.GetPhysicalExamList()
                                        this.GetInvestigationList()
                                        this.GetScanObservationList()
                                        this.GetVaccinationData()
                                    }
                                })
                            }
                        })
                    }
                    else {
                        this.setState({ isLoader: false })
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.setState({ isLoader: false })
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    getPreviousObstrtricData() {
        try {
            RestAPIService.getAll(Serviceurls.ANTENATAL_OBS_DATA_POST + '?patient_id=' + this.state.PatientData?.patient_id + "&antenatal_status=Completed").
                then((response) => {
                    if (response.data.status === "success") {
                        var data = response.data.data
                        this.setState({
                            previousObstetricData: data
                        }, () => {
                            for (let i = 0; i < data.length; i++) {
                                if (this.state.prevSelectedID == data[i].antenatal_obstetric.id) {
                                    this.setState({
                                        antenatal_id: data[i].antenatal_obstetric.id,
                                        updatedLastMenstrualPeriod: data[i].antenatal_obstetric.last_menstrual_period,
                                        CompletedReason: data[i].antenatal_obstetric?.remarks ? data[i].antenatal_obstetric?.remarks : ""
                                    }, () => {
                                        this.GetObstetricHistory()
                                        this.GetPhysicalExamList()
                                        this.GetInvestigationList()
                                        this.GetScanObservationList()
                                        this.GetVaccinationData()
                                    })
                                }
                            }
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    anatentalMainHeader = (label, value) => {
        return (
            <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                <Button
                    sx={{ textTransform: "capitalize", fontWeight: 600, color: value === this.state.SelectedTab ? "#04B7B1" : "black" }}
                    onClick={() => {
                        if (value === "ObstetricData" || this.state.antenatal_id) {
                            this.setState({
                                SelectedTab: value,
                                isPreviousData: false,
                                isObstetricDataNew: false,
                            }, () => { this.ClearData() })
                        } else {
                            this.errorMessage("Please enter the Obstetric data")
                        }
                    }}
                >
                    {label}
                </Button>
                {
                    value === "ObstetricData" ?
                        <Box>
                            <Button
                                sx={{ textTransform: "capitalize", fontWeight: 600 }}
                                size='small'
                                onClick={() => {
                                    this.getPreviousObstrtricData()
                                    this.setState({
                                        isPreviousData: true,
                                        SelectedTab: "PreviousAntenental",
                                        obstetricDataList: [],
                                        ObsHistoryData: [],
                                        PhysicalExaminationList: [],
                                        scanObservationList: [],
                                        vaccinationList: [],
                                        investigationChartList: [],
                                        antenatal_id: ""
                                    })
                                }}>Previous</Button>
                            <Button
                                sx={{ textTransform: "capitalize", fontWeight: 600 }}
                                size='small'
                                disabled={this.state.antenatal_id ? false : true}
                                onClick={() => {
                                    var expectedDateOfDelivery = moment(this.state.lastMenstrualPeriod).add(40, 'week').format("YYYY-MM-DD");
                                    this.setState({
                                        SelectedTab: "ObstetricData",
                                        previousObstetricData: [],
                                        selectedPreviousData: "",
                                        CompletedReason: "",
                                        obstetricDataList: [],
                                        // isPreviousData: this.state.isPreviousData ? true : false
                                    }, () => {
                                        if (!this.state.isPreviousData) {
                                            this.setState({
                                                isObstetricDataNew: true,
                                                expectedDateOfDelivery: expectedDateOfDelivery,
                                                selectedSubTitle: "ObstetricData"
                                            }, () => {
                                                this.GetObstetricData()
                                            })
                                        } else {
                                            this.GetObstetricData()
                                        }
                                    })
                                    this.ClearData("ObstetricData")
                                }}>New</Button>
                            <CommonEditButton size={"1.2vw"} onClick={this.onEditHandler.bind(this, "ObstetricData", {})} />
                        </Box> :
                        <Button
                            sx={{ textTransform: "capitalize", fontWeight: 600 }}
                            size='small'
                            onClick={() => {
                                if (value === "ObstetricData" || this.state.antenatal_id) {
                                    this.setState({
                                        SelectedTab: value
                                    }, () => { this.ClearData() })
                                } else {
                                    this.errorMessage("Please enter the Obstetric data")
                                }
                            }}>New</Button>
                }

            </Box>
        )
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    onEditHandler(TabName, item = {}) {
        if (TabName === "ObstetricData") {
            var { obstetricDataList } = this.state
            if (obstetricDataList && obstetricDataList.length > 0) {
                for (let i = 0; i < obstetricDataList.length; i++) {
                    this.setState({
                        obs_PICME: obstetricDataList[i].antenatal_obstetric.picme,
                        obs_Para: obstetricDataList[i].antenatal_obstetric.para,
                        obs_Gravida: obstetricDataList[i].antenatal_obstetric.gravida,
                        obs_LiveBirth: obstetricDataList[i].antenatal_obstetric.live_births,
                        obs_Abortion: obstetricDataList[i].antenatal_obstetric.abortions,
                        obs_LastMenstrualPeriod: obstetricDataList[i].antenatal_obstetric.last_menstrual_period,
                        obs_ExpectedDeliveryDate: obstetricDataList[i].antenatal_obstetric.expected_date_of_delivery,
                        obs_editid: obstetricDataList[i].antenatal_obstetric.id,
                        isObtetricData: true,
                        SelectedTab: "ObstetricData"
                    })
                }
            }
        } else if (TabName === "ObstetricHistory") {
            this.setState({
                obshis_AntePeriod: item?.antenatal_period ? item?.antenatal_period : "",
                obshis_ModeOfDelivery: item?.mode_of_delivery ? { label: item?.mode_of_delivery, value: item?.mode_of_delivery } : null,
                obshis_sex: item?.gender ? { label: item?.gender, value: item?.gender } : null,
                obshis_BirthWght: item?.baby_weight ? item?.baby_weight : "",
                obshis_Outcome: item?.outcome ? item?.outcome : "",
                obshis_NeonatalPeriod: item?.neonatal_period ? item?.neonatal_period : "",
                obshis_date: item?.date_of_visit ? new Date(item?.date_of_visit) : null,
                SelectedTab: "ObstetricHistory",
                obshis_editid: item?.id ? item?.id : null
            })
        } else if (TabName === "PhysicalExamination") {
            this.setState({
                PhyExam_weight: item?.weight ? item?.weight : "",
                PhyExam_height: item?.height_of_uterus ? item?.height_of_uterus : "",
                PhyExam_BlodPress: item?.blood_pressure ? item?.blood_pressure : "",
                PhyExam_Complaints: item?.complaints ? item?.complaints : "",
                PhyExam_Presentation: item?.presentation ? item?.presentation : "",
                PhyExam_Remarks: item?.remarks ? item?.remarks : "",
                PhyExam_date: item?.date_of_visit ? new Date(item?.date_of_visit) : null,
                SelectedTab: "PhysicalExamination",
                PhyExam_editid: item?.id ? item?.id : null
            })
        } else if (TabName === "InvestigationChart") {
            this.setState({
                InvestigationDataList: item?.investigation_test ? JSON.parse(JSON.stringify(item?.investigation_test)) : [],
                invest_Remarks: item?.remarks ? item?.remarks : "",
                selectedMonthWeek: item?.month_weeks ? item?.month_weeks : "",
                invest_date: item?.date_of_visit ? new Date(item?.date_of_visit) : null,
                SelectedTab: "InvestigationChart",
                invest_editid: item?.id ? item?.id : null
            })
        } else if (TabName === "ScanObservation") {
            this.setState({
                scanObs_scanName: item?.scan_name ? { name: item?.scan_name } : null,
                scanObs_ScanFinding: item?.scan_findings ? item?.scan_findings : "",
                scanObs_Remarks: item?.remarks ? item?.remarks : "",
                selectedScanMonth: item?.month_weeks ? item?.month_weeks : "",
                scanObs_date: item?.date_of_visit ? new Date(item?.date_of_visit) : null,
                SelectedTab: "ScanObservation",
                scan_editid: item?.id ? item?.id : null
            })
        } else if (TabName === "Vaccination") {
            this.setState({
                vacci_name: item?.vaccination_name ? item?.vaccination_name : "",
                vacci_Remarks: item?.remarks ? item?.remarks : "",
                selectedDateMonth: item?.month_weeks ? item?.month_weeks : "",
                vacci_date: item?.date_of_visit ? new Date(item?.date_of_visit) : null,
                SelectedTab: "Vaccination",
                vacci_editid: item?.id ? item?.id : null
            })
        }
    }

    onDeleteHandler(TabName, item) {
        if (TabName === "ObstetricHistory") {
            this.setState({
                obshis_deleteid: item?.id ? item?.id : null,
                isDeleteCliked: TabName
            })
        }
        else if (TabName === "PhysicalExamination") {
            this.setState({
                PhyExam_deleteid: item?.id ? item?.id : null,
                isDeleteCliked: TabName
            })
        }
        else if (TabName === "InvestigationChart") {
            this.setState({
                invest_deleteid: item?.id ? item?.id : null,
                isDeleteCliked: TabName
            })
        }
        else if (TabName === "ScanObservation") {
            this.setState({
                scan_deleteid: item?.id ? item?.id : null,
                isDeleteReasonCliked: TabName
            })
        }
        else if (TabName === "Vaccination") {
            this.setState({
                vacci_deleteid: item?.id ? item?.id : null,
                isDeleteCliked: TabName
            })
        }
    }

    returnDateField = (label, stateKey, value, width = '15vw', labelWidth = "5vw", disable = false) => {
        const { t } = this.props
        let states = this.state
        return (
            <Box sx={{ display: 'flex', alignItems: "center", margin: "0.5vw" }}>
                <Typography sx={{ width: labelWidth }}>{t(label)}</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        // open={this.state.DatePickerOpen}
                        // onOpen={() => { this.setState({ DatePickerOpen: true }) }}
                        // onClose={() => { this.setState({ DatePickerOpen: false }) }}
                        value={value}
                        inputFormat='DD-MM-YYYY'
                        views={["year", "month", "day"]}
                        maxDate={stateKey === "obs_LastMenstrualPeriod" ? new Date() : null}
                        minDate={stateKey === "obs_ExpectedDeliveryDate" ? new Date() : null}
                        disabled={disable}
                        onChange={(newDate) => {
                            if (stateKey === "obs_LastMenstrualPeriod") {
                                states[stateKey] = newDate
                                states["obs_ExpectedDeliveryDate"] = new Date(new Date(newDate).getTime() + 40 * 7 * 24 * 60 * 60 * 1000);
                            } else {
                                states[stateKey] = newDate
                            }
                            this.setState({ states }, () => {
                                var dateFirst = ""
                                var dateSecond = ""
                                // time difference
                                var timeDiff = ""
                                // days difference
                                var diffDays = ""
                                var weeks = ""
                                var month = ""
                                var selectedMonthWeek = ""
                                if (stateKey === "invest_date") {
                                    dateFirst = new Date(this.state.selectedLastMenstrualPeriod);
                                    dateSecond = new Date(this.state.invest_date);
                                    // time difference
                                    timeDiff = Math.abs(dateSecond.getTime() - dateFirst.getTime());
                                    // days difference
                                    diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
                                    weeks = Math.round(diffDays / 7)
                                    month = Math.round(weeks / 4)
                                    selectedMonthWeek = ""
                                    selectedMonthWeek = weeks + "Week" + " / " + month + " Month"
                                    this.setState({
                                        selectedMonthWeek: selectedMonthWeek,
                                    })
                                } else if (stateKey === "scanObs_date") {
                                    dateFirst = new Date(this.state.selectedLastMenstrualPeriod);
                                    dateSecond = new Date(this.state.scanObs_date);
                                    // time difference
                                    timeDiff = Math.abs(dateSecond.getTime() - dateFirst.getTime());
                                    // days difference
                                    diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
                                    weeks = Math.round(diffDays / 7)
                                    month = Math.round(weeks / 4)
                                    selectedMonthWeek = ""
                                    selectedMonthWeek = weeks + " Week " + " / " + month + " Month"
                                    this.setState({
                                        selectedScanMonth: selectedMonthWeek
                                    })
                                } else if (stateKey === "vacci_date") {
                                    dateFirst = new Date(this.state.selectedLastMenstrualPeriod);
                                    dateSecond = new Date(this.state.vacci_date);
                                    // time difference
                                    timeDiff = Math.abs(dateSecond.getTime() - dateFirst.getTime());
                                    // days difference
                                    diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
                                    weeks = Math.round(diffDays / 7)
                                    month = Math.round(weeks / 4)
                                    selectedMonthWeek = ""
                                    selectedMonthWeek = weeks + " Week " + " / " + month + " Month"
                                    this.setState({
                                        selectedDateMonth: selectedMonthWeek
                                    })
                                }
                            })
                        }}
                        renderInput={(params) => <TextField onKeyDown={(e) => e.preventDefault()} sx={{ marginLeft: '2vw', width: width }} size='small' {...params} onClick={() => { this.setState({ DatePickerOpen: true }) }} />}
                    />
                </LocalizationProvider>
            </Box>
        )
    }

    returnTextField = (label, stateKey, value, width = '15vw', labelWidth = "5vw", validation = "no validation", MaxLength = 30, disable = false) => {
        const { t } = this.props
        let states = this.state
        return (
            <Box sx={{ display: 'flex', alignItems: "center", margin: "0.5vw" }}>
                <Typography sx={{ width: labelWidth }}>{t(label)}</Typography>
                <TextField
                    size='small'
                    label={t(label)}
                    sx={{ marginLeft: "1vw", marginY: "0.5vw", width: width }}
                    inputProps={{ maxLength: MaxLength }}
                    disabled={disable}
                    onChange={(e) => {
                        let value = e.target.value
                        if (validation === "only number") {
                            if (CommonValidation.numberOnly(value) || value === "") {
                                states[stateKey] = value
                            }
                        } else if (validation === "only number below 11") {
                            if ((CommonValidation.DecimalNumberThreeDigit(value) || value === "") && +value <= 10) {
                                states[stateKey] = value
                            }
                        } else if(validation ==="only number with slash"){
                            if (  (CommonValidation.slashAfter(value) )|| value === "") {
                                states[stateKey] = value
                            }
                        }
                        else {
                            states[stateKey] = value
                        }
                        this.setState({ states })
                    }}
                    value={value}
                    autoComplete='off' />
            </Box>
        )
    }

    returnMultiTextField = (label, stateKey, value, width = '15vw', labelWidth = "5vw") => {
        const { t } = this.props
        let states = this.state
        return (
            <Box sx={{ display: 'flex', alignItems: "center", margin: "0.5vw" }}>
                <Typography sx={{ width: labelWidth }}>{t(label)}</Typography>
                <TextField
                    size='small'
                    label={t(label)}
                    sx={{ marginLeft: "1vw", marginY: "0.5vw", width: width }}
                    onChange={(e) => {
                        states[stateKey] = e.target.value
                        this.setState({ states })
                    }}
                    inputProps={{ maxLength: 250 }}
                    value={value}
                    multiline={true}
                    rows={3} />
            </Box>
        )
    }

    returnAutoComplete = (label, stateKey, DataList, labelKey, width = '15vw', labelWidth = "15vw") => {
        let states = this.state;
        const { t } = this.props
        return (
            <Box sx={{ display: 'flex', alignItems: "center", margin: "0.5vw" }}>
                <Typography sx={{ width: labelWidth }}>{t(label)}</Typography>
                <Autocomplete
                    size='small'
                    clearIcon={false}
                    options={DataList}
                    getOptionLabel={(item) => (item[labelKey])}
                    value={states[stateKey] ? states[stateKey] : null}
                    onChange={(event, newValue) => {
                        states[stateKey] = newValue
                        this.setState({ states })
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            placeholder={label}
                            sx={{ marginLeft: "1vw", marginY: "0.5vw", width: width }}
                        />
                    )}
                />
            </Box>
        )
    }

    renderObsdataTable = () => {
        return (
            <Box>
                <Box component={'div'} border={'1px solid lightgray'} overflow={'auto'}>
                    <Table stickyHeader size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Gravida</TableCell>
                                <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Para</TableCell>
                                <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Live Birth</TableCell>
                                <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Abortion</TableCell>
                                <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Last Menstrual Period</TableCell>
                                <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Expected date of delivery</TableCell>
                                <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>PICME</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.obstetricDataList?.length > 0 ? this.state.obstetricDataList?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell><Typography fontSize={'0.9vw'} fontWeight={600}>{item?.antenatal_obstetric.gravida ? item?.antenatal_obstetric.gravida : "-"}</Typography></TableCell>
                                    <TableCell><Typography fontSize={'0.9vw'} fontWeight={600}>{item?.antenatal_obstetric.para ? item?.antenatal_obstetric.para : "-"}</Typography></TableCell>
                                    <TableCell><Typography fontSize={'0.9vw'} fontWeight={600}>{item?.antenatal_obstetric.live_births ? item?.antenatal_obstetric.live_births : "-"}</Typography></TableCell>
                                    <TableCell><Typography fontSize={'0.9vw'} fontWeight={600}>{item?.antenatal_obstetric.abortions ? item?.antenatal_obstetric.abortions : "-"}</Typography></TableCell>
                                    <TableCell><Typography fontSize={'0.9vw'} fontWeight={600}>{item?.antenatal_obstetric.last_menstrual_period ? formatDate(item?.antenatal_obstetric.last_menstrual_period) : "-"}</Typography></TableCell>
                                    <TableCell><Typography fontSize={'0.9vw'} fontWeight={600}>{item?.antenatal_obstetric.expected_date_of_delivery ? formatDate(item?.antenatal_obstetric.expected_date_of_delivery) : "-"}</Typography></TableCell>
                                    <TableCell><Typography fontSize={'0.9vw'} fontWeight={600}>{item?.antenatal_obstetric.picme ? item?.antenatal_obstetric.picme : "-"}</Typography></TableCell>
                                </TableRow>
                            )) :
                                <TableRow>
                                    <TableCell />
                                    <TableCell align='right' sx={{ position: 'relative', left: '6.5vw' }}>No Records To Be Shown</TableCell>
                                    <TableCell />
                                    <TableCell />
                                    <TableCell />
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </Box>
            </Box>
        )
    }

    renderObstetricHistoryTable = () => {
        return (
            <Box>
                <Box component={'div'} border={'1px solid lightgray'} overflow={'auto'}>
                    <Table stickyHeader size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Date</TableCell>
                                <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Antenatal Period</TableCell>
                                <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Mode of Delivery</TableCell>
                                <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Sex/WT</TableCell>
                                <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Outcome</TableCell>
                                <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Neonatal Period</TableCell>
                                <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.ObsHistoryData?.length > 0 ? this.state.ObsHistoryData?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell><Typography fontSize={'0.9vw'} fontWeight={600}>{item?.date_of_visit ? formatDate(item?.date_of_visit) : "-"}</Typography></TableCell>
                                    <TableCell><Typography fontSize={'0.9vw'} fontWeight={600}>{item?.antenatal_period ? item?.antenatal_period : "-"}</Typography></TableCell>
                                    <TableCell><Typography fontSize={'0.9vw'} fontWeight={600}>{item?.mode_of_delivery ? item?.mode_of_delivery : "-"}</Typography></TableCell>
                                    <TableCell><Typography fontSize={'0.9vw'} fontWeight={600}>{`${item?.gender ? item?.gender : "-"} / ${item?.baby_weight ? item?.baby_weight : "-"} kg`}</Typography></TableCell>
                                    <TableCell><Typography fontSize={'0.9vw'} fontWeight={600}>{item?.outcome ? item?.outcome : "-"}</Typography></TableCell>
                                    <TableCell><Typography fontSize={'0.9vw'} fontWeight={600}>{item?.neonatal_period ? item?.neonatal_period : "-"}</Typography></TableCell>
                                    <TableCell>
                                        <Box component={'div'} style={{ marginLeft: "-0.65vw" }} display={'flex'} justifyContent={'flex-start'} flexDirection={'row'} alignItems={'center'}>
                                            <CommonEditButton size={"1.2vw"} onClick={this.onEditHandler.bind(this, "ObstetricHistory", item)} />
                                            <CommonDeleteButton size={"1.2vw"} onClick={this.onDeleteHandler.bind(this, "ObstetricHistory", item)} />
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            )) :
                                <TableRow>
                                    <TableCell />
                                    <TableCell align='right' sx={{ position: 'relative', left: '6.5vw' }}>No Records To Be Shown</TableCell>
                                    <TableCell />
                                    <TableCell />
                                    <TableCell />
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </Box>
            </Box>
        )
    }


    renderPhysicalExaminationTable = () => {
        return (
            <Box>
                <Box component={'div'} border={'1px solid lightgray'} overflow={'auto'}>
                    <Table stickyHeader size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Date</TableCell>
                                <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Week</TableCell>
                                <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Weight</TableCell>
                                <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Height</TableCell>
                                <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Blood Pressure</TableCell>
                                <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Complaints</TableCell>
                                <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Presentation</TableCell>
                                <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Remarks</TableCell>
                                <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.PhysicalExaminationList?.length > 0 ? this.state.PhysicalExaminationList?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell><Typography fontSize={'0.9vw'} fontWeight={600}>{item?.date_of_visit ? formatDate(item?.date_of_visit) : "-"}</Typography></TableCell>
                                    <TableCell><Typography fontSize={'0.9vw'} fontWeight={600}>{item?.weeks ? item?.weeks : "-"}</Typography></TableCell>
                                    <TableCell><Typography fontSize={'0.9vw'} fontWeight={600}>{item?.weight ? item?.weight : "-"}</Typography></TableCell>
                                    <TableCell><Typography fontSize={'0.9vw'} fontWeight={600}>{item?.height_of_uterus ? item?.height_of_uterus : "-"}</Typography></TableCell>
                                    <TableCell><Typography fontSize={'0.9vw'} fontWeight={600}>{item?.blood_pressure ? item?.blood_pressure : "-"}</Typography></TableCell>
                                    <TableCell><Typography fontSize={'0.9vw'} fontWeight={600}>{item?.complaints ? item?.complaints : "-"}</Typography></TableCell>
                                    <TableCell><Typography fontSize={'0.9vw'} fontWeight={600}>{item?.presentation ? item?.presentation : "-"}</Typography></TableCell>
                                    <TableCell><Typography fontSize={'0.9vw'} fontWeight={600}>{item?.remarks ? item?.remarks : "-"}</Typography></TableCell>
                                    <TableCell>
                                        <Box component={'div'} style={{ marginLeft: "-0.65vw" }} display={'flex'} justifyContent={'flex-start'} flexDirection={'row'} alignItems={'center'}>
                                            <CommonEditButton size={"1.2vw"} onClick={this.onEditHandler.bind(this, "PhysicalExamination", item)} />
                                            <CommonDeleteButton size={"1.2vw"} onClick={this.onDeleteHandler.bind(this, "PhysicalExamination", item)} />
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            )) :
                                <TableRow>
                                    <TableCell />
                                    <TableCell align='right' sx={{ position: 'relative', left: '6.5vw' }}>No Records To Be Shown</TableCell>
                                    <TableCell />
                                    <TableCell />
                                    <TableCell />
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </Box>
            </Box>
        )
    }

    renderInvestigationChartTable = () => {
        return (
            <Box>
                <Box component={'div'} border={'1px solid lightgray'} overflow={'auto'}>
                    <Table stickyHeader size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Month / Week</TableCell>
                                <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Investigation Name</TableCell>
                                <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Value</TableCell>
                                <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Remark</TableCell>
                                <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.investigationChartList?.length > 0 ? this.state.investigationChartList?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell><Typography fontSize={'0.9vw'} fontWeight={600}>{item?.month_weeks ? item?.month_weeks : "-"}</Typography></TableCell>
                                    <TableCell>{item?.investigation_test ? item?.investigation_test.map((list) => (<Typography fontSize={'0.9vw'} fontWeight={600}>{list?.name ? list?.name : "-"}</Typography>)) : "-"}</TableCell>
                                    <TableCell>{item?.investigation_test ? item?.investigation_test.map((list) => (<Typography fontSize={'0.9vw'} fontWeight={600}>{list?.value ? list?.value : "-"}</Typography>)) : "-"}</TableCell>
                                    <TableCell><Typography fontSize={'0.9vw'} fontWeight={600}>{item?.remarks ? item?.remarks : "-"}</Typography></TableCell>
                                    <TableCell>
                                        <Box component={'div'} style={{ marginLeft: "-0.65vw" }} display={'flex'} justifyContent={'flex-start'} flexDirection={'row'} alignItems={'center'}>
                                            <CommonEditButton size={"1.2vw"} onClick={this.onEditHandler.bind(this, "InvestigationChart", item)} />
                                            <CommonDeleteButton disable={item?.id ? false : true} size={"1.2vw"} onClick={this.onDeleteHandler.bind(this, "InvestigationChart", item)} />
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            )) :
                                <TableRow>
                                    <TableCell />
                                    <TableCell align='right' sx={{ position: 'relative', left: '6.5vw' }}>No Records To Be Shown</TableCell>
                                    <TableCell />
                                    <TableCell />
                                    <TableCell />
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </Box>
            </Box>
        )
    }

    renderScanObservationTable = () => {
        return (
            <Box>
                <Box component={'div'} border={'1px solid lightgray'} overflow={'auto'}>
                    <Table stickyHeader size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Month / Week</TableCell>
                                <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Scan Name</TableCell>
                                <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Scan Findings</TableCell>
                                <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Remark</TableCell>
                                <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.scanObservationList?.length > 0 ? this.state.scanObservationList?.map((item, index) => (
                                <TableRow key={index} sx={{ backgroundColor: (item?.comments != "" && item?.comments != undefined) ? "#FFE4C4" : 'white' }}>
                                    <TableCell>
                                        <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.month_weeks ? item?.month_weeks : "-"}</Typography>
                                        {item?.comments != "" && item?.comments != undefined ? <Typography fontSize={'0.9vw'} color={Colors.red} fontWeight={600}>{item?.comments ? item?.comments : "-"}</Typography> : null}
                                    </TableCell>
                                    <TableCell><Typography fontSize={'0.9vw'} fontWeight={600}>{item?.scan_name ? item?.scan_name : "-"}</Typography></TableCell>
                                    <TableCell><Typography fontSize={'0.9vw'} fontWeight={600}>{item?.scan_findings ? item?.scan_findings : "-"}</Typography></TableCell>
                                    <TableCell><Typography fontSize={'0.9vw'} fontWeight={600}>{item?.remarks ? item?.remarks : "-"}</Typography></TableCell>
                                    <TableCell>
                                        <Box component={'div'} style={{ marginLeft: "-0.65vw" }} display={'flex'} justifyContent={'flex-start'} flexDirection={'row'} alignItems={'center'}>
                                            <CommonEditButton disable={(item?.comments != "" && item?.comments != undefined) ? true : false} size={"1.2vw"} onClick={this.onEditHandler.bind(this, "ScanObservation", item)} />
                                            <CommonDeleteButton disable={(item?.comments != "" && item?.comments != undefined) ? true : (item?.id ? false : true)} size={"1.2vw"} onClick={this.onDeleteHandler.bind(this, "ScanObservation", item)} />
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            )) :
                                <TableRow>
                                    <TableCell />
                                    <TableCell align='right' sx={{ position: 'relative', left: '6.5vw' }}>No Records To Be Shown</TableCell>
                                    <TableCell />
                                    <TableCell />
                                    <TableCell />
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </Box>
            </Box>
        )
    }

    renderVaccinationTable = () => {
        return (
            <Box>
                <Box component={'div'} border={'1px solid lightgray'} overflow={'auto'}>
                    <Table stickyHeader size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Month / Week</TableCell>
                                <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Vaccination Name</TableCell>
                                <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Remark</TableCell>
                                <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.vaccinationList?.length > 0 ? this.state.vaccinationList?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell><Typography fontSize={'0.9vw'} fontWeight={600}>{item?.month_weeks ? item?.month_weeks : "-"}</Typography></TableCell>
                                    <TableCell><Typography fontSize={'0.9vw'} fontWeight={600}>{item?.vaccination_name ? item?.vaccination_name : "-"}</Typography></TableCell>
                                    <TableCell><Typography fontSize={'0.9vw'} fontWeight={600}>{item?.remarks ? item?.remarks : "-"}</Typography></TableCell>
                                    <TableCell>
                                        <Box component={'div'} style={{ marginLeft: "-0.65vw" }} display={'flex'} justifyContent={'flex-start'} flexDirection={'row'} alignItems={'center'}>
                                            <CommonEditButton size={"1.2vw"} onClick={this.onEditHandler.bind(this, "Vaccination", item)} />
                                            <CommonDeleteButton size={"1.2vw"} onClick={this.onDeleteHandler.bind(this, "Vaccination", item)} />
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            )) :
                                <TableRow>
                                    <TableCell />
                                    <TableCell align='right' sx={{ position: 'relative', left: '6.5vw' }}>No Records To Be Shown</TableCell>
                                    <TableCell />
                                    <TableCell />
                                    <TableCell />
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </Box>
            </Box>
        )
    }

    ClearData() {
        this.setState({
            obs_PICME: "",
            obs_Abortion: "",
            obs_LiveBirth: "",
            obs_Para: "",
            obs_Gravida: "",
            obs_ExpectedDeliveryDate: null,
            obs_LastMenstrualPeriod: null,
            // obs_ExpectedDeliveryDate: new Date(new Date().getTime() + 40 * 7 * 24 * 60 * 60 * 1000),
            // obs_LastMenstrualPeriod: new Date(),
            obs_editid: null,

            obshis_AntePeriod: "",
            obshis_ModeOfDelivery: "",
            obshis_sex: "",
            obshis_BirthWght: "",
            obshis_Outcome: "",
            obshis_NeonatalPeriod: "",
            obshis_date: new Date(),
            obshis_editid: null,
            obshis_deleteid: null,

            PhyExam_weight: "",
            PhyExam_height: "",
            PhyExam_BlodPress: "",
            PhyExam_Complaints: "",
            PhyExam_Presentation: "",
            PhyExam_Remarks: "",
            PhyExam_date: new Date(),
            PhyExam_editid: null,

            invest_name: "",
            InvestigationDataList: [],
            invest_Remarks: "",
            selectedMonthWeek: "",
            invest_date: new Date(),
            invest_editid: null,

            scanObs_Remarks: "",
            scanObs_scanName: "",
            scanObs_ScanFinding: "",
            selectedScanMonth: "",
            scanObs_date: new Date(),
            scan_editid: null,

            vacci_name: "",
            vacci_Remarks: "",
            selectedDateMonth: "",
            vacci_date: new Date(),
            vacci_editid: null,
            vacci_deleteid: null,
        }, () => {
            if (DateTime.fromJSDate(new Date(this.state.vacci_date)).toFormat("yyyy-MM-dd") == DateTime.fromJSDate(new Date(converted_date)).toFormat("yyyy-MM-dd")) {
                var dateFirst = new Date(this.state.selectedLastMenstrualPeriod);
                var dateSecond = new Date(this.state.vacci_date);
                // time difference
                var timeDiff = Math.abs(dateSecond.getTime() - dateFirst.getTime());
                // days difference
                var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
                var weeks = Math.round(diffDays / 7)
                var month = Math.round(weeks / 4)
                var selectedMonthWeek = weeks + "Week" + " / " + month + " Month"
                this.setState({
                    selectedDateMonth: selectedMonthWeek

                })
            }
            if (DateTime.fromJSDate(new Date(this.state.invest_date)).toFormat("yyyy-MM-dd") == DateTime.fromJSDate(new Date(converted_date)).toFormat("yyyy-MM-dd")) {
                var dateFirst = new Date(this.state.selectedLastMenstrualPeriod);
                var dateSecond = new Date(this.state.invest_date);
                // time difference
                var timeDiff = Math.abs(dateSecond.getTime() - dateFirst.getTime());
                // days difference
                var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
                var weeks = Math.round(diffDays / 7)
                var month = Math.round(weeks / 4)
                var selectedMonthWeek = weeks + "Week" + " / " + month + " Month"
                this.setState({
                    selectedMonthWeek: selectedMonthWeek

                })
            }
            if (DateTime.fromJSDate(new Date(this.state.scanObs_date)).toFormat("yyyy-MM-dd") == DateTime.fromJSDate(new Date(converted_date)).toFormat("yyyy-MM-dd")) {
                var dateFirst = new Date(this.state.selectedLastMenstrualPeriod);
                var dateSecond = new Date(this.state.scanObs_date);
                // time difference
                var timeDiff = Math.abs(dateSecond.getTime() - dateFirst.getTime());
                // days difference
                var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
                var weeks = Math.round(diffDays / 7)
                var month = Math.round(weeks / 4)
                var selectedMonthWeek = weeks + "Week" + " / " + month + " Month"
                this.setState({
                    selectedScanMonth: selectedMonthWeek

                })
            }
        })

    }

    PostAntenatalData() {
        try {
            let data = {
                "patient_id": this.state.PatientData.patient_id,
                "appointment_id": this.state.PatientData.appointment_id,
                "last_menstrual_period": this.state.obs_LastMenstrualPeriod ? DateTime.fromJSDate(new Date(this.state.obs_LastMenstrualPeriod)).toFormat("yyyy-MM-dd") : "",
                "expected_date_of_delivery": this.state.obs_ExpectedDeliveryDate ? DateTime.fromJSDate(new Date(this.state.obs_ExpectedDeliveryDate)).toFormat("yyyy-MM-dd") : "",
                "gravida": this.state.obs_Gravida ? +this.state.obs_Gravida : 0,
                "para": this.state.obs_Para ? +this.state.obs_Para : 0,
                "live_births": this.state.obs_LiveBirth ? +this.state.obs_LiveBirth : 0,
                "abortions": this.state.obs_Abortion ? +this.state.obs_Abortion : 0,
                "picme": this.state.obs_PICME
            }

            if (this.state.obs_editid) {
                data["id"] = this.state.obs_editid
                RestAPIService.updateWithOutId(data, Serviceurls.ANTENATAL_OBS_DATA_POST).
                    then((response) => {
                        if (response.data.status === "success") {
                            this.successMessage(response.data.message)
                            this.ClearData()
                            this.GetObstetricData()
                        } else {
                            this.errorMessage(response.data.message)
                        }
                    }).catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                RestAPIService.create(data, Serviceurls.ANTENATAL_OBS_DATA_POST).
                    then((response) => {
                        if (response.data.status === "success") {
                            this.successMessage(response.data.message)
                            this.ClearData()
                            this.GetObstetricData()
                        } else {
                            this.errorMessage(response.data.message)
                        }
                    }).catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.errorMessage(error.message)
                        }
                    })
            }

        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    PostObstetrichistory() {
        try {
            let data = {
                "antenatal_id": this.state.antenatal_id,
                "date_of_visit": this.state.obshis_date ? DateTime.fromJSDate(new Date(this.state.obshis_date)).toFormat("yyyy-MM-dd") : "",
                "antenatal_period": this.state.obshis_AntePeriod ? this.state.obshis_AntePeriod : "",
                "mode_of_delivery": this.state.obshis_ModeOfDelivery?.value ? this.state.obshis_ModeOfDelivery?.value : "",
                "gender": this.state.obshis_sex?.value ? this.state.obshis_sex?.value : "",
                "outcome": this.state.obshis_Outcome ? this.state.obshis_Outcome : "",
                "baby_weight": this.state.obshis_BirthWght ? this.state.obshis_BirthWght : "",
                "neonatal_period": this.state.obshis_NeonatalPeriod ? this.state.obshis_NeonatalPeriod : ""
            }
            if (this.state.obshis_editid) {
                data["id"] = this.state.obshis_editid
                RestAPIService.updateWithOutId(data, Serviceurls.ANTENATAL_OBSTETRIC_HISTORY).
                    then((response) => {
                        if (response.data.status === "success") {
                            this.successMessage(response.data.message)
                            this.ClearData()
                            this.GetObstetricHistory()
                        } else {
                            this.errorMessage(response.data.message)
                        }
                    }).catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                RestAPIService.create(data, Serviceurls.ANTENATAL_OBSTETRIC_HISTORY).
                    then((response) => {
                        if (response.data.status === "success") {
                            this.successMessage(response.data.message)
                            this.ClearData()
                            this.GetObstetricHistory()
                        } else {
                            this.errorMessage(response.data.message)
                        }
                    }).catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.errorMessage(error.message)
                        }
                    })
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    PostInvestigationChartData() {
        try {
            let data = {
                "antenatal_id": this.state.antenatal_id,
                "date_of_visit": this.state.invest_date ? DateTime.fromJSDate(new Date(this.state.invest_date)).toFormat("yyyy-MM-dd") : "",
                "month_weeks": this.state.selectedMonthWeek,
                "remarks": this.state.invest_Remarks,
                "investigation_test": this.state.InvestigationDataList,
            }
            if (this.state.invest_editid) {
                data["id"] = this.state.invest_editid
                RestAPIService.updateWithOutId(data, Serviceurls.ANTENATAL_INVESTIGATION_CHART).
                    then((response) => {
                        if (response.data.status === "success") {
                            this.successMessage(response.data.message)
                            this.ClearData()
                            this.GetObstetricData()
                        } else {
                            this.errorMessage(response.data.message)
                        }
                    }).catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                RestAPIService.create(data, Serviceurls.ANTENATAL_INVESTIGATION_CHART).
                    then((response) => {
                        if (response.data.status === "success") {
                            this.successMessage(response.data.message)
                            this.ClearData()
                            this.GetObstetricData()
                        } else {
                            this.errorMessage(response.data.message)
                        }
                    }).catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.errorMessage(error.message)
                        }
                    })
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    PhysicalExamPost() {
        try {
            var dateFirst = new Date(this.state.updatedLastMenstrualPeriod);
            var dateSecond = new Date(this.state.PhyExam_date);
            // time difference
            var timeDiff = Math.abs(dateSecond.getTime() - dateFirst.getTime());
            // days difference
            var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
            var weeks = Math.round(diffDays / 7)
            let data = {
                "antenatal_id": this.state.antenatal_id,
                "date_of_visit": this.state.PhyExam_date ? DateTime.fromJSDate(new Date(this.state.PhyExam_date)).toFormat("yyyy-MM-dd") : "",
                "weeks": weeks ? weeks : 0,
                "weight": this.state.PhyExam_weight ? this.state.PhyExam_weight : "",
                "blood_pressure": this.state.PhyExam_BlodPress ? this.state.PhyExam_BlodPress : "",
                "height_of_uterus": this.state.PhyExam_height ? this.state.PhyExam_height : "",
                "presentation": this.state.PhyExam_Presentation ? this.state.PhyExam_Presentation : "",
                "remarks": this.state.PhyExam_Remarks ? this.state.PhyExam_Remarks : "",
                "complaints": this.state.PhyExam_Complaints ? this.state.PhyExam_Complaints : ""
            }
            if (this.state.PhyExam_editid) {
                data["id"] = this.state.PhyExam_editid
                RestAPIService.updateWithOutId(data, Serviceurls.ANTENATAL_PHYSICAL_EXAMINATION).
                    then((response) => {
                        if (response.data.status === "success") {
                            this.successMessage(response.data.message)
                            this.ClearData()
                            this.GetPhysicalExamList()
                        } else {
                            this.errorMessage(response.data.message)
                        }
                    }).catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                RestAPIService.create(data, Serviceurls.ANTENATAL_PHYSICAL_EXAMINATION).
                    then((response) => {
                        if (response.data.status === "success") {
                            this.successMessage(response.data.message)
                            this.ClearData()
                            this.GetObstetricData()
                        } else {
                            this.errorMessage(response.data.message)
                        }
                    }).catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.errorMessage(error.message)
                        }
                    })
            }

        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    PostScanObservation() {
        try {
            let data = {
                "antenatal_id": this.state.antenatal_id,
                "date_of_visit": this.state.scanObs_date ? DateTime.fromJSDate(new Date(this.state.scanObs_date)).toFormat("yyyy-MM-dd") : "",
                "month_weeks": this.state.selectedScanMonth,
                "remarks": this.state.scanObs_Remarks,
                "scan_name": this.state.scanObs_scanName ? this.state.scanObs_scanName?.name : "",
                "scan_findings": this.state.scanObs_ScanFinding,
                "scan_id": this.state.scanObs_scanName ? this.state.scanObs_scanName?.id : ""
            }
            if (this.state.scan_editid) {
                data["id"] = this.state.scan_editid
                RestAPIService.updateWithOutId(data, Serviceurls.ANTENATAL_SCAN_OBSERVATION).
                    then((response) => {
                        if (response.data.status === "success") {
                            this.successMessage(response.data.message)
                            this.ClearData()
                            this.GetScanObservationList()
                        } else {
                            this.errorMessage(response.data.message)
                        }
                    }).catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                RestAPIService.create(data, Serviceurls.ANTENATAL_SCAN_OBSERVATION).
                    then((response) => {
                        if (response.data.status === "success") {
                            this.successMessage(response.data.message)
                            this.ClearData()
                            this.GetScanObservationList()
                        } else {
                            this.errorMessage(response.data.message)
                        }
                    }).catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.errorMessage(error.message)
                        }
                    })
            }

        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    PostVaccinationData() {
        try {
            let data = {
                "antenatal_id": this.state.antenatal_id,
                "date_of_visit": this.state.vacci_date ? DateTime.fromJSDate(new Date(this.state.vacci_date)).toFormat("yyyy-MM-dd") : "",
                "month_weeks": this.state.selectedDateMonth,
                "remarks": this.state.vacci_Remarks,
                "vaccination_name": this.state.vacci_name
            }
            if (this.state.vacci_editid) {
                data["id"] = this.state.vacci_editid
                RestAPIService.updateWithOutId(data, Serviceurls.ANTENATAL_VACCINATION).
                    then((response) => {
                        if (response.data.status === "success") {
                            this.successMessage(response.data.message)
                            this.ClearData()
                            this.GetVaccinationData()
                        } else {
                            this.errorMessage(response.data.message)
                        }
                    }).catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                RestAPIService.create(data, Serviceurls.ANTENATAL_VACCINATION).
                    then((response) => {
                        if (response.data.status === "success") {
                            this.successMessage(response.data.message)
                            this.ClearData()
                            this.GetVaccinationData()
                        } else {
                            this.errorMessage(response.data.message)
                        }
                    }).catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.errorMessage(error.message)
                        }
                    })
            }

        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    PostScanObservation() {
        try {
            let data = {
                "antenatal_id": this.state.antenatal_id,
                "date_of_visit": this.state.scanObs_date ? DateTime.fromJSDate(new Date(this.state.scanObs_date)).toFormat("yyyy-MM-dd") : "",
                "month_weeks": this.state.selectedScanMonth,
                "remarks": this.state.scanObs_Remarks,
                "scan_name": this.state.scanObs_scanName ? this.state.scanObs_scanName?.name : "",
                "scan_findings": this.state.scanObs_ScanFinding,
                "scan_id": this.state.scanObs_scanName ? this.state.scanObs_scanName?.id : ""

            }
            if (this.state.scan_editid) {
                data["id"] = this.state.scan_editid
                RestAPIService.updateWithOutId(data, Serviceurls.ANTENATAL_SCAN_OBSERVATION).
                    then((response) => {
                        if (response.data.status === "success") {
                            this.successMessage(response.data.message)
                            this.ClearData()
                            this.GetScanObservationList()
                        } else {
                            this.errorMessage(response.data.message)
                        }
                    }).catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                RestAPIService.create(data, Serviceurls.ANTENATAL_SCAN_OBSERVATION).
                    then((response) => {
                        if (response.data.status === "success") {
                            this.successMessage(response.data.message)
                            this.ClearData()
                            this.GetScanObservationList()
                        } else {
                            this.errorMessage(response.data.message)
                        }
                    }).catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.errorMessage(error.message)
                        }
                    })
            }

        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    completeAntenentalChart(comment) {
        try {
            let data = {
                "antenatal_id": this.state.antenatal_id,
                "remarks": comment
            }
            RestAPIService.create(data, Serviceurls.ANTENATAL_COMPLETE).
                then((response) => {
                    if (response.data.status === "success") {
                        this.successMessage(response.data.message)
                        this.setState({
                            obstetricDataList: [],
                            ObsHistoryData: [],
                            PhysicalExaminationList: [],
                            investigationChartList: [],
                            scanObservationList: [],
                            vaccinationList: [],
                            isObstetricDataNew: false
                        }, () => {
                            // this.GetObstetricData()
                            // this.GetObstetricHistory()
                            // this.GetPhysicalExamList()
                            // this.GetInvestigationList()
                            // this.GetScanObservationList()
                            // this.GetInvestigationList()
                        })
                    } else {
                        this.errorMessage(response.data.message)
                    }
                }).catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })

        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    ObstetricDataRight = () => {
        return (
            <Box>
                <Typography className='eMed_refdoc_title'>{"Obstetric Data"}</Typography>
                {this.returnTextField("PICME", "obs_PICME", this.state.obs_PICME, undefined, undefined, 'only number', 15)}
                {this.returnTextField("Gravida *", "obs_Gravida", this.state.obs_Gravida, undefined, undefined, 'only number', 15, this.state.obs_editid)}
                {this.returnTextField("Para *", "obs_Para", this.state.obs_Para, undefined, undefined, 'only number', 15, this.state.obs_editid)}
                {this.returnTextField("Live Birth *", "obs_LiveBirth", this.state.obs_LiveBirth, "13vw", "7vw", 'only number', 3, this.state.obs_editid)}
                {this.returnTextField("Abortion *", "obs_Abortion", this.state.obs_Abortion, undefined, undefined, 'only number', 3, this.state.obs_editid)}
                {this.returnDateField("Last Menstrual Period *", "obs_LastMenstrualPeriod", this.state.obs_LastMenstrualPeriod, '10vw', '15vw', this.state.obs_editid)}
                {this.returnDateField("Expected date of delivery *", "obs_ExpectedDeliveryDate", this.state.obs_ExpectedDeliveryDate, '10vw', '15vw', this.state.obs_editid)}
                <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                    <Button variant='outlined' onClick={() => { this.ClearData() }}>Clear</Button>
                    <Button sx={{ marginLeft: "1vw" }} variant='contained' onClick={() => {
                        if (this.state.obs_LastMenstrualPeriod && this.state.obs_ExpectedDeliveryDate && this.state.obs_Gravida && this.state.obs_Para && this.state.obs_LiveBirth) {
                            this.PostAntenatalData()
                        } else {
                            this.errorMessage("Enter all mandatory fields")
                        }
                    }}>Add</Button>
                </Box>
            </Box>
        )
    }

    PreviousAntenentalRight = () => {
        return (
            <Box>
                <Typography className='eMed_refdoc_title'>{"Previous Antenental"}</Typography>
                {
                    (this.state.previousObstetricData && this.state.previousObstetricData.length > 0) ?
                        <Typography sx={{ margin: "1vw", fontSize: "0.8vw", color: "gray" }}>{"Select the previous antenatal date"}</Typography>
                        : <Typography sx={{ margin: "1vw", marginLeft: "10vw", marginTop: "2vw", fontSize: "1vw", color: "gray" }}>{"No previous antenatal found."}</Typography>

                }
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    {this.state.previousObstetricData && this.state.previousObstetricData.length > 0 && this.state.previousObstetricData.map((item, index) => {
                        var date = moment(item.last_menstrual_period).format("YYYY-MM-DD")
                        return (
                            <Box>
                                <Button
                                    sx={{ margin: "1vw" }}
                                    variant={this.state.selectedPreviousData == item.last_menstrual_period ? "contained" : "outlined"}
                                    onClick={() => {
                                        var { previousObstetricData, obstetricDataList } = this.state
                                        var obstetricDataLists = []
                                        if (this.state.selectedPreviousData == item.last_menstrual_period) {
                                            this.setState({
                                                selectedPreviousData: item.last_menstrual_period,
                                                obstetricDataList: [],
                                                selectedPreviousData: ""
                                            }, () => {
                                                for (let i = 0; i < obstetricDataList.length; i++) {
                                                    if (obstetricDataList[i].antenatal_obstetric.last_menstrual_period === this.state.selectedPreviousData) {
                                                        obstetricDataLists.splice(obstetricDataList[i])
                                                        this.setState({
                                                            obstetricDataList: obstetricDataLists,
                                                        })
                                                    }

                                                }
                                            })
                                        } else {
                                            this.setState({
                                                selectedPreviousData: item.last_menstrual_period,
                                                obstetricDataList: []
                                            }, () => {
                                                for (let i = 0; i < previousObstetricData.length; i++) {
                                                    if (previousObstetricData[i].antenatal_obstetric.last_menstrual_period === this.state.selectedPreviousData) {
                                                        obstetricDataLists.push({ "antenatal_obstetric": previousObstetricData[i].antenatal_obstetric })
                                                        var remarks = previousObstetricData[i].antenatal_obstetric.remarks
                                                        this.setState({
                                                            obstetricDataList: obstetricDataLists,
                                                            overAllRemark: remarks,
                                                            prevSelectedID: previousObstetricData[i].antenatal_obstetric.id
                                                        }, () => {
                                                            this.getPreviousObstrtricData()
                                                        })
                                                    }

                                                }
                                            })
                                        }
                                    }}>{date}</Button>
                            </Box>
                        )
                    })}
                </Box>
            </Box>
        )
    }

    ObstetricHistoryRight = () => {
        return (
            <Box>
                <Typography className='eMed_refdoc_title'>{"Obstetric History"}</Typography>
                {this.returnDateField("Date *", "obshis_date", this.state.obshis_date)}
                {this.returnTextField("Antenatal Period (Days) *", "obshis_AntePeriod", this.state.obshis_AntePeriod, "10vw", "15vw", 'only number', 4)}
                {this.returnAutoComplete("Mode of Delivery *", "obshis_ModeOfDelivery", ModeOfDeliveryTypes, "label", "15vw", "10vw")}
                {this.returnAutoComplete("Gender *", "obshis_sex", GenderDataList, "label", "10vw", "15vw")}
                {this.returnTextField("Birth Weight (kg) *", "obshis_BirthWght", this.state.obshis_BirthWght, "10vw", "15vw", 'only number below 11', 7)}
                {this.returnTextField("Outcome *", "obshis_Outcome", this.state.obshis_Outcome, "10vw", "15vw", '', 30)}
                {this.returnTextField("Neonatal Period (Days) *", "obshis_NeonatalPeriod", this.state.obshis_NeonatalPeriod, "10vw", "15vw", 'only number', 4)}
                <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                    <Button variant='outlined' onClick={() => { this.ClearData() }}>Clear</Button>
                    <Button sx={{ marginLeft: "1vw" }} variant='contained' onClick={() => {
                        if (this.state.obshis_date && this.state.obshis_AntePeriod && this.state.obshis_ModeOfDelivery && this.state.obshis_sex && this.state.obshis_Outcome && this.state.obshis_NeonatalPeriod && this.state.obshis_BirthWght) {
                            this.PostObstetrichistory()
                        } else {
                            this.errorMessage("Enter all mandatory fields")
                        }
                    }}>Add</Button>
                </Box>
            </Box>
        )
    }

    PhysicalExaminationRight = () => {
        return (
            <Box>
                <Typography className='eMed_refdoc_title'>{"Physical Examination"}</Typography>
                <Box sx={{ height: "65vh", overflow: 'auto' }}>
                    {this.returnDateField("Date *", "PhyExam_date", this.state.PhyExam_date)}
                    {this.returnTextField("Weight (kg) *", "PhyExam_weight", this.state.PhyExam_weight, "10vw", "15vw", 'only number', 4)}
                    {this.returnTextField("Height (cm) *", "PhyExam_height", this.state.PhyExam_height, "10vw", "15vw", 'only number', 4)}
                    {this.returnTextField("Blood Pressure (mmHg) *", "PhyExam_BlodPress", this.state.PhyExam_BlodPress, "10vw", "15vw", 'only number with slash', 20)}
                    {this.returnMultiTextField("Complaints *", "PhyExam_Complaints", this.state.PhyExam_Complaints, "20vw", "7vw")}
                    {this.returnMultiTextField("Presentation *", "PhyExam_Presentation", this.state.PhyExam_Presentation, "20vw", "7vw")}
                    {this.returnMultiTextField("Remarks *", "PhyExam_Remarks", this.state.PhyExam_Remarks, "20vw", "7vw")}
                    <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                        <Button variant='outlined' onClick={() => { this.ClearData() }}>Clear</Button>
                        <Button sx={{ marginLeft: "1vw" }} variant='contained' onClick={() => {
                            if (this.state.PhyExam_date && this.state.PhyExam_weight && this.state.PhyExam_BlodPress && this.state.PhyExam_height && this.state.PhyExam_Presentation && this.state.PhyExam_Remarks && this.state.PhyExam_Complaints) {
                                this.PhysicalExamPost()
                            } else {
                                this.errorMessage("Enter all mandatory fields")
                            }
                        }}>Add</Button>
                    </Box>
                </Box>
            </Box>
        )
    }

    ScanObservationRight = () => {
        return (
            <Box>
                <Typography className='eMed_refdoc_title'>{"Scan Observation"}</Typography>
                {this.returnDateField("Date *", "scanObs_date", this.state.scanObs_date, "20vw", "6vw")}
                {this.returnAutoComplete("Scan Name *", "scanObs_scanName", this.state.ScanDataList, "name", "20vw", "7vw")}
                {this.returnMultiTextField("Scan Finding *", "scanObs_ScanFinding", this.state.scanObs_ScanFinding, "20vw", "7vw")}
                {this.returnMultiTextField("Remarks *", "scanObs_Remarks", this.state.scanObs_Remarks, "20vw", "7vw")}
                <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                    <Button variant='outlined' onClick={() => { this.ClearData() }}>Clear</Button>
                    <Button sx={{ marginLeft: "1vw" }} variant='contained' onClick={() => {
                        if (this.state.scanObs_date && this.state.scanObs_Remarks && this.state.scanObs_scanName?.name && this.state.scanObs_ScanFinding) {
                            this.PostScanObservation()
                        } else {
                            this.errorMessage("Enter all mandatory fields")
                        }
                    }}>Add</Button>
                </Box>
            </Box>
        )
    }

    VaccinationRight = () => {
        return (
            <Box>
                <Typography className='eMed_refdoc_title'>{"Vaccination"}</Typography>
                {this.returnDateField("Date *", "vacci_date", this.state.vacci_date, "20vw", "6vw")}
                {this.returnTextField("Vaccination Name *", "vacci_name", this.state.vacci_name, "20vw", "7vw", '', 40)}
                {this.returnMultiTextField("Remarks *", "vacci_Remarks", this.state.vacci_Remarks, "20vw", "7vw")}
                <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                    <Button variant='outlined' onClick={() => { this.ClearData() }}>Clear</Button>
                    <Button sx={{ marginLeft: "1vw" }} variant='contained' onClick={() => {
                        if (this.state.vacci_date && this.state.vacci_Remarks && this.state.vacci_name) {
                            this.PostVaccinationData()
                        } else {
                            this.errorMessage("Enter all mandatory fields")
                        }
                    }}>Add</Button>
                </Box>
            </Box>
        )
    }

    InvestigationChartRight = () => {
        return (
            <Box>
                <Typography className='eMed_refdoc_title'>{"Investigation Chart"}</Typography>
                <Box sx={{ height: "65vh", overflow: 'auto' }}>
                    {this.returnDateField("Date *", "invest_date", this.state.invest_date, "20vw", "6vw")}
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                        {this.returnTextField("Investigation Name ", "invest_name", this.state.invest_name, "15vw", "10vw", "", 30)}
                        <Button
                            disabled={!this.state.invest_name}
                            variant='contained'
                            size='small'
                            onClick={() => {
                                let List = this.state.InvestigationDataList
                                List.push({ name: this.state.invest_name, value: "", id: "" })
                                this.setState({
                                    invest_name: "",
                                    InvestigationDataList: List
                                })
                            }}>Add</Button>
                    </Box>
                    {
                        this.state.InvestigationDataList?.length > 0 ? this.state.InvestigationDataList?.map((list, index) => (
                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: 'flex-start', marginLeft: '1vw' }}>
                                <Typography>{list.name}</Typography>
                                <TextField
                                    label={"Investigation Value"}
                                    size='small'
                                    sx={{ marginLeft: "1vw", marginY: "0.5vw", width: "10vw" }}
                                    onChange={(e) => {
                                        let Data = this.state.InvestigationDataList
                                        Data[index].value = e.target.value
                                        this.setState({ InvestigationDataList: Data })
                                    }}
                                    inputProps={{ maxLength: 250 }}
                                    value={list.value ? list.value : ""} />
                                <IconButton onClick={() => {
                                    let Data = this.state.InvestigationDataList
                                    Data.splice(index, 1)
                                    this.setState({ InvestigationDataList: Data })
                                }}>
                                    <DeleteIcon color='error' />
                                </IconButton>
                            </Box>
                        )) : <Typography sx={{ marginLeft: "10vw", fontSize: "0.9vw" }}>No Investigations were added.</Typography>
                    }
                    {this.returnMultiTextField("Remarks *", "invest_Remarks", this.state.invest_Remarks, "20vw", "7vw")}
                    <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                        <Button variant='outlined' onClick={() => { this.ClearData() }}>Clear</Button>
                        <Button sx={{ marginLeft: "1vw" }} variant='contained' onClick={() => {
                            if (this.state.invest_date && this.state.invest_Remarks && this.state.InvestigationDataList && this.state.InvestigationDataList.length > 0) {
                                this.PostInvestigationChartData()
                            } else {
                                this.errorMessage("Enter all mandatory fields")
                            }
                        }}>Add</Button>
                    </Box>
                </Box>
            </Box>
        )
    }

    returnRightSide() {
        switch (this.state.SelectedTab) {
            case "ObstetricData": return this.ObstetricDataRight()
            case "PreviousAntenental": return this.PreviousAntenentalRight()
            case "ObstetricHistory": return this.ObstetricHistoryRight()
            case "PhysicalExamination": return this.PhysicalExaminationRight()
            case "InvestigationChart": return this.InvestigationChartRight()
            case "ScanObservation": return this.ScanObservationRight()
            case "Vaccination": return this.VaccinationRight()
        }
    }

    obsHistoryDelete = () => {
        const { t } = this.props
        try {
            RestAPIService.delete(Serviceurls.ANTENATAL_OBSTETRIC_HISTORY + "?id=" + this.state.obshis_deleteid).
                then((response) => {
                    if (response.data.status === "success") {
                        this.successMessage(response.data.message)
                        this.GetObstetricHistory()
                        this.popupClose()
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })

        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    PhysicalExaminationDelete = () => {
        const { t } = this.props
        try {
            RestAPIService.delete(Serviceurls.ANTENATAL_PHYSICAL_EXAMINATION + "?id=" + this.state.PhyExam_deleteid).
                then((response) => {
                    if (response.data.status === "success") {
                        this.successMessage(response.data.message)
                        this.GetPhysicalExamList()
                        this.popupClose()
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })

        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    InvestigationChartDelete = () => {
        const { t } = this.props
        try {
            RestAPIService.delete(Serviceurls.ANTENATAL_INVESTIGATION_CHART + "?id=" + this.state.invest_deleteid).
                then((response) => {
                    if (response.data.status === "success") {
                        this.successMessage(response.data.message)
                        this.setState({
                            investigationChartList: []
                        }, () => {
                            this.GetObstetricData()
                        })
                        this.popupClose()
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })

        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    VaccinationDelete = () => {
        const { t } = this.props
        try {
            RestAPIService.delete(Serviceurls.ANTENATAL_VACCINATION + "?id=" + this.state.vacci_deleteid).
                then((response) => {
                    if (response.data.status === "success") {
                        this.successMessage(response.data.message)
                        this.setState({
                            vaccinationList: []
                        }, () => {
                            this.GetObstetricData()
                        })
                        this.popupClose()
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })

        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    ScanObservationDelete = (comments) => {
        const { t } = this.props
        try {
            let data = { comments: comments, id: this.state.scan_deleteid }
            RestAPIService.delete(Serviceurls.ANTENATAL_SCAN_OBSERVATION, data).
                then((response) => {
                    if (response.data.status === "success") {
                        this.successMessage(response.data.message)
                        this.setState({
                            scanObservationList: []
                        }, () => {
                            this.GetObstetricData()
                        })
                        this.popupClose()
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })

        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    DeleteAntenatal = () => {
        switch (this.state.isDeleteCliked) {
            case "ObstetricHistory":
                this.obsHistoryDelete()
                break;
            case "PhysicalExamination":
                this.PhysicalExaminationDelete()
                break;
            case "InvestigationChart":
                this.InvestigationChartDelete()
                break;
            case "ScanObservation":
                this.ScanObservationDelete()
                break;
            case "Vaccination":
                this.VaccinationDelete()
                break;

        }
    }

    popupClose = () => {
        this.setState({
            isDeleteCliked: null,
            obshis_deleteid: null,
            PhyExam_deleteid: null,
            invest_deleteid: null,
            scan_deleteid: null,
            isDeleteReasonCliked: null,
            isObstetricDataNew: false,
        })
    }

    PrintAnetentalChart = () => {
        try {
            RestAPIService.getAll(Serviceurls.ANTENATAL_CHART_PRINT + "?antenatal_id=" + this.state.antenatal_id)
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    render() {
        const { t } = this.props
        return (
            <Box sx={{ display: 'flex', flexDirection: "row" }}>
                <Box sx={{ backgroundColor: "#F5F8F7" }} className="eMed_Chart_Layout" flex={0.65}>
                    <Box sx={{ height: "71vh", overflow: "auto" }}>
                        <Box sx={{ display: 'flex', justifyContent: "flex-end" }}>
                            <Button
                                sx={{ visibility: this.state.antenatal_id ? "visible" : "hidden" }}
                                className='Common_Btn_Min_Width'
                                onClick={() => { this.PrintAnetentalChart() }}
                            >
                                <Tooltip placement='top' title='Print'>
                                    <Box component={'img'} src={ImagePaths.PrintIcons.default} height={'1.5vw'} width={'1.5vw'} />
                                </Tooltip>
                            </Button>
                        </Box>
                        <Box className="eMed_Ante_Chart_Tabs">
                            {this.anatentalMainHeader("Obstetric Data", "ObstetricData")}
                            {this.renderObsdataTable()}
                        </Box>
                        <Box className="eMed_Ante_Chart_Tabs">
                            {this.anatentalMainHeader("Obstetric History", "ObstetricHistory")}
                            {this.renderObstetricHistoryTable()}
                        </Box>
                        <Box className="eMed_Ante_Chart_Tabs">
                            {this.anatentalMainHeader("Physical Examination", "PhysicalExamination")}
                            {this.renderPhysicalExaminationTable()}
                        </Box>
                        <Box className="eMed_Ante_Chart_Tabs">
                            {this.anatentalMainHeader("Investigation Chart", "InvestigationChart")}
                            {this.renderInvestigationChartTable()}
                        </Box>
                        <Box className="eMed_Ante_Chart_Tabs">
                            {this.anatentalMainHeader("Scan Observation", "ScanObservation")}
                            {this.renderScanObservationTable()}
                        </Box>
                        <Box className="eMed_Ante_Chart_Tabs">
                            {this.anatentalMainHeader("Vaccination", "Vaccination")}
                            {this.renderVaccinationTable()}
                        </Box>
                        <Box className="eMed_Ante_Chart_Tabs">
                            <Box sx={{ display: 'flex',margin: "0.5vw", flexDirection:"column" }}>
                                <Typography sx={{ width: "20vw", fontWeight: 600, fontSize:"0.8vw", marginTop:"0.3vw" }}>{"Remarks"}</Typography>
                                <TextField
                                    size='small'
                                    label={"Remarks"}
                                    disabled={true}
                                    sx={{ marginLeft: "1vw", marginY: "0.5vw", width: "57vw" }}
                                    inputProps={{ maxLength: 250 }}
                                    value={this.state.CompletedReason ? this.state.CompletedReason : ""}
                                    multiline={true}
                                    rows={3} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box className="eMed_Chart_Layout" sx={{}} flex={0.35}>
                    {this.returnRightSide()}
                </Box>
                <Loader loaderOpen={this.state.isLoader} />
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.isDeleteCliked ? <DeletePopup
                    DeletTitle={`Are you sure ? Do you want to delete?`}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this.DeleteAntenatal.bind(this)}
                /> : null}
                {this.state.isDeleteReasonCliked ? <ReasonPopup
                    title={"Are you sure? Do you want to delete"}
                    AlertPopupClose={this.popupClose.bind(this)}
                    label={"Enter the Reason"}
                    btntext={'Cancel'}
                    btnvarient={'outlined'}
                    btncolor={'error'}
                    btntext1={'Confirm'}
                    btnvarient1={'contained'}
                    sendCmt={this.ScanObservationDelete.bind(this)} /> : null}
                {this.state.isObstetricDataNew ? <ReasonPopup
                    title={"Are you sure ? Do you want to complete this Obstetric Data ?"}
                    AlertPopupClose={this.popupClose.bind(this)}
                    label={"Enter the Reason"}
                    btntext={'Cancel'}
                    btnvarient={'outlined'}
                    btncolor={'error'}
                    btntext1={'Complete'}
                    btnvarient1={'contained'}
                    sendCmt={this.completeAntenentalChart.bind(this)} /> : null}
            </Box>
        )
    }
}

export default withTranslation()(AntenatalChart)