import React, { Component } from 'react';
import LabHome from './Home';
import { Box, Typography, Paper } from "@mui/material";
import { CommonNavigationHeader } from '../../../Components/Common Components/CommonComponents';
import Status from '../Home/Status';
import TestReport from './TestReport';
import Avatar from '@mui/material/Avatar';
import ImagePaths from '../../../Utility/ImagePaths';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { clearCachevalue, localGetItem, setCachevalue } from '../../../Utility/Services/CacheProviderService';
import { withTranslation } from 'react-i18next';
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';
import ForTechnicians from './ForTechnicians';
import OutsourceList from './OutsourceList';
import LabCancelledBill from './LabCancelledBill';

const navigationHeaderList = [
    { id: 0, TabName: "Create Bill", value: "LabHome", content: <LabHome /> },
    { id: 1, TabName: "Status", value: "Status", content: <Status /> },
    { id: 2, TabName: "Test Report", value: "TestReport", content: <LabHome /> },
    { id: 3, TabName: "For Technicians", value: "ForTechnicians", content: <ForTechnicians /> },
    { id: 4, TabName: "Outsource List", value: "OutsourceList", content: <OutsourceList /> },
    { id: 5, TabName: "Cancelled Bill", value: "CancelledBill", content: <LabCancelledBill /> },
]

class MainHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedScreen: props.location?.state?.selectedScreen ? props.location?.state.selectedScreen : "LabHome",
            savedBillDetails: {},
            searchPatientDetails: [],
            statusDetailList: {},
            isPatientSearch: true,
            selectedPatientDetails: {},
            search_key: "",
            selectedTabID: props.location?.state?.selectedTabID ? props.location?.state.selectedTabID : 0,
            isTabNavigation: false,
            savedTransmitList:{},
            labType: "",
            clinic_id : null,
            isTestReport: false,
            selectedPatientDatas:this.props.history?.location?.state?.selectedPatientData ? this.props.history?.location?.state?.selectedPatientData : {},
            NewLogin: false,
            selectedLabPatient: null,
            fromResult:this.props.history?.location?.state?.fromResultSaved ? this.props.history?.location?.state?.fromResultSaved : false
        };
    }

    componentDidMount(){
        this.props.changeScreen('1')
        let subScreen = this.state.selectedScreen == "Status" ? "Status" : this.state.selectedScreen === "TestReport" ? "TestReport" : "LabHome"
        this.props.subScreenChange(subScreen)
        if(localGetItem("isLoggedIn")){
            let loginProfile = localGetItem("loggedInUserInfo");
            let user = JSON.parse(loginProfile)
            this.setState({
                labType : user.laboratory_type
            })
        }
    }

    componentDidUpdate(prevProps) {
        const { location } = this.props 
        if(location?.state && location?.state?.savedBillDetails){
            if(this.state.savedBillDetails != location.state.savedBillDetails) { 
            var { savedBillDetails } = location.state
            this.setState({
                savedBillDetails:  savedBillDetails,
                selectedScreen: "LabHome",
                selectedTabID: 0,
                isTestReport: false
            })
           }
        }
        if(location && location.state && location.state.clinic_id){
            if(this.state.clinic_id != location.state.clinic_id){
                let {clinic_id} = location.state
                this.setState({
                    clinic_id : clinic_id
                })
            }
        }
        if(location && location.state && location.state.NewLogin){
            if(this.state.NewLogin != location.state.NewLogin){
                let {NewLogin} = location.state
                this.setState({
                    NewLogin : NewLogin
                }, () => {
                    if(this.state.NewLogin) {
                        // this.props.getSavedBillStatus(true)
                        // this.props.getTransmitBillCount(true)
                    }
                })
            }
        }
        if(location?.state && location?.state?.savedTransmitList){
            if(this.state.savedTransmitList !== location.state.savedTransmitList){
                let {savedTransmitList} = location.state
                this.setState({
                    savedTransmitList : savedTransmitList,
                    selectedScreen:"LabHome",
                    selectedTabID:0,
                    isTestReport: false
                })
            }
        }
        if( (location && location.state && location.state.search_key) || (location.state && location.state.search_key == "") ) {
            if(this.state.search_key != location.state.search_key) { 
             this.setState({
                search_key: location.state.search_key ? location.state.search_key : "" ,
                isTestReport: false
             }, () => {
                 if(this.state.search_key == "" && this.state.selectedScreen == "LabHome") {
                     this.setState({
                        searchPatientDetails: []
                     })
                 } else {
                    if(this.state.selectedScreen == "LabHome") {
                        this.searchPatientDetails(this.state.search_key)
                    } 
                 }
             })
            } 
        }  

        if(this.props.history?.location?.state?.selectedPatientData && prevProps !== this.props){
            if(this.state.selectedPatientDatas !== this.props.history?.location?.state?.selectedPatientData){
                let details = this.props.history?.location?.state?.selectedPatientData
                this.setState({
                    selectedLabPatient:details ? details.patient_id : null
                    
                }, ()=>{ this.props.history.push({ state: {} }) })
            }
        }
        if(this.props.history?.location?.state?.Patient_ID && prevProps !== this.props){
                let details = this.props.history?.location?.state?.Patient_ID
                this.setState({
                    selectedLabPatient:details ? details : null
                }, ()=>{ this.props.history.push({ state: {} }) })
        }
    }
    searchPatientDetails = (search_key) => {
        var search_key = search_key ? search_key : ""
        let serviceUrl = ""
        if( this.state.labType == "Standalone Laboratory") {
               serviceUrl = Serviceurls.LAB_SEARCH_PATIENT + "?search_key=" + search_key
        } else {
               serviceUrl = Serviceurls.LAB_PATIENT_TRANSMIT_SEARCH + "?search_key=" + search_key

        }
        RestAPIService.getAll(serviceUrl)
            .then(response => {
                if (response.data.status == "success") {
                    var data = response.data.data
                    this.setState({
                        searchPatientDetails: data,
                        isPatientSearch: true
                    });
                } else if (response.data.status == "fail") {
                    // this.showToastBox(true, response.data.message, Constants.ErrorToast)
                }
            })
            .catch(e => {
                //  this.showToastBox(true, e.response.data.message, Constants.ErrorToast)
            });
      }
    NavigateScreen (id) {
        if(id === 0){
            const { history } = this.props
            history.push({ pathname: "/MainHome" })
        }
        this.setState({
            selectedScreen: navigationHeaderList[id].value,
            selectedTabID: id,
            isTabNavigation: true
        },() => {
           if(this.state.isTabNavigation) {
            this.setState({
                statusDetailList: {}
            })
           }        
        })
        this.props.subScreenChange(navigationHeaderList[id].value)
    }
    selectedTab = ( value, statusDetailList, isReceive, isTestReport) => {
        this.props.subScreenChange("LabHome")
        this.props.getSavedBillStatus(true)
        this.props.getTransmitBillCount(true)
        this.setState({
            selectedScreen: "LabHome",
            statusDetailList: statusDetailList,
            selectedTabID: 0,
            isTabNavigation: isReceive == "isReceivePayment" ? false : null,
            isTestReport: isTestReport
        })
    }
    getSavedBillStatus = (flag) => {
        this.props.getSavedBillStatus(flag)
    }
    getTransmitBillCount = (flag) => {
        this.props.getTransmitBillCount(flag)
    }

    ClearPatientData = () => {
        this.setState({
            selectedLabPatient: null,
        })
    }

    cancelBillClick = (Data) => {
        var { history } = this.props
        if(Data?.isForEditBill){
            setCachevalue(true, "isForCancelBill")
            setCachevalue(JSON.stringify({ ForCancel: true, cancelBillData: Data, FromStatus: true, isForEditBill : true }), "LabCancelBilldata")
            history.push({ pathname: "/Billing/LabBilling", state: { ForCancel: true, cancelBillData: Data, isForEditBill : true } })
        }else{
            setCachevalue(true, "isForCancelBill")
            setCachevalue(JSON.stringify({ ForCancel: true, cancelBillData: Data, FromStatus: true }), "LabCancelBilldata")
            history.push({ pathname: "/Billing/LabBilling", state: { ForCancel: true, cancelBillData: Data } })
        }
    }
    
    render() {
        const {t} = this.props;
        return (
            <Box className='lab_home_main_header'>
                <CommonNavigationHeader menu={navigationHeaderList} NavigateScreen={this.NavigateScreen.bind(this)} selectedTabID = {this.state.selectedTabID}/>
                <div className='MainLabHome' style={{ zIndex: this.state.search_key != "" && this.state.searchPatientDetails && this.state.searchPatientDetails.length > 0 && this.state.isPatientSearch ? -1000 : null }}>
                    {(this.state.selectedScreen === "LabHome" && CheckAccessFunc("Laboratory", "Home", "Create Bill", null, "Tab")?.viewAccess) ? <> <LabHome
                        savedBillDetails={this.state.savedBillDetails}
                        selectedPatientDetails={this.state.selectedPatientDatas ? this.state.selectedPatientDatas : this.state.selectedPatientDetails}
                        statusDetailList={this.state.statusDetailList}
                        savedTransmitList={this.state.savedTransmitList}
                        clinic_id={this.props.clinic_id}
                        isTestReport={this.state.isTestReport}
                        getSavedBillStatus={this.getSavedBillStatus}
                        getTransmitBillCount={this.getTransmitBillCount}
                        selectedLabPatient={this.state.selectedLabPatient}
                        ClearPatientData={this.ClearPatientData}
                    />
                        {clearCachevalue('filteredData')}</>
                        :
                        (this.state.selectedScreen === "Status" && CheckAccessFunc("Laboratory", "Home", "Status", null, "Tab")?.viewAccess) ? <><Status
                            selectedTab={this.selectedTab}
                            search_key={this.state.search_key}
                            getSavedBillStatus={this.getSavedBillStatus}
                            getTransmitBillCount={this.getTransmitBillCount}
                            cancelBillClick={this.cancelBillClick}
                            clinic_id={this.props.clinic_id}
                        />
                            {clearCachevalue('filteredData')}</> :
                            (this.state.selectedScreen === "TestReport" && CheckAccessFunc("Laboratory", "Home", "Test Report", null, "Tab")?.viewAccess) ?
                                <><TestReport
                                    search_key={this.state.search_key}
                                    selectedTab={this.selectedTab}
                                    clinic_id={this.props.clinic_id}
                                />
                                    {clearCachevalue('filteredData')}</> :
                                (this.state.selectedScreen === "ForTechnicians" && CheckAccessFunc("Laboratory", "Home", "For Technicians", null, "Tab")?.viewAccess) ?
                                    <><ForTechnicians
                                        search_key={this.state.search_key}
                                        selectedTab={this.selectedTab}
                                        clinic_id={this.props.clinic_id}
                                        fromResultSaved={this.state.fromResult}
                                    />
                                        {this.state.fromResult ? null : clearCachevalue('filteredData')}</>
                                    : (this.state.selectedScreen === "OutsourceList" && CheckAccessFunc("Laboratory", "Home", "Outsource List", null, "Tab")?.viewAccess) ?
                                        <><OutsourceList
                                            search_key={this.state.search_key}
                                            selectedTab={this.selectedTab}
                                            clinic_id={this.props.clinic_id}
                                        />
                                            {clearCachevalue('filteredData')}</> :
                                        (this.state.selectedScreen === "CancelledBill" && CheckAccessFunc("Laboratory", "Home", "Cancelled Bill", null, "Tab")?.viewAccess) ?
                                            <>
                                                <LabCancelledBill />
                                                {clearCachevalue('filteredData')}
                                            </>
                                            :
                                            <><Typography className='eMed_NoAccess_Text' sx={{ paddingTop: "13vw" }}>No Access For This Screen</Typography>
                                                {clearCachevalue('filteredData')}</>
                    }
                </div>
                {this.state.search_key != "" && this.state.searchPatientDetails && this.state.searchPatientDetails.length > 0 && this.state.isPatientSearch && this.state.selectedScreen === "LabHome" ?
                    <div className='MainSubContainer'>
                        <Paper className={this.state.labType === "Standalone Laboratory" ?  "Patient_Details_Search"  : "Patient_Details_Search lab_rajesh"}>
                            {this.state.searchPatientDetails && this.state.searchPatientDetails.length > 0 && this.state.searchPatientDetails.map((item, index) => {
                                return (
                                    <div className='Lab_Home_Saved_Bill' >
                                        <Avatar id='eMed_IconAvatar' src={item.photo_url || item.patient_photo_url ? item.photo_url || item.patient_photo_url : ImagePaths.Default} sx={{ boxShadow: 3, minWidth: '13%', marginTop: '1vh', marginRight: '2vh', marginBottom: '1vh' }} />
                                        <div style={{ minWidth: '55%' }}>
                                            <Typography sx={{ margin: "0vw" }}>{item.first_name || item.patient_name}</Typography>
                                            <div className='search_sub_container'>
                                                <Typography className='savedTextLight' sx={{ fontSize: "0.8vw" }}>{item.mobile_number || item.patient_mobile_number}</Typography>
                                                <Typography className='savedTextLight' sx={{ fontSize: "0.8vw", marginLeft: "0.5vw" }}>{" | " + item.patient_account_number ? item.patient_account_number : ""}</Typography>
                                            </div>                     
                                        </div>
                                        <button size="small" className='Lab_Search_Button' onClick={() => {
                                            this.setState({
                                                selectedPatientDetails: item,
                                                isTestReport: false
                                            }, () => {
                                                this.props.isSearchClear(true)
                                                this.setState({
                                                    searchPatientDetails: []
                                                })
                                            })
                                        }}><p className='Lab_saved_Bill_Text'>{t("Proceed")}</p></button>
                                    </div>
                                )
                            })
                            }

                        </Paper>
                    </div>
                    : null}
            </Box>
        )
    }

}
export default withTranslation()(MainHome);