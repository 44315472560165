import React, { Component } from 'react';
import "./charts.css";
import { Box, Typography, Paper, IconButton } from '@mui/material';
import { withTranslation } from 'react-i18next';
import { Doughnut } from 'react-chartjs-2';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { AmountFormat } from '../../../Components/CommonFunctions/CommonFunctions';
import ExpandPopUp from './ExpandPopUp';
import { CurrencySymbol } from '../../../Utility/Constants';

class DoughnutChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chartItem: props,
            chartData: props.chartData?.length > 0 ? props.chartData : [], // array
            chartLabel: props.chartLabel?.length > 0 ? props.chartLabel : [], 
            tblData: props.tblData?.length > 0 ? props.tblData : [], // array of object
            extractValue: props.extractValue ? props.extractValue : "",
            extractLabel: props.extractLabel ? props.extractLabel : "",
            isExpandClked: false,
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props && this.props) {
            this.setState({
                chartItem: this.props,
                chartData: this.props.chartData?.length > 0 ? this.props.chartData : [],
                chartLabel: this.props.chartLabel?.length > 0 ? this.props.chartLabel : [],
                tblData: this.props.tblData?.length > 0 ? this.props.tblData : [],
                extractValue: this.props.extractValue ? this.props.extractValue : "",
                extractLabel: this.props.extractLabel ? this.props.extractLabel : "",
            })
        }
    }

    PopupClose = () => {
        this.setState({
            isExpandClked: false
        })
    }

    renderScreen = () => {
        if(this.props.isFrom === "branches"){
            this.props?.renderNextScreen("pieChart",this.props?.branchId, this.state.chartItem?.title, this.state.chartItem?.url, this.state.chartItem?.tableName, this.state.chartItem?.subTitle )
        }
    }

    render() {
        const {t} = this.props;
        let { chartItem, tblData, extractValue, extractLabel, chartData, chartLabel } = this.state;
        let chart_data = [], chart_label = [], firstFiveValue = [], remainingValue = 0;
        let chartFont = 'Open Sans';

        // if (chartItem.subTitle === "Total Doctors") {
        //     let list = chartData?.length > 0 ? chartData : tblData?.length > 0 ? tblData : [];
        //     firstFiveValue = list?.length > 0 ? list?.slice(0, 10) : [];
        //     if (chartLabel?.length > 0) {
        //         chart_label = chartLabel?.length > 0 ? chartLabel?.slice(0, 10) : [];
        //     }

        //     // Extracting the respected values and labels to the corresponding list
        //     if (chartData?.length > 0 && firstFiveValue?.length > 0) {
        //         chart_data = [...firstFiveValue]
        //     } else if (firstFiveValue?.length > 0 && extractValue && extractLabel) {
        //         firstFiveValue.forEach(item => {
        //             chart_data.push(item[extractValue]);
        //             chart_label.push(item[extractLabel]);
        //         })
        //     }

        //     // Adding sixth labels and values to the corresponding list
        //     if (chartData?.length >= 11 && chartLabel?.length >= 11) {
        //         remainingValue = chartData.slice(10).reduce((acc, cur) => acc + cur, 0);
        //         chart_data.push(remainingValue);
        //         chart_label.push("Others");
        //     } else if (extractValue && tblData.length >= 11) {
        //         remainingValue = tblData.slice(10).reduce((acc, cur) => acc + cur[extractValue], 0);
        //         chart_data.push(remainingValue);
        //         chart_label.push("Others");
        //     } 

        // } else {
        // Extracting first five value
        let list = chartData?.length > 0 ? chartData : tblData?.length > 0 ? tblData : [];
        firstFiveValue = list?.length > 0 ? list?.slice(0, 5) : [];
        if (chartLabel?.length > 0) {
            chart_label = chartLabel?.length > 0 ? chartLabel?.slice(0, 5) : [];
        }

        // Extracting the respected values and labels to the corresponding list
        if(chartData?.length > 0 && firstFiveValue?.length > 0){
            chart_data = [...firstFiveValue]
        } else if (firstFiveValue?.length > 0 && extractValue && extractLabel) {
            firstFiveValue.forEach(item => {
                chart_data.push(item[extractValue]);
                chart_label.push(item[extractLabel]);
            })
        }

        // Adding sixth labels and values to the corresponding list
        if(chartData?.length >= 6 && chartLabel?.length >= 6){
            remainingValue = chartData.slice(5).reduce((acc, cur) => acc + cur, 0);
            chart_data.push(remainingValue);
            chart_label.push("Others");
        } else if (extractValue && tblData.length >= 6) {
            remainingValue = tblData.slice(5).reduce((acc, cur) => acc + cur[extractValue], 0);
            chart_data.push(remainingValue);
            chart_label.push("Others");
        } 
    // }
        
        const options = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: true,
                    position: chartItem.position ? chartItem.position : 'right',
                    align: 'center',
                    labels: {
                        usePointStyle: true,
                        fontStyle: 'bold',
                        fontSize: 9,
                        padding: 15,
                        color: "#000",
                        font: {
                            family: chartFont
                        },
                        generateLabels: (chart) => {
                            const labels = chart.data.labels || [];
                            const maxVisibleLabels = 3;
                            const isTruncationNeeded = labels?.length > maxVisibleLabels;
                            return labels?.map((label, i) => {
                                const truncatedLabel = (isTruncationNeeded && label?.length > 13) ? label?.substring(0, 13) + '...' : label;
                                return {
                                    text: truncatedLabel,
                                    fillStyle: chart.data.datasets[0].backgroundColor[i],
                                    strokeStyle: chart.data.datasets[0].backgroundColor[i],
                                };
                            });
                        }
                    },
                },
                tooltip: {
                    titleFont: {
                        family: chartFont,
                      },
                      bodyFont: {
                        family: chartFont,
                      },
                },
                devicePixelRatio: 2,
            }
        };

        const data = {
            labels: chart_label,
            datasets: [
                {
                    label: '',
                    data: chart_data,
                    backgroundColor: chartItem.chartColor ? chartItem.chartColor : ['#6972D5', "#7BD8C2", '#7BB5D8', '#A50F9E', '#DE7D24','#09824b','#F1584A','#833ab4','#dde917','#3a3a37','#7a34eb'],
                    cutout: "65%"
                },
            ],
        };

        const textCenter = {
            id: "textCenter",
            beforeDatasetsDraw(chart, args, pluginOptions){
                const {ctx} = chart;
                const xCo_ordi = chart.getDatasetMeta(0).data?.length > 0 && chart.getDatasetMeta(0).data[0].x;
                const yCo_ordi = chart.getDatasetMeta(0).data?.length > 0 && chart.getDatasetMeta(0).data[0].y

                let list = data.datasets[0].data?.length > 0 ? data.datasets[0].data : []; 
                let total_amt = list?.length > 0 ?  list?.reduce((acc,cur) => (acc+=cur),0): 0;

                ctx.save();
                ctx.font = "bold 9 Open Sans";
                ctx.fillStyle = "#616161"
                ctx.textAlign = 'center';
                ctx.textBaseline = "middle";
                ctx.fillText(total_amt ? AmountFormat(total_amt).replace(`${CurrencySymbol}`, "") : "0.00", xCo_ordi, yCo_ordi - 10)
                ctx.restore();

                ctx.font = "bold 0.75vw Open Sans";
                ctx.fillStyle = "#616161";
                ctx.textAlign = 'center';
                ctx.textBaseline = "middle";
                ctx.fillText(chartItem.subTitle ? chartItem.subTitle : "", xCo_ordi, yCo_ordi + 12)
                ctx.restore();
                
            }
        }

        const customDatalabels = {
            id: "customDatalabels",
            afterDatasetsDraw(chart, args, pluginOptions) {
                const { ctx, data } = chart;
                ctx.save();
                data.datasets[0].data?.length > 0 && data.datasets[0].data?.forEach((dataPoint, index) => {
                    const { x, y } = chart.getDatasetMeta(0).data[index].tooltipPosition();
                    ctx.font = "9 Open Sans";
                    ctx.fillStyle = "#ffff"
                    ctx.textAlign = 'center';
                    ctx.textBaseline = "middle";
                    ctx.fillText(dataPoint, x, y)
                })
            }
        }

        // customized labels
        let pluginData = [];
        let allow = ((tblData?.length > 0 && chartData?.length > 0) || (tblData?.length > 0));
        if(allow && chartItem.showValueAtCenter){
            pluginData.push(textCenter)
        } 
        if(allow && chartItem.showCountOnChart){
            pluginData.push(customDatalabels)
        }

        return (
            <Paper className='eMed_chart_wrapper' elevation={2} sx={{height: this.props.isFromBranchPharmacy ? '28.2vw' : '18.8vw'}}>
                <Box className="eMed_revenueTbl_titleDiv">
                    <Typography id="eMed_chart_headTxt">{chartItem.title ? chartItem.title : ""}</Typography>
                    { allow ? <IconButton size='small' onClick={() => {this.setState({isExpandClked: true})}}>
                        <img className='emedhub_expand_img' src={ImagePaths.ExpandView.default} alt = "Expand"/>
                    </IconButton> : null} 
                </Box>
                <Box className="eMed_doughnut_chartDiv"
                    sx={{cursor: this.props.isFrom === 'branches' ? "pointer" : "default" }}
                    onClick={() => { this.renderScreen() }}>
                    {allow ? <Doughnut options={options} data={data} plugins={pluginData}/>
                        : <Box className="eMed_noDataDiv">
                            <p id="eMed_revenueCard_Txt">{t("NoRecordsFound")}</p>
                        </Box>}
                </Box>
                {
                    this.state.isExpandClked ? 
                    <ExpandPopUp 
                    title = {chartItem.title} 
                    tblData = {tblData} 
                    tblColumn = {chartItem.tblColumn}
                    chartView = {<Doughnut options={options} data={data} plugins={pluginData}/>}
                    popUpClose = {this.PopupClose.bind(this)}
                    /> : null
                }
            </Paper>
        )
    }
}
export default withTranslation()(DoughnutChart);