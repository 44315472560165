import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {
    Box, Paper, TextField, Button, Stack, Grid, TableContainer, Table, TableBody, TableCell, TableRow, TableHead, Checkbox,
    Select, MenuItem, Dialog, DialogTitle, IconButton, DialogContent, DialogContentText, DialogActions,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    ListItemText,
} from "@mui/material";
import { Colors } from '../../../Styles/Colors';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import { localGetItem, localSetItem } from '../../../Utility/Services/CacheProviderService';
import Loader from '../../../Components/Loader';
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';
import { ImagePaths } from '../../../Utility/ImagePaths';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Typography } from 'antd';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { appointment_Status_opt, Header_Filter_Fields } from '../../../Utility/Constants';

let DateFilterList = ['Default', 'Last 7 days', 'Last 15 days', 'Last 30 days']
let dayEndList = [
    { label: "Billing Desk", value: 1 },
    { label: "Module", value: 2 }
]

let bill_concession_list = [
    { label: "Bill Amount", value: 1 },
    { label: "Hospital Share", value: 2 }
]

const SEARCH_DRUG_NAME = [
    { label: 'Brand Name', value: 1 },
    { label: 'Generic Name', value: 2 },
    { label: 'Both', value: 3 },
]
const moduleToStateKeyMap = new Map([
    ["IP Credit Limit", "IP_Limit"],
    ["OP Credit Limit", "OP_Limit"],
    ["IP Lab Credit Limit", "LAB_Limit"],
    ["IP Pharmacy Credit Limit", "PHARMA_Limit"]
]);

const disableCheckboxList = new Set([
    'Day End Based',
    'auto log out time',
    'Show Header Footer in Discharge Summary Print',
    'Overall Concession Based On',
    'Appointment Status Configuration',
    'Patient Search Extra Filter Fields',
    'Prescription Search Based On'
]);

class CustomConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Custom_Config_List: [],
            Block_IP: false,
            Block_OP: false,
            Block_LAB: false,
            Block_PHARMA: false,
            IP_Limit: 0,
            OP_Limit: 0,
            LAB_Limit: 0,
            PHARMA_Limit: 0,
            IP_Room_Bill: 0,
            Block_IP_Room_Bill: false,
            OPnoShow: false,
            auto_log_out_time: false,
            isLoader: false,
            LinkPharmaBilltoFO: false,
            pharmaCreditPay: false,
            billNO_Dissum: false,
            duplicateWatermark: false,
            showDisSumDateAndTime: false,
            allow_billing_date_fo: false,
            allow_billing_date_range: 'Default',
            PharmaFOLinkPoP: {},
            allow_separate_detailed_bill: false,
            disableBtn: false,
            block_room_charges_in_detailed_bill: false,
            default_pay_from_advance_for_checkout: false,
            show_op_appointment_time_in_pdf: false,
            show_ip_admission_time_in_pdf: false,
            show_bill_invoice_time_in_pdf: false,
            block_radiology_bills_in_fo: false,
            allow_doctor_fees_split_up: false,
            copy_ip_medication_to_discharge_summary: false,
            show_bill_description_in_receipt_bill: false,
            show_receipt_description_in_detailed_bill_print: false,
            show_discharge_summary_header_footer_in_all_pages: "true",
            day_end_basis: dayEndList,
            day_end_type: 1,
            show_day_end_basis: false,
            logoutTime: 15,
            billing_concession_type: 1,
            unlock_doctor_notes_in_doctor_module: false,
            op_ip_service_code: false,
            radiology_service_code: false,
            appointment_status: appointment_Status_opt,
            appointment_status_check: false,
            lab_test_code: false,
            header_filter_fields: JSON.parse(JSON.stringify(Header_Filter_Fields)),
            appointment_with_doctor_only_one: false,
            lab_cancel_bill: false,
            lab_con_name_mandatory: false,
            lab_con_name_specialization: false,
            auto_fetch_bill_amount_to_payment: true,
            discharge_type_in_discharge_summary: false,
            drug_search: 1,
            laterpage_patient_detail_in_ip_detail_print: true,
            show_discharge_time_in_ip_detail_print: true,
        }
    }

    componentDidMount() {
        this.GetCustomDatas()
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    GetCustomDatas() {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAllTwo(Serviceurls.CUSTOM_CONFIG).
                then((response) => {
                    if (response.data.status === "success") {
                        let NewObject = [
                            { "module": "OP Appointment No Show" },
                            { "module": "Show Pharmacy Bills in FO" },
                            { "module": "Complete Pharmacy Credit in Front Office" },
                            { "module": "Generate Bill Number for Discharge Summary" },
                            { "module": "Duplicate Bill Watermark" },
                            { 'module': "Show Discharge Time in Discharge Summary" },
                            { 'module': "Show Admission Time in Bills" },
                            { 'module': "Show Appointment Time in Bills" },
                            { 'module': "Show Invoice Time in Bills" },
                            { 'module': "Disable Billing Date" },
                            { 'module': "Show Receipts and Bills separately in Detailed Bill" },
                            { 'module': "Block Room Charge Sepration in Detailed Bill" },
                            { 'module': "Show Radiology Bills in FO" },
                            { 'module': "Hospital/Doctor share split up" },
                            { 'module': "Default Pay From Advance Option for Checkout" },
                            { 'module': "Copy IP Medication to Discharge Summery" },
                            { 'module': "Show Bill Description in Receipt Bills" },
                            { 'module': "Show Receipt Description in Detailed bill Print" },
                            { 'module': "Day End Based" },
                            { 'module': "auto log out time" },
                            { 'module': "Show Header Footer in Discharge Summary Print" },
                            { 'module': 'Overall Concession Based On' },
                            { 'module': 'Lock Addendum in Doctor Module' },
                            { 'module': 'Disable OP and IP Service Code' },
                            { 'module': 'Appointment Status Configuration' },
                            { 'module': 'Disable Lab Test Code' },
                            { 'module': 'Patient Search Extra Filter Fields' },
                            { 'module': 'Allow only one "with doctor" status at a time' },
                            { 'module': 'Disable Lab Cancel Bill After Completed' },
                            { 'module': 'Lab Consultant Name Mandatory' },
                            { 'module': 'Show Lab Consultant Name with Specialization' },
                            { 'module': 'Disable Radiology Service Code' },
                            { 'module': 'Should the bill amount be auto-fetched' },
                            { 'module': 'Show Discharge Type in Discharge Summary Print' },
                            { 'module': 'Prescription Search Based On' },
                            { 'module': 'Show minimal header details on the lateral pages of the IP detailed bill print' },
                            { 'module': 'Show Discharge Time in IP detailed bill Print' },
                        ] 
                        this.setState({
                            Custom_Config_List: [...response.data.data?.ip_credit_limit, ...NewObject], // Adding New object in array
                        }, () => {
                            let states = this.state
                            for (let i = 0; this.state.Custom_Config_List.length > i; i++) {
                                switch (states.Custom_Config_List[i].module) {
                                    case "IP Credit Limit": this.setState({ IP_Limit: states.Custom_Config_List[i].credit_limit, Block_IP: states.Custom_Config_List[i].allow_overdue })
                                        break;
                                    case "OP Credit Limit": this.setState({ OP_Limit: states.Custom_Config_List[i].credit_limit, Block_OP: states.Custom_Config_List[i].allow_overdue })
                                        break;
                                    case "IP Lab Credit Limit": this.setState({ LAB_Limit: states.Custom_Config_List[i].credit_limit, Block_LAB: states.Custom_Config_List[i].allow_overdue })
                                        break;
                                    case "IP Pharmacy Credit Limit": this.setState({ PHARMA_Limit: states.Custom_Config_List[i].credit_limit, Block_PHARMA: states.Custom_Config_List[i].allow_overdue })
                                        break;
                                    case "IP Room Charge Auto Billing": this.setState({ IP_Room_Bill: states.Custom_Config_List[i].credit_limit, Block_IP_Room_Bill: states.Custom_Config_List[i].allow_overdue })
                                        break;
                                    case "OP Appointment No Show": this.setState({ OPnoShow: !response.data.data?.allow_no_show_update })
                                        break;
                                    case "Show Pharmacy Bills in FO": this.setState({ LinkPharmaBilltoFO: !response.data.data?.show_pharmacy_bills_in_fo })
                                        break;
                                    case "Complete Pharmacy Credit in Front Office": this.setState({ pharmaCreditPay: !response.data.data?.allow_fo_pharmacy_bill_complete })
                                        break;
                                    case "Generate Bill Number for Discharge Summary": this.setState({ billNO_Dissum: !response.data?.data?.allow_generate_bill_discharge_summary })
                                        break;
                                    case "Duplicate Bill Watermark": this.setState({ duplicateWatermark: !response.data?.data?.allow_duplicate_watermark })
                                        break;
                                    case "Show Discharge Time in Discharge Summary": this.setState({ showDisSumDateAndTime: !response.data?.data?.show_discharge_time_in_discharge_summary })
                                        break;
                                    case "Show Admission Time in Bills": this.setState({ show_ip_admission_time_in_pdf: !response.data?.data?.show_ip_admission_time_in_pdf })
                                        break;
                                    case "Show Appointment Time in Bills": this.setState({ show_op_appointment_time_in_pdf: !response.data?.data?.show_op_appointment_time_in_pdf })
                                        break;
                                    case "Show Invoice Time in Bills": this.setState({ show_bill_invoice_time_in_pdf: !response.data?.data?.show_bill_invoice_time_in_pdf })
                                        break;
                                    case "Disable Billing Date": this.setState({
                                        allow_billing_date_fo: !response.data?.data?.allow_billing_date_fo,
                                        allow_billing_date_range: response.data?.data?.allow_billing_date_range ? response.data?.data?.allow_billing_date_range : "Default"
                                    })
                                        break;
                                    case "Show Receipts and Bills separately in Detailed Bill": this.setState({ allow_separate_detailed_bill: !response.data?.data?.allow_separate_detailed_bill })
                                        break;
                                    case "Block Room Charge Sepration in Detailed Bill": this.setState({ block_room_charges_in_detailed_bill: response.data?.data?.block_room_charges_sepration_in_detailed_bill })
                                        break;
                                    case "Show Radiology Bills in FO": this.setState({ block_radiology_bills_in_fo: !response.data?.data?.block_radiology_bills_in_fo })
                                        break;
                                    case "Hospital/Doctor share split up": this.setState({ allow_doctor_fees_split_up: !response.data?.data?.allow_doctor_fees_split_up })
                                        break
                                    case "Default Pay From Advance Option for Checkout": this.setState({ default_pay_from_advance_for_checkout: !response.data?.data?.default_pay_from_advance_for_checkout })
                                        break;
                                    case "Copy IP Medication to Discharge Summery": this.setState({ copy_ip_medication_to_discharge_summary: !response.data?.data?.copy_ip_medication_to_discharge_summary })
                                        break;
                                    case "Show Bill Description in Receipt Bills": this.setState({ show_bill_description_in_receipt_bill: !response.data?.data?.show_bill_description_in_receipt_bill })
                                        break;
                                    case "Show Receipt Description in Detailed bill Print": this.setState({ show_receipt_description_in_detailed_bill_print: !response.data?.data?.show_receipt_description_in_detailed_bill_print })
                                        break;
                                    case "Day End Based": this.setState({
                                        show_day_end_basis: response.data?.data?.show_day_end_basis,
                                        day_end_type: response.data?.data?.day_end_type ? response.data?.data?.day_end_type : 1
                                    })
                                        break;
                                    case "auto log out time": this.setState({ logoutTime: response.data?.data?.auto_log_out_time ? response.data?.data?.auto_log_out_time : 30 })
                                        break;
                                    case "Show Header Footer in Discharge Summary Print": this.setState({ show_discharge_summary_header_footer_in_all_pages: response.data?.data?.show_discharge_summary_header_footer_in_all_pages ? "true" : 'false' })
                                        break;
                                    case 'Overall Concession Based On': this.setState({
                                        billing_concession_type: response.data?.data?.billing_concession_type ? response.data?.data?.billing_concession_type : 1
                                    })
                                        break;
                                    case "Lock Addendum in Doctor Module": this.setState({ unlock_doctor_notes_in_doctor_module: !response.data?.data?.unlock_doctor_notes_in_doctor_module })
                                        break;
                                    case 'Disable OP and IP Service Code': this.setState({ op_ip_service_code: !response.data?.data?.op_ip_service_code })
                                        break;
                                    case 'Appointment Status Configuration':
                                        this.setState({
                                            appointment_status: response.data?.data?.appointment_status ? response.data?.data?.appointment_status : appointment_Status_opt
                                        })
                                        break;
                                    case 'Disable Lab Test Code':
                                        this.setState({ lab_test_code: !response.data?.data?.lab_test_code })
                                        break;
                                    case 'Patient Search Extra Filter Fields':
                                        this.setState(
                                            {
                                                header_filter_fields: response.data?.data?.header_filter_fields
                                                    ? JSON.parse(JSON.stringify(response.data.data.header_filter_fields))
                                                    : JSON.parse(JSON.stringify(Header_Filter_Fields))
                                            },
                                            () => {
                                                // Callback after header_filter_fields is set
                                                const updatedFields = [...this.state.header_filter_fields]; // Copy existing fields

                                                JSON.parse(JSON.stringify(Header_Filter_Fields)).forEach((newList) => {
                                                    // Check if the value already exists in updatedFields
                                                    const existsInUpdatedFields = updatedFields.some(
                                                        (field) => field.value === newList.value
                                                    );

                                                    // Add only if not present
                                                    if (!existsInUpdatedFields) {
                                                        updatedFields.push(newList);
                                                    }
                                                });

                                                // Update header_filter_fields with the new array
                                                this.setState({ header_filter_fields: updatedFields });
                                            }
                                        );
                                        break;
                                    case 'Allow only one "with doctor" status at a time':
                                        this.setState({ appointment_with_doctor_only_one: !response.data?.data?.appointment_with_doctor_only_one })
                                        break;
                                    case 'Disable Lab Cancel Bill After Completed':
                                        this.setState({ lab_cancel_bill: !response.data?.data?.lab_cancel_bill })
                                        break;
                                    case 'Lab Consultant Name Mandatory':
                                        this.setState({ lab_con_name_mandatory: !response.data?.data?.lab_con_name_mandatory })
                                        break;
                                    case 'Show Lab Consultant Name with Specialization':
                                        this.setState({ lab_con_name_specialization: !response.data?.data?.lab_con_name_specialization })
                                        break;
                                    case 'Disable Radiology Service Code':
                                        this.setState({ radiology_service_code: !response.data?.data?.radiology_service_code })
                                        break;
                                    case 'Should the bill amount be auto-fetched':
                                        this.setState({ auto_fetch_bill_amount_to_payment: !response.data?.data?.auto_fetch_bill_amount_to_payment })
                                        break;
                                    case 'Show Discharge Type in Discharge Summary Print':
                                        this.setState({ discharge_type_in_discharge_summary: !response.data?.data?.discharge_type_in_discharge_summary })
                                        break;
                                    case 'Prescription Search Based On':
                                        this.setState({ drug_search: response.data?.data?.drug_search })
                                        break;
                                    case 'Show minimal header details on the lateral pages of the IP detailed bill print':
                                        this.setState({ laterpage_patient_detail_in_ip_detail_print: !response.data?.data?.laterpage_patient_detail_in_ip_detail_print })
                                        break;
                                    case 'Show Discharge Time in IP detailed bill Print':
                                        this.setState({ show_discharge_time_in_ip_detail_print: !response.data?.data?.show_discharge_time_in_ip_detail_print })
                                        break;
                                }

                            }
                        })
                        this.LoaderFunction(false)
                    }
                }).catch((e) => {
                    if (e?.response?.data?.status === 'fail') {
                        this.LoaderFunction(false)
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(e.message)
                    }
                })
        }
        catch (e) {
            this.LoaderFunction(false)
            this.errorMessage(e.message)
        }
    }

    updateCustomDetails = () => {
        try {
            let data = []
            let states = this.state
            this.setState({ disableBtn: true })
            data = {
                "ip_credit_limit": [
                    {
                        "module": "IP Credit Limit",
                        "credit_limit": +(states.IP_Limit),
                        "allow_overdue": states.Block_IP
                    },
                    {
                        "module": "OP Credit Limit",
                        "credit_limit": +(states.OP_Limit),
                        "allow_overdue": states.Block_OP
                    },
                    {
                        "module": "IP Lab Credit Limit",
                        "credit_limit": +(states.LAB_Limit),
                        "allow_overdue": states.Block_LAB
                    },
                    {
                        "module": "IP Pharmacy Credit Limit",
                        "credit_limit": +(states.PHARMA_Limit),
                        "allow_overdue": states.Block_PHARMA
                    },
                    {
                        "module": "IP Room Charge Auto Billing",
                        "credit_limit": +(states.IP_Room_Bill),
                        "allow_overdue": states.Block_IP_Room_Bill
                    }
                ],
                "allow_no_show_update": !states?.OPnoShow,
                "allow_fo_pharmacy_bill_complete": !states?.pharmaCreditPay,
                "show_pharmacy_bills_in_fo": !states?.LinkPharmaBilltoFO,
                "allow_generate_bill_discharge_summary": !states?.billNO_Dissum,
                "allow_duplicate_watermark": !states?.duplicateWatermark,
                "show_discharge_time_in_discharge_summary": !states?.showDisSumDateAndTime,
                "show_ip_admission_time_in_pdf": !states?.show_ip_admission_time_in_pdf,
                "show_op_appointment_time_in_pdf": !states?.show_op_appointment_time_in_pdf,
                "show_bill_invoice_time_in_pdf": !states?.show_bill_invoice_time_in_pdf,
                "allow_billing_date_fo": !states?.allow_billing_date_fo,
                "allow_billing_date_range": states?.allow_billing_date_range,
                "allow_separate_detailed_bill": !states?.allow_separate_detailed_bill,
                "block_room_charges_sepration_in_detailed_bill": states?.block_room_charges_in_detailed_bill,
                "block_radiology_bills_in_fo": !states?.block_radiology_bills_in_fo,
                "allow_doctor_fees_split_up": !states?.allow_doctor_fees_split_up,
                "default_pay_from_advance_for_checkout": !states?.default_pay_from_advance_for_checkout,
                "copy_ip_medication_to_discharge_summary": !states?.copy_ip_medication_to_discharge_summary,
                "show_bill_description_in_receipt_bill": !states?.show_bill_description_in_receipt_bill,
                "show_receipt_description_in_detailed_bill_print": !states?.show_receipt_description_in_detailed_bill_print,
                'day_end_type': states.day_end_type ? states.day_end_type : 1,
                "auto_log_out_time": this.state.logoutTime ? this.state.logoutTime : 15,
                "show_discharge_summary_header_footer_in_all_pages": this.state.show_discharge_summary_header_footer_in_all_pages === 'true' ? true : false,
                'billing_concession_type': this.state.billing_concession_type ? this.state.billing_concession_type : 1,
                'unlock_doctor_notes_in_doctor_module': !states.unlock_doctor_notes_in_doctor_module,
                'op_ip_service_code': !states.op_ip_service_code,
                'appointment_status': states.appointment_status ? states.appointment_status : appointment_Status_opt,
                'lab_test_code': !states.lab_test_code,
                'header_filter_fields': states?.header_filter_fields ? states?.header_filter_fields : JSON.parse(JSON.stringify(Header_Filter_Fields)),
                'appointment_with_doctor_only_one': !states.appointment_with_doctor_only_one,
                'lab_cancel_bill': !states.lab_cancel_bill,
                'lab_con_name_mandatory': !states.lab_con_name_mandatory,
                'lab_con_name_specialization': !states.lab_con_name_specialization,
                'radiology_service_code': !states.radiology_service_code,
                'discharge_type_in_discharge_summary': !states.discharge_type_in_discharge_summary,
                'auto_fetch_bill_amount_to_payment': !states.auto_fetch_bill_amount_to_payment,
                'drug_search': states.drug_search ? states.drug_search : 1,
                'laterpage_patient_detail_in_ip_detail_print': !states.laterpage_patient_detail_in_ip_detail_print,
                'show_discharge_time_in_ip_detail_print': !states.show_discharge_time_in_ip_detail_print,
            }

            RestAPIService.updateWithOutId(data, Serviceurls.CUSTOM_CONFIG)
                .then((response => {
                    if (response.data.status === 'success') {
                        this.successMessage(response.data.message)
                        this.GetCustomDatas()
                        localSetItem("noShowStatus", !states?.OPnoShow);
                        localSetItem("Show_Pharmacy_Bills_In_FO", !states?.LinkPharmaBilltoFO);
                        localSetItem("Allow_Pharma_CreditBill_In_FO", !states?.pharmaCreditPay);
                        localSetItem("Allow_DischargeSummary_BillNo", !states?.billNO_Dissum);
                        localSetItem("allow_billing_date_fo", !states?.allow_billing_date_fo);
                        localSetItem("allow_billing_date_range", states?.allow_billing_date_range ? states?.allow_billing_date_range : "Default");
                        localSetItem("Show_Radiology_Bills_In_FO", !states?.block_radiology_bills_in_fo);
                        localSetItem("allow_doctor_fees_split_up", !states?.allow_doctor_fees_split_up);
                        localSetItem("default_PayFromAdvance_On_Checkout", !states?.default_pay_from_advance_for_checkout);
                        localSetItem("Copy_IP_Mediction_To_DischargeSummery", !states?.copy_ip_medication_to_discharge_summary);
                        localSetItem("day_end_value", states.day_end_type ? states.day_end_type : 1);
                        localSetItem("auto_log_out_time", this.state.logoutTime ? this.state.logoutTime : 15);
                        localSetItem('billing_concession_type', this.state.billing_concession_type ? this.state.billing_concession_type : 1)
                        localSetItem('appointment_status', JSON.stringify(this.state.appointment_status ? this.state.appointment_status : appointment_Status_opt))
                        localSetItem('lab_test_code', !this.state.lab_test_code)
                        localSetItem('op_ip_service_code', !states.op_ip_service_code)
                        localSetItem('lab_con_name_mandatory', !states.lab_con_name_mandatory)
                        localSetItem('lab_con_name_specialization', !states.lab_con_name_specialization)
                        localSetItem('radiology_service_code', !states.radiology_service_code)
                        localSetItem('auto_fetch_bill_amount_to_payment', !states.auto_fetch_bill_amount_to_payment)
                        localSetItem('lab_cancel_bill', !states.lab_cancel_bill)
                        localSetItem('drug_search', this.state.drug_search ? this.state.drug_search : 1)
                        let LoggedData = JSON.parse(localGetItem("loggedInUserInfo"))
                        LoggedData.header_filter_fields = this.state.header_filter_fields ? JSON.parse(JSON.stringify(this.state.header_filter_fields)) : JSON.parse(JSON.stringify(Header_Filter_Fields))
                        localSetItem('loggedInUserInfo', JSON.stringify(LoggedData))
                        this.setState({ disableBtn: false })
                    }
                })).catch(e => {
                    if (e?.response?.data?.status === 'fail') {
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.errorMessage(e.message)
                    }
                    this.setState({ disableBtn: false })
                })
        } catch (e) {
            this.setState({ disableBtn: false })
            this.errorMessage(e.message)
        }
    }
    onUnitChange = (type) => {
        if (type === "increment" && this.state.logoutTime >= 10) {
            this.setState({ logoutTime: this.state.logoutTime + 5 })

        } else if (type === "decrement" && this.state.logoutTime <= 60) {
            this.setState({ logoutTime: this.state.logoutTime - 5 })

        }
    }


    BlockBills = (item) => {
        let Module = item.module
        let states = this.state
        return (
            <Checkbox onChange={(event) => {
                switch (Module) {
                    case "IP Credit Limit": this.setState({ Block_IP: event.target.checked })
                        break;
                    case "OP Credit Limit": this.setState({ Block_OP: event.target.checked })
                        break;
                    case "IP Lab Credit Limit": this.setState({ Block_LAB: event.target.checked })
                        break;
                    case "IP Pharmacy Credit Limit": this.setState({ Block_PHARMA: event.target.checked })
                        break;
                    case "IP Room Charge Auto Billing": this.setState({ Block_IP_Room_Bill: event.target.checked })
                        break;
                    case "OP Appointment No Show": this.setState({ OPnoShow: event.target.checked })
                        break;
                    case "Show Pharmacy Bills in FO": this.setState({ PharmaFOLinkPoP: event.target.checked ? { state: "LinkPharmaBilltoFO", value: event.target.checked } : {}, LinkPharmaBilltoFO: event.target.checked ? this.state.LinkPharmaBilltoFO : false }, () => {
                        if (event.target.checked) {
                            if (!states?.LinkPharmaBilltoFO) {
                                this.setState({ pharmaCreditPay: true })
                            }
                        }
                    }) // warning popup opens
                        break;
                    case "Complete Pharmacy Credit in Front Office": this.setState({ PharmaFOLinkPoP: event.target.checked ? { state: "pharmaCreditPay", value: event.target.checked } : {}, pharmaCreditPay: event.target.checked ? this.state.pharmaCreditPay : false }) // warning popup opens
                        break;
                    case "Generate Bill Number for Discharge Summary": this.setState({ billNO_Dissum: event.target.checked })
                        break;
                    case 'Duplicate Bill Watermark': this.setState({ duplicateWatermark: event.target.checked })
                        break;
                    case 'Show Discharge Time in Discharge Summary': this.setState({ showDisSumDateAndTime: event.target.checked })
                        break;
                    case 'Show Admission Time in Bills': this.setState({ show_ip_admission_time_in_pdf: event.target.checked })
                        break;
                    case 'Show Appointment Time in Bills': this.setState({ show_op_appointment_time_in_pdf: event.target.checked })
                        break;
                    case 'Show Invoice Time in Bills': this.setState({ show_bill_invoice_time_in_pdf: event.target.checked })
                        break;
                    case "Disable Billing Date": this.setState({ allow_billing_date_fo: event.target.checked }, () => {
                        if (!states.allow_billing_date_fo) {
                            this.setState({ allow_billing_date_range: "Default" })
                        }
                    })
                        break;
                    case "Show Receipts and Bills separately in Detailed Bill": this.setState({ allow_separate_detailed_bill: event.target.checked })
                        break;
                    case "Block Room Charge Sepration in Detailed Bill": this.setState({ block_room_charges_in_detailed_bill: event.target.checked })
                        break;
                    case "Show Radiology Bills in FO": this.setState({ block_radiology_bills_in_fo: event.target.checked })
                        break;
                    case "Hospital/Doctor share split up": this.setState({ allow_doctor_fees_split_up: event.target.checked })
                        break;
                    case "Default Pay From Advance Option for Checkout": this.setState({ default_pay_from_advance_for_checkout: event.target.checked })
                        break;
                    case "Copy IP Medication to Discharge Summery": this.setState({ copy_ip_medication_to_discharge_summary: event.target.checked })
                        break;
                    case "Show Bill Description in Receipt Bills": this.setState({ show_bill_description_in_receipt_bill: event.target.checked })
                    case "Show Receipt Description in Detailed bill Print": this.setState({ show_receipt_description_in_detailed_bill_print: event.target.checked })
                        break;
                    case 'Day End Based': this.setState({ show_day_end_basis: event.target.checked }, () => {
                        if (!states.show_day_end_basis) {
                            this.setState({ day_end_type: 1 })
                        }
                    })
                        break;
                    case 'Lock Addendum in Doctor Module': this.setState({ unlock_doctor_notes_in_doctor_module: event.target.checked })
                        break;
                    case 'Appointment Status Configuration':
                        this.setState({ appointment_status_check: event.target.checked })
                        break;
                    case 'Disable Lab Test Code': this.setState({ lab_test_code: event.target.checked })
                        break;
                    case 'Allow only one "with doctor" status at a time': this.setState({ appointment_with_doctor_only_one: event.target.checked })
                        break;
                    case 'Disable OP and IP Service Code':
                        this.setState({ op_ip_service_code: event.target.checked })
                        break;
                    case 'Disable Lab Cancel Bill After Completed':
                        this.setState({ lab_cancel_bill: event.target.checked })
                        break;
                    case 'Lab Consultant Name Mandatory':
                        this.setState({ lab_con_name_mandatory: event.target.checked })
                        break;
                    case 'Show Lab Consultant Name with Specialization':
                        this.setState({ lab_con_name_specialization: event.target.checked })
                        break;
                    case 'Disable Radiology Service Code':
                        this.setState({ radiology_service_code: event.target.checked })
                        break;
                    case 'Should the bill amount be auto-fetched':
                        this.setState({ auto_fetch_bill_amount_to_payment: event.target.checked })
                        break;
                    case 'Show minimal header details on the lateral pages of the IP detailed bill print':
                        this.setState({ laterpage_patient_detail_in_ip_detail_print: event.target.checked })
                        break;
                    case 'Show Discharge Time in IP detailed bill Print':
                        this.setState({ show_discharge_time_in_ip_detail_print: event.target.checked })
                        break;
                }
            }}
                checked={Module == "IP Credit Limit" ? states.Block_IP
                    : Module == "OP Credit Limit" ? states.Block_OP
                        : Module == "IP Lab Credit Limit" ? states.Block_LAB
                            : Module == "IP Pharmacy Credit Limit" ? states.Block_PHARMA
                                : Module == "IP Room Charge Auto Billing" ? states.Block_IP_Room_Bill
                                    : Module == "OP Appointment No Show" ? states.OPnoShow
                                        : Module == "Complete Pharmacy Credit in Front Office" ? states.pharmaCreditPay
                                            : Module == "Generate Bill Number for Discharge Summary" ? states.billNO_Dissum
                                                : Module == "Show Pharmacy Bills in FO" ? states.LinkPharmaBilltoFO
                                                    : Module === 'Duplicate Bill Watermark' ? states.duplicateWatermark
                                                        : Module === 'Show Discharge Time in Discharge Summary' ? states.showDisSumDateAndTime
                                                            : Module === 'Show Admission Time in Bills' ? states.show_ip_admission_time_in_pdf
                                                                : Module === 'Show Appointment Time in Bills' ? states.show_op_appointment_time_in_pdf
                                                                    : Module === 'Show Invoice Time in Bills' ? states.show_bill_invoice_time_in_pdf
                                                                        : Module === "Disable Billing Date" ? states.allow_billing_date_fo
                                                                            : Module === "Show Receipts and Bills separately in Detailed Bill" ? states.allow_separate_detailed_bill
                                                                                : Module === "Block Room Charge Sepration in Detailed Bill" ? states.block_room_charges_in_detailed_bill
                                                                                    : Module === "Show Radiology Bills in FO" ? states.block_radiology_bills_in_fo
                                                                                        : Module === "Hospital/Doctor share split up" ? states.allow_doctor_fees_split_up
                                                                                            : Module === "Default Pay From Advance Option for Checkout" ? states.default_pay_from_advance_for_checkout
                                                                                                : Module === "Copy IP Medication to Discharge Summery" ? states.copy_ip_medication_to_discharge_summary
                                                                                                    : Module === "Show Receipt Description in Detailed bill Print" ? states.show_receipt_description_in_detailed_bill_print
                                                                                                        : Module === 'Day End Based' ? states.show_day_end_basis
                                                                                                            : Module === "Lock Addendum in Doctor Module" ? states.unlock_doctor_notes_in_doctor_module
                                                                                                                : Module === "Show Bill Description in Receipt Bills" ? states.show_bill_description_in_receipt_bill
                                                                                                                    : Module === 'Appointment Status Configuration' ? states.appointment_status_check
                                                                                                                        : Module === 'Disable Lab Test Code' ? states.lab_test_code
                                                                                                                            : Module === 'Allow only one "with doctor" status at a time' ? states.appointment_with_doctor_only_one
                                                                                                                                : Module === 'Disable OP and IP Service Code' ? states.op_ip_service_code
                                                                                                                                    : Module === 'Disable Lab Cancel Bill After Completed' ? states.lab_cancel_bill
                                                                                                                                        : Module === 'Lab Consultant Name Mandatory' ? states.lab_con_name_mandatory
                                                                                                                                            : Module === 'Show Lab Consultant Name with Specialization' ? states.lab_con_name_specialization
                                                                                                                                                : Module === 'Should the bill amount be auto-fetched' ? states.auto_fetch_bill_amount_to_payment
                                                                                                                                                    : Module === 'Disable Radiology Service Code' ? states.radiology_service_code
                                                                                                                                                        : Module === 'Show Discharge Type in Discharge Summary Print' ? states.discharge_type_in_discharge_summary
                                                                                                                                                            : Module === 'Show minimal header details on the lateral pages of the IP detailed bill print' ? states.laterpage_patient_detail_in_ip_detail_print
                                                                                                                                                            : Module === 'Show Discharge Time in IP detailed bill Print' ? states.show_discharge_time_in_ip_detail_print
                                                                                                                                                                : false

                }



                disabled={Module === "Complete Pharmacy Credit in Front Office" ? states.LinkPharmaBilltoFO :
                    disableCheckboxList.has(Module) ? true : false} />
        )
    }

    CreditLimit = (item) => {
        let Module = item.module
        let states = this.state
        return (
            <Box>
                {
                    Module === "Disable Billing Date" ?
                        <Box display={"flex"} justifyContent={"space-evenly"} alignItems={"center"}>
                            <label style={{ fontSize: "0.9vw", fontWeight: "bold" }}>{"Billing Allowed For"}</label>
                            <Select
                                disabled={!states.allow_billing_date_fo === false}
                                sx={{ width: "9vw", fontSize: "0.95vw" }}
                                size='small'
                                value={this.state.allow_billing_date_range}
                                onChange={(e) => { this.setState({ allow_billing_date_range: e.target.value }) }}
                            >
                                {DateFilterList?.length > 0 ? DateFilterList.map((item, index) => (
                                    <MenuItem sx={{ fontSize: "0.95vw" }} key={index} value={item}>{item}</MenuItem>
                                )) : null}
                            </Select>
                        </Box>
                        : Module === 'Day End Based' ?
                            <Box component={'div'}>
                                <Select
                                    sx={{ width: '15vw', fontSize: '0.95vw' }}
                                    size='small'
                                    value={this.state.day_end_type}
                                    onChange={(e) => { this.setState({ day_end_type: e.target.value }) }}
                                >
                                    {this.state.day_end_basis?.map((item, index) => (
                                        <MenuItem sx={{ fontSize: '0.95vw' }} key={index} value={item?.value}>{item?.label}</MenuItem>
                                    ))}
                                </Select>
                            </Box>
                            : Module === 'auto log out time' ?
                                <Box component={'div'} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                    <IconButton
                                        color="secondary"
                                        onClick={() => { this.onUnitChange("decrement"); }}
                                        disabled={this.state.logoutTime <= 10}
                                        sx={{ width: '3vw', height: '3vh' }} // Adjust width and height as needed
                                    >
                                        <RemoveIcon />
                                    </IconButton>
                                    <Typography paddingX={"1vw"} sx={{ width: '50px', height: '50px', fontSize: "2vw" }} >
                                        {this.state.logoutTime}
                                    </Typography>
                                    <IconButton
                                        color="secondary"
                                        onClick={() => { this.onUnitChange("increment"); }}
                                        disabled={this.state.logoutTime >= 60}
                                        sx={{ width: '3vw', height: '3vh', }} // Adjust width and height as needed
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </Box>
                                : Module === 'Show Header Footer in Discharge Summary Print' ?
                                    <Box component={'div'} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                        <FormControl>
                                            <RadioGroup
                                                sx={{ display: 'flex', flexDirection: 'row', width: "25vw" }}
                                                row
                                                value={this.state.show_discharge_summary_header_footer_in_all_pages}
                                                onChange={(e) => { this.setState({ show_discharge_summary_header_footer_in_all_pages: e.target.value }) }}
                                            >
                                                <FormControlLabel value="true" control={<Radio size='small' />} label="All Pages" />
                                                <FormControlLabel value="false" control={<Radio size='small' />} label="Only First and Last Page" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Box>
                                    : Module === 'Overall Concession Based On' ?
                                        <Box component={'div'}>
                                            <Select
                                                sx={{ width: '15vw', fontSize: '0.95vw' }}
                                                size='small'
                                                value={this.state.billing_concession_type}
                                                onChange={(e) => { this.setState({ billing_concession_type: e.target.value }) }}
                                            >
                                                {bill_concession_list?.map((item, index) => (
                                                    <MenuItem sx={{ fontSize: '0.95vw' }} key={index} value={item?.value}>{item?.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </Box>
                                        : Module === 'Appointment Status Configuration' ?
                                            <Box component={'div'}>
                                                <Select
                                                    sx={{ width: '15vw', fontSize: '0.95vw' }}
                                                    size='small'
                                                    labelId="appointment-status-label"
                                                    multiple
                                                    value={this.state.appointment_status?.map((item) => item?.is_checked ? item?.label : '')}
                                                    renderValue={(selected) => selected?.join(",")?.replace(/,,/g, ",")}
                                                >
                                                    {this.state.appointment_status?.map((option, index) => (
                                                        <MenuItem key={option.label} value={option.label}>
                                                            <Checkbox
                                                                disabled={option?.is_default}
                                                                checked={option.is_checked}
                                                                onChange={(e) => {
                                                                    this.state.appointment_status[index]['is_checked'] = e.target.checked;
                                                                    // Handling "With Optometry" checkbox
                                                                    if (option.label === "With Optometry") {
                                                                        const optometryCompletedIndex = this.state.appointment_status.findIndex(item => item.label === "Optometry Completed");
                                                                        if (optometryCompletedIndex !== -1) {
                                                                            if (e.target.checked) {
                                                                                // Enable or disable "Optometry Completed" based on "With Optometry"
                                                                                this.state.appointment_status[optometryCompletedIndex].is_default = false;
                                                                            } else {
                                                                                this.state.appointment_status[optometryCompletedIndex].is_default = true;
                                                                                this.state.appointment_status[optometryCompletedIndex].is_checked = false;
                                                                            }
                                                                        }
                                                                    }
                                                                    this.setState({ appointment_status: this.state.appointment_status })
                                                                }}
                                                            />
                                                            <ListItemText primary={option.label} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </Box> :
                                            Module === 'Patient Search Extra Filter Fields' ?
                                                <Box component={'div'}>
                                                    <Select
                                                        sx={{ width: '15vw', fontSize: '0.95vw' }}
                                                        size='small'
                                                        labelId="header-feilds-label"
                                                        multiple
                                                        value={this.state.header_filter_fields?.map((item) => item?.is_checked ? item?.label : '')}
                                                        renderValue={(selected) => selected?.join(",")?.replace(/,,/g, ",")}
                                                    >
                                                        {this.state.header_filter_fields?.map((option, index) => (
                                                            <MenuItem key={option.label} value={option.label}>
                                                                <Checkbox
                                                                    checked={option.is_checked}
                                                                    onChange={(e) => {
                                                                        if (this.state.header_filter_fields.every((list, eveIndex) => (list?.is_checked === false || (eveIndex === index && !e.target.checked)))) {
                                                                            this.errorMessage("Header Fields can't be empty.")
                                                                        } else {
                                                                            this.state.header_filter_fields[index]['is_checked'] = e.target.checked;
                                                                        }
                                                                        this.setState({ header_filter_fields: this.state.header_filter_fields })
                                                                    }}
                                                                />
                                                                <ListItemText primary={option.label} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </Box> :
                                                Module === 'Prescription Search Based On' ?
                                                    <Box component={'div'} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                                        <Select
                                                            size='small'
                                                            value={this.state.drug_search}
                                                            onChange={(e) => this.setState({ drug_search: e.target.value })}
                                                            sx={{ width: '15vw', fontSize: '0.95vw' }}
                                                        >
                                                            {SEARCH_DRUG_NAME?.map((item, index) => {
                                                                return (
                                                                    <MenuItem key={index} value={item?.value}>{item?.label}</MenuItem>
                                                                )
                                                            })}
                                                        </Select>
                                                    </Box> : moduleToStateKeyMap.has(Module) ?
                                                    <TextField
                                                        onChange={(e) => {
                                                            let isValid = CommonValidation.numberOnly(e.target.value)
                                                            if (e.target.value === "" || isValid) {
                                                                switch (Module) {
                                                                    case "IP Credit Limit": this.setState({ IP_Limit: e.target.value })
                                                                        break;
                                                                    case "OP Credit Limit": this.setState({ OP_Limit: e.target.value })
                                                                        break;
                                                                    case "IP Lab Credit Limit": this.setState({ LAB_Limit: e.target.value })
                                                                        break;
                                                                    case "IP Pharmacy Credit Limit": this.setState({ PHARMA_Limit: e.target.value })
                                                                        break;
                                                                    case "IP Room Charge Auto Billing": this.setState({ IP_Room_Bill: e.target.value })
                                                                        break;
                                                                }
                                                            }
                                                        }
                                                    } size="small"
                                                    value={states[moduleToStateKeyMap.get(Module)] || 0} /> : "-"
                } </Box>
        )
    }

    msgClose = () => {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }
    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }
    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    render() {
        console.log(this.state);

        const { t } = this.props;
        return (
            <Box id="eMed_srvConfig_Container" sx={{ backgroundColor: Colors.configHomeBg }}>
                <Grid container spacing={3} className="eMed_srvConfig_Box">
                    <Grid item xs={13}>
                        <Paper className="eMed_srvConfig_Div" >
                            <Box component={'div'}>
                                <TableContainer component={'div'} className="eMed_ABill_Tbl">
                                    <Table aria-label="collapsible table" stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ width: '10vw' }} align='center' className="eMed_ABill_Head">{t('S.No')}</TableCell>
                                                <TableCell sx={{ width: '60vw' }} className="eMed_ABill_Head">{t('Type')}</TableCell>
                                                <TableCell sx={{ width: '10vw' }} className="eMed_ABill_Head">{t("Block")}</TableCell>
                                                <TableCell sx={{ width: '20vw' }} className="eMed_ABill_Head">{t("Amount Limit")}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {this.state?.Custom_Config_List.map((item, index) => {
                                            return (
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell sx={{ width: '10vw' }} align='center' className="eMed_ABill_Tblcell">{index + 1}</TableCell>
                                                        <TableCell sx={{ width: '60vw', textTransform: 'capitalize' }} className="eMed_ABill_Tblcell">{item.module}</TableCell>
                                                        <TableCell sx={{ width: '10vw' }} className="eMed_ABill_Tblcell">
                                                            {this.BlockBills(item)}
                                                        </TableCell>
                                                        <TableCell sx={{ width: '20vw' }} className="eMed_ABill_Tblcell">
                                                            {this.CreditLimit(item)}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            )
                                        }
                                        )}
                                    </Table>
                                </TableContainer>
                            </Box>
                            <div className='eMed_TestConfig_RightBtnCon'>
                                <Stack display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                    <Button size='small' id='eMed_Btn_Text' variant='contained' emed_tid="CustomConfig_Save_Btn"
                                        disabled={this.state.disableBtn ? true : !CheckAccessFunc("front_office", "Settings", "Hospital Configuration", "Custom Configuration", "SubTab")?.editAccess}
                                        onClick={() => { this.updateCustomDetails() }}>{t("Save")}</Button>
                                </Stack>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)} /> : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)} /> : null}
                <Dialog
                    open={this.state.PharmaFOLinkPoP && Object.keys(this.state.PharmaFOLinkPoP).length > 0}
                >
                    <Box component={'div'} className="eMed_cnf_Box">
                        <DialogTitle id="alert-dialog-title" sx={{ color: 'red', fontWeight: 600, display: 'flex', alignItems: 'center' }}>
                            <WarningAmberIcon sx={{ marginRight: '0.5vw' }} /> Warning
                        </DialogTitle>
                        <Box component={'div'} className="eMed_cnf_BtnGr">
                            <IconButton onClick={() => { this.setState({ PharmaFOLinkPoP: {} }) }}>
                                <img className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt='Close' />
                            </IconButton>
                        </Box>
                    </Box>
                    <DialogContent dividers sx={{ width: "30vw" }}>
                        <DialogContentText color={'#7a7676'}>{`If you block pharmacy bills from front office module, it may affect the accounts (reports).`}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button size='small' variant="outlined" color='error' onClick={() => { this.setState({ PharmaFOLinkPoP: {} }) }}>Cancel</Button>
                        <Button size='small' variant="contained" color='error' onClick={() => {
                            let states = this.state
                            states[this.state.PharmaFOLinkPoP?.state] = this.state.PharmaFOLinkPoP?.value
                            this.setState({ states }, () => {
                                if (this.state.PharmaFOLinkPoP?.state === "LinkPharmaBilltoFO") {
                                    if (states?.LinkPharmaBilltoFO) {
                                        this.setState({ pharmaCreditPay: true })
                                    }
                                }
                                this.setState({ PharmaFOLinkPoP: {} })
                            })
                        }}>Confirm</Button>
                    </DialogActions>
                </Dialog>
                <Loader loaderOpen={this.state.isLoader} />
            </Box>
        );
    }
}

export default withTranslation()(CustomConfig);