import { Box, TextField } from '@mui/material';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import '../../../FrontOffice/IPPatients/PatientsDetails/ClinicalNotes/DoctorNotes.css'
import { CommonDeleteButton, CommonEditButton } from '../../../../Components/Common Components/CommonComponents';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import ShowComponents from '../Common/ShowComponent';

class DocClinicalHistoryLeft extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ClinicalHistoryText: "",
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    componentDidUpdate(prevprops) {
        if (prevprops.GetAPiCall !== this.props.GetAPiCall && this.props.GetAPiCall) { this.GetClinicalHistory() }
    }

    GetClinicalHistory() {
        try {
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_CLINICAL_HISTORY + `?patient_id=${this.props?.PatientData?.patient_id}&appointment_id=${this.props?.PatientData?.appointment_id}`).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            ClinicalHistoryText: response.data.data?.history ? response.data.data?.history : ""
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    onEditHandler(data) {
        this.props.EditClinicalHistText(this.state.ClinicalHistoryText)
    }
    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    DeleteAdvice() {
        try {
            RestAPIService.delete(Serviceurls.DOC_DOCTOR_NOTES_CLINICAL_HISTORY + `?patient_id=${this.props.PatientData?.patient_id}&appointment_id=${this.props.PatientData?.appointment_id}`).
                then((response) => {
                    if (response.data.status === "success") {
                        this.successMessage(response.data.message)
                        this.setState({ ClinicalHistoryText: "" })
                    } else {
                        this.errorMessage(response.data.message)
                    }
                }).catch(error => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    render() {
        const { t } = this.props
        return (
            <ShowComponents onIntersection={this.GetClinicalHistory.bind(this)}>
                <Box height={'20vh'} display={'flex'} flexDirection={'row'}>
                    <TextField
                        size='small'
                        className='eMed_DocNotes_ProvDiag_TextField'
                        multiline={true}
                        rows={5}
                        label={t("Clinical History")}
                        value={this.state.ClinicalHistoryText}
                        InputProps={{ readOnly: true }}
                    />
                    <Box marginTop={'0.3vw'} sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CommonEditButton size={"1.5vw"} disable={this.props?.isLocked || !this.state.ClinicalHistoryText} onClick={this.onEditHandler.bind(this)} />
                        {/* <CommonDeleteButton size={"1.5vw"} disable={this.props?.isLocked || !this.state.ClinicalHistoryText} onClick={this.DeleteAdvice.bind(this, true)} /> */}
                    </Box>
                    {this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                </Box>
            </ShowComponents>
        )
    }
}
export default withTranslation()(DocClinicalHistoryLeft)
