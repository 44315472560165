import { Table, TableBody, TableCell, Select, TextField, FormControl, MenuItem, TableHead, TableRow, TablePagination, IconButton, InputLabel, FormHelperText, Box, InputAdornment, Typography, CircularProgress, Collapse } from '@mui/material'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Colors } from '../../../Styles/Colors';
import SearchIcon from '@mui/icons-material/Search';
import { DateTime } from 'luxon';
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { getCachevalue, setCachevalue } from '../../../Utility/Services/CacheProviderService';

class LabCancelledBill extends Component {
    constructor(props) {
        super(props);
        this.state = {
            CancelledBillData: [],
            TableSearchText: '',
            tableSelectedIndex: null,
            tblPage: 0,
            tblPageSize: 10,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: 1,
        }
    }

    componentDidMount = () => {
        let dateFilter = getCachevalue("LabCancelledDateFilter")
        if (dateFilter) {
            let date = JSON.parse(dateFilter)
            this.setState({
                fromDate: date?.fromDate ? date?.fromDate : DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                toDate: date?.toDate ? date?.toDate : DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                dateIndex: date?.dateIndex ? date?.dateIndex : 1
            }, () => this.getCancelledBillData())
        } else {
            this.getCancelledBillData()
        }
    }

    getCancelledBillData = () => {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.LAB_PATINET_STATUS + `?result_status=Result Inprogress&cancelled_item=${true}&from_date=${this.state.fromDate}&to_date=${this.state.toDate}`)
                .then(response => {
                    if (response.data.status == "success") {
                        this.setState({
                            CancelledBillData: response.data.data,
                        }, () => {
                            this.LoaderFunction(false)
                        });
                    }
                })
                .catch(e => {
                    if (e?.reponse?.data?.status === 'fail') {
                        this.LoaderFunction(false)
                        this.errorMessage(e.reponse.data.status)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(e.message)
                    }
                });
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    renderSecondTable = (item, index) => {
        try {
            return (
                <><TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={this.state.tableSelectedIndex === index} timeout={'auto'}>
                        <Box component={'div'} margin={1} className='eMed_Lab_Sts_Tbl_Two'>
                            <Table size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Date</TableCell>
                                        <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Bill No</TableCell>
                                        <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Bill Type</TableCell>
                                        <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Barcode Number</TableCell>
                                        <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Last Result Status</TableCell>
                                        <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Total Test</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {item?.data?.map((secTblItem, secTblIndex) => (
                                        <TableRow key={secTblIndex} sx={{ backgroundColor: "white" }}>
                                            <TableCell>{secTblItem?.created_date__date}</TableCell>
                                            <TableCell>{secTblItem?.bill_number}</TableCell>
                                            <TableCell>
                                                <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignContent: 'center', flexDirection: 'column' }}>
                                                    <Typography sx={{ fontWeight: 600, fontSize: "0.875rem", textTransform: 'capitalize' }}>{secTblItem?.bill_type ? secTblItem?.bill_type : "-"}</Typography>
                                                    <Typography sx={{ fontSize: "0.875rem", textTransform: 'capitalize' }}>{secTblItem?.payment_status ? secTblItem?.payment_status : "-"}</Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell>{secTblItem?.barcode}</TableCell>
                                            <TableCell>{secTblItem?.status}</TableCell>
                                            <TableCell>{` ${secTblItem?.completed_test} / ${secTblItem?.total_test}`}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell></>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderCancelBillTable = () => {
        const { t } = this.props
        try {
            let filtereddata = this.state.CancelledBillData?.filter((data) => {
                return (
                    (data?.patient_name === null ? null : data?.patient_name?.toLowerCase().includes(this.state.TableSearchText?.toLowerCase()))
                );
            });
            filtereddata?.slice(
                this.state.tblPage * this.state.tblPageSize,
                this.state.tblPage * this.state.tblPageSize +
                this.state.tblPageSize)?.forEach((element, index) => {
                    let page = this.state.tblPage
                    let pageSize = this.state.tblPageSize
                    let serial = (page * pageSize) + (index + 1)
                    element.serial = serial
                })
            return (
                <Box component={'div'} border={'1px solid lightgray'} height={'67vh'} overflow={'auto'}>
                    <Box component={'div'} height={'57vh'} overflow={'auto'}>
                        <Table stickyHeader size='small'>
                            <TableHead>
                                <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>S.No</TableCell>
                                <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Patient Details</TableCell>
                                <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Mobile Number</TableCell>
                                <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Module Type</TableCell>
                                <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Total Test</TableCell>
                                <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }} />
                            </TableHead>
                            <TableBody>
                                {filtereddata?.length > 0 && filtereddata?.slice(
                                    this.state.tblPage * this.state.tblPageSize,
                                    this.state.tblPage * this.state.tblPageSize +
                                    this.state.tblPageSize)?.map((item, index) => (
                                        <><TableRow key={index}
                                            onClick={() => {
                                                this.setState({
                                                    tableSelectedIndex: this.state.tableSelectedIndex === index ? null : index
                                                })
                                            }}
                                        >
                                            <TableCell>{item?.serial}</TableCell>
                                            <TableCell>
                                                <div style={{ fontSize: '0.9vw', fontWeight: '600' }}>{`${item?.patient_name} ${item?.patient_age}`}</div>
                                                <div style={{ fontSize: '0.8vw', color: Colors.grayShade }}>{`${item?.patient_account_number ? item?.patient_account_number : '-'} | ${item?.patient_tag_name ? item?.patient_tag_name : '-'}`}</div>
                                            </TableCell>
                                            <TableCell>{item?.patient_mobile}</TableCell>
                                            <TableCell>{item?.bill_type}</TableCell>
                                            <TableCell>{`${item?.completed_test} / ${item?.total_test}`}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    size='small'
                                                    onClick={() => {
                                                        this.setState({
                                                            tableSelectedIndex: this.state.tableSelectedIndex === index ? null : index
                                                        })
                                                    }}
                                                >
                                                    {this.state.tableSelectedIndex === index ?
                                                        <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                            <TableRow>
                                                {this.renderSecondTable(item, index)}
                                            </TableRow></>
                                    ))
                                }
                            </TableBody>
                        </Table>
                        {filtereddata?.length === 0 ?
                            this.state.isLoader ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '36vw' }} className='eMed_App_DataView'><CircularProgress /></div> : <div><Typography id='eMed_Pres_pop_NORec' sx={{ top: '25vh' }}>{t("No Records Found")}</Typography></div>
                            : null}
                    </Box>
                    <TablePagination
                        className='eMed_tbl_pagination'
                        sx={{ borderTop: '1px solid lightgray' }}
                        component='div'
                        rowsPerPageOptions={[10, 20, 30]}
                        count={filtereddata?.length}
                        rowsPerPage={this.state.tblPageSize}
                        page={this.state.tblPage}
                        onPageChange={(event, newPage) => { this.setState({ tblPage: newPage }) }}
                        onRowsPerPageChange={(event) => { this.setState({ tblPageSize: event.target.value, tblPage: 0 }) }}
                    />
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    FilterDate = (from, to, index) => {
        let dateFilter = {
            fromDate: from,
            toDate: to,
            dateIndex: index
        }
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        }, () => {
            this.getCancelledBillData()
            setCachevalue(JSON.stringify(dateFilter), 'LabCancelledDateFilter')
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }
    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            isSuccessMsg: false,
            isSuccessMsgText: "",
        })
    }

    render() {
        const { t } = this.props
        return (
            <div className='Lab_Status_Container' >
                <div className='lab_status_sub_header'>
                    <div className='eMed_sts_date'>
                        <div className="eMed_Search_Box_">
                            <TextField
                                variant='standard'
                                placeholder='Search'
                                value={this.state.TableSearchText}
                                onChange={(e) => { this.setState({ TableSearchText: e.target.value }) }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{ width: '15vw' }} />
                        </div>
                        <div style={{ width: '11vw' }}>
                            <CommonDatePicker
                                FromDate={this.state.fromDate}
                                ToDate={this.state.toDate}
                                DateIndex={this.state.dateIndex}
                                SelectedDates={this.FilterDate.bind(this)}
                                HideAllMenu={true} />
                        </div>
                    </div>
                </div>
                <div className='labDataGrid'>
                    {this.renderCancelBillTable()}
                </div>
                {
                    this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : this.state.isSuccessMsg ?
                            <ToastMsg
                                severity={'success'}
                                msg={this.state.isSuccessMsgText}
                                msgPop={this.msgClose.bind(this)}
                            /> : null
                }
            </div>
        )
    }
}
export default withTranslation()(LabCancelledBill)