import { Box, Button, IconButton, InputAdornment, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import { ImagePaths } from '../../../Utility/ImagePaths'
import { BillSuccessPoPUp, CommonCorporateAndInsuranceDetails, CommonPatientDetails, PharmaDiscardPop } from '../../../Components/Common Components/CommonComponents'
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import { withTranslation } from 'react-i18next'
import { AmountFormat, CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions'
import { Colors } from '../../../Styles/Colors';
import './SubLocBillingStyle.css';
import SearchIcon from '@mui/icons-material/Search';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTime } from 'luxon'
import Loader from '../../../Components/Loader';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { CurrencySymbol } from '../../../Utility/Constants';

const EmptyLine = [
  {
    drug_name: '',
    dosage_strength: '',
    dosage_type: '',
    batch_no: '',
    expiry_date: '',
    physical_box_no: '',
    cost_per_quantity: '',
    quantity: '',
    total_cost: '',
    discount: '',
    total_price: '',
    hsn_code: '',
    stock_in_quantity: '',
    Drug_sugg_list: [],
  }
]
class SubLocBilling extends Component {
  constructor(props) {
    super(props)
    this.QuantityRef = React.createRef();
    this.DrugNameRef = React.createRef();
    this.state = {
      discount: '',
      batchNo: '',
      pharmacyTblData: [
        {
          drug_name: '',
          dosage_strength: '',
          dosage_type: '',
          batch_no: '',
          expiry_date: '',
          physical_box_no: '',
          cost_per_quantity: '',
          quantity: '',
          total_cost: '',
          discount: '',
          total_price: '',
          hsn_code: '',
          stock_in_quantity: '',
          Drug_sugg_list: [],
        }
      ],
      subAmount: 0,
      totalPercentage: '',
      totalDiscount: '',
      roundOff: 0,
      netAmount: 0,
      creditPayment: false,
      receivePayment: false,
      savedBill: false,
      DurgNameCurrentIndex: null,
      QuantityCurrentIndex: null,
      PatientDetails: {},
      PatientSearchClicked: false,
      SearchedPatientList: [],
      PatientMobileNumber: "",
      PatientName: "",
      PatientSearch: "",
      description: "",
      invoiceDateTime: new Date(),
      Patient_Id: null,
      Bill_ID: null,
      DateTimerOpen: false,
      isFromSavedBill: false,
      ip_transmit_id: null,
      ConfigSetting: {},
      LoggedRole: null,
      totalBuyingPrice: 0,
      SearchingDrugIndex: null,
      isUser: false,
      PermissionAccess: {},
      isPatientisSearching: false,
      BillDetails : {},
      DateTimeChanged: false,
      successPopup: false,
      successBillId: null,
      totalPercentage_w_r:''
    }
  }

  componentDidMount() {
    let UserDetails = localGetItem("loggedInUserInfo") === null ? {} : JSON.parse(localGetItem("loggedInUserInfo"))
    if (Object.keys(UserDetails).length > 0) {
      this.setState({
        isUser: UserDetails?.is_user,
        PermissionAccess: UserDetails?.permission_access
      })
    }
    setInterval(() => { // DateTime refresh on every minute, when the user not change the datetime
      if (!this.state.DateTimeChanged) {
          const newMinute = new Date().getMinutes();
          if (this.state.invoiceDateTime && this.state.invoiceDateTime?.getMinutes() !== newMinute) {
              this.setState({
                  invoiceDateTime: new Date(),
              });
          }
      }
  }, 1000);
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps && this.props.history?.location?.state?.Bill_ID !== this.state?.Bill_ID && (this.props.history?.location?.state ? Object.keys(this.props.history?.location?.state).length > 0 : false)) {
      if (this.props.history?.location?.state?.isFromSavedBill) {
        this.setState({
          // PatientDetails: this.props.history?.location?.state?.billDetails ? this.props.history?.location?.state?.billDetails : {},
          isFromSavedBill: true,
          Bill_ID: this.props.history?.location?.state?.Bill_ID
        }, () => {
          this.PharmacyPatientSearch(false, this.props.history?.location?.state?.Patient_ID)
          this.GetBillingDetails(Serviceurls.PHARMA_SAVED_BILL_GET + "?id=" + this.props.history?.location?.state?.Bill_ID, true, false)
        })
      }
      setTimeout(() => { this.props.history.push({ state: {} }) }, 1500)
    }
  }


  GetBillingDetails(URL, ExtraLineItem = false) {
    try {
      RestAPIService.getAll(URL)
        .then((response) => {
          if (response.data.status === "success") {
            let EmptyLineObj = [
              {
                drug_name: '',
                dosage_strength: '',
                dosage_type: '',
                batch_no: '',
                expiry_date: '',
                physical_box_no: '',
                cost_per_quantity: '',
                quantity: '',
                total_cost: '',
                discount: '',
                total_price: '',
                hsn_code: '',
                stock_in_quantity: '',
                Drug_sugg_list: [],
              }
            ]
            let LineItems = []
            response.data.data?.bill_line_items?.forEach((item) => {
              item["drug_name"] = item?.brand_name
              item["discount"] = item?.discount_percentage
              item["total_price"] = item?.net_total_amount
              item["stock_list_id"] = item?.sub_loc_stock_id
              LineItems.push(item)
            })
            this.setState({
              pharmacyTblData: ExtraLineItem ? [...LineItems, ...EmptyLineObj] : LineItems,
              totalPercentage: response.data.data?.general_discount ? response.data.data?.general_discount : "",
              totalPercentage_w_r: response.data.data?.general_discount ? CommonValidation.formatAmount(response.data.data?.general_discount) : "",
              totalDiscount: response.data.data?.discount_amount ? response.data.data?.discount_amount : "",
              invoiceDateTime: response.data.data?.invoice_date,
              BillDetails : response.data.data
            })
            this.calculateMarginProfit()
          }
          else {
            this.errorMessage(response.data?.message)
          }
        }).catch((error) => {
          this.errorMessage(error?.message)
        })
    }
    catch (e) {
      this.errorMessage(e?.message)
    }
  }


  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }
  DrugSearchLoader = (index) => {
    this.setState({
      SearchingDrugIndex: index
    })
  }

  getBrandListData = (index) => {
    try {
      let { pharmacyTblData } = this.state
      if (pharmacyTblData[index]['drug_name'] !== "") {
        this.DrugSearchLoader(index)
        RestAPIService.getAll(Serviceurls.SUBLOC_DRUG_SEARCH + `?search_key=${pharmacyTblData[index]['drug_name']}`)
          .then((response) => {
            if (response.data.status === 'success') {
              if (response.data.data.length > 0) {
                pharmacyTblData[index]['Drug_sugg_list'] = response.data.data
                this.setState({ pharmacyTblData }, () => {
                  this.DrugSearchLoader(null)
                })
              } else {
                this.DrugSearchLoader(null)
                this.errorMessage("No Drug Found")
              }
            }
          }).catch((error) => {
            if (error?.response?.data?.message) {
              this.DrugSearchLoader(null)
              this.errorMessage(error.response.data.message)
            } else {
              this.DrugSearchLoader(null)
              this.errorMessage(error.message)
            }
          })
      }
      else {
        this.errorMessage("Enter Brand Name")
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getDrugDetailsData = (index, FromDrug = false) => {
    try {
      let { pharmacyTblData } = this.state
      RestAPIService.getAll(Serviceurls.SUBLOC_BATCH_DETAILS_SEARCH + `?drug_ms_id=${this.state.pharmacyTblData[index]?.drug_id}`)
        .then((response) => {
          if (response.data.status === 'success') {
            if (response.data.data?.length > 0) {
              pharmacyTblData[index]['Batch_Sugg_list'] = response.data.data
              if (pharmacyTblData[index]['batch_no'] === "" || !pharmacyTblData[index]['batch_no']) {
                pharmacyTblData[index]['expiry_date'] = response.data.data[0]?.expiry_date
                pharmacyTblData[index]['physical_box_no'] = response.data.data[0]?.physical_box_no
                pharmacyTblData[index]['batch_no'] = response.data.data[0]?.batch_no
                pharmacyTblData[index]['cost_per_quantity'] = response.data.data[0]?.cost_per_quantity
                pharmacyTblData[index]['hsn_code'] = response.data.data[0]?.hsn_code
                pharmacyTblData[index]['stock_in_quantity'] = response.data.data[0]?.stock_in_quantity
                pharmacyTblData[index]['buying_price_per_piece'] = response.data.data[0]?.buying_price_per_piece
                pharmacyTblData[index]['drug_id'] = response.data.data[0]?.drug_id
                pharmacyTblData[index]['stock_list_id'] = response.data.data[0]?.id
                pharmacyTblData[index]['schedule'] = response.data.data[0]?.schedule
                pharmacyTblData[index]['gst_percentage'] = response.data.data[0]?.gst_percentage
              }
              this.setState({ pharmacyTblData, QuantityCurrentIndex: index })
              if (FromDrug) {
                this.QuantityRef?.current?.focus();
              }
            } else {
              pharmacyTblData[index]['Batch_Sugg_list'] = []
              this.setState({ pharmacyTblData, QuantityCurrentIndex: index })
            }
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }


  postPharmacyBillData = (isFromSavedBill) => {
    try {
      this.LoaderFunction(true)
      let states = this.state
      let url = ''
      if (this.props.isForPatientBilling) { url = Serviceurls.PHARMA_BILLING_RECEIVE_PAYMENT_POST }
      else if (this.props.isForGenericConsumpsion) { url = Serviceurls.SUBLOC_GENERIC_CONSUMP_POST }
      let Lineitems = []
      if (this.props.isForPatientBilling) {
        this.state.pharmacyTblData.forEach((item) => {
          if (item.drug_name !== "") {
            let BillItem = {
              "sub_loc_stock_id": item.stock_list_id,
              "drug_id": item.drug_id,
              "hsn_code": item.hsn_code,
              "batch_no": item.batch_no,
              "expiry_date": item.expiry_date,
              "physical_box_no": item.physical_box_no,
              "cost_per_quantity": item.cost_per_quantity,
              "total_cost": item.total_cost,
              "discount_percentage": +item.discount,
              "gst_percentage": item?.gst_percentage,
              "quantity": +item.quantity,
              "net_total_amount": item.total_price,
            }
            if(states.Bill_ID){
              BillItem["line_item_id"] = item?.line_item_id
            }
            Lineitems.push(BillItem)
          }
        })
      } else {
        this.state.pharmacyTblData.forEach((item) => {
          if (item.drug_name !== "") {
            let BillItem = {
              "drug_ms_id": item.drug_ms_id ? item.drug_ms_id : item.drug_id,
              "stock_list_id": item.stock_list_id,
              "cost_per_quantity": item.cost_per_quantity,
              "total_cost": item.total_cost,
              "qty": +item.quantity,
            }
            Lineitems.push(BillItem)
          }
        })
      }

      let Datetime = new Date(this.state.invoiceDateTime)
      let date = DateTime.fromJSDate(Datetime).toFormat("yyyy-MM-dd")
      let time = DateTime.fromJSDate(Datetime).toLocaleString(DateTime.TIME_24_SIMPLE)
      let data = {}
      if (this.props.isForPatientBilling) {
        data = {
          "invoice_date": `${date} ${time}:00`,
          "billing_customer_name": states.PatientDetails?.name,
          "billing_customer_mobile_no": states?.PatientDetails?.mobile_number,
          "doctor_name": null,
          "patient_id": states.PatientDetails?.id ? states.PatientDetails?.id : states.PatientDetails?.patient_id,
          "patient_account_number": states?.PatientDetails?.patient_account_number,
          "ip_number": states?.PatientDetails?.ip_number,
          "ip_admission_id": states.PatientDetails?.ip_id ? states.PatientDetails?.ip_id : states.PatientDetails?.ip_admission_id ? states.PatientDetails?.ip_admission_id : null,
          "appointment_id": states?.PatientDetails?.ip_admission_id ? null : (states?.PatientDetails?.appointment_id ? states?.PatientDetails?.appointment_id : null),
          "discount_amount": +states.totalDiscount,
          "taxable_amount": states.netAmount,
          "general_discount": +states.totalDiscount,
          "cgst_amount": 0,
          "sgst_amount": 0,
          "igst_amount": 0,
          "tax_amount": 0,
          "round_off": states.roundOff,
          "delivery_charge": 0,
          "gross_amount": states.subAmount,
          "grand_total": states.subAmount,
          "net_amount": states.netAmount,
          "received_amount": 0,
          "balance_amount": 0,
          "bill_line_items": Lineitems,
          "employee_id": null,
          "company_name": null,
          "credit_type": isFromSavedBill ? null : "Patient credit",
          "validity": null,
          "bill_type": isFromSavedBill ? "Saved" : "Credit",
          "doctor_reg_no": null,
          "line_item": [],
          "ip_transmit_id": null,
          "cash_amount": null,
          "card_amount": null,
          "upi_amount": null,
          "bank_amount": null,
          "cheque_amount": null,
          "insurance_amount": null,
          "general_discount_percentage": +states?.totalPercentage,
          "payment_modes": [],
          "doctor_id": states.PatientDetails?.doctor_id ? states.PatientDetails?.doctor_id : null,
          "doctor_reg_no": null,
          "card_number": null,
          "reference_no": null,
          "transaction_id": null,
          "insurance_company_name": null,
          "transaction_number": null,
          "policy_number": null,
          "billing_status": 'Partially Saved',
          "payment_status": 'not paid',
        }
        if (states.Bill_ID) { data["bill_summary_id"] = states.Bill_ID }
        if (states.BillDetails?.temp_invoice_number) { data["temp_invoice_number"] = states.BillDetails?.temp_invoice_number }
        if (data["ip_admission_id"] === null) { data["appointment_id"] = states.PatientDetails?.op_id ? states.PatientDetails?.op_id : states.PatientDetails?.appointment_id }
      } else {
        data = {
          "consumption_date": `${date} ${time}`,
          // "ip_admission_id": 10032,
          // "appointment_id":null,
          "description": states.description,
          "line_items": Lineitems
        }
      }

      RestAPIService.create(data, url)
        .then((response) => {
          if (response.data.status === 'success') {
            if (isFromSavedBill || states.PatientDetails?.billing_summary_id) {
              this.props?.getPharmaSavedBillCount(true)
            }
            if(!isFromSavedBill && this.props.isForPatientBilling){
              this.setState({
                successPopup: true,
                successBillId: response?.data?.data?.id ? response?.data?.data?.id : null
              })
            }
            this.LoaderFunction(false)
            this.successMessage(response.data.message)
            this.DiscardAction(true)
          }
        }).catch(error => {
          if (error?.response?.data?.message) {
            this.LoaderFunction(false)
            this.errorMessage(error.response.data.message)
            this.setState({
              creditPayment: false,
              receivePayment: false,
              savedBill: false
            })
          } else {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
            this.setState({
              creditPayment: false,
              receivePayment: false,
              savedBill: false
            })
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }



  calculateDiscountTotalPrice = (index) => {
    try {
      let { pharmacyTblData } = this.state
      let totalCost = pharmacyTblData[index]['total_cost']
      let discount = pharmacyTblData[index]['discount']
      let discountValue = CommonValidation.calculatePercentageWithoutRoundoff(discount, totalCost)
      pharmacyTblData[index]['total_price'] = totalCost - discountValue
      this.setState({ pharmacyTblData }, () => this.calculateTotalDiscount())
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  calculateCostAndTotal = (value, index, FromReturn = false) => {
    try {
      let { pharmacyTblData } = this.state
      pharmacyTblData[index]['total_cost'] = +value * pharmacyTblData[index]['cost_per_quantity']
      pharmacyTblData[index]['total_price'] = pharmacyTblData[index]['total_cost']
      this.setState({ pharmacyTblData }, () => this.calculateTotalDiscount())
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  calculateTotalDiscount = () => {
    try {
      this.state.totalDiscount = CommonValidation.calculatePercentageWithoutRoundoff(this.state.subAmount, this.state.totalPercentage)
      this.state.totalPercentage = CommonValidation.amountPercentageWithoutRoundoff(this.state.totalDiscount, this.state.subAmount)
      this.setState({
        totalDiscount: this.state.totalDiscount,
        totalPercentage: this.state.totalPercentage,
        totalPercentage_w_r: CommonValidation.formatAmount(this.state.totalPercentage),
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onChangeHandler = (e, index) => {
    try {
      const { name, value } = e.target
      switch (name) {
        case 'quantity':
          if (this.state.pharmacyTblData[index]['drug_name']) {
            if (((CommonValidation.numberOnly(value) && value?.length < 7) || value === '')) {
              this.state.pharmacyTblData[index]['quantity'] = value
              this.setState({
                pharmacyTblData: this.state.pharmacyTblData
              }, () => {
                this.calculateCostAndTotal(value, index, false)
                this.calculateMarginProfit()
              })
            }
          } else {
            this.errorMessage('Select Brand Name')
          }
          break;
        case 'discount':
          if (this.state.pharmacyTblData[index]['quantity']) {
            if ((CommonValidation.DecimalNumber(value) && value >= 1 && value <= 100) || value === '') {
              this.state.pharmacyTblData[index]['discount'] = value
              this.setState({
                pharmacyTblData: this.state.pharmacyTblData
              }, () => {
                this.calculateDiscountTotalPrice(index)
                this.calculateMarginProfit()
              })
            }
          } else {
            this.errorMessage('Enter Quantity')
          }
          break;
        case 'totalDiscount':
          if ((CommonValidation.NumberWithDot(value) && value >= 1 && value <= this.state.subAmount) || value === "") {
            this.state.totalPercentage = CommonValidation.amountPercentageWithoutRoundoff(value, this.state.subAmount)
            this.state.totalPercentage_w_r = CommonValidation.amountPercentage(value, this.state.subAmount)
            this.setState({
              totalDiscount: value
            }, () => {
              this.calculateMarginProfit()
            })
          }
          break;
        case 'totalPercentage':
          if ((CommonValidation.DecimalNumber(value) && value >= 1 && +value <= 100) || value === "") {
            this.state.totalDiscount = CommonValidation.calculatePercentageWithoutRoundoff(value, this.state.subAmount)
            this.setState({
              totalPercentage: value,
              totalPercentage_w_r:value
            }, () => {
              this.calculateMarginProfit()
            })
          }
          break;
        default:
          this.setState({
            [name]: value
          })
          break;
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  disabled = (names, index) => {
    try {
      let disable = true
      switch (names) {
        case 'quantity':
          if (this.state.pharmacyTblData[index]['drug_name']) {
            disable = false
          }
          break;
        case 'discount':
          if (this.state.pharmacyTblData[index]['quantity']) {
            disable = false
          }
          break;
        case 'batch_no':
          if (this.state.pharmacyTblData[index]['drug_name'] && this.state.pharmacyTblData[index]['dosage_strength'] && this.state.pharmacyTblData[index]['dosage_type']) {
            disable = false
          }
          break;
        case 'drug_name':
          disable = false;
          break;
        default:
          disable = true;
          break;
      }
      return disable;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  inputTextBox = (values, names, labels, width, index, height, disable) => {
    return (
      <TextField
        inputRef={(names === "quantity" && this.state.QuantityCurrentIndex === index) ? this.QuantityRef : null}
        autoFocus
        size='small'
        disabled={disable ? disable : this.disabled(names, index)}
        variant='standard'
        sx={{ width: width }}
        placeholder={labels}
        onKeyDown={(e) => { if (e.key === "Enter") { this.DrugNameRef?.current.focus() } }}
        name={names}
        inputProps={{
          emed_tid: labels,
          style: {
            height: height ? height : '2vh', marginTop: '0.5vw', textAlign: 'center',
            color: (names === "quantity" && this.state.pharmacyTblData[index]["drug_name"] !== "") ? (this.state.pharmacyTblData[index]["stock_in_quantity"] < this.state.pharmacyTblData[index]["quantity"] || (!this.state.pharmacyTblData[index]["stock_in_quantity"]) ? "red" : null) : null
          }
        }}
        value={values}
        onChange={(e) => { this.onChangeHandler(e, index) }}
        error={(names === "quantity" && this.state.pharmacyTblData[index]["drug_name"] !== "") ? (this.state.pharmacyTblData[index]["stock_in_quantity"] < this.state.pharmacyTblData[index]["quantity"] || (!this.state.pharmacyTblData[index]["stock_in_quantity"])) : false}
      />
    )
  }

  renderTextBox = (names, value, height, diasble) => {
    try {
      return (
        <TextField
          autoFocus
          variant='standard'
          autoComplete='off'
          size='small'
          disable={this.state.isUser && this.state.PermissionAccess.discount_edit ? false : true}
          InputProps={{
            startAdornment: names === "totalDiscount" ? <InputAdornment position="start">{CurrencySymbol}</InputAdornment> : null,
            endAdornment: names === "totalPercentage" ? <InputAdornment position="start">%</InputAdornment> : null
          }}
          className={'eMed_discount_txt'}
          disabled={diasble}
          inputProps={{ style: { height: height, fontSize: '0.8vw' }, emed_tid: names }}
          name={names}
          value={value}
          onChange={(e) => { this.onChangeHandler(e, names) }}
        />
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  autocompleteOnChangeHandler = (newValue, names, index) => {
    try {
      let { pharmacyTblData } = this.state
      if (pharmacyTblData[index][names]?.length > 0) {
        switch (names) {
          case 'drug_name':
            pharmacyTblData[index][names] = newValue['drug_name']
            pharmacyTblData[index]['dosage_strength'] = newValue['dosage_strength']
            pharmacyTblData[index]['dosage_type'] = newValue['dosage_type']
            pharmacyTblData[index]['drug_id'] = newValue['drug_id']
            pharmacyTblData[index]['quantity'] = ""
            pharmacyTblData[index]['total_cost'] = ""
            pharmacyTblData[index]['total_price'] = ""
            pharmacyTblData[index]['discount'] = ""
            this.setState({ pharmacyTblData, QuantityCurrentIndex: index }, () => this.getDrugDetailsData(index, true))
            // this.QuantityRef?.current?.focus();
            break;
          case 'batch_no':
            pharmacyTblData[index][names] = newValue['batch_no']
            pharmacyTblData[index]['expiry_date'] = newValue['expiry_date']
            pharmacyTblData[index]['cost_per_quantity'] = newValue['cost_per_quantity']
            pharmacyTblData[index]['stock_in_quantity'] = newValue['stock_in_quantity']
            pharmacyTblData[index]['drug_id'] = newValue['drug_id']
            pharmacyTblData[index]['buying_price_per_piece'] = newValue['buying_price_per_piece']
            pharmacyTblData[index]['stock_list_id'] = newValue['id']
            pharmacyTblData[index]['schedule'] = newValue['schedule']
            this.setState({ pharmacyTblData, QuantityCurrentIndex: index, BatchAnchor: null, CurrentBatchIndex: null }, () => { this.calculateDiscountTotalPrice(index) })

            break;
          default: break;
        }
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onInputChangeAutoCompleteHandler = (value, names, index) => {
    try {
      let { pharmacyTblData } = this.state
      switch (names) {
        case 'drug_name':
          this.state.pharmacyTblData[index]['drug_name'] = value
          this.setState({ pharmacyTblData })
          break;
        case 'batch_no':
          this.state.pharmacyTblData[index]['batch_no'] = value
          this.setState({ pharmacyTblData })
          break;
        default: break;
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }


  handleCheckBox = (e, key) => {
    try {
      var states = this.state
      states[key] = e.target.checked
      this.setState({ states }, () => {
        if (!states.isCash) { this.setState({ CashAmount: "" }) }
        if (!states.isCard) { this.setState({ CardAmount: "", CardTransactionNumber: "" }) }
        if (!states.isUPI) { this.setState({ UPIAmount: "", UPINumber: "" }) }
        if (!states.isCheque) { this.setState({ ChequeAmount: "", ChequeNumber: "" }) }
        if (!states.isBankTranfer) { this.setState({ BankTfrAmount: "", BankTfrNumber: "" }) }
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  calculateMarginProfit = () => {
    var { pharmacyTblData } = this.state
    // calculate margin cumulative sum
    for (let i = 0; i < pharmacyTblData.length; i++) {
      let total_buying_price_drug = 0
      if (pharmacyTblData[i].batch_no != "") {
        let profit = parseFloat(pharmacyTblData[i]["cost_per_quantity"]) - parseFloat(pharmacyTblData[i]["buying_price_per_piece"]).toFixed(2)
        pharmacyTblData[i]["margin_profit"] = (profit * (+(pharmacyTblData[i]["quantity"])))

        total_buying_price_drug = (parseFloat(pharmacyTblData[i]["buying_price_per_piece"] * (+(pharmacyTblData[i]["quantity"]))))
        pharmacyTblData[i]["total_buying_price_per_drug"] = total_buying_price_drug

        this.setState({
          pharmacyTblData
        }, () => {
          // calculate total margin profit
          const margin_profit = this.state.pharmacyTblData && this.state.pharmacyTblData.length > 0 && this.state.pharmacyTblData.map((item) => (item.batch_no === "" ? 0 : +(item.margin_profit))).reduce((prev, curr) => (prev + curr));
          // calculate total buying price
          const total_buying_price = this.state.pharmacyTblData && this.state.pharmacyTblData.length > 0 && this.state.pharmacyTblData.map(item => (item.batch_no === "" ? 0 : +(item.total_buying_price_per_drug))).reduce((prev, curr) => (prev + curr));

          this.setState({
            totalMarginProfit: margin_profit,
            totalBuyingPrice: total_buying_price
          })
        })
      }
    }

  }

  billingCalculation = (params = [], type) => {
    try {
      let amount = 0; let payableAmount = 0;
      switch (type) {
        case 'totalServiceAmount':
          if (this.state.isForReturn || this.state.isFromIpReturn) {
            params.forEach(element => {
              amount = amount + (element.total_return_price ? element.total_return_price : 0)
              this.state.subAmount = +amount
            })
          } else {
            params.forEach(element => {
              amount = amount + (element.total_price ? element.total_price : 0)
              this.state.subAmount = +amount
            })
          }
          return AmountFormat(this.state.subAmount || 0)
        case 'marginProfit':
          let finalMarginProfit = this.state.netAmount - this.state.totalBuyingPrice

          return AmountFormat(+this.state.netAmount === 0 ? 0 : (finalMarginProfit ? parseFloat(finalMarginProfit)?.toFixed(2) : 0))
        case 'roundOff':
          this.state.roundOff = CommonValidation.roundOff(this.state.subAmount, this.state.totalDiscount)
          return this.state.roundOff;
        case 'payableAmount':
          let discount = this.state.totalDiscount ? this.state.totalDiscount : 0
          payableAmount = this.state.subAmount - discount
          this.state.netAmount = Math.round(+payableAmount)
          return AmountFormat(this.state.netAmount || 0)
        default: break;
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onAddHandler = (names, index) => {
    try {
      if (this.state.pharmacyTblData[index]['drug_name']) {
        if (names === 'quantity' || names === 'discount') {
          if (index === this.state.pharmacyTblData?.length - 1) {
            let duplicateTbl = {
              drug_name: '',
              dosage_strength: '',
              dosage_type: '',
              batch_no: '',
              expiry_date: '',
              physical_box_no: '',
              cost_per_quantity: '',
              quantity: '',
              total_cost: '',
              discount: '',
              total_price: '',
              hsn_code: '',
              stock_in_quantity: '',
              Drug_sugg_list: [],
              Batch_Sugg_list: [],
            }
            this.state.pharmacyTblData = [...this.state.pharmacyTblData, duplicateTbl]
            this.setState({
              pharmacyTblData: this.state.pharmacyTblData
            })
          }
        }
      } else {
        this.errorMessage('Enter All Fields')
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onClearTableData = () => {
    let TableHasValues = false
    this.state.pharmacyTblData.forEach((list) => {
      if (list.drug_name !== "") { TableHasValues = true }
    })
    if (TableHasValues) {
      this.setState({ ShowDiscard: true })
    } else {
      this.DiscardAction(true)
    }
  }

  PharmacyPatientSearch = (ShowErrMsg = true, PatientId = null) => {
    try {
      let { t } = this.props;
      this.setState({ isPatientisSearching: true })
      let QueryParams = ""
      if (PatientId) {
        QueryParams = `?patient_id=${PatientId}&search_key=`
      } else {
        QueryParams = `?search_key=${CommonValidation.removeSpace(this.state.PatientSearch)}`
      }
      RestAPIService.getAll(Serviceurls.SUBLOC_PATIENT_SEARCH + QueryParams).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              SearchedPatientList: PatientId ? [] : response.data.data,  // For Patient Search
              PatientDetails: PatientId ? response.data.data[0] : {} // For Saved Bill
            }, () => {
              this.setState({ isPatientisSearching: false })
              if (CommonValidation.removeSpace(this.state.PatientSearch) !== "") {
                if (response.data.data.length > 0) {
                  this.setState({ PatientSearchClicked: true })
                } else {
                  if (ShowErrMsg) { this.errorMessage(t("NoRecordsFound")) }
                }
              }
            })
          }
          else {
            this.setState({ isPatientisSearching: false })
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.setState({ isPatientisSearching: false })
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  renderSearch(label, statekey, img, Data = []) {
    const { t } = this.props;
    let states = this.state;
    let PatientDetails = statekey === "PatientDetails" ? `${states.PatientDetails?.name} | ${states.PatientDetails?.mobile_number} | ${states.PatientDetails?.patient_account_number}` : ""
    let IPOPDetails = statekey === "IPDetails" ? `${this.state.PatientDetails?.ip_number ? this.state.PatientDetails?.ip_number : this.state.PatientDetails?.op_number}` : ""
    return (
      <Box className="eMed_Ip_Est_ptCard" height={"3.5vw"} width={"22vw"}>
        <Box component={'div'} className='eMed_pharma_header_icon_warp'>
          <img className='eMed_pharma_header_icon_img' src={img ? img : ImagePaths.Male.default} alt="male" />
        </Box>
        <Box component={'div'} >
          {statekey === "PatientDetails" ?
            <Box display={'flex'}>
              <Box width={"14.5vw"}>
                <Typography fontSize={"0.8vw"} fontWeight={600}>Patient Details</Typography>
                {PatientDetails?.length > 27 ?
                  <Tooltip placement='top' title={PatientDetails} arrow>
                    <Typography fontSize={"0.9vw"}>{PatientDetails.slice(0, 27) + "..."}</Typography>
                  </Tooltip>
                  : <Typography fontSize={"0.9vw"}>{PatientDetails}</Typography>}
              </Box>
              <IconButton disabled={this.state.isFromSavedBill} onClick={() => { this.setState({ PatientDetails: {} }) }}><CloseIcon color={this.state.isFromSavedBill ? "grey" : "error"} /></IconButton>
            </Box> :
            statekey === "IPDetails" ?
              <Box display={'flex'}>
                <Box width={"14.5vw"}>
                  <Typography fontSize={"0.8vw"} fontWeight={600}>{this.state.PatientDetails?.ip_number ? "IP Number" : "OP Number"}</Typography>
                  {IPOPDetails?.length > 27 ?
                    <Tooltip placement='top' title={IPOPDetails} arrow>
                      <Typography fontSize={"0.9vw"}>{IPOPDetails.slice(0, 27) + "..."}</Typography>
                    </Tooltip>
                    : <Typography fontSize={"0.9vw"}>{IPOPDetails}</Typography>}
                </Box>
                <IconButton disabled={this.state.isFromSavedBill} onClick={() => { this.setState({ PatientDetails: {} }) }}><CloseIcon color={this.state.isFromSavedBill ? "grey" : "error"} /></IconButton>
              </Box> :
              statekey === "RoomDetails" ?
                <Box display={'flex'}>
                  <Box width={"14.5vw"}>
                    <Typography fontSize={"0.8vw"} fontWeight={600}>Room Details</Typography>
                    {states.PatientDetails?.room_details?.length > 27 ?
                      <Tooltip placement='top' title={states.PatientDetails?.room_details} arrow>
                        <Typography fontSize={"0.9vw"}>{states.PatientDetails?.room_details.slice(0, 27) + "..."}</Typography>
                      </Tooltip>
                      : <Typography fontSize={"0.9vw"}>{states.PatientDetails?.room_details}</Typography>}
                  </Box>
                  <IconButton disabled={this.state.isFromSavedBill} onClick={() => { this.setState({ PatientDetails: {} }) }}><CloseIcon color={this.state.isFromSavedBill ? "grey" : "error"} /></IconButton>
                </Box>
                : statekey === "invoiceDateTime" ?
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      emed_tid="Invoice DateTime"
                      disabled={this.props.isForGenericConsumpsion ? false : true}
                      open={states.DateTimerOpen}
                      onOpen={() => { this.setState({ DateTimerOpen: true }) }}
                      onClose={() => { this.setState({ DateTimerOpen: false }) }}
                      maxDate={new Date()}
                      label={t(label)}
                      value={states[statekey]}
                      inputFormat='DD-MM-YYYY & hh:mmA'
                      onChange={(value) => {
                        if (value === null) {
                          states[statekey] = new Date()
                        } else {
                          states[statekey] = value
                        }
                        this.setState({ states })
                      }}
                      renderInput={(params) => <TextField
                        size="small" {...params}
                        variant='standard'
                        required
                        autoComplete='off'
                        onKeyDown={(e) => e.preventDefault()}
                        onClick={() => { this.setState({ DateTimerOpen: true }) }}
                        sx={{ width: "17vw" }}
                      />}
                    />
                  </LocalizationProvider>
                  :
                  <TextField
                    autoFocus={true}
                    sx={{ width: "17vw" }}
                    inputProps={{ maxLength: 30, emed_tid: "Patient Details" }}
                    label={label}
                    variant='standard'
                    size="small"
                    autoComplete="off"
                    name={statekey}
                    value={states[statekey] ? states[statekey] : ""}
                    placeholder={label}
                    onKeyDown={(event) => {
                      if (event.key === "Enter" && CommonValidation.removeSpace(event.target.value)?.length > 2 && statekey === "PatientSearch") {
                        this.PharmacyPatientSearch()
                      }
                    }}
                    onChange={(e) => {
                      let value = e.target.value
                      let alpha = CommonValidation.alphanumValidation(value);
                      if (alpha || value === "") {
                        states[statekey] = value
                      }
                      this.setState({ states, MobileNumberAnchor: e.currentTarget, PatientDetails: {}, Patient_Id: null, SearchedPatientList: [] })
                    }}
                    InputProps={{
                      endAdornment: statekey === "PatientSearch" ?
                        this.state.isPatientisSearching ?
                          <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                            <InputAdornment position="end"><CircularProgress size={15} /></InputAdornment>
                          </Box> :
                          <InputAdornment position="end">
                            <IconButton size="small"
                              onClick={() => {
                                if (CommonValidation.removeSpace(states.PatientSearch)?.length > 2) {
                                  this.PharmacyPatientSearch()
                                }
                              }}>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment> : null
                    }}
                  />}
        </Box>
        <Menu
          open={this.state.PatientSearchClicked}
          className='eMed_Pharma_Mobile_Search_Pop'
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={() => {
            this.setState({
              SearchedPatientList: [],
              PatientSearchClicked: false
            })
          }}
          elevation={1}
        >
          {
            this.state.SearchedPatientList.length > 0 ?
              this.state.SearchedPatientList.map((list, index) => {
                list["patient_name"] = list?.name
                list["patient_age"] = list?.age
                return (
                  <MenuItem emed_tid="Patient_mobile_suggestions" autoFocus={index === 0} onClick={() => {
                    this.setState({
                      PatientDetails: list,
                      SearchedPatientList: [],
                      PatientSearchClicked: false,
                      PatientSearch: ""
                    })
                  }} key={index}>
                    <CommonPatientDetails data={list} />
                  </MenuItem>
                )
              }) : null
          }
        </Menu>
      </Box>
    )
  }

  deleteLineItems = (index = null) => {
    try {
      if (index != null && this.state.pharmacyTblData.length > 1) {
        if (index !== this.state.pharmacyTblData.length - 1) {
          this.state.pharmacyTblData.splice(index, 1)
          this.setState({ pharmacyTblData: this.state.pharmacyTblData })
        }
      } else {
        this.setState({
          pharmacyTblData: [
            {
              drug_name: '',
              dosage_strength: '',
              dosage_type: '',
              batch_no: '',
              expiry_date: '',
              physical_box_no: '',
              cost_per_quantity: '',
              quantity: '',
              total_cost: '',
              discount: '',
              total_price: '',
              hsn_code: '',
              stock_in_quantity: '',
              Drug_sugg_list: [],
            }
          ]
        })
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  BillItemsIsValid = () => {
    try {
      let Flag = false
      let BillingLineItems = []
      this.state.pharmacyTblData.forEach((item) => { if (item.drug_name !== "") { BillingLineItems.push(item) } })
      if (BillingLineItems.length > 0) {
        for (let i = 0; i < BillingLineItems.length; i++) {
          if ((BillingLineItems[i]["drug_name"] !== "" && BillingLineItems[i]["batch_no"] !== "" && BillingLineItems[i]["quantity"] !== "") && BillingLineItems.length > 0) {
            if ((BillingLineItems[i]["quantity"] <= BillingLineItems[i]["stock_in_quantity"] || !BillingLineItems[i]["stock_in_quantity"]) && BillingLineItems[i]["quantity"] !== "") {
              Flag = true
            } else {
              if (BillingLineItems[i]["quantity"] > BillingLineItems[i]["stock_in_quantity"]) {
                this.errorMessage("Quantity is More then Stock Quantity")
              }
            }
          } else {
            if (BillingLineItems[i]["drug_name"] === "") { this.errorMessage("No drug were Entered") }
            else if (BillingLineItems[i]["batch_no"] === "") { this.errorMessage("Batch were Not Selected") }
            else if (BillingLineItems[i]["quantity"] === "") { this.errorMessage("Quantity is Not Entered") }
            else { this.errorMessage("Invalid Bill Items") }
            Flag = false
            break
          }
        }
        return Flag
      } else {
        this.errorMessage("No Drug were Added")
        return Flag
      }

    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  Validation = (isFromSavedBill = false) => {
    try {
      let states = this.state;
      if (this.BillItemsIsValid() && (Object.keys(states.PatientDetails)?.length > 0 || this.props.isForGenericConsumpsion)) {
        this.postPharmacyBillData(isFromSavedBill)
      } else {
        if (!Object.keys(states.PatientDetails)?.length > 0 && this.props.isForPatientBilling) { this.errorMessage("Select Patient") }
      }
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  renderPaymentButton = () => {
    try {
      return (
        this.props.isForPatientBilling ?
          <Box component={'div'} display={'flex'} sx={{ backgroundColor: Colors.Background }}>
            <Box flex={1} display={'flex'} justifyContent={'flex-end'}>
              <Button emed_tid="bill_clear_btn" sx={{ margin: '0.5vw 1vw 0.5vw 0vw', textTransform: 'capitalize' }}
                disabled={this.state.isFromInvoice}
                size='small'
                variant='outlined'
                onClick={() => this.onClearTableData()}
              >Clear</Button>
              <Button emed_tid="bill_saved_btn" sx={{ margin: '0.5vw 1vw 0.5vw 0vw', textTransform: 'capitalize' }}
                size='small'
                variant='outlined'
                disabled={JSON.stringify(EmptyLine) === JSON.stringify(this.state.pharmacyTblData) || Object.keys(this.state.pharmacyTblData).length === 0 || (CheckAccessFunc("sub_location", "Home", "Patient Billing",  null, "Tab")?.editAccess === false)}
                onClick={() => { this.Validation(true) }}
              >Save As Draft</Button>
              <Button emed_tid="bill_receive_payment_btn" sx={{ margin: '0.5vw 1vw 0.5vw 0vw', textTransform: 'capitalize', width: '10vw' }}
                size='small'
                variant='contained'
                disabled={JSON.stringify(EmptyLine) === JSON.stringify(this.state.pharmacyTblData) || Object.keys(this.state.pharmacyTblData).length === 0 || (CheckAccessFunc("sub_location", "Home", "Patient Billing",  null, "Tab")?.editAccess === false) }
                onClick={() => { this.Validation(false) }}
              >{'Add To Credit'}</Button>
            </Box>
          </Box> :
          <Box component={'div'} display={'flex'} sx={{ backgroundColor: Colors.Background }}>
            <Box flex={1} display={'flex'} justifyContent={'flex-end'}>
              <Button emed_tid="bill_clear_btn" sx={{ margin: '0.5vw 1vw 0.5vw 0vw', textTransform: 'capitalize' }}
                disabled={this.state.isFromInvoice}
                size='small'
                variant='outlined'
                onClick={() => this.onClearTableData()}
              >Clear</Button>
              <Button emed_tid="bill_receive_payment_btn" sx={{ margin: '0.5vw 1vw 0.5vw 0vw', textTransform: 'capitalize', width: '10vw' }}
                size='small'
                variant='contained'
                disabled={JSON.stringify(EmptyLine) === JSON.stringify(this.state.pharmacyTblData) || this.state.description === ""}
                onClick={() => { this.Validation() }}
              >{"Reduce Stock"}</Button>
            </Box>
          </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  customPharmaFooter = (params) => {
    try {
      const { t } = this.props
      let disable = this.state.subAmount ? ((this.state.isUser && this.state.PermissionAccess?.discount_edit === false) ? true : false) : true
      return (
        <Box component={'div'} height={this.props.isForPatientBilling ? '11.2vw' : "3.5vw"} className='eMed_Phrama_Table_Container'>
          {this.props.isForPatientBilling ?
            <Box component={'div'} display={'flex'} justifyContent={"flex-end"}>
              <Box component={'div'} flex={0.25}>
                <Box component={'div'} className='eMed_bill_tbl_footer'>
                  <Typography mt={0.5} fontSize={'0.9vw'}>{t("Sub Amount")}</Typography>
                  <Typography emed_tid="total_sub_amount" mt={0.5} mr={'0.8vw'}>{this.billingCalculation(params, 'totalServiceAmount')}</Typography>
                </Box>
                {/* <Box component={'div'} className='eMed_bill_tbl_footer'>
                  <Typography mt={0.5} fontSize={'0.9vw'}>{`${t("Discount")} %`}</Typography>
                  {this.renderTextBox('totalPercentage', this.state.totalPercentage_w_r, '2vh', disable)}
                  {this.renderTextBox('totalDiscount', this.state.totalDiscount, '2vh', disable)}
                </Box> */}
                <Box component={'div'} className='eMed_bill_tbl_footer'>
                  <Typography mt={0.5} fontSize={'0.9vw'}>{t("RoundOff")}</Typography>
                  <Typography emed_tid="total_roundoff_amount" mt={0.5} mr={'0.8vw'}>
                    {AmountFormat(this.billingCalculation(params, 'roundOff') || 0)}
                  </Typography>
                </Box>
                <Box component={'div'} className='eMed_bill_tbl_footer' borderTop={"2px solid gray"}>
                  <Typography mt={0.5} fontSize={'0.9vw'} fontWeight='600'>{t("PayableAmount")}</Typography>
                  <Typography emed_tid="total_payable_amount" mt={0.5} mr={'0.8vw'} fontWeight='600'>
                    {this.billingCalculation(params, 'payableAmount')}
                  </Typography>
                </Box>
                {/* <Box sx={{ display: "flex", visibility: (this.state.isUser && this.state.PermissionAccess?.margin_profit === false) ? "hidden" : "visible" }} component={'div'} className='eMed_bill_tbl_footer'>
                  <Typography mt={0.5} fontSize={'0.9vw'} >{t("Margin Profit")}</Typography>
                  <Typography emed_tid="total_margin_profit" mt={0.5} mr={'0.8vw'}>
                    {this.billingCalculation(params, 'marginProfit')}
                  </Typography>
                </Box> */}
              </Box>
            </Box> : null}
          {this.renderPaymentButton()}
        </Box>
      )
    } catch (error) {
      // this.errorMessage(error.message)
    }
  }

  isScheduledDrug(index) {
    try {
      if (
        this.state.pharmacyTblData[index]?.schedule &&
        this.state.pharmacyTblData[index]?.schedule !== null &&
        this.state.pharmacyTblData[index]?.schedule !== "" &&
        this.state.pharmacyTblData[index]?.drug_name &&
        this.state.pharmacyTblData[index]?.drug_name !== "" &&
        this.state.pharmacyTblData[index]?.batch_no &&
        this.state.pharmacyTblData[index]?.batch_no !== "") {
        return true
      }
    } catch (e) {
      return false
    }
  }

  renderBatchColumn = (index, item) => {
    return (
      <Box>
        <TextField
          inputRef={this.BatchNameRef}
          disabled={this.disabled('batch_no', index)}
          size='small'
          variant='standard'
          autoComplete='off'
          value={item.batch_no}
          onKeyDown={(e) => {
            e.preventDefault()
            // this.setState({ BatchAnchor: e.currentTarget })
          }}
          onFocus={(e) => {
            this.setState({
              BatchAnchor: e.currentTarget,
              CurrentBatchIndex: index
            }, () => {
              e.preventDefault()
              if ((!this.state.pharmacyTblData[index]['Batch_Sugg_list'])) {
                this.getDrugDetailsData(index, false)
              }
            })
          }}
          inputProps={{ emed_tid: "bill_batch_name", style: { paddingLeft: '0.4vw' } }}
          InputProps={{
            endAdornment: this.state.SearchingBatchIndex === index ? <InputAdornment position="end"><CircularProgress size={15} /></InputAdornment> : <InputAdornment position="end"><ArrowDropDownIcon size={15} /></InputAdornment>,
          }}

        />
        <Menu
          open={this.state.CurrentBatchIndex === index}
          className="eMed_Drug_Sugg_Pop"
          anchorEl={this.state.BatchAnchor}
          onClose={() => {
            this.setState({ BatchAnchor: null, CurrentBatchIndex: null })
          }}
          autoFocus={true}
          disableAutoFocusItem
          disableAutoFocus
          disableRestoreFocus
        >
          <Box width={"17vw"} >
            <Box sx={{ display: 'flex', backgroundColor: Colors.ThemeLightColor, paddingY: '0.4vw' }} emed_tid={"bill_batch_no_items"}>
              <Typography width={"40%"} fontWeight={600} fontSize={'0.8vw'} paddingLeft={'1vw'}>Batch No</Typography>
              <Typography width={"30%"} fontWeight={600} fontSize={'0.8vw'} >Expiry Date</Typography>
              <Typography width={"30%"} fontWeight={600} fontSize={'0.8vw'} >Available Qty</Typography>
            </Box>
            <Box maxHeight={'14vw'} overflow={'scroll'}>
              {
                (item.Batch_Sugg_list && item.Batch_Sugg_list.length > 0) ?
                  item.Batch_Sugg_list.map((list, Drugindex) => {
                    var datePart = list?.expiry_date ? list?.expiry_date.match(/\d+/g) : []
                    var ExpiryDate = datePart.length === 3 ? datePart[1] + '-' + datePart[0] : "-";
                    return (
                      <MenuItem
                        onKeyDown={(e) => {
                          if (Drugindex === item.Batch_Sugg_list.length - 1 && e.key === "ArrowDown") { e.stopPropagation() }
                          else if (Drugindex === 0 && e.key === "ArrowUp") {
                            e.stopPropagation()
                            this.setState({ DurgNameCurrentIndex: index })
                          }
                        }}
                        emed_tid={`batch_suggestion`}
                        key={Drugindex}
                        autoFocus={true}
                        onClick={() => { this.autocompleteOnChangeHandler(list, 'batch_no', index) }}
                        sx={[{ display: 'flex', flexDirection: 'row', backgroundColor: list?.batch_expiry_alert ? '#EE9E4F' : 'white', '&:hover': list.batch_expiry_alert ? {backgroundColor: '#f0bc89'} : null }]}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', width: "100%" }}>
                          {list?.batch_no ? list?.batch_no?.length > 15 ?
                            <Tooltip placement='top' title={list?.batch_no} arrow>
                              <Typography width={"40%"} fontSize={'0.8vw'}>{list?.batch_no.slice(0, 15) + "..."}</Typography>
                            </Tooltip>
                            : <Typography width={"40%"} fontSize={'0.8vw'}>{list?.batch_no}</Typography> : "-"}
                          <Typography width={"30%"} fontSize={'0.8vw'}>{ExpiryDate}</Typography>
                          <Typography width={"30%"} fontSize={'0.8vw'} textAlign={"center"}>{list?.stock_in_quantity}</Typography>
                        </Box>
                      </MenuItem>
                    )
                  }) : <Typography textAlign={"center"} paddingY={"0.5vw"} width={"100%"} fontSize={'0.8vw'}>{"No Batches Found"}</Typography>
              }
            </Box>
          </Box>
        </Menu>
      </Box>
    )
  }

  renderDrugColumn = (index, item) => {
    let frequent = "Frequently Brought Medicine"
    let { pharmacyTblData } = this.state
    return (
      <Box>
        <TextField
          inputRef={this.DrugNameRef}
          size='small'
          disabled={(this.props.isForPatientBilling && CheckAccessFunc("sub_location", "Home", "Patient Billing",  null, "Tab")?.editAccess === false) || (this.props.isForGenericConsumpsion && CheckAccessFunc("sub_location", "Home", "Generic Consumption",  null, "Tab")?.editAccess === false)}
          variant='standard'
          value={item.drug_name}
          onChange={(e) => {
            pharmacyTblData[index]['drug_name'] = e.target.value
            pharmacyTblData[index]['dosage_strength'] = ""
            pharmacyTblData[index]['batch_no'] = ""
            pharmacyTblData[index]['dosage_type'] = ""
            pharmacyTblData[index]['physical_box_no'] = ""
            pharmacyTblData[index]['cost_per_quantity'] = ""
            pharmacyTblData[index]['total_cost'] = ""
            pharmacyTblData[index]['quantity'] = ""
            pharmacyTblData[index]['discount'] = ""
            pharmacyTblData[index]['total_price'] = ""
            pharmacyTblData[index]['hsn_code'] = ""
            pharmacyTblData[index]['stock_in_quantity'] = ""
            pharmacyTblData[index]['expiry_date'] = ""
            pharmacyTblData[index]['drug_ms_id'] = ""
            pharmacyTblData[index]['Drug_sugg_list'] = []
            delete pharmacyTblData[index]['Batch_Sugg_list']
            this.setState({ pharmacyTblData }, () => {
              if (CommonValidation.removeSpace(pharmacyTblData[index]['drug_name'])?.length > 2) {
                this.setState({
                  DurgNameCurrentIndex: index
                }, () => { this.getBrandListData(index) })
              }
            })
          }}
          onKeyDown={(e) => {
            this.setState({
              DrugNameAnchor: e.currentTarget,
              FocusDrugList: e.key === "ArrowDown" ? true : false
            }, () => {
              if (e.key === 'Enter' && pharmacyTblData[index]['drug_name']?.length > 2) {
                this.setState({
                  DurgNameCurrentIndex: index
                }, () => { this.getBrandListData(index) })
              }
            })
          }}
          className={this.isScheduledDrug(index) ? 'eMed_SubLoc_DrugBox_Scheduled' : null}
          inputProps={{ emed_tid: "bill_drug_name", style: { color: this.isScheduledDrug(index) ? 'rgb(150, 13, 11)' : null, paddingLeft: '0.4vw' } }}
          InputProps={{
            endAdornment: this.state.SearchingDrugIndex === index ? <InputAdornment position="end"><CircularProgress size={15} /></InputAdornment> : null,
          }}
        />
        <Menu
          open={this.state.DurgNameCurrentIndex === index && item.Drug_sugg_list && item.Drug_sugg_list?.length > 0}
          className="eMed_Drug_Sugg_Pop"
          anchorEl={this.state.DrugNameAnchor}
          onClose={() => {
            this.setState({ DurgNameCurrentIndex: null })
          }}
          autoFocus={false}
          disableAutoFocusItem
          disableAutoFocus
        >
          <Box width={"21vw"}>
            <Box sx={{ display: 'flex', backgroundColor: Colors.ThemeLightColor, paddingY: '0.3vw' }}>
              <Tooltip placement='top' title={frequent}><Typography width={"70%"} fontWeight={'600'} fontSize={"0.8vw"} paddingLeft={'1vw'}>{frequent}</Typography></Tooltip>
              <Typography width={"30%"} textAlign={"center"} fontWeight={'600'} fontSize={"0.8vw"}>Quantity</Typography>
            </Box>
            <Box>
              {
                (item.Drug_sugg_list && item.Drug_sugg_list.length > 0) ?
                  item.Drug_sugg_list.map((list, Drugindex) => (
                    <MenuItem
                      onKeyDown={(e) => {
                        if (Drugindex === item.Drug_sugg_list.length - 1 && e.key === "ArrowDown") { e.stopPropagation() }
                        else if (Drugindex === 0 && e.key === "ArrowUp") {
                          e.stopPropagation()
                          this.setState({ DurgNameCurrentIndex: index })
                        }
                      }}
                      emed_tid="bill_drug_sugg_li"
                      key={Drugindex}
                      autoFocus={this.state.FocusDrugList && Drugindex === 0}
                      onClick={() => {
                        this.setState({
                          DurgNameCurrentIndex: null
                        }, () => {
                          this.onAddHandler("quantity", index)
                          this.autocompleteOnChangeHandler(list, "drug_name", index)
                        })
                      }}
                      sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Box width={"70%"}>
                        <CommonCorporateAndInsuranceDetails data={list} fromPharmaBilling={true} />
                      </Box>
                      <Typography width={"30%"} fontSize={"0.8vw"} textAlign={"center"}>{list?.in_stock}</Typography>
                    </MenuItem>
                  )) : null
              }
            </Box>
          </Box>
        </Menu>

      </Box>
    )
  }

  DiscardAction = (flag) => {
    if (flag) {
      const EmptyBillItem = [
        {
          drug_name: '',
          dosage_strength: '',
          dosage_type: '',
          batch_no: '',
          expiry_date: '',
          physical_box_no: '',
          cost_per_quantity: '',
          quantity: '',
          total_cost: '',
          discount: '',
          total_price: '',
          hsn_code: '',
          stock_in_quantity: '',
          Drug_sugg_list: [],
        }
      ]
      try {
        this.setState({
          pharmacyTblData: EmptyBillItem,
          netAmount: 0,
          subAmount: 0,
          roundOff: 0,
          totalDiscount: '',
          totalPercentage: '',
          DurgNameCurrentIndex: null,
          PatientDetails: {},
          PatientSearchClicked: false,
          SearchedPatientList: [],
          doctordetails: {},
          PatientMobileNumber: "",
          PatientName: "",
          invoiceDateTime: new Date(),
          ShowDiscard: false,
          PatientId: null,
          Bill_ID: null,
          description: "",
          PatientSearch: "",
          BillDetails : {},
          DateTimeChanged: false,
          totalPercentage_w_r:''
        })
      } catch (error) {
        this.errorMessage(error.message)
      }
    } else {
      this.setState({ ShowDiscard: false })
    }
  }

  ClosePopUp = () => {
    this.setState({
      successPopup: false,
      successBillId: null
    })
  }

  render() {
    let { t } = this.props
    return (
      <Box component={'div'} className='eMed_Pharma_Billing_container'>
        <Box component={'div'} height={'5vw'} className='eMEd_pharma_Home_header' justifyContent={'space-between'}>
          {this.props.isForPatientBilling && Object.keys(this.state.PatientDetails).length === 0 ?
            this.renderSearch("Search Mobile / Name / UHID", "PatientSearch", ImagePaths.AvatarCardIcon.default) : null}

          {this.props.isForPatientBilling && Object.keys(this.state.PatientDetails).length > 0 ?
            this.renderSearch("Patient Name | Mobile | UHID", "PatientDetails", ImagePaths.AvatarCardIcon.default) : null}
          {this.props.isForPatientBilling && Object.keys(this.state.PatientDetails).length > 0 && (this.state.PatientDetails?.ip_number || this.state.PatientDetails?.op_number) ?
            this.renderSearch("Patient Name | Mobile | UHID", "IPDetails", ImagePaths.IpHomeOff.default) : null}
          {this.props.isForPatientBilling && Object.keys(this.state.PatientDetails).length > 0 && this.state.PatientDetails?.room_details ?
            this.renderSearch("Patient Name | Mobile | UHID", "RoomDetails", ImagePaths.RoomCardImg.default) : null}

          {this.props.isForGenericConsumpsion ?
            this.renderSearch("Description", "description", ImagePaths.DescriptionCard.default,) : null}

          {this.renderSearch("Invoice Date & Time", "invoiceDateTime", ImagePaths.CalenderIcon.default,)}
        </Box>
        <Box component={'div'}>
          <Box component={'div'}>
            <TableContainer className='eMed_Phrama_Table_Container' sx={{ height: this.props.isForPatientBilling ? "22vw" : "29.5vw" }}>
              <Table stickyHeader size='small' sx={{ overflowY: 'scroll' }}>
                <TableHead>
                  <TableRow sx={{ display: 'flex' }}>
                    <TableCell sx={{ flex: 0.01 }} className='eMed_Pharma_tbl_header'>
                      <Button emed_tid={"bill_delete_all"} className='eMed_Pharma_del_btn' onClick={() => this.deleteLineItems()}>
                        <Box component={'img'} mt={'0.2vw'} src={ImagePaths.Delete.default} alt='del' height={'1.3vw'} width={'1.3vw'} />
                      </Button>
                    </TableCell>
                    <TableCell sx={{ flex: 0.170 }} className='eMed_Pharma_tbl_header'>Brand Name</TableCell>
                    <TableCell sx={{ flex: 0.098 }} align='center' className='eMed_Pharma_tbl_header'>Strength</TableCell>
                    <TableCell sx={{ flex: 0.098 }} className='eMed_Pharma_tbl_header'>Type</TableCell>
                    <TableCell sx={{ flex: 0.129 }} className='eMed_Pharma_tbl_header'>Batch-Avl Qty</TableCell>
                    <TableCell align='center' sx={{ flex: 0.083 }} className='eMed_Pharma_tbl_header'>Expiry Date</TableCell>
                    <TableCell align='center' sx={{ flex: 0.093 }} className='eMed_Pharma_tbl_header'>Box No.</TableCell>
                    <TableCell sx={{ flex: 0.083 }} align='left' className='eMed_Pharma_tbl_header'>Cost/Qty</TableCell>
                    <TableCell align='center' sx={{ flex: 0.083 }} className='eMed_Pharma_tbl_header'>Quantity</TableCell>
                    <TableCell sx={{ flex: 0.083 }} className='eMed_Pharma_tbl_header'>{`Total Cost (${CurrencySymbol})`}</TableCell>
                    <TableCell align='right' sx={{ flex: 0.083 }} className='eMed_Pharma_tbl_header'>{`Total (${CurrencySymbol})`}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.pharmacyTblData.map((item, index) => {
                    var datePart = item?.expiry_date ? item?.expiry_date.match(/\d+/g) : []
                    var ExpiryDate = datePart.length === 3 ? datePart[1] + '-' + datePart[0] : "-";
                    return (
                      <TableRow sx={{ display: 'flex' }} key={index}>
                        <TableCell sx={{ flex: 0.01 }}>
                          <Button emed_tid={"bill_delete_item"} className='eMed_Pharma_del_btn' onClick={() => this.deleteLineItems(index)}>
                            <Box component={'img'} mt={'0.5vw'} src={ImagePaths.Delete.default} alt='del' height={'1.1vw'} width={'1.1vw'} />
                          </Button>
                        </TableCell>
                        <TableCell sx={{ flex: 0.170 }}>{this.renderDrugColumn(index, item)}</TableCell>
                        <TableCell align='center' emed_tid="bill_dosage_strength" sx={{ flex: 0.098, paddingTop: '0.8vw' }} className='eMed_Pharma_tbl_Body'>{item?.dosage_strength ? item?.dosage_strength : '-'}</TableCell>
                        <TableCell emed_tid="bill_dosage_type" sx={{ flex: 0.098, paddingTop: '0.8vw' }}>
                          {item?.dosage_type ? item?.dosage_type?.length > 10 ?
                            <Tooltip placement='top' title={item?.dosage_type} arrow>
                              <div style={{ fontSize: "0.875rem" }}>{item?.dosage_type.slice(0, 10) + "..."}</div></Tooltip> :
                            item?.dosage_type : "-"}
                        </TableCell>
                        <TableCell sx={{ flex: 0.129 }}>
                          {this.renderBatchColumn(index, item)}
                        </TableCell>
                        <TableCell align={'center'} emed_tid="bill_expiry_date" sx={{ flex: 0.083, paddingTop: '0.8vw' }}>{ExpiryDate ? ExpiryDate : '-'}</TableCell>
                        <TableCell align='center' emed_tid="bill_physical_box_no" sx={{ flex: 0.093, paddingTop: '0.8vw' }}>{item?.physical_box_no ? item?.physical_box_no : '-'}</TableCell>
                        <TableCell emed_tid="bill_cost_per_quantity" align='center' sx={{ flex: 0.083, paddingTop: '0.8vw' }}>{AmountFormat(item?.cost_per_quantity|| 0)?.replace(`${CurrencySymbol}`, "")}</TableCell>
                        <TableCell sx={{ flex: 0.083 }}>{this.inputTextBox(item?.quantity, 'quantity', 'Quantity', '6vw', index)}</TableCell>
                        <TableCell emed_tid="bill_total_cost" align='center' sx={{ flex: 0.083, paddingTop: '0.8vw' }}>{AmountFormat(item?.total_cost || 0)?.replace(`${CurrencySymbol}`, "")}</TableCell>
                        <TableCell align='right' emed_tid="bill_total_price" sx={{ flex: 0.083, paddingTop: '0.8vw' }}>
                          {(AmountFormat(item?.total_price || 0)?.replace(`${CurrencySymbol}`, ""))}
                        </TableCell>
                      </TableRow>)
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {this.customPharmaFooter(this.state.pharmacyTblData)}
            {this.state.isErrorMsg ?
              <ToastMsg
                severity={'error'}
                msg={this.state.isErrorMsgText}
                msgPop={this.msgClose.bind(this)}
              />
              : null}
            {this.state.successMsg ?
              <ToastMsg
                severity={'success'}
                msg={this.state.successMsgText}
                msgPop={this.msgClose.bind(this)}
              />
              : null}
            <Loader loaderOpen={this.state.isLoader} />
            {this.state.ShowDiscard ? <PharmaDiscardPop SendAction={this.DiscardAction.bind(this)} /> : null}
            {this.state.successPopup ? <BillSuccessPoPUp isDirectPrint={true} successTxt="Credit Added Successfully" ClosePopUp={this.ClosePopUp} isFromSubLocation={true} InvoiceId={this.state.successBillId}/>: null}
          </Box>
        </Box>
      </Box>
    )
  }
}
export default withTranslation()(SubLocBilling)