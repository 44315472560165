import { Box, Button, CircularProgress, FormControl, FormHelperText, MenuItem, Select, TextField, Typography } from '@mui/material'
import React, { Component } from 'react'
import { Colors } from '../../../../Styles/Colors'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import { getCachevalue, localGetItem } from '../../../../Utility/Services/CacheProviderService'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation'
import { calculateBMI } from '../../../../Components/CommonFunctions/CommonFunctions'
import ShowComponents from './ShowComponent';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default class DocVitals extends Component {
  constructor(props) {
    super(props)
    this.state = {
      patientId: this.props.patientId,
      clinicId: this.props.clinicId,
      appointmentId: this.props.appointmentId,
      height: '',
      weight: '',
      bp_d: '',
      bp_s: '',
      temp: '',
      spo2: '',
      pulserate: '',
      bloodglucouse: '',
      postLoading: false,
      tempUnit: 'F',
      vitalsData: [],
      loading: false,
      isLocked: false,
      bmi: 0.00,
      headCircumference: '',
      waist_circumference:'',
      show_waist_circumference:false
    }
  }

  componentDidMount(){
    let show_waist_circumference = JSON.parse(localGetItem('loggedInUserInfo'))?.show_waist_circumference
    this.setState({ show_waist_circumference: show_waist_circumference })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLocked !== this.props.isLocked) {
      this.setState({ isLocked: this.props.isLocked })
    }
  }

  getDoctorNotesVitalData = () => {
    try {
      let appointmentId = this.props.prevAppointmentId ? this.props.prevAppointmentId : this.props.appointmentId
      this.setState({ loading: true })
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_VITALS + `?appointment_id=${appointmentId}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            if (response.data.data?.length > 0) {
              this.setState({
                vitalsData: response.data.data,
                height: response.data.data[0]?.height_cm,
                weight: response.data.data[0]?.weight_kg,
                bp_d: response.data.data[0]?.blood_pressure_diastolic,
                bp_s: response.data.data[0]?.blood_pressure_systolic,
                temp: response.data.data[0]?.temperature,
                spo2: response.data.data[0]?.pulse_oximetry,
                pulserate: response.data.data[0]?.pulse_rate,
                bloodglucouse: response.data.data[0]?.blood_sugar,
                tempUnit: response.data.data[0]?.temperature_unit,
                bmi: response.data.data[0]?.bmi,
                headCircumference: response.data.data[0]?.head_circumference,
                waist_circumference:response.data.data[0]?.waist_circumference,
                postLoading: true,
                loading: false,
              })
            }
          }
        }).catch(error => {
          if (error?.response?.data?.message) {
            this.setState({ loading: false })
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.setState({ loading: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ loading: false })
      this.errorMessage(error.message)
    }
  }

  postDoctorNotesVitalData = () => {
    try {
      let states = this.state
      let data = {
        appointment_id: this.props.appointmentId,
        blood_pressure_diastolic: +states.bp_d,
        blood_pressure_systolic: +states.bp_s,
        blood_sugar: +states.bloodglucouse,
        bmi: states.height && states.weight ? states.bmi : null,
        height_cm: +states.height,
        pulse_rate: +states.pulserate,
        pulse_oximetry: +states.spo2,
        temperature: +states.temp,
        temperature_unit: states.tempUnit,
        weight_kg: +states.weight,
        dataDisable: true,
        head_circumference: this.state.headCircumference ? this.state.headCircumference : null,
        waist_circumference:this.state.waist_circumference ? this.state.waist_circumference : null
      }
      if (
        (this.state.height !== "") || (this.state.weight !== "") ||
        (this.state.bp_s !== "") || (this.state.bp_d !== "") ||
        (this.state.temp !== "") || (this.state.spo2 !== "") || (this.state.waist_circumference !== '') ||
        (this.state.bloodglucouse !== "") || (this.state.pulserate !== "") || (this.state.headCircumference !== "")
      ) {
        this.setState({ postLoading: true })
        RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_VITALS)
          .then((response) => {
            if (response?.data?.status === 'success') {
              this.setState({ postLoading: false })
              this.successMessage(response.data.message)
              this.getDoctorNotesVitalData()
              this.onClearHandler()
            }
          }).catch((error) => {
            if (error?.response?.data?.message) {
              this.setState({ postLoading: false })
              this.errorMessage(error?.response?.data?.message)
            } else {
              this.setState({ postLoading: false })
              this.errorMessage(error.message)
            }
          })
      } else {
        this.errorMessage('Enter atleast one data')
      }
    } catch (error) {
      this.setState({ postLoading: false })
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  onClearHandler = () => {
    try {
      this.setState({
        height: '',
        weight: '',
        bp_d: '',
        bp_s: '',
        temp: '',
        spo2: '',
        pulserate: '',
        bloodglucouse: '',
        tempUnit: 'F',
        waist_circumference:''
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onChangeHandler = (e, names) => {
    try {
      let value = e.target.value
      let num = CommonValidation.DecimalNumber(value)
      switch (names) {
        case "height":
          if ((num && value >= 1 && value <= 230) || value === "") {
            this.setState({ height: value }, () => {
              let bmi = calculateBMI(this.state.weight, this.state.height)
              this.setState({
                bmi: bmi?.toFixed(2)
              })
            })
          }
          break;
        case "weight":
          if ((num && value >= 1 && value <= 500) || value === "") {
            this.setState({ weight: value }, () => {
              let bmi = calculateBMI(this.state.weight, this.state.height)
              this.setState({
                bmi: bmi?.toFixed(2)
              })
            })
          }
          break;
        case "bp_d":
          if ((num && value >= 1 && value <= 200) || value === "") {
            this.setState({ bp_d: value })
          }
          break;
        case "bp_s":
          if ((num && value >= 1 && value <= 250) || value === "") {
            this.setState({ bp_s: value })
          }
          break;
        case "temp":
          if ((this.state.tempUnit === "F" && num && value >= 1 && value <= 248) || value === "") {
            this.setState({ temp: value })
          } else if ((this.state.tempUnit === "C" && num && value >= 1 && value <= 200) || value === "") {
            this.setState({ temp: value })
          }
          break;
        case "spo2":
          if ((num && value >= 1 && value <= 100) || value === "") {
            this.setState({ spo2: value })
          }
          break;
        case "pulserate":
          if ((num && value >= 1 && value <= 150) || value === "") {
            this.setState({ pulserate: value })
          }
          break;
        case "blood_glucose":
          if ((num && value >= 1 && value <= 500) || value === "") {
            this.setState({ bloodglucouse: value })
          }
          break;
          case "headCircumference":
          if ((num && value >= 1 && value <= 56) || value === "") {
            this.setState({ headCircumference: value })
          }
          break;
          case "waist_circumference":
          if ((num && value >= 1 && value <= 999) || value === "") {
            this.setState({ waist_circumference: value })
          }
          break;
        default:
          this.setState({
            [names]: value
          })
          break;
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderTextBox = (names, value, width, endAdornment, placeholder, color) => {
    try {
      return (
        <TextField
          size='small'
          sx={{ width: width }}
          name={names}
          autoComplete='off'
          value={value}
          placeholder={placeholder}
          inputProps={{ style: { color: color, fontWeight: '600' } }}
          onChange={(e) => this.onChangeHandler(e, names)}
          InputProps={{
            endAdornment: names === 'temp' ?
              <FormControl size='small'>
                <Select
                  sx={{ width: '3vw' }}
                  variant='standard'
                  value={this.state.tempUnit}
                  readOnly={this.state.postLoading}
                  onChange={(e) => this.setState({ tempUnit: e.target.value })}
                >
                  <MenuItem value={'F'}>{'°F'}</MenuItem>
                  <MenuItem value={'C'}>{'°C'}</MenuItem>
                </Select>
              </FormControl>
              :
              <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{endAdornment}</Typography>
            , readOnly: this.state.postLoading
          }}
        />
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  bmiHelperText = (bmi) => {
    try {
      let message = null
      if (bmi <= 18.5) {
        message = 'Mild Thinness'
      } else if (bmi > 18.5 && bmi <= 25) {
        message = 'Normal'
      } else if (bmi > 25 && bmi <= 30) {
        message = 'Overweight'
      } else if (bmi > 30) {
        message = 'Obesity'
      }
      return message;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  render() {
    let bpsColor = this.state.bp_s <= 120 ? "green" : "red"
    let bpdColor = this.state.bp_d <= 80 ? "green" : "red"
    let tempColor = ((this.state.tempUnit === 'F' && (this.state.temp >= 97 && this.state.temp <= 99)) || (this.state.tempUnit === 'C' && (this.state.temp >= 36.1 && this.state.temp <= 37.2))) ? 'green' : 'red'
    let spo2Color = (this.state.spo2 >= 95 && this.state.spo2 <= 100) ? "green" : "red"
    let rateColor = (this.state.pulserate >= 60 && this.state.pulserate) ? 'green' : 'red'
    let bloodColor = this.state.bloodglucouse <= 200 ? 'green' : 'red'
    return (
      <ShowComponents onIntersection={this.getDoctorNotesVitalData.bind(this)}>
        <Box component={'div'}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ backgroundColor: Colors.Background, height: "3.5vw" }}
            >
              <Box component={'div'} height={'3vw'} display={'flex'} p={'0.5vw'} justifyContent={'space-between'} alignItems={'center'} width={"100%"} >
                <Box component={'div'}>
                  <Typography fontWeight={600}>Vitals</Typography>
                  {/* <Typography fontSize={'0.8vw'} color={'gray'}>Vitals Entered By
                    <Typography component={'span'} fontWeight={600} fontSize={'0.8vw'} color={Colors.SecondaryText}> Hospital Nurse</Typography>
                  </Typography> */}
                </Box>
                <Box component={'div'}>
                  {
                    this.state.postLoading ?
                      <Button
                        className='Common_Btn_Min_Width'
                        onClick={() => this.setState({ postLoading: false, dataDisable: false })}
                        disabled={this.state.isLocked || this.props.isLocked}
                      >
                        {this.state.isLocked || this.props.isLocked ?
                          <Box component={'img'} src={ImagePaths.DisabledEdit.default} height={'1.2vw'} width={'1.2vw'} />
                          :
                          <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.2vw'} width={'1.2vw'} />
                        }
                      </Button> :
                      <Button
                        variant='contained'
                        sx={{ height: '2vw', width: '5vw', textTransform: "capitalize" }}
                        onClick={() => this.postDoctorNotesVitalData()}
                        disabled={this.state.isLocked || this.props.isLocked}
                      >Add</Button>
                  }
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box component={'div'} sx={{ backgroundColor: Colors.white }} p={'1vw'}>
                <Box component={'div'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                  <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Height</Typography>
                  {this.renderTextBox('height', this.state.height, '12vw', 'cm')}
                </Box>
                <Box component={'div'} display={'flex'} mt={'0.5vw'} justifyContent={'space-between'} alignItems={'center'}>
                  <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Weight</Typography>
                  {this.renderTextBox('weight', this.state.weight, '12vw', 'kg')}
                </Box>

                {(this.state.height && this.state.weight) ?
                  <Box component={'div'}
                    sx={{ backgroundColor: this.state.bmi < 18.5 ? '#FFCCCB' : ((this.state.bmi >= 18.5) && (this.state.bmi <= 25)) ? 'lightgreen' : ((this.state.bmi > 25) && (this.state.bmi <= 30)) ? '#FBB917' : '#FF6863' }}
                    display={'flex'} borderRadius={'5px'} mt={'0.5vw'} p={'0vw 0.5vw'} justifyContent={'space-between'} alignItems={'center'} height={'2.5vw'}>
                    <Typography fontWeight={600} fontSize={'0.9vw'}>BMI</Typography>
                    <FormHelperText>
                      <Typography fontWeight={600} fontSize={'0.8vw'}>{this.bmiHelperText(this.state.bmi)}</Typography>
                    </FormHelperText>
                    <Typography fontWeight={600} fontSize={'0.9vw'}>{this.state.bmi}</Typography>
                  </Box> : null}

                <Box component={'div'} display={'flex'} mt={'0.5vw'} justifyContent={'space-between'} alignItems={'center'}>
                  <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>{'BP | S > D'}</Typography>
                  {/* {this.renderTextBox('bp_s', this.state.bp_s, '2vw', 'mm/hg')} */}
                  <Box component={'div'}>
                    <TextField
                      size='small'
                      sx={{ width: '4.5vw' }}
                      value={this.state.bp_s}
                      placeholder='S'
                      onChange={(e) => this.onChangeHandler(e, 'bp_s')}
                      inputProps={{ style: { color: bpsColor, fontWeight: '600' } }}
                    />
                    {this.renderTextBox('bp_d', this.state.bp_d, '7.5vw', 'mm/Hg', 'D', bpdColor)}
                  </Box>
                </Box>
                {/* Commented for design change */}
                {/* <Box component={'div'} display={'flex'} mt={'0.5vw'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>BP_S</Typography>
            
          </Box> */}
                <Box component={'div'} display={'flex'} mt={'0.5vw'} justifyContent={'space-between'} alignItems={'center'}>
                  <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Temp</Typography>
                  {this.renderTextBox('temp', this.state.temp, '12vw', '', '', tempColor)}
                </Box>
                <Box component={'div'} display={'flex'} mt={'0.5vw'} justifyContent={'space-between'} alignItems={'center'}>
                  <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>SpO2</Typography>
                  {this.renderTextBox('spo2', this.state.spo2, '12vw', '%', '', spo2Color)}
                </Box>
                <Box component={'div'} display={'flex'} mt={'0.5vw'} justifyContent={'space-between'} alignItems={'center'}>
                  <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Pulse Rate</Typography>
                  {this.renderTextBox('pulserate', this.state.pulserate, '12vw', '/min', '', rateColor)}
                </Box>
                <Box component={'div'} display={'flex'} mt={'0.5vw'} justifyContent={'space-between'} alignItems={'center'}>
                  <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Blood Glucose</Typography>
                  {this.renderTextBox('blood_glucose', this.state.bloodglucouse, '12vw', 'mg/DL', '', bloodColor)}
                </Box>
                {this.props.isPD ? <Box component={'div'} display={'flex'} mt={'0.5vw'} justifyContent={'space-between'} alignItems={'center'}>
                  <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Head Circumference</Typography>
                  {this.renderTextBox('headCircumference', this.state.headCircumference, '12vw', 'CM')}
                </Box> : null}
                {this.state.show_waist_circumference ? <Box component={'div'} display={'flex'} mt={'0.5vw'} justifyContent={'space-between'} alignItems={'center'}>
                  <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Waist Circumference</Typography>
                  {this.renderTextBox('waist_circumference', this.state.waist_circumference, '12vw', 'CM')}
                </Box> : null}
              </Box>
            </AccordionDetails>
          </Accordion>
          {this.state.isErrorMsg ?
            <ToastMsg
              severity={'error'}
              msg={this.state.isErrorMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.successMsg ?
            <ToastMsg
              severity={'success'}
              msg={this.state.successMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
        </Box>
      </ShowComponents>
    )
  }
}
