import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import {
    Accordion, AccordionSummary, IconButton, Typography, Autocomplete,
    TextField, Box, InputAdornment, AccordionDetails, Table, TableRow,
    TableCell, TableHead, TableBody, Tooltip, Button, Modal, Paper, Select, MenuItem,
    OutlinedInput,
    FormControl,
    InputLabel,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Serviceurls } from "../../../../Utility/API/Serviceurls";
import RestAPIService from "../../../../Utility/Services/RestAPIService";
import { CurrencySymbol } from "../../../../Utility/Constants";
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';
import { ImagePaths } from "../../../../Utility/ImagePaths";
import { DateTime } from 'luxon';
import { AmountFormat } from '../../../../Components/CommonFunctions/CommonFunctions';
import { Colors } from '../../../../Styles/Colors';
import moment from 'moment';
import PercentIcon from '@mui/icons-material/Percent';
import { AddCircle } from '@material-ui/icons';
import { DeletePopup } from "../../../../Components/Common Components/CommonComponents";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { localGetItem } from "../../../../Utility/Services/CacheProviderService";
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';


const Unit_Types = [
    { label: `${CurrencySymbol}`, value: 'rupee' },
    { label: '%', value: 'percentage' }
]

const lineItem = {
    display_name: "",
    amount_type: "rupee",
    discount_percentage: "",
    is_doctor_wise: false,
    service_amount: 0,
    service_charges: {},
    service_discount_edit: false,
    service_id: null,
    service_name: "",
    service_rate_edit: false,
    service_rate_per_unit: 0,
    service_total_amount: 0,
    service_type: "",
    service_unit: 0,
    service_unit_edit: false,
    total_price: 0,
    discountAmount: 0,
    totalAmount: 0,
}

class MultiIPBilling extends Component {
    constructor(props) {
        super(props);
        this.testNameRef = React.createRef();
        this.state = {
            openAccordion: false,
            editRate: false,
            editUnit: false,
            radDetails: {},
            overAllDiscountAmt: '',
            overAllDiscountPercent: '',
            totalBillAmt: 0,
            docSplitPopup: false,
            radList: [],
            serviceData: [],
            ipBillingList: [lineItem],
            errorMessages: [""],
            payableAmount: 0,
            totalServiceAmount: 0,
            discountReason: "",
            discountReasonPopup: false,
            is_gst_enabled: JSON.parse(localGetItem("is_gst_enabled")),
            PatientDetails: this.props.PatientDetails,
            Assorted_Id: null,
            totalDiscountedPercentage_w_r: '',
            discountPercentDoctorFee_w_r: '',
            discountPercentHospitalFee_w_r: '',
            docPercent_w_r: '',
            hospitalPercent_w_r: '',
            concessionRestrictAmt: 0
        }
    }

    componentDidMount = () => {
        this.getServiceListData();
        this.getPackageData();
        this.getLayoutData();
        let concession = localGetItem('billing_concession_type')
        let showCode = JSON.parse(localGetItem('op_ip_service_code')) ? JSON.parse(localGetItem('op_ip_service_code')) : false
        this.setState({
            billConcessionType: concession,
            showCode: showCode
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState !== this.state) {
            this.props.onStateChange(this.state);
        }
        if (prevProps.triggerSignal !== this.props.triggerSignal) {
            this.setState({
                openAccordion: false
            }, () => {
                this.onClearHandler();
            })
        }
    }

    getServiceListData = () => {
        const { errorMessage } = this.props;
        try {
            let startDate = new Date()
            let Aptime = DateTime.fromJSDate(startDate).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let invoice_date = `${DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd")}`
            RestAPIService.getAll(Serviceurls.FO_CONFIG_IPSRCNAME + '?billing_time=' + Aptime + '&billing_date=' + invoice_date)
                .then((response) => {
                    if (response.data.status === 'success') {
                        const list = response.data.data || [];
                        const dataList = list?.reduce((acc, cur) => {
                            const servicesWithDisplayName = cur?.data?.map(service => ({
                                ...service,
                                display_name: `${service?.service_type ? service?.service_type : '-'} - ${service?.service_name ? service?.service_name : '-'} - ${service?.op_service_code ? service?.op_service_code : '-'} `
                            }));
                            return [...acc, ...servicesWithDisplayName];
                        }, []);
                        this.setState({
                            serviceData: dataList,
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        errorMessage(error.response.data.message)
                    } else {
                        errorMessage(error.message)
                    }
                })
        } catch (error) {
            errorMessage(error.message)
        }
    }

    getPackageData = () => {
        const { errorMessage } = this.props;
        try {
            let startDate = new Date()
            let Aptime = DateTime.fromJSDate(startDate).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let invoice_date = `${DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd")}`
            RestAPIService.getAll(Serviceurls.FO_CONFIG_IPSRCPACKAGE + '?billing_time=' + Aptime + '&billing_date=' + invoice_date)
                .then((response) => {
                    if (response.data.status === 'success') {
                        const list = response.data.data || [];
                        this.setState({
                            PackageDataList: list,
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        errorMessage(error.response.data.message)
                    } else {
                        errorMessage(error.message)
                    }
                })
        } catch (error) {
            errorMessage(error.message)
        }
    }

    getLayoutData = () => {
        const { errorMessage } = this.props;
        try {
            RestAPIService.getAll(Serviceurls.BILL_LAYOUT_CONFIG + "?module=IP")
                .then((response) => {
                    if (response.data.status === 'success') {
                        let list = response.data.data[0] || [];
                        let showAssort = list?.layout_detail.find((item) => item?.value === "assorted pack")?.is_active ? true : false
                        if (showAssort) {
                            this.setState({
                                showAssort: true
                            }, () => this.getAssortedPackageData())
                        }
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        errorMessage(error.response.data.message)
                    } else {
                        errorMessage(error.message)
                    }
                })
        } catch (error) {
            errorMessage(error.message)
        }
    }

    getAssortedPackageData = () => {
        const { errorMessage } = this.props;
        try {
            RestAPIService.getAll(Serviceurls.ASS_GET_ASSORTED_PACKAGE + `?billing=${true}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        const list = response.data.data || [];
                        this.setState({
                            AssortedPackageList: list,
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        errorMessage(error.response.data.message)
                    } else {
                        errorMessage(error.message)
                    }
                })
        } catch (error) {
            errorMessage(error.message)
        }
    }

    renderAutoComplete = (label, statekey, list, showValue) => {
        const { t } = this.props;
        return (
            <Autocomplete
                disableClearable
                openOnFocus
                value={this.state[statekey] ? this.state[statekey] : null}
                sx={{ width: "16dvw" }}
                size="small"
                options={list?.length > 0 ? list : []}
                getOptionLabel={option => typeof (option) === "string" ? option : option[showValue]}
                onChange={(event, newValue) => {
                    this.setState({
                        [statekey]: newValue,
                    })
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t(label)}
                        autoComplete="off"
                        size="small"
                        InputProps={{
                            ...params.InputProps,
                            emed_tid: `multiradBilling_${label}`
                        }}
                    />
                )}
            />
        )
    }

    onChangeTableHandler = (e, key) => {
        const { t } = this.props
        try {
            const { name, value } = e.target
            let { ipBillingList } = this.state
            switch (name) {
                case 'rateUnit':
                    if ((CommonValidation.NumberWithDot(value) && value >= 1) || value === "") {
                        if (((ipBillingList[key]?.service_category__service_category === "Doctor Consultation Charges") || (ipBillingList[key]?.service_category__service_category === "Surgery Charges") || (ipBillingList[key]?.service_category__service_category === "Anasthesia Charges"))) {
                            ipBillingList[key]['totalAmount'] = ipBillingList[key]["time_taken"] ? this.CalculateHourAmount(value, ipBillingList[key]['time_taken'], (ipBillingList[key]["old_time_taken"] ? ipBillingList[key]["old_time_taken"] : ipBillingList[key]['time_taken']), ipBillingList[key]['minimum_time'], ipBillingList[key]['min_amount']) : (ipBillingList[key]['unit'] * value)
                            ipBillingList[key]['amount'] = +value
                            ipBillingList[key]['ip_service']['doctor_fee'] = CommonValidation.calculatePercentage(ipBillingList[key]['ip_service']['doctor_percentage'], (value * ipBillingList[key]['unit']))
                            ipBillingList[key]['ip_service']['hospital_fee'] = CommonValidation.calculatePercentage(ipBillingList[key]['ip_service']['hospital_percentage'], (value * ipBillingList[key]['unit']))
                            ipBillingList[key]['ip_service']['total_amount'] = ipBillingList[key]['unit'] * +value
                            ipBillingList[key]['ip_service']['discount_hospital_fee'] = ipBillingList[key]['ip_service']['discount_hospital_percentage'] ? CommonValidation.calculatePercentage(ipBillingList[key]['ip_service']['discount_hospital_percentage'], ipBillingList[key]['ip_service']['hospital_fee']) : 0;
                            ipBillingList[key]['ip_service']['discount_doctor_fee'] = ipBillingList[key]['ip_service']['discount_doctor_percentage'] ? CommonValidation.calculatePercentage(ipBillingList[key]['ip_service']['discount_doctor_percentage'], ipBillingList[key]['ip_service']['doctor_fee']) : 0;
                            ipBillingList[key]['discountAmount'] = ipBillingList[key]['ip_service']['discount_doctor_fee'] + ipBillingList[key]['ip_service']['discount_hospital_fee']
                            ipBillingList[key]['discountPercentage'] = CommonValidation.amountPercentageWithoutRoundoff(ipBillingList[key]['discountAmount'], ipBillingList[key]['ip_service']['total_amount'])
                            ipBillingList[key]['discountPercentage_w_r'] = CommonValidation.amountPercentage(ipBillingList[key]['discountAmount'], ipBillingList[key]['ip_service']['total_amount'])

                        } else {
                            ipBillingList[key]['totalAmount'] = ipBillingList[key]["time_taken"] ? this.CalculateHourAmount(value, ipBillingList[key]['time_taken'], (ipBillingList[key]["old_time_taken"] ? ipBillingList[key]["old_time_taken"] : ipBillingList[key]['time_taken']), ipBillingList[key]['minimum_time'], ipBillingList[key]['min_amount']) : (ipBillingList[key]['unit'] * value)
                            ipBillingList[key]['discountAmount'] = CommonValidation.calculatePercentage(ipBillingList[key]['totalAmount'], ipBillingList[key]['discountPercentage'])
                            ipBillingList[key]['discountPercentage'] = CommonValidation.amountPercentageWithoutRoundoff(ipBillingList[key]['discountAmount'], ipBillingList[key]['totalAmount'])
                            ipBillingList[key]['discountPercentage_w_r'] = CommonValidation.amountPercentage(ipBillingList[key]['discountAmount'], ipBillingList[key]['totalAmount'])
                            ipBillingList[key]['amount'] = value
                        }
                        this.setState({ ipBillingList }, () => {
                            // this.calculateTotalDiscount()
                            this.setState({
                                totalDiscountedAmount: '',
                                totalDiscountedPercentage: '',
                                totalDiscountedPercentage_w_r: '',
                            })
                        })
                    }
                    break;
                case 'percentage':
                    if ((CommonValidation.DecimalNumber(value) && value >= 1 && +value <= 100) || value === "") {
                        ipBillingList[key]['discountAmount'] = CommonValidation.calculatePercentage(value, ipBillingList[key]['totalAmount'])
                        ipBillingList[key]['discountPercentage'] = value
                        this.setState({ ipBillingList }, () => {
                            // this.calculateTotalDiscount()
                            this.setState({
                                totalDiscountedAmount: '',
                                totalDiscountedPercentage: '',
                                totalDiscountedPercentage_w_r: '',
                            })
                        })
                    }

                    break;
                case 'discountAmount':
                    let totalAmount = ipBillingList[key]['totalAmount']
                    if (CommonValidation.NumberWithDot(value) && value >= 1 && value <= totalAmount || value === "") {
                        ipBillingList[key]['discountPercentage'] = CommonValidation.amountPercentageWithoutRoundoff(value, ipBillingList[key]['totalAmount'])
                        ipBillingList[key]['discountPercentage_w_r'] = CommonValidation.amountPercentage(value, ipBillingList[key]['totalAmount'])
                        ipBillingList[key]['discountAmount'] = value
                        this.setState({ ipBillingList }, () => {
                            // this.calculateTotalDiscount()
                            this.setState({
                                totalDiscountedAmount: '',
                                totalDiscountedPercentage: '',
                                totalDiscountedPercentage_w_r: '',
                            })
                        })
                    }
                    break;
                case 'totalDiscountedPercentage':
                    let percentRestrict = +this.state.billConcessionType === 1 ? 100 : CommonValidation.amountPercentage(this.state.concessionRestrictAmt, this.state.totalServiceAmount)
                    if ((CommonValidation.DecimalNumber(value) && value > 0 && +value <= percentRestrict) || value === "") {
                        this.state.totalDiscountedAmount = CommonValidation.calculatePercentage(value, this.state.totalServiceAmount)
                        this.setState({
                            totalDiscountedPercentage: value,
                            totalDiscountedPercentage_w_r: value,
                        })
                    }
                    break;
                case 'totalDiscountedAmount':
                    if (CommonValidation.NumberWithDot(value) && value > 0 && value <= this.state.concessionRestrictAmt || value === "") {
                        this.state.totalDiscountedPercentage = CommonValidation.amountPercentageWithoutRoundoff(value, this.state.totalServiceAmount)
                        this.state.totalDiscountedPercentage_w_r = CommonValidation.amountPercentage(value, this.state.totalServiceAmount)
                        this.setState({
                            totalDiscountedAmount: value
                        })
                    }
                    break;
                case "serviceSearch":
                    this.setState({
                        serviceSearch: value
                    }, () => {
                        if (this.state.serviceSearch === "") {
                            // this.getServiceTemplateData()
                            this.setState({
                                service: "",
                                serviceTemplateData: JSON.parse(this.state.serviceTemplateDataNew)
                            })
                        } else {
                            // this.getServiceTemplateData()
                            this.setState({
                                service: "",
                                serviceTemplateData: JSON.parse(this.state.serviceTemplateDataNew)?.filter((list) => (list?.package_name?.toLowerCase()?.includes(this.state.serviceSearch?.toLowerCase())))
                            })
                        }
                    })
                    break;
                case "descriptionSerach":
                    this.setState({
                        descriptionSerach: value
                    }, () => {
                        // if (this.state.descriptionSerach === "") {
                        //   // this.getServiceDescriptionData()
                        //   this.setState({
                        //     newDescriptionName: "",
                        //     description: "",
                        //     newDescription: [],
                        //     dupNewDescription: '[]',
                        //     searchServiceName: '',
                        //   })
                        // } else {
                        //   // this.getServiceDescriptionData()
                        // }
                        if (this.state.descriptionSerach === "") {
                            // this.getServiceTemplateData()
                            this.setState({
                                newDescriptionName: "",
                                description: "",
                                newDescription: [],
                                dupNewDescription: '[]',
                                searchServiceName: '',
                                serviceDescriptionData: JSON.parse(this.state.serviceDescriptionDataNew)
                            })
                        } else {
                            // this.getServiceTemplateData()
                            this.setState({
                                newDescriptionName: "",
                                description: "",
                                newDescription: [],
                                dupNewDescription: '[]',
                                searchServiceName: '',
                                serviceDescriptionData: JSON.parse(this.state.serviceDescriptionDataNew)?.filter((list) => (list?.service_type?.toLowerCase()?.includes(this.state.descriptionSerach?.toLowerCase())))
                            })
                        }
                    })
                    break;
                default:
                    this.setState({
                        [name]: value
                    })
                    break;
            }
        } catch (error) {
            this.props.errorMessage(error.message, 'error')
        }
    }

    discountReasonPopup = () => {
        try {
            return (
                <Modal open={this.state.discountReasonPopup}>
                    <Box className='eMed_Discount_Reason'>
                        <Box component={'div'} height={'2vw'} display={'flex'} m={'1vw'} justifyContent={'space-between'}>
                            <Typography color={Colors.SecondaryText} fontWeight={600}>IP Bill Concession Reason</Typography>
                        </Box>
                        <Box m={'1vw'}>
                            <TextField
                                fullWidth
                                multiline
                                label='Reason *'
                                rows={3}
                                value={this.state.discountReason}
                                onChange={(e) => {
                                    this.setState({ discountReason: e.target.value })
                                }}
                            />
                        </Box>
                        <Box m={'1vw'} display={'flex'} justifyContent={'flex-end'}>
                            <Button
                                variant='outlined'
                                sx={{ width: '5vw', height: '2vw', mr: '1vw' }}
                                onClick={() => {
                                    this.setState({ discountReasonPopup: false, discountReason: '' })
                                }}
                            >Close</Button>
                            <Button
                                variant='contained'
                                sx={{ width: '5vw', height: '2vw' }}
                                onClick={() => {
                                    if (this.state.discountReason !== '') {
                                        this.setState({ discountReasonPopup: false })
                                    } else {
                                        this.props.errorMessage("Reason is mandatory", 'error')
                                    }
                                }}
                            >Save</Button>
                        </Box>
                    </Box>
                </Modal>
            )
        } catch (error) {
            this.props.errorMessage(error.message, 'error')
        }
    }

    concessionManditoryPopup = (names) => {
        if ((this.state.totalDiscountedPercentage || this.state.totalDiscountedAmount) && (this.state.discountReason === "")) {
            if (names === 'totalDiscountedPercentage' || names === 'totalDiscountedAmount') {
                this.setState({ discountReasonPopup: true })
            }
        }
    }

    inputTextBox = (names, value, key, placeholder, height, diasble) => {
        let search = names === "serviceSearch" || names === "descriptionSerach" || names === 'serviceCodeSearch'
        return (
            <TextField
                autoComplete='off'
                size='small'
                className={search ? 'eMed_bill_search' : 'eMed_discount_txt'}
                placeholder={placeholder}
                disabled={(this.state.corporatePay || this.state.forCancel) ? true : diasble}
                inputProps={{ style: { height: height, fontSize: '0.8vw' } }}
                name={names}
                value={value}
                onChange={(e) => { this.onChangeTableHandler(e, key) }}
            />
        )
    }

    textBox = (names, value, width, height = "", placeholder, index) => {
        try {
            return (
                <TextField
                    autoComplete='off'
                    InputProps={{
                        style: height ? { height: height } : {},
                        endAdornment: <InputAdornment position="end">{placeholder}</InputAdornment>
                    }}
                    size='small'
                    sx={{ width: width }}
                    name={names}
                    value={value}
                    onChange={(e) => this.onChangeTableHandler(e, names, index)}
                    inputProps={{ emed_tid: names }}
                    onBlur={(e) => { this.concessionManditoryPopup(names) }}
                    disabled={((names === "totalDiscountedAmount" || names === "totalDiscountedPercentage") && this.state.totalServiceAmount === 0)}
                />
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    resetBillSummary = () => {
        this.setState({
            billSummaryList: [lineItem],
            errorMessages: [""],
            payableAmount: 0,
            netAmount: 0,
            roundOff: 0,
            discountAmount: 0,
            discountPercentage: 0,
            netAmount: 0,
        })
    }

    addShare = (item, index) => {
        this.setState({
            docAmt: (item?.ip_service?.original_doctor_fee ? item.ip_service?.original_doctor_fee : item.ip_service?.doctor_fee ? item.ip_service?.doctor_fee : ''),
            docPercent: item?.ip_service?.doctor_percentage ? item?.ip_service?.doctor_percentage : '',
            docPercent_w_r: item?.ip_service?.doctor_percentage ? CommonValidation.formatAmount(item?.ip_service?.doctor_percentage) : '',
            hospitalAmt: item?.ip_service?.original_hospital_fee ? item.ip_service?.original_hospital_fee : item.ip_service?.hospital_fee ? item.ip_service?.hospital_fee : '',
            hospitalPercent: item?.ip_service?.hospital_percentage ? item?.ip_service?.hospital_percentage : '',
            hospitalPercent_w_r: item?.ip_service?.hospital_percentage ? CommonValidation.formatAmount(item?.ip_service?.hospital_percentage) : '',
            // totalBillAmt: item?.amount ? item?.amount : '',
            totalBillPercent: '100',
            rowIndex: index,
            selectedData: item,
            dupliSelectedData: JSON.stringify(item),
        }, () => {
            this.setState({ docSplitPopup: true, totalBillAmt: +this.state.docAmt + +this.state.hospitalAmt })
        })
    }

    amtCalculate = (e, name) => {
        let states = JSON.parse(JSON.stringify(this.state))
        let value = e.target.value
        let serviceFee = JSON.parse(JSON.stringify(states.selectedData?.ip_service))
        switch (name) {
            case 'doctorAmt':
                if (CommonValidation.RangeDecimalNumber(value) || value == '') {
                    serviceFee.total_amt = (+value) + (+states.hospitalAmt)
                    states.selectedData.amount = serviceFee.total_amt
                    states.selectedData.totalAmount = states.selectedData?.amount * (+states.selectedData?.unit)
                    this.setState({
                        docAmt: +value,
                        selectedData: states.selectedData
                    }, () => {
                        let totalAmt = (+value) + (+states.hospitalAmt)
                        this.setState({
                            totalBillAmt: (+value) + (+states.hospitalAmt)
                        }, () => {
                            this.setState({
                                docPercent: totalAmt ? +((+value / totalAmt) * 100) : 0,
                                docPercent_w_r: totalAmt ? +((+value / totalAmt) * 100).toFixed(2) : 0,
                                hospitalPercent: totalAmt ? +((+states.hospitalAmt / totalAmt) * 100) : 0,
                                hospitalPercent_w_r: totalAmt ? +((+states.hospitalAmt / totalAmt) * 100).toFixed(2) : 0,
                            })
                        })
                    })
                }
                break;
            case 'hospitalAmt':
                if (CommonValidation.RangeDecimalNumber(value) || value == '') {
                    if (serviceFee) {
                        serviceFee.total_amt = (+states.docAmt) + (+value)
                        states.selectedData.amount = serviceFee.total_amt
                        states.selectedData.totalAmount = states.selectedData?.amount * (+states.selectedData?.unit)
                    } else {
                        // states.selectedData.amount = (+states.docAmt) + (+value)
                        // states.selectedData.totalAmount = states.selectedData?.amount * (+states.selectedData?.unit)
                        // states.selectedData.total_amount = states.selectedData?.amount * (+states.selectedData?.unit)
                    }
                    this.setState({
                        hospitalAmt: +value,
                        selectedData: states.selectedData
                    }, () => {
                        let totalAmt = (+states.docAmt) + (+value)
                        this.setState({
                            totalBillAmt: (+states.docAmt) + (+value)
                        }, () => {
                            this.setState({
                                docPercent: totalAmt ? +((+states.docAmt / totalAmt) * 100) : 0,
                                docPercent_w_r: totalAmt ? +((+states.docAmt / totalAmt) * 100).toFixed(2) : 0,
                                hospitalPercent: totalAmt ? +((+value / totalAmt) * 100) : 0,
                                hospitalPercent_w_r: totalAmt ? +((+value / totalAmt) * 100).toFixed(2) : 0,
                            })
                        })
                    })
                }
                break;
            default:
                break;
        }
    }

    newShareSplitUp = () => {
        try {
            let { ipBillingList, selectedData } = this.state
            ipBillingList[this.state.rowIndex].amount = this.state.totalBillAmt ? +(+this.state.totalBillAmt / ipBillingList[this.state.rowIndex].unit).toFixed(2) : 0
            ipBillingList[this.state.rowIndex].ip_service.doctor_percentage = this.state.docPercent ? +this.state.docPercent : 0
            ipBillingList[this.state.rowIndex].ip_service.doctor_percentage_w_r = this.state.docPercent_w_r ? +this.state.docPercent_w_r : 0
            ipBillingList[this.state.rowIndex].ip_service.hospital_percentage = this.state.hospitalPercent ? +this.state.hospitalPercent : 0
            ipBillingList[this.state.rowIndex].ip_service.hospital_percentage_w_r = this.state.hospitalPercent_w_r ? +this.state.hospitalPercent_w_r : 0
            ipBillingList[this.state.rowIndex].ip_service.original_doctor_fee = this.state.docAmt ? +this.state.docAmt : 0
            ipBillingList[this.state.rowIndex].ip_service.original_hospital_fee = this.state.hospitalAmt ? +this.state.hospitalAmt : 0
            ipBillingList[this.state.rowIndex].ip_service.discount_hospital_percentage = this.state.hospitalAmt ? ipBillingList[this.state.rowIndex]?.ip_service.discount_hospital_percentage : 0
            ipBillingList[this.state.rowIndex].ip_service.discount_hospital_percentage_w_r = this.state.hospitalAmt ? CommonValidation.formatAmount(ipBillingList[this.state.rowIndex]?.ip_service.discount_hospital_percentage) : 0
            ipBillingList[this.state.rowIndex].ip_service.discount_doctor_percentage = this.state.docAmt ? ipBillingList[this.state.rowIndex]?.ip_service.discount_doctor_percentage : 0
            ipBillingList[this.state.rowIndex].ip_service.discount_doctor_percentage_w_r = this.state.docAmt ? CommonValidation.formatAmount(ipBillingList[this.state.rowIndex]?.ip_service.discount_doctor_percentage) : 0
            this.setState({ ipBillingList }, () => {
                this.setState({ docSplitPopup: false }, () => { this.CalculateServiceValues(this.state.rowIndex) })
            })
        } catch (error) {
            this.props.errorMessage(error.message, 'error')
        }
    }

    splitScreenPopup = () => {
        return (
            <Modal open={this.state.docSplitPopup}>
                <Paper elevation={3} id="emedhub_popup_mainBox" sx={{ width: "31vw", height: "60vh" }}>
                    <div className="eMed_DialogBox_Title" style={{ padding: '0.5vw' }}>
                        <Typography sx={{ fontSize: '1vw', fontWeight: "bold" }}>
                            Split-Up
                        </Typography>
                        <div className="eMed_DialogBox_Close">
                            <img className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt='Close' onClick={this.closeSplitPopup} />
                        </div>
                    </div>
                    <hr />
                    <div className="eMed_DialogBox_Title" style={{ padding: '0.5vw' }}>
                        <Typography sx={{ fontSize: '1vw', fontWeight: "bold" }}>Doctor Consultation</Typography>
                        {/* <div style={{display:'flex'}}>
                                    <Typography sx={{ fontSize: '1vw' }}>Select Type</Typography>
                                    <Select 
                                        size='small'
                                        sx={{ width: '5vw', height: '2vw', ml: '1vw' }}
                                        
                                    ><MenuItem>%</MenuItem></Select>
                                    </div> */}
                    </div>
                    <Box>
                        <Box component={'div'} display={'flex'} justifyContent={'space-evenly'} pl={'0.5vw'}>
                            <Box display={'flex'} justifyContent={'space-between'} pl={'1vw'}>
                                <Typography color={Colors.SecondaryText} fontWeight={600}> Rate / Unit</Typography>
                                <Typography fontWeight={'600'} pl={'1.5vw'}>{AmountFormat(this.state.totalBillAmt / this.state.selectedData?.unit)}</Typography>
                                <Typography pl={'0.5vw'} marginTop={"0.3vw"} fontSize={"0.7vw"} color={'grey'}>{`${(this.state.is_gst_enabled && this.state.selectedData?.gst_percentage) ? `+ ${this.state.selectedData?.gst_percentage} % GST` : ""}`}</Typography>
                            </Box>
                            <Box display={'flex'} justifyContent={'space-between'} pl={'1vw'}>
                                <Typography color={Colors.SecondaryText} fontWeight={600}>Unit</Typography>
                                <Typography fontWeight={'600'} pl={'1.5vw'}>{this.state.selectedData?.unit ? this.state.selectedData?.unit : "-"}</Typography>
                            </Box>
                        </Box>
                        <div className="eMed_DialogBox_Title" style={{ padding: '0.5vw' }}>
                            <Typography>Doctor Share</Typography>
                            <div style={{ display: 'flex', gap: '5%' }}>
                                <TextField sx={{ width: '10vw' }} label='Amount' size='small' value={this.state.docAmt} onChange={(e) => this.amtCalculate(e, 'doctorAmt')}></TextField>
                                <TextField disabled={true} sx={{ width: '10vw' }} size='small' label='Percentage (%)' value={this.state.docPercent_w_r}></TextField>
                            </div>
                        </div>
                        <div className="eMed_DialogBox_Title" style={{ padding: '0.5vw' }}>
                            <Typography>Hospital Share</Typography>
                            <div style={{ display: 'flex', gap: '5%' }}>
                                <TextField sx={{ width: '10vw' }} label='Amount' size='small' value={this.state.hospitalAmt} onChange={(e) => { this.amtCalculate(e, 'hospitalAmt') }}></TextField>
                                <TextField disabled={true} sx={{ width: '10vw' }} size='small' label='Percentage (%)' value={this.state.hospitalPercent_w_r}></TextField>
                            </div>
                        </div>
                        <div className="eMed_DialogBox_Title" style={{ padding: '0.5vw' }}>
                            <Typography>Total</Typography>
                            <div style={{ display: 'flex', gap: '5%' }}>
                                <TextField disabled={true} sx={{ width: '10vw' }} size='small' label='Amount' value={this.state.totalBillAmt}></TextField>
                                <TextField disabled={true} sx={{ width: '10vw' }} size='small' label='Percentage (%)' value={this.state.totalBillPercent}></TextField>
                            </div>
                        </div>
                    </Box>
                    <Box m={'1vw'} display={'flex'} justifyContent={'flex-end'}>
                        <Button
                            variant='outlined'
                            sx={{ width: '5vw', height: '2vw', mr: '1vw' }}
                            onClick={this.closeSplitPopup}
                        >Close</Button>
                        <Button
                            variant='contained'
                            sx={{ width: '5vw', height: '2vw' }}
                            onClick={() => { this.newShareSplitUp() }}
                        >Save</Button>
                    </Box>
                </Paper>
            </Modal>
        )
    }

    closeSplitPopup = () => {
        this.setState({ docSplitPopup: false, selectedData: JSON.parse(this.state.dupliSelectedData) })
    }

    validCheck = (data) => {
        const { ipBillingList } = this.state;
        let duplicate = false;
        if (ipBillingList?.length > 0) {
            ipBillingList?.forEach((element) => {
                if (((element?.service_type === data?.service_type) && (element?.service_name === data?.service_name))) {
                    duplicate = true
                }
            })
        }
        return duplicate;
    }

    handleChangeSrv = (index, value, WardDetails = null) => {
        console.log(index, value, "from onchange")
        const { t } = this.props;
        if (value) {
            let TotalAmt = 0
            let MinAmt = 0
            let MinTime = 0
            let WardName = []
            let selectedWard = ""
            let selectedWardId = ""
            let states = this.state

            value?.consultant_charge.forEach(element => {
                if (value?.consultant_charge.length == 1) {
                    TotalAmt = element?.service_amount ? element?.service_amount : element.total_amount
                    MinAmt = value?.unit == "hrs" ? element?.minimum_amount ? element?.minimum_amount : null : null
                    MinTime = value?.unit == "hrs" ? element?.minimum_time ? element?.minimum_time : null : null
                } else {
                    if (states.PatientDetails?.ward_name === element.ward_name) {
                        TotalAmt = element?.service_amount ? element?.service_amount : element.total_amount
                        MinAmt = element?.min_service_amount ? element?.min_service_amount : null
                        MinTime = element?.minimum_time ? element?.minimum_time : null
                        selectedWard = element.ward_name
                        selectedWardId = element.id
                    }
                    var dataObject = {
                        id: element.id,
                        ward: element.ward_name,
                        amount: element?.service_amount ? element?.service_amount : element.total_amount,
                        minimum_amount: element?.min_service_amount ? element?.min_service_amount : null,
                        minimum_time: element?.minimum_time ? element?.minimum_time : null
                    };
                    WardName.push(dataObject)
                }
            })
            if (WardDetails) {
                WardDetails = WardName?.find((list) => (list?.id === WardDetails))
            }
            // if ((WardName.length > 1) || (WardName.length < 1)) {
            let object = JSON.parse(JSON.stringify(value))
            let Amount = WardDetails?.amount ? WardDetails?.amount : object?.amount ? object?.amount : (+TotalAmt) ? (+TotalAmt) : 0

            if (((object.service_category__service_category === "Doctor Consultation Charges") || (object.service_category__service_category === "Surgery Charges") || (object.service_category__service_category === "Anasthesia Charges"))) {
                object.totalAmount = Amount
                object.amount = Amount
            }
            else {
                object.min_amount = WardDetails?.minimum_amount ? WardDetails?.minimum_amount : MinAmt ? MinAmt : null
                object.minimum_time = WardDetails?.minimum_time ? WardDetails?.minimum_time : MinTime ? MinTime : '00:00'
                object.amount = Amount
                object.start_date = moment(this.state.PatientDetails?.admission_date)
                object.end_date = null
                object.changed_start_time = null
                object.changed_end_time = null
                object.old_time_taken = object.time_taken
                object.totalAmount = object.unit == "hrs" ? this.CalculateHourAmount(object.amount, object.time_taken, object.old_time_taken, object?.minimum_time, +object.min_amount) : Amount
                if (this.state?.newDescriptionName === "Consultation Other" && object.service_category__service_category === "Others") {
                    object.other_consultant_name = states?.consultDocName
                }
            }
            object.unit = "1";
            object.discountPercentage = ""
            object.discountAmount = ""
            object.noDiscountAmount = ""
            object.is_template = false
            object.is_auto_billing = false
            object.isSplitBlock = false
            object.ward_name = WardDetails?.ward ? WardDetails?.ward : selectedWard
            object.ward_id = WardDetails?.id ? WardDetails?.id : selectedWardId
            object.selected_Ward = WardDetails?.ward ? WardDetails?.ward : object.consultant_charge[0].ward_name
            if (object.fee_type === 'all') {
                object.ip_service = {
                    'selected_ward_name': object.consultant_charge[0].ward_name ? object.consultant_charge[0].ward_name : '',
                    'total_amount': object.consultant_charge[0].total_amount ? +object.consultant_charge[0].total_amount : object.consultant_charge[0].service_amount,
                    'amount': object.amount ? +object.amount : 0,
                    'doctor_fee': object.consultant_charge[0].doctor_fee ? object.consultant_charge[0].doctor_fee : '',
                    'hospital_fee': object.consultant_charge[0].hospital_fee ? object.consultant_charge[0].hospital_fee : '',
                    'doctor_percentage': object.consultant_charge[0].doctor_percent ? object.consultant_charge[0].doctor_percent : 0,
                    'hospital_percentage': object.consultant_charge[0].hospital_percent ? object.consultant_charge[0].hospital_percent : 0,
                    'unit': object.unit,
                    'service_category': object.service_category__service_category ? object.service_category__service_category : '',
                    'service_amount': object.consultant_charge[0].service_amount ? object.consultant_charge[0].service_amount : 0
                }
            } else {
                object?.consultant_charge?.forEach(element => {
                    if (element.ward_name === object.selected_Ward) {
                        object.ip_service = {
                            'selected_ward_name': element.ward_name ? element.ward_name : '',
                            'total_amount': element.total_amount ? element.total_amount : object.totalAmount,
                            'amount': object.amount ? object.amount : 0,
                            'doctor_fee': element.doctor_fee ? element.doctor_fee : '',
                            'hospital_fee': element.hospital_fee ? element.hospital_fee : '',
                            'doctor_percentage': element.doctor_percent ? element.doctor_percent : 0,
                            'hospital_percentage': element.hospital_percent ? element.hospital_percent : 0,
                            'unit': object.unit,
                            'service_category': object.service_category__service_category ? object.service_category__service_category : '',
                            'service_amount': element.service_amount ? element.service_amount : 0
                        }
                    }
                })
            }

            let flag = false;
            this.state.ipBillingList.forEach(element => {
                if (element.service_name === object.service_name && element.service_type === object.service_type && element?.amount === object?.amount && object?.ward_id === element?.ward_id ||
                    element.service_name === object.service_name && element.service_type === object.service_type && element?.totalAmount === object?.totalAmount && object?.ward_id === element?.ward_id) {
                    flag = true
                }
            })
            if (!flag) {
                let list = this.state.ipBillingList
                if (value?.consultant_charge?.length > 1 && WardDetails === null) {
                    list[index] = {
                        "display_name": value?.display_name,
                        "ward_list": WardName,
                        "is_ward_wise": true,
                        "value": value,
                        "discountAmount": 0,
                        "totalAmount": 0,
                    }
                    this.props?.infoMessage("Please Select the Ward !")
                } else {
                    if (WardDetails) {
                        object.display_name = value?.display_name
                        object.ward_list = WardName
                        object.is_ward_wise = true
                        object.value = value
                    }
                    list[index] = object
                }
                if (index === list?.length - 1) {
                    list = [...list, lineItem]
                }
                this.state.ipBillingList = list
                this.setState({
                    ipBillingList: this.state.ipBillingList,
                    // WardList: []
                }, () => {
                    // this.onClearHandler()
                    // this.calculateTotalDiscount()
                    this.setState({
                        totalDiscountedAmount: '',
                        totalDiscountedPercentage: '',
                        totalDiscountedPercentage_w_r: '',
                    })
                })
            } else {
                if (WardDetails) {
                    let List = this.state.ipBillingList
                    List.splice(index, 1)
                    this.setState({
                        ipBillingList: List,
                    })
                }
                this.props.errorMessage("Service Name Already Exist", 'error')
                // this.onClearHandler()
            }
            // }
            // else {
            //     if (this.state.selectedWard === "") {
            //         this.props.errorMessage("Please, Select the Ward", 'error')
            //     }
            // }
        }
    }

    renderSrvSearch = (index, item) => {
        const { t } = this.props;
        const { errorMessages } = this.state;
        return (
            <Autocomplete
                value={item.display_name || ""}
                size="small"
                onChange={(event, newValue) => { this.handleChangeSrv(index, newValue) }}
                options={this.state.serviceData || []}
                getOptionLabel={option => typeof (option) === "string" ? option : option?.display_name}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        label={(index === this.state.ipBillingList?.length - 1 && !this.state.Assorted_Id) ? t("Search Service") : ""}
                        variant="standard"
                        autoComplete="off"
                        size="small"
                        InputProps={{
                            ...params.InputProps,
                            emed_tid: `multiradBilling_srvSrch`
                        }}
                        error={!!errorMessages[index]}
                        helperText={errorMessages[index] || ""}
                    />}
                sx={{ width: "30vw" }}
                disableClearable
                renderOption={(props, item) => (
                    <Box {...props}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignContent: "flex-start" }}>
                            <Typography sx={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.service_name}</Typography>
                            <Typography sx={{ fontSize: "0.8vw" }}>{`${item?.service_type} ${item?.ip_service_code ? ` - ( ${item?.ip_service_code} )` : ""}`}</Typography>
                        </Box>
                    </Box>
                )}
            />
        )
    }

    CalculateServiceValues = (index) => {
        try {
            let { ipBillingList } = this.state
            ipBillingList[index].totalAmount = Math.round(ipBillingList[index].unit * ipBillingList[index].amount)
            ipBillingList[index].ip_service.total_amt = Math.round(ipBillingList[index].unit * ipBillingList[index].amount)
            ipBillingList[index].ip_service.original_doctor_fee = CommonValidation.calculatePercentage(ipBillingList[index]?.ip_service?.doctor_percentage, ipBillingList[index]?.ip_service?.total_amt)
            ipBillingList[index].ip_service.original_hospital_fee = CommonValidation.calculatePercentage(ipBillingList[index]?.ip_service?.hospital_percentage, ipBillingList[index]?.ip_service?.total_amt)
            ipBillingList[index].ip_service.discount_hospital_fee = ipBillingList[index].ip_service?.discount_hospital_percentage ? CommonValidation.calculatePercentage(ipBillingList[index].ip_service?.discount_hospital_percentage, ipBillingList[index].ip_service.original_hospital_fee) : 0;
            ipBillingList[index].ip_service.discount_doctor_fee = ipBillingList[index].ip_service?.discount_doctor_percentage ? CommonValidation.calculatePercentage(ipBillingList[index].ip_service?.discount_doctor_percentage, ipBillingList[index].ip_service.original_doctor_fee) : 0;
            ipBillingList[index].discountAmount = ipBillingList[index]?.ip_service?.discount_doctor_fee + ipBillingList[index]?.ip_service?.discount_hospital_fee
            ipBillingList[index].discountPercentage = CommonValidation.amountPercentageWithoutRoundoff(ipBillingList[index]?.discountAmount, ipBillingList[index]?.ip_service?.total_amt)
            ipBillingList[index].discountPercentage_w_r = CommonValidation.amountPercentage(ipBillingList[index]?.discountAmount, ipBillingList[index]?.ip_service?.total_amt)
            ipBillingList[index].ip_service.doctor_fee = CommonValidation.formatAmount(ipBillingList[index].ip_service.original_doctor_fee - (ipBillingList[index].ip_service.discount_doctor_fee ? ipBillingList[index].ip_service.discount_doctor_fee : 0))
            ipBillingList[index].ip_service.hospital_fee = CommonValidation.formatAmount(ipBillingList[index].ip_service.original_hospital_fee - (ipBillingList[index].ip_service.discount_hospital_fee ? ipBillingList[index].ip_service.discount_hospital_fee : 0))
            ipBillingList[index].discountAmount = ipBillingList[index]?.ip_service?.discount_doctor_fee + ipBillingList[index]?.ip_service?.discount_hospital_fee
            ipBillingList[index].discountPercentage = CommonValidation.amountPercentageWithoutRoundoff(ipBillingList[index]?.discountAmount, ipBillingList[index]?.ip_service?.total_amt)
            ipBillingList[index].discountPercentage_w_r = CommonValidation.amountPercentage(ipBillingList[index]?.discountAmount, ipBillingList[index]?.ip_service?.total_amt)
            this.setState({ ipBillingList }, () => {
                // this.calculateTotalDiscount()
                this.setState({
                    totalDiscountedAmount: '',
                    totalDiscountedPercentage: '',
                    totalDiscountedPercentage_w_r: '',
                })
            })
        } catch (error) {
            this.props.errorMessage(error.message, 'error')
        }
    }

    CalculateBillValuse = (index) => {
        this.setState((prevState) => {
            const list = [...prevState.ipBillingList];
            // list[index]['service_total_amount'] = +list[index]['service_rate_per_unit'] * list[index]['service_unit']
            list[index]['discount_amount'] = CommonValidation.calculatePercentage(list[index]["service_total_amount"], list[index]['discount_percentage'])
            list[index]['discount_percentage'] = CommonValidation.amountPercentage(list[index]['discount_amount'], list[index]['service_total_amount'])
            return {
                ipBillingList: list,
            }
        })
    }


    onChangeUnit = (item, type, index) => {
        try {
            let { ipBillingList } = this.state
            switch (type) {
                case 'increase':
                    if (item.unit >= 1) {
                        item.unit++
                        item.totalAmount = item.unit * item.amount
                        if (((item?.service_category__service_category === "Doctor Consultation Charges") || (item?.service_category__service_category === "Surgery Charges") || (item?.service_category__service_category === "Anasthesia Charges")) && (item?.ip_service?.doctor_fee || item?.ip_service?.hospital_fee || item?.ip_service?.discount_doctor_percentage || item?.ip_service?.discount_hospital_percentage)) {
                            this.setState({ ipBillingList }, () => {
                                this.CalculateServiceValues(index)
                            })

                        } else {
                            item.discountAmount = CommonValidation.calculatePercentage(item.totalAmount, item.discountPercentage)
                            item.discountPercentage = CommonValidation.amountPercentageWithoutRoundoff(item.discountAmount, item.totalAmount)
                            item.discountPercentage_w_r = CommonValidation.amountPercentage(item.discountAmount, item.totalAmount)
                            this.setState({ ipBillingList }, () => {
                                // this.calculateTotalDiscount()
                                this.setState({
                                    totalDiscountedAmount: '',
                                    totalDiscountedPercentage: '',
                                    totalDiscountedPercentage_w_r: '',
                                })
                            })
                        }

                    }
                    break;
                case 'decrease':
                    if (item.unit > 1) {
                        item.unit--
                        item.totalAmount = item.unit * item.amount
                        if (((item?.service_category__service_category === "Doctor Consultation Charges") || (item?.service_category__service_category === "Surgery Charges") || (item?.service_category__service_category === "Anasthesia Charges")) && (item?.ip_service?.doctor_fee || item?.ip_service?.hospital_fee || item?.ip_service?.discount_doctor_percentage || item?.ip_service?.discount_hospital_percentage)) {
                            this.setState({ ipBillingList }, () => {
                                this.CalculateServiceValues(index)
                            })

                        } else {
                            item.discountAmount = CommonValidation.calculatePercentage(item.totalAmount, item.discountPercentage)
                            item.discountPercentage = CommonValidation.amountPercentageWithoutRoundoff(item.discountAmount, item.totalAmount)
                            item.discountPercentage_w_r = CommonValidation.amountPercentage(item.discountAmount, item.totalAmount)
                            this.setState({ ipBillingList }, () => {
                                // this.calculateTotalDiscount()
                                this.setState({
                                    totalDiscountedAmount: '',
                                    totalDiscountedPercentage: '',
                                    totalDiscountedPercentage_w_r: '',
                                })
                            })
                        }
                    }
                    break;
                default:
                    break;
            }
        } catch (error) {
            this.props.errorMessage(error.message, 'error')
        }
    }

    shareValue = () => {
        try {
            let states = this.state
            let doctorFee = 0; let hospitalFee = 0; let wardName = '';
            let discountDoctorFee = 0; let discountHospitalFee = 0;
            let ipServiceFee = states.selectedData?.ip_service
            doctorFee = CommonValidation.formatAmount(ipServiceFee?.original_doctor_fee)
            hospitalFee = CommonValidation.formatAmount(ipServiceFee?.original_hospital_fee)
            wardName = ipServiceFee?.selected_ward_name
            discountDoctorFee = CommonValidation.formatAmount(ipServiceFee?.discount_doctor_fee)
            discountHospitalFee = CommonValidation.formatAmount(ipServiceFee?.discount_hospital_fee)
            return { doctorFee, hospitalFee, wardName, discountDoctorFee, discountHospitalFee };
        } catch (error) {
            this.props.errorMessage(error.message, 'error')
        }
    }


    onChangeSymbol = (e) => {
        try {
            let states = this.state
            let ipServiceFee = states.selectedData?.ip_service
            ipServiceFee.discount_doctor_fee = 0;
            ipServiceFee.discount_hospital_fee = 0;
            this.setState({
                selectedType: e.target.value,
                discountDoctorFee: '',
                discountHospitalFee: '',
                discountPercentDoctorFee: '',
                discountPercentDoctorFee_w_r: '',
                discountPercentHospitalFee: '',
                discountPercentHospitalFee_w_r: '',
                selectedData: this.state.selectedData
            })
        } catch (error) {
            this.props.errorMessage(error.message, 'error')
        }
    }

    feeValue = () => {
        try {
            let doctorFee = 0; let hospitalFee = 0;
            let duplicateSelectedValue = JSON.parse(this.state.dupliSelectedData)
            let ipServiceFee = duplicateSelectedValue?.ip_service
            doctorFee = +ipServiceFee?.original_doctor_fee
            hospitalFee = +ipServiceFee?.original_hospital_fee

            return { doctorFee, hospitalFee };
        } catch (error) {
            this.props.errorMessage(error.message, 'error')
        }
    }

    onChangeDiscountHandler = (e, names) => {
        try {
            let value = e.target.value
            let fee = this.feeValue()
            switch (names) {
                case 'discountDoctorFee':
                    if (this.state.selectedType === 'rupee') {
                        if ((CommonValidation.NumberWithDot(value) && +value <= fee.doctorFee) || value === '') {
                            this.state.discountPercentDoctorFee = CommonValidation.amountPercentageWithoutRoundoff(+value, fee.doctorFee)
                            this.state.discountPercentDoctorFee_w_r = CommonValidation.amountPercentage(+value, fee.doctorFee)
                            this.setState({ discountDoctorFee: +value }, () => {
                                let ipServiceFee = this.state.selectedData?.ip_service
                                ipServiceFee.discount_doctor_fee = value ? value : 0
                                if (!ipServiceFee.discount_hospital_fee && !ipServiceFee.discount_doctor_fee) {
                                    this.setState({
                                        IndivDiscountComments: ""
                                    })
                                }
                                this.setState({ selectedData: this.state.selectedData })
                            })
                        }
                    } else {
                        if ((CommonValidation.NumberWithDot(value) && +value <= 100) || value === '') {
                            this.state.discountDoctorFee = CommonValidation.calculatePercentage(value, fee.doctorFee)
                            this.setState({ discountPercentDoctorFee: value, discountPercentDoctorFee_w_r: value }, () => {
                                let ipServiceFee = this.state.selectedData?.ip_service
                                ipServiceFee.discount_doctor_fee = value ? CommonValidation.calculatePercentage(value, fee.doctorFee) : 0
                                if (!ipServiceFee.discount_hospital_fee && !ipServiceFee.discount_doctor_fee) {
                                    this.setState({
                                        IndivDiscountComments: ""
                                    })
                                }
                                this.setState({ selectedData: this.state.selectedData })
                            })
                        }
                    }
                    break;
                case 'discountHospitalFee':
                    if (this.state.selectedType === 'rupee') {
                        if ((CommonValidation.NumberWithDot(value) && +value <= fee.hospitalFee) || value === '') {
                            this.state.discountPercentHospitalFee = CommonValidation.amountPercentageWithoutRoundoff(+value, fee.hospitalFee)
                            this.state.discountPercentHospitalFee_w_r = CommonValidation.amountPercentage(+value, fee.hospitalFee)
                            this.setState({ discountHospitalFee: +value }, () => {
                                let ipServiceFee = this.state.selectedData?.ip_service
                                ipServiceFee.discount_hospital_fee = value ? value : 0
                                if (!ipServiceFee.discount_hospital_fee && !ipServiceFee.discount_doctor_fee) {
                                    this.setState({
                                        IndivDiscountComments: ""
                                    })
                                }
                                this.setState({ selectedData: this.state.selectedData })
                            })
                        }
                    } else {
                        if ((CommonValidation.NumberWithDot(value) && +value <= 100) || value === '') {
                            this.state.discountHospitalFee = CommonValidation.calculatePercentage(+value, fee.hospitalFee)
                            this.setState({ discountPercentHospitalFee: value, discountPercentHospitalFee_w_r: value }, () => {
                                let ipServiceFee = this.state.selectedData?.ip_service
                                ipServiceFee.discount_hospital_fee = value ? CommonValidation.calculatePercentage(value, fee.hospitalFee) : 0
                                if (!ipServiceFee.discount_hospital_fee && !ipServiceFee.discount_doctor_fee) {
                                    this.setState({
                                        IndivDiscountComments: ""
                                    })
                                }
                                this.setState({ selectedData: this.state.selectedData })
                            })
                        }
                    }
                    break;
                default: break;
            }
        } catch (error) {
            this.props.errorMessage(error.message, 'error')
        }
    }

    splitUpTextBox = (names, values, label, width) => {
        try {
            return (
                <TextField
                    size='small'
                    sx={{ width: width }}
                    value={values}
                    label={label}
                    autoComplete='off'
                    onChange={(e) => this.onChangeDiscountHandler(e, names)}
                    InputProps={{
                        endAdornment: this.state.selectedType === 'rupee' ?
                            // <CurrencyRupee color='primary'></CurrencyRupee> :
                            CurrencySymbol :
                            <PercentIcon color='primary' />
                    }}
                />
            )
        } catch (error) {
            this.props.errorMessage(error.message, 'error')
        }
    }

    onSaveDiscountAmount = () => {
        try {
            let states = this.state
            let doctorFee = this.state.discountDoctorFee ? this.state.discountDoctorFee : 0
            let hospitalFee = this.state.discountHospitalFee ? this.state.discountHospitalFee : 0
            let totalDiscountAmount = (+doctorFee) + (+hospitalFee)
            let newDiscount = 0; let totalAmount = 0;
            let ipServiceFee = states.selectedData?.ip_service

            let feeDoctor = this.state.discountPercentDoctorFee ? this.state.discountPercentDoctorFee : ipServiceFee?.discount_doctor_fee ? ipServiceFee?.discount_doctor_fee : 0
            let feeHospital = this.state.discountPercentHospitalFee ? this.state.discountPercentHospitalFee : ipServiceFee?.discount_hospital_fee ? ipServiceFee?.discount_hospital_percentage : 0

            // if (feeDoctor !== 0 && feeHospital !== 0) {
            let doctorAmount = CommonValidation.calculatePercentage(+states.discountPercentDoctorFee, ipServiceFee?.original_doctor_fee)
            let hospitalAmount = CommonValidation.calculatePercentage(+states.discountPercentHospitalFee, ipServiceFee?.original_hospital_fee)

            totalAmount = +ipServiceFee?.original_doctor_fee + +ipServiceFee?.original_hospital_fee
            newDiscount = doctorAmount + hospitalAmount
            let doctorPercentage = states.discountPercentDoctorFee ? +states.discountPercentDoctorFee : 0
            let hospitalPercentage = states.discountPercentHospitalFee ? +states.discountPercentHospitalFee : 0
            let discountPercent = CommonValidation.amountPercentageWithoutRoundoff(newDiscount, totalAmount)
            let discountPercent_w_r = CommonValidation.amountPercentage(newDiscount, totalAmount)
            states.selectedData.discountAmount = this.state.selectedType === 'rupee' ? (CommonValidation.formatAmount(+doctorFee) + CommonValidation.formatAmount(+hospitalFee)) : CommonValidation.formatAmount(newDiscount)
            states.selectedData.discountPercentage = this.state.selectedType === 'rupee' ? CommonValidation.amountPercentage(totalDiscountAmount, +states.selectedData?.amount) : discountPercent
            states.selectedData.discountPercentage_w_r = this.state.selectedType === 'rupee' ? CommonValidation.amountPercentage(totalDiscountAmount, +states.selectedData?.amount) : CommonValidation.formatAmount(discountPercent_w_r)

            // } else if (feeDoctor !== 0 && feeHospital === 0) {
            //   newDiscount = +feeDoctor
            //   totalAmount = +ipServiceFee?.original_doctor_fee + +ipServiceFee?.original_hospital_fee
            //   let discountPercentAmount = CommonValidation.calculatePercentage(newDiscount, +totalAmount)
            //   let doctorPercentage = states.discountPercentDoctorFee ? +states.discountPercentDoctorFee : 0
            //   let hospitalPercentage = states.discountPercentHospitalFee ? +states.discountPercentHospitalFee : 0
            //   let discountPercent = doctorPercentage + hospitalPercentage
            //   states.selectedData.discountAmount = this.state.selectedType === 'rupee' ? (CommonValidation.formatAmount(+doctorFee) + CommonValidation.formatAmount(+hospitalFee)) : CommonValidation.formatAmount(discountPercentAmount)
            //   states.selectedData.discountPercentage = this.state.selectedType === 'rupee' ? CommonValidation.amountPercentage(totalDiscountAmount, +states.selectedData?.amount) : CommonValidation.formatAmount(discountPercent)

            // } else {
            //   newDiscount = feeHospital
            //   totalAmount = +ipServiceFee?.original_doctor_fee + +ipServiceFee?.original_hospital_fee
            //   let discountPercentAmount = CommonValidation.calculatePercentage(newDiscount, +totalAmount)
            //   let doctorPercentage = states.discountPercentDoctorFee ? +states.discountPercentDoctorFee : 0
            //   let hospitalPercentage = states.discountPercentHospitalFee ? +states.discountPercentHospitalFee : 0
            //   let discountPercent = doctorPercentage + hospitalPercentage
            //   states.selectedData.discountAmount = this.state.selectedType === 'rupee' ? (CommonValidation.formatAmount(+doctorFee) + CommonValidation.formatAmount(+hospitalFee)) : CommonValidation.formatAmount(discountPercentAmount)
            //   states.selectedData.discountPercentage = this.state.selectedType === 'rupee' ? CommonValidation.amountPercentage(totalDiscountAmount, +states.selectedData?.amount) : CommonValidation.formatAmount(discountPercent)

            // }
            ipServiceFee.discount_doctor_fee = this.state.selectedType === 'rupee' ? +doctorFee : CommonValidation.calculatePercentage(this.state.discountPercentDoctorFee, ipServiceFee?.original_doctor_fee)
            ipServiceFee.discount_hospital_fee = this.state.selectedType === 'rupee' ? +hospitalFee : CommonValidation.calculatePercentage(this.state.discountPercentHospitalFee, ipServiceFee?.original_hospital_fee)
            ipServiceFee.discount_doctor_percentage = this.state.selectedType === 'rupee' ? CommonValidation.amountPercentageWithoutRoundoff(this.state.discountDoctorFee ? +this.state.discountDoctorFee : 0, ipServiceFee?.original_doctor_fee) : this.state.discountPercentDoctorFee ? +this.state.discountPercentDoctorFee : 0
            ipServiceFee.discount_doctor_percentage_w_r = this.state.selectedType === 'rupee' ? CommonValidation.amountPercentage(this.state.discountDoctorFee ? +this.state.discountDoctorFee : 0, ipServiceFee?.original_doctor_fee) : this.state.discountPercentDoctorFee_w_r ? +this.state.discountPercentDoctorFee_w_r : 0
            ipServiceFee.discount_hospital_percentage = this.state.selectedType === 'rupee' ? CommonValidation.amountPercentageWithoutRoundoff(this.state.discountHospitalFee ? +this.state.discountHospitalFee : 0, ipServiceFee?.original_hospital_fee) : this.state.discountPercentHospitalFee ? +this.state.discountPercentHospitalFee : 0
            ipServiceFee.discount_hospital_percentage_w_r = this.state.selectedType === 'rupee' ? CommonValidation.amountPercentage(this.state.discountHospitalFee ? +this.state.discountHospitalFee : 0, ipServiceFee?.original_hospital_fee) : this.state.discountPercentHospitalFee_w_r ? +this.state.discountPercentHospitalFee_w_r : 0
            ipServiceFee.original_doctor_fee = +ipServiceFee.original_doctor_fee
            ipServiceFee.original_hospital_fee = +ipServiceFee.original_hospital_fee
            ipServiceFee.doctor_fee = CommonValidation.formatAmount((+ipServiceFee.original_doctor_fee) - (ipServiceFee.discount_doctor_fee ? ipServiceFee.discount_doctor_fee : +doctorFee))
            ipServiceFee.hospital_fee = CommonValidation.formatAmount((+ipServiceFee.original_hospital_fee) - (ipServiceFee.discount_hospital_fee ? ipServiceFee.discount_hospital_fee : +hospitalFee))
            // ipServiceFee.doctor_percentage = ipServiceFee.discount_doctor_percentage ? ipServiceFee.discount_doctor_percentage : CommonValidation.amountPercentage(ipServiceFee?.old_doctor_fee, states.selectedData?.amount)
            // ipServiceFee.hospital_percentage = ipServiceFee.discount_hospital_percentage ? ipServiceFee.discount_hospital_percentage : CommonValidation.amountPercentage(ipServiceFee?.old_hospital_fee, states.selectedData?.amount)
            states.selectedData.amount_type = this.state.selectedType
            ipServiceFee.total_amount = ipServiceFee.doctor_fee + ipServiceFee.hospital_fee
            states.selectedData.individual_discount_comments = states.IndivDiscountComments

            states.selectedData.ip_service = ipServiceFee
            states.ipBillingList[this.state.discountIndex] = states.selectedData

            if ((states.discountDoctorFee || states.discountHospitalFee || states.discountPercentDoctorFee || states.discountPercentHospitalFee) > 0) {
                states.selectedData.isSplitBlock = true
            }
            this.setState({
                selectedData: this.state.selectedData,
                serviceDiscountpopup: false,
                discountIndex: null
            }, () => {
                // this.calculateTotalDiscount()
                this.setState({
                    IndivDiscountComments: '',
                    discountDoctorFee: '',
                    discountHospitalFee: '',
                    discountPercentDoctorFee: '',
                    discountPercentHospitalFee: '',
                    selectedType: 'rupee',
                    totalDiscountedAmount: '',
                    totalDiscountedPercentage: '',
                    totalDiscountedPercentage_w_r: '',
                })
            })
        } catch (error) {
            this.props.errorMessage(error.message, 'error')
        }
    }

    serviceIndividualDiscountPopup = () => {
        try {
            let states = this.state
            let fee = this.shareValue()
            let doctorShare = this.state.selectedType === 'rupee' ? this.state.discountDoctorFee : this.state.discountPercentDoctorFee
            let doctorShare_w_r = this.state.selectedType === 'rupee' ? this.state.discountDoctorFee : this.state.discountPercentDoctorFee_w_r
            let hospitalShare = this.state.selectedType === 'rupee' ? this.state.discountHospitalFee : this.state.discountPercentHospitalFee
            let hospitalShare_w_r = this.state.selectedType === 'rupee' ? this.state.discountHospitalFee : this.state.discountPercentHospitalFee_w_r
            return (
                <Modal open={this.state.serviceDiscountpopup}>
                    <Box className='eMed_Service_Discount'>
                        <Box m={'1vw'}>
                            <Typography color={Colors.SecondaryText} fontWeight={600}>Split-Up</Typography>
                        </Box>
                        <Box display={'flex'} justifyContent={'space-between'} m={'1vw'}>
                            <Box>
                                <Typography component={'div'} color={Colors.SecondaryText} fontWeight={600}>Doctor Consultation - <Typography component={'span'} fontSize={'0.8vw'} color={'gray'}>{
                                    states.ipPatientData?.patient_type === 'new_patient' ? "New Patient" :
                                        states.ipPatientData?.patient_type === 'review_patient' ? 'Review Patient' :
                                            states.ipPatientData?.patient_type === 'old_patient' ? 'Old Patient' : null
                                }</Typography></Typography>
                                <Typography visibility={fee?.wardName ? "visible" : "hidden"} component={'div'} color={'gray'}>Ward Name - <Typography component={'span'} color={Colors.SecondaryText} fontWeight={600}>{states.selectedData?.is_template ? "Nill" : fee.wardName ? fee.wardName : 'All'}</Typography></Typography>
                            </Box>
                            <Box>
                                <Typography color={'gray'}>Bill Amount</Typography>
                                <Typography fontWeight={600} color={Colors.SecondaryText}>{states.selectedData?.totalAmount ? AmountFormat(states.selectedData?.totalAmount) : states.selectedData?.ip_service?.total_amount ? AmountFormat(states.selectedData?.ip_service?.total_amount) : AmountFormat(states.selectedData?.total_amount ? states.selectedData?.total_amount : states.selectedData?.totalAmount)}</Typography>
                            </Box>
                        </Box>
                        <Box m={'1vw'} display={'flex'} justifyContent={'flex-end'}>
                            <Typography fontWeight={600} color={Colors.SecondaryText}>Select Type:
                                <Select
                                    size='small'
                                    sx={{ width: '5vw', height: '2vw', ml: '1vw' }}
                                    value={this.state.selectedType}
                                    onChange={(e) => { this.onChangeSymbol(e) }}
                                >
                                    {Unit_Types.map((item, index) => (
                                        <MenuItem key={index} value={item.value}>{item?.label}</MenuItem>
                                    ))}
                                </Select>
                            </Typography>
                        </Box>
                        <Box m={'1vw'} display={'flex'} justifyContent={'space-evenly'}>
                            {this.splitUpTextBox('discountDoctorFee', doctorShare_w_r, 'Doctor Discount', '13.5vw')}
                            {this.splitUpTextBox('discountHospitalFee', hospitalShare_w_r, 'Hospital Discount', '13.5vw')}
                        </Box>
                        <Box display={'flex'} justifyContent={'space-between'} m={'1vw'}>
                            <Box>
                                <Typography fontSize={'0.8vw'} color={'gray'}>Doctor Share</Typography>
                                <Typography color={Colors.SecondaryText} fontWeight={600}>
                                    {`${fee.doctorFee} - ${fee.discountDoctorFee ? fee.discountDoctorFee : 0} = ${((fee.doctorFee) - (+fee.discountDoctorFee)) ? AmountFormat((fee.doctorFee) - (+fee.discountDoctorFee)) : 0}`}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography fontSize={'0.8vw'} color={'gray'}>Hospital Share</Typography>
                                <Typography color={Colors.SecondaryText} fontWeight={600}>
                                    {`${fee.hospitalFee} - ${fee.discountHospitalFee ? fee.discountHospitalFee : 0} = ${((fee.hospitalFee) - (+fee.discountHospitalFee)) ? AmountFormat(fee.hospitalFee - (+fee.discountHospitalFee)) : 0}`}
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ paddingX: '0.5vw' }}>
                            <TextField
                                label={"Discount Comments"}
                                sx={{ width: '31vw' }}
                                multiline={true}
                                onChange={(e) => {
                                    this.setState({
                                        IndivDiscountComments: e.target.value
                                    })
                                }}
                                inputProps={{ maxLength: 250 }}
                                disabled={(!doctorShare && !hospitalShare)}
                                value={this.state.IndivDiscountComments}
                                rows={2} />
                        </Box>
                        <Box display={'flex'} justifyContent={'flex-end'} m={'1vw'}>
                            <Button variant='outlined' sx={{ width: '5vw', height: '2vw', textTransform: 'capitalize', mr: '1vw' }}
                                onClick={() => {
                                    this.setState({
                                        serviceDiscountpopup: false,
                                        discountDoctorFee: '',
                                        discountHospitalFee: '',
                                        discountPercentDoctorFee: '',
                                        discountPercentDoctorFee_w_r: '',
                                        discountPercentHospitalFee: '',
                                        discountPercentHospitalFee_w_r: '',
                                        selectedType: 'rupee',
                                        selectedData: JSON.parse(this.state.dupliSelectedData),
                                    })
                                }}>Close</Button>
                            <Button variant='contained' sx={{ width: '5vw', height: '2vw', textTransform: 'capitalize' }}
                                onClick={() => { this.onSaveDiscountAmount() }}
                            >Save</Button>
                        </Box>
                    </Box>
                </Modal>
            )
        } catch (error) {
            this.props.errorMessage(error.message, 'error')
        }
    }


    onClickAddDiscount = (item, index) => {
        try {
            let ipServiceFee = item?.ip_service
            let discountFee = ipServiceFee?.discount_doctor_fee ? ipServiceFee?.discount_doctor_fee : 0
            let discountHospitalFee = ipServiceFee?.discount_hospital_fee ? ipServiceFee?.discount_hospital_fee : 0

            ipServiceFee.original_doctor_fee = ipServiceFee.original_doctor_fee ? ipServiceFee.original_doctor_fee : ipServiceFee?.doctor_fee
            ipServiceFee.original_hospital_fee = ipServiceFee.original_hospital_fee ? ipServiceFee.original_hospital_fee : ipServiceFee?.hospital_fee
            ipServiceFee.share_discount = true
            this.setState({
                serviceDiscountpopup: true,
                selectedData: JSON.parse(JSON.stringify(item)),
                dupliSelectedData: JSON.stringify(item),
                selectedType: item?.amount_type,
                discountIndex: index
            }, () => {
                if (this.state.selectedType === 'rupee') {
                    this.setState({
                        discountDoctorFee: ipServiceFee?.discount_doctor_fee ? ipServiceFee?.discount_doctor_fee : 0,
                        discountHospitalFee: ipServiceFee?.discount_hospital_fee ? ipServiceFee?.discount_hospital_fee : 0
                    })
                } else {
                    this.setState({
                        discountPercentDoctorFee: ipServiceFee?.discount_doctor_percentage ? ipServiceFee?.discount_doctor_percentage : 0,
                        discountPercentDoctorFee_w_r: ipServiceFee?.discount_doctor_percentage ? CommonValidation.formatAmount(ipServiceFee?.discount_doctor_percentage) : 0,
                        discountPercentHospitalFee: ipServiceFee?.discount_hospital_percentage ? ipServiceFee?.discount_hospital_percentage : 0,
                        discountPercentHospitalFee_w_r: ipServiceFee?.discount_hospital_percentage ? CommonValidation.formatAmount(ipServiceFee?.discount_hospital_percentage) : 0,
                    })
                }
            })

            this.setState({
                IndivDiscountComments: item?.individual_discount_comments ? item?.individual_discount_comments : ""
            })
        } catch (error) {
            this.props.errorMessage(error.message, 'error')
        }
    }

    billingCalculation = (type) => {
        let amount = 0; let payableAmount = 0;
        switch (type) {
            case 'newAmount':
                let noDiscountAmount = 0;
                this.state.ipBillingList.forEach(element => {
                    noDiscountAmount = noDiscountAmount + (element.unit * +element.amount)
                })
                return AmountFormat(noDiscountAmount)
            case 'discountAmount':
                let discountedAmount = 0;
                this.state.ipBillingList.forEach(element => {
                    discountedAmount = discountedAmount + (element.discountAmount)
                })
                return AmountFormat(discountedAmount);
            case 'totalServiceAmount':
                // let totalAmt = 0;
                // this.state.ipBillingList.forEach(element => {
                //     totalAmt = totalAmt + ((element.service_fee?.hospital_fee || (element.service_fee?.hospital_fee === 0)) ? element.service_fee?.hospital_fee : (element.totalAmount - element.discountAmount))
                //     amount = amount + (element.totalAmount - element.discountAmount)
                //     this.state.totalServiceAmount = +amount
                //     this.state.concessionRestrictAmount = (+this.state.billConcessionType === 2) ? +totalAmt : +amount
                // })
                console.log(this.state.ipBillingList, "dsfg");
                return AmountFormat(this.state.totalServiceAmount)
            case 'roundOff':
                this.state.roundOff = CommonValidation.roundOff(this.state.totalServiceAmount, this.state.totalDiscountedAmount)
                return this.state.roundOff;
            case 'payableAmount':
                let totalAmt = 0;
                this.state.ipBillingList.forEach(element => {
                    if (element?.id) {
                        totalAmt = totalAmt + ((element.ip_service?.hospital_fee || element.ip_service?.hospital_fee === 0) ? element.ip_service?.hospital_fee : ((this.state.is_gst_enabled && element?.gst_percentage) ? CommonValidation.CalculateGSTInclude((element?.totalAmount - element.discountAmount), element?.gst_percentage) : (element?.totalAmount - element.discountAmount)))
                        amount = amount + (((this.state.is_gst_enabled && element?.gst_percentage) ? CommonValidation.CalculateGSTInclude((element?.totalAmount - element.discountAmount), element?.gst_percentage) : (element?.totalAmount - element.discountAmount)))
                        this.state.totalServiceAmount = +amount
                        this.state.concessionRestrictAmt = +this.state.billConcessionType === 1 ? +amount : +totalAmt
                    }
                })
                let discount = this.state.totalDiscountedAmount ? this.state.totalDiscountedAmount : 0
                payableAmount = this.state.totalServiceAmount - discount
                this.state.roundOff = CommonValidation.roundOff(this.state.totalServiceAmount, this.state.totalDiscountedAmount)
                this.state.payableAmount = Math.round(+payableAmount)
                return AmountFormat(this.state.payableAmount)

            default:
                break;
        }
    }

    popupClose = (data) => {
        if (data) {
            this.setState({
                isDeleteAll: false,
                ClearClicked: false
            })
        }
        this.setState({
            isDeleteCliked: false,
            deleteIndex: null,
            deleteService: ""
        })
    }

    onClearHandler = () => {
        this.setState({
            editRate: false,
            editUnit: false,
            overAllDiscountAmt: '',
            overAllDiscountPercent: '',
            totalBillAmt: 0,
            docSplitPopup: false,
            ipBillingList: [lineItem],
            errorMessages: [""],
            payableAmount: 0,
            totalServiceAmount: 0,
            discountReason: "",
            discountReasonPopup: false,
            isDeleteAll: false,
            Assorted_Id: null,
            totalDiscountedAmount: '',
            totalDiscountedPercentage: '',
            totalDiscountedPercentage_w_r: '',
            concessionRestrictAmt: 0
        })
    }

    handleDelete = () => {
        this.state.ipBillingList.splice(this.state.deleteIndex, 1)
        this.setState({
            editRate: false,
            editUnit: false,
            totalBillAmt: 0,
            docSplitPopup: false,
            ipBillingList: this.state.ipBillingList,
            errorMessages: [""],
            isDeleteCliked: false,
        })
    }

    convertTimeToDecimal(time) {
        if (time) {
            let [hours, minutes] = time?.split(':').map(Number);
            return hours * 60 + minutes;
        } else {
            return 0
        }
    }

    CalculateHourAmount(ratePerHour, changedTime, fixedTime, minTime, minAmount) {
        let fMinTime = typeof (minTime) === 'number' ? (minTime < 9 ? `00:0${minTime}` : `00:${minTime}`) : minTime
        let fixedTimes = this.convertTimeToDecimal(fixedTime);
        let changedTimes = this.convertTimeToDecimal(changedTime);
        let minTimes = this.convertTimeToDecimal(fMinTime ? fMinTime : '00:00');
        let changeAndFixedTime = changedTimes - fixedTimes
        let time = changeAndFixedTime > 0 ? changeAndFixedTime : 0
        let timeCalculateTime = (time === 0 && minTimes === 0) ? 0 : (Math.ceil(time / minTimes) === Infinity ? 0 : Math.ceil(time / minTimes))
        let totalCost = ((+ratePerHour) + (timeCalculateTime * minAmount))
        // let totalCost = Math.ceil(changedTimes / fixedTimes) * ratePerHour;

        return totalCost;
    }

    FindTimeDifference = (startDate, endDate) => {

        var diff = Math.abs(new Date(endDate) - new Date(startDate));
        var hours = Math.floor(diff / 3.6e6);
        return hours
    }

    TimeFieldCalculation = (item) => {
        let { ipBillingList } = this.state
        let Hours = (item.hour == "" ? "00" : (item.hour).length < 2 ? ("0" + item.hour) : item.hour) + ":" + (item.minute == "" ? "00" : (item.minute).length < 2 ? ("0" + item.minute) : item.minute) + ":" + "00"
        item.totalAmount = this.CalculateHourAmount(item.amount, Hours, item.old_time_taken, item?.minimum_time, +item?.min_amount)
        item.time_taken = Hours.split(":")[0] + ":" + Hours.split(":")[1]
        this.setState({ ipBillingList }, () => {
            item.field = false
            this.setState({
                ipBillingList
            })
        })
    }

    CalculateTimeDiff = () => {
        var startTime = new Date(this.state?.selectedService?.start_date);
        var endTime = new Date(this.state?.selectedService?.end_date);

        const startTimes = new Date(startTime);
        const endTimes = new Date(endTime);

        if (endTimes > startTimes) {
            this.setState({
                timeFormate: false
            })
        } else {
            this.setState({
                timeFormate: true
            })
        }

        startTime.setSeconds(0);
        endTime.setSeconds(0);

        var timeDiffMillis = endTime - startTime;
        var hours = Math.floor(timeDiffMillis / (1000 * 60 * 60));
        var minutes = Math.floor((timeDiffMillis % (1000 * 60 * 60)) / (1000 * 60));

        let formattedHours = hours > 9 ? hours.toString() : `0${hours}`;
        let formattedMinutes = minutes > 9 ? minutes.toString() : `0${minutes}`;

        let changedTime = `${formattedHours}:${formattedMinutes}:00`;
        this.setState({
            ChangedUnit: changedTime
        })
    }

    DateConvertion = (date) => {
        let outputDateString = date.getFullYear() + '-' +
            ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
        return outputDateString
    }

    TimeConvertion = (inputDate) => {
        let outputDateString = inputDate.getFullYear() + '-' +
            ('0' + (inputDate.getMonth() + 1)).slice(-2) + '-' +
            ('0' + inputDate.getDate()).slice(-2) + 'T' +
            ('0' + inputDate.getHours()).slice(-2) + ':' +
            ('0' + inputDate.getMinutes()).slice(-2) + ':' +
            ('0' + inputDate.getSeconds()).slice(-2);
        return moment(outputDateString)
    }

    buttonOneAction = () => {
        let { selectedService } = this.state;
        if (!selectedService?.procedure_start_time && !selectedService?.procedure_end_time) {
            selectedService["start_date"] = (selectedService?.changed_start_time == null ? moment(this.state.PatientDetails?.admission_date) : selectedService?.changed_start_time);
            selectedService["end_date"] = selectedService?.changed_end_time;
        } else {
            selectedService["start_date"] = selectedService?.start_date
            selectedService["end_date"] = selectedService?.end_date
        }
        this.setState({
            selectedService
        }, () => {
            this.setState({ Timepopup: false })
        })
    }
    buttonTwoAction = (item) => {
        let { ipBillingList, ChangedUnit, timeFormate } = this.state
        let changedTime = ChangedUnit == 0 ? item.service_units : ChangedUnit
        if (this.state?.selectedService?.end_date && this.state?.selectedService?.end_date != null && !timeFormate) {
            var timeParts = changedTime.split(":");
            var hours = parseFloat(timeParts[0]);
            var minutes = parseFloat(timeParts[1]);
            if (hours >= 0 || minutes >= 0) {
                item.totalAmount = this.CalculateHourAmount(item.amount, changedTime, item.old_time_taken, item?.minimum_time, +item?.min_amount)
                item.time_taken = changedTime
                item.discountAmount = CommonValidation.calculatePercentage(item.totalAmount, item.discountPercentage)
                item.discountPercentage = CommonValidation.amountPercentage(item.discountAmount, item.totalAmount)
                item.changed_start_time = item.start_date
                item.changed_end_time = item.end_date
                this.setState({ ipBillingList, Timepopup: false }, () => {
                    // this.calculateTotalDiscount()
                    this.setState({
                        totalDiscountedAmount: '',
                        totalDiscountedPercentage: '',
                        totalDiscountedPercentage_w_r: '',
                    })
                })
            } else {
                this.props.errorMessage("Enter Correct End Date/Time", 'error')
            }
        } else {
            this.props.errorMessage("Enter Correct End Date/Time", 'error')
        }
    }


    packageSelection = () => {
        let states = this.state
        return (
            <Autocomplete
                disableClearable
                openOnFocus
                value={null}
                sx={{ width: "16dvw" }}
                size="small"
                disabled={this.props?.PatientDetails === null || this.state.Assorted_Id}
                options={this.state.PackageDataList?.length > 0 ? this.state.PackageDataList : []}
                getOptionLabel={option => typeof (option) === "string" ? option : option?.package_name}
                onChange={(event, newValue) => {
                    if (newValue?.package_name !== "") {
                        let serviceList = []
                        let templateFlag = false;
                        this.state.ipBillingList.forEach(element => {
                            if (newValue?.package_name === element.template_name) {
                                templateFlag = true
                            }
                        })
                        newValue?.service_data.forEach((element) => {
                            if (((element.service_category__service_category === "Doctor Consultation Charges") || (element.service_category__service_category === "Surgery Charges") || (element.service_category__service_category === "Anasthesia Charges"))) {
                                element.totalAmount = element.amount
                            } else {
                                element.min_amount = element?.minimum_amount ? element?.minimum_amount : 0
                                element.minimum_time = element?.minimum_time ? element?.minimum_time : '00:00'
                                element.start_date = moment(this.state.PatientDetails?.admission_date)
                                element.end_date = null
                                element.changed_start_time = null
                                element.changed_end_time = null
                                element.old_time_taken = element.time_taken
                                element.totalAmount = element.unit == "hrs" ? this.CalculateHourAmount(element.amount, element.time_taken, element.old_time_taken, element.minimum_time, +element.min_amount) : element.amount
                            }
                            element.unit = "1"
                            element.discountPercentage = ""
                            element.discountAmount = ""
                            element.template_name = newValue?.package_name
                            element.templateId = this.state.templateId
                            element.is_template = true
                            element.selected_Ward = this.state.selectedWard ? this.state.selectedWard : element.consultant_charge[0].ward_name
                            element.is_auto_billing = false
                            element.isSplitBlock = false
                            element.display_name = `${element?.service_type ? element?.service_type : '-'} - ${element?.service_name ? element?.service_name : '-'} - ${element?.ip_service_code ? element?.ip_service_code : '-'} `
                            element.ip_service = {
                                'selected_ward_name': element.selected_ward ? element.selected_ward : '',
                                'total_amount': element.totalAmount ? element.totalAmount : 0,
                                'amount': element.amount ? element.amount : 0,
                                'doctor_fee': element.doctor_share ? element.doctor_share : '',
                                'hospital_fee': element.hospital_share ? element.hospital_share : '',
                                'doctor_percentage': element.doctor_percent ? element.doctor_percent : 0,
                                'hospital_percentage': element.hospital_percent ? element.hospital_percent : 0,
                                'unit': element.unit ? element.unit : 0,
                                'service_category': element.service_category__service_category ? element.service_category__service_category : '',
                                'service_amount': element.service_amount ? element.service_amount : 0
                            }
                            if (!templateFlag) {
                                serviceList.push(element)
                            } else {
                                this.props.errorMessage("Service Template Already Exist", 'error')
                                this.onClearHandler()
                            }
                        })
                        let BillList = this.state.ipBillingList
                        BillList = BillList.concat(serviceList).filter((list) => list?.id)
                        BillList.push(lineItem)
                        this.setState({
                            ipBillingList: BillList
                        }, () => {
                            // this.onClearHandler()
                            // this.calculateTotalDiscount()
                            this.setState({
                                totalDiscountedAmount: '',
                                totalDiscountedPercentage: '',
                                totalDiscountedPercentage_w_r: '',
                            })
                        })
                    } else {
                        this.props.errorMessage("Please, Select Service Template", 'error')
                    }

                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={"Select Package"}
                        autoComplete="off"
                        size="small"
                        disabled={this.props?.PatientDetails === null || this.state.Assorted_Id}
                        InputProps={{
                            ...params.InputProps,
                            emed_tid: `multiBilling_package`
                        }}
                    />
                )}
            />
        )
    }

    AssortPackageSelection = () => {
        let states = this.state
        return (
            <Autocomplete
                disableClearable
                openOnFocus
                value={null}
                sx={{ width: "16dvw" }}
                size="small"
                disabled={this.props?.PatientDetails === null}
                options={this.state.AssortedPackageList?.length > 0 ? this.state.AssortedPackageList : []}
                getOptionLabel={option => typeof (option) === "string" ? option : option?.pack_name}
                onChange={(event, newValue) => {
                    if (newValue?.package_name !== "") {
                        if (this.state.ipBillingList?.every((list) => !list?.id)) {
                            let assortedServiceList = [];
                            newValue?.line_items?.forEach(element => {
                                Object.values(element)
                                    .filter(value => Array.isArray(value))
                                    .forEach(dataArray => {
                                        dataArray.forEach(dataItem => {
                                            if (dataItem) {
                                                assortedServiceList.push(dataItem);
                                            }
                                        });
                                    });
                            });
                            var serviceList = []
                            assortedServiceList.forEach((element) => {
                                if (((element.service_category__service_category === "Doctor Consultation Charges") || (element.service_category__service_category === "Surgery Charges") || (element.service_category__service_category === "Anasthesia Charges"))) {
                                    element.totalAmount = element.amount
                                } else {
                                    element.min_amount = element?.minimum_amount ? element?.minimum_amount : 0
                                    element.minimum_time = element?.minimum_time ? element?.minimum_time : '00:00'
                                    element.start_date = moment(this.state.ipPatientData?.admission_date)
                                    element.end_date = null
                                    element.changed_start_time = null
                                    element.changed_end_time = null
                                    element.old_time_taken = element.time_taken
                                    element.totalAmount = element.unit == "hrs" ? this.CalculateHourAmount(element.amount, element.time_taken, element.old_time_taken, element.minimum_time, +element.min_amount) : element.amount
                                }
                                element.unit = element.quantity ? element.quantity : '1'
                                element.discountPercentage = ""
                                element.discountAmount = ""
                                element.drug_id = element.drug_config_id ? element.drug_config_id : null
                                element.assorted_head = element.service_head ? element.service_head : ''
                                element.assorted_name = this.state.service
                                element.assortedId = this.state.Assorted_Id
                                element.is_assorted = true
                                element.selected_Ward = this.state.selectedWard ? this.state.selectedWard : (element.consultant_charge != null) ? element.consultant_charge[0].ward_name : ""
                                element.is_auto_billing = false
                                element.isSplitBlock = false
                                element.display_name = `${element?.service_type ? element?.service_type : '-'} - ${element?.service_name ? element?.service_name : '-'} - ${element?.ip_service_code ? element?.ip_service_code : '-'} `
                                if (+element?.quantity > 1 && element?.amount) {
                                    element.amount = +(+element?.amount / +element?.quantity)?.toFixed(2)
                                }
                                element.ip_service = {
                                    'selected_ward_name': element.selected_ward ? element.selected_ward : '',
                                    'total_amount': element.totalAmount ? element.totalAmount : 0,
                                    'amount': element.amount ? element.amount : 0,
                                    'doctor_fee': element.doctor_share ? element.doctor_share : '',
                                    'hospital_fee': element.hospital_share ? element.hospital_share : '',
                                    'doctor_percentage': element.doctor_percent ? element.doctor_percent : 0,
                                    'hospital_percentage': element.hospital_percent ? element.hospital_percent : 0,
                                    'unit': element.unit ? element.unit : 0,
                                    'service_category': element.service_category__service_category ? element.service_category__service_category : '',
                                    'service_amount': element.service_amount ? element.service_amount : 0
                                }
                                serviceList.push(element)
                            })
                            this.setState({
                                ipBillingList: assortedServiceList,
                                Assorted_Id: newValue?.id ? newValue?.id : null
                            }, () => {
                                // this.onClearHandler()
                                // this.calculateTotalDiscount()
                                this.setState({
                                    totalDiscountedAmount: '',
                                    totalDiscountedPercentage: '',
                                    totalDiscountedPercentage_w_r: '',
                                })
                            })
                        } else {
                            this.props.errorMessage("Can't add Assorted Pack with other services")
                        }
                    }

                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={"Assorted Pakage"}
                        autoComplete="off"
                        size="small"
                        disabled={this.props?.PatientDetails === null}
                        InputProps={{
                            ...params.InputProps,
                            emed_tid: `multiBilling_package`
                        }}
                    />
                )}
            />
        )
    }


    render() {
        console.log(this.state);
        let states = this.state
        const { t } = this.props;
        let AppointmentDate = this.state.PatientDetails?.admission_date?.split("T")[0];
        const { openAccordion, ipBillingList } = this.state;
        return (
            <Accordion expanded={openAccordion} sx={{ border: '1px solid #F5F8F7', marginX: "0.3vw" }}>
                <AccordionSummary sx={{
                    flexDirection: 'row-reverse',
                    '& .MuiAccordionSummary-content': {
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }
                }}
                    expandIcon={
                        <IconButton color='primary'
                            onClick={() => {
                                if (this.props?.PatientDetails === null) {
                                    this.props?.errorMessage("Select the patient for billing.", 'error')
                                } else {
                                    this.setState((prevState) => ({ openAccordion: !prevState.openAccordion }))
                                }
                            }}
                            emed_tid="emedhub_expandBtn_testID">
                            <ExpandMoreIcon />
                        </IconButton>}
                >
                    <Typography fontWeight={"bold"} fontSize={"1dvw"} color={"#616161"}>Inpatient</Typography>
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"} columnGap={"10px"}>
                        {this.state.showAssort ? this.AssortPackageSelection() : null}
                        {this.packageSelection()}
                        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} columnGap={"5px"}>
                            {+this.state.billConcessionType === 1 ? null :
                                <Tooltip placement='top' title={`Maximum concession should be ${CurrencySymbol} ${this.state.concessionRestrictAmt}`}>
                                    <ErrorOutlineIcon color='primary' sx={{ height: '1.3vw', width: '1.3vw' }} />
                                </Tooltip>
                            }
                            <Typography fontWeight={"bold"} fontSize={"1dvw"} color={"#616161"}>Concession:</Typography>
                            {this.textBox('totalDiscountedPercentage', this.state.totalDiscountedPercentage_w_r, '6dvw', '', '%')}
                            {this.textBox('totalDiscountedAmount', this.state.totalDiscountedAmount, '6dvw', '', CurrencySymbol)}
                            {this.state.totalDiscountedPercentage || this.state.totalDiscountedAmount ?
                                <Tooltip placement='top' title={this.state.discountReason ? this.state.discountReason : this.state.savedDiscountReason}>
                                    <Box onClick={() => this.setState({ discountReasonPopup: true, discountReason: this.state.discountReason ? this.state.discountReason : this.state.savedDiscountReason })} component={'img'} src={ImagePaths.LabReportOff.default} width={'1.5vw'} height={'1.5vw'} sx={{ cursor: 'pointer' }} alignSelf={'center'} />
                                </Tooltip> :
                                <Box component={'img'} src={ImagePaths.BillTransUser.default} width={'1vw'} height={'1vw'} alignSelf={'center'} />
                            }
                        </Box>
                        <Typography fontWeight={"bold"} fontSize={"1dvw"} color={"#616161"} sx={{ width: "9vw", textAlign: 'end' }}>{
                            this.billingCalculation('payableAmount')
                        }
                        </Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Table stickyHeader size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell className='rad_bill_tbl_head' sx={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
                                    <IconButton size='small' sx={{ height: '1.2dvw' }} emed_tid='rad_bill_all_dlt_btn'
                                        onClick={() => { this.setState({ isDeleteAll: this.state.ipBillingList?.length > 0 ? true : false }) }}>
                                        <Box component={'img'} src={ImagePaths.Delete.default} className='Lab_Home_DeleteIcon' alt='Delete' />
                                    </IconButton>
                                    <Typography fontWeight={600} fontSize={'0.9dvw'}>Service Description</Typography>
                                </TableCell>
                                <TableCell className='rad_bill_tbl_head' align='center'>
                                    <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                        <Typography fontWeight={600} fontSize={'0.9dvw'}>Units</Typography>
                                        <Tooltip placement='top' title='Edit'>
                                            <IconButton size='small' sx={{ height: '1.2dvw' }} emed_tid='rad_bill_unt_btn' onClick={() => this.setState({ editUnit: !this.state.editUnit })}>
                                                <Box component={'img'} src={ImagePaths.NewEditcon.default} />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </TableCell>
                                <TableCell className='rad_bill_tbl_head' align='left'>
                                    <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                        <Typography fontWeight={600} fontSize={'0.9dvw'}>Rate Per Unit</Typography>
                                        <Tooltip placement='top' title='Edit'>
                                            <IconButton size='small' sx={{ height: '1.2dvw' }} emed_tid='rad_bill_rate_btn'
                                                onClick={() => {
                                                    this.setState({ editRate: !this.state.editRate }, () => {
                                                        if (this.state.editRate && this.state.editDiscount) {
                                                            this.setState({
                                                                editDiscount: false
                                                            })
                                                        }
                                                    })
                                                }}
                                            >
                                                <Box component={'img'} src={ImagePaths.NewEditcon.default} />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </TableCell>
                                <TableCell className='rad_bill_tbl_head' align='left'>
                                    <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                        <Typography fontWeight={600} fontSize={'0.9dvw'}>Discount</Typography>
                                        <Tooltip placement='top' title='Edit'>
                                            <IconButton size='small' sx={{ height: '1.2dvw' }} emed_tid='rad_bill_rate_btn'
                                                onClick={() => {
                                                    this.setState({ editDiscount: !this.state.editDiscount }, () => {
                                                        if (this.state.editRate && this.state.editDiscount) {
                                                            this.setState({
                                                                editRate: false
                                                            })
                                                        }
                                                    })
                                                }} >
                                                <Box component={'img'} src={ImagePaths.NewEditcon.default} />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </TableCell>
                                <TableCell className='rad_bill_tbl_head' align='right'>
                                    <Typography fontWeight={600} fontSize={'0.9dvw'}>Total Amount</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.ipBillingList?.length > 0 ? this.state.ipBillingList?.map((item, index) => {
                                let serviceName = `${item?.service_type ? item?.service_type : '-'} - ${item?.service_name ? item?.service_name : '-'}`
                                return (
                                    <TableRow key={index}>
                                        <TableCell sx={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
                                            <IconButton size='small' height={'1.2dvw'} sx={{ visibility: ((item?.id || item?.is_ward_wise) && !this.state.Assorted_Id) ? "visible" : "hidden" }}
                                                onClick={() => this.setState({ isDeleteCliked: true, deleteIndex: index, deleteService: item.service_type })}>
                                                <Box component={'img'} src={ImagePaths.Delete.default} height={'1.2dvw'} width={'1.2dvw'} />
                                            </IconButton>
                                            {this.renderSrvSearch(index, item)}
                                            {
                                                item?.is_ward_wise ?
                                                    <FormControl size='small'>
                                                        <InputLabel>Ward Name</InputLabel>
                                                        <Select
                                                            label={"Select Ward"}
                                                            size='small'
                                                            value={item?.ward_id ? item?.ward_id : null}
                                                            onChange={(e) => { this.handleChangeSrv(index, item?.value, e.target.value) }}
                                                            sx={{ width: '10vw' }}
                                                        >
                                                            {item?.ward_list.map((list, index) => {
                                                                return (
                                                                    <MenuItem key={index} value={list?.id}>{list?.ward}</MenuItem>
                                                                )
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                    : null
                                            }
                                        </TableCell>
                                        <TableCell align="center">

                                            {item?.time_taken == null || item?.time_taken == undefined ?
                                                <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                    {this.state.editUnit && item?.is_edit_unit ?
                                                        <Box component={'div'} display={'flex'} flexDirection={'row'}>
                                                            <Button className='eMed_Bill_add_btn' emed_tid='rad_bill_dec_btn'
                                                                onClick={() => { this.onChangeUnit(item, 'decrease', index) }}
                                                            >
                                                                <Typography>-</Typography>
                                                            </Button>
                                                            <Typography paddingRight={"1dvw"}>{item.unit}</Typography>
                                                            <Button className='eMed_Bill_add_btn' emed_tid='rad_bill_inc_btn'
                                                                onClick={() => { this.onChangeUnit(item, 'increase', index) }}
                                                            >
                                                                <Typography>+</Typography>
                                                            </Button>
                                                        </Box> :
                                                        <Typography>{item?.unit ? item?.unit : 0}</Typography>
                                                    }
                                                </Box> :
                                                <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                    {
                                                        this.state.editUnit && item.field && item.is_edit_unit ?
                                                            <div className='eMed_Time_field'>
                                                                <OutlinedInput
                                                                    value={item?.hour}
                                                                    onChange={(e) => {
                                                                        var today = new Date();
                                                                        today.setHours(23, 59, 59, 999);
                                                                        var AptDate = DateTime.fromJSDate(today).toFormat('yyyy-MM-dd')
                                                                        var Aptime = DateTime.fromJSDate(today).toLocaleString(DateTime.TIME_24_WITH_SECONDS);

                                                                        var startDate = this.state.PatientDetails?.admission_date ? this.state.PatientDetails?.admission_date : `${DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd')}T${DateTime.fromJSDate(new Date()).toLocaleString(DateTime.TIME_24_WITH_SECONDS)}`;
                                                                        var endDate = (AptDate + "T" + Aptime);
                                                                        let maxHours = this.FindTimeDifference(startDate, endDate)

                                                                        if ((CommonValidation.numberOnly(e.target.value) && +e.target.value < maxHours || +e.target.value == "")) {
                                                                            item.hour = +e.target.value;
                                                                            this.setState({ ipBillingList })
                                                                        }
                                                                    }}
                                                                    style={{ width: "3.5vw", height: "1.7vw", fontSize: "0.9vw" }}
                                                                />
                                                                <OutlinedInput
                                                                    value={item?.minute}
                                                                    onChange={(e) => {
                                                                        if ((CommonValidation.numberOnly(e.target.value) && +e.target.value < 60 || +e.target.value == "")) {
                                                                            item.minute = +e.target.value;
                                                                            this.setState({ minuteField: e.target.value })
                                                                        }
                                                                    }}
                                                                    style={{ width: "3.5vw", height: "1.7vw", fontSize: "0.9vw" }}
                                                                />
                                                                <IconButton onClick={() => { this.TimeFieldCalculation(item) }} size="small" ><img className="eMed_Tick_img" src={ImagePaths.Tick.default} alt={"Tick"} /></IconButton>
                                                            </div> :
                                                            <div style={{ display: "flex", cursor: "pointer", paddingTop: "0.25vw" }}>
                                                                <div onClick={() => { this.TimeFieldEnable(item?.time_taken, item) }} style={{ paddingRight: "0.25vw", fontSize: "0.95vw" }}>{item?.time_taken.length > 5 ? item.time_taken.slice(0, 5) : item?.time_taken}</div>
                                                                <div className='eMed_Time_cont' onClick={() => { this.setState({ Timepopup: this.state.editUnit && item.is_edit_unit ? true : false, selectedService: item, selectedIndex: index }) }}>
                                                                    {this.state.editUnit && !item?.is_auto_billing ?
                                                                        <div className='eMed_Time_cont'>
                                                                            <img className='eMed_Time_img' src={ImagePaths.HighlightedClock.default} alt='edit' />
                                                                        </div> :
                                                                        <div className='eMed_Time_cont'>
                                                                            <img className='eMed_Time_img' src={ImagePaths.NormalClock.default} alt='edit' />
                                                                        </div>}
                                                                </div>
                                                            </div>
                                                    }
                                                </Box>
                                            }
                                        </TableCell>
                                        <TableCell align="center">
                                            {!item?.is_auto_billing && this.state.editRate && item.is_edit_amount && ((item?.service_category__service_category === "Doctor Consultation Charges") || (item?.service_category__service_category === "Surgery Charges") || (item?.service_category__service_category === "Anasthesia Charges")) && ((item?.ip_service?.doctor_fee && item?.ip_service?.hospital_fee) || (item?.ip_service?.discount_doctor_fee > 0 || item?.ip_service?.discount_hospital_fee > 0) || (item?.ip_service?.doctor_percentage === 100 || item?.ip_service?.hospital_percentage === 100)) ?
                                                <>
                                                    <Button size='small' sx={{ textTransform: "capitalize" }} disabled={this.state.disableSplitSharePopup} onClick={() => { this.addShare(item, index) }}>{`Add Share ${item.amount ? `(${AmountFormat(item?.amount)}) ${(item?.gst_percentage && this.state.is_gst_enabled) ? `+ ${item?.gst_percentage} % GST` : ""}` : `${CurrencySymbol} 0.00`}`}</Button>
                                                </>
                                                : this.state.editRate && item.is_edit_amount ?
                                                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                        {this.inputTextBox('rateUnit', item.amount, index, "0", "0.7vw", false)}
                                                        <Typography fontSize={"0.9vw"}>{(item?.gst_percentage && this.state.is_gst_enabled) ? `+ ${item?.gst_percentage} % GST` : ""}</Typography>
                                                    </Box>
                                                    :
                                                    <Typography component={'div'} position={'relative'}>{AmountFormat(item.amount ? item.amount : 0)}</Typography>
                                            }
                                        </TableCell>
                                        <TableCell align="center" display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                            {
                                                this.state.editDiscount && item.is_edit_discount && ((item?.service_category__service_category === "Doctor Consultation Charges") || (item?.service_category__service_category === "Surgery Charges") || (item?.service_category__service_category === "Anasthesia Charges")) && ((item?.ip_service?.doctor_fee && item?.ip_service?.hospital_fee) || (item?.ip_service?.discount_doctor_fee > 0 || item?.ip_service?.discount_hospital_fee > 0) || (item?.ip_service?.doctor_percentage === 100 || item?.ip_service?.hospital_percentage === 100)) ?
                                                    <>
                                                        <Button
                                                            startIcon={<AddCircle />}
                                                            endIcon={item?.discountAmount ? `(${AmountFormat(item?.discountAmount)})` : null}
                                                            sx={{ textTransform: 'capitalize', height: '1.5vw' }}
                                                            onClick={() => { this.onClickAddDiscount(item, index) }}
                                                        >Add Discount</Button>
                                                    </> :
                                                    this.state.editDiscount && item.is_edit_discount ?
                                                        <>
                                                            {this.inputTextBox('percentage', item.discountPercentage_w_r, index, "0 %", "0.7vw", false)}
                                                            {this.inputTextBox('discountAmount', item.discountAmount, index, `${CurrencySymbol} 0`, "0.7vw", false)}
                                                        </> :
                                                        <Typography>{AmountFormat(item?.discountAmount ? item?.discountAmount : 0)}</Typography>
                                            }
                                        </TableCell>
                                        <TableCell align="right">
                                            <Box component={'div'} display={'flex'} justifyContent={'flex-end'}>
                                                {(this.state.is_gst_enabled && item?.gst_percentage) ? <Tooltip placement='top' arrow title={(this.state.is_gst_enabled && item?.gst_percentage) ? `Inclusive of ${item?.gst_percentage}% GST` : ""}>
                                                    <ReportGmailerrorredIcon color='primary' sx={{ marginRight: '0.5vw' }} />
                                                </Tooltip> : null}
                                                <Typography>{AmountFormat((((this.state.is_gst_enabled && item?.gst_percentage) ? CommonValidation.CalculateGSTInclude((item.totalAmount - item.discountAmount), item?.gst_percentage) : (item.totalAmount - item.discountAmount))).toFixed(2) || 0)}</Typography>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                )
                            }) :
                                <TableRow>
                                    <TableCell colSpan={6} align='center'>No Records To Be Shown</TableCell>
                                </TableRow>}
                        </TableBody>
                    </Table>
                </AccordionDetails>
                {this.state.docSplitPopup ? this.splitScreenPopup() : null}
                {this.serviceIndividualDiscountPopup()}
                {this.discountReasonPopup()}
                {this.state.isDeleteAll && this.state.ipBillingList.length > 0 ? <DeletePopup
                    DeletTitle={`${t("deleteMsg")} ${t("All")} ?`}
                    deleteAlertPopupClose={this.popupClose.bind(this, "all")}
                    removeData={this.onClearHandler.bind(this)}
                /> : null}
                {this.state.isDeleteCliked ? <DeletePopup
                    DeletTitle={`${t("deleteMsg")} ${this.state.deleteService ? this.state.deleteService : "-"} ?`}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this.handleDelete.bind(this)}
                /> : null}
                {this.state.Timepopup ?
                    <Modal open={true}>
                        <Paper elevation={3} id="emedhub_popup_mainBox" sx={{ width: "37vw", height: "12vw" }}>
                            <div id="emedhub_popup_header" style={{ backgroundColor: Colors.DataTblHeaderbg }}>
                                <Tooltip placement='top' title={states?.selectedService?.service_name} arrow>
                                    <Typography sx={{ color: "#616161", fontWeight: 600 }}>{`Kindly Select Date and Time of ${states?.selectedService?.service_name?.length > 22 ?
                                        states?.selectedService?.service_name.slice(0, 20) + "..." : states?.selectedService?.service_name}`}
                                    </Typography>
                                </Tooltip>
                                <IconButton onClick={() => { this.buttonOneAction() }} size="small" ><img className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt={"close"} /></IconButton>
                            </div>
                            <div className='emedhub_DatePicker_container'>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        value={states?.selectedService?.start_date}
                                        name={"datePicker"}
                                        open={this.state.startTimeOpen}
                                        onOpen={() => { this.setState({ startTimeOpen: true }) }}
                                        onClose={() => { this.setState({ startTimeOpen: false }) }}
                                        inputFormat='DD-MM-YYYY hh:mm A'
                                        label={t('Start Date & Time')}
                                        minDate={AppointmentDate}
                                        maxDate={new Date()}
                                        minTime={AppointmentDate === this.DateConvertion(new Date(states?.selectedService?.start_date)) ? moment(this.state.PatientDetails?.admission_date) : null}
                                        onChange={
                                            (newDate) => {
                                                ipBillingList[states?.selectedIndex]["start_date"] = newDate
                                                this.setState({
                                                    ipBillingList
                                                }, () => { this.CalculateTimeDiff() })
                                            }}
                                        renderInput={(params) => <TextField onClick={() => { this.setState({ startTimeOpen: true }) }} onKeyDown={(e) => { e.preventDefault() }} style={{ width: '17vw' }} size='small' {...params} />}
                                    />
                                </LocalizationProvider>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        value={states?.selectedService?.end_date ? states?.selectedService?.end_date : null}
                                        name={"datePicker"}
                                        open={this.state.endTimeOpen}
                                        onOpen={() => { this.setState({ endTimeOpen: true }) }}
                                        onClose={() => { this.setState({ endTimeOpen: false }) }}
                                        inputFormat='DD-MM-YYYY hh:mm A'
                                        label={t('End Date & Time')}
                                        minDate={states?.selectedService?.start_date}
                                        maxDate={new Date()}
                                        minTime={this.DateConvertion(new Date(states?.selectedService?.start_date)) === this.DateConvertion(new Date(states?.selectedService?.end_date)) ? this.TimeConvertion(new Date(states?.selectedService?.start_date)) : null}
                                        onChange={
                                            (newDate) => {
                                                ipBillingList[states?.selectedIndex]["end_date"] = newDate ? newDate : null
                                                this.setState({
                                                    ipBillingList
                                                }, () => { this.CalculateTimeDiff() })
                                            }}
                                        renderInput={(params) => <TextField onClick={() => { this.setState({ endTimeOpen: true }) }} onKeyDown={(e) => { e.preventDefault() }} style={{ width: '17vw' }} size='small' {...params} />}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginTop: "1vw" }}>
                                <Button size="small" sx={{ marginRight: "1vw", textTransform: "capitalize" }} variant={"outlined"} onClick={() => { this.buttonOneAction() }}>{"Cancel"}</Button>
                                <Button variant="contained" size="small" sx={{ textTransform: "capitalize" }} onClick={() => { this.buttonTwoAction(this.state?.selectedService) }}>{"Add"}</Button>
                            </div>
                        </Paper>
                    </Modal>
                    : null
                }
            </Accordion>
        )
    }
}
export default withTranslation()(MultiIPBilling);
