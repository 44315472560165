import { Box, Button, colors, Drawer, Grow, IconButton, Stack, Tooltip } from '@mui/material'
import React, { Component } from 'react'
import '../pharmaReports.css';
import { Colors } from '../../../../Styles/Colors';
import { AmountsCard, AmountsCardWithCount } from '../../../../Components/CommonCards/CommonCards';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DataGrid } from '@mui/x-data-grid';
import { CommonGridToolBarWithFilterText } from '../../../../Components/Common Components/CommonComponents';
import { formatDate, splitDate, AmountFormat } from '../../../../Components/CommonFunctions/CommonFunctions';
import { withTranslation } from 'react-i18next';
import PharmaFilter from '../PharmaFilter';
import { DateTime } from 'luxon';
import { CurrencySymbol } from '../../../../Utility/Constants';

class SalesOverview extends Component {

  constructor(props) {
    super(props)
    this.state = {
      salesOverviewData: [],
      page: 0,
      pageSize: 10,
      rowCount: 0,
      filterDatas: {
        'fromDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        'toDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        "dateIndex": 1,
        "days":91,
        "patientName": '',
        "billNumber":'',
        "doctorName":null,
        "OPSelected": true,
        "IPSelected": true,
        "SelfSelected": true,
      },
      pageName: 'salesOverview',
      filterOpen: false,
      totalAmount: 0,
      cgst: 0,
      sgst: 0,
      discountAmount: 0,
      netAmount: 0,
      roundOff:0,
      isLoader: false,
      inPatientCount : 0,
      outPatientCount :0,
      Otccount :0,
      showBox : false,
      ipNetAmt :0,
      opNetAmt :0,
      OtcNetAmt :0,
      rowCount :0,
    }
  }

  componentDidMount() {
    this.getSalesOverviewReportData()
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  renderFilter = (data) => {
    this.setState({
      filterDatas: data,
      filterOpen: false,
      page:0,
    }, () => { this.getSalesOverviewReportData() })
  }

  ClosePopUp() {
    this.setState({
      filterOpen: false,
    })
  }

  clearFilter = (data) => {
    this.setState({
      filterDatas: data
    })
  }

  gridToolBar = () => {
    let TextArray = [
      { label: "From Date", value: formatDate(this.state.filterDatas.fromDate) },
      { label: "To Date", value: formatDate(this.state.filterDatas.toDate) },
    ]
    return (
      <Box>
        <CommonGridToolBarWithFilterText FilterTextArray={TextArray} noExport={true} />
      </Box>

    )
  }

  getSalesOverviewReportData = () => {
    try {
      this.LoaderFunction(true);
      let patientType = []
      if(this.state.filterDatas.OPSelected){
        patientType?.push("op")
      }
      if(this.state.filterDatas.IPSelected){
        patientType?.push("ip")
      }
      if(this.state.filterDatas.SelfSelected){
        patientType?.push("pharmacy")
      }
      let doctorName = this.state.filterDatas.doctorName === null ? '' : this.state.filterDatas.doctorName?.name
      RestAPIService.getAll(Serviceurls.PHARMA_SALES_OVERVIEW_REPORT_GET + `?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&page=${this.state.page + 1}&page_size=${this.state.pageSize}&patient_name=${this.state.filterDatas.patientName}&bill_number=${this.state.filterDatas.billNumber}&doctor_name=${doctorName}&module_type=${patientType}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.LoaderFunction(false);
            this.setState({
              salesOverviewData: response.data.data?.result,
              totalAmount: response.data.data?.total_amount,
              cgst: response.data.data?.total_cgst_amount,
              sgst: response.data.data?.total_sgst_amount,
              discountAmount: response.data.data?.total_discount_amount,
              netAmount: response.data.data?.total_net_amount,
              inPatientCount: response.data.data?.total_ip_count,
              outPatientCount: response.data.data?.total_op_count,
              Otccount: response.data.data?.total_pharmacy_count,
              ipNetAmt: response.data.data?.total_ip_net_amount,
              opNetAmt: response.data.data?.total_op_net_amount,
              OtcNetAmt: response.data.data?.total_pharmacy_net_amount,
              roundOff: response.data.data?.total_round_off_amount,
              rowCount: response.data.data?.total_count
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.LoaderFunction(false);
            this.errorMessage(error.response.data.message)
          } else {
            this.LoaderFunction(false);
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getSalesoverviewPrint = () => {
    try {
      this.setState({disableBtn: true})
      let patientType = []
      if(this.state.filterDatas.OPSelected){
        patientType?.push("op")
      }
      if(this.state.filterDatas.IPSelected){
        patientType?.push("ip")
      }
      if(this.state.filterDatas.SelfSelected){
        patientType?.push("pharmacy")
      }
      let doctorName = this.state.filterDatas.doctorName === null ? '' : this.state.filterDatas.doctorName?.name
      RestAPIService.getAll(Serviceurls.PHARMA_SALES_OVERVIEW_REPORT_GET + `?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&page=${this.state.page + 1}&page_size=${this.state.pageSize}&patient_name=${this.state.filterDatas.patientName}&bill_number=${this.state.filterDatas.billNumber}&doctor_name=${doctorName}&module_type=${patientType}&export=pdf`)
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({disableBtn: false})
        }).catch((error) => {
          if (error.response?.data?.message) {
            this.errorMessage(error.response?.data?.message);
          } else {
            this.errorMessage(error.message);
          }
          this.setState({disableBtn: false})
        })
    } catch (error) {
      this.errorMessage(error.message)
      this.setState({disableBtn: false})
    }
  }

  getSalesoverviewExcel = () => {
    try {
      this.setState({disableBtn: true})
      let patientType = []
      if(this.state.filterDatas.OPSelected){
        patientType?.push("op")
      }
      if(this.state.filterDatas.IPSelected){
        patientType?.push("ip")
      }
      if(this.state.filterDatas.SelfSelected){
        patientType?.push("pharmacy")
      }
      RestAPIService.excelGet(Serviceurls.PHARMA_SALES_OVERVIEW_REPORT_GET + `?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&page=${this.state.page + 1}&page_size=${this.state.pageSize}&bill_number=${this.state.filterDatas.billNumber}&module_type=${patientType}&export=excel`)
        .then((response) => {
          if(response.data){
          var pom = document.createElement('a');
          var csvContent = response.data; //here we load our csv data 
    
          let filename = response.headers["content-disposition"].split("filename=")[1]
          // var blob = new Blob([csvContent], {type: 'data:application/vnd.ms-excel;base64'});
    
          var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
          var url = URL.createObjectURL(blob);
          pom.href = url;
          pom.setAttribute('download', filename ? filename : 'registry.xlsx');
          pom.click();
          this.setState({disableBtn: false})
          }
        }).catch((error) => {
          if (error.response?.data?.message) {
            this.errorMessage(error.response?.data?.message);
          } else {
            this.errorMessage(error.message);
          }
          this.setState({disableBtn: false})
        })
    } catch (error) {
      this.setState({disableBtn: false})
      this.errorMessage(error.message);
    }
  }

  render() {
    const { t } = this.props
    this.state.salesOverviewData.forEach((element, index) => element.sno = index + 1)
    const columns = [
      {
        field: 'invoice_number', headerName: "Bill No", flex: 0.0769,
        renderCell: ({row}) => {
          return (<Box component={'div'}>
            {<Tooltip placement='top' title={row?.invoice_number} arrow>
              <div>{row?.invoice_number?.length > 10 ? `...${row?.invoice_number?.slice(-10)}` : '-'}</div></Tooltip>}
          </Box>)}
      },
      {
        field: 'invoice_dt', headerName: "Bill Date", flex: 0.0769, headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>
          {splitDate(params?.row?.invoice_dt)?.length > 12 ? <Tooltip placement='top' title={params?.row?.invoice_dt} arrow><div>{params?.row?.invoice_dt?.slice(0, 12) + '...'}</div></Tooltip> :
            params?.row?.invoice_dt ? splitDate(params?.row?.invoice_dt) : '-'}
        </Box>)
      },
      {
        field: 'billing_customer_name', headerName: "Patient Name", flex: 0.0769,
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.billing_customer_name?.length > 12 ? <Tooltip placement='top' title={params?.row?.billing_customer_name} arrow><div>{params?.row?.billing_customer_name?.slice(0, 12) + '...'}</div></Tooltip> :
            params?.row?.billing_customer_name ? params?.row?.billing_customer_name : '-'}
        </Box>)
      },
      {
        field: 'module_type', headerName: "Patient Type", flex: 0.0769,
        renderCell: (params) => (<Box component={'div'}>{params?.row?.module_type ? params?.row?.module_type : '-'}</Box>)
      },
      {
        field: 'doctor_name', headerName: "Doctor Name", flex: 0.0769,
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.doctor_name?.length > 12 ? <Tooltip placement='top' title={params?.row?.doctor_name} arrow><div>{params?.row?.doctor_name?.slice(0, 12) + '...'}</div></Tooltip> :
            params?.row?.doctor_name ? params?.row?.doctor_name : '-'}
        </Box>)
      },
      // { field: 'tax_rate', headerName: "Tax %", flex: 0.0769, type: "number", headerAlign: "center", align: "center" },
      {
        field: 'taxable_amount', headerName: `${"Amount"} (${CurrencySymbol})`, flex: 0.0769, type: "number",
        renderCell: ({ row }) => (<Box>{AmountFormat(row?.taxable_amount || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
      },
      {
        field: 'state', headerName: `${"State"}`, flex: 0.0769,
        renderCell: (params) => (<Box>{params?.row?.state ? params?.row?.state?.length > 10 ?
          <Tooltip placement='top' title={params?.row?.state}><div>{params?.row?.state?.slice(0, 10) + '...'}</div></Tooltip> :
          params?.row?.state : '-'}</Box>)
      },
      {
        field: 'cgst_amount', headerName: `${"CGST"} (${CurrencySymbol})`, flex: 0.0769, type: "number",
        renderCell: ({ row }) => (<Box>{AmountFormat(row?.cgst_amount || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
      },
      {
        field: 'sgst_amount', headerName: `${"SGST"} (${CurrencySymbol})`, flex: 0.0769, type: "number",
        renderCell: ({ row }) => (<Box>{AmountFormat(row?.sgst_amount || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
      },
      {
        field: 'igst', headerName: `${"IGST"} (${CurrencySymbol})`, flex: 0.0769, type: 'number',
        renderCell: ({ row }) => (<Box>{AmountFormat(row?.igst || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
      },
      {
        field: 'discount_amt', headerName: `${"Discount Amount"} (${CurrencySymbol})`, flex: 0.0769, type: "number",
        renderCell: ({ row }) => (<Box>{AmountFormat(row?.discount_amt || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
      },
      {
        field: 'round_off', headerName: `${"Round Off"} (${CurrencySymbol})`, flex: 0.0769, type: "number",
        renderCell: ({ row }) => (<Box>{AmountFormat(row?.round_off || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
      },
      {
        field: 'net_amount', headerName: `${"Net Amount"} (${CurrencySymbol})`, flex: 0.0769, type: "number",
        renderCell: ({ row }) => (<Box>{AmountFormat(row?.net_amount || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
      },
    ]
    return (
      <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>
        <Box component={'div'} className='eMed_Pharma_rts_header'>
          <Box component={'div'} flex={0.8} display={'flex'}>
          {/* {AmountsCard("Net Amount", this.state.netAmount, false, "white", false, null, 'rpt_saleOver_net_amt')} */}
          <Box sx={{cursor: 'pointer'}} onClick={() => { this.setState({ showBox: !this.state.showBox,  }) }}>{AmountsCardWithCount(t("Net Amount"), this.state.netAmount, false, this.state.showBox ? Colors.Primary : "white", true, this.state.showBox ? "white" : Colors.Primary, 'rec_recev_amt',"10vw",this.state.rowCount,true)}</Box>
          {AmountsCardWithCount("In Patient", this.state.ipNetAmt, false, "white", false, null, 'rpt_saleOver_net_amt',"10vw",this.state.inPatientCount,true)}
          {AmountsCardWithCount("Out Patient", this.state.opNetAmt, false, "white", false, null, 'rpt_saleOver_net_amt',"10vw",this.state.outPatientCount,true)}
          {AmountsCardWithCount("OTC", this.state.OtcNetAmt, false, "white", false, null, 'rpt_saleOver_net_amt',"10vw", this.state.Otccount, true)}
            <Box component={'div'} sx={{ width: '30vw', overflow: 'scroll', marginLeft: '1vw', height: "5.5vw", display: 'flex', alignItems: 'center' }} >
              {this.state.showBox ?
                <Box sx={{ display: 'flex', flexDirection: "row" }}>
                  <Grow in={this.state.showBox} style={{ transformOrigin: '0 0 0' }}
                    {...(this.state.showBox ? { timeout: 500 } : {})}>{AmountsCard("Taxable Amount", this.state.totalAmount, false,"white",false,Colors.Primary,'rpt_saleOver_tax_amt',"10vw")}</Grow>
                  <Grow in={this.state.showBox} style={{ transformOrigin: '0 0 0' }}
                    {...(this.state.showBox ? { timeout: 1000 } : {})}>{AmountsCard("CGST", this.state.cgst, false,"white",false,Colors.Primary,'rpt_saleOver_cgst',"10vw")}</Grow>
                  <Grow in={this.state.showBox} style={{ transformOrigin: '0 0 0' }}
                    {...(this.state.showBox ? { timeout: 1000 } : {})}>{AmountsCard("SGST", this.state.sgst, false,"white",false,Colors.Primary,'rpt_saleOver_sgst',"10vw")}</Grow>
                  <Grow in={this.state.showBox} style={{ transformOrigin: '0 0 0' }}
                    {...(this.state.showBox ? { timeout: 1500 } : {})}>{AmountsCard("Discount Amount", this.state.discountAmount, false, "white", false, Colors.Primary, 'rpt_saleOver_dis_amt', "10vw")}</Grow>
                  <Grow in={this.state.showBox} style={{ transformOrigin: '0 0 0' }}
                    {...(this.state.showBox ? { timeout: 1500 } : {})}>{AmountsCard("Round Off", this.state.roundOff, false, "white", false, Colors.Primary, 'rpt_saleOver_rnd_amt', "10vw")}</Grow>
                </Box> : null}
            </Box>
          </Box>
          <Box flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5vw'}>
            <Button emed_tid = "rpt_saleOver_flt" className='eMed_Pharma_rts_btn' onClick={() => this.setState({ filterOpen: true })}>
              <Box component={'img'} src={ImagePaths.Filter.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
            </Button>
            <Button emed_tid = "rpt_saleOver_print" className='eMed_Pharma_rts_btn' disabled={this.state.disableBtn} onClick={()=>{this.getSalesoverviewPrint()}}>
              <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt='print' height={'2.3vw'} width={'2.3vw'} />
            </Button>
            <Button emed_tid = "rpt_saleOver_export" className='eMed_Pharma_rts_btn' disabled={this.state.disableBtn} onClick={() => this.getSalesoverviewExcel()}>
              <Box component={'img'} src={ImagePaths.ExportIcon.default} alt='export' height={'2.3vw'} width={'2.3vw'} />
            </Button>
          </Box>
        </Box>
        <Box component={'div'} className='eMed_Pharma_rts_tbl_card'>
          <DataGrid
            className='eMed_Pharma_table'
            rows={this.state.salesOverviewData}
            columns={columns}
            page={this.state.page}
            pageSize={this.state.pageSize}
            getRowId={(row) => row['sno']}
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            components={{
              Toolbar: this.gridToolBar,
              NoRowsOverlay: () => (
                <Stack className='eMed_conf_nodata'>
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
            initialState={{
              columns:{
                columnVisibilityModel:{
                  state:false,
                  igst:false
                }
              }
            }}
            rowCount={this.state.rowCount}
            onPageChange={(newPage) => { this.setState({ page: newPage }, () => this.getSalesOverviewReportData()) }}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize }, () => this.getSalesOverviewReportData())}
            rowsPerPageOptions={[10, 20, 30]}
            headerHeight={40}
            disableSelectionOnClick
            pagination
            paginationMode='server'
            loading= {this.state.isLoader}
          />
        </Box>

        <Drawer
          anchor={'right'}
          open={this.state.filterOpen}
          ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
        >
          <PharmaFilter pageName={this.state.pageName} filterDatas={this.state.filterDatas} closeFilter={this.ClosePopUp.bind(this)} filteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)} calDoc={true}/>
        </Drawer>

        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}

export default withTranslation()(SalesOverview)