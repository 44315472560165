import React from "react";
import "./charts.css";
import { Box, Paper, Typography, Tooltip } from "@mui/material";
import { ImagePaths } from "../../../Utility/ImagePaths";
import { withTranslation } from "react-i18next";

const CountCards = ({ details, t }) => {
    return (
        <Paper className="eMed_revenueCard_div" elevation={2}>
            <Box className="eMed_revenueCard_imgDiv">
                <img
                    className="emedhub_revenueCard_img"
                    src={details?.image || ""}
                    alt={details?.title}
                />
                <div style={{ marginLeft: "0.2vw" }}>
                    <Typography id="eMed_revenueCard_headTxt">
                        {details?.title?.length > 16 ? (
                            <Tooltip placement="top" title={details?.title} arrow>
                                <div id="eMed_revenueCard_headTxt">
                                    {details?.title?.slice(0, 15) + "..."}
                                </div>
                            </Tooltip>
                        ) : details.title || ""}
                    </Typography>

                    <Typography id="eMed_revenueCard_Txt">
                        {details?.branchName?.length > 18 ? (
                            <Tooltip placement="top" title={details?.branchName} arrow>
                                <div id="eMed_revenueCard_Txt">
                                    {details?.branchName?.slice(0, 18) + "..."}
                                </div>
                            </Tooltip>
                        ) : details?.branchName || "" }
                    </Typography>
                </div>
            </Box>
            <Box className="eMed_revenueCard_countDiv">
                <Typography id="eMed_revenueCard_amount">
                    {details?.count?.toString()?.length > 10 ? (
                        <Tooltip
                            placement="top"
                            title={details?.count}
                            arrow
                        >
                            <div id="eMed_revenueCard_amount">
                                {details?.count?.toString()?.slice(0, 10) + "..."}
                            </div>
                        </Tooltip>
                    ) : details?.count || 0}
                </Typography>
                {typeof details?.ProfitValue === "number" ? (
                    <Box>
                        <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                            <img
                                style={{ height: "0.9vw", width: "0.9vw" }}
                                className="eMed_revenueCard_profit"
                                src={
                                    details?.ProfitValue < 0
                                        ? ImagePaths.LossIcon.default
                                        : ImagePaths.ProfitIcon.default
                                }
                            />
                            <Typography
                                fontSize={"0.8vw"}
                                fontWeight={600}
                                sx={{
                                    color: details?.ProfitValue < 0 ? "#B8170D" : "#007A64",
                                }}
                            >
                                {`Count : ${Math.abs(details.ProfitValue)}`}
                            </Typography>
                        </Box>
                        <Typography id="eMed_revenueCard_comparTlt">
                            {`vs Compared Date`}
                        </Typography>
                    </Box>
                ) : null}
            </Box>
        </Paper>
    );
};

export default withTranslation()(CountCards);