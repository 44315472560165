import { Autocomplete, Box, Button, Drawer, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import '../reports.css';
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards'
import { ImagePaths } from '../../../../Utility/ImagePaths';
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { CommonGridToolBarWithFilterText, CommonTimeRangePicker } from "../../../../Components/Common Components/CommonComponents";
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { AmountFormat, formatDate, timeOnlyConvert } from "../../../../Components/CommonFunctions/CommonFunctions";
import { DateTime } from 'luxon';
import { CurrencySymbol } from '../../../../Utility/Constants';
import { Colors } from '../../../../Styles/Colors';
import { localGetItem } from '../../../../Utility/Services/CacheProviderService';


class ConsultWiseReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            doctorName: [],
            doctorNameList: localGetItem("DoctorsList") === null ? [] : JSON.parse(localGetItem("DoctorsList")),
            labTestData: [],
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: 1,
            ConsultationData: [],
            serviceTotalAmount: "",
            FilteredFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilteredToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilterDatas: {
                "startTime": null,
                "endTime": null,
                "onError": false,
            },
            timeError: false,
            selectedTestList: [],
            isBillDoctor: false,
        }
    }

    componentDidMount() {
        const userData = JSON.parse(localGetItem("loggedInUserInfo"));
        const isBillDoctor = userData?.bill_doctor_enabled || false;
        this.setState({ isBillDoctor: isBillDoctor })
        this.getConsultionData()
        if (this.props.fromLab) {
            this.getTestListData()
        } else if (this.props.fromRadiology) {
            this.getDoctorsList()
        }
    }

    getTestListData = () => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_TEST_CONFIG_GET)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            labTestData: response.data.data ? response.data.data : []
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getDoctorsList() {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME).
                then((response) => {
                    if (response.data.status === "success") {
                        let doctor_list = response.data.data ? response.data.data.filter((item) => (item.specialization_active === true)) : []
                        this.setState({ doctorNameList: doctor_list })
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    onChangeHandler = (e) => {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        }, () => {
            this.state.FilterDatas.endTime = null
            this.setState({
                FilterDatas: this.state.FilterDatas
            })
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    getConsultionData = () => {
        try {
            this.LoaderFunction(true)
            let doctorId = [];
            let testIds = [];
            this.state.doctorName.forEach(element => doctorId.push(element.doctor_id))
            var { FilterDatas } = this.state
            let start_time = FilterDatas.startTime ? new Date(FilterDatas.startTime) : ''
            let end_time = FilterDatas.endTime ? new Date(FilterDatas.endTime) : ''
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            this.state.selectedTestList?.forEach(element => testIds?.push(element?.test_name))
            let url = Serviceurls.FO_REPORTS_CONSULT_WISE + `?doctor_id=${doctorId}&from_date=${this.state.fromDate}&to_date=${this.state.toDate}&start_time=${setStartTime}&end_time=${setEndTime}`
            if (this.props.fromLab) {
                url = Serviceurls.LAB_CONSULTANT_WISE_REPORT + `?doctor_id=${doctorId}&from_date=${this.state.fromDate}&to_date=${this.state.toDate}&test_name=${testIds}&patient_name=${this.state.patientName ? this.state.patientName : ''}`
            } else if (this.props.fromRadiology) {
                url = Serviceurls.RAD_CONSULTANT_WISE_REPORT + `?doctor_id=${doctorId}&from_date=${this.state.fromDate}&to_date=${this.state.toDate}&patient_name=${this.state.patientName ? this.state.patientName : ''}`
            }
            RestAPIService.getAll(url).
                then((response) => {
                    if (response.data.status === "success") {
                        if (this.props.fromLab || this.props.fromRadiology) {
                            this.setState({
                                ConsultationData: response.data.data?.results ? response.data.data?.results : [],
                                serviceTotalAmount: response.data.data?.total_amount,
                            }, () => { this.LoaderFunction(false) })
                        } else {
                            this.setState({
                                ConsultationData: response.data.data,
                                serviceTotalAmount: response.data.total_amount,
                                FilteredFromDate: this.state.fromDate,
                                FilteredToDate: this.state.toDate,
                            }, () => { this.LoaderFunction(false) })
                        }
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }
    printReports = () => {
        try {
            let doctorId = [];
            let testIds = [];
            this.state.doctorName.forEach(element => doctorId.push(element.doctor_id))
            var { FilterDatas } = this.state
            let start_time = FilterDatas.startTime ? new Date(FilterDatas.startTime) : ''
            let end_time = FilterDatas.endTime ? new Date(FilterDatas.endTime) : ''
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            this.state.selectedTestList?.forEach(element => testIds?.push(element?.test_name))
            let url = Serviceurls.FO_REPORTS_CONSULT_WISE + `?doctor_id=${doctorId}&from_date=${this.state.fromDate}&to_date=${this.state.toDate}&export_type=pdf&start_time=${setStartTime}&end_time=${setEndTime}`
            if (this.props.fromLab) {
                url = Serviceurls.LAB_CONSULTANT_WISE_REPORT + `?doctor_id=${doctorId}&from_date=${this.state.fromDate}&to_date=${this.state.toDate}&test_name=${testIds}&patient_name=${this.state.patientName ? this.state.patientName : ''}&export_type=pdf`
            } else if (this.props.fromRadiology) {
                url = Serviceurls.RAD_CONSULTANT_WISE_REPORT + `?doctor_id=${doctorId}&from_date=${this.state.fromDate}&to_date=${this.state.toDate}&patient_name=${this.state.patientName ? this.state.patientName : ''}&export_type=pdf`
            }
            RestAPIService.getAll(url)
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }


    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }

    selectedTime = (start, end, error) => {
        this.state.FilterDatas.startTime = start
        this.state.FilterDatas.endTime = end
        this.setState({ FilterDatas: this.state.FilterDatas, timeError: error })
    }

    renderFilterScreen = () => {
        const { t } = this.props
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
                    <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)}
                            HideAllMenu={true} />
                    </Box>
                    {(this.props.fromLab || this.props.fromRadiology) ?
                        <Box className='eMed_rts_dropdown_lab_new'>
                            <TextField
                                size='small'
                                sx={{ width: '22vw', mb: '1vw' }}
                                value={this.state.patientName}
                                name="patientName"
                                onChange={(e) => this.onChangeHandler(e, 'patientName')}
                                label='Patient Name'
                                autoComplete='off'
                            />
                            <Autocomplete
                                sx={{ mb: '1vw' }}
                                multiple
                                limitTags={1}
                                size='small'
                                options={this.state.doctorNameList}
                                getOptionLabel={(option) => (option?.doctor_name)}
                                value={this.state.doctorName}
                                onChange={(event, newValue) => {
                                    this.setState({ doctorName: newValue })
                                }}
                                renderOption={(props, option) => (
                                    <li {...props} key={option?.doctor_id}>
                                        {option?.doctor_name}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={('Select Doctor Name')}
                                    />
                                )}
                            />
                            {this.props.fromLab ?
                                <Autocomplete
                                    multiple
                                    limitTags={1}
                                    sx={{ mb: '1vw' }}
                                    size='small'
                                    disableCloseOnSelect
                                    disablePortal
                                    options={this.state.labTestData ? this.state.labTestData : []}
                                    getOptionLabel={(option) => (typeof option === "string" ? option : option?.test_name || "")}
                                    value={this.state.selectedTestList}
                                    onChange={(event, newValue) => {
                                        this.setState({ selectedTestList: newValue })
                                    }}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option?.id}>
                                            {option?.test_name}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={('Select Test Name')}
                                        />
                                    )}
                                /> : null}
                        </Box>
                        :
                        <Box sx={{ width: '22vw' }}>
                            <CommonTimeRangePicker
                                startTime={this.state.FilterDatas?.startTime}
                                endTime={this.state.FilterDatas?.endTime}
                                selectedTime={this.selectedTime.bind(this)}
                                FromDate={this.state.fromDate}
                                ToDate={this.state.toDate}
                                onError={this.state.FilterDatas.onError}
                            />
                        </Box>
                    }
                </Box>
                <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"
                        onClick={() => {
                            this.setState({
                                doctorName: [],
                                serviceName: [],
                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                dateIndex: 1,
                                FilterDatas: {
                                    "startTime": null,
                                    "endTime": null,
                                    "onError": false,
                                },
                                timeError: false,
                                patientName: '',
                                selectedTestList: [],
                            },
                                () => this.getConsultionData())
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small"
                        disabled={this.state.FilterDatas.startTime ? (this.state.FilterDatas.startTime && this.state.FilterDatas.endTime && this.state.timeError != true) ? false : true : false}
                        onClick={() => {
                            this.getConsultionData()
                            this.setState({ FilterOpen: false })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }
    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }
    gridToolBar = () => {
        let start_time = this.state.FilterDatas.startTime ? new Date(this.state.FilterDatas.startTime) : ''
        let end_time = this.state.FilterDatas.endTime ? new Date(this.state.FilterDatas.endTime) : ''
        let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.fromDate) },
            { label: "To Date", value: formatDate(this.state.toDate) },
        ]
        if (this.state.FilterDatas.startTime !== null && this.state.FilterDatas.endTime !== null) {
            TextArray.push(
                { label: "Start Time", value: timeOnlyConvert(setStartTime) },
                { label: "End Time", value: timeOnlyConvert(setEndTime) }
            )
        }
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
            </Box>

        )
    }
    render() {
        this.state.ConsultationData.forEach((element, index) => element.sno = index + 1)
        const { t } = this.props
        const columns = [
            {
                field: "sno", headerName: t("S No"), flex: 0.1, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.sno ? params?.row?.sno : "-"}</Box>)
            },
            {
                field: "service_name", headerName: t("DoctorName"), flex: 0.45,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.service_name?.length > 70 ?
                    <Tooltip placement='top' title={params?.row?.service_name} arrow><div>{params?.row?.service_name.slice(0, 70) + "..."}</div></Tooltip>
                    : params?.row?.service_name ? params?.row?.service_name : "-"}</Box>)
            },
            {
                field: "count", headerName: t("Bill Count"), flex: 0.2, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.count ? params?.row?.count : "-"}</Box>)
            },
            {
                field: "discounted_amount", headerName: t(`Total (${CurrencySymbol})`), flex: 0.25, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.discounted_amount ? AmountFormat(params?.row?.discounted_amount).replace(`${CurrencySymbol}`, "") : "0.00"}</Box>)
            },
        ]

        const labColumns = [
            {
                field: 'invoice_date', headerName: 'Invoice Date', flex: 0.143,
                renderCell: (params) => <Box>{params?.row?.invoice_date ? params?.row?.invoice_date : '-'}</Box>
            },
            {
                field: 'invoice_number', headerName: 'Invoice No', flex: 0.143,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.invoice_number ? <Tooltip placement='top' title={params?.row?.invoice_number} arrow>
                    <Box>{params?.row?.invoice_number ?
                        "..." + params?.row?.invoice_number.slice(-10) : params?.row?.invoice_number}</Box>
                </Tooltip> : "-"}</Box>)
            },
            {
                field: 'patient_name', headerName: 'Patient Details', flex: 0.14,
                renderCell: (params) => {
                    let patientDetails = `${params?.row?.patient_uhid ? params?.row?.patient_uhid : '-'} | ${params?.row?.mobile_number ? params?.row?.mobile_number : '-'}`
                    if (this.props.fromRadiology) {
                        patientDetails = `${params?.row?.patient_account_number ? params?.row?.patient_account_number : '-'} | ${params?.row?.patients_mobile ? params?.row?.patients_mobile : '-'}`
                    }
                    return (
                        <Box component={'div'} emed_tid={'emed_lab_pat_dtls'}>
                            <Typography fontSize={'0.9vw'} fontWeight={600}>{params?.row?.patient_name ?
                                params?.row?.patient_name?.length > 15 ?
                                    <Tooltip placement='top' title={params?.row?.patient_name}><div style={{ fontWeight: 600, fontSize: '0.9vw' }}>{params?.row?.patient_name?.slice(0, 15) + '...' + (params?.row?.patient_age ? params?.row?.patient_age : '-')}</div></Tooltip> :
                                    `${params?.row?.patient_name} ${params?.row?.patient_age ? params?.row?.patient_age : '-'}` : '-'}</Typography>
                            <Typography fontSize={'0.8vw'} color={Colors.grayShade}>{patientDetails}</Typography>
                        </Box>
                    )
                }
            },
            {
                field: 'ip_op_number', headerName: 'IP/OP No', flex: 0.143,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.ip_op_number?.length > 20 ? <Tooltip placement='top' title={params?.row?.ip_op_number} arrow>
                    <Box>{params?.row?.ip_op_number ? params?.row?.ip_op_number.slice(0, 17) + "..." : params?.row?.ip_op_number}</Box>
                </Tooltip> : params?.row?.ip_op_number ? params?.row?.ip_op_number : "-"}</Box>)
            },
            {
                field: 'doctor_name', headerName: 'Consultant Name', flex: 0.143,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.doctor_name?.length > 20 ?
                    <Tooltip placement='top' title={params?.row?.doctor_name} arrow><div>{params?.row?.doctor_name?.slice(0, 20) + "..."}</div></Tooltip>
                    : params?.row?.doctor_name ? params?.row?.doctor_name : "-"}</Box>)

            },
            {
                field: 'test_name', headerName: 'Test Name', flex: 0.143,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.test_name?.length > 20 ?
                    <Tooltip placement='top' title={params?.row?.test_name} arrow><div>{params?.row?.test_name?.slice(0, 20) + "..."}</div></Tooltip>
                    : params?.row?.test_name ? params?.row?.test_name : '-'}</Box>)
            },
            {
                field: 'amount', headerName: 'Amount', flex: 0.10, type: "number",
                renderCell: (params) => (<Box component={'div'}>{AmountFormat(params?.row?.amount || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            },
        ]

        const billedDoc = {
            field: "bill_doctor_name", headerName: t("Billed Doctor"), flex: 0.143,
            renderCell: ({ row }) => {
                const billDoctor = row?.bill_doctor_name?.trim();
                return (<Box component={'div'}>{billDoctor?.length > 20 ?
                <Tooltip placement='top' title={billDoctor} arrow><div>{billDoctor?.slice(0, 20) + "..."}</div></Tooltip>
                : billDoctor ? billDoctor : "-"}</Box>)}
        }

        if (this.state.isBillDoctor) {
            labColumns.splice(5, 0, billedDoc)
        }

        if (this.props.fromRadiology) {
            let fields = [
                {
                    field: 'service_name', headerName: 'Service Name', flex: 0.10,
                    renderCell: (params) => (<Box component={'div'}>{params?.row?.service_name?.length > 15 ?
                        <Tooltip placement='top' title={params?.row?.service_name} arrow><div>{params?.row?.service_name?.slice(0, 13) + "..."}</div></Tooltip>
                        : params?.row?.service_name ? params?.row?.service_name : '-'}</Box>)
                },
                {
                    field: 'service_type', headerName: 'Service Type', flex: 0.10,
                    renderCell: (params) => (<Box component={'div'}>{params?.row?.service_type?.length > 15 ?
                        <Tooltip placement='top' title={params?.row?.service_type} arrow><div>{params?.row?.service_type?.slice(0, 13) + "..."}</div></Tooltip>
                        : params?.row?.service_type ? params?.row?.service_type : '-'}</Box>)
                },
            ]
            labColumns?.splice(5, 1, ...fields)
            if (this.state.isBillDoctor) {
                labColumns.splice(5, 0, billedDoc)
            }
        }

        return (
            <Box component={'div'} className='eMed_rts_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'>
                        {AmountsCard(t("TotalAmount"), this.state.serviceTotalAmount)}
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container'>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </Button>
                        </Box>
                        <Button className='eMed_usrconf_btn' onClick={() => { this.printReports() }}>
                            <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                        </Button>
                        {/* <Button className='eMed_usrconf_btn'>
                            <img src={ImagePaths.LabUploadButton.default} alt="upload" className='eMed_action_img' />
                        </Button> */}
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_rts_table'>
                    <DataGrid
                        rows={this.state.ConsultationData}
                        columns={(this.props.fromLab || this.props.fromRadiology) ? labColumns : columns}
                        getRowId={(row) => row['sno']}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                    />
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}


export default withTranslation()(ConsultWiseReport)
