import { Box, IconButton, Stack, Button, Tooltip } from '@mui/material'
import React, { Component } from 'react'
import { ImagePaths } from '../../../Utility/ImagePaths'
import { DataGrid } from '@mui/x-data-grid'
import CommonGridHeader from '../../../Components/Common Components/CommonComponents'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import { Colors } from '../../../Styles/Colors'
import { withTranslation } from 'react-i18next'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions'

class PharmaInternalSalesWantedList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      wantedListData: [],
      page: 0,
      pageSize: 10,
      pharmacy_id: null,
      editAccess : false,

    }
  }
  componentDidMount = () => {
    this.getPharmaInternalSalesWantedList()
    let editAccess = CheckAccessFunc("Pharmacy", "Internal Sales", "WantedList", null, "Tab")?.editAccess
    this.setState({editAccess: editAccess })
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  getPharmaInternalSalesWantedList = () => {
    try {
      this.LoaderFunction(true);
      RestAPIService.getAll(Serviceurls.INTERNALSALES_WANTEDLIST).
        then((response) => {
          if (response?.data?.status === "success") {
            this.LoaderFunction(false);
            this.setState({
              wantedListData: response?.data?.data ? response?.data?.data : []

            })
          }
          else {
            this.LoaderFunction(false);
            this.errorMessage(response?.data?.message)
          }
        }).catch((error) => {
          this.LoaderFunction(false);
          this.errorMessage(error?.message)
        })
    }
    catch (e) {
      this.errorMessage(e?.message)
    }
  }
 
  internalSalesWantedlistPrint = () => {
    try {
      RestAPIService.getAll(Serviceurls.INTERNALSALES_WANTEDLIST + `?export=pdf`)
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }
  
  internalSalesWantedlistexport = () => {
    const { t } = this.props;
    try {
        this.setState({ disableBtn: true })
        RestAPIService.excelGet(`${Serviceurls.INTERNALSALES_WANTEDLIST}?&export=${"excel"}`).
            then((response) => {
                if (response.data) {
                    var pom = document.createElement('a');
                    var csvContent = response.data; //here we load our csv data 

                    let filename = response.headers["content-disposition"].split("filename=")[1]

                    var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
                    var url = URL.createObjectURL(blob);
                    pom.href = url;
                    pom.setAttribute('download', filename ? filename : 'registry.xlsx');
                    pom.click();
                    this.setState({ disableBtn: false })
                }
            }).catch((error) => {
                this.errorMessage(error.response.data?.message)
                this.setState({ disableBtn: false })
            })
    } catch (error) {
        this.errorMessage(t("PrintError"))
        this.setState({ disableBtn: false })
    }
}

  render() {
    const { t } = this.props
    this.state.wantedListData?.forEach((element, index) => element.sno = index + 1)
    const columns = [
      {
        field: "sno", sortable: false, flex: 0.03, headerName: t("SNo"),
        renderCell: (params) => (<Box component={'div'}>{params?.row?.sno ? params?.row?.sno : "-"}</Box>)
      },
      {
        field: "pharmacy_name", flex: 0.166, headerName: t("Pharmacy Name"),
        renderCell: ({ row }) => (<div>{row?.pharmacy_name?.length > 20 ?
          <Tooltip placement="top" title={row?.pharmacy_name} arrow><div>{row?.pharmacy_name.slice(0, 20) + "..."}</div></Tooltip>
          : row?.pharmacy_name ? row?.pharmacy_name : "-"}</div>)
      },
      {
        field: "branch_name", flex: 0.166, headerName: t("Branch Name"),
        renderCell: ({ row }) => (<div>{row?.branch_name?.length > 20 ?
          <Tooltip placement="top" title={row?.branch_name} arrow><div>{row?.branch_name.slice(0, 20) + "..."}</div></Tooltip>
          : row?.branch_name ? row?.branch_name : "-"}</div>)
      },
      {
        field: "drug_count", flex: 0.166, headerName: ("No of Drugs"), headerAlign: "right",  type:'number',
        renderCell: (params) => (<Box component={'div'}>{params?.row?.drug_count ? params?.row?.drug_count : "-"}</Box>)
      },
      {
        field: "action", flex: 0.07, headerName: ("Action"), headerAlign: "center", align: "center", sortable: false,
        renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
          <Tooltip title={("View Wanted List")} placement="top" onClick={() => { this.props.history.push({ pathname: '/PharmacyInternalSales/WantedList/POEntry', state: { pharmacy_id: params?.row?.pharmacy_id, OnlyView: true, branch_name: params?.row?.branch_name, pharmacy_name: params?.row?.pharmacy_name } }) }} arrow>
            <Button emed_tid="vnd_view" disabled={!this.state.editAccess} className='eMed_usrconf_btn'>
              <img src={this.state.editAccess ?ImagePaths.LabViewButton.default : ImagePaths.ViewButtonOff.default  } alt="print" className='eMed_action_img' />
            </Button>
          </Tooltip>
        </Box>
      }

    ]
    return (
      <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>

        <div className='eMed_Purchase_Table_top'  >
          <Tooltip title="Print" placement='top' arrow>
            <Button emed_tid="vnd_print" className='eMed_usrconf_btn' disabled={this.state.disableBtn || !this.state.editAccess} onClick={() => this.internalSalesWantedlistPrint()}>
              <img src={this.state.editAccess ? ImagePaths.LabPrintIcon.default : ImagePaths.LabPrintIconOff.default } alt="print" className='eMed_action_img' />
            </Button></Tooltip>
          <Tooltip placement="top" title={t("Export")} arrow>
            <Button emed_tid="vnd_export" className='eMed_usrconf_btn' size="small" disabled={this.state.disableBtn || !this.state.editAccess} onClick={() => this.internalSalesWantedlistexport()}>
              <img src={this.state.editAccess ?ImagePaths.LabUploadButton.default : ImagePaths.DisableUpload.default } alt='upload' className='eMed_action_img' />
            </Button></Tooltip>
        </div>


        <Box component={'div'} className='eMed_Pharma_rts_tbl_card'>
          <DataGrid
            className="eMed_usrConf_tbl"
            rows={this.state.wantedListData}
            columns={columns}
            getRowId={(row) => row['sno']}
            page={this.state.page}
            pageSize={this.state.pageSize}
            onPageChange={(newPage) => this.setState({ page: newPage })}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            components={{
              Toolbar: CommonGridHeader.CommonToolBar,
              NoRowsOverlay: () => (
                <Stack className='eMed_conf_nodata'>
                  {("NoRecordsFound")}
                </Stack>
              )
            }}
            rowsPerPageOptions={[10, 20, 30]}
            pagination
            headerHeight={40}
            disableSelectionOnClick
            loading={this.state.isLoader}
          />

        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}

      </Box>


    )
  }
}
export default withTranslation()(PharmaInternalSalesWantedList)
