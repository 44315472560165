import React, { Component } from "react";
import ShowComponents from "./ShowComponent";
import { Colors } from "../../../../Styles/Colors";
import { Box, Button, Chip, Modal, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import { Bucket_Name, FUNDUS_OPTION, FundusImage } from "../../../../Utility/Constants";
import { SketchField, Tools } from "react-sketch-draw";
import CreateIcon from "@material-ui/icons/Create";
import LineIcon from "@material-ui/icons/BorderColor";
import RectIcon from "@material-ui/icons/CropLandscape";
import CircleIcon from "@material-ui/icons/RadioButtonUnchecked";
import RestAPIService from "../../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../../Utility/API/Serviceurls";
import AddIcon from "@material-ui/icons/AddOutlined";
import SprayIcon from "@material-ui/icons/BlurCircular";
import UndoIcon from "@material-ui/icons/UndoOutlined";
import RedoIcon from "@material-ui/icons/RedoOutlined";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import { ImagePaths } from "../../../../Utility/ImagePaths";
import SaveIcon from "@material-ui/icons/Save";
import ToastMsg from "../../../../Components/ToastMsg/ToastMsg";
import { DeletePopup } from "../../../../Components/Common Components/CommonComponents";
import Loader from "../../../../Components/Loader"

var AWS = require("aws-sdk");
var s3 = new AWS.S3({
    region: "ap-south-1",
});

const styles = {
    control: {
        width: "24px",
        height: "24px",
    },

    control_space_1: {
        width: "24px",
    },
}
export default class DocFundus extends Component {

    constructor(props) {
        super(props)
        this.state = {
            patientDetails: {},
            clinicId: this.props.clinicId,
            patientId: this.props.patientId,
            appointmentId: this.props.appointmentId,
            isLocked: this.props.isLocked,
            lineWidth: 3,
            lineColor: "#000000",
            fillColor: "red",
            backgroundColor: "#FFF",
            shadowWidth: 0,
            shadowOffset: 0,
            tool: Tools.Pencil,
            enableRemoveSelected: false,
            fillWithColor: false,
            fillWithBackgroundColor: false,
            drawings: [],
            canUndo: false,
            canRedo: false,
            controlledSize: false,
            sketchWidth: 700, // 600,
            sketchHeight: 300, // 600,
            stretched: true,
            stretchedX: false,
            stretchedY: false,
            originX: "left",
            originY: "top",
            imageUrl: "",
            expandTools: false,
            expandControls: false,
            expandColors: false,
            expandBack: false,
            expandImages: false,
            expandControlled: false,
            text: "",
            enableCopyPaste: false,
            controlledValue: null,
            templateUrl: FundusImage,
            data: [],
            background_image: "",
            selectedColor: "#000000",
            value: "Two",
            LeftEye: {
                Media: "",
                Optic: "",
                C_D_Ratio: "",
                Macula: "",
            },
            RightEye: {
                Media: "",
                Optic: "",
                C_D_Ratio: "",
                Macula: "",
            },
            id_LE: "",
            id_RE: "",
            leftData: [],
            rightData: [],
            disableLE: false,
            disableRE: false,
            rightEye: false,
            lefytEye: false,
            normal: false,
            isDeleteCliked: false,
            FundusData: [],
            isLoader: false,
        }
    }

    apiCalls = () => {
        this._getImage();
        this.PrevFundusDiag();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLocked !== this.props.isLocked) {
            this.setState({ isLocked: this.props.isLocked })
        }
    }

    _onSketchChange = () => {
        let prev = this.state.canUndo;
        let now = this._sketch.canUndo();
        if (prev !== now) {
            this.setState({ canUndo: now });
        }
    };

    popupClose = () => {
        this.setState({
            isDeleteCliked: false
        })
    }

    PrevFundusDiag = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_FUNDUS_PREVIOUS_DIAGRAM + `?patient_id=${this.state.patientId}&appointment_id=${this.state.appointmentId}&doctor_id=${this.props.doctorID}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            FundusData: response?.data?.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    _getImage = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_OPT_FUNDUS_IMG + `?appointment_id=${this.state.appointmentId}&patient_id=${this.state.patientId}`)
                .then((response => {
                    if (response?.data?.status === 'success') {
                        this.setState({ isLoader: false })
                        var fundus = response.data.data ? response.data.data : [];
                        var field = this.state;

                        try {
                            field["data"] = JSON.parse(JSON.stringify(fundus));
                            this.setState({ field });

                            if (fundus.fundus_image_key) {
                                this._setBackgroundImage(fundus.fundus_image_key);
                            } else {
                                field["data"] = [];
                                if (FundusImage) {
                                    this._sketch.setBackgroundFromDataUrl(FundusImage);
                                }
                                this.setState({
                                    field,
                                });
                            }
                        } catch (e) {
                            field["data"] = [];
                            if (FundusImage) {
                                this._sketch.setBackgroundFromDataUrl();
                            }
                            this.setState({
                                field,
                            });
                        }
                    }
                })).catch((error) => {
                    this.errorMessage(error.message)
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    CopyPreviousData = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_FUNDUS_PREVIOUS_DIAGRAM + `?patient_id=${this.state.patientId}&appointment_id=${this.state.appointmentId}&doctor_id=${this.props.doctorID}`)
                .then((response => {
                    this.setState({ isLoader: false })
                    if (response?.data?.status === 'success') {
                        var fundus = response.data.data ? response.data.data : [];
                        var field = this.state;
                        try {
                            field["data"] = JSON.parse(JSON.stringify(fundus));
                            this.setState({ field });

                            if (fundus.fundus_image_key) {
                                this._setBackgroundImage(fundus.fundus_image_key);
                                setTimeout(() => { this._save() }, 1500)
                            } else {
                                field["data"] = [];
                                if (FundusImage) {
                                    this._sketch.setBackgroundFromDataUrl(FundusImage);
                                }
                                this.setState({
                                    field,
                                });
                            }
                        } catch (e) {
                            field["data"] = [];
                            if (FundusImage) {
                                this._sketch.setBackgroundFromDataUrl();
                            }
                            this.setState({
                                field,
                            });
                        }
                    }
                })).catch((error) => {
                    this.errorMessage(error.message)
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    _save = () => {
        var { drawings } = this.state;
        try {
            drawings.push(this._sketch.toDataURL());
            var lengths = (drawings.length) - 1
            if (drawings.length > 0) {
                let data = new FormData();
                data.append('appointment_id', this.state.appointmentId);
                data.append('patient_id', this.state.patientId);
                data.append('report_photo', this.dataURItoBlob(drawings[lengths]));
                data.append('image_json', (this.state.iscopy ? this.state.FundusData.image_json : JSON.stringify(this._sketch)));

                RestAPIService.create(data, Serviceurls.DOC_OPT_FUNDUS_IMG)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.successMessage(response?.data?.message)
                            this.setState({
                                isDeleteCliked: false, iscopy: false
                            }, () => {
                                // if (this.state.iscopy) {
                                //     this.CopyPreviousData()
                                // } else {
                                this._getImage()
                                // }
                            })
                        }
                    }).catch((error) => {
                        this.errorMessage(error.message)
                    })
            }
        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    _addText = () => {
        this.setState({ tool: Tools.Select });
        this._sketch.addText(this.state.text);
    };


    async _setBackgroundImage(doc_key) {
        try {
            var self = this;
            const response = await RestAPIService.getAllTwo(Serviceurls.AWS_URL_GET + "?doc_key=" + encodeURIComponent(doc_key));
    
            if (response.data.status === "success") {
                const Data = response.data;
                self.setState({ background_image: Data?.data });
                if (Data?.data) {
                    self._sketch.setBackgroundFromDataUrl(
                        Data.data
                    );
                }
            }
        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: "Error fetching S3 URL"
            });
        }
    }

    dataURItoBlob = (dataURI) => {
        var byteString = atob(dataURI.split(",")[1]);
        var mimeString = dataURI
            .split(",")[0]
            .split(":")[1]
            .split(";")[0];

        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], { type: mimeString });
    };

    _undo = () => {
        this._sketch.undo();
        this.setState({
            canUndo: this._sketch.canUndo(),
            canRedo: this._sketch.canRedo(),
        });
    };

    _redo = () => {
        this._sketch.redo();
        this.setState({
            canUndo: this._sketch.canUndo(),
            canRedo: this._sketch.canRedo(),
        });
    };

    _clear = () => {
        this._sketch.clear();
        this._sketch.setBackgroundFromDataUrl(this.state.templateUrl);
        this.setState({
            controlledValue: null,
            backgroundColor: "#FFF",
            fillWithBackgroundColor: false,
            canUndo: this._sketch.canUndo(),
            canRedo: this._sketch.canRedo(),
            isDeleteCliked: false,
            isLoader: true
        }, () => {
            if (this.state.iscopy) {
                setTimeout(() => { this.CopyPreviousData() }, 1500)
            } else {
                setTimeout(() => { this._save() }, 1500)
            }
        });
    };

    _copy = () => {
        try {
            this._sketch.copy();
            this._sketch.paste();
        } catch (error) {
            this.setState({
                enableRemoveSelected: false,
                enableCopyPaste: false,
            });
        }
    };
    _removeSelected = () => {
        try {
            this._sketch.removeSelected();
        } catch (error) {
            this.setState({
                enableRemoveSelected: false,
                enableCopyPaste: false,
            });
        }
    };

    _removeMe = (index) => {
        let drawings = this.state.drawings;
        drawings.splice(index, 1);
        this.setState({ drawings: drawings });
    };


    _changeColor = (color) => {
        let { tool } = this.state;

        this.setState({
            lineColor: color,
            tool: Tools.Pan
        }, () => {
            this.setState({
                tool: tool
            });
        });
    };

    _selectTool = () => {
        this.setState({ tool: Tools.Select });
        this.setState({
            enableRemoveSelected: true,
            enableCopyPaste: true,
        });
    };

    handleClear = () => {
        this.setState({
            LeftEye: {
                Media: "",
                Optic: "",
                C_D_Ratio: "",
                Macula: "",
            },
            RightEye: {
                Media: "",
                Optic: "",
                C_D_Ratio: "",
                Macula: "",
            }
        })
    }

    renderGlassPower = (Heading, values, key, Tab, state_Key) => {
        let states = this.state
        return (
            <div>
                <div style={{ marginTop: "0.5vw", fontWeight: "600" }}>{Heading}</div>
                <div style={{ display: "flex", flexWrap: "wrap", marginTop: "0.5vw" }}>
                    {
                        values && values.map((item, index) => (
                            <Chip
                                className={`eMed_chip ${item.label === key ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                style={{ width: "4vw" }}
                                label={item?.label}
                                variant={item.label === key ? 'contained' : 'outlined'}
                                key={index}
                                onClick={() => {

                                    if (item.label === key) {
                                        states[Tab][state_Key] = ""
                                        this.setState({ states })
                                    } else {
                                        states[Tab][state_Key] = item?.value
                                        this.setState({ states })
                                    }
                                }}
                                clickable
                            />
                        ))
                    }
                </div>
            </div>
        )
    }

    renderRightSideValues = (Heading, value) => {
        return (
            <div style={{ marginLeft: "0.85vw", marginTop: "0.75vw" }}>
                <div>{Heading}</div>
                <div className="eMed_BoxShadow">
                    {value?.length > 13 ? <Tooltip title={value} placement='top' arrow><div>{value?.slice(0, 10) + "..."}</div></Tooltip>: value}
                </div>
            </div>
        )
    }

    renderFundusImage = () => {
        let { controlledValue } = this.state;
        return (
            <div style={{ backgroundColor: Colors.white, border: "1px solid black" }}>
                <SketchField
                    name={"sketchfield"}
                    ref={(c) => (this._sketch = c)}
                    tool={this.state.tool}
                    lineColor={this.state.lineColor}
                    lineWidth={this.state.lineWidth}
                    fillColor={
                        this.state.fillWithColor
                            ? this.state
                                .fillColor
                            : "transparent"
                    }
                    backgroundColor={
                        this.state
                            .fillWithBackgroundColor
                            ? this.state
                                .backgroundColor
                            : "transparent"
                    }
                    width={700}
                    height={300}
                    undoSteps={2000}
                    value={controlledValue}
                    forceValue
                    onChange={this._onSketchChange}
                />
            </div>
        )
    }

    render() {
        return (
            <ShowComponents onIntersection={this.apiCalls.bind(this)}>
                <Box component={'div'} sx={{ backgroundColor: Colors.white, marginTop: "0.5vw", marginLeft: "0.5vw" }}>
                    <Box component={'div'} p={'0.5vw'} sx={{ backgroundColor: Colors.Background, display: "flex", flexDirection: "row", alignItems: "center" }} height={'2.5vw'}>
                        <Typography fontWeight={600}>Fundus</Typography>
                        <div style={{ display: "flex", flexDirection: "row", marginLeft: "1vw" }}>
                            <div className="eMed_icon_styles" style={{ backgroundColor: Object.keys(this.state.FundusData).length > 0 ? Colors.ThemeColor : Colors.disableComponentColor }}
                                onClick={() => {
                                    if (Object.keys(this.state.FundusData).length > 0 && !this.props.isLocked) {
                                        this.setState({ isLoader: true, iscopy: true }, () => { this._clear() })
                                    }
                                }}>
                                <Tooltip placement='top' title='Copy Previous Fundus Diagram'>
                                    <Box component={'img'} src={ImagePaths.copy.default} height={'1.5vw'} width={'1.5vw'} />
                                </Tooltip>
                            </div>
                        </div>
                    </Box>
                </Box>
                <Box component={'div'} p={'0.5vw'}>
                    <Box component={'div'} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {this.renderFundusImage()}
                    </Box>

                    <div style={{ width: "100%", display: "flex", alignItems: "center", marginTop: "0.5vw", flexDirection: "row", justifyContent: "center", backgroundColor: Colors.Background }}>
                        <div>
                            <Button className='Common_Btn_Min_Width' style={{ border: this.state.lineColor == "#000000" ? "1px solid black" : null }} onClick={() => { this._changeColor("#000000") }}>
                                <Box component={'img'} src={ImagePaths.Black.default} height={'1.5vw'} width={'1.5vw'} />
                            </Button>
                            <Button className='Common_Btn_Min_Width' style={{ border: this.state.lineColor == "#F19C38" ? "1px solid black" : null }} onClick={() => { this._changeColor("#F19C38") }}>
                                <Box component={'img'} src={ImagePaths.Orange.default} height={'1.5vw'} width={'1.5vw'} />
                            </Button>
                            <Button className='Common_Btn_Min_Width' style={{ border: this.state.lineColor == "#4A95EC" ? "1px solid black" : null }} onClick={() => { this._changeColor("#4A95EC") }}>
                                <Box component={'img'} src={ImagePaths.Blue.default} height={'1.5vw'} width={'1.5vw'} />
                            </Button>
                            <Button className='Common_Btn_Min_Width' style={{ border: this.state.lineColor == "#67AC5B" ? "1px solid black" : null }} onClick={() => { this._changeColor("#67AC5B") }}>
                                <Box component={'img'} src={ImagePaths.Green.default} height={'1.5vw'} width={'1.5vw'} />
                            </Button>
                            <Button className='Common_Btn_Min_Width' style={{ border: this.state.lineColor == "#E15241" ? "1px solid black" : null }} onClick={() => { this._changeColor("#E15241") }}>
                                <Box component={'img'} src={ImagePaths.Red.default} height={'1.5vw'} width={'1.5vw'} />
                            </Button>
                            <Button className='Common_Btn_Min_Width' style={{ border: this.state.lineColor == "#74574A" ? "1px solid black" : null }} onClick={() => { this._changeColor("#74574A") }}>
                                <Box component={'img'} src={ImagePaths.Brown.default} height={'1.5vw'} width={'1.5vw'} />
                            </Button>
                            <Button className='Common_Btn_Min_Width' style={{ border: this.state.lineColor == "#FFFFFF" ? "1px solid black" : null }} onClick={() => { this._changeColor("#FFFFFF") }}>
                                <Box component={'img'} src={ImagePaths.White.default} height={'1.5vw'} width={'1.5vw'} />
                            </Button>
                        </div>
                        <div style={{ marginLeft: "4vw", display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <Tooltip placement="top" title={'Spray'}>
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        this.setState({
                                            tool: Tools.Spray,
                                            lineWidth: 10,
                                        }, () => { this._changeColor("#000000") });
                                    }}
                                >
                                    <SprayIcon style={styles.control} />
                                </Button>
                            </Tooltip>
                            <Tooltip placement="top" title={"Pencil"}>
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        this.setState({
                                            tool: Tools.Pencil,
                                            lineWidth: 3,
                                        }, () => { this._changeColor("#000000") });
                                    }}
                                >
                                    <CreateIcon
                                        style={styles.control}
                                    />
                                </Button>
                            </Tooltip>

                            <Tooltip placement="top" title={"Line Pencil"}>
                                <Button sx={{ marginTop: "0.5vw" }} color="primary"
                                    onClick={() => {
                                        this.setState({
                                            tool: Tools.Line,
                                            lineWidth: 3,
                                        }, () => { this._changeColor("#000000") });
                                    }}
                                >
                                    <LineIcon
                                        style={styles.control}
                                    />
                                </Button>
                            </Tooltip>

                            <Tooltip placement="top" title={"Rectangle"}>

                                <Button
                                    color="primary"
                                    onClick={() => {
                                        this.setState({
                                            tool: Tools.Rectangle,
                                            lineWidth: 3,
                                        }, () => { this._changeColor("#000000") });
                                    }}
                                >
                                    <RectIcon
                                        style={styles.control}
                                    />
                                </Button>
                            </Tooltip>


                            <Tooltip placement="top" title={"Circle"}>
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        this.setState({
                                            tool: Tools.Circle,
                                            lineWidth: 3,
                                        }, () => { this._changeColor("#000000") });
                                    }}
                                >
                                    <CircleIcon
                                        style={styles.control}
                                    />
                                </Button>
                            </Tooltip>

                            <Tooltip placement="top" title={"Brush"}>
                                <Button
                                    color="primary"
                                    style={{ marginLeft: "-0.5vw" }}
                                    onClick={() => {
                                        this.setState({
                                            tool: Tools.Pencil,
                                            lineWidth: 10,
                                        }, () => { this._changeColor("#000000") });
                                    }}
                                >
                                    <Box component={'img'} src={ImagePaths.Brush.default} height={'1.4vw'} />
                                </Button>
                            </Tooltip>
                        </div>
                    </div>

                    <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", justifyContent: "center" }}>

                        <Tooltip placement="top" title={"Eraser"}>
                            <Button
                                color="primary"
                                onClick={() => {
                                    this.setState({
                                        tool: Tools.Pencil,
                                        lineWidth: 10,
                                    }, () => { this._changeColor("#FFFFFF") });
                                }}
                            >
                                <Box component={'img'} src={ImagePaths.Eraser.default} height={'1.2vw'} width={'1.2vw'} />
                            </Button>
                        </Tooltip>

                        <Tooltip placement="top" title={"Undo"}>
                            <Button
                                color="primary"
                                disabled={!this.state.canUndo}
                                onClick={() => { this._undo() }}
                            >
                                <UndoIcon
                                    style={styles.control}
                                />
                            </Button>
                        </Tooltip>

                        <Tooltip placement="top" title={"Redo"}>

                            <Button
                                color="primary"
                                disabled={!this.state.canRedo}
                                onClick={() => { this._redo() }}
                            >
                                <RedoIcon
                                    style={styles.control}
                                />
                            </Button>
                        </Tooltip>

                        <div style={{ justifyContent: "center" }}>
                            <TextField id="standard-basic" variant="standard" onChange={(e) => { this.setState({ text: e.target.value }) }} />
                            <div style={{ fontSize: 10, color: "grey", marginTop: 5 }} >Enter text to add</div>
                        </div>
                        <Tooltip placement="top" title={"Add Text"}>

                            <Button
                                color="primary"
                                onClick={() => { this._addText() }}
                            >
                                <AddIcon
                                    style={styles.control}
                                />
                            </Button>
                        </Tooltip>

                        <Button disabled={this.state.isLocked || this.props.isLocked} onClick={() => { this._save() }}>
                            <SaveIcon style={styles.control} />
                        </Button>
                        <Tooltip placement="top" title={"Trash"}>

                            <Button
                                color="primary"
                                disabled={this.state.isLocked || this.props.isLocked}
                                onClick={() => {
                                    this.setState({
                                        isDeleteCliked: true
                                    })
                                }}
                            >
                                <DeleteIcon
                                    style={styles.control}
                                />
                            </Button>
                        </Tooltip>
                    </div>
                </Box>
                {this.state.isDeleteCliked ? <DeletePopup
                    DeletTitle={"Are you sure you want to delete Fundus ?"}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this._clear.bind(this)}
                /> : null}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                <Loader loaderOpen={this.state.isLoader} />
            </ShowComponents >
        )
    }
}