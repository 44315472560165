import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import { t } from "i18next";
import './MultiBilling.css';
import { Serviceurls } from "../../../../Utility/API/Serviceurls";
import RestAPIService from "../../../../Utility/Services/RestAPIService";
import { Autocomplete, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, IconButton, InputAdornment, Modal, Radio, RadioGroup, Stack, TextField, Tooltip, Typography } from "@mui/material";
import ToastMsg from "../../../../Components/ToastMsg/ToastMsg";
import MultiLabBilling from "./MultiLabBilling";
import MultiRadBilling from "./MultiRadBilling";
import { BillSuccessPoPUp, CommonPatientDetails } from "../../../../Components/Common Components/CommonComponents";
import MultiOPBilling from "./MultiOPBilling";
import MultiIPBilling from "./MultiIPBilling";
import CommonValidation from "../../../../Components/CommonFunctions/CommonValidation";
import { ImagePaths } from "../../../../Utility/ImagePaths";
import CalculateIcon from '@mui/icons-material/Calculate';
import { clearCachevalue, getCachevalue, localGetItem } from "../../../../Utility/Services/CacheProviderService";
import { AmountFormat, debounce } from "../../../../Components/CommonFunctions/CommonFunctions";
import { Colors } from "../../../../Styles/Colors";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { DateTime } from "luxon";
import Loader from '../../../../Components/Loader';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

class MultiBillingHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            infoMsg: false,
            infoMsgText: '',
            is_gst_enabled: JSON.parse(localGetItem("is_gst_enabled")),
            consultantName: null,
            consultantID: null,
            opState: {},
            ipState: {},
            labState: {},
            radiologyState: {},
            totalServiceAmount: 0,
            totalconcessionAmount: 0,
            totalPayableAmount: 0,
            ClearPopupopen: false,
            PatientDetails: null,
            isCard: false,
            isCash: false,
            isUPI: false,
            isBankTranfer: false,
            isCheque: false,
            CashAmount: "",
            ReceivedCashAmount: "",
            CashBalanceAmount: "",
            CardAmount: "",
            CardTransactionNumber: "",
            UPINumber: "",
            UPIAmount: "",
            BankTfrAmount: "",
            BankTfrNumber: "",
            ChequeNumber: "",
            ChequeAmount: "",
            UhidOrPhNumber: "",
            IPorIPNumber: "",
            PatientType: "",
            PatientSearchList: [],
            triggerSignal: false,
            BillId: null,
            ReceiptId: null,
            isCreditBill: null,
            LabTransmittedItems: [],
            TransmittedLabId: null,
            LabTransmittedIds: null,
            RadiologyTranmittedItems: [],
            TransmittedRadiologyId: null,
            RadiologyTransmittedIds: null
        }
        this.debouncedUpdateChildState = debounce(this.updateChildState, 500);
    }

    componentDidMount() {
        if (this.props.history?.location?.state?.service_number) {
            this.getPatientDataSearch("", this.props.history?.location?.state?.service_number)
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if (this.props.history?.location?.state?.service_number) {
                this.getPatientDataSearch("", this.props.history?.location?.state?.service_number)
                if (getCachevalue("patientData") === null && getCachevalue("IppatientData") === null) {
                    this.props.history.push({ pathname: "/Billing/MultiBilling", state: {} })
                }
            }
            if (this.props.history?.location?.state?.TransmitData) {
                let TransmittedData = this.props.history?.location?.state?.TransmitData
                this.getPatientDataSearch("", TransmittedData?.ip_number ? TransmittedData?.ip_number : TransmittedData?.op_number, TransmittedData)
                this.props.history.push({ pathname: "/Billing/MultiBilling", state: {} })
            }
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message,
            DisableBtn: false
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message,
            DisableBtn: false
        })
    }

    infoMessage = (message) => {
        this.setState({
            infoMsg: true,
            infoMsgText: message
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key,
        })
    }

    getConsultantList = () => {
        const { errorMessage } = this.props;
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME)
                .then((response) => {
                    if (response.data.status === 'success') {
                        const list = response.data.data || [];
                        this.setState({
                            ConsultantDataList: list,
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            errorMessage(error.message)
        }
    }

    getInsuranceDetails = () => {
        const { errorMessage } = this.props;
        try {
            RestAPIService.getAll(Serviceurls.FO_INSURANCE_LIST)
                .then((response) => {
                    if (response.data.status === 'success') {
                        const list = response.data.data || [];
                        this.setState({
                            insuranceData: list,
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            errorMessage(error.message)
        }
    }

    getCorporateDetails = () => {
        const { errorMessage } = this.props;
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_CORPORATE)
                .then((response) => {
                    if (response.data.status === 'success') {
                        const list = response.data.data || [];
                        this.setState({
                            corporateData: list,
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            errorMessage(error.message)
        }
    }


    getPatientDataSearch = (patient_data = "", serviceNumber = "", TransmittedData = null) => {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.FO_MULTI_BILLING_PATIENT_SEARCH + '?service_number=' + encodeURIComponent(serviceNumber) + '&patient_data=' + encodeURIComponent(patient_data) + (TransmittedData ? "&from_transmit=true" : ""))
                .then((response) => {
                    if (response.data.status === 'success') {
                        const list = response.data.data || [];
                        this.LoaderFunction(false)
                        if (list?.length) {
                            this.setState({
                                PatientDetails: list?.length === 1 ? list[0] : null,
                                IPorIPNumber: "",
                                UhidOrPhNumber: "",
                                triggerSignal: !this.state.triggerSignal
                            }, () => {
                                this.ClearFuction()
                                if (list?.length > 1) {
                                    this.setState({
                                        PatientSearchList: list,
                                    })
                                } else {
                                    this.FetchPatientType()
                                }
                                if (TransmittedData) {
                                    if (TransmittedData?.lab_line_items?.length) {
                                        this.setState({
                                            LabTransmittedItems: TransmittedData?.lab_line_items,
                                            TransmittedLabId: TransmittedData?.laboratory_id,
                                            LabTransmittedIds: TransmittedData?.lab_test_transmit_summary_id
                                        })
                                    }
                                    if (TransmittedData?.rad_line_items?.length) {
                                        this.setState({
                                            RadiologyTranmittedItems: TransmittedData?.rad_line_items,
                                            TransmittedRadiologyId: TransmittedData?.radiology_id,
                                            RadiologyTransmittedIds: TransmittedData?.rad_test_transmit_summary_id
                                        })
                                    }
                                }
                            })
                        } else {
                            this.errorMessage("No Records Found !")
                        }
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    FetchPatientType() {
        this.setState({
            PatientType: this.state.PatientDetails?.ip_admission_id ? "ip" : this.state.PatientDetails?.appointment_id ? "op" : "individual",
            paymentCreditType: this.state.PatientDetails?.primary_payment_type === "Patient credit" ? 'patientcredit' :
                this.state.PatientDetails?.primary_payment_type === "Corprate credit" ? 'corporatecredit' :
                    (this.state.PatientDetails?.primary_payment_type === "Insurance credit" && this.state.PatientDetails?.ip_admission_id) ? 'insurancecredit' : 'patientcredit',
        }, () => {
            // this.ClearFuction()
            if (this.state.PatientType === "individual") {
                this.getConsultantList()
            }
        })
    }


    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            infoMsg: false,
            infoMsgText: '',
        })
    }

    updateChildState = (childKey, newState) => {
        this.setState(
            (prevState) => ({
                ...prevState,
                [childKey]: newState,
            }),
            () => {
                const totalServiceAmount = (+this.state.opState?.totalServiceAmount || 0) + (+this.state.ipState?.totalServiceAmount || 0) + (+this.state.labState?.netAmount || 0) + (+this.state.radiologyState?.subAmount || 0)
                const totalconcessionAmount = +(this.state.opState?.totalDiscountedAmount || 0) + (+this.state.ipState?.totalDiscountedAmount || 0) + (+this.state.labState?.discountAmount || 0) + (+this.state.radiologyState?.overAllDiscountAmt || 0)
                const totalPayableAmount = (+this.state.opState?.payableAmount || 0) + (+this.state.ipState?.payableAmount || 0) + (+this.state.labState?.totalBillAmount || 0) + (+this.state.radiologyState?.payableAmount || 0)
                this.setState({ totalServiceAmount, totalconcessionAmount, totalPayableAmount });
            }
        );
    };

    renderAmountTextFields = (label, key, Icon) => {
        var states = this.state
        let { t } = this.props;
        return (
            <TextField
                sx={{ padding: 0, margin: 0, width: "10vw" }}
                className='eMed_CrdPop_Amount_TextFields'
                size='small'
                // disabled={this.state.forCancel}
                label={t(label)}
                autoComplete='off'
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <img src={Icon} alt={label} className='eMed_action_img_small' />
                        </InputAdornment>
                    ),
                }}
                value={states[key]}
                onChange={(e) => {
                    let number = CommonValidation.NumberWithDot(e.target.value);
                    if ((number && +e.target.value > 0) || e.target.value === "") {
                        states[key] = e.target.value
                        this.setState({
                            states
                        }, () => {
                            if (key === "CashAmount") {
                                this.setState({
                                    CashBalanceAmount: this.state.ReceivedCashAmount === "" ? "" : +e.target.value - +this.state.ReceivedCashAmount
                                })
                            }
                            let CashAmt = states.CashAmount === "" ? 0 : +states.CashAmount;
                            let CardAmt = states.CardAmount === "" ? 0 : +states.CardAmount;
                            let UPIAmt = states.UPIAmount === "" ? 0 : +states.UPIAmount;
                            let bankTransferAmt = states.BankTfrAmount === "" ? 0 : +states.BankTfrAmount;
                            let ChequeAmt = states.ChequeAmount === "" ? 0 : +states.ChequeAmount;
                            if ((CashAmt + CardAmt + UPIAmt + bankTransferAmt + ChequeAmt) == +states.totalPayableAmount && this.state?.totalPayableAmount && this.state.isCredit) {
                                this.setState({
                                    isCredit: false
                                })
                            }
                        })
                    }
                }}
            />
        )
    }

    renderNumberTextFields = (label, key, Right = false) => {
        var states = this.state
        let { t } = this.props;
        return (
            <TextField
                className='eMed_CrdPop_Amount_TextFields'
                sx={{ marginLeft: Right ? "1vw" : 0, width: "10vw" }}
                inputProps={{ maxLength: 20 }}
                // disabled={this.state.forCancel}
                disabled={key === "CashBalanceAmount" || (key === "ReceivedCashAmount" ? (this.state.CashAmount === "" ? true : false) : false)}
                focused={key === "CashBalanceAmount"}
                size='small'
                label={t(label)}
                value={states[key]}
                autoComplete='off'
                onChange={(e) => {
                    let number = CommonValidation.gstnumberValidation(e.target.value);
                    let onlynumber = CommonValidation.numberOnly(e.target.value);
                    if (key === "ReceivedCashAmount") {
                        if (onlynumber || e.target.value === "") {
                            this.setState({
                                ReceivedCashAmount: e.target.value,
                                CashBalanceAmount: e.target.value === "" ? "" : +this.state.CashAmount - +e.target.value
                            })
                        }
                    }
                    else {
                        if (number || e.target.value === "") {
                            states[key] = e.target.value.toUpperCase()
                            this.setState({
                                states
                            }, () => {
                                let CashAmt = states.CashAmount === "" ? 0 : +states.CashAmount;
                                let CardAmt = states.CardAmount === "" ? 0 : +states.CardAmount;
                                let UPIAmt = states.UPIAmount === "" ? 0 : +states.UPIAmount;
                                let bankTransferAmt = states.BankTfrAmount === "" ? 0 : +states.BankTfrAmount;
                                let ChequeAmt = states.ChequeAmount === "" ? 0 : +states.ChequeAmount;
                                if ((CashAmt + CardAmt + UPIAmt + bankTransferAmt + ChequeAmt) == +states.totalPayableAmount && this.state?.totalPayableAmount && this.state.isCredit) {
                                    this.setState({
                                        isCredit: false
                                    })
                                }
                            })
                        }
                    }
                }}
            />
        )
    }

    handleCheckBox = (e, key) => {
        var states = this.state
        states[key] = e.target.checked
        this.setState({ states }, () => {
            if (!states.isCash) { this.setState({ CashAmount: "", CashBalanceAmount: "", ReceivedCashAmount: "" }) }
            if (!states.isCard) { this.setState({ CardAmount: "", CardTransactionNumber: "", CashBalanceAmount: "", ReceivedCashAmount: "" }) }
            if (!states.isUPI) { this.setState({ UPIAmount: "", UPINumber: "", CashBalanceAmount: "", ReceivedCashAmount: "" }) }
            if (!states.isCheque) { this.setState({ ChequeAmount: "", ChequeNumber: "", CashBalanceAmount: "", ReceivedCashAmount: "" }) }
            if (!states.isBankTranfer) { this.setState({ BankTfrAmount: "", BankTfrNumber: "", CashBalanceAmount: "", ReceivedCashAmount: "" }) }
            this.AutoFetchbillAmount()
        })
    }

    AutoFetchbillAmount = () => {
        let FetchingAccess = localGetItem("auto_fetch_bill_amount_to_payment")
        var states = this.state
        if (FetchingAccess === "true") {
            this.setState({
                isCredit: false
            }, () => {
                if (states.isCash && !states.isCard && !states.isUPI && !states.isBankTranfer && !states.isCheque) {
                    this.setState({ CashAmount: this.state.totalPayableAmount, })
                } else if (!states.isCash && states.isCard && !states.isUPI && !states.isBankTranfer && !states.isCheque) {
                    this.setState({ CardAmount: this.state.totalPayableAmount, })
                } else if (!states.isCash && !states.isCard && states.isUPI && !states.isBankTranfer && !states.isCheque) {
                    this.setState({ UPIAmount: this.state.totalPayableAmount, })
                } else if (!states.isCash && !states.isCard && !states.isUPI && states.isBankTranfer && !states.isCheque) {
                    this.setState({ BankTfrAmount: this.state.totalPayableAmount, })
                } else if (!states.isCash && !states.isCard && !states.isUPI && !states.isBankTranfer && states.isCheque) {
                    this.setState({ ChequeAmount: this.state.totalPayableAmount, })
                }
            })
        }
    }

    ClearFuction() {
        this.setState({
            opState: {},
            ipState: {},
            labState: {},
            radiologyState: {},
            totalServiceAmount: 0,
            totalconcessionAmount: 0,
            totalPayableAmount: 0,
            ClearPopupopen: false,
            isCard: false,
            isCash: false,
            isUPI: false,
            isBankTranfer: false,
            isCheque: false,
            CashAmount: "",
            ReceivedCashAmount: "",
            CashBalanceAmount: "",
            CardAmount: "",
            CardTransactionNumber: "",
            UPINumber: "",
            UPIAmount: "",
            BankTfrAmount: "",
            BankTfrNumber: "",
            ChequeNumber: "",
            ChequeAmount: "",
            UhidOrPhNumber: "",
            IPorIPNumber: "",
            PatientSearchList: [],
            LabTransmittedItems: [],
            TransmittedLabId: null,
            LabTransmittedIds: null,
            RadiologyTranmittedItems: [],
            TransmittedRadiologyId: null,
            RadiologyTransmittedIds: null,
            consultantName: null,
            consultantID: null,
        })
    }

    BillFooterLabel = (label, value) => {
        return (
            <Box sx={{ display: 'flex', width: "21dvw", justifyContent: 'space-between' }}>
                <Typography sx={{ fontSize: "0.9vw" }}>{label}</Typography>
                <Typography sx={{ fontSize: "0.9vw", fontWeight: 600 }}>{AmountFormat(value ? value : 0)}</Typography>
            </Box>
        )
    }

    ClearDataPopup = () => {
        let isHaveBillingData = (this.state.opState?.opBillingList?.filter((list) => (list.id))?.length > 0 || this.state.ipState?.ipBillingList?.filter((list) => (list.id)).length > 0 || this.state.labState?.billSummaryList?.filter((element) => (element?.lab_test_id || element?.lab_set_id || element?.package_id)).length > 0 || this.state.radiologyState?.radBillingList?.filter((bill) => (bill?.service_id)).length > 0)
        return (
            <Dialog open={this.state.ClearPopupopen}>
                <DialogTitle sx={{ color: Colors.TableHeaderTextColor, display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
                    <Typography sx={{ fontSize: "1vw", fontWeight: 600 }}>{"Confirmation !"}</Typography>
                    <IconButton onClick={() => { this.setState({ ClearPopupopen: false }) }} marginRight={'4vw'}>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ bgcolor: Colors.PopupBackground }}>
                    {isHaveBillingData ? "Are you sure, do you want to clear the patient and billing data ?" : "Are you sure, do you want to clear the data ?"}
                </DialogContent>
                <DialogActions id="eMed_dialog_button_div" sx={{ bgcolor: Colors.PopupBackground }}>
                    {isHaveBillingData ? <Button sx={{ textTransform: "capitalize" }} id='eMed_dialog_button' color='error' variant='outlined' onClick={() => {
                        this.setState(
                            (prevState) => ({
                                triggerSignal: !prevState.triggerSignal,
                                ClearPopupopen: false
                            }),
                            () => {
                                this.ClearFuction()
                            }
                        );
                    }}>{"Clear Billing data only"}</Button> : null}
                    <Button sx={{ textTransform: "capitalize" }} id='eMed_dialog_button' color='error' variant='outlined' onClick={() => {
                        this.setState(
                            (prevState) => ({
                                triggerSignal: !prevState.triggerSignal,
                                ClearPopupopen: false,
                                PatientDetails: null,
                                PatientType: ""
                            }),
                            () => {
                                this.ClearFuction()
                                if (this.props.history?.location?.state?.service_number) {
                                    this.getPatientDataSearch("", this.props.history?.location?.state?.service_number)
                                }
                            }
                        );
                    }}>{"Clear all data"}</Button>
                </DialogActions>
            </Dialog>
        )
    }

    convertTimeToDecimal(time) {
        if (time) {
            let [hours, minutes] = time?.split(':').map(Number);
            return hours * 60 + minutes;
        } else {
            return 0
        }
    }

    CalculateHourAmount(ratePerHour, changedTime, fixedTime, minTime, minAmount) {
        let fixedTimes = this.convertTimeToDecimal(fixedTime);
        let changedTimes = this.convertTimeToDecimal(changedTime);
        let minTimes = this.convertTimeToDecimal(minTime ? minTime : '00:00')
        let changeAndFixedTime = changedTimes - fixedTimes
        let time = changeAndFixedTime > 0 ? changeAndFixedTime : 0
        let timeCalculateTime = (time === 0 && minTimes === 0) ? 0 : Math.ceil(time / minTimes) === Infinity ? 0 : Math.ceil(time / minTimes)
        let totalCost = ((+ratePerHour) + (timeCalculateTime * (minAmount ? minAmount : 0)))
        // let minTimeHourConvert = minTimes / 60
        // let totalCost = Math.ceil((changedTimes / fixedTimes) - minTimeHourConvert) * ratePerHour;

        return totalCost;
    }

    splitTimeTaken = (time_taken) => {
        if (time_taken && typeof time_taken === "string") {
            let timeTakenStr = time_taken?.split(':');
            let timeTaken = timeTakenStr?.length > 0 && timeTakenStr?.slice(0, 2)?.join(':');
            return timeTaken;
        }
        return time_taken;
    }


    returnIPData = (ipState, fromCredit = false) => {
        let BillLineItems = []
        let noDiscountAmount = 0;
        let IpBillList = ipState?.ipBillingList ? ipState?.ipBillingList?.filter((bill) => bill?.id) : []
        IpBillList.forEach(element => {
            let timeTakenStr = element?.time_taken && element.time_taken?.split(':');
            let timeTaken = timeTakenStr?.length > 0 && timeTakenStr?.slice(0, 2)?.join(':')
            let Unit = timeTaken ? (timeTaken + " " + "hrs") : element.unit
            let old_Unit = element.old_time_taken ? element.old_time_taken : element.time_taken ? element.time_taken : null
            let timeAmount = element.time_taken ? this.CalculateHourAmount(+element?.amount, element.time_taken, old_Unit, element?.minimum_time, element?.min_amount) : 0
            let ServiceNetAmount = element.time_taken ? (+timeAmount - element.discountAmount) : +(element.totalAmount - element.discountAmount)
            BillLineItems.push({
                "service_name": element.service_name ? CommonValidation.removeSpace(element.service_name) : '',
                "service_type": element.service_type ? CommonValidation.removeSpace(element.service_type) : '',
                "service_category_id": element.service_category_id ? element.service_category_id : null,
                "service_id": element?.service_id ? element?.service_id : element.ip_service_id ? element.ip_service_id : element.id ? element.id : null,
                "drug_id": element.drug_id ? element.drug_id : null,
                "template_id": element.templateId ? element.templateId : element?.ip_template_id ? element?.ip_template_id : null,
                "assorted_head": element.assorted_head ? element.assorted_head : '',
                "service_units": Unit ? Unit : "1",
                "service_old_units": old_Unit ? old_Unit : null,
                "service_rate_per_unit": +((this.state.is_gst_enabled && element?.gst_percentage) ? CommonValidation.CalculateGSTInclude(element.amount, element?.gst_percentage) : element.amount),
                "service_amount": ((this.state.is_gst_enabled && element?.gst_percentage) ? CommonValidation.CalculateGSTInclude(ServiceNetAmount, element?.gst_percentage) : ServiceNetAmount),
                "total_amount": (element.time_taken ? +timeAmount : +((this.state.is_gst_enabled && element?.gst_percentage) ? CommonValidation.CalculateGSTInclude(element.amount, element?.gst_percentage) : element.amount) * +element.unit),
                "discount_amount": +element.discountAmount ? +element.discountAmount : 0,
                "discount_percentage": element.discountPercentage ? +element.discountPercentage : 0,
                "doctor_percentage": element?.ip_service?.doctor_percentage ? +element?.ip_service?.doctor_percentage : element?.doctor_percent ? element.doctor_percent : element.doctor_percentage ? element.doctor_percentage : 0,
                "hospital_percentage": element?.ip_service?.hospital_percentage ? +element?.ip_service?.hospital_percentage : element?.hospital_percent ? element.hospital_percent : element.hospital_percentage ? element.hospital_percentage : 0,
                "old_doctor_fee": element?.ip_service?.original_doctor_fee ? +element?.ip_service?.original_doctor_fee : element?.ip_service?.doctor_fee ? element?.ip_service?.doctor_fee : 0,
                "old_hospital_fee": element?.ip_service?.original_hospital_fee ? +element?.ip_service?.original_hospital_fee : element?.ip_service?.hospital_fee ? element?.ip_service?.hospital_fee : 0,
                "new_doctor_fee": element?.ip_service?.doctor_fee ? +element?.ip_service?.doctor_fee : element.doctor_share ? element?.doctor_share : element.new_doctor_fee ? element.new_doctor_fee : 0,
                "new_hospital_fee": element?.ip_service?.hospital_fee ? +element?.ip_service?.hospital_fee : element.hospital_share ? element.hospital_share : element.new_hospital_fee ? element.new_hospital_fee : 0,
                "amount_type": element?.amount_type ? element?.amount_type : 'rupee',
                "procedure_start_time": element?.start_date ? element?.start_date : null,
                "procedure_end_time": element?.end_date ? element?.end_date : null,
                "minimum_amount": element.time_taken && element?.min_amount ? +element?.min_amount : null,
                "minimum_time": element.minimum_time ? (typeof (element.minimum_time) === 'number' ? (element.minimum_time < 9 ? `00:0${element.minimum_time}` : `00:${element.minimum_time}`) : element.minimum_time) : '00:00',
                "individual_discount_comments": element?.individual_discount_comments ? element?.individual_discount_comments : "",
                "other_consultant_name": element?.other_consultant_name ? element?.other_consultant_name : null,
                "is_auto_billing": false,
                "status": fromCredit ? 'credit' : "paid",
                "gst_percentage": (element?.gst_percentage && this.state.is_gst_enabled) ? element?.gst_percentage : 0,
            })
            noDiscountAmount = noDiscountAmount + (element.time_taken ? this.CalculateHourAmount(+((this.state.is_gst_enabled && element?.gst_percentage) ? CommonValidation.CalculateGSTInclude(element.amount, element?.gst_percentage) : element.amount), element.time_taken, old_Unit, element?.minimum_time, element?.min_amount) : element.unit * +((this.state.is_gst_enabled && element?.gst_percentage) ? CommonValidation.CalculateGSTInclude(element.amount, element?.gst_percentage) : element.amount))
        })

        return IpBillList?.length === 0 ? {} : {
            "assorted_pack_id": ipState?.Assorted_Id ? ipState?.Assorted_Id : null,
            "total_amount": noDiscountAmount ? +noDiscountAmount : 0,
            "discount_percentage": ipState.totalDiscountedPercentage ? +ipState.totalDiscountedPercentage : 0,
            "total_sub_amount": ipState.totalServiceAmount ? +(ipState.totalServiceAmount) : 0,
            "discount_amount": ipState.totalDiscountedAmount ? +ipState.totalDiscountedAmount : 0,
            "net_amount": ipState.payableAmount ? +ipState.payableAmount : 0,
            "paid_amount": fromCredit ? 0 : (ipState.payableAmount ? +ipState.payableAmount : 0),
            "received_amount": fromCredit ? 0 : (ipState.payableAmount ? +ipState.payableAmount : 0),
            "remarks": ipState.discountReason ? ipState.discountReason : '',
            "bill_line_items": BillLineItems,
            "round_off": +ipState.roundOff,
        }
    }

    returnOPData = (opState, fromCredit = false) => {
        let BillLineItems = []
        let noDiscountAmount = 0;
        let OPBillList = opState?.opBillingList ? opState?.opBillingList?.filter((bill) => bill?.id) : []
        OPBillList?.forEach((element) => {
            if (element?.id) {
                let Unit = element.time_taken ? this.splitTimeTaken(element.time_taken) : element.unit
                let old_Unit = element.old_time_taken ? element.old_time_taken : element.time_taken ? element.time_taken : null
                let TotalAmount = ((this.state.is_gst_enabled && element?.gst_percentage) ? CommonValidation.CalculateGSTInclude(+element.amount * element.unit, element?.gst_percentage) : +element.amount * element.unit)
                let NetAmount = ((element.time_taken ? this.CalculateHourAmount(+element?.amount, Unit, old_Unit, element?.minimum_time, +element?.min_amount) : +element.amount * element.unit) - +element.discountAmount)
                BillLineItems.push({
                    "service_catagory_id": element.service_catagory_id ? element.service_catagory_id : null,
                    "service_name": element.service_name ? CommonValidation.removeSpace(element.service_name) : '',
                    "service_id": element?.op_service_id ? element.op_service_id : element?.id ? element.id : null,
                    "service_type": element.service_type ? CommonValidation.removeSpace(element.service_type) : '',
                    "amount_gross": element.amount === "" ? 0 : (element.time_taken ? this.CalculateHourAmount(+((this.state.is_gst_enabled && element?.gst_percentage) ? CommonValidation.CalculateGSTInclude(element.amount, element?.gst_percentage) : element.amount), Unit, old_Unit, element?.minimum_time, +element?.min_amount) : (TotalAmount)),
                    "service_discount_percentage": element.discountPercentage === "" ? 0 : +element.discountPercentage,
                    "doctor_percentage": element.service_fee?.doctor_percentage ? element?.service_fee?.doctor_percentage : element.doctor_percentage ? element.doctor_percentage : 0,
                    "hospital_percentage": element.service_fee?.hospital_percentage ? element?.service_fee?.hospital_percentage : element.hospital_percentage ? element.hospital_percentage : 0,
                    "old_doctor_fee": element?.service_fee?.original_doctor_fee ? element?.service_fee?.original_doctor_fee : element?.service_fee?.doctor_fee ? element?.service_fee?.doctor_fee : 0,
                    "old_hospital_fee": element?.service_fee?.original_hospital_fee ? element?.service_fee?.original_hospital_fee : element?.service_fee?.hospital_fee ? element?.service_fee?.hospital_fee : 0,
                    "new_doctor_fee": element.service_fee?.doctor_fee ? element?.service_fee?.doctor_fee : 0,
                    "new_hospital_fee": element.service_fee?.hospital_fee ? element?.service_fee?.hospital_fee : 0,
                    "amount_type": element.service_fee?.amount_type ? element.service_fee?.amount_type : element.amount_type ? element.amount_type : 'rupee',
                    "service_discount_amount": element.discountAmount === "" ? 0 : +element.discountAmount,
                    "service_rate_per_unit": element.amount === "" ? 0 : +((this.state.is_gst_enabled && element?.gst_percentage) ? CommonValidation.CalculateGSTInclude(element.amount, element?.gst_percentage) : element.amount),
                    "service_unit_count": Unit ? Unit : "1",
                    "procedure_start_time": null,
                    "procedure_end_time": null,
                    "minimum_amount": element.time_taken && element?.min_amount ? element?.min_amount : null,
                    "minimum_time": element?.minimum_time ? element?.minimum_time : '00:00',
                    "service_old_unit_count": old_Unit ? old_Unit : null,
                    "amount_net": (this.state.is_gst_enabled && element?.gst_percentage) ? CommonValidation.CalculateGSTInclude(NetAmount, element?.gst_percentage) : NetAmount,
                    "is_auto_billing": false,
                    "individual_discount_comments": element?.individual_discount_comments ? element?.individual_discount_comments : "",
                    "gst_percentage": (element?.gst_percentage && this.state.is_gst_enabled) ? element?.gst_percentage : 0,

                })
                let nextAmount = (+element.unit * +(((this.state.is_gst_enabled && element?.gst_percentage) ? CommonValidation.CalculateGSTInclude(+element.amount, element?.gst_percentage) : +element.amount))).toFixed(2)
                noDiscountAmount = +noDiscountAmount + +nextAmount
            }
        })

        return OPBillList?.length === 0 ? {} : {
            "amount_gross": noDiscountAmount ? noDiscountAmount : 0,
            "discount_percent": opState.totalDiscountedPercentage ? +opState.totalDiscountedPercentage : 0,
            "discount": opState.totalDiscountedAmount ? +opState.totalDiscountedAmount : 0,
            "amount_invoice": opState.payableAmount ? +opState.payableAmount : 0,
            "round_off": +opState.roundOff,
            "amount_net": opState.payableAmount ? +opState.payableAmount : 0,
            "received_amount": fromCredit ? 0 : (opState.payableAmount ? +opState.payableAmount : 0),
            "total_sub_amount": opState.totalServiceAmount ? +(opState.totalServiceAmount) : 0,
            "remarks": opState.discountReason ? opState.discountReason : '',
            "bill_line_items": BillLineItems
        }
    }

    returnLabData = (labState, fromCredit = false) => {
        let BillLineItems = []
        let LabBillList = labState?.billSummaryList ? labState?.billSummaryList?.filter((element) => (element?.lab_test_id || element?.lab_set_id || element?.package_id)) : []
        LabBillList?.forEach((element) => {
            if (element?.lab_test_id || element?.lab_set_id || element?.package_id) {
                BillLineItems.push({
                    "lab_test_id": element?.lab_test_id ? element?.lab_test_id : null,
                    "lab_set_id": element?.lab_set_id ? element?.lab_set_id : null,
                    "package_id": element?.package_id ? element?.package_id : null,
                    "amount": element?.amount ? element?.amount : 0,
                    "test_name": element?.test_name ? element?.test_name : "",
                    "grp_name": element?.grp_name ? element?.grp_name : "",
                    "test_units": 1,
                    "test_rate_per_unit": element?.amount ? element?.amount : 0,
                    "lab_test_transmit_summary_id": element?.transmit_summary_id ? element?.transmit_summary_id : null
                })
            }
        }
        )
        return LabBillList?.length === 0 ? {} : {
            "laboratory_id": labState?.labDetails?.laboratory_id,
            "discount_amount": labState.discountAmount ? labState.discountAmount : 0,
            "net_amount": labState.totalBillAmount ? labState.totalBillAmount : 0,
            "is_ip_transmit": false,
            "reffered_by": "",
            "referral_source": "",
            "reffered_reg_no": "",
            "total_amount": labState.netAmount ? +labState.netAmount : 0,
            "discount_percentage": labState.discountPercentage ? +labState.discountPercentage : 0,
            "round_off": labState.roundOff ? +labState.roundOff : 0,
            "received_amount": fromCredit ? 0 : (labState.totalBillAmount ? +labState.totalBillAmount : 0),
            "balance_amount": 0,
            "remarks": labState.discountAmount != 0 ? labState.discountReason : '',
            "doctor_name": this.state.consultantName ? this.state.consultantName : '',
            "lab_test_transmit_summary_id": this.state.LabTransmittedIds ? this.state.LabTransmittedIds : null,
            "is_transmitted": this.state.LabTransmittedIds?.length ? true : false,
            "bill_line_items": BillLineItems
        }
    }

    returnRadiologyData = (radiologyState, fromCredit = false) => {
        let BillLineItems = []
        let RadiologyBillList = radiologyState?.radBillingList ? radiologyState?.radBillingList?.filter((bill) => bill?.service_id) : []
        RadiologyBillList?.forEach((element) => {
            if (element?.service_id) {
                if (element?.is_doctor_wise) {
                    BillLineItems?.push({
                        total_amount: element?.service_total_amount ? +element?.service_total_amount : 0,
                        net_amount: +(element.service_total_amount - element.discount_amount),
                        service_name: element?.service_name ? element?.service_name : 0,
                        service_type: element?.service_type ? element?.service_type : 0,
                        discount_percentage: element?.discount_percentage ? +element?.discount_percentage : 0,
                        discount_amount: element?.discount_amount ? +element?.discount_amount : 0,
                        rate_per_unit: element?.service_rate_per_unit ? +element?.service_rate_per_unit : 0,
                        amount: element?.service_total_amount ? +element?.service_total_amount : 0,
                        units: element?.service_unit ? +element?.service_unit : 1,
                        service_id: element?.service_id ? element?.service_id : null,
                        original_doctor_fee: element?.service_charges?.original_doctor_fee ? +element?.service_charges?.original_doctor_fee : 0,
                        original_hospital_fee: element?.service_charges?.original_hospital_fee ? +element?.service_charges?.original_hospital_fee : 0,
                        discount_doctor_fee: element?.service_charges?.discount_doctor_fee ? +element?.service_charges?.discount_doctor_fee : 0,
                        discount_hospital_fee: element?.service_charges?.discount_hospital_fee ? +element?.service_charges?.discount_hospital_fee : 0,
                        discount_doctor_percentage: element?.service_charges?.discount_doctor_percentage ? +element?.service_charges?.discount_doctor_percentage : 0,
                        discount_hospital_percentage: element?.service_charges?.discount_hospital_percentage ? +element?.service_charges?.discount_hospital_percentage : 0,
                        doctor_fee: element?.service_charges?.doctor_fee ? +element?.service_charges?.doctor_fee : 0,
                        hospital_fee: element?.service_charges?.hospital_fee ? +element?.service_charges?.hospital_fee : 0,
                        discount_comment: element?.individual_discount_comments ? element?.individual_discount_comments : "",
                    })
                } else {
                    BillLineItems?.push({
                        total_amount: element?.service_total_amount ? +element?.service_total_amount : 0,
                        net_amount: +(element.service_total_amount - element.discount_amount),
                        service_name: element?.service_name ? element?.service_name : 0,
                        service_type: element?.service_type ? element?.service_type : 0,
                        discount_percentage: element?.discount_percentage ? +element?.discount_percentage : 0,
                        discount_amount: element?.discount_amount ? +element?.discount_amount : 0,
                        rate_per_unit: element?.service_rate_per_unit ? +element?.service_rate_per_unit : 0,
                        amount: element?.service_total_amount ? +element?.service_total_amount : 0,
                        units: element?.service_unit ? +element?.service_unit : 1,
                        service_id: element?.service_id ? element?.service_id : null,
                        original_doctor_fee: 0,
                        original_hospital_fee: 0,
                        discount_doctor_fee: 0,
                        discount_hospital_fee: 0,
                        discount_doctor_percentage: 0,
                        discount_hospital_percentage: 0,
                        doctor_fee: 0,
                        hospital_fee: 0,
                        discount_comment: "",
                    })
                }
            }
        })
        return RadiologyBillList?.length === 0 ? {} : {
            "radiology_id": radiologyState?.radDetails?.radiology_id,
            "reffered_by": "",
            "referral_source": "",
            "total_amount": +radiologyState.subAmount,
            "discount_percentage": +radiologyState.overAllDiscountPercent,
            "discount_amount": +radiologyState.overAllDiscountAmt,
            "round_off": +radiologyState.roundOff || 0,
            "net_amount": +radiologyState.payableAmount,
            "received_amount": fromCredit ? 0 : +radiologyState.payableAmount,
            // "balance_amount": 0,
            "is_transmitted": this.state.RadiologyTransmittedIds?.length ? true : false,
            "rad_test_transmit_summary_id": this.state.RadiologyTransmittedIds ? this.state.RadiologyTransmittedIds : null,
            "bill_line_items": BillLineItems,
            "discount_comment": radiologyState?.discountReason ? radiologyState?.discountReason : "",
        }
    }

    postMultiBill = (fromCredit = false) => {
        let { opState, ipState, labState, radiologyState } = this.state
        let payment = []
        if (this.state.isCash) {
            payment.push(1)
        }
        if (this.state.isCard) {
            payment.push(2)
        }
        if (this.state.isUPI) {
            payment.push(3)
        }
        if (this.state.isCheque) {
            payment.push(4)
        }
        if (this.state.isBankTranfer) {
            payment.push(5)
        }
        let Aptime = DateTime.fromJSDate(new Date()).toLocaleString(DateTime.TIME_24_SIMPLE);
        let invoice_date = `${DateTime.fromJSDate(new Date()).toFormat("yyyy-MM-dd")} ${Aptime}:00`
        let creditType = this.state.paymentCreditType === "patientcredit" ? "Patient credit" :
            this.state.paymentCreditType === "corporatecredit" ? "Corprate credit" :
                (this.state.paymentCreditType === "insurancecredit" && this.state.PatientType === "ip") ? "Insurance credit" : 'Patient credit'
        let data = {
            "credit_type": creditType,
            "employer_id": creditType === "Corprate credit" ? (this.state.selCompanyID ? this.state.selCompanyID : this.state?.PatientDetails?.employer_id ? this.state?.PatientDetails?.employer_id : null) : null,
            "employee_no": creditType === "Corprate credit" ? (this.state.selEmployeeNo ? this.state.selEmployeeNo : this.state?.PatientDetails?.id_no ? this.state?.PatientDetails?.id_no : '') : "",
            "insurance_name": (creditType === "Insurance credit" && this.state.PatientType === "ip") ? (this.state.selInsName ? this.state.selInsName : this.state.PatientDetails?.insurance_company_name ? this.state.PatientDetails?.insurance_company_name : '') : "",
            "policy_no": (creditType === "Insurance credit" && this.state.PatientType === "ip") ? (this.state.selPolicyNo ? this.state.selPolicyNo : this.state.PatientDetails?.policy_number ? this.state.PatientDetails?.policy_number : '') : "",
            "ip_admission_id": (this.state.PatientType === "ip" ? this.state.PatientDetails?.ip_admission_id : null),
            "bill_type": fromCredit ? "credit" : "regular",
            "net_amount": this.state.totalPayableAmount ? this.state.totalPayableAmount : 0,
            "received_amount": fromCredit ? 0 : (this.state.totalPayableAmount ? this.state.totalPayableAmount : 0),
            "appointment_id": this.state.PatientType === "op" ? this.state.PatientDetails?.appointment_id : null,
            "doctor_id": this.state.PatientType === "individual" ? this.state.consultantID : this.state.PatientDetails?.doctor_id ? this.state.PatientDetails?.doctor_id : null,
            "patient_id": this.state.PatientDetails?.patient_id ? this.state.PatientDetails?.patient_id : null,
            "nurse_profile_id": null,
            "patient_account_number": this.state.PatientDetails?.patient_account_number ? this.state.PatientDetails?.patient_account_number : null,
            "patient_type": this.state.PatientType === "ip" ? "Inpatient" : this.state.PatientType === "op" ? "Outpatient" : "Individual",
            "invoice_date": invoice_date,
            "payment_status": fromCredit ? "not paid" : "paid",
            "payment_mode": payment,
            "paid_in": "FRONT OFFICE",
            "collected_in": this.state.PatientType === "ip" ? "IP" : this.state.PatientType === "op" ? "OP" : (this.state.labState?.billSummaryList?.length ? "FO LAB" : "FO RAD"),
            "ip_number": this.state.PatientType === "ip" ? this.state.PatientDetails?.ip_number : null,
            "amount_cash": this.state.CashAmount ? +this.state.CashAmount : 0,
            "amount_card": this.state.CardAmount ? +this.state.CardAmount : 0,
            "upi_amount": this.state.UPIAmount ? +this.state.UPIAmount : 0,
            "cheque_amount": this.state.ChequeAmount ? +this.state.ChequeAmount : 0,
            "bank_transfer_amount": this.state.BankTfrAmount ? +this.state.BankTfrAmount : 0,
            "card_ref_no": this.state.CardTransactionNumber ? this.state.CardTransactionNumber : "",
            "upi_ref_no": this.state.UPINumber ? this.state.UPINumber : "",
            "bank_ref_no": this.state.BankTfrNumber ? this.state.BankTfrNumber : "",
            "cheque_ref_no": this.state.ChequeNumber ? this.state.ChequeNumber : "",
            "sub_amount": this.state.totalServiceAmount ? +this.state.totalServiceAmount : 0,
            "net_amount": this.state.totalPayableAmount ? +this.state.totalPayableAmount : 0,
            "discount_amount": this.state.totalconcessionAmount ? +this.state.totalconcessionAmount : 0,
            // "round_off": "",
            "op_bill": this.returnOPData(opState, fromCredit),
            "ip_bill": this.returnIPData(ipState, fromCredit),
            "lab_bill": this.returnLabData(labState, fromCredit),
            "rad_bill": this.returnRadiologyData(radiologyState, fromCredit),
        }

        data["total_amount"] = (data?.op_bill?.amount_gross ? data?.op_bill?.amount_gross : 0) + (data?.ip_bill?.total_amount ? data?.ip_bill?.total_amount : 0) + (data?.lab_bill?.total_amount ? data?.lab_bill?.total_amount : 0) + (data?.rad_bill?.total_amount ? data?.rad_bill?.total_amount : 0)
        console.log(data);
        this.LoaderFunction(true)
        RestAPIService.create(data, Serviceurls.FO_MULTI_BILLING_POST)
            .then((response) => {
                if (response.data.status === 'success') {
                    this.LoaderFunction(false)
                    this.setState({
                        BillId: response?.data?.bill_id ? response?.data?.bill_id : null,
                        ReceiptId: response?.data?.receipt_id ? response?.data?.receipt_id : null,
                        isCreditBill: fromCredit
                    }, () => {
                        this.successMessage(response.data.message)
                    })
                }
            }).catch((error) => {
                this.LoaderFunction(false)
                if (error?.response?.data?.message) {
                    this.errorMessage(error.response.data.message)
                } else {
                    this.errorMessage(error.message)
                }
            })
    }

    ValiadtionBilldata = (fromCredit = false) => {
        let { opState, ipState, labState, radiologyState } = this.state

        if (opState.totalDiscountedAmount && !opState.discountReason) {
            this.errorMessage("Enter OP Bill Consession reason")
        } else if (ipState.totalDiscountedAmount && !ipState.discountReason) {
            this.errorMessage("Enter IP Bill Consession reason")
        } else if (labState.discountAmount && !labState.discountReason) {
            this.errorMessage("Enter Lab Bill Consession reason")
        } else if (radiologyState.overAllDiscountAmt && !radiologyState?.discountReason) {
            this.errorMessage("Enter Radiology Bill Consession reason")
        } else if (this.state.PatientType === "individual" && !this.state.consultantID) {
            this.errorMessage("Select Consultant")
        } else {
            this.postMultiBill(fromCredit)
        }
    }

    PatientSelectionPopup() {
        let { t } = this.props;
        return (
            <Modal open={this.state.PatientSearchList?.length}>
                <Box className="eMed_MobileNum_Main" width={"31.5vw"}>
                    <Box component={'div'} className="eMed_MobileNum_Header">
                        <Typography variant='h6'>{"Patient List"}</Typography>
                        <IconButton onClick={() => { this.setState({ PatientSearchList: [] }) }} marginRight={'4vw'}>
                            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                        </IconButton>
                    </Box>
                    <Box component={'div'} className="eMed_MobileNum_wrapper" width={"31vw"}>
                        {
                            this.state.PatientSearchList?.length > 0 ?
                                this.state.PatientSearchList?.map((list) => {
                                    let PatientFullName = `${list.first_name} ${list.last_name}`
                                    list["patient_name"] = PatientFullName
                                    list["patient_mobile_number"] = list.mobile_number
                                    list["patient_photo_url"] = list.photo_url
                                    return (
                                        <Box>
                                            <Box className="eMed_Patient_Num_Search_pop" sx={{ width: "30vw" }}>
                                                <CommonPatientDetails data={list} />
                                                <Button size='small' variant='contained' sx={{ textTransform: 'capitalize' }}
                                                    onClick={() => {
                                                        this.setState({
                                                            PatientSearchList: []
                                                        })
                                                        this.getPatientDataSearch(list?.patient_account_number,)
                                                    }}
                                                >Create Bill</Button>
                                            </Box>
                                            <Divider />
                                        </Box>
                                    )
                                }) : null
                        }
                    </Box>
                </Box>
            </Modal>
        )
    }

    renderCreditType = () => {
        let { PatientDetails } = this.state
        let ins_name = PatientDetails?.insurance_company_name ? PatientDetails?.insurance_company_name : this.state.selInsName
        let ins_no = PatientDetails?.policy_number ? PatientDetails?.policy_number : this.state.selPolicyNo
        let comp_name = PatientDetails?.company_name ? PatientDetails?.company_name : this.state.selCompanyName
        let employee_no = PatientDetails?.id_no ? PatientDetails?.id_no : this.state.selEmployeeNo
        return (
            <Box>
                <Box component={'div'} display={'flex'} flexDirection={'row'} width={'40vw'} justifyContent={'space-between'}>
                    <Typography fontSize={'1vw'} fontWeight={'bold'}>Select the Credit Type</Typography>
                    <IconButton size='small' onClick={() => {
                        this.setState({
                            creditChange: false,
                            addInsurance: false,
                            addCorporate: false,
                            paymentCreditType: this.state.selPayemtnCreditType ? this.state.selPayemtnCreditType : this.state.PatientDetails?.primary_payment_type === "Patient credit" ? 'patientcredit' :
                                this.state.PatientDetails?.primary_payment_type === "Corprate credit" ? 'corporatecredit' :
                                    this.state.PatientDetails?.primary_payment_type === "Insurance credit" ? 'insurancecredit' : 'patientcredit',
                            insuranceName: null,
                            insuranceNo: '',
                            corpName: null,
                            employeeNo: '',
                            selInsName: this.state.selInsName ? this.state.selInsName : this.state.PatientDetails?.insurance_company_name ? this.state.PatientDetails?.insurance_company_name : '',
                            selPolicyNo: this.state.selPolicyNo ? this.state.selPolicyNo : this.state.PatientDetails?.policy_number ? this.state.PatientDetails?.policy_number : '',
                            selCompanyName: this.state.selCompanyName ? this.state.selCompanyName : this.state?.PatientDetails?.employer_name ? this.state?.PatientDetails?.employer_name : '',
                            selEmployeeNo: this.state.selEmployeeNo ? this.state.selEmployeeNo : this.state?.PatientDetails?.id_no ? this.state?.PatientDetails?.id_no : '',
                            selCompanyID: this.state.selCompanyID ? this.state.selCompanyID : this.state?.PatientDetails?.employer_id ? this.state?.PatientDetails?.employer_id : '',
                            DisableBtn: false,

                        })
                    }}>
                        <img src={ImagePaths.Close.default} alt="close" style={{ width: '1vw', height: '1vw' }} />
                    </IconButton>
                </Box>
                <Box component={'div'} sx={{ height: '5vw', marginTop: '2vw', display: 'flex' }}>
                    <Box component={'div'} sx={{ width: '13vw', height: '5vw', borderRight: '1px solid lightgray', marginLeft: '1vw' }}>
                        <Typography>Patient Credit</Typography>
                        <FormControlLabel value={'patientcredit'} control={<Radio checked={this.state.paymentCreditType === 'patientcredit'} size='small' />}
                            onClick={(e) => {
                                this.setState({
                                    paymentCreditType: e.target.value,
                                    addInsurance: false,
                                    addCorporate: false,
                                    corpName: null,
                                    employeeNo: '',
                                    insuranceName: null,
                                    insuranceNo: ''
                                })
                            }} label="Patient Credit" />
                    </Box>
                    <Box component={'div'} sx={{ width: '15vw', height: '5vw', borderRight: '1px solid lightgray', marginLeft: '1vw', display: this.state.PatientType === "ip" ? "inline" : "none" }}>
                        <Box display={'flex'} justifyContent={'space-between'}>
                            <Typography>Insurace Credit</Typography>
                            {ins_name ? <Tooltip title={'edit'} placement='top'>
                                <IconButton size='small' onClick={() => {
                                    this.setState({
                                        addInsurance: true,
                                        addCorporate: false,
                                        paymentCreditType: 'insurancecredit',
                                        insuranceName: ins_name,
                                        insuranceNo: PatientDetails?.policy_number,
                                        corpName: null,
                                        employeeNo: ''
                                    })
                                }}>
                                    <img style={{ width: '.9vw', height: '.9vw' }} src={ImagePaths.outlinedEdit.default} alt='eidt' />
                                </IconButton>
                            </Tooltip> : null}
                        </Box>
                        {ins_name ?
                            <FormControlLabel value={'insurancecredit'} control={<Radio checked={this.state.paymentCreditType === 'insurancecredit'} size='small' />} onClick={(e) => { this.setState({ paymentCreditType: e.target.value, addCorporate: false, }) }}
                                label={<Box>
                                    {ins_name?.length > 18 ?
                                        <Tooltip title={ins_name} placement='top'><Typography sx={{ fontSize: '0.8vw', fontWeight: 'bold' }}>{ins_name.slice(0, 18) + '...'}</Typography></Tooltip>
                                        : <Typography sx={{ fontSize: '0.8vw', fontWeight: 'bold' }}>{ins_name}</Typography>}
                                    {ins_no?.length > 18 ?
                                        <Tooltip title={ins_no} placement='top'><Typography sx={{ fontSize: '0.7vw', color: '#888888' }}>{ins_no.slice(0, 18) + '...'}</Typography></Tooltip>
                                        : <Typography sx={{ fontSize: '0.7vw', color: '#888888' }}>{ins_no}</Typography>}
                                </Box>} /> :
                            <Button size='small' variant="text" startIcon={<AddCircleIcon />} sx={{ textTransform: 'capitalize' }}
                                onClick={() => {
                                    this.setState({
                                        addInsurance: true,
                                        addCorporate: false,
                                        paymentCreditType: 'insurancecredit',
                                        insuranceName: null,
                                        insuranceNo: ''
                                    })
                                }}
                            >Add Insurance</Button>}
                    </Box>
                    <Box component={'div'} sx={{ width: '15vw', height: '5vw', marginLeft: '1vw' }}>
                        <Box display={'flex'} justifyContent={'space-between'}>
                            <Typography>Corporate Credit</Typography>
                            {comp_name ? <Tooltip title={'edit'} placement='top'>
                                <IconButton size='small' onClick={() => {
                                    this.setState({
                                        addCorporate: true,
                                        addInsurance: false,
                                        paymentCreditType: 'corporatecredit',
                                        corpName: comp_name,
                                        employeeNo: PatientDetails?.id_no,
                                        insuranceName: null,
                                        insuranceNo: '',

                                    })
                                }}>
                                    <img style={{ width: '.9vw', height: '.9vw' }} src={ImagePaths.outlinedEdit.default} alt='eidt' />
                                </IconButton>
                            </Tooltip> : null}
                        </Box>
                        {comp_name ?
                            <FormControlLabel value={'corporatecredit'} control={<Radio checked={this.state.paymentCreditType === 'corporatecredit'} size='small' />} onClick={(e) => { this.setState({ paymentCreditType: e.target.value, addInsurance: false }) }}
                                label={<Box>
                                    {comp_name?.length > 18 ?
                                        <Tooltip title={comp_name} placement='top'><Typography sx={{ fontSize: '0.8vw', fontWeight: 'bold' }}>{comp_name.slice(0, 18) + '...'}</Typography></Tooltip>
                                        : <Typography sx={{ fontSize: '0.8vw', fontWeight: 'bold' }}>{comp_name}</Typography>}
                                    {employee_no?.length > 18 ?
                                        <Tooltip title={employee_no} placement='top'><Typography sx={{ fontSize: '0.7vw', color: '#888888' }}>{employee_no.slice(0, 18) + '...'}</Typography></Tooltip>
                                        : <Typography sx={{ fontSize: '0.7vw', color: '#888888' }}>{employee_no}</Typography>}
                                </Box>} /> :
                            <Button size='small' variant="text" startIcon={<AddCircleIcon />} sx={{ textTransform: 'capitalize' }}
                                onClick={() => {
                                    this.setState({
                                        addCorporate: true,
                                        addInsurance: false,
                                        paymentCreditType: 'corporatecredit',
                                    })
                                }}
                            >
                                Add Corporate</Button>}
                    </Box>
                </Box>
                {this.state.addInsurance ?
                    <Box marginTop={'1vw'} display={'flex'} height={'5vw'}>
                        <Autocomplete
                            size='small'
                            id="combo-box-demo-ins"
                            options={this.state.insuranceData}
                            getOptionLabel={(options) => typeof (options) === 'string' ? options : options.insurance_name}
                            onChange={(e, value) => {
                                this.setState({
                                    insuranceName: value,
                                })
                            }}
                            value={this.state.insuranceName}
                            sx={{ width: '13vw', marginRight: '1vw' }}
                            renderInput={(params) => <TextField {...params} label="Insurance Company Name" />}
                        />
                        <TextField
                            size='small'
                            label="Insurance No"
                            variant="outlined"
                            sx={{ width: '13vw' }}
                            value={this.state.insuranceNo}
                            onChange={(e) => {
                                let alphanum = CommonValidation.alphanumValidation(e.target.value)
                                if (e.target.value === "" || alphanum) {
                                    this.setState({
                                        insuranceNo: e.target.value
                                    })
                                }
                            }}
                        />
                    </Box> :
                    this.state.addCorporate ?
                        <Box marginTop={'1vw'} display={'flex'} height={'5vw'}>
                            <Autocomplete
                                size='small'
                                id="combo-box-demo-ins"
                                options={this.state.corporateData}
                                getOptionLabel={(options) => typeof (options) === 'string' ? options : options.company_name}
                                onChange={(e, value) => {
                                    this.setState({
                                        corpName: value,
                                    })
                                }}
                                value={this.state.corpName}
                                sx={{ width: '13vw', marginRight: '1vw' }}
                                renderInput={(params) => <TextField {...params} label="Corporate Company Name" />}
                            />
                            <TextField
                                size='small'
                                label="Employee ID"
                                variant="outlined"
                                sx={{ width: '13vw' }}
                                value={this.state.employeeNo}
                                onChange={(e) => {
                                    let alphanum = CommonValidation.alphanumValidation(e.target.value)
                                    if (e.target.value === "" || alphanum) {
                                        this.setState({
                                            employeeNo: e.target.value
                                        })
                                    }
                                }} />
                        </Box> : <Box marginTop={'1vw'} display={'flex'} height={'5vw'}></Box>}
                <Box marginTop={'2vw'} display={'flex'} justifyContent={'flex-end'}>
                    <Stack spacing={2} direction="row">
                        <Button size='small' sx={{ textTransform: 'capitalize' }} variant="outlined"
                            onClick={() => {
                                this.setState({
                                    creditChange: false,
                                    addInsurance: false,
                                    addCorporate: false,
                                    paymentCreditType: this.state.selPayemtnCreditType ? this.state.selPayemtnCreditType : this.state.PatientDetails?.primary_payment_type === "Patient credit" ? 'patientcredit' :
                                        this.state.PatientDetails?.primary_payment_type === "Corprate credit" ? 'corporatecredit' :
                                            this.state.PatientDetails?.primary_payment_type === "Insurance credit" ? 'insurancecredit' : 'patientcredit',
                                    insuranceName: null,
                                    insuranceNo: '',
                                    corpName: null,
                                    employeeNo: '',
                                    selInsName: this.state.selInsName ? this.state.selInsName : this.state.PatientDetails?.insurance_company_name ? this.state.PatientDetails?.insurance_company_name : '',
                                    selPolicyNo: this.state.selPolicyNo ? this.state.selPolicyNo : this.state.PatientDetails?.policy_number ? this.state.PatientDetails?.policy_number : '',
                                    selCompanyName: this.state.selCompanyName ? this.state.selCompanyName : this.state?.PatientDetails?.employer_name ? this.state?.PatientDetails?.employer_name : '',
                                    selEmployeeNo: this.state.selEmployeeNo ? this.state.selEmployeeNo : this.state?.PatientDetails?.id_no ? this.state?.PatientDetails?.id_no : '',
                                    selCompanyID: this.state.selCompanyID ? this.state.selCompanyID : this.state?.PatientDetails?.employer_id ? this.state?.PatientDetails?.employer_id : '',
                                    DisableBtn: false,
                                })
                            }}>Back</Button>
                        <Button size='small' sx={{ textTransform: 'capitalize' }} variant="contained" onClick={() => {
                            if (this.state.addInsurance && (!this.state.insuranceName || !this.state.insuranceNo)) {
                                this.successErrorMessgae("Invalid Insurance Details", 'error')
                            } else if (this.state.addCorporate && (!this.state.corpName || !this.state.employeeNo)) {
                                this.successErrorMessgae("Invalid Corporate Details", 'error')
                            } else {
                                this.state.PatientDetails['is_billed'] = true
                                this.setState({
                                    creditChange: false,
                                    selPayemtnCreditType: this.state.paymentCreditType,
                                    selInsName: this.state.insuranceName?.insurance_name ? this.state.insuranceName?.insurance_name : this.state.selInsName ? this.state.selInsName : this.state.PatientDetails?.insurance_company_name ? this.state.PatientDetails?.insurance_company_name : '',
                                    selPolicyNo: this.state.insuranceNo ? this.state.insuranceNo : this.state.selPolicyNo ? this.state.selPolicyNo : this.state.PatientDetails?.policy_number ? this.state.PatientDetails?.policy_number : '',
                                    selCompanyName: this.state.corpName?.company_name ? this.state.corpName?.company_name : this.state.selCompanyName ? this.state.selCompanyName : this.state?.PatientDetails?.employer_name ? this.state?.PatientDetails?.employer_name : '',
                                    selEmployeeNo: this.state.employeeNo ? this.state.employeeNo : this.state.selEmployeeNo ? this.state.selEmployeeNo : this.state?.PatientDetails?.id_no ? this.state?.PatientDetails?.id_no : '',
                                    selCompanyID: this.state.corpName?.id ? this.state.corpName?.id : this.state.selCompanyID ? this.state.selCompanyID : this.state?.PatientDetails?.employer_id ? this.state?.PatientDetails?.employer_id : '',
                                    PatientDetails: this.state.PatientDetails,
                                    DisableBtn: false,
                                })
                            }
                        }}>Submit</Button>
                    </Stack>
                </Box>
            </Box>
        )
    }

    renderConsultantName = () => {
        var states = this.state
        return (
            <Box>
                <Autocomplete
                    options={this.state.ConsultantDataList}
                    openOnFocus
                    getOptionLabel={(options) => typeof (options) === 'string' ? options : (options.doctor_name) ? `${options.doctor_name} ${options.qualification} ${options.specialization_name}` : null}
                    onChange={(e, value) => {
                        this.setState({
                            consultantName: value?.doctor_name,
                            consultantID: value?.doctor_id
                        })
                    }}
                    // onInputChange={(event, newvalue) => {
                    //         this.setState({ consultantName: newvalue })
                    // }}
                    value={this.state.consultantName || null}
                    size="small"
                    id="combo-box-deo"
                    sx={{ width: "13vw", height: "5vh" }}
                    renderInput={(params) => <TextField {...params} label={'Consultant Name *'} />}
                    renderOption={(props, option) => (
                        <li {...props} key={option?.id}>
                            <Typography fontSize={'0.9vw'}>{`${option.doctor_name ? option.doctor_name : "-"} | ${option.qualification ? option.qualification : "-"} | ${option.specialization_name ? option.specialization_name : "-"}`}</Typography>
                        </li>
                    )}
                />
            </Box>
        )
    }

    ClosePopUp() {
        this.setState({
            BillId: null,
            ReceiptId: null,
            isCreditBill: null,
            triggerSignal: !this.state.triggerSignal,
            ClearPopupopen: false,
            PatientDetails: null,
            PatientType: ""
        }, () => {
            this.ClearFuction()
            if (this.props.history?.location?.state?.service_number) {
                this.getPatientDataSearch("", this.props.history?.location?.state?.service_number)
            }
        })
    }

    render() {

        const { t } = this.props
        let states = this.state
        let FullAmountEntered = ((this.state?.CashAmount ? +this.state?.CashAmount : 0) + (this.state?.CardAmount ? +this.state?.CardAmount : 0) + (this.state?.BankTfrAmount ? +this.state?.BankTfrAmount : 0) + (this.state?.UPIAmount ? +this.state?.UPIAmount : 0) + (this.state?.ChequeAmount ? +this.state?.ChequeAmount : 0)) !== (this.state.totalPayableAmount ? +this.state.totalPayableAmount : 0)
        let isHaveBillingData = (this.state.opState?.opBillingList?.filter((list) => (list.id))?.length > 0 || this.state.ipState?.ipBillingList?.filter((list) => (list.id)).length > 0 || this.state.labState?.billSummaryList?.filter((element) => (element?.lab_test_id || element?.lab_set_id || element?.package_id)).length > 0 || this.state.radiologyState?.radBillingList?.filter((bill) => (bill?.service_id)).length > 0)

        return (
            <Box className="emed_multibill_container">
                <Box className="emed_multibill_header">
                    <Box sx={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', marginLeft: '0.5vw', paddingTop: '0.3vw', width: "38.5dvw" }}>
                        {
                            getCachevalue("patientData") ? <Button startIcon={<ArrowBackIosNewIcon />} sx={{ textTransform: "capitalize", margin: "1vw" }} variant="outlined" onClick={() => {
                                clearCachevalue("isInRadiologyBilling");
                                this.props.history.push({
                                    pathname: '/OPHome/PatientDetails/Billing',
                                })
                            }}>Back to OP Billing</Button> :
                                getCachevalue("IppatientData") ? <Button startIcon={<ArrowBackIosNewIcon />} sx={{ textTransform: "capitalize", margin: "1vw" }} variant="outlined" onClick={() => {
                                    clearCachevalue("isInRadiologyBilling");
                                    this.props.history.push({
                                        pathname: '/IPHome/PatientDetails/ServiceBilling',
                                    })
                                }}>Back to IP Billing</Button> :
                                    <>
                                        <Box sx={{ display: "flex" }}>
                                            <Typography sx={{ fontSize: '0.9vw', fontWeight: 600, marginLeft: '0.7vw' }}>Patient Search</Typography>
                                            {(this.state.UhidOrPhNumber || this.state.IPorIPNumber) ? <Typography sx={{ fontSize: '0.9vw', marginLeft: '0.5vw', color: 'GrayText' }}>{` - ( Click enter to search... )`}</Typography> : null}
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'center', marginLeft: '0.5vw', paddingTop: "0.3vw" }}>
                                            <TextField
                                                size='small'
                                                label={"UHID / Ph No"}
                                                sx={{ width: '18vw', marginRight: "1vw" }}
                                                id="icd-cde"
                                                placeholder='Search by UHID / Ph No'
                                                inputProps={{ maxLength: 20, emed_tid: "UhidorphNumber" }}
                                                variant="outlined"
                                                value={this.state.UhidOrPhNumber}
                                                onChange={(event) => {
                                                    this.setState({
                                                        UhidOrPhNumber: event.target.value
                                                    })
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter' && e.target.value !== '' && e.target.value?.length > 4) {
                                                        this.getPatientDataSearch(this.state.UhidOrPhNumber, "")
                                                    }
                                                }}
                                            />
                                            <TextField
                                                size='small'
                                                label={"OP / IP No"}
                                                sx={{ width: '18vw' }}
                                                inputProps={{ maxLength: 25, emed_tid: 'oporIpNumber' }}
                                                id="icd-cde"
                                                placeholder='Search by OP / IP No'
                                                variant="outlined"
                                                value={this.state.IPorIPNumber}
                                                onChange={(event) => {
                                                    this.setState({
                                                        IPorIPNumber: event.target.value
                                                    })
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter' && e.target.value !== '' && e.target.value?.length > 4) {
                                                        this.getPatientDataSearch("", this.state.IPorIPNumber)
                                                    }
                                                }}
                                            />
                                        </Box>
                                    </>
                        }
                    </Box>
                    <Box sx={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column', padding: "1vw", alignItems: 'center', justifyContent: 'center', marginLeft: '0.5vw' }}>
                        <Typography sx={{ fontSize: '0.9vw', fontWeight: 600 }}>Patient Type</Typography>
                        <Divider />
                        <FormControl sx={{ marginLeft: "1vw" }} disabled>
                            <RadioGroup
                                value={this.state.PatientType}
                                sx={{ display: 'flex', flexDirection: 'row' }}
                                onChange={(e) => {
                                    this.setState({ PatientType: e.target.value })
                                }}
                            >
                                <FormControlLabel value="op" control={<Radio size='small' inputProps={{ 'emed_tid': `all_sessions_radio_testID` }} />} label="OP" />
                                <FormControlLabel value="ip" control={<Radio size='small' inputProps={{ 'emed_tid': `all_sessions_radio_testID` }} />} label="IP" />
                                <FormControlLabel value="individual" control={<Radio size='small' inputProps={{ 'emed_tid': `specific_sessions_radio_testID` }} />} label="Individual" />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    <Box sx={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column', padding: "1vw", alignItems: 'center', justifyContent: 'center', marginLeft: '0.5vw', width: "35dvw" }}>
                        <Typography sx={{ fontSize: '0.9vw', fontWeight: 600 }}>Patient Details</Typography>
                        <Divider />
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', marginLeft: '0.5vw', paddingTop: "0.3vw", visibility: this.state.PatientDetails === null ? "hidden" : "visible", width: "33dvw" }}>
                            <Box width={"15dvw"}><CommonPatientDetails data={this.state.PatientDetails} /></Box>
                            {
                                (this.state.PatientType === "ip" || this.state.PatientType === "op") ?
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography sx={{ fontSize: '0.9vw', fontWeight: 600 }}>{this.state.PatientType === "ip" ? "IP Number" : "OP Number"}</Typography>
                                        <Typography sx={{ fontSize: '0.9vw' }}>{this.state.PatientType === "ip" ? this.state.PatientDetails?.ip_number : this.state.PatientDetails?.op_number} </Typography>
                                    </Box>
                                    :
                                    <Box>{this.renderConsultantName()}</Box>
                            }

                        </Box>
                    </Box>
                </Box>
                <Box className="emed_multibill_content">
                    {(this.state.PatientType === "individual" || this.state.PatientType === "ip") ? null :
                        <MultiOPBilling
                            triggerSignal={this.state.triggerSignal}
                            onStateChange={(newState) => this.debouncedUpdateChildState("opState", newState)}
                            PatientDetails={this.state.PatientDetails}
                            errorMessage={this.errorMessage}
                            successMessage={this.successMessage} />}

                    {(this.state.PatientType === "individual" || this.state.PatientType === "op") ? null :
                        <MultiIPBilling
                            triggerSignal={this.state.triggerSignal}
                            onStateChange={(newState) => this.debouncedUpdateChildState("ipState", newState)}
                            PatientDetails={this.state.PatientDetails}
                            errorMessage={this.errorMessage}
                            infoMessage={this.infoMessage}
                            successMessage={this.successMessage} />}

                    {(this.state.PatientType === "individual" && this.state.radiologyState.radBillingList?.some((list) => list?.service_id)) ? null :
                        <MultiLabBilling
                            triggerSignal={this.state.triggerSignal}
                            onStateChange={(newState) => this.debouncedUpdateChildState("labState", newState)}
                            PatientDetails={this.state.PatientDetails}
                            LabTransmittedItems={this.state.LabTransmittedItems}
                            TransmittedLabId={this.state.TransmittedLabId}
                            errorMessage={this.errorMessage}
                            successMessage={this.successMessage} />}

                    {((this.state.PatientType === "individual" && this.state.labState.billSummaryList?.some((list) => list?.test_name)) || localGetItem("Show_Radiology_Bills_In_FO") !== "true") ? null :
                        <MultiRadBilling
                            triggerSignal={this.state.triggerSignal}
                            onStateChange={(newState) => this.debouncedUpdateChildState("radiologyState", newState)}
                            PatientDetails={this.state.PatientDetails}
                            RadiologyTranmittedItems={this.state.RadiologyTranmittedItems}
                            TransmittedRadiologyId={this.state.TransmittedRadiologyId}
                            errorMessage={this.errorMessage}
                            successMessage={this.successMessage} />}

                </Box>
                <Box className="emed_multibill_footer">
                    <Box sx={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column', padding: "1vw", alignItems: 'flex-start', justifyContent: 'flex-start', marginLeft: '1vw', width: "66.5dvw" }}>
                        <Box ml={"0.5vw"}>
                            <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox checked={this.state.isCash} onChange={(e) => { this.handleCheckBox(e, "isCash") }} size='small' disabled={this.state.totalPayableAmount === 0 ? true : false} />} label={t("Cash")} />
                            {this.state.forCancel ? null : <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox checked={this.state.isCard} onChange={(e) => { this.handleCheckBox(e, "isCard") }} size='small' disabled={this.state.totalPayableAmount === 0 ? true : false} />} label={t("Card")} />}
                            <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox checked={this.state.isUPI} onChange={(e) => { this.handleCheckBox(e, "isUPI") }} size='small' />} label={t("UPI")} disabled={this.state.totalPayableAmount === 0 ? true : false} />
                            <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' sx={{ width: "10vw" }} control={<Checkbox checked={this.state.isBankTranfer} onChange={(e) => { this.handleCheckBox(e, "isBankTranfer") }} size='small' />} label={t("BankTransfer")} disabled={this.state.totalPayableAmount === 0 ? true : false} />
                            {this.state.forCancel ? null : <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox checked={this.state.isCheque} onChange={(e) => { this.handleCheckBox(e, "isCheque") }} size='small' />} label={t("Cheque")} disabled={this.state.totalPayableAmount === 0 ? true : false} />}
                        </Box>

                        <Box component={'div'} display={'flex'} ml={'0.5vw'} overflow={'scroll'} mt={'-0.3vw'} width={'67dvw'}>
                            {
                                this.state.isCash ?
                                    <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                        {this.renderAmountTextFields("CashAmount", "CashAmount", ImagePaths.CashIcon.default)}
                                        {states.isCash && !states.isCard && !states.isUPI && !states.isBankTranfer && !states.isCheque ?
                                            <>
                                                {
                                                    this.state.openCashBalancebox ?
                                                        <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"} alignItems={'center'} justifyContent={"center"}>
                                                            {this.renderNumberTextFields("Cash Collected", "ReceivedCashAmount", true)}
                                                            {this.renderNumberTextFields("Balance Amount", "CashBalanceAmount", true)}
                                                            <Box onClick={() => { this.setState({ openCashBalancebox: false }) }} variant='outlined' sx={{ marginTop: "1vw", marginLeft: "0.5vw", cursor: 'pointer' }} size='small'>
                                                                <img src={ImagePaths.ClearIcon.default} style={{ height: '1.5vw', width: "1.5vw" }} alt='close' />
                                                            </Box>
                                                        </Box> :
                                                        <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"} alignItems={'center'}>
                                                            <IconButton onClick={() => { this.setState({ openCashBalancebox: true }) }}><CalculateIcon color='primary' /></IconButton>
                                                        </Box>
                                                }
                                            </>
                                            : null
                                        }
                                    </Box> : null
                            }
                            {
                                this.state.isCard ?
                                    <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                        {this.renderAmountTextFields("CardAmount", "CardAmount", ImagePaths.CardIcon.default)}
                                        {this.renderNumberTextFields("CardTransactionNumber", "CardTransactionNumber", true)}
                                    </Box>
                                    : null
                            }
                            {
                                this.state.isUPI ?
                                    <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                        {this.renderAmountTextFields("UPIAmount", "UPIAmount", ImagePaths.UPIIcon.default)}
                                        {this.renderNumberTextFields("UPITransactionNumber", "UPINumber", true)}
                                    </Box>
                                    : null
                            }
                            {
                                this.state.isBankTranfer ?
                                    <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                        {this.renderAmountTextFields("BankTransferAmount", "BankTfrAmount", ImagePaths.BankTransferIcon.default)}
                                        {this.renderNumberTextFields("BankTransactionNumber", "BankTfrNumber", true)}
                                    </Box>
                                    : null
                            }
                            {
                                this.state.isCheque ?
                                    <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                        {this.renderAmountTextFields("ChequeAmount", "ChequeAmount", ImagePaths.ChequeIcon.default)}
                                        {this.renderNumberTextFields("ChequeTransactionNumber", "ChequeNumber", true)}
                                    </Box>
                                    : null
                            }
                        </Box>
                    </Box>
                    <Box sx={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column', padding: "0.2vw", alignItems: 'center', justifyContent: 'space-between', marginLeft: '0.5vw', width: "25dvw" }}>
                        {this.BillFooterLabel("Sub Amount", this.state.totalServiceAmount)}
                        {this.BillFooterLabel("Concession Amount", this.state.totalconcessionAmount)}
                        {this.BillFooterLabel("Payable Amount", this.state.totalPayableAmount)}
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '22dvw' }}>
                            <Button sx={{ textTransform: 'capitalize' }} size='small' variant='outlined'
                                onClick={() => {
                                    this.setState(
                                        (prevState) => ({
                                            ClearPopupopen: true
                                        })
                                    );
                                }}
                            >Clear All</Button>
                            <Tooltip title={'Change Credit Type'} placement='top' arrow>
                                <IconButton size='small'
                                    onClick={() => {
                                        this.setState({
                                            creditChange: true,
                                        }, () => {
                                            this.getInsuranceDetails()
                                            this.getCorporateDetails()
                                        })
                                    }}>
                                    <img style={{ width: '1.2vw', height: '1.2vw' }} src={(false) ? ImagePaths.outlinedEditDis.default : ImagePaths.ChangeCredit.default} alt='icon' />
                                </IconButton>
                            </Tooltip>
                            <Button sx={{ textTransform: 'capitalize' }} size='small' variant='contained'
                                disabled={(this.state.CashAmount !== "" || this.state.CardAmount !== "" || this.state.BankTfrAmount !== "" || this.state.UPIAmount !== "" || this.state.ChequeAmount !== "" || !this.state.totalPayableAmount || this.state.DisableBtn)}
                                onClick={() => { this.setState({ DisableBtn: true }, () => { this.ValiadtionBilldata(true) }) }}
                            >Add to Credit</Button>
                            <Button
                                sx={{ textTransform: 'capitalize' }} size='small' variant='contained'
                                onClick={() => { this.setState({ DisableBtn: true }, () => { this.ValiadtionBilldata() }) }}
                                disabled={this.state.DisableBtn || FullAmountEntered || !isHaveBillingData}
                            >Receive Payment</Button>
                        </Box>
                    </Box>
                </Box>
                {this.ClearDataPopup()}
                {this.PatientSelectionPopup()}
                {this.state.creditChange ?
                    <Dialog
                        className="emed_DialogboxOrder"
                        open={this.state.creditChange}
                        onClose={() => { this.setState({ creditChange: false }) }}
                        maxWidth={"md"}
                    >
                        <div className='emed_Dialog_Appoin' style={{ width: '42vw', height: "45vh" }}>
                            {this.renderCreditType()}
                        </div>
                    </Dialog> : null}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.infoMsg ?
                    <ToastMsg
                        severity={'info'}
                        msg={this.state.infoMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.BillId !== null ? <BillSuccessPoPUp MultiBilling={true} InvoiceId={this.state.BillId} Receipt_id={this.state.ReceiptId} ClosePopUp={this.ClosePopUp.bind(this)} successTxt={this.state.isCreditBill ? "Credit Added Successfully !" : "Payment Received Successfully !"} /> : null}
                <Loader loaderOpen={this.state.isLoader} />
            </Box>
        )
    }
}
export default withTranslation()(MultiBillingHome);