import React, { Component } from 'react';
import '../ChartDashboard/ChartHomeCss.css';
import { Box, Typography, Grid, Divider, Button, Drawer, Tooltip, Alert, Skeleton, IconButton, Stack } from '@mui/material';
import { withTranslation } from 'react-i18next';
import ToastMsg from '../../Components/ToastMsg/ToastMsg';
import { ImagePaths } from '../../Utility/ImagePaths';
import { DateTime } from 'luxon';
import LoopIcon from '@mui/icons-material/Loop';
import RevenueCard from '../ChartDashboard/CommonCharts/RevenueCard';
import DoughnutChart from '../ChartDashboard/CommonCharts/DoughnutChart';
import BarChart from '../ChartDashboard/CommonCharts/Barchart';
import {
    vendorColumns, PurchaseSales, BranchWiseOverallRenvenue , OverallPaymentCollection,
    BranchWiseOverallCreditAmount, PharmaPatientDemographics , OverallAppointmentType,
    OPSpecialzationChart, OPDoctorsChart, OPServicesChart, OPLocationChart, TopSellingDrugs,
    mostLeastTest, labLocation, BranchPharmaSalesDemoChart,BranchLabSalesDemoChart,
    BranchTotalPatientCount,
    BranchTotalDoctorCount
} from '../ChartDashboard/CommonCharts/TableColumns';
import ChartDashboardFilter from '../ChartDashboard/ChartDashboardFilter';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import RestAPIService from '../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../Utility/API/Serviceurls';
import { ProfitLossPercent, formatDate } from '../../Components/CommonFunctions/CommonFunctions';
import CountCardView from '../ChartDashboard/CommonCharts/CountCardView';
import CountCards from '../ChartDashboard/CommonCharts/CountCards';

class BranchDetailDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            branchId: this.props?.history?.location?.state?.branchId ?this.props?.history?.location?.state?.branchId : '',
            isErrorMsg: false,
            isErrorMsgText: "",
            successMsg: false,
            successMsgText: "",
            FilterOpen: false,
            AlertText: "",
            FilterDatas: {
                "FromDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "ToDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "DateIndex": 1,
                "CompareFromDate": "",
                "CompareToDate": "",
                "CompareDateIndex": 0,
                "BranchesList": [],
                "ModulesList": ["OP","IP","PHARMACY","LAB"],
                "SelectedBranches": [],
                "SelectedModules": ["OP","IP","PHARMACY","LAB"],
            },
            branchName: '',
            OverallChartDatas: {
                TotalRevenue: {},
                PaymentCollection: {},
                CreditAmount: {},
                AppointmentType: {},
                PatientCount: {},
                DoctorCount: {},
                opPatientCount: {},
            },
            OutPatientChartDatas: {
                SpecialzationChart: {},
                TopDoctorsChart: {},
                TopServicesChart: {},
                LeastServicesChart: {},
                LocationAnalysisChart: {},
            },
            InPatientChartDatas: {
                SpecialzationChart: {},
                TopDoctorsChart: {},
                TopServicesChart: {},
                LeastServicesChart: {},
                LocationAnalysisChart: {},
            },
            PharmacyChartDatas: {
                totalProductsCard: {
                    total_product: 0,
                    total_amount: 0,
                    compare_percent: 0,
                    APILoaded: false
                },
                lowStockCard: {
                    total_product: 0,
                    total_amount: 0,
                    compare_percent: 0,
                    APILoaded: false
                },
                stockExpiryCard: {
                    total_product: 0,
                    total_amount: 0,
                    compare_percent: 0,
                    APILoaded: false
                },
                noMovementCard: {
                    total_product: 0,
                    total_amount: 0,
                    compare_percent: 0,
                    APILoaded: false
                },
                slowMovementCard: {
                    total_product: 0,
                    total_amount: 0,
                    compare_percent: 0,
                    APILoaded: false
                },
                newArrivalCard: {
                    total_product: 0,
                    total_amount: 0,
                    compare_percent: 0,
                    APILoaded: false
                },
                pharmaVendor: {
                    chartData: [],
                    chartLabel: [],
                    tblData: [],
                    total_amount: 0,
                    APILoaded: false
                },
                pharmaPurchase: [],
                pharmaPurchaseAPI: false,
                topSellingDrug: {
                    chartData: [],
                    chartLabel: [],
                    tblData: [],
                    APILoaded: false
                },
                salesDemo: {
                    chartData: [],
                    chartLabel: [],
                    TableData: [],
                    totalAmt: 0,
                },
                pharmaDemographics: [],
                pharmaDemographicsAPILoad: false,
            },
            LabChartDatas: {
                orderedTestCard: {
                    total_patient: 0,
                    total_amount: 0,
                    compare_percent: 0,
                    APILoaded: false
                },
                completedCard: {
                    total_patient: 0,
                    total_amount: 0,
                    compare_percent: 0,
                    APILoaded: false
                },
                salesDemo:{
                    chartData: [],
                    chartLabel: [],
                    TableData: [],
                    totalAmt: 0
                },
                labDemographics: [],
                labDemographicsAPI: false,
                labPurchase: [],
                labPurchaseAPILoaded: false,
                mostPerforming: [],
                locationAnalysis: {
                    chartData: [],
                    chartLabel: [],
                    tblData: [],
                    APILoaded: false
                },
                leastPerforming: [],
                PerforminAPIload: false,
                tablecol: [],
            }
        }
        this.childComponentIds = ["overallRef", "opRef", "ipRef", "pharmaRef", "labRef"];
    }

    componentDidMount() {
        this.setupIntersectionObserver()
    }

    componentDidUpdate = (prevProps) => {
        let history = this.props?.history?.location?.state
        if(prevProps !== this.props){
            this.state.FilterDatas.FromDate = history?.fromDate 
            this.state.FilterDatas.ToDate = history?.toDate 
            this.state.FilterDatas.DateIndex = history?.dateIndex 
            this.setState({FilterDatas : this.state.FilterDatas})
            this.setState({
                branchName: history?.branchName ? history?.branchName : {},
                branchId: history?.branchId ? history?.branchId : '',

            })
        }
    }

    setupIntersectionObserver = () => {
        const options = {
            threshold: 0.25,
        };
        this.observer = new IntersectionObserver(this.handleIntersection, options);
        this.childComponentIds?.forEach((childId) => {
            const childElement = document.getElementById(childId);
            if (childElement) {
                this.observer.observe(childElement);
            }
        });
    }

    componentWillUnmount() {
        this.observer.disconnect();
    }

    handleIntersection = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                const childId = entry.target.id;
                this.ChartDataAPIcall(childId);
                this.observer.unobserve(entry.target);
            }
        });
    }

    ChartDataAPIcall(childId) {
        try {
            switch (childId) {
                case "overallRef":
                    this.OverallApiCalls();
                    break;
                case "opRef":
                    if (this.state.FilterDatas?.SelectedModules.includes("OP")) { this.OutPatientApiCalls() }
                    break;
                case "ipRef":
                    if (this.state.FilterDatas?.SelectedModules.includes("IP")) { this.InPatientApiCalls() }
                    break;
                case "pharmaRef":
                    if (this.state.FilterDatas?.SelectedModules.includes("PHARMACY")) { this.pharmacyApiCalls() }
                    break;
                case "labRef":
                    if (this.state.FilterDatas?.SelectedModules.includes("LAB")) { this.labApiCalls() }
                    break;
            }
        }
        catch (e) {
            this.errorMessage(e?.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    RefreshAllChartsData() {
        this.setState({
            OverallChartDatas: {
                TotalRevenue: {},
                PaymentCollection: {},
                CreditAmount: {},
                AppointmentType: {},
                PatientCount: {},
                DoctorCount: {},
                opPatientCount: {},
            },
            OutPatientChartDatas: {
                SpecialzationChart: {},
                TopDoctorsChart: {},
                TopServicesChart: {},
                LeastServicesChart: {},
                LocationAnalysisChart: {},
            },
            InPatientChartDatas: {
                SpecialzationChart: {},
                TopDoctorsChart: {},
                TopServicesChart: {},
                LeastServicesChart: {},
                LocationAnalysisChart: {},
            },
            PharmacyChartDatas: {
                totalProductsCard: {
                    total_product: 0,
                    total_amount: 0,
                    compare_percent: 0,
                    APILoaded: false
                },
                lowStockCard: {
                    total_product: 0,
                    total_amount: 0,
                    compare_percent: 0,
                    APILoaded: false
                },
                stockExpiryCard: {
                    total_product: 0,
                    total_amount: 0,
                    compare_percent: 0,
                    APILoaded: false
                },
                noMovementCard: {
                    total_product: 0,
                    total_amount: 0,
                    compare_percent: 0,
                    APILoaded: false
                },
                slowMovementCard: {
                    total_product: 0,
                    total_amount: 0,
                    compare_percent: 0,
                    APILoaded: false
                },
                newArrivalCard: {
                    total_product: 0,
                    total_amount: 0,
                    compare_percent: 0,
                    APILoaded: false
                },
                pharmaVendor: {
                    chartData: [],
                    chartLabel: [],
                    tblData: [],
                    total_amount: 0,
                    APILoaded: false
                },
                pharmaPurchase: [],
                pharmaPurchaseAPI: false,
                topSellingDrug: {
                    chartData: [],
                    chartLabel: [],
                    tblData: [],
                    APILoaded: false
                },
                pharmaDemographics: [],
                pharmaDemographicsAPILoad: false,
                salesDemo:{
                    chartData: [],
                    chartLabel: [],
                    TableData: [],
                    totalAmt: 0
                }
            },
            LabChartDatas: {
                orderedTestCard: {
                    total_patient: 0,
                    total_amount: 0,
                    compare_percent: 0,
                    APILoaded: false
                },
                completedCard: {
                    total_patient: 0,
                    total_amount: 0,
                    compare_percent: 0,
                    APILoaded: false
                },
                salesDemo:{
                    chartData: [],
                    chartLabel: [],
                    TableData: [],
                    totalAmt: 0
                },
                labDemographics: [],
                labDemographicsAPI: false,
                labPurchase: [],
                labPurchaseAPILoaded: false,
                mostPerforming: [],
                locationAnalysis: {
                    chartData: [],
                    chartLabel: [],
                    tblData: [],
                    APILoaded: false
                },
                leastPerforming: [],
                PerforminAPIload: false
            }
        }, () => { this.setupIntersectionObserver() })
    }

    sortList = (data, sortBy = "", order) => {
        switch (order) {
            case "asc":
                if (sortBy) {
                    return data.sort((a, b) => a[sortBy] - b[sortBy])
                } else {
                    return data.sort((a, b) => a - b)
                }
            default:
                if (sortBy) {
                    return data.sort((a, b) => b[sortBy] - a[sortBy])
                } else {
                    return data.sort((a, b) => b - a)
                }
        }
    }

    getPatientCount = () => {
        const { FilterDatas, branchId, OverallChartDatas } = this.state;
        const handleApiError = (message) => {
            this.setState((prevState) => ({
                OverallChartDatas: {
                    ...prevState.OverallChartDatas,
                    opPatientCount: {
                        ...prevState.OverallChartDatas.opPatientCount,
                        APILoaded: true,
                    },
                },
            }));
            this.errorMessage(message);
        };

        RestAPIService.getAll(
            `${Serviceurls.CHART_OP_PATIENT_COUNT}?from_date=${FilterDatas?.FromDate}&to_date=${FilterDatas?.ToDate}&cmp_from_date=${FilterDatas?.CompareFromDate}&cmp_to_date=${FilterDatas?.CompareToDate}&branch_id=${branchId}`
        )
            .then((response) => {
                if (response?.data?.status === "success") {
                    const { patient_count, cmp_patient_count } = response?.data;
                    const updatedChartData = { ...OverallChartDatas };
                    updatedChartData.opPatientCount = {
                        ...updatedChartData.opPatientCount,
                        patient_count: {
                            new_patient: patient_count?.new_patient || 0,
                            review_patient: patient_count?.review_patient || 0,
                            establish_patient: patient_count?.establish_patient || 0,
                        },
                        ComparePercent: {
                            newPatientPercent: ProfitLossPercent(patient_count?.new_patient, cmp_patient_count?.new_patient),
                            reviewPatientPercent: ProfitLossPercent(patient_count?.review_patient, cmp_patient_count?.review_patient),
                            oldPatientPercent: ProfitLossPercent(patient_count?.establish_patient, cmp_patient_count?.establish_patient),
                        },
                        APILoaded: true,
                    };
                    this.setState({ OverallChartDatas: updatedChartData });
                } else {
                    handleApiError(response.data.message);
                }
            })
            .catch((error) => {
                handleApiError(error.message);
            });
    }; 

    OverallApiCalls() {
        try {
            let states = this.state
            let OverAllChartsAPIQuery = "?from_date=" + states.FilterDatas?.FromDate +
                "&to_date=" + states.FilterDatas?.ToDate +
                "&module_type=" + states.FilterDatas?.SelectedModules +
                "&branch_id=" + states?.branchId

            RestAPIService.getAll(Serviceurls.CHART_OVERALL_REVENUE +
                "?from_date=" + states.FilterDatas?.FromDate +
                "&to_date=" + states.FilterDatas?.ToDate +
                "&cmp_from_date=" + states.FilterDatas?.CompareFromDate +
                "&cmp_to_date=" + states.FilterDatas?.CompareToDate +
                "&module_type=" + states.FilterDatas?.SelectedModules +
                "&branch_id=" + states?.branchId).
                then((response) => {
                    if (response.data.status === "success" && response?.data?.data?.length > 0) {
                        let initialObj = {
                            OP: 0,
                            IP: 0,
                            Lab: 0,
                            Pharmacy: 0,
                            Radiology: 0,
                            OT: 0,
                        }
                        let totalAmount = response?.data?.data.reduce((acc, cur, i) => {
                            acc["OP"] = parseFloat(acc.OP) + parseFloat(cur.op_revenue ? cur.op_revenue : 0);
                            acc["IP"] = parseFloat(acc.IP) + parseFloat(cur.ip_revenue ? cur.ip_revenue : 0);
                            acc["Lab"] = parseFloat(acc.Lab) + parseFloat(cur.lab_revenue ? cur.lab_revenue : 0);
                            acc["Pharmacy"] = parseFloat(acc.Pharmacy) + parseFloat(cur.pharmacy_revenue ? cur.pharmacy_revenue : 0);
                            acc["Radiology"] = parseFloat(acc.Radiology) + parseFloat(cur.radiology_revenue ? cur.radiology_revenue : 0);
                            acc["OT"] = parseFloat(acc.OT) + parseFloat(cur.ot_revenue ? cur.ot_revenue : 0);
                            return acc;
                        }, initialObj)
                        let tableDataArray = []
                        response?.data?.data?.forEach((element) => {
                            element.overall_profit = element?.overall_revenue - element?.overall_expense
                            tableDataArray.push(element)
                        })
                        states["OverallChartDatas"]["TotalRevenue"]["TableData"] = tableDataArray
                        states["OverallChartDatas"]["TotalRevenue"]["ChartLabel"] = Object.keys(totalAmount)
                        states["OverallChartDatas"]["TotalRevenue"]["ChartData"] = Object.values(totalAmount)
                        states["OverallChartDatas"]["TotalRevenue"]["TotalObject"] = totalAmount
                        states["OverallChartDatas"]["TotalRevenue"]["TotalRevenueAmount"] = response?.data?.overall_revenue ? response?.data?.overall_revenue : 0
                        states["OverallChartDatas"]["TotalRevenue"]["TotalExpenseAmount"] = response?.data?.overall_expense ? response?.data?.overall_expense : 0
                        states["OverallChartDatas"]["TotalRevenue"]["BranchName"] = response?.data?.data?.length > 0 ? response?.data?.data[0]?.branch_name : "-"

                        let CompareIntailObj = {
                            CompareOP: 0,
                            CompareIP: 0,
                            Comparelab: 0,
                            ComparePharma: 0,
                            OP: 0,
                            IP: 0,
                            Lab: 0,
                            pharma: 0,
                            other: 0,
                            revenue: 0,
                            expence: 0,
                            CompareExpence: 0,
                        }
                        let CompareArray = response?.data?.data.reduce((acc, cur, i) => {
                            acc["CompareOP"] = parseFloat(acc.CompareOP) + parseFloat(cur.cmp_op_revenue ? cur.cmp_op_revenue : 0);
                            acc["CompareIP"] = parseFloat(acc.CompareIP) + parseFloat(cur.cmp_ip_revenue ? cur.cmp_ip_revenue : 0);
                            acc["Comparelab"] = parseFloat(acc.Comparelab) + parseFloat(cur.cmp_lab_revenue ? cur.cmp_lab_revenue : 0);
                            acc["ComparePharma"] = parseFloat(acc.ComparePharma) + parseFloat(cur.cmp_pharmacy_revenue ? cur.cmp_pharmacy_revenue : 0);
                            acc["CompareRevenue"] = parseFloat(acc.ComparePharma) + parseFloat(cur.cmp_overall_revenue ? cur.cmp_overall_revenue : 0);
                            acc["OP"] = parseFloat(acc.OP) + parseFloat(cur.op_revenue ? cur.op_revenue : 0);
                            acc["IP"] = parseFloat(acc.IP) + parseFloat(cur.ip_revenue ? cur.ip_revenue : 0);
                            acc["Lab"] = parseFloat(acc.Lab) + parseFloat(cur.lab_revenue ? cur.lab_revenue : 0);
                            acc["pharma"] = parseFloat(acc.pharma) + parseFloat(cur.pharmacy_revenue ? cur.pharmacy_revenue : 0);
                            acc["revenue"] = parseFloat(acc.revenue) + parseFloat(cur.overall_revenue ? cur.overall_revenue : 0);
                            acc["expence"] = parseFloat(acc.expence) + parseFloat(cur.overall_expense ? cur.overall_expense : 0);
                            acc["CompareExpence"] = parseFloat(acc.CompareExpence) + parseFloat(cur.cmp_overall_expense ? cur.cmp_overall_expense : 0);
                            return acc;
                        }, CompareIntailObj)

                        states["OverallChartDatas"]["TotalRevenue"]["ComparePercent"] = {
                            OPComparePercent: ProfitLossPercent(CompareArray?.OP, CompareArray?.CompareOP),
                            IPComparePercent: ProfitLossPercent(CompareArray?.IP, CompareArray?.CompareIP),
                            PharmacyComparePercent: ProfitLossPercent(CompareArray?.pharma, CompareArray?.ComparePharma),
                            LabComparePercent: ProfitLossPercent(CompareArray?.Lab, CompareArray?.Comparelab),
                            TotalComparePercent: ProfitLossPercent(CompareArray?.revenue, CompareArray?.CompareRevenue),
                            ExpenceComparePercent: ProfitLossPercent(CompareArray?.expence, CompareArray?.CompareExpence),
                        }
                        states["OverallChartDatas"]["TotalRevenue"]["APILoaded"] = true
                        this.setState({ states })
                    }
                    else {
                        states["OverallChartDatas"]["TotalRevenue"]["APILoaded"] = true
                        this.setState({ states })
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    states["OverallChartDatas"]["TotalRevenue"]["APILoaded"] = true
                    this.setState({ states })
                    this.errorMessage(error.message)
                })

            RestAPIService.getAll(Serviceurls.CHART_OVERALL_PAYMENTS + OverAllChartsAPIQuery).
                then((response) => {
                    if (response.data.status === "success") {
                        states["OverallChartDatas"]["PaymentCollection"]["TableData"] = response?.data?.data
                        states["OverallChartDatas"]["PaymentCollection"]["TotalCredit"] = response?.data?.data
                        states["OverallChartDatas"]["PaymentCollection"]["ChartData"] = [
                            response?.data?.total_cash,
                            response?.data?.total_card,
                            response?.data?.total_upi,
                            response?.data?.total_cheque,
                            response?.data?.total_bank_transfer,
                        ]
                        states["OverallChartDatas"]["PaymentCollection"]["ChartLabel"] = ["Cash", "Card", "UPI", "Cheque", "Bank Trans"]
                        states["OverallChartDatas"]["PaymentCollection"]["APILoaded"] = true
                        this.setState({ states })
                    }
                    else {
                        states["OverallChartDatas"]["PaymentCollection"]["APILoaded"] = true
                        this.setState({ states })
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    states["OverallChartDatas"]["PaymentCollection"]["APILoaded"] = true
                    this.setState({ states })
                    this.errorMessage(error.message)
                })

            RestAPIService.getAll(Serviceurls.CHART_OVERALL_CREDIT_AMOUNT + OverAllChartsAPIQuery).
                then((response) => {
                    if (response.data.status === "success") {
                        states["OverallChartDatas"]["CreditAmount"]["TableData"] = response?.data?.data
                        states["OverallChartDatas"]["CreditAmount"]["ChartData"] = [
                            response?.data?.total_op_credit,
                            response?.data?.total_ip_credit,
                            response?.data?.total_lab_credit,
                            response?.data?.total_pharmacy_credit,
                            response?.data?.total_rad_credit,
                        ]
                        states["OverallChartDatas"]["CreditAmount"]["ChartLabel"] = ["OP", "IP", "Lab", "Pharmacy","Radiology"]
                        states["OverallChartDatas"]["CreditAmount"]["TotalCreditAmount"] = response?.data?.total_op_credit + response?.data?.total_ip_credit + response?.data?.total_pharmacy_credit + response?.data?.total_lab_credit + response?.data?.total_rad_credit
                        states["OverallChartDatas"]["CreditAmount"]["APILoaded"] = true
                        this.setState({ states })
                    }
                    else {
                        states["OverallChartDatas"]["CreditAmount"]["APILoaded"] = true
                        this.setState({ states })
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    states["OverallChartDatas"]["CreditAmount"]["APILoaded"] = true
                    this.setState({ states })
                    this.errorMessage(error.message)
                })

            //Call only when OP Module is Selected
            if (this.state.FilterDatas?.SelectedModules?.includes("OP")) {
                RestAPIService.getAll(Serviceurls.CHART_OVERALL_APPOINTMENT_TYPE_COUNT + OverAllChartsAPIQuery).
                    then((response) => {
                        if (response.data.status === "success") {
                            let FinalData = this.ChangeIntoSingleArray(response?.data?.data, "-", "des")
                            states["OverallChartDatas"]["AppointmentType"]["TableData"] = FinalData?.TableData
                            states["OverallChartDatas"]["AppointmentType"]["ChartData"] = FinalData?.ChartData
                            states["OverallChartDatas"]["AppointmentType"]["ChartLabel"] = FinalData?.ChartLabel
                            states["OverallChartDatas"]["AppointmentType"]["APILoaded"] = true
                            this.setState({ states })
                        }
                        else {
                            states["OverallChartDatas"]["AppointmentType"]["APILoaded"] = true
                            this.setState({ states })
                            this.errorMessage(response.data.message)
                        }
                    }).catch((error) => {
                        states["OverallChartDatas"]["AppointmentType"]["APILoaded"] = true
                        this.setState({ states })
                        this.errorMessage(error.message)
                    })
                    this.getPatientCount();
            }

            RestAPIService.getAll(Serviceurls.BRANCH_MOD_TOTAL_PATIENT_DOC_COUNT + OverAllChartsAPIQuery).
                then((response) => {
                    if (response.data.status === "success") {

                        states["OverallChartDatas"]["PatientCount"]["TableData"] = response?.data?.total_patient?.data
                        states["OverallChartDatas"]["PatientCount"]["ChartData"] = [
                            response?.data?.total_patient?.total_op_count,
                            response?.data?.total_patient?.total_ip_count,
                        ]
                        states["OverallChartDatas"]["PatientCount"]["ChartLabel"] = ["OP", "IP"]
                        states["OverallChartDatas"]["PatientCount"]["TotalPatientCount"] = response?.data?.total_patient?.total_op_count + response?.data?.total_patient?.total_ip_count
                        states["OverallChartDatas"]["PatientCount"]["APILoaded"] = true

                        let FinalData = this.ChangeIntoSingleArray(response?.data?.total_doctors, "-")
                        states["OverallChartDatas"]["DoctorCount"]["TableData"] = FinalData?.TableData
                        states["OverallChartDatas"]["DoctorCount"]["ChartData"] = FinalData?.ChartData
                        states["OverallChartDatas"]["DoctorCount"]["ChartLabel"] = FinalData?.ChartLabel
                        states["OverallChartDatas"]["DoctorCount"]["APILoaded"] = true

                        this.setState({ states })
                    }
                    else {
                        states["OverallChartDatas"]["PatientCount"]["APILoaded"] = true
                        states["OverallChartDatas"]["DoctorCount"]["APILoaded"] = true
                        this.setState({ states })
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    states["OverallChartDatas"]["PatientCount"]["APILoaded"] = true
                    states["OverallChartDatas"]["DoctorCount"]["APILoaded"] = true
                    this.setState({ states })
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e?.message)
        }
    }

    OutPatientApiCalls() {
        try {
            let states = this.state
            let OutPatientsChartsAPIQuery = "?from_date=" + states.FilterDatas?.FromDate +
                "&to_date=" + states.FilterDatas?.ToDate +
                "&branch_id=" + states?.branchId
            RestAPIService.getAll(Serviceurls.CHART_OP_SPECIALZATION + OutPatientsChartsAPIQuery).
                then((response) => {
                    if (response.data.status === "success") {
                        let FinalData = this.ChangeIntoSingleArray(response?.data?.data, "-", "des")
                        states["OutPatientChartDatas"]["SpecialzationChart"]["TableData"] = FinalData?.TableData
                        states["OutPatientChartDatas"]["SpecialzationChart"]["ChartData"] = FinalData?.ChartData
                        states["OutPatientChartDatas"]["SpecialzationChart"]["ChartLabel"] = FinalData?.ChartLabel
                        states["OutPatientChartDatas"]["SpecialzationChart"]["APILoaded"] = true
                        this.setState({ states })
                    }
                    else {
                        states["OutPatientChartDatas"]["SpecialzationChart"]["APILoaded"] = true
                        this.setState({ states })
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    states["OutPatientChartDatas"]["SpecialzationChart"]["APILoaded"] = true
                    this.setState({ states })
                    this.errorMessage(error.message)
                })

            RestAPIService.getAll(Serviceurls.CHART_OP_TOP_DOCTORS + OutPatientsChartsAPIQuery).
                then((response) => {
                    if (response.data.status === "success") {
                        let FinalData = this.ChangeIntoSingleArray(response?.data?.data, "-", "des")
                        states["OutPatientChartDatas"]["TopDoctorsChart"]["TableData"] = FinalData?.TableData
                        states["OutPatientChartDatas"]["TopDoctorsChart"]["ChartData"] = FinalData?.ChartData
                        states["OutPatientChartDatas"]["TopDoctorsChart"]["ChartLabel"] = FinalData?.ChartLabel
                        states["OutPatientChartDatas"]["TopDoctorsChart"]["APILoaded"] = true
                        this.setState({ states })
                    }
                    else {
                        states["OutPatientChartDatas"]["TopDoctorsChart"]["APILoaded"] = true
                        this.setState({ states })
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    states["OutPatientChartDatas"]["TopDoctorsChart"]["APILoaded"] = true
                    this.setState({ states })
                    this.errorMessage(error.message)
                })

            RestAPIService.getAll(Serviceurls.CHART_OP_LOCATION_ANALYSIS + OutPatientsChartsAPIQuery).
                then((response) => {
                    if (response.data.status === "success") {
                        let FinalData = this.ChangeIntoSingleArray(response?.data?.data, "No Location", "des")
                        states["OutPatientChartDatas"]["LocationAnalysisChart"]["TableData"] = FinalData?.TableData
                        states["OutPatientChartDatas"]["LocationAnalysisChart"]["ChartData"] = FinalData?.ChartData
                        states["OutPatientChartDatas"]["LocationAnalysisChart"]["ChartLabel"] = FinalData?.ChartLabel
                        states["OutPatientChartDatas"]["LocationAnalysisChart"]["APILoaded"] = true
                        this.setState({ states })
                    }
                    else {
                        states["OutPatientChartDatas"]["LocationAnalysisChart"]["APILoaded"] = true
                        this.setState({ states })
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    states["OutPatientChartDatas"]["LocationAnalysisChart"]["APILoaded"] = true
                    this.setState({ states })
                    this.errorMessage(error.message)
                })

            RestAPIService.getAll(Serviceurls.CHART_OP_SERVICES + OutPatientsChartsAPIQuery).
                then((response) => {
                    if (response.data.status === "success") {
                        let FinalData = this.ChangeIntoSingleArray(response?.data?.data, "-", "des")
                        states["OutPatientChartDatas"]["TopServicesChart"]["TableData"] = FinalData?.TableData?.slice(0, 50) // Slice only First 50 datas
                        states["OutPatientChartDatas"]["TopServicesChart"]["ChartData"] = FinalData?.ChartData
                        states["OutPatientChartDatas"]["TopServicesChart"]["ChartLabel"] = FinalData?.ChartLabel
                        states["OutPatientChartDatas"]["TopServicesChart"]["APILoaded"] = true
                        this.setState({ states })

                        let LeastFinalData = this.ChangeIntoSingleArray(response?.data?.data, "-", "asc")
                        states["OutPatientChartDatas"]["LeastServicesChart"]["TableData"] = LeastFinalData?.TableData?.slice(0, 50) // Slice only First 50 datas
                        states["OutPatientChartDatas"]["LeastServicesChart"]["ChartData"] = LeastFinalData?.ChartData
                        states["OutPatientChartDatas"]["LeastServicesChart"]["ChartLabel"] = LeastFinalData?.ChartLabel
                        states["OutPatientChartDatas"]["LeastServicesChart"]["APILoaded"] = true
                        this.setState({ states })
                    }
                    else {
                        states["OutPatientChartDatas"]["TopServicesChart"]["APILoaded"] = true
                        states["OutPatientChartDatas"]["LeastServicesChart"]["APILoaded"] = true
                        this.setState({ states })
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    states["OutPatientChartDatas"]["TopServicesChart"]["APILoaded"] = true
                    states["OutPatientChartDatas"]["LeastServicesChart"]["APILoaded"] = true
                    this.setState({ states })
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e?.message)
        }
    }

    InPatientApiCalls() {
        try {
            let states = this.state
            let OutPatientsChartsAPIQuery = "?from_date=" + states.FilterDatas?.FromDate +
                "&to_date=" + states.FilterDatas?.ToDate +
                "&branch_id=" + states?.branchId
            RestAPIService.getAll(Serviceurls.CHART_IP_SPECIALZATION + OutPatientsChartsAPIQuery).
                then((response) => {
                    if (response.data.status === "success") {
                        let FinalData = this.ChangeIntoSingleArray(response?.data?.data, "-", "des")
                        states["InPatientChartDatas"]["SpecialzationChart"]["TableData"] = FinalData?.TableData
                        states["InPatientChartDatas"]["SpecialzationChart"]["ChartData"] = FinalData?.ChartData
                        states["InPatientChartDatas"]["SpecialzationChart"]["ChartLabel"] = FinalData?.ChartLabel
                        states["InPatientChartDatas"]["SpecialzationChart"]["APILoaded"] = true
                        this.setState({ states })
                    }
                    else {
                        states["InPatientChartDatas"]["SpecialzationChart"]["APILoaded"] = true
                        this.setState({ states })
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    states["InPatientChartDatas"]["SpecialzationChart"]["APILoaded"] = true
                    this.setState({ states })
                    this.errorMessage(error.message)
                })

            RestAPIService.getAll(Serviceurls.CHART_IP_TOP_DOCTORS + OutPatientsChartsAPIQuery).
                then((response) => {
                    if (response.data.status === "success") {
                        let FinalData = this.ChangeIntoSingleArray(response?.data?.data, "-", "des")
                        states["InPatientChartDatas"]["TopDoctorsChart"]["TableData"] = FinalData?.TableData
                        states["InPatientChartDatas"]["TopDoctorsChart"]["ChartData"] = FinalData?.ChartData
                        states["InPatientChartDatas"]["TopDoctorsChart"]["ChartLabel"] = FinalData?.ChartLabel
                        states["InPatientChartDatas"]["TopDoctorsChart"]["APILoaded"] = true
                        this.setState({ states })
                    }
                    else {
                        states["InPatientChartDatas"]["TopDoctorsChart"]["APILoaded"] = true
                        this.setState({ states })
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    states["InPatientChartDatas"]["TopDoctorsChart"]["APILoaded"] = true
                    this.setState({ states })
                    this.errorMessage(error.message)
                })

            RestAPIService.getAll(Serviceurls.CHART_IP_LOCATION_ANALYSIS + OutPatientsChartsAPIQuery).
                then((response) => {
                    if (response.data.status === "success") {
                        let FinalData = this.ChangeIntoSingleArray(response?.data?.data, "No Location", "des")
                        states["InPatientChartDatas"]["LocationAnalysisChart"]["TableData"] = FinalData?.TableData
                        states["InPatientChartDatas"]["LocationAnalysisChart"]["ChartData"] = FinalData?.ChartData
                        states["InPatientChartDatas"]["LocationAnalysisChart"]["ChartLabel"] = FinalData?.ChartLabel
                        states["InPatientChartDatas"]["LocationAnalysisChart"]["APILoaded"] = true
                        this.setState({ states })
                    }
                    else {
                        states["InPatientChartDatas"]["LocationAnalysisChart"]["APILoaded"] = true
                        this.setState({ states })
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    states["InPatientChartDatas"]["LocationAnalysisChart"]["APILoaded"] = true
                    this.setState({ states })
                    this.errorMessage(error.message)
                })

            RestAPIService.getAll(Serviceurls.CHART_IP_SERVICES + OutPatientsChartsAPIQuery).
                then((response) => {
                    if (response.data.status === "success") {
                        let FinalData = this.ChangeIntoSingleArray(response?.data?.data, "-", "des")
                        states["InPatientChartDatas"]["TopServicesChart"]["TableData"] = FinalData?.TableData?.slice(0, 50)
                        states["InPatientChartDatas"]["TopServicesChart"]["ChartData"] = FinalData?.ChartData
                        states["InPatientChartDatas"]["TopServicesChart"]["ChartLabel"] = FinalData?.ChartLabel
                        states["InPatientChartDatas"]["TopServicesChart"]["APILoaded"] = true
                        this.setState({ states })

                        let LeastFinalData = this.ChangeIntoSingleArray(response?.data?.data, "-", "asc")
                        states["InPatientChartDatas"]["LeastServicesChart"]["TableData"] = LeastFinalData?.TableData?.slice(0, 50)
                        states["InPatientChartDatas"]["LeastServicesChart"]["ChartData"] = LeastFinalData?.ChartData
                        states["InPatientChartDatas"]["LeastServicesChart"]["ChartLabel"] = LeastFinalData?.ChartLabel
                        states["InPatientChartDatas"]["LeastServicesChart"]["APILoaded"] = true
                        this.setState({ states })
                    }
                    else {
                        states["InPatientChartDatas"]["TopServicesChart"]["APILoaded"] = true
                        states["InPatientChartDatas"]["LeastServicesChart"]["APILoaded"] = true
                        this.setState({ states })
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    states["InPatientChartDatas"]["TopServicesChart"]["APILoaded"] = true
                    states["InPatientChartDatas"]["LeastServicesChart"]["APILoaded"] = true
                    this.setState({ states })
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e?.message)
        }
    }

    ChangeIntoSingleArray(APIdata, ElseKey = "", SortOrder = "") {
        try {
            let aggregatedData = {};
            APIdata.forEach(list => {
                list.type_data.forEach(type => {
                    const Type = type.title || ElseKey;
                    if (aggregatedData[Type]) {
                        aggregatedData[Type] += type.values;
                    } else {
                        aggregatedData[Type] = type.values;
                    }
                });
            });
            let FinalTotalArray = Object.entries(aggregatedData).map(([Type, values]) => ({ "title": Type, values }));
            if (SortOrder === "asc") {
                FinalTotalArray = FinalTotalArray.sort((a, b) => a.values - b.values);
            } else if (SortOrder === 'des') {
                FinalTotalArray = FinalTotalArray.sort((a, b) => b.values - a.values);
            }
            let ChartData = []
            FinalTotalArray.forEach((item) => (ChartData.push(item?.values)))
            let ChartLabel = []
            FinalTotalArray.forEach((item) => (ChartLabel.push(item?.title ? item?.title.replace(/Appointment/g, '') : ElseKey)))
            let rows = [];
            // Extracting Table datas
            APIdata?.forEach(item => {
                if (item?.type_data?.length > 0) {
                    rows = [...rows, ...item?.type_data?.map((type, index) => ({
                        title: type.title ? type.title : ElseKey,
                        values: type?.values,
                        branch_name: item?.branch_name,
                        branch_id: item?.branch_id,
                    }))]
                }
            })
            if (SortOrder === "asc") {
                rows = rows.sort((a, b) => a.values - b.values);
            } else if (SortOrder === 'des') {
                rows = rows.sort((a, b) => b.values - a.values);
            }
            return { "TableData": rows, "ChartData": ChartData, "ChartLabel": ChartLabel }
        }
        catch (e) {
            console.log(e?.message);
            return { "TableData": [], "ChartData": [], "ChartLabel": [] }
        }
    }

    datastoArray = (apiData, innerData, key1, key2, key3 = "", key4 = "") => {
        let rows = [];
        try {
            if (apiData?.length > 0) {
                // Extracting Table datas
                apiData?.forEach(item => {
                    if (item[innerData]?.length > 0) {
                        rows = [...rows, ...item[innerData]?.map((list, i) => ({
                            branch_name: item?.branch_name,
                            [key1]: list[key1],
                            [key2]: list[key2],
                            [key3]: key3 ? list[key3] : "",
                            [key4]: key4 ? list[key4] : ""
                        }))]
                    };
                })
            }
            return rows;
        } catch (e) {
            console.log(e?.message);
            return rows;
        }
    }

    pharmacyApiCalls() {
        let { PharmacyChartDatas, FilterDatas, branchId } = this.state;
        let Getquery = `?from_date=${FilterDatas?.FromDate}&to_date=${FilterDatas?.ToDate}&branch_id=${branchId}`;

        try {

            // Vendor Api call
            RestAPIService.getAll(Serviceurls.CHART_PHARMA_VENDOR + Getquery)
                .then((response) => {
                    if (response?.data?.status === "success") {
                        let chart_data = []
                        let chart_label = []
                        let tableDatas = []
                        let data = response?.data?.data
                        let vendorData = {}

                        data[0]?.top_five?.forEach((list, i) => {
                            chart_label.push(list?.vendor__name)
                            chart_data?.push(list?.total_paid_amount)
                        })
                        vendorData.ChartValue = chart_data
                        vendorData.ChartLabel = chart_label
                        vendorData.branch_name = data[0]?.branch_name
                        vendorData.totalAmt = data[0]?.total_amount


                        tableDatas = data?.flatMap(item =>
                            item.vendor_data?.map(type => ({
                                branch_id: item.branch_id,
                                branch_name: item.branch_name,
                                vendor_id: type?.vendor_id,
                                vendor__name: type.vendor__name,
                                total_paid_amount: type.total_paid_amount
                            }))
                        );
                        vendorData.tableData = tableDatas

                        PharmacyChartDatas.pharmaVendor.chartData = vendorData.ChartValue.length > 0 ? vendorData.ChartValue : [];
                        PharmacyChartDatas.pharmaVendor.chartLabel = vendorData.ChartLabel.length > 0 ? vendorData.ChartLabel : [];
                        PharmacyChartDatas.pharmaVendor.tblData = vendorData.tableData.length > 0 ? vendorData.tableData : [];
                        PharmacyChartDatas.pharmaVendor.total_amount = vendorData.totalAmt ? +vendorData.totalAmt.toFixed(2) : 0;
                        PharmacyChartDatas.pharmaVendor.APILoaded = true;
                        this.setState({
                            PharmacyChartDatas
                        })

                    }
                    else {
                        PharmacyChartDatas.pharmaVendor.APILoaded = true;
                        this.setState({
                            PharmacyChartDatas
                        })
                        this.errorMessage(response?.data?.message)
                    }
                }).catch((error) => {
                    PharmacyChartDatas.pharmaVendor.APILoaded = true;
                    this.setState({
                        PharmacyChartDatas
                    })
                    this.errorMessage(error.message)
                })

            // Purchase Vs Sales Api call
            RestAPIService.getAll(Serviceurls.CHART_PHARMA_SALE_PURCHASE + Getquery)
                .then((response) => {
                    let Data = response.data;
                    let list = Data?.data?.length > 0 ? Data?.data : [];
                    if (Data.status === "success") {
                        PharmacyChartDatas.pharmaPurchase = list;
                        PharmacyChartDatas.pharmaPurchaseAPI = true;
                        this.setState({
                            PharmacyChartDatas
                        })
                    }
                }).catch((error) => {
                    PharmacyChartDatas.pharmaPurchaseAPI = true;
                    this.setState({
                        PharmacyChartDatas
                    })
                    this.errorMessage(error.message)
                })

            // Top selling drugs Api call
            RestAPIService.getAll(Serviceurls.CHART_PHARMA_TOP_SELLING + Getquery)
                .then((response) => {
                    let Data = response.data;
                    if (Data.status === "success") {
                        let list = Data?.data?.length > 0 ? Data?.data : [];
                        let rows = [], chartData = [], chartLabel = [];

                        rows = this.datastoArray(list, "drug_data", "branch_id", "drug_ms__drug_name", "drug_counnt", "drug_amount");

                        // Extracting chart data
                        let sortedItem = this.sortList([...rows], "drug_counnt");
                        let slicedData = sortedItem?.slice(0, 6);

                        if (slicedData?.length > 0) {
                            slicedData?.forEach(data => {
                                chartData.push(data?.drug_counnt ? data?.drug_counnt : 0);
                                chartLabel.push(data?.drug_ms__drug_name ? data?.drug_ms__drug_name : "");
                            })
                        }

                        //Assigning values to state
                        PharmacyChartDatas.topSellingDrug.tblData = sortedItem?.length > 0 ? sortedItem : [];
                        PharmacyChartDatas.topSellingDrug.chartData = chartData?.length > 0 ? chartData : [];
                        PharmacyChartDatas.topSellingDrug.chartLabel = chartLabel?.length > 0 ? chartLabel : [];
                        PharmacyChartDatas.topSellingDrug.APILoaded = true;
                        this.setState({
                            PharmacyChartDatas
                        })
                    }
                }).catch((error) => {
                    PharmacyChartDatas.topSellingDrug.APILoaded = true;
                    this.setState({
                        PharmacyChartDatas
                    })
                    this.errorMessage(error.message)
                })

            // Patient Demographics Api call
            RestAPIService.getAll(Serviceurls.CHART_PHARMA_DEMOGRAPHICS + Getquery)
                .then((response) => {
                    let Data = response.data;
                    let list = Data?.data?.length > 0 ? Data?.data : [];
                    if (Data.status === "success") {
                        let salesDemoChart = [list[0]?.total_op_amount,list[0]?.total_inpatient_amount, list[0]?.total_self_amount ]
                        let totalAmount = list[0]?.total_op_amount + list[0]?.total_inpatient_amount + list[0]?.total_self_amount 
                        PharmacyChartDatas.salesDemo.chartData = salesDemoChart
                        PharmacyChartDatas.salesDemo.chartLabel = ["OP", "IP", "Self"]
                        PharmacyChartDatas.salesDemo.TableData = list
                        PharmacyChartDatas.salesDemo.totalAmt = totalAmount
                        PharmacyChartDatas.pharmaDemographics = list
                        PharmacyChartDatas.pharmaDemographicsAPILoad = true;
                        this.setState({
                            PharmacyChartDatas: this.state.PharmacyChartDatas
                        })
                    }
                }).catch((error) => {
                    PharmacyChartDatas.pharmaDemographicsAPILoad = true;
                    this.setState({
                        PharmacyChartDatas
                    })
                    this.errorMessage(error.message)
                })

            // Pharmacy card data Api calls

            // No movement drugs
            RestAPIService.getAll(Serviceurls.CHART_PHARMA_NOMOVEMENT + Getquery)
                .then((response) => {
                    let Data = response.data;
                    if (Data.status === "success") {

                        let total_amount1 = Data?.data?.total_amount, total_amount2 = Data?.cmp_data?.total_amount
                        let comparePercent = ProfitLossPercent(total_amount1, total_amount2);

                        PharmacyChartDatas.noMovementCard.total_product = Data?.data?.total_product;
                        PharmacyChartDatas.noMovementCard.total_amount = total_amount1;
                        PharmacyChartDatas.noMovementCard.compare_percent = comparePercent;
                        PharmacyChartDatas.noMovementCard.APILoaded = true;
                        this.setState({
                            PharmacyChartDatas
                        })
                    }
                }).catch((error) => {
                    PharmacyChartDatas.noMovementCard.APILoaded = true;
                    this.setState({
                        PharmacyChartDatas
                    })
                    this.errorMessage(error.message)
                })

            // slow movement drugs
            RestAPIService.getAll(Serviceurls.BRANCH_MOD_PHARMACY_SLOWMOVE_GET + Getquery)
                .then((response) => {
                    let Data = response.data;
                    if (Data.status === "success") {

                        let total_amount1 = +(Data?.data?.total_amount?.toFixed(2)), total_amount2 = +(Data?.cmp_data?.total_amount?.toFixed(2))
                        let comparePercent = ProfitLossPercent(total_amount1, total_amount2);

                        PharmacyChartDatas.slowMovementCard.total_product = Data?.data?.total_product;
                        PharmacyChartDatas.slowMovementCard.total_amount = total_amount1;
                        PharmacyChartDatas.slowMovementCard.compare_percent = comparePercent;
                        PharmacyChartDatas.slowMovementCard.APILoaded = true;
                        this.setState({
                            PharmacyChartDatas
                        })
                    }
                }).catch((error) => {
                    PharmacyChartDatas.slowMovementCard.APILoaded = true;
                    this.setState({
                        PharmacyChartDatas
                    })
                    this.errorMessage(error.message)
                })

            // New Arrival drugs
            RestAPIService.getAll(Serviceurls.BRANCH_MOD_PHARMACY_NEWARRIVAL_GET + Getquery)
                .then((response) => {
                    let Data = response.data;
                    if (Data.status === "success") {

                        let total_amount1 = Data?.data?.total_amount, total_amount2 = Data?.cmp_data?.total_amount
                        let comparePercent = ProfitLossPercent(total_amount1, total_amount2);

                        PharmacyChartDatas.newArrivalCard.total_drug = Data?.data?.total_drug;
                        PharmacyChartDatas.newArrivalCard.total_amount = total_amount1;
                        PharmacyChartDatas.newArrivalCard.compare_percent = comparePercent;
                        PharmacyChartDatas.newArrivalCard.APILoaded = true;
                        this.setState({
                            PharmacyChartDatas
                        })
                    }
                }).catch((error) => {
                    PharmacyChartDatas.newArrivalCard.APILoaded = true;
                    this.setState({
                        PharmacyChartDatas
                    })
                    this.errorMessage(error.message)
                })

            // Short expiry drugs
            RestAPIService.getAll(Serviceurls.CHART_PHARMA_STOCKEXPIRY + Getquery)
                .then((response) => {
                    let Data = response.data;
                    if (Data.status === "success") {
                        let total_amount1 = Data?.data?.total_amount, total_amount2 = Data?.cmp_data?.cmp_total_amount
                        let comparePercent = ProfitLossPercent(total_amount1, total_amount2);

                        PharmacyChartDatas.stockExpiryCard.total_product = Data?.data?.total_drug;
                        PharmacyChartDatas.stockExpiryCard.total_amount = total_amount1;
                        PharmacyChartDatas.stockExpiryCard.compare_percent = comparePercent;
                        PharmacyChartDatas.stockExpiryCard.APILoaded = true;
                        this.setState({
                            PharmacyChartDatas
                        })
                    }
                }).catch((error) => {
                    PharmacyChartDatas.stockExpiryCard.APILoaded = true;
                    this.setState({
                        PharmacyChartDatas
                    })
                    this.errorMessage(error.message)
                })

            // Low Stock drugs
            RestAPIService.getAll(Serviceurls.CHART_PHARMA_LOWSTOCK + Getquery)
                .then((response) => {
                    let Data = response.data;
                    if (Data.status === "success") {
                        let total_amount1 = Data?.data?.total_amount, total_amount2 = Data?.cmp_data?.total_amount
                        let comparePercent = ProfitLossPercent(total_amount1, total_amount2);

                        PharmacyChartDatas.lowStockCard.total_product = Data?.data?.total_product;
                        PharmacyChartDatas.lowStockCard.total_amount = total_amount1;
                        PharmacyChartDatas.lowStockCard.compare_percent = comparePercent;
                        PharmacyChartDatas.lowStockCard.APILoaded = true;
                        this.setState({
                            PharmacyChartDatas
                        })
                    }
                }).catch((error) => {
                    PharmacyChartDatas.lowStockCard.APILoaded = true;
                    this.setState({
                        PharmacyChartDatas
                    })
                    this.errorMessage(error.message)
                })

            //Total drugs
            RestAPIService.getAll(Serviceurls.CHART_PHARMA_TOTALDRUG + Getquery)
                .then((response) => {
                    let Data = response.data;
                    if (Data.status === "success") {
                        let total_amount1 = Data?.data?.total_amount, total_amount2 = Data?.cmp_data?.total_amount
                        let comparePercent = ProfitLossPercent(total_amount1, total_amount2);

                        PharmacyChartDatas.totalProductsCard.total_product = Data?.data?.total_product;
                        PharmacyChartDatas.totalProductsCard.total_amount = total_amount1;
                        PharmacyChartDatas.totalProductsCard.compare_percent = comparePercent;
                        PharmacyChartDatas.totalProductsCard.APILoaded = true;
                        this.setState({
                            PharmacyChartDatas
                        })
                    }
                }).catch((error) => {
                    PharmacyChartDatas.totalProductsCard.APILoaded = true;
                    this.setState({
                        PharmacyChartDatas
                    })
                    this.errorMessage(error.message)
                })

        } catch (e) {
            this.errorMessage(e?.message)
        }
    }

    labApiCalls() {
        let { LabChartDatas, FilterDatas, branchId } = this.state;
        let Getquery = `?from_date=${FilterDatas?.FromDate}&to_date=${FilterDatas?.ToDate}&module_type=${FilterDatas?.SelectedModules}&branch_id=${branchId}`;
        let Chartquery = (FilterDatas?.CompareFromDate && FilterDatas?.CompareToDate) ?
            `?from_date=${FilterDatas?.FromDate}&to_date=${FilterDatas?.ToDate}&cmp_from_date=${FilterDatas?.CompareFromDate}&cmp_to_date=${FilterDatas?.CompareToDate}&branch_id=${branchId}`
            : Getquery;

        try {
            // Laboratory card data Api calls

            // Total Ordered Test
            RestAPIService.getAll(Serviceurls.CHART_LAB_ORDEREDTEST + Chartquery)
                .then((response) => {
                    let Data = response.data;
                    if (Data.status === "success") {

                        let total_amount1 = Data?.data?.total_amount, total_amount2 = Data?.cmp_data?.total_amount
                        let comparePercent = ProfitLossPercent(total_amount1, total_amount2);

                        LabChartDatas.orderedTestCard.total_patient = Data?.data?.total_patient;
                        LabChartDatas.orderedTestCard.total_amount = total_amount1;
                        LabChartDatas.orderedTestCard.compare_percent = comparePercent;
                        LabChartDatas.orderedTestCard.APILoaded = true;
                        this.setState({
                            LabChartDatas
                        })
                    }
                }).catch((error) => {
                    LabChartDatas.orderedTestCard.APILoaded = true;
                    this.setState({
                        LabChartDatas
                    })
                    this.errorMessage(error.message)
                })

            // Total Completed Test
            RestAPIService.getAll(Serviceurls.CHART_LAB_COMPLETEDTEST + Chartquery)
                .then((response) => {
                    let Data = response.data;
                    if (Data.status === "success") {

                        let total_amount1 = Data?.data?.total_amount, total_amount2 = Data?.cmp_data?.total_amount
                        let comparePercent = ProfitLossPercent(total_amount1, total_amount2);

                        LabChartDatas.completedCard.total_patient = Data.data.total_patient;
                        LabChartDatas.completedCard.total_amount = total_amount1;
                        LabChartDatas.completedCard.compare_percent = comparePercent;
                        LabChartDatas.completedCard.APILoaded = true;
                        this.setState({
                            LabChartDatas
                        })
                    }
                }).catch((error) => {
                    LabChartDatas.completedCard.APILoaded = true;
                    this.setState({
                        LabChartDatas
                    })
                    this.errorMessage(error.message)
                })

            // Patient Demographics Api call
            RestAPIService.getAll(Serviceurls.CHART_LAB_DEMOGRAPHICS + Getquery)
                .then((response) => {
                    let Data = response.data;
                    let list = Data?.data
                    if (Data.status === "success") {
                        let salesDemoChart = [list[0]?.total_op_amount,list[0]?.total_inpatient_amount, list[0]?.total_self_amount ]
                        let totalAmount = list[0]?.total_op_amount + list[0]?.total_inpatient_amount + list[0]?.total_self_amount 
                        LabChartDatas.salesDemo.chartData = salesDemoChart
                        LabChartDatas.salesDemo.chartLabel = ["OP", "IP", "Self"]
                        LabChartDatas.salesDemo.TableData = list
                        LabChartDatas.salesDemo.totalAmt = totalAmount
                        LabChartDatas.labDemographics = Data?.data?.length > 0 ? this.sortList([...Data?.data], "city_count") : [];
                        LabChartDatas.labDemographicsAPI = true
                        this.setState({
                            LabChartDatas
                        })
                    }
                }).catch((error) => {
                    LabChartDatas.labDemographicsAPI = true
                    this.setState({
                        LabChartDatas
                    })
                    this.errorMessage(error.message)
                })

            // Purchase Vs Sales Api call
            RestAPIService.getAll(Serviceurls.CHART_LAB_SALE_PURCHASE + Getquery)
                .then((response) => {
                    let Data = response.data;
                    let list = Data?.data?.length > 0 ? Data?.data : [];
                    if (Data.status === "success") {
                        LabChartDatas.labPurchase = list;
                        LabChartDatas.labPurchaseAPILoaded = true;
                        this.setState({
                            LabChartDatas
                        })
                    }
                }).catch((error) => {
                    LabChartDatas.labPurchaseAPILoaded = true;
                    this.setState({
                        LabChartDatas
                    })
                    this.errorMessage(error.message)
                })

            // Location Analysis Api call
            RestAPIService.getAll(Serviceurls.CHART_LAB_LOCATION + Getquery)
                .then((response) => {
                    let Data = response.data, rows = [], cityDetails = {};
                    let list = Data?.data?.length > 0 ? Data?.data : [];
                    if (Data.status === "success") {
                        rows = this.datastoArray(list, "data", "branch_id", "patient__city__city_name", "city_count");
                        if (rows?.length > 0) {
                            cityDetails = rows?.reduce((acc, cur) => {
                                let { patient__city__city_name, city_count } = cur;
                                acc[patient__city__city_name] = (acc[patient__city__city_name] ? acc[patient__city__city_name] : 0) + (city_count ? city_count : 0)
                                return acc;
                            }, {})
                        }
                        LabChartDatas.locationAnalysis.tblData = rows;
                        LabChartDatas.locationAnalysis.chartData = Object.values(cityDetails);
                        LabChartDatas.locationAnalysis.chartLabel = Object.keys(cityDetails);
                        LabChartDatas.locationAnalysis.APILoaded = true;
                        this.setState({
                            LabChartDatas
                        })
                    }
                }).catch((error) => {
                    LabChartDatas.locationAnalysis.APILoaded = true;
                    this.setState({
                        LabChartDatas
                    })
                    this.errorMessage(error.message)
                })

            // Most and least performing Api call
            RestAPIService.getAll(Serviceurls.CHART_LAB_MOST_PERFORMING + Getquery)
                .then((response) => {
                    let Data = response.data, rows = [];
                    let list = Data?.data ? Data?.data : [];

                    if (Data.status === "success") {
                        rows = this.datastoArray(list, "test_data", "branch_id", "test_name", "count");
                        LabChartDatas.mostPerforming = rows?.length > 0 ? this.sortList([...rows], "count", "")?.slice(0, 50) : [];
                        LabChartDatas.leastPerforming = rows?.length > 0 ? this.sortList([...rows], "count", "asc")?.slice(0, 50) : [];
                        LabChartDatas.PerforminAPIload = true;
                        this.setState({
                            LabChartDatas
                        })
                    }
                }).catch((error) => {
                    LabChartDatas.PerforminAPIload = true;
                    this.setState({
                        LabChartDatas
                    })
                    this.errorMessage(error.message)
                })

        } catch (e) {
            this.errorMessage(e?.message)
        }
    }

    FilterDate = (From, To, Index) => {
        this.setState({
            FromDate: From,
            ToDate: To,
            DateIndex: Index
        })
    }

    returnFilteredDate() {
        let { FilterDatas } = this.state;
        if (FilterDatas?.FromDate === "" && FilterDatas?.ToDate === "") {
            return "All"
        } else if (FilterDatas?.FromDate === FilterDatas?.ToDate) {
            return formatDate(FilterDatas?.FromDate)
        } else {
            return `${formatDate(FilterDatas?.FromDate)} - ${formatDate(FilterDatas?.ToDate)}`
        }
    }
    
    renderNextScreen = (from, id, title, link,tbName, subTitle = '') => {
        this.props?.history?.push({
            pathname: '/AdminDashboard/BranchDashboard/ChartComparison',
            state: {
                isFrom: from,
                branchId: this.state.branchId,
                branchName: this.state.branchName,
                title: title,
                fromDate: this.state.FilterDatas?.FromDate,
                toDate: this.state.FilterDatas?.ToDate,
                URL: link,
                tableName: tbName,
                subTitle : subTitle,
            }
        });
    }

    HeaderSegment = () => {
        return (
            <Box className="eMed_CharhPage_Header_div">
                <Box flex={0.8}>
                    <Typography fontWeight={600} fontSize={"1.2vw"}>{this.state.branchName}</Typography>
                    <Typography fontSize={"1vw"}>Here is a Overview of your Hospital</Typography>
                </Box>
                <Box flex={0.2} className="eMed_CharhPage_Header_filter">
                    <Box display={'flex'} flexDirection={'row'} width={'25vw'} justifyContent={'flex-end'} paddingRight={"1vw"} alignItems={"flex-end"}>
                        <Typography fontSize={"0.8vw"} paddingRight={"0.5vw"}>Filtered Date : </Typography>
                        <Typography fontSize={"0.9vw"} paddingRight={"0.5vw"} fontWeight={600}>{this.returnFilteredDate()}</Typography>
                    </Box>
                    <Tooltip title="Filter" arrow placement='top'>
                        <Button onClick={() => { this.setState({ FilterOpen: true }) }} variant='outlined' sx={{ backgroundColor: 'white', marginRight: '1vw' }}>
                            <FilterAltIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Refresh All Charts" arrow placement='top'>
                        <Button onClick={() => {
                            this.RefreshAllChartsData()
                        }} variant='outlined' sx={{ backgroundColor: 'white' }}>
                            <LoopIcon />
                        </Button>
                    </Tooltip>
                </Box>

            </Box>
        )
    }

    OverallCharts = () => {
        let OPRevenueData = {
            image: ImagePaths.DashboardBed.default,
            title: "Total OP",
            branchName: this.state.branchName ? this.state.branchName : '',
            Amount: this.state.OverallChartDatas?.TotalRevenue?.TotalObject?.OP ? this.state.OverallChartDatas?.TotalRevenue?.TotalObject?.OP : 0,
            ProfitValue: this.state.FilterDatas?.CompareFromDate === "" ? null : this.state.OverallChartDatas?.TotalRevenue?.ComparePercent?.OPComparePercent ? this.state.OverallChartDatas?.TotalRevenue?.ComparePercent?.OPComparePercent : 0,
            countLabel: "Total Patient",
            count: this.state.OverallChartDatas?.TotalRevenue?.opPatientCount ? this.state.OverallChartDatas?.TotalRevenue?.opPatientCount : 0,
        }
        let IPRevenueData = {
            image: ImagePaths.DashboardBed.default,
            title: "Total IP",
            branchName: this.state.branchName ? this.state.branchName : '',
            Amount: this.state.OverallChartDatas?.TotalRevenue?.TotalObject?.IP ? this.state.OverallChartDatas?.TotalRevenue?.TotalObject?.IP : 0,
            countLabel: "Total Patient",
            count: this.state.OverallChartDatas?.TotalRevenue?.ipPatientCount ? this.state.OverallChartDatas?.TotalRevenue?.ipPatientCount : 0,
            ProfitValue: this.state.FilterDatas?.CompareFromDate === "" ? null : this.state.OverallChartDatas?.TotalRevenue?.ComparePercent?.IPComparePercent ? this.state.OverallChartDatas?.TotalRevenue?.ComparePercent?.IPComparePercent : 0,
        }
        let TotalRevenueData = {
            image: ImagePaths.DashboardOp.default,
            title: "Total Revenue",
            branchName: this.state.branchName ? this.state.branchName : '',
            Amount: this.state.OverallChartDatas?.TotalRevenue?.TotalRevenueAmount ? this.state.OverallChartDatas?.TotalRevenue?.TotalRevenueAmount : 0,
            countLabel: "Total Patient",
            count: this.state.OverallChartDatas?.TotalRevenue?.totalPatientCount ? this.state.OverallChartDatas?.TotalRevenue?.totalPatientCount : 0,
            ProfitValue: this.state.FilterDatas?.CompareFromDate === "" ? null : this.state.OverallChartDatas?.TotalRevenue?.ComparePercent?.TotalComparePercent ? this.state.OverallChartDatas?.TotalRevenue?.ComparePercent?.TotalComparePercent : 0,
        }
        let PharmaRevenueData = {
            image: ImagePaths.Product.default,
            title: "Total Pharmacy",
            branchName: this.state.branchName ? this.state.branchName : '',
            Amount: this.state.OverallChartDatas?.TotalRevenue?.TotalObject?.Pharmacy ? this.state.OverallChartDatas?.TotalRevenue?.TotalObject?.Pharmacy : 0,
            countLabel: "Total Patient",
            count: this.state.OverallChartDatas?.TotalRevenue?.pharmaPatientCount ? this.state.OverallChartDatas?.TotalRevenue?.pharmaPatientCount : 0,
            ProfitValue: this.state.FilterDatas?.CompareFromDate === "" ? null : this.state.OverallChartDatas?.TotalRevenue?.ComparePercent?.PharmacyComparePercent ? this.state.OverallChartDatas?.TotalRevenue?.ComparePercent?.PharmacyComparePercent : 0,
        }
        let LabRevenueData = {
            image: ImagePaths.DashboardTick.default,
            title: "Total Laboratory",
            branchName: this.state.branchName ? this.state.branchName : '',
            Amount: this.state.OverallChartDatas?.TotalRevenue?.TotalObject?.Lab ? this.state.OverallChartDatas?.TotalRevenue?.TotalObject?.Lab : 0,
            countLabel: "Total Patient",
            count: this.state.OverallChartDatas?.TotalRevenue?.labPatientCount ? this.state.OverallChartDatas?.TotalRevenue?.labPatientCount : 0,
            ProfitValue: this.state.FilterDatas?.CompareFromDate === "" ? null : this.state.OverallChartDatas?.TotalRevenue?.ComparePercent?.LabComparePercent ? this.state.OverallChartDatas?.TotalRevenue?.ComparePercent?.LabComparePercent : 0,
        }
        let TotalExpenseData = {
            image: ImagePaths.DashboardExpense.default,
            title: "Total Expense",
            branchName: this.state.branchName ? this.state.branchName : '',
            Amount: this.state.OverallChartDatas?.TotalRevenue?.TotalExpenseAmount ? this.state.OverallChartDatas?.TotalRevenue?.TotalExpenseAmount : 0,
            ProfitValue: this.state.FilterDatas?.CompareFromDate === "" ? null : this.state.OverallChartDatas?.TotalRevenue?.ComparePercent?.ExpenceComparePercent ? this.state.OverallChartDatas?.TotalRevenue?.ComparePercent?.ExpenceComparePercent : 0,
        }

        const newPatientData = {
            image: ImagePaths.dashboardNewPatient.default,
            title: "New Patient",
            branchName: this.state.OverallChartDatas?.TotalRevenue?.BranchName || "-",
            count: this.state.OverallChartDatas?.opPatientCount?.patient_count?.new_patient || 0,
            ProfitValue: this.state.FilterDatas?.CompareFromDate === "" ? null : this.state.OverallChartDatas?.opPatientCount?.ComparePercent?.newPatientPercent || 0,
        }
        const reviewPatientData = {
            image: ImagePaths.dashboardReviewPatient.default,
            title: "Review Patient",
            branchName: this.state.OverallChartDatas?.TotalRevenue?.BranchName ? this.state.OverallChartDatas?.TotalRevenue?.BranchName : "-",
            count: this.state.OverallChartDatas?.opPatientCount?.patient_count?.review_patient || 0,
            ProfitValue: this.state.FilterDatas?.CompareFromDate === "" ? null : this.state.OverallChartDatas?.opPatientCount?.ComparePercent?.reviewPatientPercent || 0,
        }
        const oldPatientData = {
            image: ImagePaths.dashboardOldPatient.default,
            title: "Old Patient",
            branchName: this.state.OverallChartDatas?.TotalRevenue?.BranchName ? this.state.OverallChartDatas?.TotalRevenue?.BranchName : "-",
            count: this.state.OverallChartDatas?.opPatientCount?.patient_count?.establish_patient || 0,
            ProfitValue: this.state.FilterDatas?.CompareFromDate === "" ? null : this.state.OverallChartDatas?.opPatientCount?.ComparePercent?.oldPatientPercent || 0,
        }

        const renderPatientCard = (details) => {
            return (
                <Box className="eMed_DashChart_CardView">
                    {this.state.OverallChartDatas?.opPatientCount?.APILoaded ? (
                        <CountCards details={details} />
                    ) : (
                        <Skeleton variant="rounded" width="100%" height="100%" animation="wave" />
                    )}
                </Box>
            )
        };

        return (
            <Box id="overallRef" className="eMed_Ovarall_Chart_Wrapper">
                <Grid container spacing={1} >
                    <Grid item xs={3} >
                        <Box className="eMed_Individual_DashChart">
                            {this.state.OverallChartDatas?.TotalRevenue?.APILoaded ?
                                <DoughnutChart
                                    title={"Total Revenue"}
                                    chartLabel={this.state.OverallChartDatas?.TotalRevenue?.ChartLabel && this.state.OverallChartDatas?.TotalRevenue?.TotalRevenueAmount ? this.state.OverallChartDatas?.TotalRevenue?.ChartLabel : []}
                                    chartData={this.state.OverallChartDatas?.TotalRevenue?.ChartData && this.state.OverallChartDatas?.TotalRevenue?.TotalRevenueAmount ? this.state.OverallChartDatas?.TotalRevenue?.ChartData : []}
                                    tblData={this.state.OverallChartDatas?.TotalRevenue?.TableData && this.state.OverallChartDatas?.TotalRevenue?.TotalRevenueAmount ? this.state.OverallChartDatas?.TotalRevenue?.TableData : []}
                                    subTitle={'Revenue'}
                                    totalAmt={this.state.OverallChartDatas?.TotalRevenue?.TotalRevenueAmount ? this.state.OverallChartDatas?.TotalRevenue?.TotalRevenueAmount : 0}
                                    position={"bottom"}
                                    extractValue={"revenue"}
                                    extractLabel={"module"}
                                    showValueAtCenter={true}
                                    isFrom='branches'
                                    tblColumn={BranchWiseOverallRenvenue}
                                    tableName={"BranchWiseOverallRenvenue"}
                                    url={Serviceurls.CHART_OVERALL_REVENUE}
                                    renderNextScreen={this.renderNextScreen.bind(this)}
                                /> :
                                <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={5} className="eMed_DashChart_CardView_Wrapper">
                        <Box className="eMed_DashChart_CardView">
                            {this.state.OverallChartDatas?.TotalRevenue?.APILoaded ?
                                <RevenueCard details={TotalRevenueData} /> :
                                <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                        <Box className="eMed_DashChart_CardView">
                            {this.state.OverallChartDatas?.TotalRevenue?.APILoaded ?
                                <RevenueCard details={IPRevenueData} /> :
                                <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                        <Box className="eMed_DashChart_CardView">
                            {this.state.OverallChartDatas?.TotalRevenue?.APILoaded ?
                                <RevenueCard details={PharmaRevenueData} /> :
                                <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                        <Box className="eMed_DashChart_CardView">
                            {this.state.OverallChartDatas?.TotalRevenue?.APILoaded ?
                                <RevenueCard details={TotalExpenseData} /> :
                                <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                        <Box className="eMed_DashChart_CardView">
                            {this.state.OverallChartDatas?.TotalRevenue?.APILoaded ?
                                <RevenueCard details={OPRevenueData} /> :
                                <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                        <Box className="eMed_DashChart_CardView">
                            {this.state.OverallChartDatas?.TotalRevenue?.APILoaded ?
                                <RevenueCard details={LabRevenueData} /> :
                                <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={4} >
                        <Box className="eMed_Individual_DashChart">
                            {this.state.OverallChartDatas?.PaymentCollection?.APILoaded ?
                                <BarChart
                                    title={"Overall Payment Collection"}
                                    Tlt2={"Amount"}
                                    chartLabel={this.state.OverallChartDatas?.PaymentCollection?.ChartLabel ? this.state.OverallChartDatas?.PaymentCollection?.ChartLabel : []}
                                    chartData={this.state.OverallChartDatas?.PaymentCollection?.ChartData ? this.state.OverallChartDatas?.PaymentCollection?.ChartData : []}
                                    tblData={this.state.OverallChartDatas?.PaymentCollection?.TableData ? this.state.OverallChartDatas?.PaymentCollection?.TableData : []}
                                    tblColumn={OverallPaymentCollection}
                                    isFrom='branches'
                                    tableName={"OverallPaymentCollection"}
                                    url={Serviceurls.CHART_OVERALL_PAYMENTS}
                                    renderNextScreen={this.renderNextScreen.bind(this)}
                                /> :
                                <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={3} >
                        <Box className="eMed_Individual_DashChart">
                            {this.state.OverallChartDatas?.CreditAmount?.APILoaded ?
                                <DoughnutChart
                                    title={"Credit Bill Value"}
                                    chartLabel={this.state.OverallChartDatas?.CreditAmount?.ChartLabel && this.state.OverallChartDatas?.CreditAmount?.TotalCreditAmount ? this.state.OverallChartDatas?.CreditAmount?.ChartLabel : []}
                                    chartData={this.state.OverallChartDatas?.CreditAmount?.ChartData && this.state.OverallChartDatas?.CreditAmount?.TotalCreditAmount ? this.state.OverallChartDatas?.CreditAmount?.ChartData : []}
                                    tblData={this.state.OverallChartDatas?.CreditAmount?.TableData && this.state.OverallChartDatas?.CreditAmount?.TotalCreditAmount ? this.state.OverallChartDatas?.CreditAmount?.TableData : []}
                                    subTitle={'Credit Bill Value'}
                                    totalAmt={this.state.OverallChartDatas?.CreditAmount?.TotalCreditAmount ? this.state.OverallChartDatas?.CreditAmount?.TotalCreditAmount : 0}
                                    position={"bottom"}
                                    extractValue="lab_bill_summary_id"
                                    extractLabel="lab_test__name"
                                    showValueAtCenter={true}
                                    isFrom='branches'
                                    tblColumn={BranchWiseOverallCreditAmount}
                                    tableName={"BranchWiseOverallCreditAmount"}
                                    url={Serviceurls.CHART_OVERALL_CREDIT_AMOUNT}
                                    renderNextScreen={this.renderNextScreen.bind(this)}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={3} >
                        <Box className="eMed_Individual_DashChart">
                            {this.state.FilterDatas?.SelectedModules?.includes("OP") ?   // Show only When OP Module is Selected
                                this.state.OverallChartDatas?.AppointmentType?.APILoaded ?
                                    <BarChart
                                        title={"Appointment Type"}
                                        Tlt2={"Count"}
                                        chartLabel={this.state.OverallChartDatas?.AppointmentType?.ChartLabel ? this.state.OverallChartDatas?.AppointmentType?.ChartLabel : []}
                                        chartData={this.state.OverallChartDatas?.AppointmentType?.ChartData ? this.state.OverallChartDatas?.AppointmentType?.ChartData : []}
                                        tblData={this.state.OverallChartDatas?.AppointmentType?.TableData ? this.state.OverallChartDatas?.AppointmentType?.TableData : []}
                                        tblColumn={OverallAppointmentType}
                                        isFrom='branches'
                                        tableName={"OverallAppointmentType"}
                                        url={Serviceurls.CHART_OVERALL_APPOINTMENT_TYPE_COUNT}
                                        renderNextScreen={this.renderNextScreen.bind(this)}
                                    /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" /> : null}
                        </Box>
                    </Grid>
                    <Grid item xs={3} >
                        <Box className="eMed_Individual_DashChart">
                            {this.state.OverallChartDatas?.PatientCount?.APILoaded ?
                                <DoughnutChart
                                    title={"Total Patient Report"}
                                    chartLabel={this.state.OverallChartDatas?.PatientCount?.ChartLabel ? this.state.OverallChartDatas?.PatientCount?.ChartLabel : []}
                                    chartData={this.state.OverallChartDatas?.PatientCount?.ChartData ? this.state.OverallChartDatas?.PatientCount?.ChartData : []}
                                    tblData={this.state.OverallChartDatas?.PatientCount?.TableData ? this.state.OverallChartDatas?.PatientCount?.TableData : []}
                                    subTitle={'Total Patient Count'}
                                    totalAmt={this.state.OverallChartDatas?.PatientCount?.TotalPatientCount ? this.state.OverallChartDatas?.PatientCount?.TotalPatientCount : 0}
                                    position={"bottom"}
                                    showValueAtCenter={true}
                                    isFrom='branches'
                                    tblColumn={BranchTotalPatientCount}
                                    tableName={"BranchTotalPatientCount"}
                                    url={Serviceurls.BRANCH_MOD_TOTAL_PATIENT_DOC_COUNT}
                                    renderNextScreen={this.renderNextScreen.bind(this)}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={3} >
                        <Box className="eMed_Individual_DashChart">
                            {this.state.OverallChartDatas?.DoctorCount?.APILoaded ?
                                <DoughnutChart
                                    title={"Total Doctor Report"}
                                    chartLabel={this.state.OverallChartDatas?.DoctorCount?.ChartLabel ? this.state.OverallChartDatas?.DoctorCount?.ChartLabel : []}
                                    chartData={this.state.OverallChartDatas?.DoctorCount?.ChartData ? this.state.OverallChartDatas?.DoctorCount?.ChartData : []}
                                    tblData={this.state.OverallChartDatas?.DoctorCount?.TableData ? this.state.OverallChartDatas?.DoctorCount?.TableData : []}
                                    subTitle={'Total Doctors'}
                                    totalAmt={this.state.OverallChartDatas?.DoctorCount?.TotalDoctorCount ? this.state.OverallChartDatas?.DoctorCount?.TotalDoctorCount : 0}
                                    position={"bottom"}
                                    showValueAtCenter={true}
                                    isFrom='branches'
                                    tblColumn={BranchTotalDoctorCount}
                                    tableName={"BranchTotalDoctorCount"}
                                    url={Serviceurls.BRANCH_MOD_TOTAL_PATIENT_DOC_COUNT}
                                    renderNextScreen={this.renderNextScreen.bind(this)}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    {// Show only When OP Module is Selected
                    this.state.FilterDatas?.SelectedModules?.includes("OP")
                        && <Grid item xs={12} className="eMed_DashChart_CardView_Wrapper">
                            {renderPatientCard(newPatientData)}
                            {renderPatientCard(reviewPatientData)}
                            {renderPatientCard(oldPatientData)}
                        </Grid>}
                </Grid>
            </Box>
        )
    }

    OutPatientCharts = () => {
        return (
            <Box id="opRef" className="eMed_OP_Chart_Wrapper">
                <Divider textAlign='left' className='eMed_Divider_Div'>
                    <Typography component={'p'}>Out Patient Dashboard</Typography>
                </Divider>
                <Grid container spacing={1} >
                    <Grid item xs={5} >
                        <Box className="eMed_Individual_DashChart">
                            {this.state.OutPatientChartDatas?.SpecialzationChart?.APILoaded ?
                                <BarChart
                                    title={"Top Performing Specialzation"}
                                    Tlt2={"Count"}
                                    chartLabel={this.state.OutPatientChartDatas?.SpecialzationChart?.ChartLabel ? this.state.OutPatientChartDatas?.SpecialzationChart?.ChartLabel : []}
                                    chartData={this.state.OutPatientChartDatas?.SpecialzationChart?.ChartData ? this.state.OutPatientChartDatas?.SpecialzationChart?.ChartData : []}
                                    tblData={this.state.OutPatientChartDatas?.SpecialzationChart?.TableData ? this.state.OutPatientChartDatas?.SpecialzationChart?.TableData : []}
                                    tblColumn={OPSpecialzationChart}
                                    isFrom='branches'
                                    tableName={"OPSpecialzationChart"}
                                    url={Serviceurls.CHART_OP_SPECIALZATION}
                                    renderNextScreen={this.renderNextScreen.bind(this)}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={4} >
                        <Box className="eMed_Individual_DashChart">
                            {this.state.OutPatientChartDatas?.TopDoctorsChart?.APILoaded ?
                                <BarChart
                                    title={"Top Performing Doctor"}
                                    Tlt2={"Count"}
                                    chartLabel={this.state.OutPatientChartDatas?.TopDoctorsChart?.ChartLabel ? this.state.OutPatientChartDatas?.TopDoctorsChart?.ChartLabel : []}
                                    chartData={this.state.OutPatientChartDatas?.TopDoctorsChart?.ChartData ? this.state.OutPatientChartDatas?.TopDoctorsChart?.ChartData : []}
                                    tblData={this.state.OutPatientChartDatas?.TopDoctorsChart?.TableData ? this.state.OutPatientChartDatas?.TopDoctorsChart?.TableData : []}
                                    tblColumn={OPDoctorsChart}
                                    isFrom='branches'
                                    tableName={"OPDoctorsChart"}
                                    url={Serviceurls.CHART_OP_TOP_DOCTORS}
                                    renderNextScreen={this.renderNextScreen.bind(this)}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={3} >
                        <Box className="eMed_Individual_DashChart">
                            {this.state.OutPatientChartDatas?.TopServicesChart?.APILoaded ?
                                <DoughnutChart
                                    title={"Most Performing Services"}
                                    chartLabel={this.state.OutPatientChartDatas?.TopServicesChart?.ChartLabel ? this.state.OutPatientChartDatas?.TopServicesChart?.ChartLabel : []}
                                    chartData={this.state.OutPatientChartDatas?.TopServicesChart?.ChartData ? this.state.OutPatientChartDatas?.TopServicesChart?.ChartData : []}
                                    tblData={this.state.OutPatientChartDatas?.TopServicesChart?.TableData ? this.state.OutPatientChartDatas?.TopServicesChart?.TableData : []}
                                    position={"bottom"}
                                    tblColumn={OPServicesChart}
                                    isFrom='branches'
                                    tableName={"OPServicesChart"}
                                    url={Serviceurls.CHART_OP_SERVICES}
                                    renderNextScreen={this.renderNextScreen.bind(this)}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={4} >
                        <Box className="eMed_Individual_DashChart">
                            {this.state.OutPatientChartDatas?.LocationAnalysisChart?.APILoaded ?
                                <BarChart
                                    title={"Location Analysis"}
                                    Tlt2={"Count"}
                                    chartLabel={this.state.OutPatientChartDatas?.LocationAnalysisChart?.ChartLabel ? this.state.OutPatientChartDatas?.LocationAnalysisChart?.ChartLabel : []}
                                    chartData={this.state.OutPatientChartDatas?.LocationAnalysisChart?.ChartData ? this.state.OutPatientChartDatas?.LocationAnalysisChart?.ChartData : []}
                                    tblData={this.state.OutPatientChartDatas?.LocationAnalysisChart?.TableData ? this.state.OutPatientChartDatas?.LocationAnalysisChart?.TableData : []}
                                    tblColumn={OPLocationChart}
                                    isFrom='branches'
                                    tableName={"OPLocationChart"}
                                    url={Serviceurls.CHART_OP_LOCATION_ANALYSIS}
                                    renderNextScreen={this.renderNextScreen.bind(this)}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={5} >
                        <Box className="eMed_Individual_DashChart">

                        </Box>
                    </Grid>
                    <Grid item xs={3} >
                        <Box className="eMed_Individual_DashChart">
                            {this.state.OutPatientChartDatas?.LeastServicesChart?.APILoaded ?
                                <DoughnutChart
                                    title={"Least Performing Services"}
                                    chartLabel={this.state.OutPatientChartDatas?.LeastServicesChart?.ChartLabel ? this.state.OutPatientChartDatas?.LeastServicesChart?.ChartLabel : []}
                                    chartData={this.state.OutPatientChartDatas?.LeastServicesChart?.ChartData ? this.state.OutPatientChartDatas?.LeastServicesChart?.ChartData : []}
                                    tblData={this.state.OutPatientChartDatas?.LeastServicesChart?.TableData ? this.state.OutPatientChartDatas?.LeastServicesChart?.TableData : []}
                                    position={"bottom"}
                                    tblColumn={OPServicesChart}
                                    subTitle={'Services'}
                                    isFrom='branches'
                                    tableName={"OPLocationChart"}
                                    url={Serviceurls.CHART_OP_SERVICES}
                                    renderNextScreen={this.renderNextScreen.bind(this)}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    InPatientCharts = () => {
        return (
            <Box id="ipRef" className="eMed_OP_Chart_Wrapper">
                <Divider textAlign='left' className='eMed_Divider_Div'>
                    <Typography component={'p'}>In Patient Dashboard</Typography>
                </Divider>
                <Grid container spacing={1} >
                    <Grid item xs={4} >
                        <Box className="eMed_Individual_DashChart">
                            {this.state.InPatientChartDatas?.SpecialzationChart?.APILoaded ?
                                <BarChart
                                    title={"Top Performing Specialzation"}
                                    Tlt2={"Count"}
                                    chartLabel={this.state.InPatientChartDatas?.SpecialzationChart?.ChartLabel ? this.state.InPatientChartDatas?.SpecialzationChart?.ChartLabel : []}
                                    chartData={this.state.InPatientChartDatas?.SpecialzationChart?.ChartData ? this.state.InPatientChartDatas?.SpecialzationChart?.ChartData : []}
                                    tblData={this.state.InPatientChartDatas?.SpecialzationChart?.TableData ? this.state.InPatientChartDatas?.SpecialzationChart?.TableData : []}
                                    tblColumn={OPSpecialzationChart}
                                    isFrom='branches'
                                    tableName={"OPSpecialzationChart"}
                                    url={Serviceurls.CHART_IP_SPECIALZATION}
                                    renderNextScreen={this.renderNextScreen.bind(this)}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={5} >
                        <Box className="eMed_Individual_DashChart">
                            {this.state.InPatientChartDatas?.TopDoctorsChart?.APILoaded ?
                                <BarChart
                                    title={"Top Performing Doctor"}
                                    Tlt2={"Count"}
                                    chartLabel={this.state.InPatientChartDatas?.TopDoctorsChart?.ChartLabel ? this.state.InPatientChartDatas?.TopDoctorsChart?.ChartLabel : []}
                                    chartData={this.state.InPatientChartDatas?.TopDoctorsChart?.ChartData ? this.state.InPatientChartDatas?.TopDoctorsChart?.ChartData : []}
                                    tblData={this.state.InPatientChartDatas?.TopDoctorsChart?.TableData ? this.state.InPatientChartDatas?.TopDoctorsChart?.TableData : []}
                                    tblColumn={OPDoctorsChart}
                                    isFrom='branches'
                                    tableName={"OPDoctorsChart"}
                                    url={Serviceurls.CHART_IP_TOP_DOCTORS}
                                    renderNextScreen={this.renderNextScreen.bind(this)}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={3} >
                        <Box className="eMed_Individual_DashChart">
                            {this.state.InPatientChartDatas?.TopServicesChart?.APILoaded ?
                                <DoughnutChart
                                    title={"Most Performing Services"}
                                    chartLabel={this.state.InPatientChartDatas?.TopServicesChart?.ChartLabel ? this.state.InPatientChartDatas?.TopServicesChart?.ChartLabel : []}
                                    chartData={this.state.InPatientChartDatas?.TopServicesChart?.ChartData ? this.state.InPatientChartDatas?.TopServicesChart?.ChartData : []}
                                    tblData={this.state.InPatientChartDatas?.TopServicesChart?.TableData ? this.state.InPatientChartDatas?.TopServicesChart?.TableData : []}
                                    position={"bottom"}
                                    tblColumn={OPServicesChart}
                                    isFrom='branches'
                                    tableName={"OPLocationChart"}
                                    url={Serviceurls.CHART_IP_SERVICES}
                                    renderNextScreen={this.renderNextScreen.bind(this)}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={4} >
                        <Box className="eMed_Individual_DashChart">
                            {this.state.InPatientChartDatas?.LocationAnalysisChart?.APILoaded ?
                                <BarChart
                                    title={"Location Analysis"}
                                    Tlt2={"Count"}
                                    chartLabel={this.state.InPatientChartDatas?.LocationAnalysisChart?.ChartLabel ? this.state.InPatientChartDatas?.LocationAnalysisChart?.ChartLabel : []}
                                    chartData={this.state.InPatientChartDatas?.LocationAnalysisChart?.ChartData ? this.state.InPatientChartDatas?.LocationAnalysisChart?.ChartData : []}
                                    tblData={this.state.InPatientChartDatas?.LocationAnalysisChart?.TableData ? this.state.InPatientChartDatas?.LocationAnalysisChart?.TableData : []}
                                    tblColumn={OPLocationChart}
                                    isFrom='branches'
                                    tableName={"OPLocationChart"}
                                    url={Serviceurls.CHART_IP_LOCATION_ANALYSIS}
                                    renderNextScreen={this.renderNextScreen.bind(this)}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={5} >
                        <Box className="eMed_Individual_DashChart">
    
                        </Box>
                    </Grid>
                    <Grid item xs={3} >
                        <Box className="eMed_Individual_DashChart">
                            {this.state.InPatientChartDatas?.LeastServicesChart?.APILoaded ?
                                <DoughnutChart
                                    title={"Least Performing Services"}
                                    chartLabel={this.state.InPatientChartDatas?.LeastServicesChart?.ChartLabel ? this.state.InPatientChartDatas?.LeastServicesChart?.ChartLabel : []}
                                    chartData={this.state.InPatientChartDatas?.LeastServicesChart?.ChartData ? this.state.InPatientChartDatas?.LeastServicesChart?.ChartData : []}
                                    tblData={this.state.InPatientChartDatas?.LeastServicesChart?.TableData ? this.state.InPatientChartDatas?.LeastServicesChart?.TableData : []}
                                    position={"bottom"}
                                    tblColumn={OPServicesChart}
                                    isFrom='branches'
                                    tableName={"OPLocationChart"}
                                    url={Serviceurls.CHART_IP_SERVICES}
                                    renderNextScreen={this.renderNextScreen.bind(this)}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    PharmacyCharts = () => {

        let { PharmacyChartDatas, OverallChartDatas } = this.state;
        let vendorDatas = PharmacyChartDatas.pharmaVendor, noMovementData = PharmacyChartDatas.noMovementCard, shortExpiry = PharmacyChartDatas.stockExpiryCard, slowMovementData = PharmacyChartDatas.slowMovementCard, newArrivalData = PharmacyChartDatas.newArrivalCard;
        let lowStock = PharmacyChartDatas.lowStockCard, totalDrug = PharmacyChartDatas.totalProductsCard, topSellingDrug = PharmacyChartDatas.topSellingDrug;
        let Branch_name = OverallChartDatas?.TotalRevenue?.BranchName ? OverallChartDatas?.TotalRevenue?.BranchName : "-";

        const TotalProducts = {
            image: ImagePaths.Product.default, title: "Total Drugs", countLabel: "Total Drugs",
            branchName: Branch_name,
            count: totalDrug?.total_product ? totalDrug?.total_product : 0,
            Amount: totalDrug?.total_amount ? totalDrug?.total_amount : 0,
            countLabel: "Total Drug",
        }

        const TotalLowStock = {
            image: ImagePaths.LowStock.default, title: "Total Low Stock", countLabel: "Total Drugs",
            branchName: Branch_name,
            count: lowStock?.total_product ? lowStock?.total_product : 0,
            Amount: lowStock?.total_amount ? lowStock?.total_amount : 0,
            countLabel: "Total Drug",
        }

        const ShortExpiry = {
            image: ImagePaths.ShortExpiry.default, title: "Total Expiry", countLabel: "Total Drugs",
            branchName: Branch_name,
            count: shortExpiry?.total_product ? shortExpiry?.total_product : 0,
            Amount: shortExpiry?.total_amount ? shortExpiry?.total_amount : 0,
            countLabel: "Total Drug",
        }

        const NoMovementStock = {
            image: ImagePaths.LowStock.default, title: "No Movement Stock", countLabel: "Total Drugs",
            branchName: Branch_name,
            count: noMovementData?.total_product ? noMovementData?.total_product : 0,
            Amount: noMovementData?.total_amount ? noMovementData?.total_amount : 0,
            countLabel: "Total Drug",
        }

        const SlowMovementStock = {
            image: ImagePaths.LowStock.default, title: "Slow Movement", countLabel: "Total Drugs",
            branchName: Branch_name,
            count: slowMovementData?.total_product ? slowMovementData?.total_product : 0,
            Amount: slowMovementData?.total_amount ? slowMovementData?.total_amount : 0,
            countLabel: "Total Drug",
        }

        const NewArrivalStock = {
            image: ImagePaths.LowStock.default, title: "New Arrival", countLabel: "Total Drugs",
            branchName: Branch_name,
            count: newArrivalData?.total_drug ? newArrivalData?.total_drug : 0,
            Amount: newArrivalData?.total_amount ? newArrivalData?.total_amount : 0,
            countLabel: "Total Drug",
        }

        return (
            <Box id="pharmaRef" className="eMed_OP_Chart_Wrapper">
                <Divider textAlign='left' className='eMed_Divider_Div'>
                    <Typography component={'p'}>Pharmacy Dashboard</Typography>
                </Divider>
                <Grid container spacing={1}>
                    <Grid item xs={3.36}>
                    <Box sx={{display:'flex', flexDirection:'column', gap:'0.5vw'}}>
                        <Box className='eMed_DashChart_CardView_Wrapper'>
                        <Box className="eMed_DashChart_CardView">
                            {this.state.PharmacyChartDatas?.totalProductsCard?.APILoaded ?
                                <CountCardView details={TotalProducts} />
                                : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                        <Box className="eMed_DashChart_CardView">
                            {this.state.PharmacyChartDatas?.lowStockCard?.APILoaded ?
                                <CountCardView details={TotalLowStock} />
                                : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                        <Box className="eMed_DashChart_CardView">
                            {this.state.PharmacyChartDatas?.stockExpiryCard?.APILoaded ?
                                <CountCardView details={ShortExpiry} />
                                : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                        <Box className="eMed_DashChart_CardView">
                            {this.state.PharmacyChartDatas?.noMovementCard?.APILoaded ?
                                <CountCardView details={NoMovementStock} />
                                : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                        <Box className="eMed_DashChart_CardView">
                            {this.state.PharmacyChartDatas?.slowMovementCard?.APILoaded ?
                                <CountCardView details={SlowMovementStock} />
                                : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                        <Box className="eMed_DashChart_CardView">
                            {this.state.PharmacyChartDatas?.newArrivalCard?.APILoaded ?
                                <CountCardView details={NewArrivalStock} />
                                : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                        </Box>
                        <Box className="eMed_Individual_DashChart" height={'28.2vw'}>
                            {PharmacyChartDatas?.pharmaDemographicsAPILoad ?
                                <DoughnutChart
                                    title={"Sales Demographics"}
                                    chartData={PharmacyChartDatas?.salesDemo?.chartData?.length > 0 ? PharmacyChartDatas.salesDemo?.chartData : []}
                                    chartLabel={PharmacyChartDatas?.salesDemo?.chartLabel?.length > 0 ? PharmacyChartDatas.salesDemo?.chartLabel : []}
                                    tblData={PharmacyChartDatas?.salesDemo?.TableData?.length > 0 ? PharmacyChartDatas?.salesDemo?.TableData : []}
                                    subTitle={'Revenue'}
                                    totalAmt={PharmacyChartDatas.salesDemo?.totalAmt ? PharmacyChartDatas.salesDemo?.totalAmt : 0}
                                    showValueAtCenter={true}
                                    tblColumn={BranchPharmaSalesDemoChart}
                                    isFromBranchPharmacy={true}
                                    isFrom='branches'
                                    tableName={"BranchPharmaSalesDemoChart"}
                                    url={Serviceurls.CHART_PHARMA_DEMOGRAPHICS}
                                    renderNextScreen={this.renderNextScreen.bind(this)}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={4.57}>
                        <Box sx={{display:'flex', flexDirection:'column', gap:'0.5vw'}}>
                        <Box className="eMed_Individual_DashChart">
                            {vendorDatas?.APILoaded ?
                                <DoughnutChart
                                    title={"Vendor Payment"}
                                    chartData={vendorDatas?.chartData?.length > 0 ? vendorDatas?.chartData : []}
                                    chartLabel={vendorDatas?.chartLabel?.length > 0 ? vendorDatas?.chartLabel : []}
                                    tblData={vendorDatas?.tblData?.length > 0 ? vendorDatas?.tblData : []}
                                    subTitle={'Purchase value'}
                                    totalAmt={vendorDatas.total_amount ? vendorDatas.total_amount : 0}
                                    position={""}
                                    showValueAtCenter={true}
                                    tblColumn={vendorColumns}
                                    isFrom='branches'
                                    tableName={"vendorColumns"}
                                    url={Serviceurls.CHART_PHARMA_VENDOR}
                                    renderNextScreen={this.renderNextScreen.bind(this)}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                        <Box className="eMed_Individual_DashChart">
                            {topSellingDrug?.APILoaded ?
                                <BarChart
                                    title={"Top Selling Drugs"} Tlt2={"Count"}
                                    tblData={topSellingDrug?.tblData?.length > 0 ? topSellingDrug?.tblData : []}
                                    chartData={topSellingDrug?.chartData?.length > 0 ? topSellingDrug?.chartData : []}
                                    chartLabel={topSellingDrug?.chartLabel?.length > 0 ? topSellingDrug?.chartLabel : []}
                                    tblColumn={TopSellingDrugs}
                                    isFrom='branches'
                                    tableName={"TopSellingDrugs"}
                                    url={Serviceurls.CHART_PHARMA_TOP_SELLING}
                                    renderNextScreen={this.renderNextScreen.bind(this)}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={4.07} >
                    <Box sx={{display:'flex', flexDirection:'column', gap:'0.5vw'}}>

                        <Box className="eMed_Individual_DashChart">
                            {PharmacyChartDatas?.pharmaPurchaseAPI ?
                                <BarChart
                                    title={"Purchase Vs Sales"}
                                    Tlt2={"Sales"}
                                    Tlt3={"Purchase"}
                                    tblData={PharmacyChartDatas?.pharmaPurchase?.length > 0 ? PharmacyChartDatas?.pharmaPurchase : []}
                                    valueToBeExtracted={["sales_amount", "purchase_amount"]}
                                    extractLabel={"branch_name"}
                                    tblColumn={PurchaseSales}
                                    isFrom='branches'
                                    tableName={"PurchaseSales"}
                                    url={Serviceurls.CHART_PHARMA_SALE_PURCHASE}
                                    renderNextScreen={this.renderNextScreen.bind(this)}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                        <Box className="eMed_Individual_DashChart">
                            {PharmacyChartDatas?.pharmaDemographicsAPILoad ?
                                <BarChart
                                    title={"Patient Demographics"}
                                    Tlt1={"IP"} Tlt2={"OP"} Tlt3={"Self"}
                                    tblData={PharmacyChartDatas?.pharmaDemographics?.length > 0 ? PharmacyChartDatas?.pharmaDemographics : []}
                                    valueToBeExtracted={["ip_bill_count", "op_bill_count", "self_bill_count"]}
                                    extractLabel={"branch_name"}
                                    tblColumn={PharmaPatientDemographics}
                                    isFrom='branches'
                                    tableName={"PharmaPatientDemographics"}
                                    url={Serviceurls.CHART_PHARMA_DEMOGRAPHICS}
                                    renderNextScreen={this.renderNextScreen.bind(this)}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    LaboratoryCharts = () => {
        let { LabChartDatas, OverallChartDatas } = this.state;
        let { orderedTestCard, completedCard, locationAnalysis } = LabChartDatas;

        let Branch_name = OverallChartDatas?.TotalRevenue?.BranchName ? OverallChartDatas?.TotalRevenue?.BranchName : "-";

        const orderTests = {
            image: ImagePaths.Product.default, title: "Total Ordered Test", countLabel: "Total Test",
            branchName: Branch_name,
            count: orderedTestCard?.total_patient ? orderedTestCard?.total_patient : 0,
            Amount: orderedTestCard?.total_amount ? orderedTestCard?.total_amount : 0,
            ProfitValue: this.state.FilterDatas?.CompareFromDate === "" ? null : orderedTestCard?.compare_percent ? orderedTestCard?.compare_percent : 0
        }

        const completedTests = {
            image: ImagePaths.LowStock.default, title: "Total Completed Test", countLabel: "Total Test",
            branchName: Branch_name,
            count: completedCard?.total_patient ? completedCard?.total_patient : 0,
            Amount: completedCard?.total_amount ? completedCard?.total_amount : 0,
            ProfitValue: this.state.FilterDatas?.CompareFromDate === "" ? null : completedCard?.compare_percent ? completedCard?.compare_percent : 0
        }

        return (
            <Box id="labRef" className="eMed_OP_Chart_Wrapper">
                <Divider textAlign='left' className='eMed_Divider_Div'>
                    <Typography component={'p'}>Laboratory Dashboard</Typography>
                </Divider>
                <Grid container spacing={1}>
                    <Grid item xs={1.66} className='eMed_DashChart_CardView_Wrapper'>
                        <Box className="eMed_DashChart_CardView">
                            {orderedTestCard?.APILoaded ?
                                <CountCardView details={orderTests} />
                                : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                        <Box className="eMed_DashChart_CardView">
                            {completedCard?.APILoaded ?
                                <CountCardView details={completedTests} />
                                : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={3.44} >
                        <Box className="eMed_Individual_DashChart">
                            {LabChartDatas?.labDemographicsAPI ?
                                <BarChart
                                    title={"Patient Demographics"}
                                    Tlt1={"IP"} Tlt2={"OP"} Tlt3={"Self"}
                                    tblData={LabChartDatas?.labDemographics?.length > 0 ? LabChartDatas?.labDemographics : []}
                                    valueToBeExtracted={["ip_bill_count", "op_bill_count", "self_bill_count"]}
                                    extractLabel={"branch_name"}
                                    tblColumn={PharmaPatientDemographics}
                                    isFrom='branches'
                                    tableName={"PharmaPatientDemographics"}
                                    url={Serviceurls.CHART_LAB_DEMOGRAPHICS}
                                    renderNextScreen={this.renderNextScreen.bind(this)}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={3.44} >
                        <Box className="eMed_Individual_DashChart">
                            {LabChartDatas?.labPurchaseAPILoaded ?
                                <DoughnutChart
                                    title={"Sales Demographics"}
                                    chartData={LabChartDatas.salesDemo?.chartData?.length > 0 ? LabChartDatas.salesDemo?.chartData : []}
                                    chartLabel={LabChartDatas.salesDemo?.chartLabel?.length > 0 ? LabChartDatas.salesDemo?.chartLabel : []}
                                    tblData={LabChartDatas.salesDemo?.TableData?.length > 0 ? LabChartDatas.salesDemo?.TableData : []}
                                    subTitle={'Revenue'}
                                    totalAmt={LabChartDatas.salesDemo?.totalAmt ? LabChartDatas.salesDemo?.totalAmt : 0}
                                    position={""}
                                    showValueAtCenter={true}
                                    tblColumn={BranchLabSalesDemoChart}
                                    isFrom='branches'
                                    tableName={"BranchLabSalesDemoChart"}
                                    url={Serviceurls.CHART_LAB_DEMOGRAPHICS}
                                    renderNextScreen={this.renderNextScreen.bind(this)}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={3.44} >
                        <Box className="eMed_Individual_DashChart">
                            {LabChartDatas?.PerforminAPIload ?
                                <DoughnutChart
                                    title={"Most Performing Tests"}
                                    tblData={LabChartDatas?.mostPerforming?.length > 0 ? LabChartDatas?.mostPerforming : []}
                                    position={"bottom"}
                                    extractValue="count"
                                    extractLabel="test_name"
                                    // showCountOnChart={true}
                                    tblColumn={mostLeastTest}
                                    isFrom='branches'
                                    tableName={"mostLeastTest"}
                                    url={Serviceurls.CHART_LAB_MOST_PERFORMING}
                                    renderNextScreen={this.renderNextScreen.bind(this)}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={4} >
                        <Box className="eMed_Individual_DashChart">
                            {locationAnalysis?.APILoaded ?
                                <BarChart
                                    title={"Location Analysis"}
                                    Tlt2={"Count"}
                                    tblData={locationAnalysis?.tblData?.length > 0 ? locationAnalysis?.tblData : []}
                                    chartData={locationAnalysis?.chartData?.length > 0 ? locationAnalysis?.chartData : []}
                                    chartLabel={locationAnalysis?.chartLabel?.length > 0 ? locationAnalysis?.chartLabel : []}
                                    tblColumn={labLocation}
                                    isFrom='branches'
                                    tableName={"labLocation"}
                                    url={Serviceurls.CHART_LAB_LOCATION}
                                    renderNextScreen={this.renderNextScreen.bind(this)}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                    <Grid item xs={5} >
                    <Box className="eMed_Individual_DashChart">

                    </Box>
                    </Grid>
                    <Grid item xs={3} >
                        <Box className="eMed_Individual_DashChart">
                            {LabChartDatas?.PerforminAPIload ?
                                <DoughnutChart
                                    title={"Least Performing Tests"}
                                    tblData={LabChartDatas?.leastPerforming?.length > 0 ? LabChartDatas?.leastPerforming : []}
                                    position={"bottom"}
                                    extractValue="count"
                                    extractLabel="test_name"
                                    // showCountOnChart={true}
                                    tblColumn={mostLeastTest}
                                    isFrom='branches'
                                    tableName={"mostLeastTest"}
                                    url={Serviceurls.CHART_LAB_MOST_PERFORMING}
                                    renderNextScreen={this.renderNextScreen.bind(this)}
                                /> : <Skeleton variant="rounded" width={"100%"} height={"100%"} animation="wave" />}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    ClosePopUp() {
        this.setState({
            FilterOpen: false,
            BillPreviewPop: false,
        })
    }

    renderFilter = (data) => {
        this.setState({
            FilterDatas: data,
            FilterOpen: false,
        }, () => {
            this.RefreshAllChartsData()
        })
    }

    render() {
        return (
            <Box id='eMed_BM_mainDiv'>
                <Box component={'div'} className='eMed_Pharma_Header_Card'>
                    <Stack direction="row" spacing={1} alignItems="center"><IconButton size="small" onClick={() => {
                        this.props?.history?.push({ pathname: '/AdminDashboard' })
                    }} ><img className="emedhub_popupIcon_img" src={ImagePaths.LabBackButton.default} alt={"Back"} /></IconButton><Typography sx={{ color: "#616161" }}> {"Back to Dashboard"}</Typography></Stack>
                </Box>
                {this.HeaderSegment()}
                {this.state.AlertText !== "" ? <Alert severity="error" onClose={() => { this.setState({ AlertText: "" }) }}>{this.state.AlertText}</Alert> : null}
                <Box className="eMed_Dashboard_Chart_wrapper" height={this.state.AlertText !== "" ? "69vh" : "70vh"}>
                    {this.OverallCharts()}
                    {this.state.FilterDatas?.SelectedModules.includes("OP") ? this.OutPatientCharts() : null}
                    {this.state.FilterDatas?.SelectedModules.includes("IP") ? this.InPatientCharts() : null}
                    {this.state.FilterDatas?.SelectedModules.includes("PHARMACY") ? this.PharmacyCharts() : null}
                    {this.state.FilterDatas?.SelectedModules.includes("LAB") ? this.LaboratoryCharts() : null}
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
                >
                    <ChartDashboardFilter
                        FilterDatas={this.state.FilterDatas}
                        CloseFilter={this.ClosePopUp.bind(this)}
                        FilteredData={this.renderFilter.bind(this)}
                        fromAdminDash={true} />
                </Drawer>
            </Box>
        )
    }
}
export default withTranslation()(BranchDetailDashboard);