import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { getCachevalue } from '../../../../Utility/Services/CacheProviderService'
import { formatDate } from '../../../../Components/CommonFunctions/CommonFunctions'
import { Nutrition, Intensity_Level, Made_Of_Delivery, Gestational_Age } from '../../../../Utility/Constants';

//create lookups
const createMapFromArray = (array, keySelector, valueSelector) => {
    return new Map(array.map(item => [keySelector(item), valueSelector(item)]));
};

// lookups
const nutritionMap = createMapFromArray(Nutrition, (item => +item.value), (item => item.label));
const intensityMap = createMapFromArray(Intensity_Level, (item => +item.value), (item => item.label));
const deliveryModeMap = createMapFromArray(Made_Of_Delivery, (item => item.value), (item => item.label));
const gestationAgeMap = createMapFromArray(Gestational_Age, (item => item.value), (item => item.label));

const history = {
    bloodGrp: '',
    medicalHis: [],
    HospitalHis: [],
    socialHis: [],
    familyHis: [],
    diabeticHis: {
        diabetic_his: {},
        nutrition_his: {},
        physical_his: {},
        blood_glu_his: {},
        menstuaral_his: {}
    },
    obgynHis: {
        obgyn_menstrual_his: {},
        obstetrical_his: [],
        birth_control_his: {},
        gynaec_his: {},
        papsmear_his: {},
        obgyn_surgery: []
    },
    pdHis: {
        birth_his: {},
        medical_his: []
    }
}
export default function CollabHistory(props) {
    const [patient_id, setpatient_id] = useState(null)
    const [hisData, setHisData] = useState(history);
    const [errorMsg, seterrorMsg] = useState(false);
    const [errorMsgText, seterrorMsgText] = useState('');
    const { historyData } = props;

    useEffect(() => {
        try {
            const cachedData = getCachevalue('DoctorPatientData');
            const appointData = JSON.parse(cachedData);
            if (appointData?.patient_id) {
                setpatient_id(appointData.patient_id);
            }
            
        } catch (error) {
            errorMessage(error.message)
        }
    }, []);

    const fetchData = (url, query, handleResponse) => {
        RestAPIService.getAll(`${Serviceurls[url]}${query}`)
            .then((response) => {
                if (response.data.status === 'success') {
                    handleResponse(response.data.data);
                }
            }).catch((error) => {
                if (error?.response?.data?.message) {
                    errorMessage(error.response.data.message)
                } else {
                    errorMessage(error.message)
                }
            })
    }

    const msgClose = () => {
        seterrorMsg(false);
        seterrorMsgText('');
    }
    const errorMessage = (msg) => {
        seterrorMsg(true)
        seterrorMsgText(msg)
    }

    const renderDiv = (label, value, descrip = "") => {
        return (
            <div>
                <Typography fontSize={'1vw'}>{label}</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={'bold'}>{value || "-"}</Typography>
                {descrip && <Typography fontSize={'0.9vw'} fontWeight={'bold'}>{descrip || "-"}</Typography>}
            </div>
        )
    }

    const renderDiabeticHistory = () => {
        const { diabetic_his, nutrition_his, physical_his, blood_glu_his, menstuaral_his } = historyData.diabeticHis;
        return (
            <div>
                <Typography fontWeight={'bold'} marginTop={'1vw'} fontSize={'1vw'}>Diabetic History</Typography>
                <Paper sx={{ marginTop: '0.5vw', paddingBottom: "0.5dvw" }}>
                    {/* Diabetic History */}
                        <Box>
                            <Typography sx={{ fontSize: '1vw', backgroundColor: '#FAF9F9', border: '2px', padding: "0.5dvw" }}>
                                Diabetic History
                            </Typography>
                            <Box margin={'1dvw'} display={'grid'} gridTemplateColumns={"repeat(3, 1fr)"} columnGap={"1dvw"} rowGap={"0.625dvw"}>
                                {renderDiv("New to Diagnosis?", diabetic_his?.new_diabetes_diagnosed === null ? '' : diabetic_his?.new_diabetes_diagnosed ? 'Yes' : 'No')}
                                {renderDiv("Do you check your Blood Glucose level?", diabetic_his?.blood_glucose_check === null ? '' : diabetic_his?.blood_glucose_check ? 'Yes' : 'No')}
                                {renderDiv("Do you get low blood gulcose?", diabetic_his?.low_blood_glucose === null ? '' : diabetic_his?.low_blood_glucose ? 'Yes' : 'No')}
                                {renderDiv("How many years have you had diabetics ?", diabetic_his?.diabetes_duration)}
                                {renderDiv("Family History of Diabetic", diabetic_his?.diabetes_family_history)}
                                {renderDiv("What Type of Diabetic do you have?", diabetic_his?.diabetes_type === 1 ? 'Type 1' : diabetic_his?.diabetes_type === 2 ? 'Type 2' : diabetic_his?.diabetes_type === 0 ? 'Not Sure' : diabetic_his?.diabetes_type)}
                                {renderDiv("How is your Diabetic treated?", diabetic_his?.treatment_method?.join(", "))}
                                {renderDiv("Name of the blood Glucose meter", diabetic_his?.bg_check_meter)}
                                {renderDiv("Usual Result", diabetic_his?.bg_usual_result ? `${diabetic_his?.bg_usual_result} mg/dl` : "-")}
                                {renderDiv("Last A1c Result, Date", `${diabetic_his?.last_a1c_result ? diabetic_his?.last_a1c_result + "% , " : ""} ${diabetic_his?.last_ac1_date ? formatDate(diabetic_his?.last_ac1_date) : ""}`)}
                            </Box>
                        </Box>
                    {/* Nutrition History */}
                        <Box>
                            <Typography marginTop={'1vw'} sx={{ fontSize: '1vw', backgroundColor: '#FAF9F9', border: '2px', padding: "0.5dvw" }}>Nutrition</Typography>
                            <Box sx={{ marginTop: '.5vw' }}>
                                <Box margin={'1dvw'} display={'grid'} gridTemplateColumns={"repeat(3, 1fr)"} columnGap={"1dvw"} rowGap={"0.625dvw"}>
                                    {renderDiv("Have you ever had Nutritional Counselling ?", nutrition_his?.nutritional_counselling === null ? '' : nutrition_his?.nutritional_counselling ? 'Yes' : 'No')}
                                    {renderDiv("Types of Nutrition", nutrition_his?.nutrition_type)}
                                </Box>
                                <Typography fontSize={'1vw'} padding={"0.5dvw"}>
                                    Please List the typical foods and amounts that you eat and drink on a typical day
                                </Typography>
                                <Box margin={'1dvw'} display={'grid'} gridTemplateColumns={"repeat(3, 1fr)"} columnGap={"1dvw"} rowGap={"0.625dvw"}>
                                    {renderDiv("Early Morning", nutrition_his?.food_early_morning_amount)}
                                    {renderDiv("Breakfast", nutrition_his?.food_breakfast_amount)}
                                    {renderDiv("Mid-Morning", nutrition_his?.food_mid_morning_amount)}
                                    {renderDiv("Lunch", nutrition_his?.food_lunch_amount)}
                                    {renderDiv("Evening", nutrition_his?.food_evening_amount)}
                                    {renderDiv("Dinner", nutrition_his?.food_dinner_amount)}
                                    {renderDiv("Late Night", nutrition_his?.food_late_night_amount)}
                                    {renderDiv("Water Consumption(In Liters)", nutrition_his?.water_consumption)}
                                </Box>
                            </Box>
                        </Box>
                    {/* Physical Activity History */}
                        <Box>
                            <Typography marginTop={'1vw'} sx={{ fontSize: '1vw', backgroundColor: '#FAF9F9', border: '2px', padding: "0.5dvw" }}>Physical Activity</Typography>
                            <Box margin={'1dvw'} display={'grid'} gridTemplateColumns={"repeat(3, 1fr)"} columnGap={"1dvw"} rowGap={"0.625dvw"}>
                                {renderDiv("Do you exercise Daily ?", physical_his?.exercise_regularly === '' ? '' : physical_his?.exercise_regularly ? 'Yes' : 'No',)}
                                {renderDiv("Please rate your current daily activity level", physical_his?.current_activity_level)}
                                {renderDiv("How many days a week do you exercise ?", physical_his?.exercise_frequency_weeks)}
                                {renderDiv("How many minutes per workout session ?", `${physical_his?.exercise_duration_minutes} Mins`)}
                                {renderDiv("Types of exercise", physical_his?.exercise_types)}
                                {renderDiv("Intensity Level", physical_his?.exercise_intensity)}
                            </Box>
                        </Box>
                    {/* Blood Glucose Monitoring */}
                        <Box>
                            <Typography marginTop={'1vw'} sx={{ fontSize: '1vw', backgroundColor: '#FAF9F9', border: '2px', padding: "0.5dvw" }}>Blood Glucose Monitoring</Typography>
                            <Box margin={'1dvw'} display={'grid'} gridTemplateColumns={"repeat(3, 1fr)"} columnGap={"1dvw"} rowGap={"0.625dvw"}>
                                {renderDiv("How often do you test your blood glucose per month ?", blood_glu_his?.bg_check_frequency)}
                                {renderDiv("Any recent blood glucose above 300 ?", blood_glu_his?.bg_above_300 === null ? '' : blood_glu_his?.bg_above_300 ? 'Yes' : 'No')}
                                {renderDiv("Any recent blood glucose below 80 ?", blood_glu_his?.bg_below_70 === null ? '' : blood_glu_his?.bg_below_70 ? 'Yes' : 'No')}
                                {renderDiv("Have you had any of the following signs of high blood glucose in the past 3 months ?", blood_glu_his?.high_blood_glucose?.join(", ") || "")}
                                {renderDiv("Have you had any of the following signs of Low blood glucose in the past 3 months ?", blood_glu_his?.low_blood_glucose?.join(", ") || "")}
                                {renderDiv("Do you check your feet ?", blood_glu_his?.check_your_feet === null ? '' : blood_glu_his?.check_your_feet ? 'Yes' : 'No')}
                                {renderDiv("Do you have any problem in your vision ?", blood_glu_his?.vision_problem === null ? '' : blood_glu_his?.vision_problem ? 'Yes' : 'No')}
                            </Box>
                        </Box>
                    {/* Menstrual History */}
                        <Box>
                            <Typography marginTop={'1vw'} sx={{ fontSize: '1vw', backgroundColor: '#FAF9F9', border: '2px', padding: "0.5dvw" }}>Menstrual History</Typography>
                            <Box margin={'1dvw'} display={'grid'} gridTemplateColumns={"repeat(3, 1fr)"} columnGap={"1dvw"} rowGap={"0.625dvw"}>
                                {renderDiv("Age at Menarche", menstuaral_his?.age_at_menarche)}
                                {renderDiv("Menstrual cycle", menstuaral_his?.mensural_cycle)}
                                {renderDiv("Child birth / abortion", menstuaral_his?.child_birth)}
                                {renderDiv("Age at Menopause", menstuaral_his?.age_at_menopause)}
                            </Box>
                        </Box>
                </Paper>
            </div>)
    }

    const renderObgynHistory = () => {
        const { obgyn_menstrual_his, obstetrical_his, birth_control_his, gynaec_his, papsmear_his, obgyn_surgery } = historyData.obgynHis;
        return (
            <div>
                <Typography fontWeight={'bold'} marginTop={'1vw'} fontSize={'1vw'}>OBGYN History</Typography>
                <Paper sx={{ marginTop: '0.5vw', paddingBottom: "0.5dvw" }}>
                    {/* Menstrual History */}
                        <Box>
                            <Typography sx={{ fontSize: '1vw', backgroundColor: '#FAF9F9', border: '2px', padding: "0.5dvw" }}>
                                Menstrual History
                            </Typography>
                            <Box margin={'1dvw'} display={'grid'} gridTemplateColumns={"repeat(3, 1fr)"} columnGap={"1dvw"} rowGap={"0.625dvw"}>
                                {renderDiv("Are your periods regular ?", obgyn_menstrual_his?.is_period_regular ? "Yes" : "No")}
                                {renderDiv("Your Periods starts every", obgyn_menstrual_his?.period_start_duration ? `${obgyn_menstrual_his?.period_start_duration} Day(s)` : "-")}
                                {renderDiv("How many days your periods last?", obgyn_menstrual_his?.period_last_duration ? `${obgyn_menstrual_his?.period_last_duration} Day(s)` : "-")}
                                {renderDiv("Does Bleeding / Spotting occur between periods?", obgyn_menstrual_his?.is_bleeding_btw_periods ? "Yes" : "No", obgyn_menstrual_his?.bleeding_btw_periods_desc)}
                                {renderDiv("Is the pain associated with periods?", obgyn_menstrual_his?.pain_with_periods === "yes" ? "Yes" : "No")}
                                {renderDiv("Are you on any current treatment?", obgyn_menstrual_his?.on_current_treatment ? "Yes" : "No", obgyn_menstrual_his?.current_treatment_desc)}
                            </Box>
                        </Box>
                    {/* Obstetrical History */}
                        <Box>
                            <Typography marginTop={'1vw'} sx={{ fontSize: '1vw', backgroundColor: '#FAF9F9', border: '2px', padding: "0.5dvw" }}>Obstetrical History</Typography>
                            <Box marginTop={'.5vw'}>
                                <Table size='small' aria-label="stictbl">
                                    <TableHead sx={{ backgroundColor: '#FAF9F9' }}>
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: 'bold' }}>Year</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>Place of Delivery</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>Duration of Pregnancy (Weeks)</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>Hours of Labor</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>Type of Delivery</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>Complication For Mother /Baby</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>Gender</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>Birth Weight (Kgs)</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>Present Health</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {obstetrical_his?.length > 0 ? obstetrical_his?.map((item) => (
                                            <TableRow>
                                                <TableCell sx={{ fontSize: "0.9dvw" }}>{item?.year_of_delivery_or_abortion || '-'}</TableCell>
                                                <TableCell sx={{ fontSize: "0.9dvw" }}>{item?.place_of_delivery_or_abortion || '-'}</TableCell>
                                                <TableCell sx={{ fontSize: "0.9dvw" }}>{item?.duration_of_pregnancy || '-'}</TableCell>
                                                <TableCell sx={{ fontSize: "0.9dvw" }}>{item?.hours_of_Labor || '-'}</TableCell>
                                                <TableCell sx={{ fontSize: "0.9dvw" }}>{item?.type_of_delivery ? item?.type_of_delivery?.length > 15 ?
                                                    <Tooltip placement='top' title={item?.type_of_delivery}><Box sx={{ fontSize: "0.9dvw" }}>{item?.type_of_delivery.slice(0, 15) + '...'}</Box></Tooltip> :
                                                    item?.type_of_delivery : '-'}</TableCell>
                                                <TableCell sx={{ fontSize: "0.9dvw" }}>{item?.complications_for_mother_or_baby ? item?.complications_for_mother_or_baby?.length > 15 ?
                                                    <Tooltip placement='top' title={item?.complications_for_mother_or_baby}>
                                                        <Box sx={{ fontSize: "0.9dvw" }}>{item?.complications_for_mother_or_baby.slice(0, 15) + '...'}</Box>
                                                    </Tooltip> :
                                                    item?.complications_for_mother_or_baby : '-'}</TableCell>
                                                <TableCell sx={{ fontSize: "0.9dvw" }}>{item?.year_of_delivery_or_abortion ? (item?.child_gender === "m" ? "Male" : item?.child_gender === "f" ? "Female" : "-") : '-'}</TableCell>
                                                <TableCell sx={{ fontSize: "0.9dvw" }}>{item?.child_birth_weight || '-'}</TableCell>
                                                <TableCell sx={{ fontSize: "0.9dvw" }}>
                                                    {item?.child_present_health.length > 15 ?
                                                        <Tooltip title={item?.child_present_health}>
                                                            <div>{item?.child_present_health ? item?.child_present_health.slice(0, 15) + "..." : '-'}</div>
                                                        </Tooltip> : item?.child_present_health ? item?.child_present_health : '-'}
                                                </TableCell>
                                            </TableRow>
                                        )) :
                                            <TableRow>
                                                <TableCell colSpan={9} align="center">{'No Records To Be Shown'}</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </Box>
                        </Box>
                    {/* Birth Control Methods */}
                        <Box>
                            <Typography marginTop={'1vw'} sx={{ fontSize: '1vw', backgroundColor: '#FAF9F9', border: '2px', padding: "0.5dvw" }}>Birth Control Methods</Typography>
                            <Box margin={'1dvw'} display={'grid'} gridTemplateColumns={"repeat(3, 1fr)"} columnGap={"1dvw"} rowGap={"0.625dvw"}>
                                {renderDiv("Did you experiance depression before or after pregnancy?", birth_control_his?.is_depression_before_after_pregnancy ? "Yes" : "No")}
                                {renderDiv("Have you used and birth control in the past ?", birth_control_his?.past_birth_control_methods)}
                                {renderDiv("Have you used any infertility therapy previously", birth_control_his?.previous_infertility_therapy_desc)}
                            </Box>
                        </Box>
                    {/* Gynaecological History */}
                        <Box>
                            <Typography marginTop={'1vw'} sx={{ fontSize: '1vw', backgroundColor: '#FAF9F9', border: '2px', padding: "0.5dvw" }}>Gynaecological History</Typography>
                            <Box margin={'1dvw'} display={'grid'} gridTemplateColumns={"repeat(3, 1fr)"} columnGap={"1dvw"} rowGap={"0.625dvw"}>
                                {renderDiv("Have you ever had a yeast infection", gynaec_his?.had_yeast_infection ? "Yes" : "No")}
                                {renderDiv("Have you ever been diagnosed with any fibroids in uterus", gynaec_his?.had_fibroids_uterus ? "Yes" : "No")}
                                {renderDiv("Have you ever had an overian cyst", gynaec_his?.had_ovarian_cysts ? "Yes" : "No")}
                                {renderDiv("Have you ever been treated for vaginal bacterial infection (bacterial vaginosis)?", gynaec_his?.had_bacterial_vaginosis ? "Yes" : "No", gynaec_his?.bacterial_vaginosis_desc)}
                                {renderDiv("Does bleeding / spotting occur after intercourse?", gynaec_his?.is_bleeding_after_intercourse ? "Yes" : "No", gynaec_his?.bleeding_intercourse_desc)}
                                {renderDiv("Did you have any urinary problems such as UTI(Urinary Tract Infection), Urinary Frequency, Loss of bladder control, blood in urine, etc..",
                                    gynaec_his?.had_urinary_problems ? "Yes" : "No", gynaec_his?.urinary_problems_desc)}
                                {renderDiv("If in menopause, at what age did it occur?", gynaec_his?.menopause_occured_age)}
                                {renderDiv("Have you used any hormone replacement?", gynaec_his?.used_hormone_replacement)}
                                {renderDiv("Other Gynaecological history", gynaec_his?.other_gyn_history)}
                            </Box>
                        </Box>
                    {/* Pap smear / Mammogram History */}
                        <Box>
                            <Typography marginTop={'1vw'} sx={{ fontSize: '1vw', backgroundColor: '#FAF9F9', border: '2px', padding: "0.5dvw" }}>Pap smear / Mammogram History</Typography>
                            <Box margin={'1dvw'} display={'grid'} gridTemplateColumns={"repeat(3, 1fr)"} columnGap={"1dvw"} rowGap={"0.625dvw"}>
                                {renderDiv("Date of last pap smear ?", papsmear_his?.last_pap_smear ? formatDate(papsmear_his?.last_pap_smear) : "-")}
                                {renderDiv("Have you ever had any treatment for an abnormal pap smear?", papsmear_his?.had_abnormal_pap_smears_treatment ? "Yes" : "No", papsmear_his?.abnormal_treatment_desc)}
                                {renderDiv("Cryotherapy", papsmear_his?.treatment_cryotherapy ? "Yes" : "No", papsmear_his?.cryotherapy_desc)}
                                {renderDiv("Laser", papsmear_his?.treatment_laser ? "Yes" : "No", papsmear_his?.laser_desc)}
                                {renderDiv("Cone Biopsy", papsmear_his?.treatment_cone_biopsy ? "Yes" : "No", papsmear_his?.cone_biopsy_desc)}
                                {renderDiv("Loop Excision(LEEP)", papsmear_his?.treatment_leep ? "Yes" : "No", papsmear_his?.leep_desc)}
                                {renderDiv("Date of last mammogram?", papsmear_his?.last_mammogram_date ? formatDate(papsmear_his?.last_mammogram_date) : "-")}
                                {renderDiv("Have you had an abnormal mammogram?", papsmear_his?.had_abnormal_mammogram ? "Yes" : "No", papsmear_his?.abnormal_mammogram_desc)}
                            </Box>
                        </Box>
                    {/* OBGYN Surgeries */}
                        <Box>
                            <Typography marginTop={'1vw'} sx={{ fontSize: '1vw', backgroundColor: '#FAF9F9', border: '2px', padding: "0.5dvw" }}>OBGYN Surgeries</Typography>
                            <Box margin={'1dvw'} display={'grid'} gridTemplateColumns={"repeat(3, 1fr)"} columnGap={"1dvw"} rowGap={"0.625dvw"}>
                                {obgyn_surgery?.map((item) => (
                                    <div key={item?.id}>
                                        {renderDiv(item?.surgery_name, `${item?.surgery_id ? (item?.is_surgery_done ? "Yes" : "No") : "Yes"} ${item.surgery_year ? "," + formatDate(item.surgery_year) : ""}`)}
                                    </div>
                                ))}
                            </Box>
                        </Box>
                </Paper>
            </div>)
    }

    const renderPediatricHistory = () => {
        const { birth_his, medical_his } = historyData.pdHis;
        return (
            <div>
                <Typography fontWeight={'bold'} marginTop={'1vw'} fontSize={'1vw'}>PD History</Typography>
                <Paper sx={{ marginTop: '0.5vw', paddingBottom: "0.5dvw" }}>
                    {/* Birth History */}
                        <Box>
                            <Typography sx={{ fontSize: '1vw', backgroundColor: '#FAF9F9', border: '2px', padding: "0.5dvw" }}>
                                Birth History
                            </Typography>
                            <Box margin={'1dvw'} display={'grid'} gridTemplateColumns={"repeat(3, 1fr)"} columnGap={"1dvw"} rowGap={"0.625dvw"}>
                                {renderDiv("Any complications during pregnancy ?", birth_his?.pregnancy_complication ? "Yes" : "No", birth_his?.pregnancy_comp_desc)}
                                {renderDiv("Complications after birth ?", birth_his?.after_birth_complication ? "Yes" : "No", birth_his?.after_birth_comp_desc)}
                                {renderDiv("Is there any admission during the Neonatal period ? ", birth_his?.neonatal_period ? "Yes" : "No", birth_his?.neonatal_period_reason)}
                                {renderDiv("Mode of Delivery", birth_his?.mode_of_delivery)}
                                {renderDiv("Gestational Age", birth_his?.gestational_age)}
                                {renderDiv("Birth Weight (kgs)", birth_his?.birth_weight)}
                                {renderDiv("Development", birth_his?.development)}
                                {renderDiv("Feeding History", birth_his?.feeding_history)}
                            </Box>
                        </Box>
                    {/* Medical History */}
                        <Box>
                            <Typography marginTop={'1vw'} sx={{ fontSize: '1vw', backgroundColor: '#FAF9F9', border: '2px', padding: "0.5dvw" }}>Medical History</Typography>
                            <Table size='small' sx={{ marginTop: '.5vw' }} aria-label="stictbl">
                                <TableHead sx={{ backgroundColor: '#FAF9F9' }}>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Problem</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Comments</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {medical_his?.length > 0 ? medical_his?.map((item) => (
                                        <TableRow>
                                            <TableCell>{item?.surgery ? item?.surgery?.length > 30 ?
                                                <Tooltip placement='top' title={item?.surgery}><div>{item?.surgery?.slice(0, 30) + '...'}</div></Tooltip> :
                                                item?.surgery : '-'}</TableCell>
                                            <TableCell>{item?.description ? item?.description?.length > 30 ?
                                                <Tooltip placement='top' title={item?.description}><div>{item?.description?.slice(0, 30) + '...'}</div></Tooltip> :
                                                item?.description : '-'}</TableCell>
                                        </TableRow>
                                    )) :
                                        <TableRow>
                                            <TableCell colSpan={3} align="center">{'No Records To Be Shown'}</TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </Box>
                </Paper>
            </div>)
    }

    const { id, isProgrammaticScroll, userData } = props;
    return (
        <Box>
            <Box marginLeft={'.5vw'} borderBottom={'1px solid #E3E2E2'} paddingBottom={"1vw"}>
                    <Box id={`medicalHistory_${id}`}>
                        <Typography fontWeight={'bold'} fontSize={'1vw'}>Medical History</Typography>
                        <Box marginTop={'.5vw'}>
                            <Typography fontSize={'1vw'}>Blood Group: <b>{historyData.bloodGrp || '-'}</b></Typography>
                            <Paper>
                                <TableContainer>
                                    <Table size='small' aria-label="stictbl">
                                        <TableHead sx={{ backgroundColor: '#FAF9F9' }}>
                                            <TableRow>
                                                <TableCell sx={{ fontWeight: 'bold', width: '30vw' }}>Date</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold', width: '35vw' }}>Problem</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold', width: '35vw' }}>Comments</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {historyData.medicalHis.length > 0 ? historyData.medicalHis.map((item) => (
                                                <TableRow>
                                                    <TableCell sx={{ width: '30vw' }}>{item.created_date || '-'}</TableCell>
                                                    <TableCell sx={{ width: '35vw' }}>{item?.disease_name ? item?.disease_name?.length > 30 ?
                                                        <Tooltip placement='top' title={item?.disease_name}><div>{item?.disease_name.slice(0, 30) + '...'}</div></Tooltip> :
                                                        item?.disease_name : '-'}</TableCell>
                                                    <TableCell sx={{ width: '35vw' }}>{item?.comments ? item?.comments?.length > 30 ?
                                                        <Tooltip placement='top' title={item?.comments}><div>{item?.comments.slice(0, 30) + '...'}</div></Tooltip> :
                                                        item?.comments : '-'}</TableCell>
                                                </TableRow>
                                            )) :
                                                <TableRow>
                                                    <TableCell colSpan={3} align="center">{'No Records To Be Shown'}</TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Box>
                    </Box>
                
                    <Box id={`hospitalHistory_${id}`}>
                        <Typography fontWeight={'bold'} marginTop={'1vw'} fontSize={'1vw'}>Hospitalization History</Typography>
                        <Box marginTop={'.5vw'}>
                            <Paper>
                                <TableContainer>
                                    <Table size='small' aria-label="stictbl">
                                        <TableHead sx={{ backgroundColor: '#FAF9F9' }}>
                                            <TableRow>
                                                <TableCell sx={{ fontWeight: 'bold', width: '30vw' }}>Hospitalized For</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold', width: '35vw' }}>Problem</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold', width: '35vw' }}>Hospital Name</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold', width: '35vw' }}>Year</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold', width: '35vw' }}>Comments</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {historyData.HospitalHis.length > 0 ? historyData.HospitalHis.map((item) => (
                                                <TableRow>
                                                    <TableCell sx={{ width: '15vw' }}>{item.is_surgery ? 'Surgery' : 'Hospitailized'}</TableCell>
                                                    <TableCell sx={{ width: '25vw' }}>{item?.disease_name ? item?.disease_name?.length > 15 ?
                                                        <Tooltip placement='top' title={item?.disease_name}><div>{item?.disease_name.slice(0, 15) + '...'}</div></Tooltip> :
                                                        item?.disease_name : '-'}</TableCell>
                                                    <TableCell sx={{ width: '10vw' }}>{item.hospitalized_year || '-'}</TableCell>
                                                    <TableCell sx={{ width: '25vw' }}>{item?.hospital_name_or_description ? item?.hospital_name_or_description?.length > 15 ?
                                                        <Tooltip placement='top' title={item?.hospital_name_or_description}><div>{item?.hospital_name_or_description.slice(0, 15) + '...'}</div></Tooltip> :
                                                        item?.hospital_name_or_description : '-'}</TableCell>
                                                    <TableCell sx={{ width: '25vw' }}>{item?.comments ? item?.comments?.length > 15 ?
                                                        <Tooltip placement='top' title={item?.comments}><div>{item?.comments.slice(0, 15) + '...'}</div></Tooltip> :
                                                        item?.comments : '-'}</TableCell>
                                                </TableRow>
                                            )) :
                                                <TableRow>
                                                    <TableCell colSpan={3} align="center">{'No Records To Be Shown'}</TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Box>
                    </Box>
                
                    <Box id={`familyHistory_${id}`}>
                        <Typography fontWeight={'bold'} marginTop={'1vw'} fontSize={'1vw'}>Family History</Typography>
                        <Box marginTop={'.5vw'}>
                            <Paper>
                                <TableContainer>
                                    <Table size='small' aria-label="stictbl">
                                        <TableHead sx={{ backgroundColor: '#FAF9F9' }}>
                                            <TableRow>
                                                <TableCell sx={{ fontWeight: 'bold', width: '30vw' }}>Selected Family Member</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold', width: '35vw' }}>Family History</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold', width: '35vw' }}>Description</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold', width: '35vw' }}>Comments</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {historyData.familyHis.length > 0 ? historyData.familyHis.map((item) => (
                                                <TableRow>
                                                    <TableCell sx={{ width: '15vw' }}>{item.family_member || '-'}</TableCell>
                                                    <TableCell sx={{ width: '25vw' }}>{item?.disease_names ? item?.disease_names?.length > 2 ?
                                                        <Tooltip placement='top' title={item?.disease_names?.join(',')}><div>{item?.disease_names?.slice(0, 2)?.join(',') + '...'}</div></Tooltip> :
                                                        item?.disease_names?.join(',') : '-'}</TableCell>
                                                    <TableCell sx={{ width: '10vw' }}>{item.description || '-'}</TableCell>
                                                    <TableCell sx={{ width: '25vw' }}>{item?.comments ? item?.comments?.length > 15 ?
                                                        <Tooltip placement='top' title={item?.comments}><div>{item?.comments.slice(0, 15) + '...'}</div></Tooltip> :
                                                        item?.comments : '-'}</TableCell>
                                                </TableRow>
                                            )) :
                                                <TableRow>
                                                    <TableCell colSpan={4} align="center">{'No Records To Be Shown'}</TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Box>
                    </Box>
                
                    <Box id={`socialHistory_${id}`}>
                        <Typography fontWeight={'bold'} marginTop={'1vw'} fontSize={'1vw'}>Social History</Typography>
                        <Paper sx={{ padding: "0.625dvw 0dvw", marginTop: '.5vw' }}>
                            <Box margin={'1dvw'} display={'grid'} gridTemplateColumns={"repeat(4, 1fr)"} columnGap={"1dvw"} rowGap={"0.625dvw"}>
                                {renderDiv("Alcohol", historyData?.socialHis[0]?.alcohol)}
                                {renderDiv("Smoking", historyData?.socialHis[0]?.smoking)}
                                {renderDiv("Drugs", historyData?.socialHis[0]?.drugs?.join(', '))}
                                {renderDiv("Daily Activity", historyData?.socialHis[0]?.daily_activity)}
                                {renderDiv("Is Married", historyData?.socialHis[0]?.is_married === null ? '-' : historyData?.socialHis[0]?.is_married ? 'Yes' : 'No')}
                                {renderDiv("Is Exercise done daily", historyData?.socialHis[0]?.is_exercise_done_daily === null ? '-' : historyData?.socialHis[0]?.is_exercise_done_daily ? 'Yes' : 'No')}
                                {renderDiv("Occupation", historyData?.socialHis[0]?.occupation)}
                            </Box>
                        </Paper>
                    </Box>
                
                {userData.service_type === "DB" && renderDiabeticHistory()}
                {userData.service_type === "OBGYN" && renderObgynHistory()}
                {userData.service_type === "PD" && renderPediatricHistory()}
            </Box>
            {errorMsg && <ToastMsg
                severity={'error'}
                msg={errorMsgText}
                msgPop={msgClose.bind(this)}
            />}
        </Box>
    )
}