import { Box, FormControlLabel, Stack, TextField, Button, Tooltip, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Switch, IconButton, Autocomplete } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Colors } from '../../../Styles/Colors'
import { DataGrid } from '@mui/x-data-grid'
import { CommonDeleteButton, CommonEditButton } from '../../../Components/Common Components/CommonComponents'
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation'
import { ImagePaths } from '../../../Utility/ImagePaths'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import { useHistory, useLocation } from 'react-router-dom'
import Loader from '../../../Components/Loader';
import { CurrencySymbol } from '../../../Utility/Constants'
import { AmountFormat } from '../../../Components/CommonFunctions/CommonFunctions'

const assStates = {
    assPackCode: '',
    assPackName: '',
    finalAmt: '',
}
const list = ['Consumable', 'Non Consumable']
export default function AssortedPackageAdd() {
    const history = useHistory();
    const location = useLocation();
    const [assData, setassData] = useState(assStates);
    const [headText, setheadText] = useState('');
    const [headTbl, setheadTbl] = useState([]);
    const [errorMsg, seterrorMsg] = useState(false);
    const [errorMsgText, seterrorMsgText] = useState('');
    const [successMsg, setsuccessMsg] = useState(false);
    const [successMsgText, setsuccessMsgText] = useState('');
    const [Index, setIndex] = useState(null);
    const [serviceType, setserviceType] = useState(null);
    const [serviceName, setserviceName] = useState(null);
    const [serviceQty, setserviceQty] = useState('');
    const [serAmt, setserAmt] = useState('');
    const [editHead, seteditHead] = useState('');
    const [isDrug, setisDrug] = useState(false);
    const [selPack, setselPack] = useState(null);
    const [IPPackage, setIPPackage] = useState([]);
    const [DrugPackage, setDrugPackage] = useState([]);
    const [serTypeData, setserTypeData] = useState([]);
    const [serNameData, setserNameData] = useState([]);
    const [MediserNameData, setMediserNameData] = useState([]);
    const [EditID, setEditID] = useState(null);
    const [EditListID, setEditListID] = useState(null);
    const [deletedIDs, setdeletedIDs] = useState([]);
    const [headIDs, setheadIDs] = useState([]);
    const [isloader, setisloader] = useState(false);
    const [serData, setserData] = useState([]);
    const [isDrugEdit, setIsDrugEdit] = useState(false)

    useEffect(() => {
        getIPackageService()
        getDrugPackageService()
        getServiceType()
    }, [])

    useEffect(() => {
        // if (isDrug && serviceType) {
            getDrugDetails()
        // }
        if (location.states) {
            let data = location.states.line_items
            setheadTbl(data)
            setEditListID(location.states.id)
            setassData({ assPackCode: location.states.pack_code, assPackName: location.states.pack_name, finalAmt: location.states.pack_amount, })
        }
    }, [])

    useEffect(() => {
        if (headTbl && headTbl.length > 0) {
            setheadTbl((currentHeadTbl) => {
                let hasChanges = false;
    
                const updatedHeadTbl = currentHeadTbl.map((item) => {
                    const totalAmount = item?.service_data?.length > 0
                        ? item.service_data.reduce((sum, ser) => +(sum + +(ser.amount || 0)).toFixed(2), 0)
                        : 0;
                    if (item.totalAmount !== totalAmount) {
                        hasChanges = true;
                        return {
                            ...item,
                            totalAmount,
                        };
                    }
                    return item;
                });
                return hasChanges ? updatedHeadTbl : currentHeadTbl;
            });
        }
    }, [headTbl]);
    

    useEffect(() => {
        if (headTbl && headTbl.length > 0) {
            const headTotalAmt = headTbl.reduce(
                (sum, head) => sum + parseFloat(head.totalAmount || 0),
                0
            );
            setassData(prev => ({ ...prev, finalAmt: +headTotalAmt.toFixed(2) }));
        }
    }, [headTbl]);


    const getDrugDetails = () => {
        try {
            RestAPIService.getAll(Serviceurls.ASS_GET_DRUG_MASTER)
                .then((response) => {
                    if (response.data.status === 'success') {
                        const filData = response.data.data
                        setMediserNameData(filData || [])
                    }
                }).catch(e => {
                    if (e?.response?.data?.status === 'fail') {
                        errorMessage(e.response.data.message)
                    } else {
                        errorMessage(e.message)
                    }
                })
        } catch (e) {
            errorMessage(e.message)
        }
    }
    const getIPackageService = () => {
        try {
            setisloader(true)
            RestAPIService.getAll(Serviceurls.FO_CONFIG_IPSRCPACKAGE)
                .then((response) => {
                    if (response.data.status === 'success') {
                        setisloader(false)
                        setIPPackage(response?.data?.data || [])
                    }
                }).catch(e => {
                    setisloader(false)
                    if (e?.response?.data?.status === 'fail') {
                        errorMessage(e.response.data.message)
                    } else {
                        errorMessage(e.message)
                    }
                })
        } catch (e) {
            setisloader(false)
            errorMessage(e.message)
        }
    }

    const getDrugPackageService = () => {
        try {
            setisloader(true)
            RestAPIService.getAll(Serviceurls.ASS_GET_DRUG_PACKAGE)
                .then((response) => {
                    if (response.data.status === 'success') {
                        setisloader(false)
                        setDrugPackage(response?.data?.data || [])
                    }
                }).catch(e => {
                    setisloader(false)
                    if (e?.response?.data?.status === 'fail') {
                        errorMessage(e.response.data.message)
                    } else {
                        errorMessage(e.message)
                    }
                })
        } catch (e) {
            setisloader(false)
            errorMessage(e.message)
        }
    }
    const getServiceType = () => {
        try {
            setisloader(true)
            RestAPIService.getAll(Serviceurls.IP_NEW_SERVICE_CONFIGURATION)
                .then((response) => {
                    if (response.data.status === 'success') {
                        setisloader(false)
                        setserTypeData(response?.data?.data || [])
                    }
                }).catch(e => {
                    setisloader(false)
                    if (e?.response?.data?.status === 'fail') {
                        errorMessage(e.response.data.message)
                    } else {
                        errorMessage(e.message)
                    }
                })
        } catch (e) {
            setisloader(false)
            errorMessage(e.message)
        }
    }

    const errorMessage = (msg) => {
        seterrorMsg(true)
        seterrorMsgText(msg)
    }

    const successMessage = (msg) => {
        setsuccessMsg(true)
        setsuccessMsgText(msg)
    }


    const textBox = (label, name, val, width) => {
        return (
            <Box>
                <TextField
                    size='small'
                    name={name}
                    value={val}
                    disabled={name === 'finalAmt'}
                    id="drug-master"
                    sx={{ width: width, marginRight: '1vw' }}
                    label={label}
                    onChange={(e) => { onChangeHandler(e, name) }}
                    variant="outlined"
                />
            </Box>
        )
    }

    const onChangeHandler = (e, name) => {
        let value = e.target.value
        let numval = CommonValidation.NumberWithDot(value);
        switch (name) {
            case 'assPackCode':
                let valid = CommonValidation.ALPHA_NUMARIC(value)
                if (value === "" || valid && value.length <= 20) {
                    setassData({ ...assData, [name]: value })
                }
                break
            case 'assPackName':
                if (value === "" || value.length <= 100) {
                    setassData({ ...assData, [name]: value })
                }
                break
            case 'serviceQty':
                let validnum = CommonValidation.numberOnly(value)
                if (value === "" || validnum && value.length <= 5) {
                    setserviceQty(value)
                }
                break
            case 'serAmt':
                if (value === '' || numval && value.length <= 10) {
                    setserAmt(value)
                }
                break
            default:
                if (value === '' || numval && value.length <= 10) {
                    setassData({ ...assData, [name]: value })
                }
                break
        }
    }

    const onAddService = () => {
        if (!headText) {
            errorMessage('Please Enter the Service Name');
        } else {
            let data = {
                "service_head": headText,
                "service_data": serData.length > 0 ? serData : [],
                "index_no": Index + 1
            };

            if (Index !== undefined && Index !== null) {
                const editPackList = headTbl.map((item, i) =>
                    i === +Index ? { ...item, ...data } : item
                );
                setheadTbl(editPackList);
            } else {
                setheadTbl(prevHeadTbl => [...prevHeadTbl, data]);
            }

            setheadText('');
            setIndex(null);
            seteditHead('');
            setserData([])
        }
    };
    const Servicetbl = () => {
        return (
            <Box sx={{ width: '40vw', height: '63vh' }}>
                <TableContainer component={Paper} sx={{ height: '63vh' }}>
                    <Table size='small' sx={{ width: '40vw', }} aria-label="simple table">
                        <TableHead sx={{ backgroundColor: '#FAF9F9' }}>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 'bold', width: '25vw' }}>Service Head</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', width: '10vw' }}>Total Amount</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', width: '5vw' }}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ width: '25vw' }}>
                                    <TextField
                                        size='small'
                                        value={headText}
                                        id="ass-service"
                                        sx={{ width: '16vw' }}
                                        placeholder='Service Head'
                                        onChange={(e) => {
                                            if (e.target.value === '' || e.target.value.length <= 100) {
                                                setheadText(e.target.value)
                                            }
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter' && (e.target.value === '' || e.target.value.length <= 100)) {
                                                setheadText(e.target.value)
                                                onAddService()
                                            }
                                        }}
                                        variant="standard"
                                    />
                                </TableCell>
                                <TableCell sx={{ width: '10vw' }}>{'-'}</TableCell>
                                <TableCell sx={{ width: '5vw' }}>
                                    <Tooltip placement='top' title={'Add'}>
                                        <IconButton size='small' onClick={() => { onAddService() }}>
                                            <img component={'img'} src={ImagePaths.AddUserButton.default} alt='plus' style={{ width: '1.2vw', height: '1.2vw' }} />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                            {headTbl.length > 0 ? headTbl.map((item, i) => (
                                <TableRow sx={{ bgcolor: (editHead === item.service_head) ? '#d9d9d9' : 'white', cursor: 'pointer' }}>
                                    <TableCell sx={{ width: '25vw' }} onClick={() => {
                                        seteditHead(item.service_head);
                                        setselPack(null);
                                        setserviceType(null);
                                        setserviceName(null);
                                        setserviceQty('');
                                        setserAmt('');
                                        setEditID(null);
                                        setisDrug(false);
                                        setIsDrugEdit(false);
                                    }}>
                                        {item.service_head.length > 50 ?
                                            <Tooltip title={item.service_head} placement='top'><Typography fontSize={'1vw'}>{item.service_head.slice(0, 50) + '...'}</Typography></Tooltip> :
                                            <Typography fontSize={'1vw'}>{item.service_head}</Typography>}
                                    </TableCell>
                                    <TableCell sx={{ width: '10vw' }}>{AmountFormat(item.totalAmount || 0)?.replace(`${CurrencySymbol}`, "")}</TableCell>
                                    <TableCell sx={{ width: '5vw' }}>
                                        <Stack direction="row" alignItems="center" spacing={4}>
                                            <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={1}>
                                                <CommonEditButton size="1.5vw" onClick={() => { setIndex(i); setheadText(item.service_head); setserData(item.service_data); }} />
                                                <CommonDeleteButton size="1.5vw" onClick={() => {
                                                    let updatedTbl = [...headTbl];
                                                    const removedService = updatedTbl[i];
                                                    if (removedService?.head_id) {
                                                        setheadIDs((prev) => [...prev, removedService.head_id]);
                                                    }
                                                    updatedTbl.splice(i, 1);
                                                    setheadTbl(updatedTbl);
                                                }} />
                                            </Stack>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            )) : (
                                <TableRow>
                                    <TableCell colSpan={3} align="center">{'No Records To Be Shown'}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        )
    }


    const autoFeild = (label, name, val, list, width, dis) => {
        return (
            <Box>
                {name === 'serviceType' || name === 'serviceName' ?
                    (isDrug ?
                        <Autocomplete
                            size={'small'}
                            options={list}
                            disabled={dis || EditID}
                            name={name}
                            getOptionLabel={(option) => typeof (option) === 'string' ? option : name === 'serviceName' ? `${option.drug_name} | ${option.dosage_type} | ${option.drug_strength}` : option}
                            value={val}
                            sx={{ width: width, marginRight: '1vw' }}
                            onChange={(event, newValue) => { onChangeAuto(event, newValue, name) }}
                            renderInput={(params) => <TextField {...params} placeholder={label} variant='standard' />}
                        /> : <Autocomplete
                            size={'small'}
                            options={list}
                            disabled={dis || EditID}
                            name={name}
                            getOptionLabel={(option) => typeof (option) === 'string' ? option : name === 'serviceName' ? option.service_name : option.service_type}
                            value={val}
                            sx={{ width: width, marginRight: '1vw' }}
                            onChange={(event, newValue) => { onChangeAuto(event, newValue, name) }}
                            renderInput={(params) => <TextField {...params} placeholder={label} variant='standard' />}
                        />) :
                    <TextField
                        size='small'
                        disabled={dis}
                        name={name}
                        value={val}
                        id="drug-master"
                        sx={{ width: width, marginRight: '1vw' }}
                        placeholder={label}
                        onChange={(e) => { onChangeHandler(e, name) }}
                        variant="standard"
                    />}
            </Box>
        )
    }

    const onChangeAuto = (e, val, name) => {
        switch (name) {
            case 'serviceType':
                setserviceType(val);
                if (val?.data && Array.isArray(val.data)) {
                    setserNameData(val.data);
                }
                // if(isDrug){
                //     getDrugDetails()
                // }

                break;
            case 'serviceName':
                setserviceQty("1")
                setserviceName(val)
                setserAmt(val.amount ? val.amount : '')
                break;
            default:
                setserviceName(val);
                break;
        }
    };

    const onAddType = (e, val) => {
        setselPack(val)
    }
    const addServiceTbl = () => {
        if (!isDrug) {
            setheadTbl((prev) => {
                return prev.map((item) => {
                    if (item.service_head === editHead) {
                        const newServiceData = selPack?.service_data?.map((service, i) => ({
                            ...service,
                            quantity: service.quantity ? service.quantity : 1,
                            index_no: i + 1,
                            service_config_id: service.id,
                            id : null
                        }));
                        const existingIds = new Set(item.service_data.map(service => service.id));
                        const filteredNewServiceData = newServiceData.filter(service => {
                            if (existingIds.has(service.service_config_id)) {
                                errorMessage('Already Service is Added')
                                return false;
                            }
                            return true;
                        });
                        return {
                            ...item,
                            service_data: [
                                ...item.service_data,
                                ...filteredNewServiceData
                            ]
                        };
                    }
                    return item;
                });
            });
        } else {
            setheadTbl((prev) => {
                return prev.map((item) => {
                    if (item.service_head === editHead) {
                        const newServiceData = selPack.line_items.map((service, i) => ({
                            ...service,
                            quantity: service.quantity ? service.quantity : 1,
                            index_no: i + 1,
                            drug_config_id: service.drug_config_id,
                        }));
                        const existingIds = new Set(item.service_data.map(service => service.drug_config_id));

                        const duplicates = [];
                        const filteredNewServiceData = newServiceData.filter(lineItem => {
                            if (existingIds.has(lineItem.drug_config_id)) {
                                errorMessage(`Already Drug is Added`);
                                return false;
                            }
                            return true;
                        });

                        return {
                            ...item,
                            service_data: [
                                ...item.service_data,
                                ...filteredNewServiceData.map((lineItem, i) => ({
                                    drug_config_id: lineItem.drug_config_id,
                                    service_name: lineItem.drug_name,
                                    service_type: lineItem.drug_type,
                                    quantity: lineItem.quantity,
                                    amount: lineItem.amount,
                                    drug_strength: lineItem.drug_strength,
                                    dosage_type: lineItem.dosage_type,
                                    index_no: i + 1,
                                }))
                            ]
                        };
                    }
                    return item;
                });
            });
        }
        setselPack(null)
    }
    const onAddSubService = () => {
        if (!serviceType || !serviceName || !serAmt) {
            errorMessage('Please Enter All Details');
            return;
        }
    
        let newService = {};
        if (!isDrug) {
            newService = {
                service_type: serviceType?.service_type || serviceType,
                service_name: serviceName?.service_name || serviceName,
                quantity: serviceQty || 1,
                amount: serAmt,
                service_config_id: serviceName?.id || EditID,
            };
        } else {
            newService = {
                service_type: serviceType?.drug_type || serviceType,
                service_name: serviceName?.drug_name || serviceName,
                quantity: serviceQty || 1,
                amount: serAmt,
                drug_config_id: serviceName?.id || EditID,
            };
        }
    
        setheadTbl((prev) => {
            return prev.map((item) => {
                if (item.service_head === editHead) {
                    if (EditID) {
                        return {
                            ...item,
                            service_data: item.service_data.map((service) =>
                                (service.drug_config_id || service.service_config_id) ===
                                (newService.drug_config_id || newService.service_config_id)
                                    ? { ...service, ...newService }
                                    : service
                            ),
                        };
                    } else {
                        const isDuplicate = item.service_data.some(
                            (service) =>
                                (isDrug ? service.drug_config_id : service.service_config_id) ===
                                (isDrug ? newService.drug_config_id : newService.service_config_id)
                        );
    
                        if (isDuplicate) {
                            errorMessage('Service Already Added');
                            return item;
                        }
    
                        const newServiceData = item.service_data.map((service, i) => ({
                            ...service,
                            index_no: i + 1,
                        }));
    
                        const updatedServiceData = [
                            ...newServiceData,
                            { ...newService, index_no: newServiceData.length + 1 },
                        ];
    
                        return {
                            ...item,
                            service_data: updatedServiceData,
                        };
                    }
                }
                return item;
            });
        });
        setserviceType(null);
        setserviceName(null);
        setserviceQty('');
        setserAmt('');
        setEditID(null);
        setIsDrugEdit(false)
    };
    
    

    const SubServicetbl = () => {
        let disFeild = (Index == undefined && Index == null && !editHead)
        return (
            <Box sx={{ width: '52vw', height: '63vh' }}>
                <Box sx={{ height: '8vh', paddingTop: '.5vw', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Autocomplete
                        size={'small'}
                        disabled={disFeild}
                        options={isDrug ? DrugPackage : IPPackage}
                        getOptionLabel={(option) => isDrug ? option.pack_name : option.package_name}
                        value={selPack}
                        sx={{ width: '14vw', marginRight: '1vw' }}
                        onChange={(event, newValue) => { onAddType(event, newValue) }}
                        renderInput={(params) => <TextField {...params} placeholder={isDrug ? 'Select Drug Package' : 'Select Service Package'} variant='outlined' />}
                    />
                    <Button size='small' variant='contained' disabled={!selPack} sx={{ textTransform: 'capitalize', width: '4vw', marginRight: '1vw' }} onClick={() => { addServiceTbl() }}>Add</Button>
                    <Typography sx={{ marginRight: '1vw' }}>Service</Typography>
                    <FormControlLabel disabled={disFeild} control={<Switch size='small' checked={isDrug} onChange={(e) => {
                        setisDrug(e.target.checked)
                        setserviceType(null)
                        setserviceName(null)
                        setserviceQty('')
                        setserAmt('')
                        setEditID(null)
                        setIsDrugEdit(false)
                    }} />} label="Medication" />
                </Box>
                <TableContainer component={Paper} sx={{ height: '55vh' }}>
                    <Table size='small' sx={{ width: '52vw' }} aria-label="simple table">
                        <TableHead sx={{ backgroundColor: '#FAF9F9' }}>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 'bold', width: '15vw', padding: '0.5vw' }}>Type</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', width: '15vw', padding: '0.5vw' }}>Name</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', width: '6vw', padding: '0.5vw' }}>Quantity</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', width: '6vw', padding: '0.5vw' }}>Amount</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', width: '4vw', padding: '0.5vw' }}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ width: '15vw', padding: '0.5vw' }} >{autoFeild('Type', 'serviceType', serviceType, isDrug ? list : serTypeData, '15vw', disFeild)}</TableCell>
                                <TableCell sx={{ width: '15vw', padding: '0.5vw' }}>{autoFeild('Name', 'serviceName', serviceName, isDrug ? MediserNameData.filter((item) => serviceType === item.drug_type) : serNameData, '15vw', disFeild)}</TableCell>
                                <TableCell sx={{ width: '6vw', padding: '0.5vw' }}>{autoFeild('Quantity', 'serviceQty', serviceQty, [], '5vw', (disFeild || (!isDrug && !isDrugEdit)))}</TableCell>
                                <TableCell sx={{ width: '6vw', padding: '0.5vw' }}>{autoFeild('Amount', 'serAmt', serAmt, [], '5vw', disFeild)}</TableCell>
                                <TableCell sx={{ width: '4vw', padding: '0.5vw' }}>
                                    <Tooltip placement='top' title={'Add'}>
                                        <IconButton size='small' onClick={() => { onAddSubService() }}>
                                            <img component={'img'} src={ImagePaths.AddUserButton.default} alt='plus' style={{ width: '1.2vw', height: '1.2vw' }} />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                            {headTbl.length > 0 ? headTbl.filter(item => item.service_head === editHead).map((item, i) => (
                                item.service_data.length > 0 ? item.service_data.map((data, index) => (
                                    <TableRow key={index}>
                                        <TableCell sx={{ width: '15vw', padding: '0.5vw' }}>
                                            {data.service_type?.length > 25 ?
                                                <Tooltip title={data.service_type} placement='top'>
                                                    <Typography fontSize={'1vw'}>{data.service_type.slice(0, 22) + '...'}</Typography>
                                                </Tooltip> :
                                                <Typography fontSize={'1vw'}>{data.service_type || '-'}</Typography>
                                            }
                                        </TableCell>
                                        <TableCell sx={{ width: '15vw', padding: '0.5vw' }}>
                                            {data.service_name?.length > 25 ?
                                                <Tooltip title={data.service_name} placement='top'>
                                                    <Typography fontSize={'1vw'}>{data.service_name.slice(0, 22) + '...'}</Typography>
                                                </Tooltip> :
                                                <Typography fontSize={'1vw'}>{data.service_name || '-'}</Typography>
                                            }
                                        </TableCell>
                                        <TableCell sx={{ width: '6vw', padding: '0.5vw' }}>{data.quantity || '-'}</TableCell>
                                        <TableCell sx={{ width: '6vw', padding: '0.5vw' }}>{AmountFormat(data.amount || 0)?.replace(`${CurrencySymbol}`, "")}</TableCell>
                                        <TableCell sx={{ width: '4vw', padding: '0.5vw' }}>
                                            <Stack direction="row" alignItems="center" spacing={4}>
                                                <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={1}>
                                                    <CommonEditButton size="1.5vw" onClick={() => {
                                                        setserviceType(data.service_type)
                                                        setserviceName(data.service_name)
                                                        setserviceQty(data.quantity)
                                                        setserAmt(data.amount)
                                                        setEditID(data.service_config_id || data.drug_config_id)
                                                        setisDrug(false)
                                                        setIsDrugEdit(data.drug_config_id ? true : false)
                                                    }} />
                                                    <CommonDeleteButton size="1.5vw" onClick={() => {
                                                        setheadTbl((prev) => {
                                                            return prev.map((item) => {
                                                                if (item.service_head === editHead) {
                                                                    const updatedServiceData = [...item.service_data];
                                                                    if (updatedServiceData[index]) {
                                                                        const removedService = updatedServiceData[index];
                                                                        if (EditListID && removedService.id) {
                                                                            setdeletedIDs((prevIDs) => [...prevIDs, removedService.id]);
                                                                        }
                                                                        updatedServiceData.splice(index, 1);
                                                                    } else {
                                                                        errorMessage("Invalid index provided for service_data.");
                                                                    }
                                                                    const newTotalAmount = updatedServiceData.reduce(
                                                                        (sum, service) => sum + Number(service.amount || 0),
                                                                        0
                                                                    );
                                                        
                                                                    return {
                                                                        ...item,
                                                                        service_data: updatedServiceData,
                                                                        totalAmount: +(newTotalAmount.toFixed(2)),
                                                                    };
                                                                }
                                                        
                                                                return item;
                                                            });
                                                        });
                                                    }} />
                                                </Stack>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                )) : (
                                    <TableRow key="no-data">
                                        <TableCell colSpan={5} align="center">{'No Services Available Under this Category'}</TableCell>
                                    </TableRow>
                                )
                            )) : (
                                <TableRow>
                                    <TableCell colSpan={5} align="center">{'No Records To Be Shown'}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        )

    }

    const msgClose = () => {
        seterrorMsg(false);
        seterrorMsgText('');
        setsuccessMsg(false);
        setsuccessMsgText('');
    }
    const onSaveAssPack = () => {
        try {
            setisloader(true)
            let EmptyheadTbl = headTbl.length === 0
            let EmptyServiceData = headTbl.some(item => item.service_data?.length === 0);
            if (!assData.assPackName || !assData.finalAmt) {
                setisloader(false)
                errorMessage('Please Enter the Package Details')
            } else if (EmptyheadTbl) {
                setisloader(false)
                errorMessage('Please add At least one Services Heads');
            } else if (EmptyServiceData) {
                setisloader(false)
                errorMessage('Please add Services for All Heads');
            } else {
                let data = {
                    "pack_name": assData?.assPackName,
                    "pack_code": assData?.assPackCode ? assData?.assPackCode : '',
                    "pack_amount": assData?.finalAmt,
                    "line_items": headTbl,
                    "head_id": headIDs || [],
                    "delete_ids": deletedIDs || []
                }
                if (EditListID) {
                    data['id'] = EditListID
                }
                RestAPIService.create(data, Serviceurls.ASS_GET_ASSORTED_PACKAGE)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            setisloader(false)
                            setassData(assStates)
                            history.push({ pathname: "/Settings/IPService/AssortedPackage", states : { alertmsg : response.data.message ? response.data.message : "" } })
                            setEditListID(null)
                        }
                    }).catch(e => {
                        setisloader(false)
                        if (e?.response?.data?.status === 'fail') {
                            errorMessage(e.response.data.message)
                        } else {
                            errorMessage(e.message)
                        }
                    })

            }
        } catch (e) {
            setisloader(false)
            errorMessage(e.message)
        }
    }

    return (
        <Box component={'div'} sx={{ height: '79.5vh', width: '94vw', backgroundColor: Colors.Background, padding: '.5vw' }}>
            <Box component={'div'} sx={{ height: '8vh', width: '93vw', backgroundColor: 'white', padding: '.6vw' }}>
                <Box component={'div'} sx={{ display: 'flex', flexDirection: 'row', }}>
                    {textBox("Assorted Package Code", 'assPackCode', assData.assPackCode, '15vw')}
                    {textBox("Assorted Package Name", 'assPackName', assData.assPackName, '15vw')}
                    {textBox("Final Amount", 'finalAmt', assData.finalAmt, '15vw')}
                </Box>
            </Box>
            <Box component={'div'} sx={{ height: '69vh', width: '93vw', marginTop: '.5vw', backgroundColor: 'white' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    {Servicetbl()}
                    {SubServicetbl()}
                </Box>
                <Box component={'div'} sx={{ marginTop: '.5vw', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Box sx={{ width: '9vw' }}>
                        <Button size='small' variant='contained' sx={{ textTransform: 'capitalize', width: '7vw', marginLeft: '1vw' }} disabled={isloader} onClick={() => { onSaveAssPack() }}>Save</Button>
                    </Box>
                </Box>
            </Box>
            {errorMsg ?
                <ToastMsg
                    severity={'error'}
                    msg={errorMsgText}
                    msgPop={msgClose.bind(this)}
                />
                : null}
            {successMsg ?
                <ToastMsg
                    severity={'success'}
                    msg={successMsgText}
                    msgPop={msgClose.bind(this)}
                />
                : null}
                <Loader loaderOpen={isloader} />
        </Box>
    )
}

