import { Box, Button, Stack, Tooltip, Typography, Drawer, TextField, IconButton, Menu, MenuItem } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
// import "./PharmaPurchase.css"
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { CommonGridToolBarWithFilterText, ReasonPopup } from '../../../Components/Common Components/CommonComponents';
import { formatDate, CheckAccessFunc } from "../../../Components/CommonFunctions/CommonFunctions";
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker';
import { Colors } from '../../../Styles/Colors';
import PrintPreviewPop from '../../../Components/Common Components/PrintPreviewPop';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { clearCachevalue, setCachevalue } from '../../../Utility/Services/CacheProviderService';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DateTime } from 'luxon';
import CircularProgress from '@mui/material/CircularProgress';

class PharmaInternalSalesRequestList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      pageSize: 10,
      fromDate: '',
      toDate: '',
      isLoader: false,
      PORequestData: [],
      deletePopup: false,
      purchase_order_id: null,
      pharmacyId: null,
      editAccess : false,
    }
  }
  componentDidMount() {
    this.getPharmaISRequestList()
    let editAccess = CheckAccessFunc("Pharmacy", "Internal Sales", "RequestList", null, "Tab")?.editAccess
    this.setState({editAccess: editAccess })

  }
  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  getPharmaISRequestList = () => {
    try {
      this.LoaderFunction(true);
      RestAPIService.getAll(Serviceurls.INTERNALSALES_REQUESTLIST)
        .then((response) => {
          if (response.data.status === 'success') {
            this.LoaderFunction(false);
            this.setState({
              PORequestData: response?.data?.data ? response?.data?.data : [],
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.LoaderFunction(false);
            this.errorMessage(error.response.data.message)
          } else {
            this.LoaderFunction(false);
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }
  getPharmaISRequestListPrint = () => {
    try {
      RestAPIService.getAll(Serviceurls.INTERNALSALES_REQUESTLIST + `?export=pdf`)
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }
  
  getPharmaISRequestListExcel = () => {
    const { t } = this.props;
    try {
        this.setState({ disableBtn: true })
        RestAPIService.excelGet(`${Serviceurls.INTERNALSALES_REQUESTLIST}?&export=${"excel"}`).
            then((response) => {
                if (response.data) {
                    var pom = document.createElement('a');
                    var csvContent = response.data; //here we load our csv data 

                    let filename = response.headers["content-disposition"].split("filename=")[1]

                    var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
                    var url = URL.createObjectURL(blob);
                    pom.href = url;
                    pom.setAttribute('download', filename ? filename : 'registry.xlsx');
                    pom.click();
                    this.setState({ disableBtn: false })
                }
            }).catch((error) => {
                this.errorMessage(error.response.data?.message)
                this.setState({ disableBtn: false })
            })
    } catch (error) {
        this.errorMessage(t("PrintError"))
        this.setState({ disableBtn: false })
    }
}

  rejectData = () => {
    try {
        this.LoaderFunction(true)
        RestAPIService.delete(`${Serviceurls.INTERNALSALES_SEND_REQUEST_LIST}?po_id=${this.state.purchase_order_id}&po_pharmacy_id=${this.state.pharmacyId ? this.state.pharmacyId : null}&cancel_reason=${this.state.comments}`)
            .then(response => {
                if (response.data.status === "success") {
                    this.successMessage(response?.data?.message);
                    this.LoaderFunction(false)
                    this.setState({
                        deletePopup: false,
                        comments: '',
                        purchase_order_id: null,
                        pharmacyId: null,
                    }, () => { this.getPharmaISRequestList() })
                }
            })
            .catch(error => {
                this.LoaderFunction(false)
                if (error?.response?.data?.status === "fail") {
                    this.errorMessage(error?.response?.data?.message);
                }
            });
    } catch (e) {
        this.LoaderFunction(false)
        this.errorMessage(e?.message, false);
    }
}


  closePopup() {
    this.setState({
      deletePopup: false
    })
  }
  addCancelComments = (value) => {
    this.setState({
      comments: value
    }, () => { this.rejectData() })
  }

  addDrugs = ()=>{
    this.props.history.push({ pathname: "/PharmacyInternalSales/RequestList/RequestDetail" , state : {isFromaddDrug : true}})
  }

  render() {
    const { t } = this.props
    this.state.PORequestData?.forEach((element, index) => element.sno = index + 1)
    const columns = [
      {
        field: "sno", sortable: false, flex: 0.03, headerName: t("SNo"),
        renderCell: (params) => (<Box component={'div'}>{params?.row?.sno ? params?.row?.sno : "-"}</Box>)
      },
      {
        field: "pharmacy_name", flex: 0.166, headerName: ("Pharmacy Name"),
        renderCell: (params) => (<Box component={'div'}>{params?.row?.pharmacy_name ? params?.row?.pharmacy_name : "-"}</Box>)
      },
      {
        field: "purchase_order_date", flex: 0.166, headerName: t("PI Date"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'} emed_tid="PO Date">{params?.row?.purchase_order_date ? formatDate(params?.row?.purchase_order_date) : "-"}</Box>)
      },
      {
        field: "po_number", flex: 0.166, headerName: t("PI Number"),
        renderCell: ({ row }) => (<div>{row?.po_number?.length > 25 ?
          <Tooltip placement="top" title={row?.po_number} arrow><div>{row?.po_number.slice(0, 24) + "..."}</div></Tooltip>
          : row?.po_number ? row?.po_number : "-"}</div>)
      },
      {
        field: "total_drugs", flex: 0.136, headerName: t("Total Drug"), headerAlign: "center", align: "center",
        renderCell: ({ row }) => (<div>{row?.total_drugs?.length > 18 ?
          <Tooltip placement="top" title={row?.total_drugs} arrow><div>{row?.total_drugs.slice(0, 18) + "..."}</div></Tooltip>
          : row?.total_drugs ? row?.total_drugs : "-"}</div>)
      },
      {
        field: "status", flex: 0.166, headerName: t("Status"), type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.store_status ? params?.row?.store_status : "-"}</Box>)
      },
      {
        field: "action", flex: 0.166, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false,
        renderCell: (params) => (
          <Box alignItems={"center"} display={'flex'} flexDirection={'row'}>
            <Tooltip title={("View Request")} placement="top" onClick={() => { this.props.history.push({ pathname: '/PharmacyInternalSales/RequestList/RequestDetail', state: { pharmacy_id: params?.row?.id, OnlyView: true, branch_name: params?.row?.branch_name, pharmacy_name: params?.row?.pharmacy_name ,selectedItem : params?.row} }) }} arrow>
              <Button emed_tid="vnd_view" className='eMed_usrconf_btn' disabled ={!this.state.editAccess}>
                <img src={this.state.editAccess ? ImagePaths.LabViewButton.default : ImagePaths.ViewButtonOff.default } alt="print" className='eMed_action_img' />
              </Button>
            </Tooltip>
            <Tooltip title={("Reject Request")} placement="top" arrow>
              <Button emed_tid="vnd_view" className='eMed_usrconf_btn' disabled={(params?.row?.store_status ==="Cancelled" || params?.row?.store_status ==="Approved" || params?.row?.store_status ==='Completed') || !this.state.editAccess} onClick={() => { 
              this.setState({ deletePopup: true, purchase_order_id: params?.row?.id, pharmacyId: params?.row?.pharmacy_id })
            }}>
                <img src={(params?.row?.store_status ==="Cancelled" || params?.row?.store_status ==="Approved" || params?.row?.store_status ==='Completed') || (!this.state.editAccess) ? ImagePaths.CancelAppDisabled.default : ImagePaths.CancelRed.default} alt="Reject" className='eMed_action_img' />
              </Button>
            </Tooltip>

          </Box>
        )
      }
    ]
    return (
      <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>
        <div className='eMed_Purchase_Table_top'>
           <Tooltip placement="top" title={t("Send Drug")} arrow>
            <Button emed_tid="vnd_export" className='eMed_usrconf_btn' size="small" variant='contained' disabled={this.state.disableBtn  || !this.state.editAccess} onClick={() => this.addDrugs()}>{"Send Drug"}
            </Button></Tooltip>
          <Tooltip title="Print" placement='top' arrow>
            <Button emed_tid="vnd_print" className='eMed_usrconf_btn' disabled={this.state.disableBtn || !this.state.editAccess} onClick={() => this.getPharmaISRequestListPrint()}>
              <img src={this.state.editAccess ?ImagePaths.LabPrintIcon.default : ImagePaths.LabPrintIconOff.default } alt="print" className='eMed_action_img' />
            </Button></Tooltip>
          <Tooltip placement="top" title={t("Export")} arrow>
            <Button emed_tid="vnd_export" className='eMed_usrconf_btn' size="small" disabled={this.state.disableBtn || !this.state.editAccess} onClick={() => this.getPharmaISRequestListExcel()}>
              <img src={this.state.editAccess ? ImagePaths.LabUploadButton.default :ImagePaths.DisableUpload.default } alt='upload' className='eMed_action_img' />
            </Button></Tooltip>
        </div>

        <Box component={'div'} className='eMed_Pharma_rts_tbl_card'>
          <DataGrid
            className='eMed_Pharma_table'
            rows={this.state.PORequestData}
            columns={columns}
            getRowId={(row) => row['sno']}
            page={this.state.page}
            pageSize={this.state.pageSize}
            // hideFooterSelectedRowCount
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            components={{
              Toolbar: this.gridToolBar,
              NoRowsOverlay: () => (
                <Stack className='eMed_conf_nodata'>
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
            rowsPerPageOptions={[10, 20, 30]}
            headerHeight={40}
            // disableSelectionOnClick
            pagination
            onPageChange={(newPage) => { this.setState({ page: newPage }) }}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
            loading={this.state.isLoader}
          />
        </Box>
        {this.state.deletePopup ? <ReasonPopup
          title={"Are you sure you want to cancel the Bill"}
          AlertPopupClose={this.closePopup.bind(this)}
          label={"Enter the Cancel Reason"}
          btntext={'Cancel'}
          btnvarient={'outlined'}
          btncolor={'error'}
          btntext1={'Confirm'}
          btnvarient1={'contained'}
          sendCmt={this.addCancelComments.bind(this)} /> : null}
      </Box>

    )
  }
}

export default withTranslation()(PharmaInternalSalesRequestList)