import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import {
    Accordion, AccordionSummary, IconButton, Typography, Autocomplete,
    TextField, Box, InputAdornment, AccordionDetails, Table, TableRow,
    TableCell, TableHead, TableBody, Tooltip, Button, Modal, Paper, Select, MenuItem
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Serviceurls } from "../../../../Utility/API/Serviceurls";
import RestAPIService from "../../../../Utility/Services/RestAPIService";
import { CurrencySymbol } from "../../../../Utility/Constants";
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';
import { ImagePaths } from "../../../../Utility/ImagePaths";
import { DateTime } from 'luxon';
import { AmountFormat } from '../../../../Components/CommonFunctions/CommonFunctions';
import { Colors } from '../../../../Styles/Colors';
import PercentIcon from '@mui/icons-material/Percent';
import { DeletePopup } from "../../../../Components/Common Components/CommonComponents";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { localGetItem } from "../../../../Utility/Services/CacheProviderService";


const lineItem = {
    display_name: "",
    amount_type: "rupee",
    discount_amount: "",
    discount_percentage: "",
    is_doctor_wise: false,
    service_amount: 0,
    service_charges: {},
    service_discount_edit: false,
    service_id: null,
    service_name: "",
    service_rate_edit: false,
    service_rate_per_unit: 0,
    service_total_amount: 0,
    service_type: "",
    service_unit: 0,
    service_unit_edit: false,
    total_price: 0,
}

const Unit_Types = [
    { label: `${CurrencySymbol}`, value: 'rupee' },
    { label: '%', value: 'percentage' }
]

class MultiRadBilling extends Component {
    constructor(props) {
        super(props);
        this.testNameRef = React.createRef();
        this.state = {
            openAccordion: false,
            editRate: false,
            editUnit: false,
            radDetails: {},
            overAllDiscountAmt: '',
            overAllDiscountPercent: '',
            totalBillAmt: 0,
            docSplitPopup: false,
            radList: [],
            serviceData: [],
            radBillingList: [lineItem],
            errorMessages: [""],
            discountReason: "",
            overAllDiscountPercent_w_r: '',
            docPercent_w_r: '',
            hospitalPercent_w_r: '',
            concessionRestrictAmt: 0
        }
    }

    componentDidMount = () => {
        this.getRadiologyList();
        this.getServiceListData();
        let concession = localGetItem("billing_concession_type")
        let showCode = JSON.parse(localGetItem('radiology_service_code')) ? JSON.parse(localGetItem('radiology_service_code')) : false
        this.setState({
            billingConcessionType: concession,
            showCode: showCode
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState !== this.state) {
            this.props.onStateChange(this.state);
        }
        if (prevProps.triggerSignal !== this.props.triggerSignal) {
            this.setState({
                openAccordion: false
            }, () => {
                this.onClearHandler();
            })
        }

        if (prevProps.RadiologyTranmittedItems !== this.props.RadiologyTranmittedItems && this.props?.RadiologyTranmittedItems?.length) {
            let BillItems = []
            this.props.RadiologyTranmittedItems?.forEach((value) => {
                if (value?.service_id) {
                    const ServiceFee = value?.is_emergency ? value?.service_data?.emergency_charges : value?.is_holiyday ? value?.service_data?.holiday_charges : value?.service_data?.normal_charges;
                    let ServiceObject = {
                        display_name: `${value?.service_type ? value?.service_type : '-'} - ${value?.service_name ? value?.service_name : '-'} - ${value?.rad_service_code ? value?.rad_service_code : '-'}`,
                        service_id: value?.service_id ? value?.service_id : null,
                        service_name: value?.service_name ? value?.service_name : '',
                        service_type: value?.service_type ? value?.service_type : '',
                        service_amount: ServiceFee?.total ? ServiceFee?.total : 0,
                        total_price: ServiceFee?.total ? ServiceFee?.total : 0,
                        service_total_amount: ServiceFee?.total ? ServiceFee?.total : 0,
                        service_rate_per_unit: ServiceFee?.total ? ServiceFee?.total : 0,
                        service_unit: 1,
                        service_unit_edit: value?.is_edit_unit ? value?.is_edit_unit : false,
                        service_rate_edit: value?.is_edit_amount ? value?.is_edit_amount : false,
                        service_discount_edit: value?.is_edit_discount ? value?.is_edit_discount : false,
                        is_doctor_wise: value?.config_type === "Doctor Wise",
                        amount_type: 'rupee',
                        discount_amount: "",
                        discount_percentage: "",
                        service_charges: {},
                        transmit_summary_id: value?.transmit_summary_id ? value?.transmit_summary_id : null
                    }
                    if (value?.config_type === "Doctor Wise") {
                        ServiceObject.service_charges = {
                            total_amount: ServiceFee?.total ? ServiceFee?.total : 0,
                            original_doctor_fee: ServiceFee?.doctor_fee ? ServiceFee?.doctor_fee : 0,
                            original_hospital_fee: ServiceFee?.hospital_fee ? ServiceFee?.hospital_fee : 0,
                            doctor_fee: ServiceFee?.doctor_fee ? ServiceFee?.doctor_fee : 0,
                            hospital_fee: ServiceFee?.hospital_fee ? ServiceFee?.hospital_fee : 0,
                            doctor_percentage: CommonValidation.amountPercentage(ServiceFee?.doctor_fee ? ServiceFee?.doctor_fee : 0, ServiceFee?.total ? ServiceFee?.total : 0),
                            hospital_percentage: CommonValidation.amountPercentage(ServiceFee?.hospital_fee ? ServiceFee?.hospital_fee : 0, ServiceFee?.total ? ServiceFee?.total : 0),
                            discount_doctor_fee: 0,
                            discount_hospital_fee: 0,
                            discount_doctor_percentage: 0,
                            discount_hospital_percentage: 0,
                        }
                    }
                    BillItems.push(ServiceObject)
                }
            })
            BillItems.push(lineItem)
            setTimeout(() => {
                this.setState({
                    openAccordion: true,
                    radBillingList: BillItems,
                    radDetails: this.state.radList?.find((radiology) => radiology?.radiology_id === this.props?.TransmittedRadiologyId)
                })
            }, 2000)
        }
    }

    getRadiologyList = () => {
        const { errorMessage } = this.props;
        try {
            RestAPIService.getAll(Serviceurls.GET_RADIOLOGY_LIST)
                .then(response => {
                    if (response.data.status === "success") {
                        const rad_list = response?.data?.data || [];
                        this.setState({
                            radList: rad_list,
                            radDetails: rad_list?.length > 0 ? rad_list[0] : null
                        })
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.message) {
                        errorMessage(error.response.data.message)
                    } else {
                        errorMessage(error.message)
                    }
                });
        }
        catch (e) {
            errorMessage(e.message)
        }
    }

    getServiceListData = () => {
        const { errorMessage } = this.props;
        try {
            let startDate = new Date()
            let Aptime = DateTime.fromJSDate(startDate).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let invoice_date = `${DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd")}`
            RestAPIService.getAll(Serviceurls.RAD_SERVICE_CONFIG + '?billing_time=' + Aptime + '&billing_date=' + invoice_date)
                .then((response) => {
                    if (response.data.status === 'success') {
                        const list = response.data.data || [];
                        const dataList = list?.reduce((acc, cur) => {
                            const servicesWithDisplayName = cur?.data?.map(service => ({
                                ...service,
                                display_name: `${service?.service_type ? service?.service_type : '-'} - ${service?.service_name ? service?.service_name : '-'} - ${service?.rad_service_code ? service?.rad_service_code : '-'}`
                            }));
                            return [...acc, ...servicesWithDisplayName];
                        }, []);
                        this.setState({
                            serviceData: dataList,
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        errorMessage(error.response.data.message)
                    } else {
                        errorMessage(error.message)
                    }
                })
        } catch (error) {
            errorMessage(error.message)
        }
    }

    renderAutoComplete = (label, statekey, list, showValue) => {
        const { t } = this.props;
        return (
            <Autocomplete
                disableClearable
                openOnFocus
                value={this.state[statekey] ? this.state[statekey] : null}
                sx={{ width: "16dvw" }}
                size="small"
                options={list?.length > 0 ? list : []}
                getOptionLabel={option => typeof (option) === "string" ? option : option[showValue]}
                onChange={(event, newValue) => {
                    this.setState({
                        [statekey]: newValue,
                    })
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t(label)}
                        autoComplete="off"
                        size="small"
                        InputProps={{
                            ...params.InputProps,
                            emed_tid: `multiradBilling_${label}`
                        }}
                    />
                )}
            />
        )
    }

    onClearHandler = () => {
        this.setState({
            editRate: false,
            editUnit: false,
            overAllDiscountAmt: '',
            overAllDiscountPercent: '',
            totalBillAmt: 0,
            docSplitPopup: false,
            radBillingList: [lineItem],
            errorMessages: [""],
            discountReason: "",
            overAllDiscountPercent_w_r: '',
            docPercent_w_r: '',
            hospitalPercent_w_r: '',
            concessionRestrictAmt: 0
        })
    }

    onChangeHandler = (e, names, index) => {
        try {
            let value = e.target.value
            let { radBillingList } = this.state
            switch (names) {
                case 'ratePerUnit':
                    if (CommonValidation.NumberWithDot(value) || value === '') {
                        radBillingList[index]['service_rate_per_unit'] = value
                        radBillingList[index]['service_total_amount'] = value * radBillingList[index]['service_unit']
                        this.setState({ radBillingList }, () => { this.CalculateBillValuse(index) })
                    }
                    break;
                case 'discountAmount':
                    if ((CommonValidation.NumberWithDot(value) && +value <= radBillingList[index]['service_total_amount']) || (value === "") || (value === 0)) {
                        radBillingList[index]['discount_percentage'] = CommonValidation.amountPercentageWithoutRoundoff(value, radBillingList[index]['service_total_amount'])
                        radBillingList[index]['discount_percentage_w_r'] = CommonValidation.amountPercentage(value, radBillingList[index]['service_total_amount'])
                        radBillingList[index]['discount_amount'] = value
                        // radBillingList[index]['total_price'] = radBillingList[index]['service_total_amount'] - value
                        this.setState({ radBillingList }, () => {
                            this.CalculateBillValuse(index)
                        })
                    }
                    break;
                case 'discountPercent':
                    if ((CommonValidation.DecimalNumber(value) && +value <= 100) || (value === "") || (value === 0)) {
                        radBillingList[index]['discount_amount'] = CommonValidation.calculatePercentage(value, radBillingList[index]['service_total_amount'])
                        radBillingList[index]['discount_percentage'] = value
                        radBillingList[index]['discount_percentage_w_r'] = value
                        radBillingList[index]['total_price'] = radBillingList[index]['service_total_amount'] - CommonValidation.calculatePercentage(value, radBillingList[index]['service_total_amount'])
                        this.setState({ radBillingList }, () => {
                            // this.calculateDiscountAmt()
                            this.setState({
                                overAllDiscountAmt: '',
                                overAllDiscountPercent: '',
                                overAllDiscountPercent_w_r: ''
                            })
                        })
                    }
                    break;
                case 'overAllDiscountAmt':
                    if ((CommonValidation.NumberWithDot(value) && value >= 1 && value <= this.state.concessionRestrictAmt) || value === "") {
                        this.state.overAllDiscountPercent = CommonValidation.amountPercentageWithoutRoundoff(value, this.state.subAmount)
                        this.state.overAllDiscountPercent_w_r = CommonValidation.amountPercentage(value, this.state.subAmount)
                        this.setState({
                            overAllDiscountAmt: value,
                            discountReason: value ? this.state.discountReason : ""
                        }, () => {
                            if (+this.state.overAllDiscountPercent === 100) {
                                this.setState({
                                    isCard: false, isCash: false,
                                    isUPI: false, isBankTranfer: false,
                                    isCheque: false, CashAmount: "",
                                    CardAmount: "", CardTransactionNumber: "",
                                    UPINumber: "", UPIAmount: "",
                                    BankTfrAmount: "", BankTfrNumber: "",
                                    ChequeNumber: "", ChequeAmount: "",
                                })
                            }
                        })
                    }
                    break;
                case 'overAllDiscountPercent':
                    let percentRestrict = +this.state.billingConcessionType === 1 ? 100 : CommonValidation.amountPercentage(this.state.concessionRestrictAmt, this.state.subAmount)
                    if ((CommonValidation.DecimalNumber(value) && value >= 1 && +value <= percentRestrict) || value === "") {
                        this.state.overAllDiscountAmt = CommonValidation.calculatePercentage(value, this.state.subAmount)
                        this.setState({
                            overAllDiscountPercent: value,
                            overAllDiscountPercent_w_r: value,
                            discountReason: value ? this.state.discountReason : "",
                        }, () => {
                            if (+value === 100) {
                                this.setState({
                                    isCard: false, isCash: false,
                                    isUPI: false, isBankTranfer: false,
                                    isCheque: false, CashAmount: "",
                                    CardAmount: "", CardTransactionNumber: "",
                                    UPINumber: "", UPIAmount: "",
                                    BankTfrAmount: "", BankTfrNumber: "",
                                    ChequeNumber: "", ChequeAmount: "",
                                })
                            }
                        })
                    }
                    break;
                case 'searchServiceName':
                    this.setState({
                        searchServiceName: value
                    }, () => {
                        let duplicateServiceName = JSON.parse(this.state.dupServiceNameList)
                        if (this.state.searchServiceName !== '') {
                            duplicateServiceName = duplicateServiceName.filter((item) => {
                                return (
                                    (item?.service_name === '' ? '' : item?.service_name?.toLowerCase()?.includes(this.state.searchServiceName?.toLowerCase())))
                            })
                            this.setState({ serviceTypeList: duplicateServiceName, selectedServiceName: '' })
                        } else {
                            this.setState({ serviceTypeList: duplicateServiceName, selectedServiceName: '' })
                        }
                    })
                    break;
                case 'searchServiceType':
                    this.setState({
                        searchServiceType: value
                    }, () => {
                        let dupServiceData = JSON.parse(this.state.dupServiceData)
                        if (this.state.searchServiceType !== '') {
                            dupServiceData = dupServiceData.filter((item) => {
                                return (
                                    (item?.service_type === '' ? '' : item?.service_type?.toLowerCase()?.includes(this.state.searchServiceType?.toLowerCase())))
                            })
                            this.setState({ serviceData: dupServiceData, selectedServiceType: '', selectedServiceName: '', serviceTypeList: [] })
                        } else {
                            this.setState({ serviceData: dupServiceData, selectedServiceType: '', selectedServiceName: '', serviceTypeList: [] })
                        }
                    })
                    break;
                default:
                    this.setState({
                        [names]: value
                    })
                    break;
            }
        } catch (error) {
            this.props.errorMessage(error.message)
        }
    }

    textBox = (names, value, width, height = "", placeholder, index) => {
        try {
            return (
                <TextField
                    autoComplete='off'
                    InputProps={{
                        style: height ? { height: height } : {},
                        endAdornment: <InputAdornment position="end">{placeholder}</InputAdornment>
                    }}
                    size='small'
                    sx={{ width: width }}
                    name={names}
                    value={value}
                    onChange={(e) => this.onChangeHandler(e, names, index)}
                    inputProps={{ emed_tid: names }}
                    disabled={((names === "overAllDiscountAmt" || names === "overAllDiscountPercent") && this.state.subAmount === 0)}
                    onBlur={(e) => { this.concessionManditoryPopup(names) }}
                />
            )
        } catch (error) {
            this.props.errorMessage(error.message)
        }
    }

    resetBillSummary = () => {
        this.setState({
            billSummaryList: [lineItem],
            errorMessages: [""],
            totalBillAmount: 0,
            netAmount: 0,
            roundOff: 0,
            discountAmount: 0,
            discountPercentage: 0,
            netAmount: 0,
        })
    }

    addShare = (item, index) => {
        this.setState({
            docAmt: (item?.service_charges?.original_doctor_fee ? item.service_charges?.original_doctor_fee : item.service_charges?.doctor_fee ? item.service_charges?.doctor_fee : ''),
            docPercent: item?.service_charges?.doctor_percentage ? item?.service_charges?.doctor_percentage : '',
            docPercent_w_r: item?.service_charges?.doctor_percentage ? CommonValidation.formatAmount(item?.service_charges?.doctor_percentage) : '',
            hospitalAmt: item?.service_charges?.original_hospital_fee ? item.service_charges?.original_hospital_fee : item.service_charges?.hospital_fee ? item.service_charges?.hospital_fee : '',
            hospitalPercent: item?.service_charges?.hospital_percentage ? item?.service_charges?.hospital_percentage : '',
            hospitalPercent_w_r: item?.service_charges?.hospital_percentage ? CommonValidation.formatAmount(item?.service_charges?.hospital_percentage) : '',
            // totalBillAmt: item?.amount ? item?.amount : '',
            totalBillPercent: '100',
            rowIndex: index,
            selectedData: item,
            dupliSelectedData: JSON.stringify(item),
        }, () => {
            this.setState({
                docSplitPopup: true,
                totalBillAmt: (+this.state.docAmt) + (+this.state.hospitalAmt)
            })
        })
    }

    amtCalculate = (e, name) => {
        let states = JSON.parse(JSON.stringify(this.state))
        let value = e.target.value
        let serviceFee = JSON.parse(JSON.stringify(states.selectedData))
        switch (name) {
            case 'doctorAmt':
                if (CommonValidation.RangeDecimalNumber(value) || value == '') {
                    states.selectedData.service_amount = (+value) + (+states.hospitalAmt)
                    states.selectedData.service_rate_per_unit = serviceFee.service_amount
                    states.selectedData.totalAmount = states.selectedData?.service_rate_per_unit * (+states.selectedData?.service_unit)
                    this.setState({
                        docAmt: +value,
                        selectedData: states.selectedData
                    }, () => {
                        let totalAmt = (+value) + (+states.hospitalAmt)
                        this.setState({
                            totalBillAmt: (+value) + (+states.hospitalAmt)
                        }, () => {
                            this.setState({
                                docPercent: totalAmt ? +((+value / totalAmt) * 100) : 0,
                                docPercent_w_r: totalAmt ? +((+value / totalAmt) * 100).toFixed(2) : 0,
                                hospitalPercent: totalAmt ? +((+states.hospitalAmt / totalAmt) * 100) : 0,
                                hospitalPercent_w_r: totalAmt ? +((+states.hospitalAmt / totalAmt) * 100).toFixed(2) : 0,
                            })
                        })
                    })
                }
                break;
            case 'hospitalAmt':
                if (CommonValidation.RangeDecimalNumber(value) || value == '') {
                    serviceFee.service_amount = (+states.docAmt) + (+value)
                    states.selectedData.service_rate_per_unit = serviceFee.total_amt
                    states.selectedData.service_total_amount = states.selectedData?.service_rate_per_unit * (+states.selectedData?.service_unit)
                    this.setState({
                        hospitalAmt: +value,
                        selectedData: states.selectedData
                    }, () => {
                        let totalAmt = (+states.docAmt) + (+value)
                        this.setState({
                            totalBillAmt: (+states.docAmt) + (+value)
                        }, () => {
                            this.setState({
                                docPercent: totalAmt ? +((+states.docAmt / totalAmt) * 100) : 0,
                                docPercent_w_r: totalAmt ? +((+states.docAmt / totalAmt) * 100).toFixed(2) : 0,
                                hospitalPercent: totalAmt ? +((+value / totalAmt) * 100) : 0,
                                hospitalPercent_w_r: totalAmt ? +((+value / totalAmt) * 100).toFixed(2) : 0,
                            })
                        })
                    })
                }
                break;
            default:
                break;
        }
    }

    newShareSplitUp = () => {
        try {
            this.setState((prevState) => {
                const list = [...prevState.radBillingList];
                list[prevState.rowIndex].service_rate_per_unit = prevState.totalBillAmt ? +(+prevState.totalBillAmt / list[prevState.rowIndex].service_unit).toFixed(2) : 0
                list[prevState.rowIndex].service_charges.doctor_percentage = prevState.docPercent ? +prevState.docPercent : 0
                list[prevState.rowIndex].service_charges.doctor_percentage_w_r = this.state.docPercent_w_r ? +this.state.docPercent_w_r : 0
                list[prevState.rowIndex].service_charges.hospital_percentage = prevState.hospitalPercent ? +prevState.hospitalPercent : 0
                list[prevState.rowIndex].service_charges.hospital_percentage_w_r = this.state.hospitalPercent_w_r ? +this.state.hospitalPercent_w_r : 0
                list[prevState.rowIndex].service_charges.original_doctor_fee = prevState.docAmt ? +prevState.docAmt : 0
                list[prevState.rowIndex].service_charges.original_hospital_fee = prevState.hospitalAmt ? +prevState.hospitalAmt : 0
                list[prevState.rowIndex].service_charges.discount_hospital_percentage = prevState.hospitalAmt ? list[prevState.rowIndex]?.service_charges.discount_hospital_percentage : 0
                list[prevState.rowIndex].service_charges.discount_hospital_percentage_w_r = this.state.hospitalAmt ? CommonValidation.formatAmount(list[prevState.rowIndex]?.service_charges.discount_hospital_percentage) : 0
                list[prevState.rowIndex].service_charges.discount_doctor_percentage = prevState.docAmt ? list[prevState.rowIndex]?.service_charges.discount_doctor_percentage : 0
                list[prevState.rowIndex].service_charges.discount_doctor_percentage_w_r = this.state.docAmt ? CommonValidation.formatAmount(list[prevState.rowIndex]?.service_charges.discount_doctor_percentage) : 0
                return {
                    radBillingList: list
                }
            }, () => {
                this.setState({ docSplitPopup: false }, () => {
                    this.CalculateServiceValues(this.state.rowIndex)
                })
            })
        } catch (error) {
            this.props.errorMessage(error.message)
        }
    }

    splitScreenPopup = () => {
        return (
            <Modal open={this.state.docSplitPopup}>
                <Paper elevation={3} id="emedhub_popup_mainBox" sx={{ width: "31dvw", height: "60vh" }}>
                    <div className="eMed_DialogBox_Title" style={{ padding: '0.5dvw' }}>
                        <Typography sx={{ fontSize: '1vw', fontWeight: "bold" }}>
                            Split-Up
                        </Typography>
                        <div className="eMed_DialogBox_Close">
                            <img className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt='Close' onClick={this.closeSplitPopup} />
                        </div>
                    </div>
                    <hr />
                    <Box>
                        <Box component={'div'} display={'flex'} justifyContent={'space-evenly'} pl={'0.5dvw'}>
                            <Box display={'flex'} justifyContent={'space-between'} pl={'1vw'}>
                                <Typography color={Colors.SecondaryText} fontWeight={600}> Rate / Unit</Typography>
                                <Typography fontWeight={'600'} pl={'1.5vw'}>{AmountFormat(this.state.totalBillAmt / (this.state.selectedData?.service_unit ? this.state.selectedData?.service_unit : 1))}</Typography>
                            </Box>
                            <Box display={'flex'} justifyContent={'space-between'} pl={'1vw'}>
                                <Typography color={Colors.SecondaryText} fontWeight={600}>Unit</Typography>
                                <Typography fontWeight={'600'} pl={'1.5vw'}>{this.state.selectedData?.service_unit ? this.state.selectedData?.service_unit : "-"}</Typography>
                            </Box>
                        </Box>
                        <div className="eMed_DialogBox_Title" style={{ padding: '0.5vw' }}>
                            <Typography>Doctor Share</Typography>
                            <div style={{ display: 'flex', gap: '5%' }}>
                                <TextField sx={{ width: '10vw' }} label='Amount' size='small' value={this.state.docAmt} onChange={(e) => this.amtCalculate(e, 'doctorAmt')}></TextField>
                                <TextField disabled={true} sx={{ width: '10vw' }} size='small' label='Percentage (%)' value={this.state.docPercent_w_r}></TextField>
                            </div>
                        </div>
                        <div className="eMed_DialogBox_Title" style={{ padding: '0.5vw' }}>
                            <Typography>Hospital Share</Typography>
                            <div style={{ display: 'flex', gap: '5%' }}>
                                <TextField sx={{ width: '10vw' }} label='Amount' size='small' value={this.state.hospitalAmt} onChange={(e) => { this.amtCalculate(e, 'hospitalAmt') }}></TextField>
                                <TextField disabled={true} sx={{ width: '10vw' }} size='small' label='Percentage (%)' value={this.state.hospitalPercent_w_r}></TextField>
                            </div>
                        </div>
                        <div className="eMed_DialogBox_Title" style={{ padding: '0.5vw' }}>
                            <Typography>Total</Typography>
                            <div style={{ display: 'flex', gap: '5%' }}>
                                <TextField disabled={true} sx={{ width: '10vw' }} size='small' label='Amount' value={this.state.totalBillAmt}></TextField>
                                <TextField disabled={true} sx={{ width: '10vw' }} size='small' label='Percentage (%)' value={"100"}></TextField>
                            </div>
                        </div>
                    </Box>
                    <Box m={'1vw'} display={'flex'} justifyContent={'flex-end'}>
                        <Button
                            variant='outlined'
                            sx={{ width: '5vw', height: '2vw', mr: '1vw' }}
                            onClick={this.closeSplitPopup}
                        >Close</Button>
                        <Button
                            variant='contained'
                            sx={{ width: '5vw', height: '2vw' }}
                            onClick={() => { this.newShareSplitUp() }}
                        >Save</Button>
                    </Box>
                </Paper>
            </Modal>
        )
    }

    closeSplitPopup = () => {
        this.setState({ docSplitPopup: false, selectedData: JSON.parse(this.state.dupliSelectedData) })
    }

    validCheck = (data) => {
        const { radBillingList } = this.state;
        let duplicate = false;
        if (radBillingList?.length > 0) {
            radBillingList?.forEach((element) => {
                if (((element?.service_type === data?.service_type) && (element?.service_name === data?.service_name))) {
                    duplicate = true
                }
            })
        }
        return duplicate;
    }

    handleChangeSrv = (index, value) => {
        console.log(index, value, "from onchange")
        const { t } = this.props;
        if (value) {
            this.setState((prevState) => {
                let list = [...prevState.radBillingList];
                const errors = [...prevState.errorMessages];
                const ServiceFee = value?.is_emergency ? value?.service_data?.emergency_charges :
                    value?.is_holiyday ? value?.service_data?.holiday_charges :
                        value?.service_data?.normal_charges;

                if (this.validCheck(value)) {
                    errors[index] = t('Service Type and Service Name already exists');
                    return {
                        ...prevState,
                        errorMessages: errors
                    };
                }
                errors[index] = "";
                list[index] = {
                    display_name: value?.display_name,
                    service_id: value?.id ? value?.id : null,
                    service_name: value?.service_name ? value?.service_name : '',
                    service_type: value?.service_type ? value?.service_type : '',
                    service_amount: ServiceFee?.total ? ServiceFee?.total : 0,
                    total_price: ServiceFee?.total ? ServiceFee?.total : 0,
                    service_total_amount: ServiceFee?.total ? ServiceFee?.total : 0,
                    service_rate_per_unit: ServiceFee?.total ? ServiceFee?.total : 0,
                    service_unit: 1,
                    service_unit_edit: value?.is_edit_unit ? value?.is_edit_unit : false,
                    service_rate_edit: value?.is_edit_amount ? value?.is_edit_amount : false,
                    service_discount_edit: value?.is_edit_discount ? value?.is_edit_discount : false,
                    is_doctor_wise: value?.config_type === "Doctor Wise",
                    amount_type: 'rupee',
                    discount_amount: "",
                    discount_percentage: "",
                    service_charges: {},
                }
                if (value?.config_type === "Doctor Wise") {
                    list[index]['service_charges'] = {
                        total_amount: ServiceFee?.total ? ServiceFee?.total : 0,
                        original_doctor_fee: ServiceFee?.doctor_fee ? ServiceFee?.doctor_fee : 0,
                        original_hospital_fee: ServiceFee?.hospital_fee ? ServiceFee?.hospital_fee : 0,
                        doctor_fee: ServiceFee?.doctor_fee ? ServiceFee?.doctor_fee : 0,
                        hospital_fee: ServiceFee?.hospital_fee ? ServiceFee?.hospital_fee : 0,
                        doctor_percentage: CommonValidation.amountPercentage(ServiceFee?.doctor_fee ? ServiceFee?.doctor_fee : 0, ServiceFee?.total ? ServiceFee?.total : 0),
                        hospital_percentage: CommonValidation.amountPercentage(ServiceFee?.hospital_fee ? ServiceFee?.hospital_fee : 0, ServiceFee?.total ? ServiceFee?.total : 0),
                        discount_doctor_fee: 0,
                        discount_hospital_fee: 0,
                        discount_doctor_percentage: 0,
                        discount_hospital_percentage: 0,
                    }
                }
                if (index === list?.length - 1) {
                    list = [...list, lineItem]
                }
                console.log(list, "from the onchange")
                return {
                    ...prevState,
                    radBillingList: list,
                    errorMessages: errors
                }
            })
        }
    }

    renderSrvSearch = (index, item) => {
        const { t } = this.props;
        const { errorMessages } = this.state;
        return (
            <Autocomplete
                value={item.display_name || null}
                size="small"
                disableClearable
                onChange={(event, newValue) => { this.handleChangeSrv(index, newValue) }}
                options={this.state.serviceData || []}
                getOptionLabel={option => typeof (option) === "string" ? option : option?.display_name}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        label={(index === this.state.radBillingList?.length - 1) ? t("Search Service") : ""}
                        variant="standard"
                        autoComplete="off"
                        size="small"
                        InputProps={{
                            ...params.InputProps,
                            emed_tid: `multiradBilling_srvSrch`
                        }}
                        error={!!errorMessages[index]}
                        helperText={errorMessages[index] || ""}
                    />}
                sx={{ width: "100%" }}
                renderOption={(props, item) => (
                    <Box {...props}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignContent: "flex-start" }}>
                            <Typography sx={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.service_name}</Typography>
                            <Typography sx={{ fontSize: "0.8vw" }}>{`${item?.service_type} ${item?.rad_service_code ? ` - ( ${item?.rad_service_code} )` : ""}`}</Typography>
                        </Box>
                    </Box>
                )}
            />
        )
    }

    CalculateServiceValues = (index) => {
        try {
            this.setState((prevState) => {
                const list = [...prevState.radBillingList];
                list[index].service_total_amount = Math.round(list[index]?.service_unit * list[index]?.service_rate_per_unit)
                list[index].service_amount = Math.round(list[index]?.service_unit * list[index]?.service_rate_per_unit)
                list[index].service_charges.original_doctor_fee = CommonValidation.calculatePercentage(list[index]?.service_charges?.doctor_percentage, list[index]?.service_amount)
                list[index].service_charges.original_hospital_fee = CommonValidation.calculatePercentage(list[index]?.service_charges?.hospital_percentage, list[index]?.service_amount)
                list[index].service_charges.discount_hospital_fee = list[index]?.service_charges?.discount_hospital_percentage ? CommonValidation.calculatePercentage(list[index]?.service_charges?.discount_hospital_percentage, list[index]?.service_charges?.original_hospital_fee) : 0;
                list[index].service_charges.discount_doctor_fee = list[index]?.service_charges?.discount_doctor_percentage ? CommonValidation.calculatePercentage(list[index]?.service_charges?.discount_doctor_percentage, list[index]?.service_charges?.original_doctor_fee) : 0;
                list[index].discount_amount = list[index]?.service_charges?.discount_doctor_fee + list[index]?.service_charges?.discount_hospital_fee
                list[index].discount_percentage = CommonValidation.amountPercentageWithoutRoundoff(list[index]?.discount_amount, list[index]?.service_amount)
                list[index].discount_percentage_w_r = CommonValidation.amountPercentage(list[index]?.discount_amount, list[index]?.service_amount)
                list[index].service_charges.doctor_fee = CommonValidation.formatAmount(list[index].service_charges.original_doctor_fee - (list[index].service_charges.discount_doctor_fee ? list[index].service_charges.discount_doctor_fee : 0))
                list[index].service_charges.hospital_fee = CommonValidation.formatAmount(list[index].service_charges.original_hospital_fee - (list[index].service_charges.discount_hospital_fee ? list[index].service_charges.discount_hospital_fee : 0))
                list[index].discount_amount = list[index]?.service_charges?.discount_doctor_fee + list[index]?.service_charges?.discount_hospital_fee
                list[index].discount_percentage = CommonValidation.amountPercentageWithoutRoundoff(list[index]?.discount_amount, list[index]?.service_amount)
                list[index].discount_percentage_w_r = CommonValidation.amountPercentage(list[index]?.discount_amount, list[index]?.service_amount)
                return {
                    radBillingList: list,
                    overAllDiscountAmt: '',
                    overAllDiscountPercent: '',
                }
            })
        } catch (error) {
            this.props.errorMessage(error.message, 'error')
        }
    }



    CalculateBillValuse = (index) => {
        this.setState((prevState) => {
            const list = [...prevState.radBillingList];
            // list[index]['service_total_amount'] = +list[index]['service_rate_per_unit'] * list[index]['service_unit']
            list[index]['discount_amount'] = CommonValidation.calculatePercentage(list[index]["service_total_amount"], list[index]['discount_percentage'])
            list[index]['discount_percentage'] = CommonValidation.amountPercentageWithoutRoundoff(list[index]['discount_amount'], list[index]['service_total_amount'])
            list[index]['discount_percentage_w_r'] = CommonValidation.amountPercentage(list[index]['discount_amount'], list[index]['service_total_amount'])
            return {
                radBillingList: list,
            }
        })
    }

    onChangeUnitHandler = (item, key, index) => {
        try {
            switch (key) {
                case 'decrease':
                    if (item?.service_unit > 1) {
                        item.service_unit--
                        item.service_total_amount = item.service_unit * item.service_rate_per_unit
                        this.setState({ radBillingList: this.state.radBillingList }, () => {
                            if (item?.is_doctor_wise) {
                                this.CalculateServiceValues(index)
                            } else {
                                this.CalculateBillValuse(index)
                            }
                        })
                    }
                    break;
                case 'increase':
                    if (item?.service_unit >= 1) {
                        item.service_unit++
                        item.service_total_amount = item.service_unit * item.service_rate_per_unit
                        this.setState({ radBillingList: this.state.radBillingList }, () => {
                            if (item?.is_doctor_wise) {
                                this.CalculateServiceValues(index)
                            } else {
                                this.CalculateBillValuse(index)
                            }
                        })
                    }
                    break;
                default: break;
            }
        } catch (error) {
            this.props.errorMessage(error.message)
        }
    }

    onClickAddDiscount = (item, index) => {
        try {
            let ipServiceFee = item?.service_charges
            let discountFee = ipServiceFee?.discount_doctor_fee ? ipServiceFee?.discount_doctor_fee : 0
            let discountHospitalFee = ipServiceFee?.discount_hospital_fee ? ipServiceFee?.discount_hospital_fee : 0

            ipServiceFee.original_doctor_fee = ipServiceFee.original_doctor_fee ? ipServiceFee.original_doctor_fee : ipServiceFee?.doctor_fee
            ipServiceFee.original_hospital_fee = ipServiceFee.original_hospital_fee ? ipServiceFee.original_hospital_fee : ipServiceFee?.hospital_fee
            ipServiceFee.share_discount = true
            this.setState({
                serviceDiscountpopup: true,
                selectedData: JSON.parse(JSON.stringify(item)),
                dupliSelectedData: JSON.stringify(item),
                selectedType: item?.amount_type,
                discountIndex: index
            }, () => {
                if (this.state.selectedType === 'rupee') {
                    this.setState({
                        discountDoctorFee: ipServiceFee?.discount_doctor_fee ? ipServiceFee?.discount_doctor_fee : 0,
                        discountHospitalFee: ipServiceFee?.discount_hospital_fee ? ipServiceFee?.discount_hospital_fee : 0
                    })
                } else {
                    this.setState({
                        discountPercentDoctorFee: ipServiceFee?.discount_doctor_percentage ? ipServiceFee?.discount_doctor_percentage : 0,
                        discountPercentDoctorFee_w_r: ipServiceFee?.discount_doctor_percentage ? CommonValidation.formatAmount(ipServiceFee?.discount_doctor_percentage) : 0,
                        discountPercentHospitalFee: ipServiceFee?.discount_hospital_percentage ? ipServiceFee?.discount_hospital_percentage : 0,
                        discountPercentHospitalFee_w_r: ipServiceFee?.discount_hospital_percentage ? CommonValidation.formatAmount(ipServiceFee?.discount_hospital_percentage) : 0
                    })
                }
            })

            this.setState({
                IndivDiscountComments: item?.individual_discount_comments ? item?.individual_discount_comments : ""
            })
        } catch (error) {
            this.props.errorMessage(error.message, 'error')
        }
    }

    feeValue = () => {
        try {
            let doctorFee = 0; let hospitalFee = 0;
            let duplicateSelectedValue = JSON.parse(this.state.dupliSelectedData)
            let ipServiceFee = duplicateSelectedValue?.service_charges
            doctorFee = +ipServiceFee?.original_doctor_fee
            hospitalFee = +ipServiceFee?.original_hospital_fee

            return { doctorFee, hospitalFee };
        } catch (error) {
            this.props.errorMessage(error.message, 'error')
        }
    }

    onChangeDiscountHandler = (e, names) => {
        try {
            let value = e.target.value
            let fee = this.feeValue()
            switch (names) {
                case 'discountDoctorFee':
                    if (this.state.selectedType === 'rupee') {
                        if ((CommonValidation.NumberWithDot(value) && +value <= fee.doctorFee) || value === '') {
                            this.state.discountPercentDoctorFee = CommonValidation.amountPercentageWithoutRoundoff(+value, fee.doctorFee)
                            this.state.discountPercentDoctorFee_w_r = CommonValidation.amountPercentage(+value, fee.doctorFee)
                            this.setState({ discountDoctorFee: +value }, () => {
                                let ipServiceFee = this.state.selectedData?.service_charges
                                ipServiceFee.discount_doctor_fee = value ? value : 0
                                if (!ipServiceFee.discount_hospital_fee && !ipServiceFee.discount_doctor_fee) {
                                    this.setState({
                                        IndivDiscountComments: ""
                                    })
                                }
                                this.setState({ selectedData: this.state.selectedData })
                            })
                        }
                    } else {
                        if ((CommonValidation.NumberWithDot(value) && +value <= 100) || value === '') {
                            this.state.discountDoctorFee = CommonValidation.calculatePercentage(+value, fee.doctorFee)
                            this.setState({ discountPercentDoctorFee: +value, discountPercentDoctorFee_w_r: +value }, () => {
                                let ipServiceFee = this.state.selectedData?.service_charges
                                ipServiceFee.discount_doctor_fee = value ? CommonValidation.calculatePercentage(value, fee.doctorFee) : 0
                                if (!ipServiceFee.discount_hospital_fee && !ipServiceFee.discount_doctor_fee) {
                                    this.setState({
                                        IndivDiscountComments: ""
                                    })
                                }
                                this.setState({ selectedData: this.state.selectedData })
                            })
                        }
                    }
                    break;
                case 'discountHospitalFee':
                    if (this.state.selectedType === 'rupee') {
                        if ((CommonValidation.NumberWithDot(value) && +value <= fee.hospitalFee) || value === '') {
                            this.state.discountPercentHospitalFee = CommonValidation.amountPercentageWithoutRoundoff(+value, fee.hospitalFee)
                            this.state.discountPercentHospitalFee_w_r = CommonValidation.amountPercentage(+value, fee.hospitalFee)
                            this.setState({ discountHospitalFee: +value }, () => {
                                let ipServiceFee = this.state.selectedData?.service_charges
                                ipServiceFee.discount_hospital_fee = value ? value : 0
                                if (!ipServiceFee.discount_hospital_fee && !ipServiceFee.discount_doctor_fee) {
                                    this.setState({
                                        IndivDiscountComments: ""
                                    })
                                }
                                this.setState({ selectedData: this.state.selectedData })
                            })
                        }
                    } else {
                        if ((CommonValidation.NumberWithDot(value) && +value <= 100) || value === '') {
                            this.state.discountHospitalFee = CommonValidation.calculatePercentage(+value, fee.hospitalFee)
                            this.setState({ discountPercentHospitalFee: +value, discountPercentHospitalFee_w_r: +value }, () => {
                                let ipServiceFee = this.state.selectedData?.service_charges
                                ipServiceFee.discount_hospital_fee = value ? CommonValidation.calculatePercentage(value, fee.hospitalFee) : 0
                                if (!ipServiceFee.discount_hospital_fee && !ipServiceFee.discount_doctor_fee) {
                                    this.setState({
                                        IndivDiscountComments: ""
                                    })
                                }
                                this.setState({ selectedData: this.state.selectedData })
                            })
                        }
                    }
                    break;
                default: break;
            }
        } catch (error) {
            this.props.errorMessage(error.message, 'error')
        }
    }

    splitUpTextBox = (names, values, label, width) => {
        try {
            return (
                <TextField
                    size='small'
                    sx={{ width: width }}
                    value={values}
                    label={label}
                    autoComplete='off'
                    onChange={(e) => this.onChangeDiscountHandler(e, names)}
                    InputProps={{
                        endAdornment: this.state.selectedType === 'rupee' ?
                            // <CurrencyRupee color='primary'></CurrencyRupee> :
                            CurrencySymbol :
                            <PercentIcon color='primary' />
                    }}
                />
            )
        } catch (error) {
            this.props.errorMessage(error.message, 'error')
        }
    }

    shareValue = () => {
        try {
            let states = this.state
            let doctorFee = 0; let hospitalFee = 0; let wardName = '';
            let discountDoctorFee = 0; let discountHospitalFee = 0;
            let ipServiceFee = states.selectedData?.service_charges
            doctorFee = CommonValidation.formatAmount(ipServiceFee?.original_doctor_fee)
            hospitalFee = CommonValidation.formatAmount(ipServiceFee?.original_hospital_fee)
            wardName = ipServiceFee?.selected_ward_name
            discountDoctorFee = CommonValidation.formatAmount(ipServiceFee?.discount_doctor_fee)
            discountHospitalFee = CommonValidation.formatAmount(ipServiceFee?.discount_hospital_fee)
            return { doctorFee, hospitalFee, wardName, discountDoctorFee, discountHospitalFee };
        } catch (error) {
            this.props.errorMessage(error.message, 'error')
        }
    }

    onChangeSymbol = (e) => {
        try {
            let states = this.state
            let ipServiceFee = states.selectedData?.service_charges
            ipServiceFee.discount_doctor_fee = 0;
            ipServiceFee.discount_hospital_fee = 0;
            this.setState({
                selectedType: e.target.value,
                discountDoctorFee: '',
                discountHospitalFee: '',
                discountPercentDoctorFee: '',
                discountPercentDoctorFee_w_r: '',
                discountPercentHospitalFee: '',
                discountPercentHospitalFee_w_r: '',
                selectedData: this.state.selectedData
            })
        } catch (error) {
            this.props.errorMessage(error.message, 'error')
        }
    }

    serviceIndividualDiscountPopup = () => {
        try {
            let states = this.state
            let fee = this.shareValue()
            let doctorShare = this.state.selectedType === 'rupee' ? this.state.discountDoctorFee : this.state.discountPercentDoctorFee
            let doctorShare_w_r = this.state.selectedType === 'rupee' ? this.state.discountDoctorFee : this.state.discountPercentDoctorFee_w_r
            let hospitalShare = this.state.selectedType === 'rupee' ? this.state.discountHospitalFee : this.state.discountPercentHospitalFee
            let hospitalShare_w_r = this.state.selectedType === 'rupee' ? this.state.discountHospitalFee : this.state.discountPercentHospitalFee_w_r
            return (
                <Modal open={this.state.serviceDiscountpopup}>
                    <Box className='eMed_Service_Discount'>
                        <Box m={'1vw'}>
                            <Typography color={Colors.SecondaryText} fontWeight={600}>Split-Up</Typography>
                        </Box>
                        <Box display={'flex'} justifyContent={'space-between'} m={'1vw'}>
                            <Box>
                                <Typography color={'gray'}>Bill Amount</Typography>
                                <Typography fontWeight={600} color={Colors.SecondaryText}>{states.selectedData?.service_amount ? AmountFormat(states.selectedData?.service_amount) : states.selectedData?.service_charges?.total_amount ? AmountFormat(states.selectedData?.service_charges?.total_amount) : AmountFormat(states.selectedData?.total_amount ? states.selectedData?.total_amount : states.selectedData?.totalAmount)}</Typography>
                            </Box>
                        </Box>
                        <Box m={'1vw'} display={'flex'} justifyContent={'flex-end'}>
                            <Typography fontWeight={600} color={Colors.SecondaryText}>Select Type:
                                <Select
                                    size='small'
                                    sx={{ width: '5vw', height: '2vw', ml: '1vw' }}
                                    value={this.state.selectedType}
                                    onChange={(e) => { this.onChangeSymbol(e) }}
                                >
                                    {Unit_Types.map((item, index) => (
                                        <MenuItem key={index} value={item.value}>{item?.label}</MenuItem>
                                    ))}
                                </Select>
                            </Typography>
                        </Box>
                        <Box m={'1vw'} display={'flex'} justifyContent={'space-evenly'}>
                            {this.splitUpTextBox('discountDoctorFee', doctorShare_w_r, 'Doctor Discount', '13.5vw')}
                            {this.splitUpTextBox('discountHospitalFee', hospitalShare_w_r, 'Hospital Discount', '13.5vw')}
                        </Box>
                        <Box display={'flex'} justifyContent={'space-between'} m={'1vw'}>
                            <Box>
                                <Typography fontSize={'0.8vw'} color={'gray'}>Doctor Share</Typography>
                                <Typography color={Colors.SecondaryText} fontWeight={600}>
                                    {`${fee.doctorFee} - ${fee.discountDoctorFee ? fee.discountDoctorFee : 0} = ${((fee.doctorFee) - (+fee.discountDoctorFee)) ? AmountFormat((fee.doctorFee) - (+fee.discountDoctorFee)) : 0}`}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography fontSize={'0.8vw'} color={'gray'}>Hospital Share</Typography>
                                <Typography color={Colors.SecondaryText} fontWeight={600}>
                                    {`${fee.hospitalFee} - ${fee.discountHospitalFee ? fee.discountHospitalFee : 0} = ${((fee.hospitalFee) - (+fee.discountHospitalFee)) ? AmountFormat(fee.hospitalFee - (+fee.discountHospitalFee)) : 0}`}
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ paddingX: '0.5vw' }}>
                            <TextField
                                label={"Discount Comments"}
                                sx={{ width: '31vw' }}
                                multiline={true}
                                onChange={(e) => {
                                    this.setState({
                                        IndivDiscountComments: e.target.value
                                    })
                                }}
                                inputProps={{ maxLength: 250 }}
                                disabled={(!doctorShare && !hospitalShare)}
                                value={this.state.IndivDiscountComments}
                                rows={2} />
                        </Box>
                        <Box display={'flex'} justifyContent={'flex-end'} m={'1vw'}>
                            <Button variant='outlined' sx={{ width: '5vw', height: '2vw', textTransform: 'capitalize', mr: '1vw' }}
                                onClick={() => {
                                    this.setState({
                                        serviceDiscountpopup: false,
                                        discountDoctorFee: '',
                                        discountHospitalFee: '',
                                        discountPercentDoctorFee: '',
                                        discountPercentDoctorFee_w_r: '',
                                        discountPercentHospitalFee: '',
                                        discountPercentHospitalFee_w_r: '',
                                        selectedType: 'rupee',
                                        selectedData: JSON.parse(this.state.dupliSelectedData),
                                    })
                                }}>Close</Button>
                            <Button variant='contained' sx={{ width: '5vw', height: '2vw', textTransform: 'capitalize' }}
                                onClick={() => { this.onSaveDiscountAmount() }}
                            >Save</Button>
                        </Box>
                    </Box>
                </Modal>
            )
        } catch (error) {
            this.props.errorMessage(error.message, 'error')
        }
    }

    onSaveDiscountAmount = () => {
        try {
            let states = this.state
            let doctorFee = this.state.discountDoctorFee ? this.state.discountDoctorFee : 0
            let hospitalFee = this.state.discountHospitalFee ? this.state.discountHospitalFee : 0
            let totalDiscountAmount = (+doctorFee) + (+hospitalFee)
            let newDiscount = 0; let totalAmount = 0;
            let ipServiceFee = states.selectedData?.service_charges

            let feeDoctor = this.state.discountPercentDoctorFee ? this.state.discountPercentDoctorFee : ipServiceFee?.discount_doctor_fee ? ipServiceFee?.discount_doctor_fee : 0
            let feeHospital = this.state.discountPercentHospitalFee ? this.state.discountPercentHospitalFee : ipServiceFee?.discount_hospital_fee ? ipServiceFee?.discount_hospital_percentage : 0

            // if (feeDoctor !== 0 && feeHospital !== 0) {
            let doctorAmount = CommonValidation.calculatePercentage(+states.discountPercentDoctorFee, ipServiceFee?.original_doctor_fee)
            let hospitalAmount = CommonValidation.calculatePercentage(+states.discountPercentHospitalFee, ipServiceFee?.original_hospital_fee)

            totalAmount = +ipServiceFee?.original_doctor_fee + +ipServiceFee?.original_hospital_fee
            newDiscount = doctorAmount + hospitalAmount
            let doctorPercentage = states.discountPercentDoctorFee ? +states.discountPercentDoctorFee : 0
            let hospitalPercentage = states.discountPercentHospitalFee ? +states.discountPercentHospitalFee : 0
            let discountPercent = CommonValidation.amountPercentageWithoutRoundoff(newDiscount, totalAmount)
            states.selectedData.discount_amount = this.state.selectedType === 'rupee' ? (CommonValidation.formatAmount(+doctorFee) + CommonValidation.formatAmount(+hospitalFee)) : CommonValidation.formatAmount(newDiscount)
            states.selectedData.discount_percentage = this.state.selectedType === 'rupee' ? CommonValidation.amountPercentageWithoutRoundoff(totalDiscountAmount, +states.selectedData?.service_rate_per_unit) : discountPercent
            states.selectedData.discount_percentage_w_r = this.state.selectedType === 'rupee' ? CommonValidation.amountPercentage(totalDiscountAmount, +states.selectedData?.service_rate_per_unit) : CommonValidation.formatAmount(discountPercent)

            // } else if (feeDoctor !== 0 && feeHospital === 0) {
            //     newDiscount = +feeDoctor
            //     totalAmount = +ipServiceFee?.original_doctor_fee + +ipServiceFee?.original_hospital_fee
            //     let discountPercentAmount = CommonValidation.calculatePercentage(newDiscount, +totalAmount)
            //     let doctorPercentage = states.discountPercentDoctorFee ? +states.discountPercentDoctorFee : 0
            //     let hospitalPercentage = states.discountPercentHospitalFee ? +states.discountPercentHospitalFee : 0
            //     let discountPercent = doctorPercentage + hospitalPercentage
            //     states.selectedData.discount_amount = this.state.selectedType === 'rupee' ? (CommonValidation.formatAmount(+doctorFee) + CommonValidation.formatAmount(+hospitalFee)) : CommonValidation.formatAmount(discountPercentAmount)
            //     states.selectedData.discount_percentage = this.state.selectedType === 'rupee' ? CommonValidation.amountPercentage(totalDiscountAmount, +states.selectedData?.service_rate_per_unit) : CommonValidation.formatAmount(discountPercent)

            // } else {
            //     newDiscount = feeHospital
            //     totalAmount = +ipServiceFee?.original_doctor_fee + +ipServiceFee?.original_hospital_fee
            //     let discountPercentAmount = CommonValidation.calculatePercentage(newDiscount, +totalAmount)
            //     let doctorPercentage = states.discountPercentDoctorFee ? +states.discountPercentDoctorFee : 0
            //     let hospitalPercentage = states.discountPercentHospitalFee ? +states.discountPercentHospitalFee : 0
            //     let discountPercent = doctorPercentage + hospitalPercentage
            //     states.selectedData.discount_amount = this.state.selectedType === 'rupee' ? (CommonValidation.formatAmount(+doctorFee) + CommonValidation.formatAmount(+hospitalFee)) : CommonValidation.formatAmount(discountPercentAmount)
            //     states.selectedData.discount_percentage = this.state.selectedType === 'rupee' ? CommonValidation.amountPercentage(totalDiscountAmount, +states.selectedData?.service_rate_per_unit) : CommonValidation.formatAmount(discountPercent)

            // }
            ipServiceFee.discount_doctor_fee = this.state.selectedType === 'rupee' ? +doctorFee : CommonValidation.calculatePercentage(this.state.discountPercentDoctorFee, ipServiceFee?.original_doctor_fee)
            ipServiceFee.discount_hospital_fee = this.state.selectedType === 'rupee' ? +hospitalFee : CommonValidation.calculatePercentage(this.state.discountPercentHospitalFee, ipServiceFee?.original_hospital_fee)
            ipServiceFee.discount_doctor_percentage = this.state.selectedType === 'rupee' ? CommonValidation.amountPercentageWithoutRoundoff(this.state.discountDoctorFee ? +this.state.discountDoctorFee : 0, ipServiceFee?.original_doctor_fee) : this.state.discountPercentDoctorFee ? +this.state.discountPercentDoctorFee : 0
            ipServiceFee.discount_doctor_percentage_w_r = this.state.selectedType === 'rupee' ? CommonValidation.amountPercentage(this.state.discountDoctorFee ? +this.state.discountDoctorFee : 0, ipServiceFee?.original_doctor_fee) : this.state.discountPercentDoctorFee_w_r ? +this.state.discountPercentDoctorFee_w_r : 0
            ipServiceFee.discount_hospital_percentage = this.state.selectedType === 'rupee' ? CommonValidation.amountPercentageWithoutRoundoff(this.state.discountHospitalFee ? +this.state.discountHospitalFee : 0, ipServiceFee?.original_hospital_fee) : this.state.discountPercentHospitalFee ? +this.state.discountPercentHospitalFee : 0
            ipServiceFee.discount_hospital_percentage_w_r = this.state.selectedType === 'rupee' ? CommonValidation.amountPercentage(this.state.discountHospitalFee ? +this.state.discountHospitalFee : 0, ipServiceFee?.original_hospital_fee) : this.state.discountPercentHospitalFee_w_r ? +this.state.discountPercentHospitalFee_w_r : 0
            ipServiceFee.original_doctor_fee = +ipServiceFee.original_doctor_fee
            ipServiceFee.original_hospital_fee = +ipServiceFee.original_hospital_fee
            ipServiceFee.doctor_fee = CommonValidation.formatAmount((+ipServiceFee.original_doctor_fee) - (ipServiceFee.discount_doctor_fee ? ipServiceFee.discount_doctor_fee : +doctorFee))
            ipServiceFee.hospital_fee = CommonValidation.formatAmount((+ipServiceFee.original_hospital_fee) - (ipServiceFee.discount_hospital_fee ? ipServiceFee.discount_hospital_fee : +hospitalFee))
            // ipServiceFee.doctor_percentage = ipServiceFee.discount_doctor_percentage ? ipServiceFee.discount_doctor_percentage : CommonValidation.amountPercentage(ipServiceFee?.old_doctor_fee, states.selectedData?.service_rate_per_unit)
            // ipServiceFee.hospital_percentage = ipServiceFee.discount_hospital_percentage ? ipServiceFee.discount_hospital_percentage : CommonValidation.amountPercentage(ipServiceFee?.old_hospital_fee, states.selectedData?.service_rate_per_unit)
            states.selectedData.amount_type = this.state.selectedType
            ipServiceFee.total_amount = ipServiceFee.doctor_fee + ipServiceFee.hospital_fee
            states.selectedData.individual_discount_comments = states.IndivDiscountComments

            states.selectedData.service_charges = ipServiceFee
            states.radBillingList[this.state.discountIndex] = states.selectedData

            if ((states.discountDoctorFee || states.discountHospitalFee || states.discountPercentDoctorFee || states.discountPercentHospitalFee) > 0) {
                states.selectedData.isSplitBlock = true
            }
            this.setState({
                selectedData: this.state.selectedData,
                serviceDiscountpopup: false,
                discountIndex: null
            }, () => {
                // this.calculateDiscountAmt()
                this.setState({
                    IndivDiscountComments: '',
                    discountDoctorFee: '',
                    discountHospitalFee: '',
                    discountPercentDoctorFee: '',
                    discountPercentDoctorFee_w_r: '',
                    discountPercentHospitalFee: '',
                    discountPercentHospitalFee_w_r: '',
                    selectedType: 'rupee',
                    overAllDiscountAmt: '',
                    overAllDiscountPercent: '',
                    overAllDiscountPercent_w_r: '',
                })
            })
        } catch (error) {
            this.props.errorMessage(error.message, 'error')
        }
    }

    discountReasonPopup = () => {
        try {
            return (
                <Modal open={this.state.discountReasonPopup}>
                    <Box className='eMed_Discount_Reason' sx={{ height: '33dvh' }}>
                        <Box component={'div'} height={'2vw'} display={'flex'} m={'1vw'} justifyContent={'space-between'}>
                            <Typography color={Colors.SecondaryText} fontWeight={600}>Radiology Bill Discount Reason</Typography>
                        </Box>
                        <Box m={'1vw'}>
                            <TextField
                                fullWidth
                                multiline
                                label='Reason *'
                                rows={3}
                                value={this.state.discountReason}
                                onChange={(e) => {
                                    this.setState({ discountReason: e.target.value })
                                }}
                            />
                        </Box>
                        <Box m={'1vw'} display={'flex'} justifyContent={'flex-end'}>
                            <Button
                                variant='outlined'
                                sx={{ width: '5vw', height: '2vw', mr: '1vw' }}
                                onClick={() => {
                                    this.setState({ discountReasonPopup: false, discountReason: '' })
                                }}
                            >Close</Button>
                            <Button
                                variant='contained'
                                sx={{ width: '5vw', height: '2vw' }}
                                onClick={() => {
                                    if (this.state.discountReason !== '') {
                                        this.setState({ discountReasonPopup: false })
                                    } else {
                                        this.props.errorMessage("Reason is mandatory", 'error')
                                    }
                                }}
                            >Save</Button>
                        </Box>
                    </Box>
                </Modal>
            )
        } catch (error) {
            this.props.errorMessage(error.message, 'error')
        }
    }

    billingCalculation = (key) => {
        try {
            let { radBillingList } = this.state
            let subAmount = 0; let payableAmount = 0;

            switch (key) {
                case 'roundOff':
                    this.state.roundOff = CommonValidation.roundOff(this.state.subAmount, this.state.overAllDiscountAmt)
                    return AmountFormat(this.state.roundOff);
                case 'payableAmount':
                    let tltAmt = 0;
                    radBillingList.forEach(element => {
                        tltAmt = tltAmt + ((element.service_charges.hospital_fee || element.service_charges.hospital_fee === 0) ? element.service_charges?.hospital_fee : (element.service_total_amount - (+element.discount_amount)))
                        subAmount = subAmount + ((+element.service_total_amount) - (+element.discount_amount))
                        this.state.subAmount = +subAmount
                        this.state.concessionRestrictAmt = +this.state.billingConcessionType === 1 ? +subAmount : +tltAmt
                    })
                    let discount = this.state.overAllDiscountAmt ? +this.state.overAllDiscountAmt : 0
                    payableAmount = +this.state.subAmount - +discount
                    this.state.roundOff = CommonValidation.roundOff(this.state.subAmount, this.state.overAllDiscountAmt)
                    this.state.payableAmount = Math.round(+payableAmount)
                    return AmountFormat(+this.state.payableAmount)
                default: break;
            }
        } catch (error) {
            this.props.errorMessage(error.message)
        }
    }

    concessionManditoryPopup = (names) => {
        if ((this.state.overAllDiscountPercent || this.state.overAllDiscountAmt) && (this.state.discountReason === "")) {
            if (names === 'overAllDiscountPercent' || names === 'overAllDiscountAmt') {
                this.setState({ discountReasonPopup: true })
            }
        }
    }

    popupClose = () => {
        try {
            this.setState({
                deletePopup: false,
                deletePopupTwo: false,
                removeData: false
            })
        } catch (error) {
            this.props.errorMessage(error.message)
        }
    }

    onDeleteHandlerLftAll = () => {
        try {
            this.setState({
                radBillingList: [],
                deletePopup: false,
                removeData: false
            }, () => {
                this.onClearHandler()
            })
        } catch (error) {
            this.props.errorMessage(error.message)
        }
    }

    onDeleteLftHandler = () => {
        try {
            this.state.radBillingList?.splice(this.state.deletedIndex, 1)
            this.setState({
                radBillingList: this.state.radBillingList,
            }, () => {
                this.setState({
                    deletedIndex: null,
                    deletePopupTwo: false,
                    removeData: false,
                    overAllDiscountPercent: this.state.radBillingList?.length === 0 ? '' : this.state.overAllDiscountPercent,
                    overAllDiscountPercent_w_r: this.state.radBillingList?.length === 0 ? '' : CommonValidation.formatAmount(this.state.overAllDiscountPercent),
                    subAmount: this.state.radBillingList?.length === 0 ? 0 : this.state.subAmount,
                }, () => {
                    // this.calculateDiscountAmt()
                    // this.onClearLeftHandler()
                    this.setState({
                        overAllDiscountAmt: '',
                        overAllDiscountPercent: '',
                        overAllDiscountPercent_w_r: ''
                    })
                })
            })
        } catch (error) {
            this.props.errorMessage(error.message)
        }
    }

    render() {
        console.log(this.props);

        const { t } = this.props;
        const { openAccordion, radList, totalBillAmount } = this.state;
        return (
            <Accordion expanded={openAccordion} sx={{ border: '1px solid #F5F8F7', marginX: "0.3vw" }}>
                <AccordionSummary sx={{
                    flexDirection: 'row-reverse',
                    '& .MuiAccordionSummary-content': {
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }
                }}
                    expandIcon={
                        <IconButton color='primary'
                            onClick={() => {
                                if (this.props?.PatientDetails === null) {
                                    this.props?.errorMessage("Select the patient for billing.", 'error')
                                } else {
                                    this.setState((prevState) => ({ openAccordion: !prevState.openAccordion }))
                                }
                            }}
                            emed_tid="emedhub_expandBtn_testID">
                            <ExpandMoreIcon />
                        </IconButton>}
                >
                    <Typography fontWeight={"bold"} fontSize={"1dvw"} color={"#616161"}>Radiology</Typography>
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"} columnGap={"10px"}>
                        {/* {this.renderAutoComplete('Selected Radiology', "radDetails", radList, "radiology_name")} */}
                        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} columnGap={"5px"}>
                            {+this.state.billingConcessionType === 1 ? null : <Box component={'div'}>
                                <Tooltip placement='top' title={`Maximum Concession Should be ${AmountFormat(this.state.concessionRestrictAmt || 0)}`}>
                                    <ErrorOutlineIcon color='primary' sx={{ width: '1.3vw', height: '1.3vw' }} />
                                </Tooltip>
                            </Box>}
                            <Typography fontWeight={"bold"} fontSize={"1dvw"} color={"#616161"}>Concession:</Typography>
                            {this.textBox('overAllDiscountPercent', this.state.overAllDiscountPercent_w_r, '6dvw', '', '%')}
                            {this.textBox('overAllDiscountAmt', this.state.overAllDiscountAmt, '6dvw', '', CurrencySymbol)}
                        </Box>
                        {(this.state.overAllDiscountPercent || this.state.overAllDiscountAmt) && !this.props.history?.location?.state?.fromCancelBill && !this.props?.CancelFromFO ?
                            <Tooltip placement='top' title={this.state.discountReason ? this.state.discountReason : this.state.savedDiscountReason}>
                                <Box onClick={() => this.setState({ discountReasonPopup: this.state.ForCancel ? false : true, discountReason: this.state.discountReason ? this.state.discountReason : this.state.savedDiscountReason })} component={'img'} src={ImagePaths.LabReportOff.default} width={'1.5vw'} height={'1.5vw'} sx={{ cursor: 'pointer' }} alignSelf={'center'} />
                            </Tooltip> :
                            <Box component={'img'} src={ImagePaths.BillTransUser.default} width={'1vw'} height={'1vw'} alignSelf={'center'} />
                        }
                        <Typography fontWeight={"bold"} fontSize={"1dvw"} color={"#616161"} sx={{ width: "9vw", textAlign: 'end' }}>{
                            this.billingCalculation('payableAmount')
                        } </Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Table stickyHeader size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell className='rad_bill_tbl_head' sx={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
                                    <IconButton size='small' sx={{ height: '1.2dvw' }} emed_tid='rad_bill_all_dlt_btn'
                                        onClick={() => { this.setState({ deletePopup: true }) }}
                                    >
                                        <Box component={'img'} src={ImagePaths.Delete.default} className='Lab_Home_DeleteIcon' alt='Delete' />
                                    </IconButton>
                                    <Typography fontWeight={600} fontSize={'0.9dvw'}>Service Description</Typography>
                                </TableCell>
                                <TableCell className='rad_bill_tbl_head'>
                                    <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                        <Typography fontWeight={600} fontSize={'0.9dvw'}>Units</Typography>
                                        <Tooltip placement='top' title='Edit'>
                                            <IconButton size='small' sx={{ height: '1.2dvw' }} emed_tid='rad_bill_unt_btn' onClick={() => this.setState({ editUnit: !this.state.editUnit })}>
                                                <Box component={'img'} src={ImagePaths.NewEditcon.default} />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </TableCell>
                                <TableCell className='rad_bill_tbl_head' align='right'>
                                    <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                        <Typography fontWeight={600} fontSize={'0.9dvw'}>Rate Per Unit</Typography>
                                        <Tooltip placement='top' title='Edit'>
                                            <IconButton size='small' sx={{ height: '1.2dvw' }} emed_tid='rad_bill_rate_btn' onClick={() => this.setState({ editRate: !this.state.editRate })} >
                                                <Box component={'img'} src={ImagePaths.NewEditcon.default} />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </TableCell>
                                <TableCell className='rad_bill_tbl_head' align='right'>
                                    <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                        <Typography fontWeight={600} fontSize={'0.9dvw'}>Discount</Typography>
                                        <Tooltip placement='top' title='Edit'>
                                            <IconButton size='small' sx={{ height: '1.2dvw' }} emed_tid='rad_bill_rate_btn' onClick={() => this.setState({ editDiscount: !this.state.editDiscount })} >
                                                <Box component={'img'} src={ImagePaths.NewEditcon.default} />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </TableCell>
                                <TableCell className='rad_bill_tbl_head' align='right'>
                                    <Typography fontWeight={600} fontSize={'0.9dvw'}>Total Amount</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.radBillingList?.length > 0 ? this.state.radBillingList?.map((item, index) => {
                                let serviceName = `${item?.service_type ? item?.service_type : '-'} - ${item?.service_name ? item?.service_name : '-'}`
                                return (
                                    <TableRow key={index}>
                                        <TableCell sx={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
                                            <IconButton size='small' height={'1.2dvw'} sx={{ visibility: (item?.service_id) ? "visible" : "hidden" }}
                                                onClick={() => this.setState({ deletePopupTwo: true, deletedDatas: item, deletedIndex: index })}
                                            >
                                                <Box component={'img'} src={ImagePaths.Delete.default} height={'1.2dvw'} width={'1.2dvw'} />
                                            </IconButton>
                                            {this.renderSrvSearch(index, item)}
                                        </TableCell>
                                        <TableCell align="center">
                                            {this.state.editUnit && item?.service_unit_edit ?
                                                <Box component={'div'} display={'flex'} flexDirection={'row'}>
                                                    <Button className='eMed_Bill_add_btn' emed_tid='rad_bill_dec_btn'
                                                        onClick={() => { this.onChangeUnitHandler(item, 'decrease', index) }}
                                                    >
                                                        <Typography>-</Typography>
                                                    </Button>
                                                    <Typography paddingRight={"1dvw"}>{item.service_unit}</Typography>
                                                    <Button className='eMed_Bill_add_btn' emed_tid='rad_bill_inc_btn'
                                                        onClick={() => { this.onChangeUnitHandler(item, 'increase', index) }}
                                                    >
                                                        <Typography>+</Typography>
                                                    </Button>
                                                </Box> :
                                                <Typography>{item?.service_unit ? item?.service_unit : 0}</Typography>
                                            }
                                        </TableCell>
                                        <TableCell align="center">
                                            {this.state.editRate && item?.service_rate_edit ?
                                                item?.is_doctor_wise ?
                                                    <Button size='small' sx={{ textTransform: "capitalize" }} onClick={() => { this.addShare(item, index) }}>{`Edit Share ${item.service_rate_per_unit ? `(${AmountFormat(item?.service_rate_per_unit)})` : 0}`}</Button> :
                                                    <Box component={'div'}>
                                                        {this.textBox('ratePerUnit', item?.service_rate_per_unit, '8dvw', '1.7dvw', CurrencySymbol, index)}
                                                    </Box>
                                                :
                                                <Typography>{AmountFormat(item?.service_rate_per_unit ? item?.service_rate_per_unit : 0)}</Typography>}
                                        </TableCell>
                                        <TableCell align="center">
                                            {this.state.editDiscount && item?.service_discount_edit ?
                                                item?.is_doctor_wise ?
                                                    <Button size='small' sx={{ textTransform: "capitalize" }} onClick={() => { this.onClickAddDiscount(item, index) }}>{`Edit Discount ( ${item.discount_amount ? `${AmountFormat(item?.discount_amount)}` : 0} )`}</Button> :
                                                    <Box component={'div'}>
                                                        {this.textBox('discountPercent', item?.discount_percentage_w_r, '7dvw', '1.7dvw', '%', index)}
                                                        {this.textBox('discountAmount', item?.discount_amount, '7dvw', '1.7dvw', CurrencySymbol, index)}
                                                    </Box> :
                                                <Typography>{AmountFormat(item?.discount_amount ? item?.discount_amount : 0)}</Typography>}
                                        </TableCell>
                                        <TableCell align="right">
                                            <Typography align='right'>{item?.service_total_amount ? AmountFormat(item?.service_total_amount - (item?.discount_amount ? item?.discount_amount : 0)) : 0}</Typography>
                                        </TableCell>
                                    </TableRow>
                                )
                            }) :
                                <TableRow>
                                    <TableCell colSpan={6} align='center'>No Records To Be Shown</TableCell>
                                </TableRow>}
                        </TableBody>
                    </Table>
                </AccordionDetails>
                {this.state.docSplitPopup ? this.splitScreenPopup() : null}
                {this.serviceIndividualDiscountPopup()}
                {this.discountReasonPopup()}
                {this.state.deletePopup ? <DeletePopup
                    DeletTitle={`Are you sure you want to delete ?`}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this.onDeleteHandlerLftAll.bind(this)}
                    disable={this.state.removeData}
                /> : null}
                {this.state.deletePopupTwo ? <DeletePopup
                    DeletTitle={`Are you sure you want to delete ?`}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this.onDeleteLftHandler.bind(this)}
                    disable={this.state.removeData}
                /> : null}
            </Accordion>
        )
    }
}
export default withTranslation()(MultiRadBilling);