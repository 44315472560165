import { Box, Stack, Button, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Colors } from '../../../Styles/Colors'
import { DataGrid } from '@mui/x-data-grid'
import { CommonDeleteButton, CommonEditButton, CommonGridToolBarWithFilterText, DeletePopup } from '../../../Components/Common Components/CommonComponents'
import { useHistory } from "react-router-dom";
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import { CurrencySymbol } from '../../../Utility/Constants'
import { AmountFormat } from '../../../Components/CommonFunctions/CommonFunctions'
import { useLocation } from 'react-router-dom'


export default function DrugPackage() {
  const history = useHistory();
  const location = useLocation();
  const [packageData, setpackageData] = useState([]);
  const [page, setpage] = useState(0);
  const [pageSize, setpageSize] = useState(10);
  const [delIDs, setdelIDs] = useState([])
  const [errorMsg, seterrorMsg] = useState(false);
  const [errorMsgText, seterrorMsgText] = useState('');
  const [successMsg, setsuccessMsg] = useState(false);
  const [successMsgText, setsuccessMsgText] = useState('');
  const [isDelete, setisDelete] = useState(false);
  const [deletedID, setdeletedID] = useState(null);
  const [isloader, setisloader] = useState(false);
  const [CheckBoxList, setCheckBoxList] = useState([]);
  

  useEffect(() => {
    getDrugPackage()
    if (location?.states?.alertmsg) {
      let message = location.states.alertmsg
      successMessage(message)
    }
  }, [])

  const errorMessage = (msg) => {
    seterrorMsg(true)
    seterrorMsgText(msg)
  }

  const successMessage = (msg) => {
    setsuccessMsg(true)
    setsuccessMsgText(msg)
  }

  const clearFunc = () => {
    setisDelete(false);
    setdeletedID(null);
    setdelIDs([])
  }

  const getDrugPackage = () => {
    try {
      setisloader(true)
      RestAPIService.getAll(Serviceurls.ASS_GET_DRUG_PACKAGE)
        .then((response) => {
          if (response.data.status === 'success') {
            setpackageData(response?.data?.data || [])
            setisloader(false)
          }
        }).catch(e => {
          setisloader(false)
          if (e?.response?.data?.status === 'fail') {
            errorMessage(e.response.data.message)
          } else {
            errorMessage(e.message)
          }
        })
    } catch (e) {
      setisloader(false)
      errorMessage(e.message)
    }
  }

  const deleteDrug = () => {
    try {
      let data = {
        'id': deletedID ? [deletedID] : (delIDs || [])
      }
      RestAPIService.delete(Serviceurls.ASS_GET_DRUG_PACKAGE, data)
        .then((response) => {
          if (response.data.status === 'success') {
            successMessage(response.data.message)
            getDrugPackage()
            setCheckBoxList([]);
            clearFunc()
          }
        }).catch(e => {
          if (e?.response?.data?.status === 'fail') {
            errorMessage(e.response.data.message)
          } else {
            errorMessage(e.message)
          }
        })
    } catch (e) {
      errorMessage(e.message)
    }
  }

  const grpDelete = () => {
    setisDelete(true)
  }

  const tableView = () => {

    const gridToolBar = () => {
      return (
        <Box>
          <CommonGridToolBarWithFilterText isDelete={delIDs?.length > 0} removeData={grpDelete.bind(this)} />
        </Box>

      )
    }

    const handleTableCheckbox = (id) => {
      let selectedIDs = new Set(id);
      setCheckBoxList(id)
      let selectedRows = packageData.filter((row) => selectedIDs.has(row.sno));
      let FinalData = []
      selectedRows.forEach((item) => {
        FinalData.push(item.id)
      })
      setdelIDs(FinalData)
    }

    packageData?.forEach((element, index) => {
      element.sno = index + 1
      element.count =  element.line_items?.length
    })
    const columns = [
      {
        field: 'pack_name', headerName: 'Package Name', flex: 0.4,
        renderCell: (params) => (params.row.pack_name ? params.row.pack_name?.length > 15 ?
          <Tooltip placement="top" title={params.row.pack_name}><Typography fontSize={'0.9vw'}>{params.row.pack_name?.slice(0, 15) + '...'}</Typography></Tooltip> :
          <Typography fontSize={'0.9vw'}>{params.row.pack_name}</Typography> : '-')

      },
      {
        field: 'count', headerName: 'No of Drugs', flex: 0.3,
        renderCell: (params) => {
          let count = params?.row?.line_items?.length
          return (<Box component={'div'}>{count ? count : '0'}</Box>)
        }

      },
      {
        field: 'pack_amount', headerName: 'Amount', flex: 0.2,
        renderCell: (params) => (<Box component={'div'}>{AmountFormat(params?.row?.pack_amount || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)

      },
      {
        field: 'action', headerName: 'Action', flex: 0.1,
        renderCell: (params) => (
          <Stack direction="row" alignItems="center" spacing={4}>
            <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={1}>
              <CommonEditButton size="1.5vw" onClick={() => { history.push({ pathname: "/Settings/IPService/DrugPackage/Add", states: params.row })}} />
              <CommonDeleteButton size="1.5vw" onClick={() => { setisDelete(true); setdeletedID(params?.row?.id); }} />
            </Stack>
          </Stack>)

      },
    ]
    return (
      <Box sx={{ height: '72vh' }}>
        <DataGrid
          checkboxSelection
          selectionModel={CheckBoxList}
          onSelectionModelChange={(id) => handleTableCheckbox(id)}
          rows={packageData}
          columns={columns}
          getRowId={(row) => row['sno']}
          page={page}
          pageSize={pageSize}
          localeText={{
            toolbarColumns: "",
            toolbarDensity: "",
            toolbarExport: "",
            toolbarFilters: "",
            toolbarExportPrint: ""
          }}
          components={{
            Toolbar: gridToolBar,
            NoRowsOverlay: () => (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                {'No Records To Be Shown'}
              </Box>
            )
          }}
          onPageChange={(newPage) => { setpage(newPage) }}
          onPageSizeChange={(newPageSize) => { setpageSize(newPageSize) }}
          rowsPerPageOptions={[10, 20, 30]}
          pagination
          headerHeight={40}
          density='compact'
          disableSelectionOnClick
          loading={isloader}
        />
      </Box>
    )
  }
  const msgClose = () => {
    seterrorMsg(false);
    seterrorMsgText('');
    setsuccessMsg(false);
    setsuccessMsgText('');
  }

  const PopupClose = () => {
    setisDelete(false);
    setdeletedID(null)
  }

  return (
    <Box component={'div'} sx={{ height: '79.5vh', width: '94vw', backgroundColor: Colors.Background, padding: '.5vw' }}>
      <Box component={'div'} sx={{ height: '5vh', width: '93vw', alignContent: 'center' }}>
        <Box component={'div'} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', }}>
          <Button size='small' variant='contained' sx={{ textTransform: 'capitalize', width: '7vw' }}
            onClick={() => {
              history.push({ pathname: "/Settings/IPService/DrugPackage/Add" })
            }}>Add</Button>
        </Box>
      </Box>
      <Box component={'div'} sx={{ height: '72vh', width: '93vw', marginTop: '.5vw', backgroundColor: 'white', }}>
        {tableView()}
      </Box>
      {errorMsg ?
        <ToastMsg
          severity={'error'}
          msg={errorMsgText}
          msgPop={msgClose.bind(this)}
        />
        : null}
      {successMsg ?
        <ToastMsg
          severity={'success'}
          msg={successMsgText}
          msgPop={msgClose.bind(this)}
        />
        : null}
      {isDelete ?
        <DeletePopup
          DeletTitle={`Are you sure, Do you want to delete this item ?`}
          DeleteNotify={"If you remove this, this could be effect in entire Configuration"}
          deleteAlertPopupClose={PopupClose.bind(this)}
          removeData={deleteDrug.bind(this)}
        />
        : null}
    </Box>
  )
}

