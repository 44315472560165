import { Autocomplete, Box, Button, Paper, TextField, Typography, CircularProgress } from '@mui/material'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import '../reports.css';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DateTime } from "luxon";
import Loader from '../../../../Components/Loader'

async function parseBlobError(responseBlob) {
  try {
    // Convert Blob to text
    const errorText = await responseBlob.text();
    const errorJson = JSON.parse(errorText);
    console.error("Parsed Error:", errorJson);
    return errorJson.message;
  } catch (parseError) {
    console.error("Error parsing Blob response:", parseError);
    return "";
  }
}
class GenerateReport extends Component {
  constructor() {
    super()
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    this.state = {
      reportData: [],
      DatePickerOpen: false,
      datePicker: yesterday,
      datePicker2:yesterday,
      apiNextDate: "",
      reportType: null,
      isLoader: false,
      loader: false,
    }
  }

  componentDidMount() {
    this.getTallyReportData()
  }

  getTallyReportData = () => {
    try {
      this.setState({ loader: true})
      RestAPIService.getAllTwo(Serviceurls.TALLY_REPORT)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              reportData: response.data?.data,
              loader: false,
            })
          }
        })
        .catch(e => {
          this.setState({ loader: false})
          if (e.response.data.status === 'fail') {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })
    } catch (e) {
      this.setState({ loader: false})
      this.errorMessage(e.message)
    }
  }

  getCeleryReportExcel = () => {
    const { t } = this.props
    try {
      let states = this.state;
      var {datePicker2} = this.state
      let newDate = new Date(datePicker2)
      let apiStopDate = newDate.setDate(newDate.getDate() + 1)
      apiStopDate =  DateTime.fromJSDate((new Date(apiStopDate))).toFormat("yyyy-MM-dd")
      let maxday = DateTime.fromJSDate((new Date())).toFormat("yyyy-MM-dd")
      let repData = states.datePicker ? new Date(states.datePicker) : "";
      let tallyData = repData ? DateTime.fromJSDate(repData).toFormat("yyyy-MM-dd") : "";
      let nextDate = (repData && tallyData != maxday && apiStopDate != states.apiNextDate ) ? new Date(repData) : ""
      if(tallyData === apiStopDate || nextDate === "" ){
        if(tallyData === apiStopDate){
          let ChangeDate = repData.setDate(repData.getDate() - 1)
          ChangeDate =  (new Date(ChangeDate))
          this.setState({
            datePicker: ChangeDate,
            apiNextDate: ""
          })
        }
      }else{
      this.setState({isLoader: true})
      RestAPIService.getAllTwo(`${states.reportType?.url}?date=${tallyData}`).
        then((response) => {
          if (response.data.status === 'success') {
            this.setState({ task_id: response.data?.task_id, },
              () => {
                this.pollTaskStatus();
              })
          }
        }).catch((e) => {
          this.setState({isLoader: false})
          if (e.response.data?.message) {
            this.errorMessage(e.response.data?.message)
          } else {
            this.errorMessage(e.message)
          }
        })
      }
    } catch (e) {
      this.setState({isLoader: false})
      this.errorMessage(t("PrintError"))
    }
  }

  checkTaskStatus = async (taskId) => {
    try{
      const res = await RestAPIService.excelGetAccReport(Serviceurls.CELERY_DEMO_START + "?task_id=" + taskId);
    
      // Assuming `res.data` contains the data you're expecting
      const data = res;
      return data;
    }catch(error){
      console.error('Error fetching task status:', error.message);
      const data = {status: "fail", headers:[]};
      return data;
    }
     
  };

  pollTaskStatus = async () => {
    const { task_id } = this.state;
    let isTaskComplete = false;
    let states = this.state;
    var {datePicker2} = this.state
    let newDate = new Date(datePicker2)
    let apiStopDate = newDate.setDate(newDate.getDate() + 1)
    apiStopDate =  DateTime.fromJSDate((new Date(apiStopDate))).toFormat("yyyy-MM-dd")
    let maxday = DateTime.fromJSDate((new Date())).toFormat("yyyy-MM-dd")
    let repData = states.datePicker ? new Date(states.datePicker) : "";
    let tallyData = repData ? DateTime.fromJSDate(repData).toFormat("yyyy-MM-dd") : "";
    let nextDate = (repData && tallyData != maxday && apiStopDate != states.apiNextDate ) ? new Date(repData) : ""
    let nextDate2 = nextDate ? new Date(nextDate.setDate(nextDate.getDate() + 1)) : ""
    let nextDate3 = nextDate2 ? DateTime.fromJSDate(nextDate2).toFormat("yyyy-MM-dd") : ""
    if(tallyData === apiStopDate || nextDate === "" ){

    }else{

    while (!isTaskComplete) {
      const statusData = await this.checkTaskStatus(task_id);

      if (statusData?.headers["content-disposition"]) {
        isTaskComplete = true;
        var pom = document.createElement('a');
        var csvContent = statusData?.data; //here we load our csv data 
        let filename = statusData?.headers["content-disposition"]?.split("filename=")[1]
        var blob = new Blob([csvContent], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        var url = URL.createObjectURL(blob);
        pom.href = url;
        pom.setAttribute('download', filename ? filename : 'Report.xlsx');
        pom.click();
        this.setState({isLoader: false, datePicker : nextDate2, apiNextDate : nextDate3}, () => {
          setTimeout(() => {
            this.getCeleryReportExcel()
          }, 2000)
        })
      } else if (statusData?.status === 'fail') {
        isTaskComplete = true;
        this.setState({isLoader: false, datePicker : nextDate2, apiNextDate : nextDate3 }, () => {
          setTimeout(() => {
            this.getCeleryReportExcel()
          }, 2000)
        })
        this.errorMessage("Preperation Failed. Please try again")
      }
      await new Promise((resolve) => setTimeout(resolve, 5000)); // Poll every 2 seconds
    }}
  };

  getTallyReportExcel = () => {
    const { t } = this.props
    try {
      let states = this.state;
      var {datePicker2} = this.state
      let newDate = new Date(datePicker2)
      let apiStopDate = newDate.setDate(newDate.getDate() + 1)
      apiStopDate =  DateTime.fromJSDate((new Date(apiStopDate))).toFormat("yyyy-MM-dd")
      let maxday = DateTime.fromJSDate((new Date())).toFormat("yyyy-MM-dd")
      let repData = states.datePicker ? new Date(states.datePicker) : "";
      let tallyData = repData ? DateTime.fromJSDate(repData).toFormat("yyyy-MM-dd") : "";
      let nextDate = (repData && tallyData != maxday && apiStopDate != states.apiNextDate ) ? new Date(repData) : ""
      let nextDate2 = nextDate ? new Date(nextDate.setDate(nextDate.getDate() + 1)) : ""
      let nextDate3 = nextDate2 ? DateTime.fromJSDate(nextDate2).toFormat("yyyy-MM-dd") : ""
      if(tallyData === apiStopDate || nextDate === "" ){
        if(tallyData === apiStopDate){
          let ChangeDate = repData.setDate(repData.getDate() - 1)
          ChangeDate =  (new Date(ChangeDate))
          this.setState({
            datePicker: ChangeDate,
            apiNextDate: ""
          })
        }
      }else{
        this.setState({isLoader: true})
      RestAPIService.excelGetAccReport(`${states.reportType?.url}?date=${tallyData}`)
        .then((response) => {
          if (response.data) {
            var pom = document.createElement('a');
            var csvContent = response.data; //here we load our csv data 

            let filename = response.headers["content-disposition"].split("filename=")[1]

            var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
            var url = URL.createObjectURL(blob);
            pom.href = url;
            pom.setAttribute('download', filename ? filename : 'registry.xlsx');
            pom.click();
            if(nextDate){
              this.setState({isLoader: false, datePicker : nextDate2, apiNextDate : nextDate3 }, () => {
                setTimeout(() => {
                  this.getTallyReportExcel()
                }, 2000)
              })
            }else{
              this.setState({isLoader: false})
            }
            
          }
        }).catch(async(e) => {
          if(nextDate){
            this.setState({isLoader: false, datePicker : nextDate2, apiNextDate : nextDate3}, () => {
              setTimeout(() => {
                this.getTallyReportExcel()
              }, 2000)
            })
          }else{
            this.setState({isLoader: false})
          }
          if (e.response && e.response.data) {
            if (e.response.data instanceof Blob) {
              const errorMessage = await parseBlobError(e.response.data);
              if (errorMessage) {
                this.errorMessage(errorMessage);
              }
            } else if (e.response.data?.message) {
              this.errorMessage(e.response.data?.message)
            }
          } else {
            this.errorMessage(e.message)
          }
        })
      } 
    } catch (e) {
      let states = this.state;
      var {datePicker2} = this.state
      let newDate = new Date(datePicker2)
      let apiStopDate = newDate.setDate(newDate.getDate() + 1)
      apiStopDate =  DateTime.fromJSDate((new Date(apiStopDate))).toFormat("yyyy-MM-dd")
      let maxday = DateTime.fromJSDate((new Date())).toFormat("yyyy-MM-dd")
      let repData = states.datePicker ? new Date(states.datePicker) : "";
      let tallyData = repData ? DateTime.fromJSDate(repData).toFormat("yyyy-MM-dd") : "";
      let nextDate = (repData && tallyData != maxday && apiStopDate != states.apiNextDate ) ? new Date(repData) : ""
      let nextDate2 = nextDate ? new Date(nextDate.setDate(nextDate.getDate() + 1)) : ""
      let nextDate3 = nextDate2 ? DateTime.fromJSDate(nextDate2).toFormat("yyyy-MM-dd") : ""
      if(nextDate){
        this.setState({isLoader: false, datePicker : nextDate2, apiNextDate : nextDate3}, () => {
          this.getTallyReportExcel()
        })
      }else{
        this.setState({isLoader: false})
      }
      this.errorMessage(t("PrintError"))
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
    })
  }
  render() {
    var States = this.state
    return (
      <Box component={'div'} className='eMed_rts_container'>
        <Box component={"div"} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '79vh' }}>
          <Paper sx={{ width: '25vw', height: '24vw', backgroundColor: 'white', borderRadius: '.3vw' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography sx={{ fontSize: '1.1vw', fontWeight: 'bold', marginTop: '1vw' }}>Generate Report</Typography>
            </Box>
            <Box borderBottom={'1px solid lightgray'} marginTop={'1vw'}></Box>
            <Box sx={{ marginTop: '2vw', display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    open={this.state.DatePickerOpen}
                    onOpen={() => { this.setState({ DatePickerOpen: true }) }}
                    onClose={() => { this.setState({ DatePickerOpen: false }) }}
                    value={this.state.datePicker}
                    label='From Date'
                    minDate={new Date('2024-04-01')}
                    maxDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                    name={"datePicker"}
                    inputFormat='DD-MM-YYYY'
                    views={["year", "month", "day"]}
                    onChange={
                      (newDate) => {
                        this.setState({
                          datePicker: newDate,
                          datePicker2: newDate,
                        });
                      }}
                    renderInput={(params) =>
                      <TextField onKeyDown={(e) => e.preventDefault()} style={{ width: '18vw' }} size='small' {...params}
                        onClick={() => { this.setState({ DatePickerOpen: true }) }} />}
                  />
                </LocalizationProvider>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    open={this.state.DatePickerOpen2}
                    onOpen={() => { this.setState({ DatePickerOpen2: true }) }}
                    onClose={() => { this.setState({ DatePickerOpen2: false }) }}
                    value={this.state.datePicker2}
                    label='To Date'
                    minDate={new Date('2024-04-01')}
                    maxDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                    name={"datePicker2"}
                    inputFormat='DD-MM-YYYY'
                    views={["year", "month", "day"]}
                    onChange={
                      (newDate) => {
                        this.setState({
                          datePicker2: newDate,
                        });
                      }}
                    renderInput={(params) =>
                      <TextField onKeyDown={(e) => e.preventDefault()} style={{ width: '18vw', marginTop: '2vw' }} size='small' {...params}
                        onClick={() => { this.setState({ DatePickerOpen2: true }) }} />}
                  />
                </LocalizationProvider>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Autocomplete
                  size='small'
                  disablePortal
                  id="gen-autocom"
                  options={this.state.reportData}
                  getOptionLabel={(option) => option?.label}
                  onChange={(e, newValue) => {
                    this.setState({
                      reportType: newValue
                    })
                  }}
                  sx={{ width: '18vw', marginTop: '2vw' }}
                  renderInput={(params) => <TextField {...params} label="Type of Report" />}
                />
              </Box>
              <Box sx={{ marginTop: '2vw', display: 'flex', justifyContent: 'center' }}>
                <Button
                  size='small'
                  disabled={!this.state.reportType}
                  variant='contained' sx={{ textTransform: 'capitalize', width: '12vw' }}
                  onClick={() => {
                    if(!this.state.isLoader && !(States.reportType?.is_celery)){
                      this.getTallyReportExcel()
                    }else if(!this.state.isLoader && States.reportType?.is_celery){
                      this.getCeleryReportExcel()
                    }
                  }}>
                  {this.state.isLoader ? <CircularProgress  size={'1.5vw'} color='inherit' /> : 'Generate'}
                </Button>
              </Box>
            </Box>
          </Paper>
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
          <Loader loaderOpen={this.state.loader} />
      </Box>
    )
  }
}
export default withTranslation()(GenerateReport)