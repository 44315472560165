import { Autocomplete, Box, Button, Divider, Paper, Stack, TextField, Tooltip, Drawer, Typography, Dialog, IconButton, Card } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import "../PharmacyPurchase/PharmaPurchase.css"
import { ImagePaths } from '../../../Utility/ImagePaths';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { CommonGridToolBarWithFilterText } from '../../../Components/Common Components/CommonComponents';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';
import Loader from '../../../Components/Loader';
import { Colors } from '../../../Styles/Colors';
import { Details } from '@material-ui/icons';


let RoleData = null;
let userAccess = null;
let subUserAccess = null;

class PharmaISPoEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      pageSize: 10,
      rowCount: 0,
      WantedListData: [],
      VendorList: [],
      SelectedWantedList: [],
      searchkey: "",
      FilterOpen: false,
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: '',
      prevHistory: false,
      prevVendorData: {},
      isInformMsg: false,
      isInformMsgText: '',
      sendWantedListData : [],
      pharmacy_id: this.props.history?.location?.state?.pharmacy_id ? this.props.history?.location?.state?.pharmacy_id : null,
      pharmacy_name: this.props.history?.location?.state?.pharmacy_name ? this.props.history?.location?.state?.pharmacy_name : null,
      branch_name: this.props.history?.location?.state?.branch_name ? this.props.history?.location?.state?.branch_name : null,
      OnlyView: this.props.history?.location?.state?.OnlyView ? this.props.history?.location?.state?.OnlyView : null,
      pharmaId :null,
      isDisable : false,
    }
  }

  componentDidMount() {
    RoleData = JSON.parse(localGetItem("loggedInUserInfo"));
    let pharmacy_type = RoleData?.pharmacy_type === 'Inhouse Pharmacy' ? "Pharmacy" : "Standalone Pharmacy";
    userAccess = CheckAccessFunc(pharmacy_type, "Purchase", 'Wanted List', null, "Tab");
    subUserAccess = CheckAccessFunc(pharmacy_type, "Purchase", 'Wanted List', 'Order List', "SubTab");
    this.getPharmaISPOEntry()
  }



  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }
  infMessage = (message) => {
    this.setState({
      isInformMsg: true,
      isInformMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: '',
      isInformMsg: false,
      isInformMsgText: '',
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }
  getPharmaISPOEntry() {
    try {
      this.LoaderFunction(true);
      let searchKey = this.state.searchkey ? (this.state.searchkey?.replaceAll('&', '%26')).replaceAll(',', " ") : ""
      RestAPIService.getAll(Serviceurls.INTERNALSALES_WANTEDLISTDetail + "?search_key=" + (searchKey) + "&page=" + (this.state.page + 1) + "&page_size=" + this.state.pageSize + "&from_store=true&pharmacy_id=" + this.state.pharmacy_id).
        then((response) => {
          if (response.data.status === "success") {
            this.LoaderFunction(false);
            this.setState({
              WantedListData: response.data?.data,
              rowCount: response?.data?.total_count
            },()=>{
              const pharmaIds  =this.state.WantedListData[0]["pharmacy_id"]
              this.setState({
                pharmaId: pharmaIds
              });
            
            })
          }
          else {
            this.LoaderFunction(false);
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.LoaderFunction(false);
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }
  getPharmaISPOEntryPrint = () => {
    try {
      this.setState({ disableBtn: true })
      let searchKey = this.state.searchkey ? (this.state.searchkey?.replaceAll('&', '%26')).replaceAll(',', " ") : ""
      RestAPIService.getAll(Serviceurls.INTERNALSALES_WANTEDLISTDetail  + `?search_key=${searchKey}&page=${this.state.page + 1}&page_size=${this.state.pageSize}&from_store=true&pharmacy_id=${this.state.pharmacy_id}&export=pdf`)
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({ disableBtn: false })
        }).catch((error) => {
          this.errorMessage(error.message)
          this.setState({ disableBtn: false })
        })
    } catch (error) {
      this.errorMessage(error.message)
      this.setState({ disableBtn: false })
    }
  }
  
  getPharmaISPOEntryExcel = () => {
    const { t } = this.props;
    // let { pharmacy_id, bill_year } = this.state;
    // let billyear = bill_year ? new Date(bill_year).getFullYear() : today.getFullYear();
    try {
        this.setState({ disableBtn: true })
        let searchKey = this.state.searchkey ? (this.state.searchkey?.replaceAll('&', '%26')).replaceAll(',', " ") : ""
        RestAPIService.excelGet(`${Serviceurls.INTERNALSALES_WANTEDLISTDetail}?search_key=${searchKey}&page=${this.state.page + 1}&page_size=${this.state.pageSize}&from_store=true&pharmacy_id=${this.state.pharmacy_id}&export=${"excel"}`).
            then((response) => {
                if (response.data) {
                    var pom = document.createElement('a');
                    var csvContent = response.data; //here we load our csv data 

                    let filename = response.headers["content-disposition"].split("filename=")[1]

                    var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
                    var url = URL.createObjectURL(blob);
                    pom.href = url;
                    pom.setAttribute('download', filename ? filename : 'registry.xlsx');
                    pom.click();
                    this.setState({ disableBtn: false })
                }
            }).catch((error) => {
                this.errorMessage(error.response.data?.message)
                this.setState({ disableBtn: false })
            })
    } catch (error) {
        this.errorMessage(t("PrintError"))
        this.setState({ disableBtn: false })
    }
}

  handleTableCheckbox = (id) => {
    let states = this.state;
    let selectedIDs = new Set(id);
    let selectedRows = states["WantedListData"].filter((row) => selectedIDs.has(row.drug_id));   
    let FinalData = []
    selectedRows.forEach((item) => {
      // FinalData.push({
      //   "vendor_id": item?.vendor_id ? item?.vendor_id : null,
      //   "order_quantity": item?.qty ? +item?.qty : null,
      //   "stock_id": null,
      //   "drug_id": item?.drug_id,
      //   "serial_no": item?.serial_no ? item?.serial_no : null
      // })
      FinalData.push(item)
    })
    this.setState({ SelectedWantedList: FinalData })
  }


  AddtoWantedListCart = (item) => {
    let { SelectedWantedList } = this.state
    try {
      // let tempdata
      let datas = {
        // vendor_id: item?.vendor_id,
        // purchase_order_date: item.purchase_date,
        line_items: [],
        from_wanted_list: true,
        po_pharmacy_id: item?.pharmacy_id ?  item?.pharmacy_id : this.state.pharmaId ?this.state.pharmaId :null ,
        // gst_no: item.gst
      }
      const { t } = this.props

      if (item) { // individual select
       let tempdata = {
          // "vendor_id": item.vendor_id,
          "order_quantity": +item?.qty,
          // "stock_id": null,
          "drug_ms_id": item?.drug_id,
        };

        
           item["drug_ms_id"] = +item?.drug_id
           item["order_quantity"] = +item?.qty
           item["free_quantity"] = +item?.free_qty

        // item.push(tempdata)
        datas['line_items']?.push(item);
        // datas['line_items']?.push(tempdata);

      } else if (SelectedWantedList.length > 0) { // Bulk select
        SelectedWantedList.forEach((item)=>{
          item["free_quantity"] = +item?.free_qty
          item["order_quantity"] = +item?.qty
          item["drug_ms_id"]  =  item.drug_id
        })
        datas["line_items"] = [...SelectedWantedList];
        // datas["wanted_list"] = [...SelectedWantedList];
      }
      // let data = {
      //   vendor_id: item?.vendor_id,
      //   purchase_order_date: item.purchase_date,
      //   line_items: item,
      //   wanted_list: true,
      //   gst_no: item.gst
      // }
      // if(datas?.wanted_list?.length > 0){
      //   console.log("wanted_lis",datas.wanted_list)
      //    this.props.history.push({pathname :"/PharmacyInternalSales/RequestList/RequestDetail", state : { sendWantedListData : datas.wanted_list}})
      // }

      if (datas?.line_items?.length > 0) {
        this.setState({ disableBtn: true })
        RestAPIService.create(datas, Serviceurls.PURCHASE_PLACEORDER_POST)
          .then(response => {
            if (response.data.status == "success") {
              this.successMessage(response.data.message)
              this.setState({ disableBtn: false ,isDisable : false})
              let wantedData = {
                pharmacy_id: this.state.pharmacy_id,
                pharmacy_name: this.state.pharmacy_name,
                branch_name: this.state.branch_name,
                OnlyView: this.state.OnlyView
              }
              this.props.history.push({ pathname: "/PharmacyInternalSales/RequestList/RequestDetail", state: { poID: response.data.po_id, isFromWanted: true, wantedData: wantedData } })
            }
          })
          .catch(error => {
            if (error?.response?.data?.status === "fail") {
              this.errorMessage(error.response.data.message)
              this.setState({isDisable : false})
            } else {
              this.errorMessage(error.message)
              this.setState({isDisable : false})
            }
            this.setState({ disableBtn: false })
            this.setState({isDisable : false})
          })
      } else {
        this.errorMessage(t("KindlyaddQuantity"));
        this.setState({isDisable : false})
      }
    }
    catch (e) {
      this.setState({ disableBtn: false })
      this.errorMessage(e.message)
      this.setState({isDisable : false})
    }
  }

  // closeFilter = () => {
  //   this.setState({ FilterOpen: false })
  // }
  gridToolBar = () => {
    const { t } = this.props;
    return (
      <Box>
        <CommonGridToolBarWithFilterText
        //  searchHolder = {t("searchBrandName")}
        />
      </Box>
    )
  }
  renderCard = () => {
    return (
      <Card component={'div'} className='doc_header_card' display={"flex"} flexDirection={"row"} justifyContent={"spaceBetween"} alignItems={"spaceBetween"} height={"12vh"} width={"8vw"} >
        <Box component={'div'} padding={"2px"} display={"flex"} flexDirection={"column"} justifyContent={"spaceBetween"} >
          <Typography fontWeight={"bold"} fontSize={"1vw"}>{"Branch Name"}</Typography>
          <Typography>{this.state.branch_name}</Typography>
        </Box>

        <Box component={'div'} display={"flex"} flexDirection={"column"} justifyContent={"spaceBetween"} ml={"2vw"}>
          <Typography fontWeight={"bold"} fontSize={"1vw"}>{"Pharmacy Name"}</Typography>
          <Typography>{this.state.pharmacy_name}</Typography>

        </Box>
      </Card >
    )
  }
  // renderFilterScreen = () => {
  //   const { t } = this.props;
  //   let states = this.state;
  //   return (
  //     <Box component={"div"} className='eMed_rts_filter_container'>
  //       <Box component={"div"} className='eMed_filter_Top'>
  //         <Typography variant='h6' fontWeight={600}>{t('Filter')}</Typography>
  //         <Button emed_tid='want_close' onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
  //           <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
  //         </Button>
  //       </Box>
  //       <Box className='eMed_rts_middle'>
  //         <Box className='eMed_rts_dropdown'>
  //           <TextField
  //             label={t("BrandName")}
  //             sx={{ width: "20vw" }}
  //             size="small"
  //             inputProps={{ emed_tid: 'BrandName' }}
  //             autoComplete="off"
  //             value={states.searchkey ? states.searchkey : ""}
  //             onChange={(e) => {
  //               this.setState({
  //                 searchkey: e.target.value.trimStart()
  //               })
  //             }}
  //           />
  //         </Box>
  //       </Box>
  //       <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
  //         <Button emed_tid='fil_clear' variant='outlined' size="small"
  //           onClick={() => {
  //             this.setState({
  //               searchkey: "",
  //             }, () => this.GetWantedList())
  //           }}
  //           id="eMed_Config_btn">{t("Clear")}</Button>
  //         <Button emed_tid='fil_search' variant='contained' size="small"
  //           onClick={() => {
  //             this.GetWantedList()
  //             this.setState({ FilterOpen: false })
  //           }}
  //           id="eMed_Config_btn">{t("Search")}</Button>
  //       </Stack>
  //     </Box>
  //   )
  // }
  renderTable = () => {
    this.state.WantedListData.forEach((element, index) => {
      let page = this.state.page
      let pageSize = this.state.pageSize
      let serial = (page * pageSize) + (index + 1)
      element.serial_no = serial
    })
    const { t } = this.props
    const columns = [
      {
        field: "serial", flex: 0.04, headerName: t("SNo"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.serial_no ? params?.row?.serial_no : "-"}</Box>)
      },
      {
        field: "drug_name", flex: 0.15, headerName: t("Brand Name"),
        renderCell: (params) => (params?.row?.drug_name?.length > 23 ?
          <Tooltip placement="top" title={params?.row?.drug_name} arrow><div>{params?.row?.drug_name.slice(0, 22) + "..."}</div></Tooltip>
          : <Box component={'div'}>{params?.row?.drug_name ? params?.row?.drug_name : "-"}</Box>)
      },
      {
        field: "generic_name", flex: 0.15, headerName: t("Generic Name"),
        renderCell: (params) => (params?.row?.generic_name?.length > 23 ?
          <Tooltip placement="top" title={params?.row?.generic_name} arrow><div>{params?.row?.generic_name.slice(0, 22) + "..."}</div></Tooltip>
          : <Box component={'div'}>{params?.row?.generic_name ? params?.row?.generic_name : "-"}</Box>)
      },
      {
        field: "dosage_type", flex: 0.15, headerName: t("Dosage Type / Strength"), headerAlign: "center", align: "center",
        renderCell: (params) => {
          let dosage = `${params?.row?.dosage_type ? params?.row?.dosage_type : "-"} / ${params?.row?.drug_strength ? params?.row?.drug_strength : "-"}`
          return (dosage?.length > 23 ?
            <Tooltip placement="top" title={dosage} arrow><div>{dosage.slice(0, 22) + "..."}</div></Tooltip>
            : <Box component={'div'}>{dosage ? dosage : "-"}</Box>)
        }
      },
      {
        field: "available_quantity", flex: 0.1, headerName: t("Avail.Qty"), type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.available_quantity ? params?.row?.available_quantity : "-"}</Box>)
      },
      {
        field: "mrp_per_quantity", flex: 0.1, headerName: t("Latest MRP/Qty"), type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.mrp_per_quantity ? params?.row?.mrp_per_quantity : "-"}</Box>)
      },

      {
        field: "qty", flex: 0.1, headerName: t("Order Qty/Unit"),  headerAlign: "right",  type:'number',
        renderCell: (params) => (<Box component={'div'} emed_tid={`want_ordqty_${params.id}`}>
          <TextField
            inputProps={{
              style: {
                height: "2vw",
                padding: '0 14px',
              },
            }}
            disabled={!userAccess?.editAccess}
            autoComplete='off'
            value={params.row?.qty ? params.row?.qty : ""}
            onChange={(e) => {
              let number = CommonValidation.numberOnly(e.target.value);
              if (number || e.target.value === "") {
                this.state.WantedListData[params.row.serial_no - 1]["qty"] = +e.target.value
                this.setState({
                  WantedListData: this.state.WantedListData
                })
              }

            }}
            className='eMed_DataGrid_TextField' />
        </Box>)
      }
    ]

    if (userAccess?.editAccess) {
      columns.push(
        {
          field: "action", flex: 0.07, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false,
          renderCell: (params) =>
            <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
              <Tooltip title={t("Send Drug")} placement="top" arrow>
                <Button emed_tid='want_moveord' disabled={this.state.disableBtn || this.state.isLoader} className='eMed_usrconf_btn' onClick={() => { this.setState({ disableBtn: true }, () => { this.AddtoWantedListCart(params?.row) }) }}>
                  <img src={ImagePaths.PharmaPurchase.default} alt="cart" className='eMed_action_img' />
                </Button></Tooltip>
            </Box>
        }
      );
    }

    return (
      <Box component={"div"} className='eMed_Purcahse_Table_Container' height={"62vh"}  >
        <Stack component={"div"}>
          <Paper className='eMed_Purchase_Table_Wrapper' >
            <DataGrid
              checkboxSelection={userAccess?.editAccess}
              onSelectionModelChange={(id) => this.handleTableCheckbox(id)}
              rows={this.state.WantedListData}
              columns={columns}
              getRowId={(row) => row['drug_id']}
              page={this.state.page}
              pageSize={this.state.pageSize}
              hideFooterSelectedRowCount
              localeText={{
                toolbarColumns: "",
                toolbarDensity: "",
                toolbarExport: "",
                toolbarFilters: "",
                toolbarExportPrint: ""
              }}
              headerHeight={40}
              components={{
                Toolbar: this.gridToolBar,
                NoRowsOverlay: () => (
                  <Stack className='eMed_conf_nodata'>
                    {t("NoRecordsFound")}
                  </Stack>
                )
              }}
              rowsPerPageOptions={[10, 20, 30]}
              disableSelectionOnClick
              pagination
              paginationMode='server'
              onPageChange={(newPage) => { this.setState({ page: newPage }, () => this.getPharmaISPOEntry()) }}
              onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize }, () => this.getPharmaISPOEntry())}
              rowCount={this.state.rowCount}
            // filterMode="server"
            // onFilterModelChange={this.onFilterChange}
            />
          </Paper>
        </Stack>
      </Box>
    )
  }


  render() {
    // console.log("wantedData",this.state.datas.wanted_list)
    const { t } = this.props;
    return (

      <Box   >
        <Box className="eMed_Add_vendor_page_top2">
          <Stack direction="row" spacing={1} alignItems="center"><IconButton size="small" emed_tid="addvnd_bk" onClick={() => {
            this.props.history.push({ pathname: '/PharmacyInternalSales/WantedList' })
          }} >
            <img className="emedhub_popupIcon_img" src={ImagePaths.LabBackButton.default} alt={"Back"} /></IconButton><Typography sx={{ color: "#616161" }}> {t("Back To Wanted List  ")}</Typography>
          </Stack>
        </Box>

        <Box className="eMed_Patient_Card" sx={{ width: '26vw' }} marginLeft={"0.8vw"} >

          <Box component={'div'} className="eMed_Pateint_Detail_LongData">
            <Box component={'div'} className='eMed_patient_txt' >
              <Typography fontWeight={600} fontSize={"0.9vw"}>{"Branch Name"}</Typography>
              <Typography fontSize={"0.7vw"}  >{this.state.branch_name}</Typography>
            </Box>

            <Box component={'div'} className='eMed_patient_txt'>
              <Typography fontWeight={600} fontSize={"0.9vw"}>{"Pharmacy Name"}</Typography>
              <Typography fontSize={"0.7vw"} >{this.state.pharmacy_name}</Typography>
            </Box>
          </Box>

        </Box>



        {/* <Box display={"flex"} margin={"0.1vh"} mt={"1vh"} ml={"1vw"} alignItems={"flex-start"} justifyContent={"spaceBetween"}  >
          {this.renderCard()}

        </Box> */}


        <div className='eMed_Purchase_Table_top' style={{ justifyContent: 'flex-end', marginTop: "-5vh" }}    >
          {/* <div>
            <Button emed_tid='want_movelst' disabled = {this.state.isLoader} className='eMed_Filter_Btns' variant='contained' size='small' sx={{ display: this.state.SelectedWantedList.length > 0 ? "block" : "none" }} 
            onClick={() => { this.AddtoWantedListCart() }}
            >{`Move to Order (${this.state.SelectedWantedList?.length})`}</Button>
          </div> */}
          <div className='eMed_Purchase_Table_top' style={{ justifyContent: 'flex-end' }} >
            {subUserAccess?.viewAccess ?
              <>
                <Button emed_tid='want_ordlst' className='eMed_Filter_Btns' variant='contained' size='small'
                  disabled ={this.state.SelectedWantedList?.length === 0 || this.state.isDisable}
                  onClick={() => { 
                    this.setState({
                       isDisable: true
                    },()=>{this.AddtoWantedListCart()})
                    
                    // this.props?.history?.push({ pathname: '/PharmacyInternalSales/RequestList/RequestDetail' }) 
                    }}
                >{`Send Drug`}</Button>
              </>
              : null}


            {/* <Tooltip title="Filter" placement='top' arrow>
              <Button emed_tid='want_filter' className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
              </Button>
            </Tooltip> */}
            <Tooltip title="Print" placement='top' arrow>
              <Button emed_tid='want_export' className='eMed_usrconf_btn' disabled={this.state.disableBtn} onClick={() => this.getPharmaISPOEntryPrint()}>
                <img src={ImagePaths.LabPrintIcon.default} alt="upload" className='eMed_action_img' />
              </Button></Tooltip>
            <Tooltip title="Export" placement='top' arrow>
              <Button emed_tid='want_export' className='eMed_usrconf_btn' disabled={this.state.disableBtn} onClick={() => this.getPharmaISPOEntryExcel()}>
                <img src={ImagePaths.LabUploadButton.default} alt="upload" className='eMed_action_img' />
              </Button></Tooltip>
          </div>
        </div>
        {this.renderTable()}
        {/* <Drawer
          anchor={'right'}
          open={this.state.FilterOpen}
          ModalProps={{ onBackdropClick: this.closeFilter }}
        >
          {this.renderFilterScreen()}
        </Drawer> */}
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.isInformMsg ?
          <ToastMsg
            severity={'warning'}
            msg={this.state.isInformMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>

    )
  }
}
export default withTranslation()(PharmaISPoEntry)