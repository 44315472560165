import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TriggerApiOnView from './TriggerApiOnView'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { getCachevalue } from '../../../../Utility/Services/CacheProviderService';

export default function CollabSurgeryNotes(props) {
  const [patient_id, setpatient_id] = useState(null)
  const [surlayout, setsurlayout] = useState([]);
  const [surNotes, setsurNotes] = useState([]);
  const [errorMsg, seterrorMsg] = useState(false);
  const [errorMsgText, seterrorMsgText] = useState('');
  const [clinicID, setclinicID] = useState(null);
  const [valSurNote, setvalSurNote] = useState({});

  const { id, isProgrammaticScroll, userData, details } = props;

  useEffect(() => {
    try {
      const cachedData = getCachevalue('DoctorPatientData');
      const appointData = JSON.parse(cachedData);
      const clinicId = getCachevalue('SelectedDoctorClinic')
      if (appointData?.patient_id) {
        setpatient_id(appointData.patient_id);
      }
      if (clinicId) {
        setclinicID(clinicId)
      }
    } catch (error) {
      errorMessage(error.message)
    }
  }, []);

  const msgClose = () => {
    seterrorMsg(false);
    seterrorMsgText('');
  }
  const errorMessage = (msg) => {
    seterrorMsg(true)
    seterrorMsgText(msg)
  }

  const getSurgeryLayout = () => {
    try {
      RestAPIService.getAll(Serviceurls.SUR_NOTES_LAYOUT + `?clinic_id=${clinicID}&ip_admission_id=${details?.admission_id}`)
        .then(response => {
          if (response.data.status === 'success') {
            setsurlayout(response.data.data || [])
            getSurgeryData(response.data.data)
          }
        }).catch(e => {
          if (e?.response?.data?.status === 'fail') {
            errorMessage(e.response.data.message)
          } else {
            errorMessage(e.message)
          }
        })
    } catch (e) {
      errorMessage(e.message)
    }
  }
  const getSurgeryData = (layout) => {
    try {
      RestAPIService.getAll(Serviceurls.IP_SN_CREATE + `?clinic_id=${clinicID}&ip_admission_id=${details?.admission_id}&patient_id=${patient_id}`)
        .then(response => {
          if (response.data.status === 'success') {
            setsurNotes(response.data.data)
            appendData(response.data.data, layout)
          }
        }).catch(e => {
          if (e?.response?.data?.status === 'fail') {
            errorMessage(e.response.data.message)
          } else {
            errorMessage(e.message)
          }
        })
    } catch (e) {
      errorMessage(e.message)
    }
  }

  const appendData = (data, layout) => {
    if (data?.data?.length == 1) {
      var tem_json = data?.data[0]?.template_data
      var data1 = layout.filter((item) => (item.id == data?.data[0]?.layout_id))
      var keys = {}
      data1[0]?.surgery_json?.forEach((element) => {
        keys[element.field_name] = tem_json[element.field_name]
      })
      setvalSurNote(keys)
    }
  };

  const getSurgerNotes = (section) => {
    const idKey = "ip_admission_id=" + details?.admission_id
    const query = `?patient_id=${patient_id}&${idKey}&clinic_id=${clinicID}`;
    switch (section) {
      case "surgerynotes":
        getSurgeryLayout()
        break;
      default:
        break;
    }
  };
  let datalabel = []
  if(surlayout?.length > 0 && surNotes?.data?.length > 0){
    datalabel = surlayout.filter((item) => (item.id == surNotes?.data[0]?.layout_id))
  }
  

  return (
    <Box marginTop={'1vw'}>
      <TriggerApiOnView id={`surgerynotes_${id}`} onEnterView={(id) => getSurgerNotes(id)} isProgrammaticScroll={isProgrammaticScroll}>
        <Box id={`surgerynotes_${id}`} marginLeft={'1vw'}>
          <Typography fontWeight={'bold'} fontSize={'1vw'}>Surgery Notes</Typography>
          {datalabel?.length === 1 ? datalabel[0]?.surgery_json?.map((item) => {
            let vals = item.field_name
            return (
              <Box marginTop={'.5vw'} >
                <Typography fontSize={'1vw'} fontWeight={'bold'}>{item.label}</Typography>
                <Typography>{valSurNote[vals] || '-'}</Typography>
              </Box>
            )

          }) : <Box className='eMed_DisSum_Tem_Nolayout'>{'-'}</Box>}
        </Box>
      </TriggerApiOnView>
      {errorMsg && <ToastMsg
        severity={'error'}
        msg={errorMsgText}
        msgPop={msgClose.bind(this)}
      />}
    </Box>
  )
}
