import { Box, Button, Grid, Paper, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import CommonGridHeader, { CommonDeleteButton, CommonEditButton, DeletePopup } from '../../../Components/Common Components/CommonComponents';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { Colors } from '../../../Styles/Colors';
import '../Configuration.css';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';
class RefferalStaff extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            employeeName: "",
            hospitalName: "",
            designation: "",
            mobileNumber: "",
            employeeCode: "",
            designation: "",
            refStaffData: [],
            deletePopup: false,
            rowValue: null,
            selectedId: null,
            commission: "",
            disablebtn :false,
            delSelectedId : null,
        }
    }

    componentDidMount() {
        let userAccess = CheckAccessFunc("front_office", "Settings", "Referral Configuration", "Referral Staff", "SubTab")?.editAccess
        this.setState({userEditAccess: userAccess})
        this.getStaffRefferalData()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message,
            disablebtn: false
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    LoaderFunction = (key) => {
        this.setState({
          isLoader: key
        })
      }

    inputTextBox(label, value, name, fullWidth, rows, widths) {
        const { t } = this.props
        return (
            <TextField
                size='small'
                multiline
                rows={rows}
                fullWidth={fullWidth}
                inputProps={{ maxLength: 100 }}
                label={label === "Commission" ? `${t(label)} (%)` : `${t(label)} *`}
                name={name}
                value={value}
                onChange={(e) => this.onChangeHandler(e)}
                className="eMed_refdoc_txtbox"
                sx={{ width: widths, margin: "0.3vw" }}
            />
        )
    }

    onChangeHandler = (event) => {
        try {
            const { name, value } = event.target
            let alpha = CommonValidation.alphabetOnly(value);
            let number = CommonValidation.numberOnly(value);
            let regNum = CommonValidation.alphanumValidation(value);
            switch (name) {
                case "employeeName":
                    if (alpha || value === "") {
                        this.setState({ employeeName: value })
                    }
                    break;
                // case "designation":
                //     if (alpha || value === "") {
                //         this.setState({ designation: value })
                //     }
                //     break;
                case "designation":
                    if (alpha || value === "") {
                        this.setState({ designation: value })
                    }
                    break;
                // case "hospitalName":
                //     if (alpha || value === "") {
                //         this.setState({ hospitalName: value })
                //     }
                //     break;
                case "employeeCode":
                    if (regNum && value.length <= 30 || value === "") {
                        this.setState({ employeeCode: value })
                    }
                    break;
                case "mobileNumber":
                    if ((number && value.length <= 10) || value === "") {
                        this.setState({ mobileNumber: value })
                    }
                    break;
                case "commission":
                    if (value <= 100) {
                        this.setState({ commission: value })
                    }
                    break;
                default:
                    this.setState({ [name]: value })
                    break
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }


    onEditHandler = (value) => {
        const { t } = this.props
        try {
            this.setState({
                isEdit: true,
                employeeName: value?.row?.name,
                mobileNumber: value?.row?.mobile_no,
                employeeCode: value?.row?.emp_code,
                designation: value?.row?.designation,
                selectedId: value?.row?.id,
                commission: value?.row?.commission_percent
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onDeleteHandler = (value) => {
        if (!this.state.isEdit) {
            this.setState({
                deletePopup: true,
                rowValue: value?.row,
                delSelectedId: value?.row?.id
            })
        }
    }

    popupClose = () => {
        this.setState({ deletePopup: false })
    }

    onClearHandler = () => {
        this.setState({
            employeeName: "", 
            designation: "",
            employeeCode: "",
             mobileNumber: "", 
             commission: ""
        })
    }

    getStaffRefferalData = () => {
        const { t } = this.props
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.FO_CONFIG_REF_Config).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            refStaffData: response.data.data
                        },()=>{this.LoaderFunction(false)})
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    postReferralDoctorData = () => {
        const { t } = this.props
        try {
            let data = {
                "name": CommonValidation.removeSpace(this.state.employeeName),
                "mobile_no": this.state.mobileNumber,
                "emp_code": this.state.employeeCode,
                "designation": CommonValidation.removeSpace(this.state.designation),
                // "clinic_name": CommonValidation.removeSpace(this.state.hospitalName),
                "commission_percent": this.state.commission ? +this.state.commission : null
            }
            if (this.state.isEdit) {
                data['id'] = this.state.selectedId
            }

            if (data.name === "" || data.mobile_no === "" || data.emp_code === "" 
                  || data.designation === ""  ) {
                this.errorMessage(t("ManitoryFields"))
            } else if (data.mobile_no.length < 10) { this.errorMessage(t("EnterMobile")) }
            else {
                this.LoaderFunction(true)
                RestAPIService.create(data, Serviceurls.FO_CONFIG_REF_Config).
                    then((response) => {
                        if (response.data.status === 'success') {
                            this.onClearHandler()
                            this.getStaffRefferalData()
                            this.setState({ isEdit: false, disablebtn: false })
                            this.LoaderFunction(false)
                            this.successMessage(response.data.message)
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.status === "fail") {
                            this.LoaderFunction(false)
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.LoaderFunction(false)
                            this.errorMessage(error.message)
                        }
                    })
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    deleteRefferalStaffData = () => {
        const { t } = this.props
        try {
            RestAPIService.delete(Serviceurls.FO_CONFIG_REF_Config + "?id=" + this.state.delSelectedId).
                then((response) => {
                    if (response.data.status === "success") {
                        this.successMessage(response.data.message)
                        this.getStaffRefferalData()
                        this.setState({ deletePopup: false })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })

        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderRefStaffLeft = () => {
        this.state.refStaffData.forEach((element, index) => element.sno = index + 1)
        const { t } = this.props
        const columns = [
            { field: "sno", sortable: false, flex: 0.05, headerName: t("SNo") },
            {
                field: "name", flex: 0.131, headerName: t("Employee Name"),
                renderCell: (params) => (<Box component={'div'}>{params?.row?.name ? params?.row?.name?.length > 10 ?
                    <Tooltip placement="top" title={params?.row?.name} arrow><div>{params?.row?.name.slice(0, 10) + "..."}</div></Tooltip> : params?.row?.name : "-"}</Box>)
            },
            {
                field: "emp_code", flex: 0.136, headerName: t("Employee code"), type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.emp_code ? params?.row?.emp_code : "-"}</Box>)
            },
            {
                field: "designation", flex: 0.131, headerdesignation: t("Designation"),
                renderCell: (params) => (<Box component={'div'}>{params?.row?.designation ? params?.row?.designation?.length > 10 ?
                    <Tooltip placement="top" title={params?.row?.designation} arrow><div>{params?.row?.designation.slice(0, 10) + "..."}</div></Tooltip> : params?.row?.designation : "-"}</Box>)
            },
            {
                field: "mobile_no", flex: 0.131, headerName: t("MobileNumber"),
                renderCell: (params) => (<Box component={'div'}>{params?.row?.mobile_no ? params?.row?.mobile_no : "-"}</Box>)
            },
            {
                field: "commission_percent", flex: 0.081, headerName: t("Commission"),
                renderCell: (params) => (<Box component={'div'}>{params?.row?.commission_percent ? params?.row?.commission_percent :
                    params?.row?.commission_percent === 0 ? "0" : "-"}</Box>)
            },
            {
                field: "action", flex: 0.102, headerName: t("Action"), headerAlign: 'center', align: 'center', sortable: false, hideable: false,
                renderCell: (params) => <div>
                    <CommonEditButton disable = {!this.state.userEditAccess }size={"1.5vw"} onClick={this.onEditHandler.bind(this, params)} />
                    <CommonDeleteButton disable = {!this.state.userEditAccess} size={"1.5vw"} onClick={this.onDeleteHandler.bind(this, params)} />
                </div>
            }
        ]
        return (
            <Stack component={"div"}>
                <Paper className='eMed_refdoc_left' >
                    <DataGrid
                        rows={this.state.refStaffData}
                        columns={columns}
                        getRowId={(row) => row['id']}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: CommonGridHeader.CommonToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    clinic_name: false
                                }
                            }
                        }}
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                    />
                </Paper>
            </Stack>
        )
    }

    renderRefStaffRight = () => {
        const { t } = this.props
        return (
            <Stack component={"div"}>
                <Paper className='eMed_refdoc_right' elevation={1}>
                    <Typography className='eMed_refdoc_title'><strong>{t("refDoctor")}</strong></Typography>
                    <Stack height={"60vh"}>
                        <Grid container className='eMed_refdoc_txt_card'>
                            <Grid item xs={12}>
                                {this.inputTextBox("Employee Name", this.state.employeeName, "employeeName", false, 1, "27vw")}
                            </Grid>
                            <Grid item xs={6}>
                                {this.inputTextBox("Employee Code", this.state.employeeCode, "employeeCode", false, 1,)}
                            </Grid>
                            <Grid item xs={6}>
                                {this.inputTextBox("Designation", this.state.designation, "designation", false, 1)}
                            </Grid>
                            <Grid item xs={6}>
                                {this.inputTextBox("MobileNumber", this.state.mobileNumber, "mobileNumber", false, 1)}
                            </Grid>
                            <Grid item xs={6}>
                                {this.inputTextBox("Commission", this.state.commission, "commission", false, 1)}
                            </Grid>
                        </Grid>
                    </Stack>
                    <Stack spacing={2} direction="row" className='eMed_ref_btn' sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center">
                        <Button disabled = {!this.state.userEditAccess} variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.onClearHandler() }}>{t("Clear")}</Button>
                        <Button disabled = {!this.state.userEditAccess || this.state.disablebtn} variant='contained' size="small" id="eMed_Config_btn"
                             onClick={() => { 
                                this.setState({disablebtn:true},()=>{
                                    this.postReferralDoctorData()
                                })
                                 }}>{this.state.isEdit ? t("Update") : t("Add")}</Button>
                    </Stack>
                </Paper>
            </Stack>
        )
    }
    msgClose(){
        this.setState({
          isErrorMsg: false,
          isErrorMsgText: '',
          successMsg: false,
          successMsgText: ''
        })
      }
    renderMessageFields = () => {
        const { t } = this.props
        return (
            <>
                {this.state.deletePopup ? <DeletePopup
                    DeletTitle={`${t("deleteMsg")}  ${this.state.rowValue?.name ? this.state.rowValue?.name : "-"} ?`}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this.deleteRefferalStaffData.bind(this)}
                    DeleteNotify={t("effectEntireConfig")}
                /> : null}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </>
        )
    }

    render() {
        const { t } = this.props
        return (
            <Box component={"div"} className='eMed_refdoc_container'>
                <Grid container spacing={2} className='eMed_refdoc_content' >
                    <Grid item xs={8}>
                        {this.renderRefStaffLeft()}
                    </Grid>
                    <Grid item xs={4}>
                        {this.renderRefStaffRight()}
                    </Grid>
                </Grid>
                {this.renderMessageFields()}
            </Box>
        )
    }
}

export default withTranslation()(RefferalStaff)