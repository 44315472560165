import { Box, Button, Divider, Paper, Stack, TextField, Tooltip, Drawer, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import "./PharmaPurchase.css"
import { ImagePaths } from '../../../Utility/ImagePaths';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import {CommonGridToolBarWithFilterText} from '../../../Components/Common Components/CommonComponents';
import { formatDate, CheckAccessFunc } from "../../../Components/CommonFunctions/CommonFunctions";
import { localGetItem } from '../../../Utility/Services/CacheProviderService';

let RoleData = null;
let userAccess = null;

class PharmsVendorList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      VendorList: [],
      searchkey: "",
      page: 0,
      pageSize: 10,
      rowCount: 0,
      internal_store_enabled : false,
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  componentDidMount() {
    let internalStoreEnabled = JSON.parse(localGetItem("internalStoreEnabled"))
    RoleData = JSON.parse(localGetItem("loggedInUserInfo"));
    userAccess = CheckAccessFunc(RoleData?.pharmacy_type === 'Inhouse Pharmacy'? "Pharmacy" : "Standalone Pharmacy", "Purchase", 'Vendor List', null, "Tab");
    this.VendorList()
    if(this.props.history.location.state?.addVendorSuccessMsg){
      this.successMessage(this.props.history.location.state?.addVendorSuccessMsg)
    }
    this.setState({
      internal_store_enabled : internalStoreEnabled
    })
     
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  VendorList() {
    try {
      this.LoaderFunction(true);
      RestAPIService.getAll(Serviceurls.PURCHASE_VENDOR_LIST + "?search_term="+ (this.state.searchkey) + "&page=" + (this.state.page + 1) + "&page_size=" + this.state.pageSize).
        then((response) => {
          if (response.data.status === "success") {
            this.LoaderFunction(false);
            this.setState({
              VendorList: response.data.data?.result,
              rowCount: response.data.data?.total_count
            })
          }
          else {
            this.LoaderFunction(false);
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.LoaderFunction(false);
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  printVendorList = () => {
    try {
      this.setState({disableBtn: true})
      RestAPIService.getAll(`${Serviceurls.PURCHASE_VENDOR_LIST}?export=${"pdf"}&search_term=${this.state.searchkey}`).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({disableBtn: false})
        }).catch((error) => {
          if (error.response?.data?.message) {
            this.errorMessage(error.response?.data?.message);
          } else {
            this.errorMessage(error.message);
          }
          this.setState({disableBtn: false})
        })
    } catch (error) {
      this.errorMessage(error.message)
      this.setState({disableBtn: false})
    }
  }

  getVendorListExcel = () => {
    const { t } = this.props;
    try {
      this.setState({disableBtn: true})
      RestAPIService.excelGet(`${Serviceurls.PURCHASE_VENDOR_LIST}?export=${"excel"}&search_term=${this.state.searchkey}`).
        then((response) => {
          if (response.data) {
            var pom = document.createElement('a');
            var csvContent = response.data; //here we load our csv data 

            let filename = response.headers["content-disposition"].split("filename=")[1]

            var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
            var url = URL.createObjectURL(blob);
            pom.href = url;
            pom.setAttribute('download', filename ? filename : 'registry.xlsx');
            pom.click();
            this.setState({disableBtn: false})
          }
        }).catch((error) => {
          this.errorMessage(error.response.data?.message)
          this.setState({disableBtn: false})
        })
    } catch (error) {
      this.errorMessage(t("PrintError"))
      this.setState({disableBtn: false})
    }
  }

  closeFilter = () => {
    this.setState({ FilterOpen: false })
  }

  renderFilterScreen = () => {
    const { t } = this.props;
    let states = this.state;
    return (
      <Box component={"div"} className='eMed_rts_filter_container'>
        <Box component={"div"} className='eMed_filter_Top'>
          <Typography variant='h6' fontWeight={600}>{t('Filter')}</Typography>
          <Button emed_tid= "vnd_close" onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
          </Button>
        </Box>
        <Box className='eMed_rts_middle'>
          <Box className='eMed_rts_dropdown'>
            <TextField
              label={t("VendorName")}
              sx={{ width: "20vw" }}
              size="small"
              autoComplete="off"
              value={states.searchkey ? states.searchkey : ""}
              inputProps={{ emed_tid: "VendorName" }}
              onChange={(e) => {
                this.setState({
                  searchkey: e.target.value.trimStart()
                })
              }}
            />
          </Box>
        </Box>
        <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
          <Button variant='outlined' size="small" emed_tid= "fil_clear"
            onClick={() => {
              this.setState({
                searchkey: "",
              }, () => this.VendorList())
            }}
            id="eMed_Config_btn">{t("Clear")}</Button>
          <Button variant='contained' size="small" emed_tid= "fil_search"
            onClick={() => {
              this.VendorList()
              this.setState({ FilterOpen: false })
            }}
            id="eMed_Config_btn">{t("Search")}</Button>
        </Stack>
      </Box>
    )
  }

  onFilterChange = (filterModel) => {
    if(filterModel?.quickFilterValues[0]?.length >= 2 || filterModel?.quickFilterValues?.length === 0 ){
      this.setState({
        searchkey: filterModel?.quickFilterValues[0] ? filterModel?.quickFilterValues[0] : ""
      }, () =>  {
        this.VendorList();
      })
    }
  }

  gridToolBar = () => {
    const {t} = this.props;
    return (
      <Box>
        <CommonGridToolBarWithFilterText  searchHolder = {t("searchVendorName")}/>
      </Box>
    )
  }

  renderTable = () => {
    this.state.VendorList.forEach((element, index) => element.sno = index + 1)
    this.state.VendorList.forEach((element, index) => { 
      let page = this.state.page 
      let pageSize = this.state.pageSize
      let serial = (page * pageSize) + (index + 1) 
      element.serial = serial
     })
    const { t } = this.props
    const columns = [
      {
        field: "serial", flex: 0.03, headerName: t("SNo"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.serial ? params?.row?.serial : "-"}</Box>)
      },
      {
        field: "registration_date", flex: 0.14, headerName: t("Registration Date"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.registration_date ? formatDate(params?.row?.registration_date) : "-"}</Box>)
      },
      {
        field: "name", flex: 0.14, headerName: t("Vendor Name"), headerAlign: "left", align: "left",
        renderCell: ({ row }) => (<div>{row?.name?.length > 23 ?
          <Tooltip placement="top" title={row?.name} arrow><div>{row?.name.slice(0, 22) + "..."}</div></Tooltip>
          : row?.name ? row?.name : "-"}</div>)
      },
      {
        field: "dl_no", flex: 0.15, headerName: t("DL No"), headerAlign: "center", align: "center",
        renderCell: ({ row }) => (<div>{row?.dl_no?.length > 23 ?
          <Tooltip placement="top" title={row?.dl_no} arrow><div>{row?.dl_no?.slice(0, 22) + "..."}</div></Tooltip>
          : row?.dl_no || "-"}</div>)
      },
      {
        field: "mobile_number", flex: 0.14, headerName: t("Phone Number"),  headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.mobile_number ? params?.row?.mobile_number : "-"}</Box>)
      },
      {
        field: "email", flex: 0.14, headerName: t("Email ID"), headerAlign: "center", align: "center",
        renderCell: ({ row }) => (<div>{row?.email?.length > 18 ?
          <Tooltip placement="top" title={row?.email} arrow><div>{row?.email.slice(0, 17) + "..."}</div></Tooltip>
          : row?.email ? row?.email : "-"}</div>)
      },
      {
        field: "action", flex: 0.07, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false,
        renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
          {userAccess?.editAccess ? <Tooltip title={t("Edit Vendor")} placement="top"   onClick={()=>{ this.props.history.push({ pathname: '/PharmacyPurchase/VendorList/AddVendor',state: { VendorID : params?.row?.vendor_id, OnlyView: false }})}} arrow>
            <Button emed_tid= "vnd_edit" className='eMed_usrconf_btn' disabled={params?.row?.store_vendor_type === "Internal"}>
              <img src={ params?.row?.store_vendor_type === "Internal" ? ImagePaths.DisabledEdit.default :ImagePaths.Edit.default} alt="print" className='eMed_action_img' />
            </Button>
          </Tooltip> : null}
          <Tooltip title={t("View Vendor")} placement="top"  onClick={()=>{ this.props.history.push({ pathname: '/PharmacyPurchase/VendorList/AddVendor',state: { VendorID : params?.row?.vendor_id, OnlyView: true }})}} arrow>
            <Button emed_tid= "vnd_view" className='eMed_usrconf_btn'>
              <img src={ImagePaths.LabViewButton.default} alt="print" className='eMed_action_img' />
            </Button>
          </Tooltip>
        </Box>
      }
    ]

        const vendorTypeIndex = columns.findIndex(col => col.field === 'name');
        if(this.state.internal_store_enabled){
            columns.splice(vendorTypeIndex + 1, 0, 
              {
                field: "store_vendor_type", flex: 0.14, headerName: t("Vendor Type"), headerAlign: "left", align: "left",
                renderCell: ({ row }) => (<div>{row?.store_vendor_type?.length > 23 ?
                  <Tooltip placement="top" title={row?.store_vendor_type} arrow><div>{row?.store_vendor_type.slice(0, 22) + "..."}</div></Tooltip>
                  : row?.store_vendor_type ? row?.store_vendor_type : "-"}</div>)
              },
          );
        }
    return (
      <Box component={"div"} className='eMed_Purcahse_Table_Container'>
        <Stack component={"div"}>
          <Paper className='eMed_Purchase_Table_Wrapper' >
            <DataGrid
              rows={this.state.VendorList}
              columns={columns}
              getRowId={(row) => row['serial']}
              page={this.state.page}
              pageSize={this.state.pageSize}
              hideFooterSelectedRowCount
              localeText={{
                toolbarColumns: "",
                toolbarDensity: "",
                toolbarExport: "",
                toolbarFilters: "",
                toolbarExportPrint: ""
              }}
              headerHeight={40}
              components={{
                Toolbar: this.gridToolBar,
                NoRowsOverlay: () => (
                  <Stack className='eMed_conf_nodata'>
                    {t("NoRecordsFound")}
                  </Stack>
                )
              }}
              rowsPerPageOptions={[10, 20, 30]}
              disableSelectionOnClick
              pagination
              paginationMode='server'
              onPageChange={(newPage) => { this.setState({ page: newPage }, () => { this.VendorList() }) }}
              onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize }, () => { this.VendorList() })}
              loading={this.state.isLoader}
              rowCount={this.state.rowCount}
              filterMode="server"
              onFilterModelChange={this.onFilterChange}
            />
          </Paper>
        </Stack>
      </Box>
    )
  }

  handleAddVendor = () => {
    this.props.history.push({
      pathname: '/PharmacyPurchase/VendorList/AddVendor',
      state: { VendorID: null, OnlyView: false }
    })
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <div className='eMed_Purchase_Table_top'>
          {userAccess?.editAccess && 
          <><Button emed_tid= "add_vnd" className='eMed_Filter_Btns'
           variant='contained' size='small'
           onClick={this.handleAddVendor} >{`Add Vendor`}</Button>
          <Divider orientation='vertical' /></>}
          {/* <Tooltip title="Filter" placement='top' arrow>
            <Button emed_tid= "vnd_filter" className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
              <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
            </Button>
          </Tooltip> */}
          <Tooltip title="Print" placement='top' arrow>
            <Button emed_tid= "vnd_print" className='eMed_usrconf_btn' disabled={this.state.disableBtn} onClick={() => this.printVendorList()}>
              <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
            </Button></Tooltip>
            <Tooltip placement="top" title={t("Export")} arrow>
            <Button emed_tid= "vnd_export" className='eMed_usrconf_btn' size="small" disabled={this.state.disableBtn} onClick={() => this.getVendorListExcel()}>
              <img  src={ImagePaths.LabUploadButton.default} alt='upload' className='eMed_action_img'/>
            </Button></Tooltip>
        </div>
        {this.renderTable()}
        <Drawer
          anchor={'right'}
          open={this.state.FilterOpen}
          ModalProps={{ onBackdropClick: this.closeFilter }}
        >
          {this.renderFilterScreen()}
        </Drawer>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </div>
    )
  }
}
export default withTranslation()(PharmsVendorList)