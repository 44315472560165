import { Box, Button, Stack, Tooltip, Typography, Drawer, TextField, IconButton, Menu, MenuItem } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
// import "./PharmaPurchase.css"
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import CommonGridHeader, { CommonGridToolBarWithFilterText } from '../../../Components/Common Components/CommonComponents';
import { formatDate, CheckAccessFunc } from "../../../Components/CommonFunctions/CommonFunctions";
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker';
import { Colors } from '../../../Styles/Colors';
import PrintPreviewPop from '../../../Components/Common Components/PrintPreviewPop';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { clearCachevalue, setCachevalue } from '../../../Utility/Services/CacheProviderService';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DateTime } from 'luxon';
import CircularProgress from '@mui/material/CircularProgress';

class PharmaISInternalTaxReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      pageSize: 10,
      fromDate: '',
      toDate: '',
      isLoader: false,
      PORequestData: [],
      editAccess : false,
    }
  }

  componentDidMount() {
    this.getInternalTaxReport()
    let editAccess = CheckAccessFunc("Pharmacy", "Internal Sales", "InternalTaxReport", null, "Tab")?.editAccess
    this.setState({editAccess: editAccess })
    
  }
  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }
  getInternalTaxReport = () => {
    try {
      this.LoaderFunction(true);
      RestAPIService.getAll(`${Serviceurls.INTERNALSALES_TAX_REPORT}?from_date=${this.state.fromDate}&to_date=${this.state.toDate}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.LoaderFunction(false);
            this.setState({
              PORequestData: response.data?.data  ? response.data?.data : [],
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.LoaderFunction(false);
            this.errorMessage(error.response.data.message)
          } else {
            this.LoaderFunction(false);
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }
  getInternalTaxReportPrint = () => {
    try {
        this.setState({ disableBtn: true, printLoading: true })
        RestAPIService.getAll(Serviceurls.INTERNALSALES_TAX_REPORT + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&export=pdf`)
            .then((response) => {
                const file = new Blob(
                    [response.data],
                    { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
                this.setState({ disableBtn: false, printLoading: false })
            }).catch((error) => {
                this.errorMessage(error.message)
                this.setState({ disableBtn: false, printLoading: false })
            })
    } catch (error) {
        this.errorMessage(error?.message)
        this.setState({ disableBtn: false, printLoading: false })
    }
}

getInternalTaxReportExcel = () => {
  const { t } = this.props;
  // let { pharmacy_id, bill_year } = this.state;
  // let billyear = bill_year ? new Date(bill_year).getFullYear() : today.getFullYear();
  try {
      this.setState({ disableBtn: true })
      RestAPIService.excelGet(`${Serviceurls.INTERNALSALES_TAX_REPORT}?export=${"excel"}`).
          then((response) => {
              if (response.data) {
                  var pom = document.createElement('a');
                  var csvContent = response.data; //here we load our csv data 

                  let filename = response.headers["content-disposition"].split("filename=")[1]

                  var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
                  var url = URL.createObjectURL(blob);
                  pom.href = url;
                  pom.setAttribute('download', filename ? filename : 'registry.xlsx');
                  pom.click();
                  this.setState({ disableBtn: false })
              }
          }).catch((error) => {
              this.errorMessage(error.response.data?.message)
              this.setState({ disableBtn: false })
          })
  } catch (error) {
      this.errorMessage(t("PrintError"))
      this.setState({ disableBtn: false })
  }
}


  render() {
   
    const { t } = this.props
    this.state.PORequestData?.forEach((element, index) => element.sno = index + 1)
    const columns = [
      {
        field: "sno", sortable: false, flex: 0.03, headerName: t("SNo"),
        renderCell: (params) => (<Box component={'div'}>{params?.row?.sno ? params?.row?.sno : "-"}</Box>)
      },
      {
        field: "bill_no", flex: 0.125, headerName: ("Invoice Number"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.bill_no ? params?.row?.bill_no : "-"}</Box>)
      },
      {
        field: "bill_date", flex: 0.125, headerName: t("Invoice Date"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'} emed_tid="PO Date">{params?.row?.bill_date ? formatDate(params?.row?.bill_date) : "-"}</Box>)
      },
      {
        field: "branch_name", flex: 0.135, headerName: t("Branch Name"),
        renderCell: ({ row }) => (<div>{row?.branch_name?.length > 10 ?
          <Tooltip placement="top" title={row?.branch_name} arrow><div>{row?.branch_name.slice(0, 15) + "..."}</div></Tooltip>
          : row?.branch_name ? row?.branch_name : "-"}</div>)
      },
      {
        field: "taxable_amount", flex: 0.115, headerName: t("Taxable Amount"), headerAlign: "right",  type:'number',
        renderCell: ({ row }) => (<div>{row?.taxable_amount?.length > 18 ?
          <Tooltip placement="top" title={row?.taxable_amount} arrow><div>{row?.taxable_amount.slice(0, 18) + "..."}</div></Tooltip>
          : row?.taxable_amount ? row?.taxable_amount : "-"}</div>)
      },
      {
        field: "cgst_amount", flex: 0.125, headerName: t("CGST"), headerAlign: "right",  type:'number',
        renderCell: (params) => (<Box component={'div'}>{params?.row?.cgst_amount ? params?.row?.cgst_amount : "-"}</Box>)
      },
      {
        field: "sgst_amount", flex: 0.125, headerName: t("SGST"), headerAlign: "right",  type:'number',
        renderCell: (params) => (<Box component={'div'}>{params?.row?.sgst_amount? params?.row?.sgst_amount : "-"}</Box>)
      },
      {
        field: "igst_amount", flex: 0.125, headerName: t("IGST"), headerAlign: "right",  type:'number',
        renderCell: (params) => (<Box component={'div'}>{params?.row?.igst_amount? params?.row?.igst_amount : "-"}</Box>)
      },
      {
        field: "total_amount", flex: 0.103, headerName: t("Net Amount"), headerAlign: "right",  type:'number',
        renderCell: (params) => (<Box component={'div'}>{params?.row?.total_amount ? params?.row?.total_amount : "-"}</Box>)
      },
 
    ]
    return (
      <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>
        <div className='eMed_Purchase_Table_top'>
          <Tooltip title="Print" placement='top' arrow>
            <Button emed_tid="vnd_print" className='eMed_usrconf_btn' disabled={this.state.disableBtn || !this.state.editAccess} onClick={() => this.getInternalTaxReportPrint()}>
              <img src={this.state.editAccess ? ImagePaths.LabPrintIcon.default : ImagePaths.LabPrintIconOff.default} alt="print" className='eMed_action_img' />
            </Button></Tooltip>
          <Tooltip placement="top" title={t("Export")} arrow>
            <Button emed_tid="vnd_export" className='eMed_usrconf_btn' size="small" disabled={this.state.disableBtn || !this.state.editAccess} onClick={() => this.getInternalTaxReportExcel()}>
              <img src={this.state.editAccess ? ImagePaths.LabUploadButton.default :ImagePaths.DisableUpload.default} alt='upload' className='eMed_action_img' />
            </Button></Tooltip>
        </div>
        <Box component={'div'} className='eMed_Pharma_rts_tbl_card'>
          <DataGrid
            className='eMed_Pharma_table'
            rows={this.state.PORequestData}
            columns={columns}
            getRowId={(row) => row['sno']}
            page={this.state.page}
            pageSize={this.state.pageSize}
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            components={{
              Toolbar: CommonGridHeader.CommonToolBar,
              NoRowsOverlay: () => (
                <Stack className='eMed_conf_nodata'>
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
            rowsPerPageOptions={[10, 20, 30]}
            headerHeight={40}
            disableSelectionOnClick
            pagination
            onPageChange={(newPage) => { this.setState({ page: newPage }) }}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
            loading={this.state.isLoader}
          />
        </Box>
      </Box>

    )
  }
}
export default withTranslation()(PharmaISInternalTaxReport)
