import React, { useState, useEffect } from 'react';
import { Box, Button, Drawer, Stack, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { withTranslation } from 'react-i18next';
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker';
import { CommonGridToolBarWithFilterTextCustom } from '../../../../Components/Common Components/CommonComponents';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DateTime } from 'luxon';
import { formatDate, AmountFormat, splitDate } from '../../../../Components/CommonFunctions/CommonFunctions';
import { Colors } from '../../../../Styles/Colors';
import { CurrencySymbol } from '../../../../Utility/Constants';

const OTsalesBillTaxwise = (props) => {
  const [state, setState] = useState({
    billTaxWiseData: [],
    page: 0,
    pageSize: 10,
    rowCount: 0,
    filterDatas: {
      'fromDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      'toDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      "dateIndex": 1,
      "days": 30,
    },
    filterOpen: false,
    cgst: 0,
    sgst: 0,
    netAmount: 0,
    taxableAmount: 0,
    isLoader: false,
    disableBtn: false,
    isErrorMsg: false,
    isErrorMsgText: "",
    triggerAction: null,
  })

  useEffect(() => {
    getBillTaxWiseData();
  }, []);

  useEffect(() => {
    if (state.triggerAction) {
      getBillTaxWiseData();
      setState((prevState) => ({
        ...prevState,
        triggerAction: null
      }))
    }
  }, [state.triggerAction])

  const generateUrl = () => {
    const { filterDatas } = state;

    return Serviceurls.OT_SALES_BILL_TAX_WISE + `?from_date=${filterDatas?.fromDate}&to_date=${filterDatas?.toDate}`
  }

  const getBillTaxWiseData = () => {
    try {
      LoaderFunction(true);
      const url = generateUrl();
      RestAPIService.getAll(url)
        .then((response) => {
          if (response.data.status === 'success') {
            LoaderFunction(false);
            const Data = response?.data?.data;
            Data?.result?.forEach((element, index) => {
              element.sno = index + 1;
            })
            setState((prevState) => ({
              ...prevState,
              billTaxWiseData: Data?.result || [],
              netAmount: Data?.total_net_amount || 0,
              taxableAmount: Data?.total_taxable_amount || 0,
              cgst: Data?.total_cgst_amount || 0,
              sgst: Data?.total_sgst_amount || 0,
              rowCount: Data?.total_count || Data?.result?.length,
            }))
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            LoaderFunction(false);
            errorMessage(error.response.data.message)
          } else {
            LoaderFunction(false);
            errorMessage(error.message)
          }
        })
    } catch (e) {
      errorMessage(e.message)
      LoaderFunction(false)
    }
  }

  const getBillTaxWiseExcel = () => {
    try {
      setDisableBtn(true);
      const url = generateUrl();
      RestAPIService.excelGet(`${url}&export=excel`)
        .then((response) => {
          if (response.data) {
            const pom = document.createElement('a');
            const csvContent = response.data; //here we load our csv data 

            const filename = response.headers["content-disposition"].split("filename=")[1]

            const blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' });
            const url = URL.createObjectURL(blob);
            pom.href = url;
            pom.setAttribute('download', filename ? filename : 'registry.xlsx');
            pom.click();
            setDisableBtn(false);
          }
        }).catch((error) => {
          if (error.response?.data?.message) {
            errorMessage(error.response?.data?.message);
          } else {
            errorMessage(error.message);
          }
          setDisableBtn(false);
        })
    } catch (error) {
      errorMessage(error.message);
      setDisableBtn(false);
    }
  }

  const setDisableBtn = (key) => {
    setState((prevState) => ({
      ...prevState,
      disableBtn: key
    }))
  }

  const LoaderFunction = (key) => {
    setState((prevState) => ({
      ...prevState,
      isLoader: key
    }))
  }

  const errorMessage = (message) => {
    setState((prevState) => ({
      ...prevState,
      isErrorMsg: true,
      isErrorMsgText: message
    }))
  }

  const msgClose = () => {
    setState((prevState) => ({
      ...prevState,
      isErrorMsg: false,
      isErrorMsgText: ""
    }))
  }

  const gridToolBar = () => {
    const TextArray = [
      { label: "From Date", value: formatDate(state.filterDatas.fromDate) },
      { label: "To Date", value: formatDate(state.filterDatas.toDate) },
    ]
    const ExportData = state.billTaxWiseData?.length > 0 && state.billTaxWiseData?.map((item) => ({
      "S No": item.sno,
      "Bill No": item.bill_number,
      "Bill Date": formatDate(item.bill_date),
      "State": item.state,
      "Tax Rate": item.gst_percentage,
      "Taxable Amount": item.taxable_amount,
      "CGST": item.cgst_amount,
      "SGST": item.sgst_amount,
      "IGST": item.igst_amount,
      "Net Amount": item.total_net_amount,
    }))

    return (
      <Box>
        <CommonGridToolBarWithFilterTextCustom FilterTextArray={TextArray} data={ExportData} filename={"Sales Bill Tax Wise"} title={"Sales Bill Tax Wise"} />
      </Box>
    )
  }

  const closeFilter = (fromSrch) => {
    setState((prevState) => ({
      ...prevState,
      filterOpen: false,
      triggerAction: fromSrch ? "search" : null
    }))
  }

  const FilterDate = (from, to, index) => {
    setState((prevState) => ({
      ...prevState,
      filterDatas: {
        ...prevState.filterDatas,
        fromDate: from,
        toDate: to,
        dateIndex: index
      }
    }))
  }

  const renderFilterScreen = () => {
    const { filterDatas } = state;
    const { t } = props;

    return (
      <Box component={"div"} className='eMed_rts_filter_container'>
        <Box component={"div"} className='eMed_filter_Top'>
          <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
          <Button onClick={() => { closeFilter() }} className='eMed_usrconf_btn'>
            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
          </Button>
        </Box>
        <Box className='eMed_rts_middle'>
          <Box className='eMed_date_filter'>
            <CommonDatePicker
              FromDate={filterDatas?.fromDate}
              ToDate={filterDatas?.toDate}
              DateIndex={filterDatas?.dateIndex}
              SelectedDates={FilterDate.bind(this)}
              HideAllMenu={true} />
          </Box>
        </Box>
        <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
          <Button variant='outlined' size="small"
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                filterDatas: {
                  ...prevState.filterDatas,
                  fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                  toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                  dateIndex: 1,
                },
                triggerAction: "clear"
              }));
            }}
            disabled={state.triggerAction === "clear"}
            id="eMed_Config_btn">{t("Clear")}</Button>
          <Button variant='contained' size="small"
            disabled={state.triggerAction === "search"}
            onClick={() => { closeFilter(true) }}
            id="eMed_Config_btn">{t("Search")}</Button>
        </Stack>
      </Box>
    )
  }

  const { t } = props;

  const cardList = [
    { label: "Taxable Amount", amount: state.taxableAmount, id: 'ot_rpt_salebilltax_tax_amt' },
    { label: "CGST", amount: state.cgst, id: 'ot_rpt_salebilltax_cgst' },
    { label: "SGST", amount: state.sgst, id: 'ot_rpt_salebilltax_sgst' },
    { label: "Net Amount", amount: state.netAmount, id: 'ot_rpt_salebilltax_dis_amt' },
  ];

  const columns = [
    {
      field: "sno", flex: 0.01, headerName: t("SNo"), headerAlign: "center", align: "center",
      renderCell: ({ row }) => (<Box component={'div'}>{row?.sno ? row?.sno : "-"}</Box>)
    },
    {
      field: 'invoice_number', headerName: "Bill No", flex: 0.15,
      renderCell: ({ row }) => (<Box component={'div'}>
        {row?.invoice_number?.length > 20 ? <Tooltip placement='top' title={row?.invoice_number} arrow><div>{'...' + row?.invoice_number?.slice(10, 20)}</div></Tooltip> :
          row?.invoice_number ? row?.invoice_number : '-'}
      </Box>)
    },
    {
      field: 'invoice_date', headerName: "Bill Date", flex: 0.11, headerAlign: "center", align: "center",
      renderCell: ({ row }) => {
        const invoiceDate = row?.invoice_date ? splitDate(row?.invoice_date) : "-";
        return (<Box component={'div'}>
          {row?.invoice_date ? invoiceDate : '-'}
        </Box>)
      }
    },
    {
      field: "gst_percentage", flex: 0.11, headerName: t("Tax Rate (%)"), type: "number",
      renderCell: ({ row }) => {
        return (
          <Box component={'div'}>{row.gst_percentage ? row.gst_percentage : "0"}</Box>
        )
      }
    },
    {
      field: "taxable_amount", flex: 0.11, headerName: `${t("Taxable Amount")} (${CurrencySymbol})`, type: "number",
      renderCell: ({ row }) => {
        return (
          <Box component={'div'}>{row.taxable_amount ? AmountFormat(row.taxable_amount)?.replace(`${CurrencySymbol}`, "") : "0.00"}</Box>
        )
      }
    },
    {
      field: "cgst_amount", flex: 0.11, headerName: t(`CGST (${CurrencySymbol})`), type: "number",
      renderCell: ({ row }) => (<Box component={'div'}>{row?.cgst_amount ? AmountFormat(row?.cgst_amount)?.replace(`${CurrencySymbol}`, "") : "0.00"}</Box>)
    },
    {
      field: "sgst_amount", flex: 0.11, headerName: t(`SGST (${CurrencySymbol})`), type: "number",
      renderCell: ({ row }) => (<Box component={'div'}>{row?.sgst_amount ? AmountFormat(row?.sgst_amount)?.replace(`${CurrencySymbol}`, "") : "0.00"}</Box>)
    },
    {
      field: 'igst_amount', headerName: `IGST (${CurrencySymbol})`, flex: 0.07, type: "number",
      renderCell: ({ row }) => (<Box component={'div'}>{row?.igst_amount ? AmountFormat(row?.igst_amount)?.replace(`${CurrencySymbol}`, "") : "0.00"}</Box>)
    },
    {
      field: "total_net_amount", flex: 0.13, headerName: `${t("Net Amount")} (${CurrencySymbol})`, type: "number",
      renderCell: ({ row }) => (<Box component={'div'}>{row?.total_net_amount ? AmountFormat(row?.total_net_amount)?.replace(`${CurrencySymbol}`, "") : "0.00"}</Box>)
    },
  ]
  return (
    <Box component={'div'} className='eMed_rts_container'>
      <Box component={"div"} className='eMed_Pharma_rts_header'>
        <Box component={"div"} flex={0.8} display={'flex'} >
          {cardList?.map(({ label, amount, id }) => (
            <div
              key={id}
            >
              {AmountsCard(label, amount, false, "white", false, Colors.Primary, id, "10dvw")}
            </div>
          ))}
        </Box>
        <Box component={"div"} flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5dvw'}>
          <Button emed_tid="ot_rpt_salebilltax_flt" className='eMed_usrconf_btn' onClick={() => { setState((prevState) => ({ ...prevState, filterOpen: true })) }}>
            <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
          </Button>
          <Button emed_tid="ot_rpt_salebilltax_export" className='eMed_Pharma_rts_btn' disabled={state.disableBtn} onClick={getBillTaxWiseExcel}>
            <img src={ImagePaths.ExportIcon.default} alt='export' className='eMed_action_img' />
          </Button>
        </Box>
      </Box>
      <Box component={'div'} className='eMed_Pharma_rts_tbl_card'>
        <DataGrid
          className='eMed_Pharma_table'
          rows={state.billTaxWiseData}
          columns={columns}
          getRowId={(row) => row.sno}
          page={state.page}
          pageSize={state.pageSize}
          localeText={{
            toolbarColumns: "",
            toolbarDensity: "",
            toolbarExport: "",
            toolbarFilters: "",
            toolbarExportPrint: ""
          }}
          components={{
            Toolbar: gridToolBar,
            NoRowsOverlay: () => (
              <Stack className='eMed_conf_nodata'>
                {t("NoRecordsFound")}
              </Stack>
            )
          }}
          rowCount={state?.rowCount}
          onPageChange={(newPage) => { setState((prevState) => ({ ...prevState, page: newPage })) }}
          onPageSizeChange={(newPageSize) => setState((prevState) => ({ ...prevState, pageSize: newPageSize }))}
          rowsPerPageOptions={[10, 20, 30]}
          pagination
          headerHeight={40}
          disableSelectionOnClick
          loading={state.isLoader}
        />
      </Box>
      <Drawer
        anchor={'right'}
        open={state.filterOpen}
        ModalProps={{ onBackdropClick: closeFilter }}
      >
        {renderFilterScreen()}
      </Drawer>
      {state.isErrorMsg && <ToastMsg
        severity={'error'}
        msg={state.isErrorMsgText}
        msgPop={msgClose.bind(this)}
      />}
    </Box>
  )
}

export default withTranslation()(OTsalesBillTaxwise)