import React, { Component } from 'react';
import { Autocomplete, Box, Button, Stack, Tooltip, Typography, TextField, Drawer, Modal, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import { ImagePaths } from '../../../Utility/ImagePaths';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { DateTime } from 'luxon';
import { formatDate } from '../../../Components/CommonFunctions/CommonFunctions';
import { CommonGridToolBarWithFilterText } from '../../../Components/Common Components/CommonComponents';
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker';

class LabResultLogReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: 1,
            ResultLogData: [],
            testNameData: [],
            changedUser: "",
            testName: "",
            userList: [],
            FilterOpen: false,
            disableBtn: false,
        }
    }

    componentDidMount() {
        this.getResultLogData()
        this.getTestNameList()
        this.GetUserDetails()
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message,
            disableBtn: false
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }

    removeDuplicate(data) {
        let nameListJsonObject = data.map(JSON.stringify);
        let nameListUniqueSet = new Set(nameListJsonObject);
        let nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }

    getTestNameList = () => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_TEST_CONFIG_GET + "?only_test_name=true")
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            testNameData: response.data?.data?.length > 0 ? this.removeDuplicate(response.data?.data) : []
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error?.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    GetUserDetails = () => {
        try {
            RestAPIService.getAll(Serviceurls.DAY_END_USER_DETAIL_GET)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            userList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error)
        }
    }

    getResultLogData = () => {
        try {
            this.LoaderFunction(true);
            RestAPIService.getAll(`${Serviceurls.LAB_RESULT_LOG_REPORT}?test_name=${this.state?.testName?.test_name ? this.state?.testName?.test_name : ""}&from_date=${this.state?.fromDate}&to_date=${this.state?.toDate}&modified_by=${this.state?.changedUser?.user_name ? this.state?.changedUser?.user_name : ""}`)
                .then((response) => {
                    if (response.data.status === "success") {
                        let Data = response?.data?.data;
                        this.setState({
                            ResultLogData: Data?.length > 0 ? Data : [],
                        }, () => this.LoaderFunction(false))
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.LoaderFunction(false);
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false);
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
            this.LoaderFunction(false);
        }
    }

    gridToolBar = () => {
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.fromDate) },
            { label: "To Date", value: formatDate(this.state.toDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
            </Box>
        )
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        })
    }

    printReports = () => {
        try {
            this.setState({ disableBtn: true })
            RestAPIService.getAllPrint(`${Serviceurls.LAB_RESULT_LOG_REPORT}?test_name=${this.state?.testName?.test_name ? this.state?.testName?.test_name : ""}&from_date=${this.state?.fromDate}&to_date=${this.state?.toDate}&modified_by=${this.state?.changedUser?.user_name ? this.state?.changedUser?.user_name : ""}&export_type=pdf`)
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.setState({ disableBtn: false })
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getExcel = () => {
        try {
            this.setState({ disableBtn: true })
            RestAPIService.excelGet(`${Serviceurls.LAB_RESULT_LOG_REPORT}?test_name=${this.state?.testName?.test_name ? this.state?.testName?.test_name : ""}&from_date=${this.state?.fromDate}&to_date=${this.state?.toDate}&modified_by=${this.state?.changedUser?.user_name ? this.state?.changedUser?.user_name : ""}&export_type=excel`)
                .then((response) => {
                    if (response.data) {
                        var pom = document.createElement('a');
                        var csvContent = response.data;

                        let filename = response.headers["content-disposition"]?.split("filename=")[1]

                        var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' });
                        var url = URL.createObjectURL(blob);
                        pom.href = url;
                        pom.setAttribute('download', filename ? filename : 'ResultLog.xlsx');
                        pom.click();
                        this.setState({ disableBtn: false })
                    }
                }).catch((error) => {
                    if (error.response?.data?.message) {
                        this.errorMessage(error.response?.data?.message);
                    } else {
                        this.errorMessage(error.message);
                    }
                })
        } catch (error) {
            this.errorMessage(error.message);
        }
    }

    closeFilter = () => {
        this.setState({
            FilterOpen: false
        })
    }

    renderFilterScreen = () => {
        const { t } = this.props
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t('Filter')}</Typography>
                    <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)}
                            HideAllMenu={true} />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            size='small'
                            clearIcon
                            options={this.state.testNameData?.length > 0 ? this.state.testNameData : []}
                            getOptionLabel={(option) => option?.test_name}
                            value={this.state?.testName ? this.state?.testName : null}
                            onChange={(e, newValue) => {
                                this.setState({ testName: newValue })
                            }}
                            sx={{ width: '20vw' }}
                            renderInput={(params) => (<TextField {...params} label='Select Test Name' />)}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            size='small'
                            clearIcon
                            options={this.state.userList?.length > 0 ? this.state.userList : []}
                            getOptionLabel={(option) => option?.user_name}
                            value={this.state?.changedUser ? this.state?.changedUser : null}
                            onChange={(e, newValue) => {
                                this.setState({ changedUser: newValue })
                            }}
                            sx={{ width: '20vw' }}
                            renderInput={(params) => (<TextField {...params} label='Select Changed User' />)}
                        />
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"
                        onClick={() => {
                            this.setState({
                                changedUser: "",
                                testName: "",
                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                dateIndex: 1,
                            }, () => this.getResultLogData())
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small" emed_tid='Census_Report_FilterSearch_btn'
                        onClick={() => {
                            this.getResultLogData()
                            this.setState({ FilterOpen: false })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    renderCultureResultPopup = () => {
        const { t } = this.props
        return (
            <Modal
                open={this.state.cultureTestPopup}
                onClose={() => this.setState({ cultureTestPopup: false })}>
                <Box className='emedhub_popup' sx={{ width: "80vw" }}>
                    <div className=''>
                        <div className='emedhub_popup_title'>
                            <label className='emedhub_popup_title_left'>{t("TestReport")}</label>
                            <div className='emedhub_card_close_button'>
                                <img
                                    className='emedhub_popup_title_right'
                                    src={ImagePaths.LabCloseButton.default}
                                    onClick={() => { this.setState({ cultureTestPopup: false }) }}
                                    alt='close'
                                />
                            </div>
                        </div>
                        <div className='emedhub_popup_title_2' style={{ marginTop: "0.5vw" }}>
                            <Typography className='emedhub_popup_title_Header'>{t("CulptureTest")}</Typography>
                        </div>
                    </div>
                    <div style={{ width: "77.5vw", height: "35vw", margin: "1vw", }}>
                        <Typography sx={{ margin: "auto" }}></Typography>
                        <div style={{ height: "78vh", overflow: "scroll", marginTop: "1vw", }}>
                            {this.state.selectedRow?.length > 0 ? this.state.selectedRow?.map((items, indexs) => (
                                <div className="emedhub_card_method_4_table">
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "70vw", marginTop: "1.3vw", marginBottom: "1vw" }}>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <Box component={'div'} sx={{ display: "flex" }}>
                                                <Typography>{`ORGANISM ISOLATED 0${indexs + 1} : `}</Typography>
                                                <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                                                    {items?.bacteria ? items?.bacteria?.length > 20 ?
                                                        <Tooltip placement='top' title={items?.bacteria}>
                                                            <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                                                                {items?.bacteria.slice(0, 20) + '...'}
                                                            </Typography>
                                                        </Tooltip> :
                                                        <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>{items?.bacteria}</Typography> : '-'}
                                                </Typography>
                                            </Box>
                                        </div>
                                        <Box component={'div'} sx={{ display: "flex" }}>
                                            <Typography>{`MICROSCOPY : `}</Typography>
                                            <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                                                {items?.microscopy ? items?.microscopy?.length > 20 ?
                                                    <Tooltip placement='top' title={items?.microscopy}>
                                                        <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                                                            {items?.microscopy.slice(0, 20) + '...'}
                                                        </Typography>
                                                    </Tooltip> :
                                                    <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>{items?.microscopy}</Typography> : '-'}
                                            </Typography>
                                        </Box>
                                        <Box component={'div'} sx={{ display: "flex" }}>
                                            <Typography>{`COLONY COUNT : `}</Typography>
                                            <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                                                {items?.colony_count ? items?.colony_count?.length > 20 ?
                                                    <Tooltip placement='top' title={items?.colony_count}>
                                                        <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                                                            {items?.colony_count.slice(0, 20) + '...'}
                                                        </Typography>
                                                    </Tooltip> :
                                                    <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>{items?.colony_count}</Typography> : '-'}
                                            </Typography>
                                        </Box>
                                        <Box component={'div'} sx={{ display: "flex" }}>
                                            <Typography>{`METHOD : `}</Typography>
                                            <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                                                {items?.method_type ? items?.method_type?.length > 20 ?
                                                    <Tooltip placement='top' title={items?.method_type}>
                                                        <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                                                            {items?.method_type.slice(0, 20) + '...'}
                                                        </Typography>
                                                    </Tooltip> :
                                                    <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>{items?.method_type}</Typography> : '-'}
                                            </Typography>
                                        </Box>
                                    </div>
                                    <TableContainer style={{ maxHeight: "35vh" }}>
                                        <Table style={{ border: "1px solid gray" }} stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("SNo")}</b></TableCell>
                                                    <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("Antibiotic")}</b></TableCell>
                                                    <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("MIC Result/Range")}</b></TableCell>
                                                    <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("Zone Result/Range")}</b></TableCell>
                                                    <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("Interpretaion")}</b></TableCell>
                                                    <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("Efficacy Ratio")}</b></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {items?.antibacteria?.map((item, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{index + 1}</TableCell>
                                                        <TableCell>
                                                            {item?.antibiotic ? item?.antibiotic?.length > 20 ?
                                                                <Tooltip placement='top' title={item?.antibiotic}>
                                                                    <Typography>
                                                                        {item?.antibiotic.slice(0, 20) + '...'}
                                                                    </Typography>
                                                                </Tooltip> :
                                                                <Typography>{item?.antibiotic}</Typography> : '-'}
                                                        </TableCell>
                                                        <TableCell>
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <Typography>{item?.mic_break_point ? item?.mic_break_point?.length > 20 ?
                                                                    <Tooltip placement='top' title={item?.mic_break_point}>
                                                                        <div>{item?.mic_break_point.slice(0, 20) + '...' + " "}</div>
                                                                    </Tooltip> : (item?.mic_break_point + " ") : '-'}</Typography>
                                                                {item?.mic_break_point_std ?
                                                                    <Typography style={{ marginLeft: "0.25vw" }}>{item?.mic_break_point_std ? item?.mic_break_point_std?.length > 20 ?
                                                                        <Tooltip placement='top' title={item?.mic_break_point_std}>
                                                                            <div>{" / " + item?.mic_break_point_std.slice(0, 20) + '...'}</div>
                                                                        </Tooltip> : (" / " + item?.mic_break_point_std) : '-'}</Typography> : null}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <Typography>{item?.zone_size ? item?.zone_size?.length > 20 ?
                                                                    <Tooltip placement='top' title={item?.zone_size}>
                                                                        <div>{item?.zone_size.slice(0, 20) + '...' + " "}</div>
                                                                    </Tooltip> : (item?.zone_size + " ") : '-'}</Typography>
                                                                {item?.zone_size_std ?
                                                                    <Typography style={{ marginLeft: "0.25vw" }}>{item?.zone_size_std ? item?.zone_size_std?.length > 20 ?
                                                                        <Tooltip placement='top' title={item?.zone_size_std}>
                                                                            <div>{" / " + item?.zone_size_std?.slice(0, 20) + '...'}</div>
                                                                        </Tooltip> : (" / " + item?.zone_size_std) : '-'}</Typography> : null
                                                                }
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            {item?.interpretation ? item?.interpretation?.length > 20 ?
                                                                <Tooltip placement='top' title={item?.interpretation}>
                                                                    <Typography>
                                                                        {item?.interpretation.slice(0, 20) + '...'}
                                                                    </Typography>
                                                                </Tooltip> :
                                                                <Typography>{item?.interpretation}</Typography> : '-'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item?.methodThreeBacteriaResult ? item?.methodThreeBacteriaResult?.length > 20 ?
                                                                <Tooltip placement='top' title={item?.methodThreeBacteriaResult}>
                                                                    <Typography>
                                                                        {item?.methodThreeBacteriaResult.slice(0, 20) + '...'}
                                                                    </Typography>
                                                                </Tooltip> :
                                                                <Typography>{item?.methodThreeBacteriaResult}</Typography> : '-'}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            )) : <div style={{ marginTop: "1.5vw", width: "75vw", marginLeft: "1.25vw", height: "50vh" }}>
                                <TableContainer style={{ border: "1px solid black" }}>
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("SNo")}</b></TableCell>
                                                <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("Antibiotic")}</b></TableCell>
                                                <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("MIC Result/Range")}</b></TableCell>
                                                <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("Zone Result/Range")}</b></TableCell>
                                                <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("Interpretaion")}</b></TableCell>
                                                <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("Efficacy Ratio")}</b></TableCell>
                                                <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("Action")}</b></TableCell>
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                </TableContainer>
                                <div style={{ marginTop: "10vw", marginLeft: "32vw" }}>
                                    <div>No Records To Be Shown</div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </Box>
            </Modal>
        )
    }

    render() {
        this.state?.ResultLogData?.forEach((element, index) => element.sno = index + 1)
        const { t } = this.props
        const columns = [
            {
                field: "patient_name", headerName: `${t("Patient Name")}`, flex: 0.125, type: "string",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.patient_name?.length > 18 ?
                    <Tooltip placement='top' title={params?.row?.patient_name} arrow><Typography>{params?.row?.patient_name.slice(0, 18) + "..."}</Typography></Tooltip>
                    : <Typography>{params?.row?.patient_name ? params?.row?.patient_name : "-"}</Typography>}</Box>)
            },
            {
                field: "specimen_collected_date", headerName: `${t("SID Date")}`, flex: 0.125, type: "string",
                renderCell: (params) => (<Box component={'div'}>
                    <Typography>{params?.row?.specimen_collected_date ? params?.row?.specimen_collected_date : '-'}</Typography>
                </Box>)
            },
            {
                field: "sid_number", headerName: `${t("SID No")}`, flex: 0.125, type: "string",
                renderCell: (params) => (<Box component={'div'}>
                    <Typography>{(params?.row?.sid_number ? params?.row?.sid_number : "-")}</Typography>
                </Box>)
            },
            {
                field: "test_name", headerName: `${t("Test Name")}`, flex: 0.125, type: "string",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.test_name?.length > 18 ?
                    <Tooltip placement='top' title={params?.row?.test_name} arrow><Typography>{params?.row?.test_name.slice(0, 18) + "..."}</Typography></Tooltip>
                    : <Typography>{params?.row?.test_name ? params?.row?.test_name : "-"}</Typography>}</Box>)
            },
            {
                field: "previous_result", headerName: `${t("Previous Result")}`, flex: 0.125, type: "string",
                renderCell: (params) => (<Box component={'div'}>
                    <Typography>{(params?.row?.previous_result ?
                        (params?.row?.method === "Culture Test Result" ? <Button onClick={() => { this.setState({ cultureTestPopup: true, selectedRow: params?.row?.previous_result }) }}>View Result</Button>
                            : params?.row?.previous_result) : "-")}</Typography>
                </Box>)
            },
            {
                field: "changed_result", headerName: `${t("Changed Result")}`, flex: 0.125, type: "string",
                renderCell: (params) => (<Box component={'div'}>
                    <Typography>{(params?.row?.changed_result ?
                        (params?.row?.method === "Culture Test Result" ? <Button onClick={() => { this.setState({ cultureTestPopup: true, selectedRow: params?.row?.changed_result }) }}>View Result</Button>
                            : params?.row?.changed_result) : "-")}</Typography>
                </Box>)
            },
            {
                field: "modified_by", headerName: `${t("Changed User")}`, flex: 0.125, type: "string",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.modified_by?.length > 15 ?
                    <Tooltip placement='top' title={params?.row?.modified_by} arrow><Typography>{params?.row?.modified_by.slice(0, 15) + "..."}</Typography></Tooltip>
                    : <Typography>{params?.row?.modified_by ? params?.row?.modified_by : "-"}</Typography>}</Box>)
            },
            {
                field: "modified_date", headerName: `${t("Changed Date & Time")}`, flex: 0.125, type: "string",
                renderCell: (params) => (<Box component={'div'}><Typography>{params?.row?.modified_date ? params?.row?.modified_date : '-'}</Typography></Box>)
            },
        ]
        return (
            <Box component={'div'} className='eMed_rts_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Action_Container' width={'100%'}>
                        <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                            <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                        </Button>
                    </Box>
                </Box>
                <Box component={'div'} sx={{ height: '66vh', width: '92vw', margin: 'auto', backgroundColor: 'white' }}>
                    <DataGrid
                        rows={this.state?.ResultLogData}
                        columns={columns}
                        getRowId={(row) => row['sno']}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                    />
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.renderCultureResultPopup()}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}

export default withTranslation()(LabResultLogReport)

