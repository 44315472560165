import React, { Component } from 'react'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { Autocomplete, Box, Button, IconButton, Modal, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import CommonGridHeader, { CommonPatientCard, CommonPatientDetails } from '../../../../Components/Common Components/CommonComponents'
import { getCachevalue, localGetItem } from '../../../../Utility/Services/CacheProviderService'
import { Colors } from '../../../../Styles/Colors'
import Loader from '../../../../Components/Loader';
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import { DataGrid } from '@mui/x-data-grid'
import { DateTime } from 'luxon'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import './Reports.css';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { formatDate } from '../../../../Components/CommonFunctions/CommonFunctions'
import { Bucket_Name } from '../../../../Utility/Constants'
import FileUploadIcon from '@mui/icons-material/FileUpload';

let AWS = require('aws-sdk');
let s3 = new AWS.S3({
  region: "ap-south-1"
});

const reportType = [
  { label: "Laboratory", value: "Laboratory" },
  { label: "Radiology", value: "Radiology" },
  { label: "Special", value: "Special" },
  { label: "Eye Test", value: "Eye Test" },
  { label: "Investigation", value: "Investigation" }
];
export default class DocTestReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      patientDetails: {},
      clinicId: '',
      appointmentId: '',
      patientId: '',
      page: 0,
      pageSize: 10,
      testReportData: [],
      startDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      endDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      dateIndex: 0,
      reportTypes: reportType,
      selectedReportType: '',
      selectedDate: new Date(),
      open: false,
      imageuploadList: [],
      imageViewerPopup: false,
      viewData: [],
      viewImage: null,
      loading: false,
      selectedImage: null,
      addTestData: false,
      historyPopup: false,
      labhistory: [],
      test_date: []

    }
    this.uploadImage = React.createRef();
  }

  componentDidMount() {
    let clinicId = getCachevalue('SelectedDoctorClinic')
    let appointmentId = this.props.fromOT ? JSON.parse(getCachevalue("OTpatientData")) : JSON.parse(getCachevalue('DoctorPatientData'))
    this.setState({
      clinicId: clinicId,
      appointmentId: appointmentId?.appointment_id,
      patientId: appointmentId?.patient_id
    }, () => {
      this.getPatientDetailData()
      this.getTestReportData()
      this.getlabhistorytestreport()
    })
  }

  getPatientDetailData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_PATIENT_DETAIL_GET + '?patient_id=' + this.state.patientId)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              patientDetails: response?.data?.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getTestReportData = () => {
    try {
      this.setState({ loading: true })
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_TEST_REPORT + `?patient_id=${this.state.patientId}&clinic_id=${this.state.clinicId}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              testReportData: response?.data?.data,
              loading: false
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ loading: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ loading: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ loading: false })
      this.errorMessage(error.message)
    }
  }

  getTestReportPrint = (resultSummaryId, labId) => {
    try {
      RestAPIService.getAll(Serviceurls.LAB_PATIENT_RESULT_PRINT + `?result_summary_id=${resultSummaryId}&laboratory_id=${labId}`)
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  postTestReportData = () => {
    try {
      let { imageuploadList } = this.state
      this.setState({ addTestData: true })
      const dataForm = new FormData();
      dataForm.append('patient_id', this.state.patientId)
      dataForm.append('report_date', this.state.selectedDate ? DateTime.fromJSDate(this.state.selectedDate).toFormat('yyyy-MM-dd') : DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'))
      dataForm.append('report_type', this.state.selectedReportType)
      dataForm.append('comment', this.state.testReportComments ? this.state.testReportComments : '')
      dataForm.append('appointment_id', this.state.appointmentId)
      dataForm.append('clinic_id', this.state.clinicId)

      for (let i = 0; i < imageuploadList.length; i++) {
        // if (imageuploadList[i]["type"] === "application/pdf") {
        //   dataForm.append("report_file_pdf", imageuploadList[i])
        // }
        // if (imageuploadList[i]["type"] !== "application/pdf") {
        // dataForm.append("report_file", imageuploadList[i])
        dataForm.append('report_photo', imageuploadList[i])
        // }
      }
      let validate = this.checkValidation()
      if (validate === null) {
        RestAPIService.create(dataForm, Serviceurls.DOC_DOCTOR_TEST_REPORT_POST)
          .then((response) => {
            if (response?.data?.status === 'success') {
              this.setState({ addTestData: false })
              this.successMessage(response?.data?.message)
              this.onClearTestReportData()
              this.getTestReportData()
            }
          }).catch((error) => {
            if (error?.response?.data?.message) {
              this.setState({ addTestData: false })
              this.errorMessage(error.response.data.message)
            } else {
              this.setState({ addTestData: false })
              this.errorMessage(error.message)
            }
          })
      } else {
        this.setState({ addTestData: false })
        this.errorMessage(validate)
      }
    } catch (error) {
      this.setState({ addTestData: false })
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  async getS3Key(doc_key, id) {
    try {
        const response = await RestAPIService.getAllTwo(Serviceurls.AWS_URL_GET + "?doc_key=" + encodeURIComponent(doc_key));

        if (response.data.status === "success") {
            const Data = response.data;
            return {
                data_url: Data.data
            };
        }
    } catch (e) {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: "Error fetching S3 URL"
        });
    }
}

  getlabhistorytestreport = () => {
    try {
      this.setState({ loading: true })
      RestAPIService.getAll(Serviceurls.DOC_LAB_HISTORY_TEST_REPORT_DB + `?patient_id=${this.state.patientId}&clinic_id=${this.state.clinicId}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              labhistory: response?.data.data ? response?.data.data : [],
              loading: false
            }, () => {
              let Date = []
              if (this.state.labhistory?.length > 0) {
                Date = Object.keys(this.state.labhistory[0])
                if (Date?.indexOf("test_name") > -1) { Date?.splice(Date?.indexOf("test_name"), 1) }
              }
              this.setState({ test_date: Date })
            })

          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ loading: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ loading: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ loading: false })
      this.errorMessage(error.message)
    }
  }

  async renderImage(imageData, pdfData) {
    let tempArr = [];
    if (imageData.length > 0) {
      for (let i = 0; i < imageData.length; i++) {
        let image = imageData[i].result_img_url
        if (image !== null) {
          let doc_key = image;
          let getData = await this.getS3Key(doc_key)
          getData["type"] = image.split('.').pop() === "pdf" ? "pdf" : "image"
          tempArr.push(getData)
        }
      }
    }
    // if (pdfData.length > 0) {
    //   for (let i = 0; i < pdfData.length; i++) {
    //     let pdf = pdfData[i].result_img_url
    //     if (pdf !== null) {
    //       let pdf_key = pdf;
    //       let getPdf = await this.getS3Key(pdf_key)
    //       getPdf["type"] = "pdf"
    //       tempArr.push(getPdf)
    //     }
    //   }
    // }
    this.setState({
      viewData: tempArr,
      viewImage: tempArr[0]
    }, () => {
      if (pdfData) {
        window.open(this.state.viewImage.data_url)        
      }
    })
  }

  onImageHandler = async (e) => {
    const imageList = [];
    let imageFiles = [...e.target.files];
    let clearedArr = imageFiles?.filter((image, i) => {
      let file = image?.name;
      return String(file).toLowerCase() !== String(imageFiles[i + 1]?.name).toLowerCase()
    });
    // convert images to base64;
    for (let i = 0; i < clearedArr.length; i++) {
      imageList.push(clearedArr[i])
    }
    const newList = await Promise.all(imageList)
    this.setState({
      imageuploadList: newList
    })
    e.target.value = ''
  }

  onImageRemove = (index) => {
    let { imageuploadList } = this.state;
    // let deletedArr = [...imgUpload]
    // let index = deletedArr.findIndex(image => String(image["name"]).toLowerCase() === String(imgName).toLowerCase())
    imageuploadList.splice(index, 1)
    this.setState({
      imageuploadList: this.state.imageuploadList,
    })
  }

  onClearTestReportData = () => {
    try {
      this.setState({
        imageuploadList: [],
        selectedImage: null,
        viewData: [],
        viewImage: null,
        date: new Date(),
        selectedReportType: '',
        testReportComments: ''
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  prevImage = (index) => {

    let { viewData } = this.state;

    let currentIndex = parseInt(index) || 0;

    if (currentIndex >= 1) {
      this.setState({
        currentIndex: currentIndex - 1,
        viewImage: viewData[currentIndex - 1]
      })
    }
  }

  nextImage = (index) => {

    let { viewData } = this.state;

    let currentIndex = parseInt(index) || 0;

    if ((currentIndex + 1) < viewData.length) {
      this.setState({
        currentIndex: currentIndex + 1,
        viewImage: viewData[currentIndex + 1]
      })
    }
  }

  checkValidation = () => {
    try {
      let validate = null;
      if ((this.state.selectedReportType === '') && (this.state.imageuploadList?.length === 0)) {
        validate = 'Enter all mandatory fields'
      } else if (this.state.selectedReportType === '') {
        validate = 'Select or Enter Report Type'
      } else if (this.state.imageuploadList?.length === 0) {
        validate = 'Upload atleast one Image'
      }
      return validate;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderImageViewerPopup = () => {
    try {
      return (
        <Box component={'div'}>
          {
            this.state.viewData?.length > 0 ?

              < Modal
                open={this.state.imageViewerPopup}
              >
                <Box className='eMed_Doc_Img_Pop_Up'>
                  <Box component={'div'} height={'2.5vw'} display={'flex'} pl={'1vw'} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Doctor Visit Report</Typography>
                    <Button
                      onClick={() => this.setState({
                        imageViewerPopup: false
                      })}
                    >
                      <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} mr={'1vw'} />
                    </Button>
                  </Box>
                  <Box height={'30vw'} display={'flex'} justifyContent={'center'}>
                    {this.state.viewData?.length > 1 ?
                      <Box component={'div'} display={'flex'} alignItems={'center'}>
                        <Button size="small" className='Common_Btn_Min_Width' sx={{ height: '3vw' }} onClick={() => { this.prevImage(this.state.currentIndex) }}><KeyboardDoubleArrowLeftIcon className="eMed_testrp_arrowSize" fontSize="large" color="primary" /></Button>
                      </Box> : null
                    }

                    {this.state.viewImage.type === "pdf" ?
                      <Box>
                        <img style={{ width: '12vw', height: '12vw' }} src={ImagePaths.DocumentPreview.default} alt="slide" />
                        <Button variant="contained" onClick={() => { window.open(this.state.viewImage.data_url) }}>Download Document</Button>
                      </Box>
                      :
                      <Box component={'div'} height={'30vw'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <img src={this.state.viewImage.data_url ? this.state.viewImage.data_url : ""} alt="slide" style={{ objectFit: 'contain', height: '15vw', width: '30vw' }} />
                      </Box>}

                    {this.state.viewData?.length > 1 ?
                      <Box component={'div'} display={'flex'} alignItems={'center'}>
                        <Button size="small" className='Common_Btn_Min_Width' sx={{ height: '3vw' }} onClick={() => { this.nextImage(this.state.currentIndex) }}><KeyboardDoubleArrowRightIcon className="eMed_testrp_arrowSize" fontSize="large" color="primary" /></Button>
                      </Box> : null
                    }
                  </Box>
                </Box>
              </Modal> : null
          }
        </Box >
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderLeftSide = () => {
    try {
      this.state.testReportData?.forEach((element, index) => element.sno = index + 1)
      const columns = [
        {
          field: 'report_date', headerName: 'Reported Date', flex: 0.25,
          renderCell: (params) => (<Box>{params?.row?.report_date ? formatDate(params?.row?.report_date) : '-'}</Box>)
        },
        {
          field: 'report_type', headerName: 'Report Type', flex: 0.25,
          renderCell: (params) => (<Box>{params?.row?.report_type ? params?.row?.report_type?.length > 15 ?
            <Tooltip title={params?.row?.report_type} placement='top'><div>{params?.row?.report_type?.slice(0, 15) + '...'}</div></Tooltip> :
            params?.row?.report_type : '-'}</Box>)
        },
        {
          field: 'test_name', headerName: 'Test Details', flex: 0.25,
          renderCell: (params) => (<Box>{params?.row?.test_name ? params?.row?.test_name?.length > 15 ?
            <Tooltip title={params?.row?.test_name} placement='top'><div>{params?.row?.test_name?.slice(0, 15) + '...'}</div></Tooltip> :
            params?.row?.test_name : '-'}</Box>)
        },
        {
          field: 'action', headerName: 'Status', flex: 0.25,
          renderCell: (params) => (
            <Box>
              {(params?.row?.status === 'Result Inprogress') || (params?.row?.status === "Specimen Collected") ? params?.row?.status :
                ((params?.row?.status === 'Result Delivered') || (params?.row?.status === 'Result Completed')) ?
                  <Tooltip title={params?.row?.is_outsource ? 'Outsource' : 'View'} placement="top" arrow>
                    <div>
                      <Button sx={{ textTransform: 'capitalize' }} className='Common_Btn_Min_Width'
                        disabled={params?.row?.is_outsource}
                        onClick={() => { this.getTestReportPrint(params?.row?.id, params?.row?.laboratory_id) }}
                      >View</Button>
                    </div>
                  </Tooltip>
                  : params?.row?.file_name[0]?.result_img_url?.includes('.pdf') ?
                    <Button className='Common_Btn_Min_Width'
                      onClick={() => {
                        if (params?.row?.file_name?.length > 0) {
                          let imageFile = params?.row?.file_name?.length > 0 ? params?.row?.file_name : [];
                          let pdfFile = params?.row?.pdf_file?.length > 0 ? params?.row?.pdf_file : [];
                          this.setState({
                            // imageViewerPopup: true,
                            // viewOpen: true,
                            viewData: [],
                            viewImage: null
                          }, () => this.renderImage(imageFile, true))
                        } else {
                          this.errorMessage('No PDF to be view')
                        }
                      }}
                    >
                      <FileUploadIcon color='primary' />
                    </Button> :
                    <Button sx={{ textTransform: 'capitalize' }} className='Common_Btn_Min_Width'
                      onClick={() => {
                        if (params?.row?.file_name?.length > 0) {
                          let imageFile = params?.row?.file_name?.length > 0 ? params?.row?.file_name : [];
                          let pdfFile = params?.row?.pdf_file?.length > 0 ? params?.row?.pdf_file : [];
                          this.setState({
                            imageViewerPopup: true,
                            viewOpen: true,
                            viewData: [],
                            viewImage: null
                          }, () => this.renderImage(imageFile))
                        } else {
                          this.errorMessage('No image to be view')
                        }
                      }}
                    >View</Button>
              }
            </Box>), disableExport: true
        },
      ]
      return (
        <DataGrid
          rows={this.state.testReportData}
          columns={columns}
          getRowId={(row) => row['sno']}
          page={this.state.page}
          pageSize={this.state.pageSize}
          localeText={{
            toolbarColumns: "",
            toolbarDensity: "",
            toolbarExport: "",
            toolbarFilters: "",
            toolbarExportPrint: ""
          }}
          sx={{ backgroundColor: Colors.white }}
          components={{
            Toolbar: CommonGridHeader.CommonToolBar,
            NoRowsOverlay: () => (
              <Box className='eMed_conf_nodata'>
                {'No Records To Be Shown'}
              </Box>
            )
          }}
          onPageChange={(newPage) => { this.setState({ page: newPage }) }}
          onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
          rowsPerPageOptions={[10, 20, 30]}
          pagination
          headerHeight={40}
          disableSelectionOnClick
          loading={this.state.loading}
        />
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderRightSide = () => {
    try {
      return (
        <Box>
          <Box component={'div'} height={'2.5vw'} display={'flex'} pl={'0.5vw'} alignItems={'center'}>
            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Test Report</Typography>
          </Box>
          <Box component={'div'} height={'48.5vh'} sx={{ background: Colors.white }} p={'1vw'} overflow={'scroll'}>
            <Box display={'flex'} mb={'1vw'}>
              <Autocomplete
                clearIcon
                size='small'
                sx={{ width: '14.5vw', mr: '1vw' }}
                freeSolo
                options={this.state.reportTypes.map((item) => item.label)}
                value={this.state.selectedReportType}
                limitTags={3}
                autoComplete='off'
                onChange={(event, newValue) => {
                  this.setState({
                    selectedReportType: newValue
                  })
                }}
                onInputChange={(event, newValue) => {
                  this.setState({ selectedReportType: newValue })
                }}
                renderInput={(params) => <TextField {...params} label='Select Report Type' />}
              />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  open={this.state.open}
                  onOpen={() => { this.setState({ open: true }) }}
                  onClose={() => { this.setState({ open: false }) }}
                  inputFormat="dd-MM-yyyy"
                  label={'Select Date'}
                  value={this.state.selectedDate}
                  autoComplete="off"
                  maxDate={DateTime.now()}
                  onChange={(value) => this.setState({ selectedDate: value })}
                  renderInput={(params) => <TextField onKeyDown={(e) => e.preventDefault()} size="small" sx={{ width: '14.5vw' }} {...params} onClick={() => { this.setState({ open: true }) }} />}
                />
              </LocalizationProvider>
            </Box>
            <Box component={'div'} mb={'1vw'}>
              <TextField
                size='small'
                fullWidth
                multiline
                rows={3}
                label='Comments'
                value={this.state.testReportComments}
                onChange={(e) => this.setState({ testReportComments: e.target.value })}
              />
            </Box>
            <Box component={'div'}>
              <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{ backgroundColor: Colors.white }} overflow={'scroll'}>
                <Box component={'div'} sx={{ backgroundColor: Colors.Background }} width={'29vw'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                  <Box component={'input'} type='file' sx={{ display: 'none' }}
                    multiple
                    accept="image/jpg, image/jpeg, image/png,application/pdf"
                    ref={this.uploadImage}
                    onChange={this.onImageHandler}
                  />
                  <Button sx={{ textTransform: 'capitalize' }}
                    onClick={() => this.uploadImage.current.click()}
                  >
                    <Box component={'div'} borderRadius={'10px'} border={'1px dotted gray'} height={'10vw'} width={'13vw'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                      <Box component={'img'} src={ImagePaths.LabUploadButton.default} height={'2vw'} width={'2vw'} />
                      <Typography fontSize={'0.9vw'} color={Colors.SecondaryText} fontWeight={600} mt={'0.5vw'}>Upload You Files Here</Typography>
                      <Typography fontSize={'0.6vw'} color={Colors.SecondaryText}>Supported Formats JPEG,JPG,PNG,PDF</Typography>
                    </Box>
                  </Button>
                </Box>
              </Box>
            </Box>
            {this.state.imageuploadList?.length > 0 ?
              <Box component={'div'} display={'flex'} maxWidth={'29vw'} alignItems={'center'} justifyContent={'center'} overflow={'scroll'}>
                {this.state.imageuploadList?.map((image, index) => (
                  <Box component={'div'} key={index} mr={'0.5vw'} height={'5vw'} display={'flex'} alignItems={'center'}>
                    <Tooltip title={typeof (image) === "string" ? null : image?.name} arrow placement='top'>
                      <Box component={'img'}
                        onClick={() => {
                          if (typeof (image) === "string") {
                            if (image.split('.').pop() === "jpg" || image.split('.').pop() === "jpeg" || image.split('.').pop() === "JPG" || image.split('.').pop() === "png") {
                              this.setState({ isImgClicked: true, popUpOpen: true, selectedImage: image })
                            }
                            else {
                              window.open(image)
                            }
                          }
                        }}
                        src={typeof (image) === "string" ?
                          (image.split('.').pop() === "jpg" || image.split('.').pop() === "jpeg" || image.split('.').pop() === "JPG" || image.split('.').pop() === "png" ?
                            image : ImagePaths.DocumentPreview.default) : ((image?.type === "image/jpeg" || image?.type === "image/png") ? ImagePaths.ImagePreview.default : ImagePaths.DocumentPreview.default)}
                        height={'4vw'} width={'3.5vw'}
                      />
                    </Tooltip>
                    <Box><IconButton sx={{ position: 'relative', bottom: '1.2vw', right: '1.4vw' }} size="small"
                      onClick={() => {
                        this.setState({ selectedImage: null }, () => { this.onImageRemove(index) });
                      }}>
                      <CancelTwoToneIcon fontSize='small' color="error" />
                    </IconButton>
                    </Box>
                  </Box>
                ))}
              </Box> : null}
          </Box>
          <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'}>
            <Button
              sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
              variant='outlined'
              onClick={() => this.onClearTestReportData()}
            >Clear</Button>
            <Button
              sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
              variant='contained'
              disabled={this.state.addTestData}
              onClick={() => this.postTestReportData()}
            >Add</Button>
          </Box>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }
  HistoryPopup = () => {
    return (
      <div>
        <Modal open={this.state.historyPopup}>
          <Box component={"div"} className='eMed_Doc_Notes_Counsellor_Pop_Up' style={{ width: "70vw", height: "70vh", maxHeight: "100%" }}>
            <Box component={'div'} p={'0.75vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} style={{ borderBottom: "1px solid gray" }}>
              <Typography fontSize={'0.95vw'} fontWeight={600} color={Colors.SecondaryText}>Laboratory Test Report History</Typography>
              <Button
                className='Common_Btn_Min_Width'
                onClick={() => this.setState({ historyPopup: false })}
              >
                <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
              </Button>
            </Box>
            <Box sx={{ maxHeight: '60vh', overflow: 'auto', border: '1px solid lightgray' }} component={'div'}>
              <Table stickyHeader size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell width={'20%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Test Name</TableCell>
                    {this.state.test_date?.length > 0 ? this.state.test_date?.map((item) => (
                      <TableCell width={'200vw'} sx={{ textAlign: 'center', fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>{item}</TableCell>
                    )) : null}
                  </TableRow>

                </TableHead>
                <TableBody>
                  {this.state.labhistory?.length > 0 ? this.state.labhistory?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item.test_name}</TableCell>
                      {this.state.test_date?.length > 0 ? this.state.test_date?.map((date) => (
                        <TableCell width={'20%'} sx={{ textAlign: 'center', fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>{item[date] ? item[date] : '-'}</TableCell>
                      )) : null}
                    </TableRow>
                  )) : null}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Modal>
      </div>
    )
  }
  render() {
    let attender = {
      lineOne: "Attender Name",
      lineTwo: `${this.state.patientDetails?.attender_name ? this.state.patientDetails?.attender_name :
        this.state.patientDetails?.caretaker_name ? this.state.patientDetails?.caretaker_name : "-"}`,
      lineFour: 'Contact Number',
      LineFive: `${this.state.patientDetails?.attender_mobile ? this.state.patientDetails?.attender_mobile :
        this.state.patientDetails?.caretaker_mobile ? this.state.patientDetails?.caretaker_mobile : "-"}`
    }
    let myUser = JSON.parse(localGetItem("loggedInUserInfo"))
    let UserData = myUser;
    return (
      <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'74vh'} p={'0.5vw'}>
        <Box component={'div'} display={'flex'}>
          <Box component={'div'} className='doc_header_card' mr={'0.5vw'}>
            <CommonPatientDetails data={this.state.patientDetails} showDetailed={true} fromDoctor={true} />
          </Box>
          {(UserData?.service_type === 'DB') ? <Box component={'div'} onClick={() => { this.setState({ historyPopup: true }) }} className='doc_header_card' sx={{ width: "4.5vw", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", cursor: "pointer" }} mr={'0.5vw'}>
            <Box component={'img'} src={ImagePaths.PreviousPrescription.default} height={'1.8vw'} width={'1.8vw'} />
          </Box> : null}

          {this.HistoryPopup()}
          {/* <CommonPatientCard details={attender} showDetailed={true} /> */}
        </Box>
        <Box component={'div'} display={'flex'} mt={'0.5vw'} height={'61.5vh'}>
          <Box component={'div'} flex={0.65} overflow={'scroll'}>
            {this.renderLeftSide()}
          </Box>
          <Box component={'div'} flex={0.35} border={'1px solid lightgray'}>
            {this.renderRightSide()}
          </Box>
          {this.renderImageViewerPopup()}
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        <Loader loaderOpen={this.state.isLoader} />
      </Box>
    )
  }
}
