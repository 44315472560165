import React, { Component} from "react";
import { Box, Button ,Stack, Tooltip, IconButton} from "@mui/material";
import { ImagePaths } from '../../../Utility/ImagePaths';
import {  DataGrid } from '@mui/x-data-grid';
import { Trans, withTranslation } from 'react-i18next';
import VendorDetails from "./VendorDetails";
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import CommonGridHeader from "../../../Components/Common Components/CommonComponents";
import { CheckAccessFunc } from "../../../Components/CommonFunctions/CommonFunctions";


class Vendor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data : [],
            vendor_id: null,
            isEditClicked: false,
            isViewClicked: false,
            AddVendor_Flag: false,
            current_page: 0,
            pageSize: 10,
            searchKey: '',
            disableBtn: false,
        }
    } 
    componentDidMount() {
        this._getVendorDetails();
    }

    componentWillReceiveProps(props){
        if(props.search_key != this.state.searchKey){
            this.setState({
                searchKey: props.search_key
            },() => {
                this._getVendorDetails()
            })
        }
    }
    

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    _getVendorDetails = () => {
        this.LoaderFunction(true)
        this.setState({
            data: [],
            pageSize: 10,
            current_page: 0,
        })
        RestAPIService.getAll(`${Serviceurls.LAB_VENDOR}?search_key=${this.state.searchKey}`)
        .then(response => {
            if (response.data.status == "success") {
                this.setState({
                    data: response.data.data,
                },()=>{this.LoaderFunction(false)});
            }
        })
        .catch(error => {
            this.LoaderFunction(false)
            if (error.response.data.status == "fail") {
                this.SuccessErrorBox(error.response.data.message, false);
            }
        });
    }

    handleClose = () => {
        let states = this.state
        if(states.AddVendor_Flag){
            states["AddVendor_Flag"] = false
        }else if(states.isEditClicked){
            states["isEditClicked"] = false
        }else if(states.isViewClicked){
            states["isViewClicked"] = false
        }
        this.setState({
            states
        },()=>{
            this._getVendorDetails();
        })
        
    }

    SuccessErrorBox = (message,type) => {
        this.props.responseSuccessErrorMessage(message,type)
    }

    handleEdit = (event,values) => {
        this.setState({
            isEditClicked: true,
            vendor_id: values.row.vendor_id
        })
    }
    
    handleView = (event,values) => {
        this.setState({
            isViewClicked: true,
            vendor_id: values.row.vendor_id
        })
    }

    getVendorListprint =() => {
        try{
            this.setState({disableBtn: true})
            RestAPIService.getAll(`${Serviceurls.LAB_VENDOR}?export_type=pdf`)
            .then((response) => {
                const file = new Blob(
                    [response.data],
                    { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
                this.setState({disableBtn: false})
            }).catch((e) => {
                this.SuccessErrorBox(e.response.data.message, false);
                this.setState({disableBtn: false})
            })
        }catch(e){
            this.SuccessErrorBox(e.message, false)
            this.setState({disableBtn: false})
        }
    }

    getVendorListexcel = () => {
        try {
          this.setState({disableBtn: true})
          RestAPIService.excelGet(Serviceurls.LAB_VENDOR + "?export_type=excel" + `&search_key=${this.state.searchKey}`).
            then((response) => {
              if(response.data){
              var pom = document.createElement('a');
              var csvContent = response.data; //here we load our csv data 
        
              let filename = response.headers["content-disposition"].split("filename=")[1]
        
              var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
              var url = URL.createObjectURL(blob);
              pom.href = url;
              pom.setAttribute('download', filename ? filename : 'registry.xlsx');
              pom.click();
              this.setState({disableBtn: false})
              }
            }).catch((error) => {
                this.SuccessErrorBox(error.response.data.message, false);
                this.setState({disableBtn: false})
            })
        } catch (error) {
            this.SuccessErrorBox(error.message, false);
            this.setState({disableBtn: false})
        }
      }

    handleCellClick = (param,event) => {event.stopPropagation()}
    render() {
        const { t } = this.props;
        const columns = [
            { field: 's.no', headerClassName: 'grid--header', headerName: t('SNo'), flex: 0.012, sortable: false, hideable: false,renderCell: (index) => index.api.getRowIndex(index.row.vendor_id) + 1},
            { field: 'created_date', headerClassName: 'grid--header', headerAlign: 'center', headerName: t('RegistrationDate'), flex: 0.06, sortable: false, hideable: false , align:"center", renderCell: (cellvalues) => (
                cellvalues?.row?.created_date ? <Tooltip placement="top" title={cellvalues?.row?.created_date} arrow><div>{cellvalues?.row?.created_date}</div></Tooltip> : "-"
            )},
            { field: 'name', headerClassName: 'grid--header', headerName: t('VendorName'), flex: 0.06, sortable: false, hideable: false, renderCell:(cellvalues)=>(
                cellvalues?.row?.name.length > 15 ? <Tooltip placement="top" title={cellvalues?.row?.name} arrow><div>{cellvalues?.row?.name.slice(0, 15) + '...'}</div></Tooltip> : cellvalues?.row?.name || "-"
            )},
            { field: 'gstin_number', headerClassName: 'grid--header', headerName: t('GSTIN'), flex: 0.07, sortable: false, headerAlign: "center", align: "center", renderCell:(cellvalues) =>(
                cellvalues?.row?.gstin_number.length > 15 ? <Tooltip placement="top" title={cellvalues?.row?.gstin_number} arrow><div>{cellvalues?.row?.gstin_number.slice(0, 15) + '...'}</div></Tooltip>  : cellvalues?.row?.gstin_number || "-"
            )},
            { field: 'dl_no', headerClassName: 'grid--header', headerName: t('RegistrationNumber'), flex: 0.07, sortable: false, headerAlign: "center", align: "center", hideable: false, renderCell:(cellvalues) =>(
                cellvalues?.row?.dl_no.length > 15 ? <Tooltip placement="top" title={cellvalues?.row?.dl_no} arrow><div>{cellvalues?.row?.dl_no.slice(0, 15) + '...'}</div></Tooltip> :  cellvalues?.row?.dl_no ||  "-"
            )},
            { field: 'dl_no_2', headerClassName: 'grid--header', headerName: t('LicenceNo02'), flex: 0.07, sortable: false,  headerAlign: "center", align: "center", renderCell:(cellvalues)=>(
                cellvalues?.row?.dl_no_2.length > 15 ? <Tooltip placement="top" title={cellvalues?.row?.dl_no_2} arrow><div>{cellvalues?.row?.dl_no_2.slice(0, 15) + '...'}</div></Tooltip>  : cellvalues?.row?.dl_no_2 || "-"
            )},
            { field: 'address_line_1', headerClassName: 'grid--header', headerName: t('AddressLine1'), flex: 0.05, sortable: false, renderCell:(cellvalues)=>(
                cellvalues?.row?.address_line_1.length > 15 ? <Tooltip placement="top" title={cellvalues?.row?.address_line_1} arrow><div>{cellvalues?.row?.address_line_1.slice(0, 15) + '...'}</div></Tooltip>  : cellvalues?.row?.address_line_1 || "-"
            ) },
            { field: 'address_line_2', headerClassName: 'grid--header', headerName: t('AddressLine2'), flex: 0.05, sortable: false, renderCell:(cellvalues)=>(
                cellvalues?.row?.address_line_2.length > 15 ? <Tooltip placement="top" title={cellvalues?.row?.address_line_2} arrow><div>{cellvalues?.row?.address_line_2.slice(0, 15) + '...'}</div></Tooltip> : cellvalues?.row?.address_line_2 || "-"
            )},
            { field: 'city_name', headerClassName: 'grid--header', headerName: t('City'), flex: 0.05, sortable: false, renderCell:(cellvalues)=>(
                cellvalues?.row?.city_name ? <Tooltip placement="top" title={cellvalues?.row?.city_name} arrow><div>{cellvalues?.row?.city_name}</div></Tooltip> : "-"
            )},
            { field: 'state', headerClassName: 'grid--header', headerName: t('State'), flex: 0.05, sortable: false , renderCell:(cellvalues)=>(
                cellvalues?.row?.state ? <Tooltip placement="top" title={cellvalues?.row?.state} arrow><div>{cellvalues?.row?.state}</div></Tooltip> : "-"
            )},
            { field: 'country', headerClassName: 'grid--header', headerName: t('Country'), flex: 0.05, sortable: false, renderCell:(cellvalues)=>(
                cellvalues?.row?.country ? <Tooltip placement="top" title={cellvalues?.row?.country} arrow><div>{cellvalues?.row?.country}</div></Tooltip> : "-"
            )},
            { field: 'pincode', headerClassName: 'grid--header', headerName: t('Pincode'), flex: 0.05, sortable: false, renderCell:(cellvalues)=>(
                cellvalues?.row?.pincode ? <Tooltip placement="top" title={cellvalues?.row?.pincode} arrow><div>{cellvalues?.row?.pincode}</div></Tooltip> : "-"
            )},
            { field: 'mobile_number', headerClassName: 'grid--header', headerName: t('PhoneNumber'), flex: 0.05, sortable: false, hideable: false, renderCell:(cellvalues)=>(
                cellvalues?.row?.mobile_number ? <Tooltip placement="top" title={cellvalues?.row?.mobile_number} arrow><div>{cellvalues?.row?.mobile_number}</div></Tooltip> : "-"
            )},
            { field: 'phone_number', headerClassName: 'grid--header', headerName: t('OfficeNumber'), flex: 0.05, sortable: false, renderCell:(cellvalues)=>(
                cellvalues?.row?.phone_number ? <Tooltip placement="top" title={cellvalues?.row?.phone_number} arrow><div>{cellvalues?.row?.phone_number}</div></Tooltip> : "-"
            )},
            { field: 'email', headerClassName: 'grid--header', headerName: t('Emailid'), flex: 0.05, sortable: false, hideable: false, renderCell:(cellvalues)=>(
                cellvalues?.row?.email ? <Tooltip placement="top" title={cellvalues?.row?.email} arrow><div>{cellvalues?.row?.email}</div></Tooltip> : "-"
            )},
            { field: 'actions', headerClassName: 'grid--header', headerName: t('Action'),type :'actions', flex: 0.05, sortable: false, hideable: false,
           renderCell : (cellvalues)=>{
            return(
                <Stack direction = "row" justifyContent = "space-around" alignItems="center" spacing={1}>
                <Tooltip placement ="top" title={t("Edit")} arrow><IconButton disabled={!CheckAccessFunc("Laboratory", "Purchase", "Vender List", null, "Tab")?.editAccess} onClick = {(event)=>{this.handleEdit(event,cellvalues)}} ><img className="emedhub_directPo_img" src={ImagePaths.LabEditButton.default} alt={"edit"} /></IconButton></Tooltip>
                <Tooltip placement ="top" title={t("View")} arrow><IconButton  onClick = {(event)=>{this.handleView(event,cellvalues)}} ><img className="emedhub_directPo_img" src={ImagePaths.LabViewButton.default} alt={"view"} /></IconButton></Tooltip>
                </Stack>
            );
           }}
        ]
        let {data , pageSize, isEditClicked, isViewClicked, current_page} = this.state;
        return (
            <Box className = "emedhub__purchase_page" sx={{'& .grid--header': {backgroundColor: '#FAF9F9'}}}>
                <Stack sx={{margin: "1vw"}} spacing={2} direction="row"  justifyContent="flex-end" alignItems="center">
                    <div className= "emedhub_vendor_btnDiv">
                        <Button disabled={!CheckAccessFunc("Laboratory", "Purchase", "Vender List", null, "Tab")?.editAccess} variant="contained" size="small" sx={{marginRight:"0.5vw", textTransform:"capitalize"}} onClick = {()=>{this.setState({ AddVendor_Flag : true })}}>{t('Newvendor')}</Button>
                    </div>
                    
                    <Stack direction= "row" spacing={1} alignItems="center">
                        <IconButton size="small" emed_tid='Vendor_List_PrintBtn' disabled={this.state.disableBtn} onClick={()=>this.getVendorListprint()} ><img className="emedhub_directPo_img"  src={ImagePaths.LabPrintIcon.default } alt='print' /></IconButton>
                        <IconButton size="small" emed_tid='Vendor_List_ExportBtn' disabled={this.state.disableBtn} onClick={()=>this.getVendorListexcel()}><img className="emedhub_directPo_img"  src={ImagePaths.LabUploadButton.default} alt='upload' /></IconButton>
                    </Stack>
                </Stack>
                <div id = "emedhub_ventor_Table">
                <DataGrid 
                        rows={data}
                        columns={columns}
                        sx={{overflowX:"scroll"}}
                        loading={this.state.isLoader}
                        hideFooterSelectedRowCount
                        initialState={{
                            columns: {
                              columnVisibilityModel: {
                                    gstin_number: false,
                                    dl_no_2: false,
                                    address_line_1: false,
                                    address_line_2: false,
                                    pincode: false,
                                    city_name: false,
                                    country: false,
                                    state: false,
                                    phone_number: false
                              },
                            },
                        }}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                          }}
                        components={{
                            Toolbar: CommonGridHeader.CommonToolBar,
                            NoRowsOverlay: ()=>{
                                return(
                                    <Stack direction="row" justifyContent = "center" alignItems = "center" sx={{height:"53vh"}}>
                                    {t("NoRecordsFound")}
                                    </Stack>
                                )
                            }
                          }}
                        getRowId={(row) => row.vendor_id}
                        onCellClick = {this.handleCellClick}
                        onRowClick = {this.handleCellClick}
                        rowCount={data.length}
                        page={current_page}
                        onPageChange={(newPage)=>this.setState({current_page: newPage})}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize})}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination />
                </div>
                {this.state.AddVendor_Flag ? <VendorDetails popUpClose = {this.handleClose.bind(this)} SuccessErrorBox = {this.SuccessErrorBox.bind(this)}/> :  null}
                {isEditClicked ? <VendorDetails popUpClose = {this.handleClose.bind(this)} SuccessErrorBox = {this.SuccessErrorBox.bind(this)} edit_id = {this.state.vendor_id}/> :  null}
                {isViewClicked ? <VendorDetails popUpClose = {this.handleClose.bind(this)} SuccessErrorBox = {this.SuccessErrorBox.bind(this)} view_id = {this.state.vendor_id}/> :  null}
            </Box>
        )
    }
}
export default withTranslation()(Vendor);