import { Autocomplete, Box, Button, Chip, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import '../../../FrontOffice/IPPatients/PatientsDetails/ClinicalNotes/DoctorNotes.css'
import { Colors } from '../../../../Styles/Colors';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';
import { DeletePopup } from '../../../../Components/Common Components/CommonComponents';
import { debounce } from '../../../../Components/CommonFunctions/CommonFunctions';
import { getCachevalue } from '../../../../Utility/Services/CacheProviderService';

class DocDiagnosisRight extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ProvDiagnosis: "",
            FrequentData: [],
            IcdSearchKey: "",
            ICDdatas: [],
            selecticd: null,
            TouchedProvDiag: false,
            SearchBy: "icd_code",
            provDiagnosisSuggestion: []
        }
        this.debounceTimer = null;
    }

    componentDidMount() {
        this.GetICDFrequents()
        let provDia = getCachevalue('provisional_diagnosis')
        this.setState({ ProvDiagnosis: provDia })
    }

    componentDidUpdate(preProps){
        if(preProps !== this.props){
            let provDia = getCachevalue('provisional_diagnosis')
            this.setState({ ProvDiagnosis: provDia })
        }
    }

    componentWillUnmount() {
        clearTimeout(this.debounceTimer)
    }

    getICDcode = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_REGISTRY_ICD_SEARCH + `?${this.state.SearchBy}=${this.state.IcdSearchKey}`)
                .then(response => {
                    if (response.data.status === 'success') {
                        this.setState({
                            ICDdatas: response.data ? response.data.data : []
                        })
                    }
                }).catch(e => {
                    if (e.response?.data?.status === "fail") {
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.errorMessage(e.message)
                    }
                })

        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    getProvDiagnosisData = (searchValue = '') => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_PROVISIONAL_DIAGNOSIS_SUGG_GET + `?provisional_diagnosis=${searchValue ? searchValue : ''}`)
                .then(response => {
                    if (response.data.status === 'success') {
                        this.setState({
                            provDiagnosisSuggestion: response.data.data ? response.data.data : []
                        })
                    }
                }).catch(e => {
                    if (e?.response?.data?.message) {
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.errorMessage(e.message)
                    }
                })

        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    GetICDFrequents() {
        try {
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_DIAGONIS_ICD_GET).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            FrequentData: response.data.data
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    ClearData() {
        this.setState({
            // ProvDiagnosis: "",
            IcdSearchKey: "",
            ICDdatas: [],
            selecticd: null,
            TouchedProvDiag: false,
            SearchBy: "icd_code",
        })
    }

    PostDiagnosis = () => {
        try {
            var states = this.state
            let data = {
                appointment_id: this.props?.PatientData?.appointment_id,
                icd_codes: states?.selecticd ? [states.selecticd?.icd_code] : [],
                patient_id: this.props?.PatientData?.patient_id,
            }
            if (this.state.ProvDiagnosis) {
                // data["provisional_diagnosis"] = CommonValidation.removeSpace(states?.ProvDiagnosis)
                data["provisional_diagnosis"] = (states?.ProvDiagnosis)
            }
            // if ((states?.selecticd && Object.keys(states?.selecticd).length > 0) || CommonValidation.removeSpace(states?.ProvDiagnosis)) {
            if ((states?.selecticd && Object.keys(states?.selecticd).length > 0) || (states?.ProvDiagnosis)) {
                RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_ASSESSEMENT).
                    then((response) => {
                        if (response.data.status === "success") {
                            this.successMessage(response.data.message)
                            this.ClearData()
                            this.GetICDFrequents()
                            this.props.CallgetApi('diagnosis')
                        } else {
                            this.errorMessage(response.data.message)
                        }
                    }).catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                this.errorMessage("Enter Diagnosis")
            }

        } catch (error) {
            this.errorMessage(error.message)
        }
    }
    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    deleteFrequencysugg = () => {
        try {
            this.setState({ removeData: true })
            RestAPIService.delete(`${Serviceurls.DOC_DOCTOR_NOTES_DIAGONIS_ICD_GET}?icd_code=${this.state.SelectedDeleteChip?.icd_code}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.successMessage(response?.data?.message)
                        this.GetICDFrequents()
                        this.setState({ deletePopup: false, SelectedDeleteChip: null, removeData: false })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ removeData: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ removeData: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ removeData: false })
            this.errorMessage(error.message)
        }
    }

    popupClose = () => {
        try {
            this.setState({
                deletePopup: false
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    // handleProvDiagnosisChange = debounce((value) => {
    //     this.setState({ ProvDiagnosis: this.state.ProvDiagnosis?.concat(value) }, () => {
    //         if (value) {
    //             this.getProvDiagnosisData(this.state.ProvDiagnosis)
    //         } else {
    //             this.setState({ provDiagnosisSuggestion: [] })
    //         }
    //     })
    // }, 500)


    render() {
        const { t } = this.props
        var states = this.state;
        return (
            <Box>
                <Typography className='eMed_refdoc_title'>{t("Diagnosis")}</Typography>
                <Box height={"56.3vh"} sx={{ overflowY: 'scroll' }}>
                    <Box>
                        <Autocomplete
                            noOptionsText={states.IcdSearchKey === '' ? 'Please Search...' : 'No Data Found...'}
                            options={states.ICDdatas}
                            getOptionLabel={(options) => options.icd_code + " | " + options.icd_short_desc + " | " + options.icd_desc}
                            onChange={(e, value) => {
                                this.setState({
                                    testRow: value,
                                    selecticd: value
                                })
                            }}
                            value={states.selecticd}
                            size='small'
                            className='eMed_DocNotes_icd_TxtBox'
                            disablePortal
                            id="combobox-demo"
                            renderInput={(params) => <TextField onChange={(event) => {
                                const value = event.target.value
                                if (value === "" || value != "") { this.setState({ IcdSearchKey: value }, () => { this.getICDcode() }) }
                            }} {...params} placeholder={this.state.SearchBy === "icd_code" ? "Enter ICD Code" : "Enter Description"} />}
                        />
                        <FormControl sx={{ marginLeft: "1vw" }}>
                            <RadioGroup row
                                value={this.state.SearchBy}
                                onChange={(e) => {
                                    this.setState({ SearchBy: e.target.value })
                                }}
                            >
                                <FormControlLabel value="icd_code" control={<Radio size='small' />} label="Search by ICD Code" />
                                <FormControlLabel value="desc" control={<Radio size='small' />} label="Search by Description" />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    <Divider textAlign="left" sx={{ marginTop: '0.5vw' }}><Typography sx={{ fontSize: '0.8vw' }}>ICD Codes</Typography></Divider>
                    <Box className="eMed_DocNotes_FreqChips_Wrapp">
                        {
                            this.state.FrequentData.length > 0 ? this.state.FrequentData.map((item, index) => (
                                <Chip
                                    size='small'
                                    className={`eMed_chip ${this.state.selecticd?.icd_code === item?.icd_code ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                    key={index}
                                    label={item.icd_code}
                                    variant={this.state.selecticd?.icd_code === item?.icd_code ? 'contained' : "outlined"}
                                    onClick={() => { this.setState({ selecticd: item }) }}
                                    onDelete={() => { this.setState({ deletePopup: true, SelectedDeleteChip: item }) }}
                                />
                            )) : null
                        }
                    </Box>
                    <Divider textAlign="left" sx={{ marginTop: '0.5vw' }}><Typography sx={{ fontSize: '0.8vw' }}>Short Description</Typography></Divider>
                    <Box className="eMed_DocNotes_FreqChips_Wrapp">
                        {
                            this.state.FrequentData.length > 0 ? this.state.FrequentData.map((item, index) => (
                                <Chip
                                    size='small'
                                    className={`eMed_chip ${this.state.selecticd?.icd_code === item?.icd_code ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                    key={index}
                                    label={item.icd_short_desc}
                                    variant={this.state.selecticd?.icd_code === item?.icd_code ? 'contained' : "outlined"}
                                    onClick={() => { this.setState({ selecticd: item }) }}
                                    onDelete={() => { this.setState({ deletePopup: true, SelectedDeleteChip: item }) }}
                                />
                            )) : null
                        }
                    </Box>
                    <Divider textAlign="left" sx={{ marginTop: '0.5vw' }}><Typography sx={{ fontSize: '0.8vw' }}>Long Description</Typography></Divider>
                    <Box className="eMed_DocNotes_FreqChips_Wrapp">
                        {
                            this.state.FrequentData.length > 0 ? this.state.FrequentData.map((item, index) => (
                                <Chip
                                    size='small'
                                    className={`eMed_chip ${this.state.selecticd?.icd_code === item?.icd_code ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                    key={index}
                                    label={item.icd_desc}
                                    variant={this.state.selecticd?.icd_code === item?.icd_code ? 'contained' : "outlined"}
                                    onClick={() => { this.setState({ selecticd: item }) }}
                                    onDelete={() => { this.setState({ deletePopup: true, SelectedDeleteChip: item }) }}
                                />
                            )) : null
                        }
                    </Box>
                    <Box>
                        <Typography className='eMed_refdoc_title'>{t("Provisional Diagnosis")}</Typography>
                        <Autocomplete
                            freeSolo
                            size="small"
                            sx={{ width: '25vw', margin: '0.5vw 1.5vw' }}
                            options={this.state.provDiagnosisSuggestion}
                            getOptionLabel={(option) => (typeof option === "string" ? option : option?.provisional_diagnosis)}
                            value={this.state.ProvDiagnosis}
                            onChange={(event, newValue) => {
                                this.setState({
                                    ProvDiagnosis: newValue?.provisional_diagnosis,
                                });
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    multiline
                                    label={t("Provisional Diagnosis")}
                                    placeholder={t("Provisional Diagnosis (Max 250 Characters)")}
                                    inputProps={{
                                        ...params.inputProps,
                                        maxLength: 250,
                                        emed_tid: 'prov_diagnosis_dropDown_testID'
                                    }}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        // this.handleProvDiagnosisChange(value)
                                        this.setState({ ProvDiagnosis: value })
                                        clearTimeout(this.debounceTimer)
                                        this.debounceTimer = setTimeout(() => {
                                                if (value) {
                                                    this.getProvDiagnosisData(value)
                                                } else {
                                                    this.setState({ provDiagnosisSuggestion: [] })
                                                }
                                        }, 500)
                                    }}
                                />
                            )}
                        />
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center" height={"6vh"}>
                    <Button variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.ClearData() }}>{t("Clear")}</Button>
                    <Button variant='contained' size="small" id="eMed_Config_btn" onClick={() => { this.PostDiagnosis() }}>{t("Add")}</Button>
                </Stack>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.deletePopup ? <DeletePopup
                    DeletTitle={`Are you sure ? Do you want to remove ${this.state.SelectedDeleteChip?.icd_code} from suggestion ?`}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this.deleteFrequencysugg.bind(this)}
                    disable={this.state.removeData}
                /> : null}
            </Box>
        )
    }
}
export default withTranslation()(DocDiagnosisRight)

