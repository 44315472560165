import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import "./PharmaPurchase.css"
import { Box, Button, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { ImagePaths } from '../../../Utility/ImagePaths';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import Loader from '../../../Components/Loader';
import { City, Country, State } from 'country-state-city';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';

const VendorcategoryList = [
    { value: 1, label: "Consumable" },
    { value: 2, label: "Non Consumable" },
    { value: 3, label: "Both" }
]
const  VendorTypeList = [
    { value: 1, label: "Internal" },
    { value: 2, label: "External" },  
]

class PharmaAddvendor extends Component {
    constructor(props) {
        super(props)
        this.state = {
            VendorCategory: 1,
            VendorName: "",
            VendorType : "External",
            GSTIN: "",
            DLno01: "",
            DLno02: "",
            DLno03: "",
            DLno04: "",
            DLno05: "",
            DLno06: "",
            Address1: "",
            Address2: "",
            AreaName: "",
            City: "",
            cityName: [],
            country_code: 'IN',
            state_code: 'TN',
            AllCountry: [],
            AllStates: [],
            // State: "Tamil Nadu",
            // Country: "India",
            Pincode: "",  
            Mobile: "",
            Landline: "",
            mailID: "",
            ErrorShow: false,
            VendorId: this.props.history?.location?.state?.VendorID ? this.props.history?.location?.state?.VendorID : null,
            OnlyView: this.props.history?.location?.state?.OnlyView ? this.props.history?.location?.state?.OnlyView : null,
            addVendorSuccessMsg : '',
            disableBtn: false,
            glCode: "",
            VendorTypeList : VendorTypeList,
            internal_store_enabled : false,
            main_pharmacy_enabled : false,

        }
    }

    componentDidMount() {
        let internalStoreEnabled = JSON.parse(localGetItem("internalStoreEnabled"))
        let mainPharmacyEnabled = JSON.parse(localGetItem("mainPharmacyEnabled"))
        this.setState({
            cityName: City.getCitiesOfState("IN", "TN"),
            AllCountry: Country.getAllCountries(),
            AllStates: State.getStatesOfCountry("IN"),
            internal_store_enabled: internalStoreEnabled,
            main_pharmacy_enabled: mainPharmacyEnabled
        })
        if (this.state.VendorId) {
            this.getVendorDetails()
        }
    }

    getVendorDetails() {
        try {
            this.LoaderFunction(true);
            RestAPIService.getAll(Serviceurls.PURCHASE_VENDOR_ADD + "?vendor_id=" + this.state.VendorId)
                .then((response) => {
                    if (response.data.status === "success") {
                        const datas = response.data?.data;
                        const countryIsoCode = this.state.AllCountry.find((item)=>(item.name === datas?.country))?.isoCode;
                        const allState = State.getStatesOfCountry(countryIsoCode);
                        const stateIsoCode = allState?.find((item)=>(item.name === datas?.state))?.isoCode;
                        const getValue = (key) => datas[key] || "";
                        this.LoaderFunction(false);
                        this.setState({
                            VendorCategory: getValue("vendor_type"),
                            VendorName: getValue("name"),
                            VendorType: getValue("store_vendor_type"),
                            GSTIN: getValue("gstin_number"),
                            DLno01: getValue("dl_no"),
                            DLno02: getValue("dl_no_2"),
                            DLno03: getValue("dl_no_3"),
                            DLno04: getValue("dl_no_4"),
                            DLno05: getValue("dl_no_5"),
                            DLno06: getValue("dl_no_6"),
                            Address1: getValue("address_line_1"),
                            Address2: getValue("address_line_2"),
                            AreaName: getValue("area_name"),
                            City: getValue("city"),
                            country_code: countryIsoCode,
                            AllStates: allState,
                            state_code: stateIsoCode,
                            // State: response.data?.data?.state ? response.data?.data?.state : "",
                            // Country: response.data?.data?.country ? response.data?.data?.country : "",
                            Pincode: getValue("pincode"),
                            Mobile: getValue("mobile_number"),
                            Landline: getValue("phone_number"),
                            mailID: getValue("email"),
                            glCode: getValue("ledger_code"),
                            cityName: City.getCitiesOfState(countryIsoCode , stateIsoCode)
                        })
                    }
                    else {
                        this.LoaderFunction(false);
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.LoaderFunction(false);
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.LoaderFunction(false);
            this.errorMessage(e.message)
        }
    }


    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message, MoveToHome = false) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        }, () => {
            if (MoveToHome) {
                this.props.history.push({ pathname: '/PharmacyPurchase/VendorList', state: {addVendorSuccessMsg : message } })
            }
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    errorshowflag(key, value) {
        switch (key) {
            case ("VendorName"):
                return value === "" ? true : false
            case ("VendorType"):
                return value === "" ? true : false
            case ("DLno01"):
                return value === "" ? true : false
            case ("Address1"):
                return value === "" ? true : false
            case ("AreaName"):
                return value === "" ? true : false
            case ("City"):
                return value === "" ? true : false
            case "GSTIN":
                return value.length !== 15 ? true : false
            case "Pincode":
                return value.length !== 6 ? true : false
            case "Mobile":
                return value.length !== 10 ? true : false
            case "mailID":
                return (value !== "" || CommonValidation.emailValidation(this.state.mailID)) ? false : true
            default:
                return false
        }
    }
    errorshowhelptext(key, value) {
        switch (key) {
            case ("VendorName"):
                return value === "" ? "Enter Vendor Name" : ""
            case ("VendorType"):
                return value === "" ? "Enter Vendor Type" : ""
            case ("DLno01"):
                return value === "" ? "Enter DL No" : ""
            case ("Address1"):
                return value === "" ? "Enter Address Line 1" : ""
            case ("AreaName"):
                return value === "" ? "Enter Area Name" : ""
            case ("City"):
                return value === "" ? "Enter City Name" : ""
            case "GSTIN":
                return value.length !== 15 ? "GSTIN must be 15 Characters" : ""
            case "Pincode":
                return value.length !== 6 ? "Pincode must be 6 Digits" : ""
            case "Mobile":
                return value.length !== 10 ? "Mobile must be 10 Digits" : ""
            case "mailID":
                return (value !== "" || CommonValidation.emailValidation(this.state.mailID)) ? "" : "Invalid Email ID"
            default:
                return ""
        }
    }

    onChangeHandler = (label, stateKey, value) => {
        let isValid = false;
        const checkName = label === "DL No" ? "DL No" : stateKey;
        switch (checkName) {
            case "Pincode":
            case "Mobile":
            case "Landline":
                isValid = CommonValidation.numberOnly(value);
                break;
            case "VendorName":
            case "DL No":
                isValid = CommonValidation.ALPHA_NUM_SPLCHAR(value);
                break;
            case "GSTIN":
                isValid = CommonValidation.gstnumberValidation(value);
                if (isValid && value) {
                    value = value.toUpperCase();
                }
                break;
            case "glCode":
                isValid = CommonValidation.ALPHA_NUMARIC(value);
                break;
            default:
                isValid = true;
                break;
        }

        if ((isValid || value === "") && this.state[stateKey] !== value) {
            this.setState({ [stateKey]: value });
        }
    };

    renderTextBox(label, Key, maxLength, width, multiline = false, mandatory = false) {
        return (
            <TextField
                disabled={this.state.OnlyView || false}
                inputProps={{ maxLength: maxLength, emed_tid: label }}
                sx={{ width: width }}
                autoComplete='off'
                size='small'
                label={`${label}${mandatory ? " *" : ""}`}
                value={this.state[Key] || ""}
                multiline={multiline}
                rows={4}
                error={this.state.ErrorShow && this.errorshowflag(Key, this.state[Key] || "")}
                helperText={this.state.ErrorShow ? this.errorshowhelptext(Key, this.state[Key] || "") : ""}
                onChange={(e) => {this.onChangeHandler( label, Key, e.target.value)}}
            />
        )
    }
    renderAutoSelect =(label, Key, maxLength, width , multiline = false, mandatory = false, disable) =>{
       let states = this.state
        return (
            <FormControl size='small'  >
                 <InputLabel id="title-select-label">{`${label}${mandatory ? " *" : ""}`}</InputLabel>
                 <Select
                    readOnly = {!this.state.main_pharmacy_enabled}
                    disabled={disable || !this.state.main_pharmacy_enabled || this.state.OnlyView}
                    inputProps={{ maxLength: maxLength }}
                    sx={{ width: "30vw"}}
                    label={`${label}${mandatory ? " *" : ""}`}
                    value={this.state[Key] || ""}
                    emed_tid={"VendorTypeTestID"}
                    onChange={(e) => { 
                        states[Key]  =  e.target.value 
                        this.setState({states}) 
                    }}
                >
                    {states.VendorTypeList?.length > 0 ? states.VendorTypeList.map((item) => (
                        <MenuItem value={item.label}>{item.label}</MenuItem>
                    )) : null}

                </Select>
            </FormControl>
        )
    }

    onSlctHandler = (stateKey, e) => {
        switch (stateKey) {
            case "City":
                this.setState({
                    City: e.target.value,
                    Pincode: ''
                })
                break;
            case "state_code":
                this.setState({
                    state_code: e.target.value,
                    cityName: City.getCitiesOfState(this.state.country_code, e.target.value),
                    City: '',
                    Pincode: ''
                })
                break;
            default:
                this.setState({
                    country_code: e.target.value,
                    state_code: "",
                    City: '',
                    AllStates: State.getStatesOfCountry(e.target.value),
                    cityName: '',
                    Pincode: ''
                })
                break;
        }
    }

    renderSelect = (label, stateKey, list, showKey, valueKey, width = "100%") => {
        const { t } = this.props;
        const validList = Array.isArray(list) ? list : [];
        const renderMenuItems = (item) => {
            const isValid = CommonValidation.alphabetOnly(item[showKey]);
            if (stateKey === "state_code") {
                const cities = City.getCitiesOfState(this.state.country_code, item[valueKey]);
                const validCities = cities?.filter(city => CommonValidation.alphabetOnly(city[showKey]) === true)?.length > 0;
                return isValid && validCities;
            }
            return isValid;
        };
        return (
            <FormControl size='small' sx={{ width: width }}>
                <InputLabel id="title-select-label">{t(label)}</InputLabel>
                <Select
                    sx={{ width: '97%' }}
                    MenuProps={{ style: { maxHeight: 250, maxWidth: '97%' } }}
                    size='small'
                    label={label}
                    value={this.state[stateKey] || ""}
                    disabled={this.state.OnlyView}
                    onChange={(e) => { this.onSlctHandler(stateKey, e) }}
                >
                    {
                        validList?.map((item) => {
                            if (renderMenuItems(item)) {
                                return <MenuItem key={item[valueKey]} value={item[valueKey]}>{item[showKey]}</MenuItem>;
                            }
                            return null;
                        })
                    }
                </Select>
            </FormControl>
        )
    }

    renderFormOne = () => {
        const { t } = this.props
        return (
            <Box>
                <Grid container className='eMed_AddVendor_Grid_row'>
                    <Grid item xs={4}>
                        {this.renderTextBox(`${t("Vendor Name")}`, "VendorName", 50, "97%", false, true)}
                    </Grid>
                    {this.state.internal_store_enabled ?
                        (<Grid item xs={4}>
                            {this.renderAutoSelect(`${t("Vendor Type")}`, "VendorType", 50, "97%", false,  true,)}
                        </Grid>) : null}
                    <Grid item xs={4}>
                        {this.renderTextBox(`${t("GSTIN")}`, "GSTIN", 15, "95%", false, true)}
                    </Grid>
                    {this.state.internal_store_enabled ? null : < Grid item xs={4}>
                        {this.renderTextBox(`${t("DL No")}`, "DLno01", 50, "95%", false, true)}
                    </Grid>}
                </Grid>
                <Grid container className='eMed_AddVendor_Grid_row'>
               {!this.state.internal_store_enabled ? null : < Grid item xs={4}>
                        {this.renderTextBox(`${t("DL No 1")}`, "DLno01", 50, "95%", false, true)}
                    </Grid>}
                    { 
                    <Grid item xs={4}>
                        {this.renderTextBox(`${t("DL No 2")}`, "DLno02", 50, "97%", false)}
                    </Grid>}
                    <Grid item xs={4}>
                        {this.renderTextBox(`${t("DL No ")}`, "DLno03", 50, "95%", false)}
                    </Grid>
                    {this.state.internal_store_enabled ? null : <Grid item xs={4}>
                        {this.renderTextBox(`${t("DL No ")}`, "DLno04", 50, "95%", false)}
                    </Grid>}
                </Grid>
                <Grid container className='eMed_AddVendor_Grid_row'>
                {!this.state.internal_store_enabled ? null : <Grid item xs={4}>
                        {this.renderTextBox(`${t("DL No 4")}`, "DLno04", 50, "95%", false)}
                    </Grid>}
                   {   <Grid item xs={4}>
                        {this.renderTextBox(`${t("DL No 5")}`, "DLno05", 50, "97%", false)}
                    </Grid>}
                    <Grid item xs={4}>
                        {this.renderTextBox(`${t("DL No 6")}`, "DLno06", 50, "95%", false)}
                    </Grid>
                 { this.state.internal_store_enabled ? null :   <Grid item xs={4}>
                        {this.renderTextBox(`${t("GL Code")}`, "glCode", 15, "95%", false)}
                    </Grid>}
                </Grid>
               { !this.state.internal_store_enabled ? null : <Grid container className='eMed_AddVendor_Grid_row'>
                       <Grid item xs={4}>
                        {this.renderTextBox(`${t("GL Code")}`, "glCode", 15, "95%", false)}
                    </Grid>
                </Grid>}
            </Box>
        )
    }

    renderFormTwo = () => {
        const { t } = this.props
        let states = this.state
        return (
            <Box marginTop={"0.5vw"}>
                <Divider textAlign='center'><Typography className='eMEd_Add_Vendor_Form_Headtext'>Address</Typography></Divider>
                <Grid container className='eMed_AddVendor_Grid_row'>
                    <Grid item xs={4}>
                        {this.renderTextBox(`${t("Address Line 1")}`, "Address1", 50, "97%", false, true)}
                    </Grid>
                    <Grid item xs={4}>
                        {this.renderTextBox(`${t("Address Line 2")}`, "Address2", 50, "95%", false)}
                    </Grid>
                    <Grid item xs={4}>
                        {this.renderTextBox(`${t("Area Name")}`, "AreaName", 50, "95%", false, true)}
                    </Grid>
                </Grid>
                <Grid container className='eMed_AddVendor_Grid_row'>
                    <Grid item xs={4}>
                        {this.renderSelect("City *", "City", states.cityName, "name", "name")}
                    </Grid>
                    <Grid item xs={4}>
                        {this.renderSelect("State", "state_code", states.AllStates, "name", "isoCode", "98%")}
                    </Grid>
                    <Grid item xs={4}>
                        {this.renderSelect("Country", "country_code", states.AllCountry, "name", "isoCode", "98%")}
                    </Grid>
                </Grid>
                <Grid container className='eMed_AddVendor_Grid_row'>
                    <Grid item xs={4}>
                        {this.renderTextBox(`${t("Pincode")}`, "Pincode", 6, "97%", false, true)}
                    </Grid>
                </Grid>
            </Box>
        )
    }
    renderFormThree = () => {
        const { t } = this.props
        return (
            <Box marginTop={"0.5vw"}>
                <Divider textAlign='center'><Typography className='eMEd_Add_Vendor_Form_Headtext'>Contact Details</Typography></Divider>
                <Grid container className='eMed_AddVendor_Grid_row'>
                    <Grid item xs={4}>
                        {this.renderTextBox(`${t("Mobile Number")}`, "Mobile", 10, "97%", false, true)}
                    </Grid>
                    <Grid item xs={4}>
                        {this.renderTextBox(`${t("Landline")}`, "Landline", 10, "95%", false)}
                    </Grid>
                    <Grid item xs={4}>
                        {this.renderTextBox(`${t("Email ID")}`, "mailID", 50, "95%", false, true)}
                    </Grid>
                </Grid>
            </Box>
        )
    }

    Addvendor() {
        try {
            const states = this.state;
            let VendorType =  this.state.VendorType === "External" ? 2 : 1
            const memoizedRemoveSpace = (() => {
                const cacheObj = {};
                return (str) => {
                    if (cacheObj[str]) {
                        return cacheObj[str];
                    }
                    const result = CommonValidation.removeSpace(str);
                    cacheObj[str] = result;
                    return result;
                };
            })();
            const data = {
                "address_line_1": memoizedRemoveSpace(states.Address1),
                "address_line_2": memoizedRemoveSpace(states.Address2),
                "area_name": memoizedRemoveSpace(states.AreaName),
                "city": memoizedRemoveSpace(states.City),
                "country": memoizedRemoveSpace(states.AllCountry?.find((item) => (item.isoCode === states.country_code))?.name),
                "dl_no": memoizedRemoveSpace(states.DLno01),
                "dl_no_2": memoizedRemoveSpace(states.DLno02),
                "dl_no_3": memoizedRemoveSpace(states.DLno03),
                "dl_no_4": memoizedRemoveSpace(states.DLno04),
                "dl_no_5": memoizedRemoveSpace(states.DLno05),
                "dl_no_6": memoizedRemoveSpace(states.DLno06),
                "email": memoizedRemoveSpace(states.mailID),
                "gstin_number": memoizedRemoveSpace(states.GSTIN),
                "name": memoizedRemoveSpace(states.VendorName),
                "mobile_number": memoizedRemoveSpace(states.Mobile),
                "phone_number": memoizedRemoveSpace(states.Landline),
                "pincode": memoizedRemoveSpace(states.Pincode),
                "state": memoizedRemoveSpace(states.AllStates?.find((item) => (item.isoCode === states.state_code))?.name),
                "vendor_type": states.VendorCategory,
                "ledger_code": memoizedRemoveSpace(states.glCode),
                "store_vendor_type": VendorType ? VendorType : "",
                "vendor_id": states?.VendorId || undefined
            }
            this.LoaderFunction(true)
            RestAPIService[states.VendorId ? "updateWithOutId" : "create"](data, Serviceurls.PURCHASE_VENDOR_ADD)
                .then((response) => {
                    this.LoaderFunction(false)
                    if (response.data.status === "success") {
                        this.successMessage(response.data.message, true)
                    } else {
                        this.errorMessage(response.data.message)
                    }
                    this.setState({ disableBtn: false })
                }).catch(error => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                    this.setState({ disableBtn: false })
                })
        } catch (e) {
            this.errorMessage(e.message)
            this.setState({ disableBtn: false })
        }
    }

    ClearData = () => {
        this.setState({
            VendorCategory: 1,
            VendorName: "",
            VendorType : "External",
            GSTIN: "",
            DLno01: "",
            DLno02: "",
            DLno03: "",
            DLno04: "",
            DLno05: "",
            DLno06: "",
            Address1: "",
            Address2: "",
            AreaName: "",
            City: "",
            cityName: City.getCitiesOfState("IN", "TN"),
            country_code: 'IN',
            state_code: 'TN',
            AllCountry: Country.getAllCountries(),
            AllStates: State.getStatesOfCountry("IN"),
            State: "Tamil Nadu",
            Country: "India",
            Pincode: "",
            Mobile: "",
            Landline: "",
            mailID: "",
            ErrorShow: false,
            glCode: ""
        })
    }

    onSaveVendor = () => {
        this.setState({ disableBtn: true },
            () => { this.Validation() });
    }

    Validation = () => {
        try {
            let states = this.state
            let Email = CommonValidation.emailValidation(this.state.mailID)
            if (
                CommonValidation.removeSpace(states?.VendorName) !== "" &&
                CommonValidation.removeSpace(states?.VendorType) !== "" &&
                CommonValidation.removeSpace(states?.GSTIN) !== "" &&
                CommonValidation.removeSpace(states?.DLno01) !== "" &&
                CommonValidation.removeSpace(states?.Address1) !== "" &&
                CommonValidation.removeSpace(states?.AreaName) !== "" &&
                CommonValidation.removeSpace(states?.City) !== "" &&
                states?.Pincode.length === 6 &&
                states?.Mobile !== "" &&
                (states?.mailID !== "" || Email)
            ) {
                this.Addvendor()
            } else {
                this.setState({
                    ErrorShow: true,
                    disableBtn: false
                })
            }
        } catch (e) {
            this.errorMessage(e.message)
            this.setState({ disableBtn: false })
        }
    }
    render() {
        const { t } = this.props
        return (
            <div>
                <Box className="eMed_Add_vendor_page_top">
                    <Box className="eMed_Add_vendor_page_top2">
                        <Stack direction="row" spacing={1} alignItems="center"><IconButton size="small" emed_tid = "addvnd_bk" onClick={() => {
                            this.props.history.push({ pathname: '/PharmacyPurchase/VendorList' })
                        }} >
                            <img className="emedhub_popupIcon_img" src={ImagePaths.LabBackButton.default} alt={"Back"} /></IconButton><Typography sx={{ color: "#616161" }}> {t("Back To Vendor List")}</Typography>
                        </Stack>
                    </Box>
                    <Box>
                        <FormControl size='small' sx={{ width: "14vw", marginRight: '1vw' }}>
                            <InputLabel>{t("Vendor Category")}</InputLabel>
                            <Select
                                disabled={this.state.OnlyView}
                                value={this.state.VendorCategory}
                                label={t("Vendor Category")}
                                onChange={(e) => { this.setState({ VendorCategory: e.target.value }) }}
                                MenuProps={{
                                    style: { maxHeight: 350 }
                                }}
                                inputProps={{ emed_tid: "Vendor Category" }}
                            >
                                {VendorcategoryList?.map((list, index) => (<MenuItem key={index} value={list?.value}>{list?.label}</MenuItem>))}
                            </Select>
                        </FormControl>
                        <Button emed_tid= "addvnd_clear" onClick={this.ClearData} id="eMed_Config_btn" disabled={this.state.OnlyView} sx={{ marginRight: "1vw" }} variant='outlined'>{t("Clear")}</Button>
                        <Button emed_tid= "addvnd_save" onClick={this.onSaveVendor} id="eMed_Config_btn" disabled={this.state.disableBtn || this.state.OnlyView} variant='contained'>{t("Save Vendor")}</Button>
                    </Box>
                </Box>
                <Box className="eMed_Add_vendor_form_box">
                    {this.renderFormOne()}
                    {this.renderFormTwo()}
                    {this.renderFormThree()}
                </Box>
                <Loader loaderOpen={this.state.isLoader} />
                {this.state.isErrorMsg &&
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />}
                {this.state.successMsg && <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />}
            </div>
        )
    }
}
export default withTranslation()(PharmaAddvendor);