import React, { Component } from 'react';
import { Colors } from '../../../../Styles/Colors';
import { withTranslation } from 'react-i18next';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import Loader from '../../../../Components/Loader'
import { Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Stack, TextField, Tooltip, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { DataGrid } from '@mui/x-data-grid';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CommonGridHeader, { CommonPatientDetails, DeletePopup } from '../../../../Components/Common Components/CommonComponents';
import { DateTime } from 'luxon';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { getCachevalue, localGetItem } from '../../../../Utility/Services/CacheProviderService';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import { Bucket_Name } from '../../../../Utility/Constants';
import { CheckAccessFunc } from '../../../../Components/CommonFunctions/CommonFunctions';

let AWS = require('aws-sdk');
let s3 = new AWS.S3({
    signatureVersion: 'v4',
    region: "ap-south-1",
    params: { Bucket: Bucket_Name },
});
let to_date = new Date();
let OPEditAccess = null;
let IPEditAccess = null;

class DocDeathCertific extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            rowCount: 0,
            isLoader: false,
            reportDetails: {},
            date: to_date,
            PatientDocuments: [],
            documentList: {},
            selectedForm: 'form4',
            imageuploadList: [],
            deletePopup: false
        }
        this.uploadImage = React.createRef();
    }

    componentDidMount = () => {
        let states = this.state
    
        OPEditAccess = CheckAccessFunc("front_office", "Home", "Form 4/4A", null, "Tab").editAccess
        IPEditAccess = CheckAccessFunc("front_office", "In Patients", "Form 4/4A", null, "Tab").editAccess
        var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
        let clinicId = getCachevalue('SelectedDoctorClinic') ? getCachevalue('SelectedDoctorClinic') : (myUser?.clinic_id ? myUser?.clinic_id  : "")
        let IDs = this.props.fromOP ? JSON.parse(getCachevalue('patientData')) : this.props.fromIP ? JSON.parse(getCachevalue('IppatientData')): JSON.parse(getCachevalue('DoctorPatientData'))
        states["patient_Id"] = IDs?.patient_id
        // states["appointment_id"] = IDs?.appointment_id
        states["doctor_id"] = IDs?.doctor_id
        states["clinic_id"] =  clinicId
        this.setState({
            states
        }, () => {
            this.getPatientDetailData()
            this.getDeathCertificateDeatils()
        })
    }

    getPatientDetailData = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_PATIENT_DETAIL_GET + '?patient_id=' + this.state.patient_Id)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            patientDetails: response?.data?.data ? response?.data?.data : {},
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getDeathCertificateDeatils = () => {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.DOC_DEATH_CERTFICATE_DETAILS_GET + `?patient_id=${this.state.patient_Id}&doctor_id=${this.state.doctor_id}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.LoaderFunction(false)
                        this.setState({
                            PatientDocuments: response?.data?.data
                        })
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    postDeathCertifiData = () => {
        try {
            let { reportDetails, imageuploadList, date } = this.state;
            let isAvl = true;
            let Message = "";
            if (this.state.PatientDocuments.some((item) => item?.form_type === reportDetails?.report_type)) { isAvl = false; Message = "This Form Type Already Exist" }
            else if (Object.keys(reportDetails).length === 0 && imageuploadList.length === 0) { isAvl = false; Message = "Fill the mandatory fields to continue" }
            else if (!reportDetails.report_type) { isAvl = false; Message = `Select Form Type` }
            else if (!date) { isAvl = false; Message = `Enter Date` }
            else if (imageuploadList.length === 0) { isAvl = false; Message = `Select File` }
            if (isAvl) {
                const formData = new FormData();
                formData.append("patient_id", this.state.patient_Id);
                formData.append("doctor_id", this.state.doctor_id);
                formData.append("clinic_id", this.state.clinic_id);
                formData.append("form_type", reportDetails?.report_type);
                formData.append("pdf_file", imageuploadList[0]);
                formData.append("comment", reportDetails?.comment ? reportDetails?.comment : "");
                formData.append("date", date ? DateTime.fromJSDate(date).toFormat('yyyy-MM-dd') : DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'));
                this.setState({ postLoad: true });

                RestAPIService.create(formData, Serviceurls.DOC_DEATH_CERTFICATE_DETAILS_GET)
                    .then((response) => {
                        if (response.data.status === "success") {
                            this.successMessage(response.data.message);
                            this.setState({
                                isDisable: false,
                                reportDetails: {},
                                imageuploadList: [],
                                date: new Date()
                            }, () => {
                                this.getDeathCertificateDeatils();
                            })
                        }
                    })
                    .catch((error) => {
                        this.setState({ postLoad: false, isDisable: false });
                        if (error?.response?.data?.message) {
                            this.errorMessage(error.response.data.message);
                        } else {
                            this.errorMessage(error.message);
                        }
                    });
            } else {
                this.setState({ postLoad: false, isDisable: false });
                this.errorMessage(Message)
            }
        }
        catch (error) {
            this.setState({ postLoad: false, isDisable: false });
            this.errorMessage(error.message);
        }
    };

    popupClose = () => {
        try {
            this.setState({
                deletePopup: false
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onDeleteHandler = (item) => {
        try {
            this.setState({
                deletePopup: true,
                selectedKey: item
            })

        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    deleteOpServiceConfigData = () => {
        try {
            this.LoaderFunction(true)
            let Data = {
                patient_id: this.state.patient_Id,
                doctor_id: this.state.doctor_id,
                form_type: this.state.selectedKey?.form_type,
                form_id: this.state.selectedKey?.id
            }
            RestAPIService.delete(Serviceurls.DOC_DEATH_CERTFICATE_DETAILS_GET + `?form_id=${this.state.selectedKey?.id}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.LoaderFunction(false)
                        this.successMessage(response.data.message)
                        this.getDeathCertificateDeatils()
                        this.setState({ deletePopup: false })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction(false)
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    async getS3Key(doc_key, id) {
        try {
            const response = await RestAPIService.getAllTwo(Serviceurls.AWS_URL_GET + "?doc_key=" + encodeURIComponent(doc_key));
    
            if (response.data.status === "success") {
                const Data = response.data;
                window.open(Data.data)
            }
        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: "Error fetching S3 URL"
            });
        }
    }

    onImageHandler = async (e) => {
        const FileType = e?.target?.files[0]?.type
        if(FileType.startsWith('image/') || FileType === 'application/pdf'){
            const imageList = [];
            let imageFiles = [...e.target.files];
            let clearedArr = imageFiles?.filter((image, i) => {
                let file = image?.name;
                return String(file).toLowerCase() !== String(imageFiles[i + 1]?.name).toLowerCase()
            });
            // convert images to base64;
            for (let i = 0; i < clearedArr.length; i++) {
                imageList.push(clearedArr[i])
            }
            const newList = await Promise.all(imageList)
            e.target.value = ''
            this.setState({
                imageuploadList: newList
            })
        }else{
            e.target.value = ''
            this.errorMessage("Please upload only images or PDF files.")
        }
        
        
    }

    renderTextBox(label, statekey, value, width) {
        const { t } = this.props;
        let { reportDetails } = this.state;
        return (
            <div id="eMed_DocRepo_divs">
                {statekey === "report_type" ?
                    <FormControl sx={{ width: width ? width : "14.5vw" }} size="small">
                        <InputLabel>{t(label)}</InputLabel>
                        <Select
                            value={value ? value : ""}
                            name={statekey}
                            label={t(label) + "*"}
                            onChange={(event) => {
                                reportDetails["report_type"] = event.target.value;
                                this.setState({
                                    reportDetails
                                })
                            }}
                            MenuProps={{
                                style: { maxHeight: 350 },
                            }}
                        >
                            <MenuItem value={"form4"}>{"Form 4"}</MenuItem>
                            <MenuItem value={"form4A"}>{"Form 4A"}</MenuItem>
                        </Select>
                    </FormControl>
                    :
                    statekey === "date" ?
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                inputFormat="dd-MM-yyyy"
                                label={label ? t(label) : ""}
                                name={statekey}
                                value={value ? value : null}
                                autoComplete="off"
                                maxDate={DateTime.now()}
                                minDate={DateTime.now()}
                                disabled={true}
                                onChange={
                                    (value) => {
                                        this.setState({
                                            date: value
                                        })
                                    }
                                }
                                renderInput={(params) => <TextField onKeyDown={(e) => this.onKeyDown(e)} size="small" disabled={true} sx={{ width: width ? width : "14.5vw" }} {...params} onClick={() => { this.setState({ open: true }) }} />}
                            />
                        </LocalizationProvider>
                        : <TextField
                            label={label ? t(label) : ""}
                            name={statekey}
                            value={value ? value : ""}
                            size="small"
                            sx={{ width: width ? width : "14.5vw" }}
                            multiline={statekey === "comment" ? true : false}
                            rows={statekey === "comment" ? 3 : null}
                            inputProps={{ maxLength: statekey === "comment" ? 255 : null }}
                            onChange={(event) => {
                                reportDetails["comment"] = event.target.value.trimStart()
                                this.setState({ reportDetails })
                            }}
                        />
                }
            </div>
        )
    }

    render() {
        const { t } = this.props;
        let { reportDetails, date } = this.state;
        this.state.PatientDocuments?.forEach((element, index) => element.sno = index + 1)
        const columns = [
            {
                field: 'date', headerAlign: "center", headerName: t('Date'), align: "center", flex: 0.20, renderCell: (cellvalues) => {
                    return (<Typography>{cellvalues.row.date ? (cellvalues.row.date).split("-").reverse().join("-") : "-"}</Typography>)
                }
            },
            {
                field: 'form_type', headerName: t('Form Type'), flex: 0.20, renderCell: (cellvalues) => {
                    return (
                        <div>{cellvalues.row?.form_type ? cellvalues.row?.form_type === 'form4' ? "Form 4" : "Form 4 A" : "-"}</div>
                    )
                }
            },
            {
                field: 'comment', headerName: t('Comments'), flex: 0.30, renderCell: (cellvalues) => {
                    return (
                        <div>{cellvalues.row?.comment?.length > 35 ? <Tooltip placement="top" title={cellvalues.row?.comment} arrow><div>{cellvalues.row?.comment.slice(0, 35) + "..."}</div></Tooltip> : cellvalues.row.comment ? cellvalues.row.comment : "-"}</div>
                    )
                }
            },
            {
                field: 'actions', headerAlign: "center", headerName: t('Action'), flex: 0.20, align: "center", sortable: false, hideable: false, renderCell: (params) => (
                    <Box sx={{visibility : (this.props.fromOP ? OPEditAccess : this.props.fromIP ? IPEditAccess :true   ) ? "visible":"hidden"}}>
                        <Button
                            className='Common_Btn_Min_Width'
                            onClick={() => { this.getS3Key(params?.row?.pdf_key) }}
                        >
                            <Box component={'img'} src={ImagePaths.Download.default} height={'1.2vw'} width={'1.2vw'} />
                        </Button>
                        <Button
                            className='Common_Btn_Min_Width'
                            onClick={() => this.onDeleteHandler(params?.row)}
                        >
                            <Box component={'img'} src={ImagePaths.Delete.default} height={'1.2vw'} width={'1.2vw'} />
                        </Button>
                    </Box>
                ), disableExport: true
            }
        ]
        let PatientData = this.props.fromOP ? JSON.parse(getCachevalue('patientData')) : this.props.fromIP ? JSON.parse(getCachevalue('IppatientData')): JSON.parse(getCachevalue('DoctorPatientData'))
        return (
            <Box id="eMed_DocRepo_Container" sx={{ backgroundColor: Colors.configHomeBg }}>
                <Grid container className="eMed_DocRepo_Box" sx={{ height: "76vh", paddingTop: "0.5vw" }}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", marginTop: "-1vw", marginBottom: "0.5vw" }}>
                        <Box component={'div'} display={'flex'}>
                            <Box component={'div'} className='doc_header_card' mr={'0.5vw'}>
                                <CommonPatientDetails data={{...this.state.patientDetails, ...{ "op_number" : this.props.fromIP ? PatientData?.ip_number : PatientData?.op_number}}} showDetailed={true} fromDoctor={true} />
                            </Box>
                        </Box>
                        <Box>
                            <FormControl size='small' sx={{ width: '13vw' }}>
                                <InputLabel>Select Form Type</InputLabel>
                                <Select
                                    label='Select Form Type'
                                    value={this.state.selectedForm}
                                    onChange={(e) => this.setState({ selectedForm: e.target.value })}
                                >
                                    <MenuItem value={"form4"}>{"Form 4"}</MenuItem>
                                    <MenuItem value={"form4A"}>{"Form 4A"}</MenuItem>
                                </Select>
                            </FormControl>
                            <Button
                                className='Common_Btn_Min_Width'
                                size='medium'
                                variant='contained'
                                sx={{ marginLeft: "0.5vw" }}
                                onClick={() => {
                                    if (this.state.selectedForm === "form4A") {
                                        this.getS3Key("Governmentforms/deathform/form4A.pdf")
                                    } else if (this.state.selectedForm === "form4") {
                                        this.getS3Key("Governmentforms/deathform/form4.pdf")
                                    }
                                }}
                            >Download <DownloadIcon color='white' sx={{ marginLeft: "0.5vw" }} /></Button>
                        </Box>
                    </Box>
                    <Grid container item spacing={3.5} className="eMed_DocRepo_mainDiv">
                        <Grid item xs={8.1}>
                            <Paper className="eMed_DocRepo_Div" sx={{ height: "64vh" }} elevation={1}>
                                <DataGrid
                                    rows={this.state.PatientDocuments}
                                    columns={columns}
                                    getRowId={(row) => row.id}
                                    localeText={{
                                        toolbarColumns: "",
                                        toolbarDensity: "",
                                        toolbarExport: "",
                                        toolbarFilters: "",
                                        toolbarExportPrint: ""
                                    }}
                                    disableSelectionOnClick
                                    components={{
                                        Toolbar: CommonGridHeader.CommonToolBar,
                                        NoRowsOverlay: () => {
                                            return (
                                                <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: "53vh" }}>
                                                    {t("NoRecordsFound")}
                                                </Stack>
                                            )
                                        }
                                    }}
                                    headerHeight={40}
                                    hideFooter
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={3.9}>
                            <Paper className="eMed_DocRepo_Div" sx={{ height: "64vh" }} elevation={1}>
                                <div className='eMed_DocRepo_rightHead' style={{ backgroundColor: Colors.DataTblHeaderbg }}>
                                    <p className='eMed_DocRepo_rightHead_txt'>{t("Form 4/4A")}</p>
                                </div>
                                <div className="eMed_DocRepo_subDiv" style={{ height: "54.8vh" }}>
                                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                                        {this.renderTextBox("Select Form Type *", "report_type", reportDetails["report_type"], "15vw")}
                                        {this.renderTextBox("Select Date *", "date", date, "11vw")}
                                    </Stack>
                                    {this.renderTextBox("Comments", "comment", reportDetails["comment"], "27vw")}
                                    {this.state.imageuploadList?.length > 0 ? null : <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'14vw'} width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                        <Box component={'input'} type='file' sx={{ display: 'none' }}
                                            accept="image/*,application/pdf"
                                            ref={this.uploadImage}
                                            onChange={this.onImageHandler}
                                        />
                                        <Button sx={{ textTransform: 'capitalize' }}
                                            disabled={this.state.imageuploadList?.length > 0}
                                            onClick={() => this.uploadImage.current.click()}
                                        >
                                            <Box component={'div'} borderRadius={'10px'} border={'1px dotted gray'} height={'10vw'} width={'16vw'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                                                <Box component={'img'} src={this.state.imageuploadList?.length > 0 ? ImagePaths.DocumentPreview.default : ImagePaths.LabUploadButton.default} height={'2vw'} width={'2vw'} />
                                                <Typography fontSize={'0.9vw'} color={Colors.SecondaryText} fontWeight={600} mt={'0.5vw'}>{this.state.imageuploadList?.length > 0 ? "File Added" : "Upload Files ( PDF / Images ) *"}</Typography>
                                            </Box>
                                        </Button>
                                    </Box>}
                                    {this.state.imageuploadList?.length > 0 ? <Box component={'div'} display={'flex'} maxWidth={'29vw'} alignItems={'center'} justifyContent={'center'}>
                                        {this.state.imageuploadList?.map((image, index) => (
                                            <Box component={'div'} key={index} mr={'0.5vw'} height={'15vw'} display={'flex'} alignItems={'center'}>
                                                <Tooltip title={typeof (image) === "string" ? null : image?.name} arrow placement='top'>
                                                    <Box component={'img'}
                                                        onClick={() => {
                                                            if (typeof (image) === "string") {
                                                                if (image.split('.').pop() === "jpg" || image.split('.').pop() === "jpeg" || image.split('.').pop() === "JPG" || image.split('.').pop() === "png") {
                                                                    this.setState({ isImgClicked: true, popUpOpen: true, selectedImage: image })
                                                                }
                                                                else {
                                                                    window.open(image)
                                                                }
                                                            }
                                                        }}
                                                        src={typeof (image) === "string" ?
                                                            (image.split('.').pop() === "jpg" || image.split('.').pop() === "jpeg" || image.split('.').pop() === "JPG" || image.split('.').pop() === "png" ?
                                                                image : ImagePaths.DocumentPreview.default) : ((image?.type === "image/jpeg" || image?.type === "image/png") ? ImagePaths.ImagePreview.default : ImagePaths.DocumentPreview.default)}
                                                        height={'3.5vw'} width={'2.8vw'}
                                                    />
                                                </Tooltip>
                                                <Box>
                                                    <IconButton sx={{ position: 'relative', bottom: '1.2vw', right: '1.4vw' }} size="small"
                                                        onClick={() => {
                                                            this.setState({
                                                                imageuploadList: [],
                                                            })
                                                        }}>
                                                        <CancelTwoToneIcon fontSize='small' color="error" />
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                        ))}
                                    </Box> : null}
                                </div>
                                <Stack spacing={2} direction="row" id='eMed_srvConfig_rightBtnCon' sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center">
                                    <Button variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.setState({ reportDetails: {}, imageuploadList: [], date: new Date() }) }}>{t("Clear")}</Button>
                                    <Button variant='contained' size="small" emed_tid="Test_Report_Add_btn" id="eMed_Config_btn" disabled={(this.state.isDisable) || !(this.props.fromOP ? OPEditAccess : this.props.fromIP ? IPEditAccess :  true) } onClick={() => { this.setState({ isDisable: true }, () => { this.postDeathCertifiData() }) }}>{t("Add")}</Button>
                                </Stack>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null
                }
                {
                    this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null
                }
                {this.state.deletePopup ? <DeletePopup
                    DeletTitle={`Are you sure you want to delete ?`}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this.deleteOpServiceConfigData.bind(this)}
                /> : null}
                <Loader loaderOpen={this.state.isLoader} />
            </Box>
        )
    }
}
export default withTranslation()(DocDeathCertific);