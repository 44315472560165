import React, { useState, useEffect } from 'react';
import {
  Autocomplete, Box, Button, Drawer, Stack, TextField, Tooltip,
  Typography, Grow, FormControlLabel, Checkbox
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { withTranslation } from 'react-i18next';
import { AmountsCard, AmountsCardWithCount } from '../../../../Components/CommonCards/CommonCards';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker';
import { CommonGridToolBarWithFilterText } from '../../../../Components/Common Components/CommonComponents';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DateTime } from 'luxon';
import { formatDate, AmountFormat, splitDate } from '../../../../Components/CommonFunctions/CommonFunctions';
import { Colors } from '../../../../Styles/Colors';
import { CurrencySymbol } from '../../../../Utility/Constants';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';

const OTSalesOverview = (props) => {
  const [state, setState] = useState({
    salesOverviewData: [],
    page: 0,
    pageSize: 10,
    filterDatas: {
      'fromDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      'toDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      "dateIndex": 1,
      "days": 30,
      "patientName": '',
      "billNumber": '',
      "doctorName": null,
      "doctorList": []
    },
    filterOpen: false,
    totalAmount: 0,
    cgst: 0,
    sgst: 0,
    discountAmount: 0,
    netAmount: 0,
    roundOff: 0,
    isLoader: false,
    ipCount: 0,
    showBox: false,
    ipNetAmt: 0,
    rowCount: 0,
    disableBtn: false,
    isErrorMsg: false,
    isErrorMsgText: "",
    triggerAction: null,
  })

  useEffect(() => {
    getSalesOverviewReportData();
    getDoctorNameList();
  }, []);

  useEffect(() => {
    if (state.triggerAction) {
      getSalesOverviewReportData();
      setState((prevState) => ({
        ...prevState,
        triggerAction: null
      }))
    }
  }, [state.triggerAction])

  const errorHandler = (error) => {
    if (error?.response?.data?.message) {
      errorMessage(error?.response?.data?.message);
    } else {
      errorMessage(error?.message);
    }
  }

  const generateUrl = () => {
    const { filterDatas } = state;

    const doctorName = filterDatas.doctorName?.doctor_id || "";

    return Serviceurls.OT_SALES_OVERVIEW_REPORT +
      `?from_date=${filterDatas.fromDate}&to_date=${filterDatas.toDate}&patient_name=${filterDatas.patientName}&bill_number=${filterDatas.billNumber}&doctor_id=${doctorName}`
  }

  const getSalesOverviewReportData = () => {
    try {
      LoaderFunction(true);
      const url = generateUrl();
      RestAPIService.getAll(url)
        .then((response) => {
          if (response.data.status === 'success') {
            LoaderFunction(false);
            const data = response?.data?.data;
            data?.result?.forEach((element, index) => element.sno = index + 1)
            setState((prevState) => ({
              ...prevState,
              salesOverviewData: data?.result,
              totalAmount: data?.total_amount,
              cgst: data?.total_cgst_amount,
              sgst: data?.total_sgst_amount,
              discountAmount: data?.total_discount_amount,
              netAmount: data?.total_net_amount,
              ipCount: data?.total_ip_count,
              ipNetAmt: data?.total_ip_net_amount,
              roundOff: data?.total_round_off_amount,
              rowCount: data?.result?.length || 0
            }))
          }
        }).catch((error) => {
          errorHandler(error);
          LoaderFunction(false);
        })
    } catch (error) {
      errorMessage(error.message);
      LoaderFunction(false);
    }
  }

  const getSalesoverviewPrint = () => {
    try {
      setDisableBtn(true);
      const url = generateUrl();
      RestAPIService.getAll(`${url}&export=pdf`)
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          setDisableBtn(false);
        }).catch((error) => {
          errorHandler(error);
          setDisableBtn(false);
        })
    } catch (error) {
      errorMessage(error.message)
      setDisableBtn(false);
    }
  }

  const getSalesoverviewExcel = () => {
    try {
      setDisableBtn(true);
      const url = generateUrl();
      RestAPIService.excelGet(`${url}&export=excel`)
        .then((response) => {
          if (response.data) {
            const pom = document.createElement('a');
            const csvContent = response.data; //here we load our csv data 

            const filename = response.headers["content-disposition"].split("filename=")[1]

            const blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' });
            const url = URL.createObjectURL(blob);
            pom.href = url;
            pom.setAttribute('download', filename ? filename : 'registry.xlsx');
            pom.click();
            setDisableBtn(false);
          }
        }).catch((error) => {
          errorHandler(error);
          setDisableBtn(false);
        })
    } catch (error) {
      setDisableBtn(false);
      errorMessage(error.message);
    }
  }

  const getDoctorNameList = () => {
    try {
      RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME)
        .then((response) => {
          if (response.data.status === "success") {
            setState((prevState) => ({
              ...prevState,
              filterDatas: {
                ...prevState.filterDatas,
                doctorList: response.data.data
              }
            }))
          }
        }).catch((error) => {
          errorHandler(error);
        })
    } catch (error) {
      errorMessage(error.message)
    }
  }

  const setDisableBtn = (key) => {
    setState((prevState) => ({
      ...prevState,
      disableBtn: key
    }))
  }

  const LoaderFunction = (key) => {
    setState((prevState) => ({
      ...prevState,
      isLoader: key
    }))
  }

  const errorMessage = (message) => {
    setState((prevState) => ({
      ...prevState,
      isErrorMsg: true,
      isErrorMsgText: message
    }))
  }

  const msgClose = () => {
    setState((prevState) => ({
      ...prevState,
      isErrorMsg: false,
      isErrorMsgText: ""
    }))
  }

  const gridToolBar = () => {
    const TextArray = [
      { label: "From Date", value: formatDate(state.filterDatas.fromDate) },
      { label: "To Date", value: formatDate(state.filterDatas.toDate) },
    ]
    return (
      <Box>
        <CommonGridToolBarWithFilterText FilterTextArray={TextArray} noExport={true} />
      </Box>
    )
  }

  const closeFilter = (fromSrch) => {
    setState((prevState) => ({
      ...prevState,
      filterOpen: false,
      triggerAction: fromSrch ? "search" : null
    }))
  }

  const FilterDate = (from, to, index) => {
    setState((prevState) => ({
      ...prevState,
      filterDatas: {
        ...prevState.filterDatas,
        fromDate: from,
        toDate: to,
        dateIndex: index
      }
    }))
  }

  const renderTextField = (label, stateKey) => {
    return (
      <TextField
        size='small'
        autoComplete='off'
        name={stateKey}
        fullWidth
        value={state.filterDatas[stateKey] || ""}
        label={label}
        inputProps={{ emed_tid: label }}
        onChange={(e) => {
          const { name, value } = e.target;
          switch (name) {
            case 'billNumber':
              if (CommonValidation.numberOnly(value) || value === '') {
                setState((prevState) => ({
                  ...prevState,
                  filterDatas: {
                    ...prevState.filterDatas,
                    [name]: value
                  }
                }))
              }
              break;
            default:
              if (CommonValidation.alphabetOnly(value) || value === '') {
                setState((prevState) => ({
                  ...prevState,
                  filterDatas: {
                    ...prevState.filterDatas,
                    [name]: value
                  }
                }))
              }
              break;
          }
        }}
      />
    )
  }

  const renderFilterScreen = () => {
    const { filterDatas } = state;
    const { t } = props;

    return (
      <Box component={"div"} className='eMed_rts_filter_container'>
        <Box component={"div"} className='eMed_filter_Top'>
          <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
          <Button onClick={() => { closeFilter() }} className='eMed_usrconf_btn'>
            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
          </Button>
        </Box>
        <Box className='eMed_rts_middle'>
          <Box className='eMed_date_filter'>
            <CommonDatePicker
              FromDate={filterDatas.fromDate}
              ToDate={filterDatas.toDate}
              DateIndex={filterDatas.dateIndex}
              SelectedDates={FilterDate.bind(this)}
              HideAllMenu={true} />
          </Box>
          <Box display={"grid"} gridTemplateColumns="repeat(2, 1fr)" gap={"0.5dvw"}>
            {renderTextField("Patient Name", 'patientName')}
            {renderTextField("Bill Number", 'billNumber')}
            <Autocomplete
              size="small"
              fullWidth
              options={filterDatas.doctorList}
              getOptionLabel={(option) => option.doctor_name}
              value={filterDatas.doctorName}
              onChange={(event, newValue) => {
                setState((prevState) => ({
                  ...prevState,
                  filterDatas: {
                    ...prevState.filterDatas,
                    doctorName: newValue,
                  },
                }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Select Doctor Name")}
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option?.doctor_id}>
                  {option?.doctor_name}
                </li>
              )}
            />
          </Box>
        </Box>
        <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
          <Button variant='outlined' size="small"
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                filterDatas: {
                  ...prevState.filterDatas,
                  fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                  toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                  dateIndex: 1,
                  days: 30,
                  patientName: '',
                  billNumber: '',
                  doctorName: null,
                },
                triggerAction: "clear"
              }));
            }}
            disabled={state.triggerAction === "clear"}
            id="eMed_Config_btn">{t("Clear")}</Button>
          <Button variant='contained' size="small"
            disabled={state.triggerAction === "search"}
            onClick={() => { closeFilter(true);}}
            id="eMed_Config_btn">{t("Search")}</Button>
        </Stack>
      </Box>
    )
  }

  const { t } = props;

  const cardList = [
    { label: "In Patient", amount: state.ipNetAmt, count: state.ipCount, test_id: "ot_rpt_saleOver_ip" },
  ];

  const growCardConfig = [
    { label: "Taxable Amount", amount: state.totalAmount, id: 'ot_rpt_saleOver_tax_amt' },
    { label: "CGST", amount: state.cgst, id: 'ot_rpt_saleOver_cgst' },
    { label: "SGST", amount: state.sgst, id: 'ot_rpt_saleOver_sgst' },
    { label: "Discount Amount", amount: state.discountAmount, id: 'ot_rpt_saleOver_dis_amt' },
    { label: "Round Off", amount: state.roundOff, id: 'ot_rpt_saleOver_rnd_amt' },
  ];

  const columns = [
    {
      field: 'invoice_number', headerName: "Bill No", flex: 0.083,
      renderCell: ({ row }) => {
        let invoice_number = row?.invoice_number ? row?.invoice_number?.split("/") : [];
        return (<Box component={'div'}>
          {<Tooltip placement='top' title={row?.invoice_number} arrow>
            <div>{invoice_number?.length > 0 ? `...${invoice_number[invoice_number.length - 1]}` : '-'}</div></Tooltip>}
        </Box>)
      }
    },
    {
      field: 'invoice_dt', headerName: "Bill Date", flex: 0.083, headerAlign: "center", align: "center",
      renderCell: ({ row }) => (<Box component={'div'}>
        {splitDate(row?.invoice_dt)?.length > 12 ? <Tooltip placement='top' title={row?.invoice_dt} arrow><div>{row?.invoice_dt?.slice(0, 12) + '...'}</div></Tooltip> :
          row?.invoice_dt ? splitDate(row?.invoice_dt) : '-'}
      </Box>)
    },
    {
      field: 'billing_customer_name', headerName: "Patient Name", flex: 0.083,
      renderCell: ({ row }) => (<Box component={'div'}>
        {row?.billing_customer_name?.length > 12 ? <Tooltip placement='top' title={row?.billing_customer_name} arrow><div>{row?.billing_customer_name?.slice(0, 12) + '...'}</div></Tooltip> :
          row?.billing_customer_name ? row?.billing_customer_name : '-'}
      </Box>)
    },
    {
      field: 'module_type', headerName: "Patient Type", flex: 0.083,
      renderCell: ({ row }) => (<Box component={'div'}>{row?.module_type ? row?.module_type : '-'}</Box>)
    },
    {
      field: 'doctor_name', headerName: "Doctor Name", flex: 0.083,
      renderCell: ({ row }) => (<Box component={'div'}>
        {row?.doctor_name?.length > 10 ? <Tooltip placement='top' title={row?.doctor_name} arrow><div>{row?.doctor_name?.slice(0, 10) + '...'}</div></Tooltip> :
          row?.doctor_name ? row?.doctor_name : '-'}
      </Box>)
    },
    {
      field: 'taxable_amount', headerName: `${"Amount"} (${CurrencySymbol})`, flex: 0.083, type: "number",
      renderCell: ({ row }) => (<Box>{AmountFormat(row?.taxable_amount || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
    },
    {
      field: 'cgst_amount', headerName: `${"CGST"} (${CurrencySymbol})`, flex: 0.083, type: "number",
      renderCell: ({ row }) => (<Box>{AmountFormat(row?.cgst_amount || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
    },
    {
      field: 'sgst_amount', headerName: `${"SGST"} (${CurrencySymbol})`, flex: 0.083, type: "number",
      renderCell: ({ row }) => (<Box>{AmountFormat(row?.sgst_amount || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
    },
    {
      field: 'igst', headerName: `${"IGST"} (${CurrencySymbol})`, flex: 0.083, type: 'number',
      renderCell: ({ row }) => (<Box>{AmountFormat(row?.igst || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
    },
    {
      field: 'discount_amt', headerName: `${"Discount Amount"} (${CurrencySymbol})`, flex: 0.083, type: "number",
      renderCell: ({ row }) => (<Box>{AmountFormat(row?.discount_amt || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
    },
    {
      field: 'total_roundoff_amount', headerName: `${"Round Off"} (${CurrencySymbol})`, flex: 0.083, type: "number",
      renderCell: ({ row }) => (<Box>{AmountFormat(row?.total_roundoff_amount || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
    },
    {
      field: 'total_net_amount', headerName: `${"Net Amount"} (${CurrencySymbol})`, flex: 0.083, type: "number",
      renderCell: ({ row }) => (<Box>{AmountFormat(row?.total_net_amount || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
    },
  ]
  return (
    <Box component={'div'} className='eMed_rts_container'>
      <Box component={"div"} className='eMed_Pharma_rts_header'>
        <Box component={"div"} flex={0.8} display={'flex'} >
          <Box sx={{ cursor: 'pointer' }}
            onClick={() => { setState((prevState) => ({ ...prevState, showBox: !prevState.showBox, })) }}>
            {AmountsCardWithCount(t("Net Amount"), state.netAmount, false,
              state.showBox ? Colors.Primary : "white", true, state.showBox ? "white" :
              Colors.Primary, 'rec_recev_amt', "10dvw", state.rowCount, true)}
          </Box>
          {cardList?.map((detail) => (<div key={detail.test_id}>
            {AmountsCardWithCount(detail.label, detail.amount, false, "white", false, null, detail.test_id, "10dvw", detail.count, true)}
          </div>))}
          <Box component={'div'} sx={{ width: '30dvw', overflow: 'auto', marginLeft: '1dvw', height: "5.5dvw", display: 'flex', alignItems: 'center' }} >
            {state.showBox &&
              <Box sx={{ display: 'flex' }}>
                {growCardConfig.map(({ label, amount, id }, index) => (
                  <Grow
                    key={index}
                    in={state.showBox}
                    style={{ transformOrigin: '0 0 0' }}
                    {...(state.showBox ? { timeout: 500 + index * 500 } : {})}
                  >
                    {AmountsCard(label, amount, false, "white", false, Colors.Primary, id, "10dvw")}
                  </Grow>
                ))}
              </Box>}
          </Box>
        </Box>
        <Box component={"div"} flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5dvw'}>
          <Button emed_tid="ot_rpt_saleOver_flt" className='eMed_usrconf_btn' onClick={() => { setState((prevState) => ({ ...prevState, filterOpen: true })) }}>
            <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
          </Button>
          <Button emed_tid="ot_rpt_saleOver_print" className='eMed_usrconf_btn' disabled={state.disableBtn} onClick={() => getSalesoverviewPrint()}>
            <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
          </Button>
          <Button emed_tid="ot_rpt_saleOver_export" className='eMed_Pharma_rts_btn' disabled={state.disableBtn} onClick={() => getSalesoverviewExcel()}>
            <img src={ImagePaths.ExportIcon.default} alt='export' className='eMed_action_img' />
          </Button>
        </Box>
      </Box>
      <Box component={'div'} className='eMed_Pharma_rts_tbl_card'>
        <DataGrid
          className='eMed_Pharma_table'
          rows={state.salesOverviewData}
          columns={columns}
          getRowId={(row) => row.sno}
          page={state.page}
          pageSize={state.pageSize}
          localeText={{
            toolbarColumns: "",
            toolbarDensity: "",
            toolbarExport: "",
            toolbarFilters: "",
            toolbarExportPrint: ""
          }}
          components={{
            Toolbar: gridToolBar,
            NoRowsOverlay: () => (
              <Stack className='eMed_conf_nodata'>
                {t("NoRecordsFound")}
              </Stack>
            )
          }}
          onPageChange={(newPage) => { setState((prevState) => ({ ...prevState, page: newPage })) }}
          onPageSizeChange={(newPageSize) => setState((prevState) => ({ ...prevState, pageSize: newPageSize }))}
          rowsPerPageOptions={[10, 20, 30]}
          rowCount={state.salesOverviewData?.length}
          pagination
          headerHeight={40}
          disableSelectionOnClick
          loading={state.isLoader}
        />
      </Box>
      <Drawer
        anchor={'right'}
        open={state.filterOpen}
        ModalProps={{ onBackdropClick: closeFilter }}
      >
        {renderFilterScreen()}
      </Drawer>
      {state.isErrorMsg && <ToastMsg
        severity={'error'}
        msg={state.isErrorMsgText}
        msgPop={msgClose.bind(this)}
      />}
    </Box>
  )
}

export default withTranslation()(OTSalesOverview)