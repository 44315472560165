
import { DataGrid } from '@mui/x-data-grid';
import React, { Component } from 'react'
import { ImagePaths } from '../../../Utility/ImagePaths'
import {
  Button, Box, Tooltip, Stack, Divider, Drawer, Typography, TextField,
  Autocomplete, FormControlLabel, FormControl, RadioGroup, Radio
} from '@mui/material';
import { withTranslation } from 'react-i18next';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { CheckAccessFunc, AmountFormat } from '../../../Components/CommonFunctions/CommonFunctions';
import { DateTime } from 'luxon';
import { CommonGridToolBarWithFilterText, CommonPopUp, ReasonPopup } from '../../../Components/Common Components/CommonComponents';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
import Loader from '../../../Components/Loader';
import { CurrencySymbol, Expiry_period, gstPercentage } from '../../../Utility/Constants';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';

let userAccess = null;
class PharmaDrugList extends Component {

  constructor() {
    super()
    this.state = {
      pageSize: 10,
      checkboxSelection: [],
      count: [],
      page: 0,
      activeInventoryList: [],
      activeStatus: true,
      count: 0,
      searchkey: '',
      FilterDatas: {
        "FromDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        "ToDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      },
      loggedInuserInfo: JSON.parse(localGetItem("loggedInUserInfo")),
      isInActive: false,
      isBulkInActive: false,
      inActiveId: null,
      inActiveDrug: "",
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: '',
      EditPopup: false,
      dosageType_list: [],
      genericName_list: [],
      brand_name: '',
      generic_name: "",
      dosage_type: '',
      dosage_strength: '',
      physical_box_no : "",
      drug_value: null,
      hsn_code: '',
      gst_percentage: null,
      reorder_threshold: '',
      reorder_quantity: '',
      sales_discount: '',
      group: '',
      schedule: '',
      bar_code: '',
      expiry_alert_period: null,
      recommended_by: null,
      selectedDrug: {},
      disableBtn: false,
      DoctorsList : [],
      showZeroStocks: "false",
      mandatoryFields: [],
    }
  }

  componentDidMount() {
    userAccess = CheckAccessFunc(this.state.loggedInuserInfo?.pharmacy_type === 'Inhouse Pharmacy'? "Pharmacy" : "Standalone Pharmacy", "Inventory", 'Drug List', null, "Tab")
    this.getActiveInventoryList()
    this.getDosageTypeList()
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  onChangeHandler = (event, key) => {
    this.setState({
      [key]: event.target.value
    })
  }

  updatePageSize = (newPageSize) => {
    this.setState({
      pageSize: newPageSize
    })
  }

  getActiveInventoryList = () => {
    try {
      this.LoaderFunction(true);
      this.setState({
        activeInventoryList: [],
        activeStatus: true
      })
      let searchKey = this.state.searchkey ? (this.state.searchkey?.replaceAll('&','%26')).replaceAll(','," "): ""
      RestAPIService.getAll(Serviceurls.ACTIVE_DRUG_LIST + `?page_number=${this.state.page + 1}&page_size=${this.state.pageSize}&active_status=${true}&search_key=${searchKey}&include_zero_drug=${this.state.showZeroStocks}`).
        then((response) => {
          if (response.data.status === "Success") {
            this.LoaderFunction(false);
            this.setState({
              activeInventoryList: response.data.result,
              total: response.data.page_count,
              newPageCount: response.data.no_of_page
            })
          }
        }).catch((error) => {this.LoaderFunction(false); this.errorMessage(error.response.data.message)})
    } catch (error) { this.errorMessage(error.message)
    }
  }

  inActivePost = (comment) => {
    let states = this.state;
    if(states.checkboxSelection.length > 0 || states.inActiveId){
      let datas = {
        active_status: false,
        id: states.inActiveId,
        remarks: comment ? comment : '',
      }
      let url = Serviceurls.ACTIVE_DRUG_LIST
      try {
        this.LoaderFunction(true);
        // this.setState({disableBtn: true})
        RestAPIService.create(datas, url)
            .then(response => {
                if (response.data.status === "success") {
                    this.LoaderFunction(false);
                    this.handleBulkNo();
                    this.handleinActiveNo();
                    this.getActiveInventoryList();
                    this.successMessage(response.data.message);
                    this.setState({disableBtn: false})
                }
            })
            .catch(error => {
                if (error?.response?.data?.status === "fail") {
                    this.LoaderFunction(false);
                    this.setState({disableBtn: false})
                    this.errorMessage(error.response.data.message);
                }
            });
    } catch (e) {
        this.errorMessage(e.message);
        this.setState({disableBtn: false})
    }
    }
  }
  groupinActivePost = (comment) => {
    let states = this.state;
    if(states.checkboxSelection.length > 0 || states.inActiveId){
      let datas = {
        active_status: false,
        id: states.checkboxSelection,
        remarks: comment ? comment : '',
      }
      let url =Serviceurls.PHARMA_ACTIVE_DRUG_POST
      try {
        this.LoaderFunction(true);
        // this.setState({disableBtn: true})
        RestAPIService.create(datas, url)
            .then(response => {
                if (response.data.status === "success") {
                    this.LoaderFunction(false);
                    this.handleBulkNo();
                    this.handleinActiveNo();
                    this.getActiveInventoryList();
                    this.successMessage(response.data.message);
                    this.setState({disableBtn: false})
                }
            })
            .catch(error => {
                if (error?.response?.data?.status === "fail") {
                    this.LoaderFunction(false);
                    this.setState({disableBtn: false})
                    this.errorMessage(error.response.data.message);
                }
            });
    } catch (e) {
        this.errorMessage(e.message);
        this.setState({disableBtn: false})
    }
    }
  }

  getDrugListExcel = () => {
    const { t } = this.props;
    try {
      this.setState({disableBtn: true})
      let searchKey = this.state.searchkey ? (this.state.searchkey?.replaceAll('&','%26')).replaceAll(','," "): ""
      RestAPIService.excelGet(`${Serviceurls.ACTIVE_DRUG_LIST}?active_status=${true}&search_key=${searchKey}&include_zero_drug=${this.state.showZeroStocks}&export=${"excel"}`).
        then((response) => {
          if (response.data) {
            var pom = document.createElement('a');
            var csvContent = response.data; //here we load our csv data 

            let filename = response.headers["content-disposition"].split("filename=")[1]

            var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
            var url = URL.createObjectURL(blob);
            pom.href = url;
            pom.setAttribute('download', filename ? filename : 'registry.xlsx');
            pom.click();
            this.setState({disableBtn: false})
          }
        }).catch((error) => {
          this.errorMessage(error.response.data?.message)
          this.setState({disableBtn: false})
        })
    } catch (error) {
      this.errorMessage(t("PrintError"))
      this.setState({disableBtn: false})
    }
  }

  getDosageTypeList = () => {
    try {
      RestAPIService.getAll(`${Serviceurls.PHARMA_DOSAGE_TYPE_LIST}?with_ids=true`)
        .then(response => {
          if (response.data.status === "success") {
            this.setState({
              dosageType_list: response.data.data,
            });
          }
        })
        .catch(error => {
          if (error.response.data.status === "fail") {
            this.errorMessage(error.response?.data?.message);
          }
        })
    } catch (e) {
      this.errorMessage(e.message);
    }
  }

  getGenericNameList = (value) => {
    try {
      RestAPIService.getAll(`${Serviceurls.PHARMA_GENERIC_NAME_LIST}?search_key=${value ? value : ""}`)
        .then(response => {
          if (response.data.status === "success") {
            this.setState({
              genericName_list: response.data.data,
            });
          }
        })
        .catch(error => {
          if (error.response.data.status === "fail") {
            this.errorMessage(error.response?.data?.message);
          }
        })
    } catch (e) {
      this.errorMessage(e.message);
    }
  }

  getEditDatas = (Id) => {
    try {
      RestAPIService.getAll(`${Serviceurls.PHARMA_INVENTORY_EDIT_DRUG_POST}?drug_ms_id=${Id ? Id : ""}`)
        .then(response => {
          if (response.data.status === "success") {
            let DrugData = response.data.data?.length ? response.data.data[0] : {}
            this.setState({
              EditDrugData: DrugData,
              EditPopup: true,
              brand_name: DrugData?.drug_name ? DrugData?.drug_name : "",
              generic_name: DrugData?.genericss ? DrugData?.genericss : "",
              dosage_type: DrugData?.dosagetype_id ? this.state.dosageType_list.find((item)=> item?.dosagetype_id === DrugData?.dosagetype_id) : "",
              drug_strength: DrugData?.drug_strength ? DrugData?.drug_strength : "",
              physical_box_no : DrugData?.physical_box_no ? DrugData?.physical_box_no : "",
              hsn_code: DrugData?.hsn_code ? DrugData?.hsn_code : "",
              manufacturer_name : DrugData?.manufacturer_name ? DrugData?.manufacturer_name : "",
              gst_percentage: DrugData?.gst_percentage ? gstPercentage.find((item) => +item?.value === DrugData?.gst_percentage) : null,
              reorder_threshold : DrugData?.reorder_threshold ? DrugData?.reorder_threshold : "",
              reorder_quantity : DrugData?.reorder_quantity ? DrugData?.reorder_quantity : "",
              expiry_alert_period : DrugData?.expiry_alert_period ? Expiry_period.find((item)=>( +item?.value === DrugData?.expiry_alert_period)) : "",
              // sales_discount : DrugData?.sales_discount ? DrugData?.sales_discount : "",
              recommended_by : DrugData?.recommended_by ? DrugData?.recommended_by : "",
              group : DrugData?.group ? DrugData?.group : "",
              schedule : DrugData?.schedule ? DrugData?.schedule : "",
              bar_code : DrugData?.bar_code ? DrugData?.bar_code : "",
            }, ()=>{
              this.getManufacturerList();
              this.getDoctorsList();
            });
          }
        })
        .catch(error => {
          if (error.response.data.status === "fail") {
            this.errorMessage(error.response?.data?.message);
          }
        })
    } catch (e) {
      this.errorMessage(e.message);
    }
  }

  getManufacturerList = () => {
    try {
        RestAPIService.getAll(`${Serviceurls.PURCHASE_MANUFACTURER_GET}?manufacturer_name=`)
            .then(response => {
                // let data = response?.data;
                if (response.data.status === "success") {
                    this.setState({
                         ManufacturerList: response?.data?.data ? response?.data?.data : []
                    })
                }
            })
            .catch(error => {
                if (error.response?.data.status === "fail") {
                    this.errorMessage(error?.response?.data?.message);
                } else {
                    this.errorMessage(error?.message);
                }
            });
    } catch (e) {
        this.errorMessage(e?.message);
    }
}

getDoctorsList() {
  try {
    RestAPIService.getAll(Serviceurls.PHARMA_DOCTOR_LIST)
      .then((response) => {
        if (response.data.status === "success") {
          let DocList = []
          response.data?.data.forEach((item) => {
            item.label = `${item?.name} | ${item?.doctor_reg_no}`
            DocList.push(item)
          })
          this.setState({
            DoctorsList: response.data?.data,
            recommended_by : this.state?.recommended_by ? response.data?.data?.find((item)=> item?.name === this.state?.recommended_by) : this.state?.recommended_by
          })
        }
        else {
          this.errorMessage(response.data?.message)
        }
      }).catch((error) => {
        this.errorMessage(error?.message)
      })
  }
  catch (e) {
    this.errorMessage(e?.message)
  }
}

  handleBulkNo = () => {
    this.setState({
      checkboxSelection: [],
      isBulkInActive: false
    })
  }

  handleinActiveNo = () => {
    this.setState({
      inActiveId: null,
      isInActive: false,
      inActiveDrug: ""
    })
  }

  handleEdit = (data) => {
    // this.setState({
    //   selectedDrug: data,
    //   drug_id: data?.id,
    //   brand_name: data?.drug_name,
    //   generic_name: data?.generic_name,
    //   dosage_type: data?.dosage_type,
    //   dosage_strength: data?.drug_strength,
    //   drug_value: +data?.overall_amount?.toFixed(2),
    //   hsn_code: data?.hsn_code,
    //   gst_percentage: data?.gst_percentage,
    //   EditPopup: true
    // })
    
    this.setState({
       isDrugisBilled : data?.is_billed
    }, ()=>{ this.getEditDatas(data?.id) })
  }

  handleSave = () => {
    try {
      let states = this.state
      if(states.generic_name === ""){
        this.errorMessage("Select Brand Name")
        this.setState({disableBtn : false})
      }else if(states.generic_name === null){
        this.errorMessage("Select Generic Name")
        this.setState({disableBtn : false})
      }else if(states.dosage_type === null){
        this.errorMessage("Select Dosage Type")
        this.setState({disableBtn : false})
      }else if(states.drug_strength === ''){
        this.errorMessage("Enter Dosage Strength")
        this.setState({disableBtn : false})
      }else if(states.gst_percentage === ''){
        this.errorMessage("Enter GST %")
        this.setState({disableBtn : false})
      }else{
        // let data = {
        //   "id": states.drug_id,
        //   "generic_name": states.generic_name.generic_name ? states.generic_name.generic_name : states.selectedDrug.generic_name,
        //   "dosage_type": states.dosage_type,
        //   "drug_strength": states.dosage_strength
        // }
        this.setState({ disableBtn: true })

        let GenericName = this.state?.generic_name

            let isGeneric = GenericName ? typeof (GenericName) === "object" : false;
            if(isGeneric === false){
               if(this.state.genericName_list.some((list)=>(list?.generic_name.toLowerCase() === GenericName.toLowerCase()))){
                isGeneric = true
                GenericName = this.state.genericName_list.find((list)=>(list?.generic_name.toLowerCase() === GenericName.toLowerCase()))
               }
            }

        let DrugData = this.state.EditDrugData
          DrugData.drug_name = this.state?.brand_name ? this.state?.brand_name : ""
          DrugData.generic_name = isGeneric ? GenericName.generic_name?.trim() : GenericName ? GenericName.trim() : ""
          DrugData.generic_ms_id =  isGeneric ? GenericName.id : null
          DrugData.dosagetype_id = this.state?.dosage_type ? this.state?.dosage_type?.dosagetype_id : ""
          DrugData.drug_strength = this.state?.drug_strength ? this.state?.drug_strength : ""
          DrugData.physical_box_no = this.state?.physical_box_no ? this.state?.physical_box_no : ""
          DrugData.hsn_code = this.state?.hsn_code ? this.state?.hsn_code : ""
          DrugData.manufacturer_name = typeof(this.state?.manufacturer_name) === 'object' ? this.state?.manufacturer_name?.manufacturer_name : (this.state?.manufacturer_name || "")
          DrugData.gst_percentage = this.state?.gst_percentage?.value ? +this.state?.gst_percentage?.value : 0
          DrugData.reorder_threshold = this.state?.reorder_threshold ? +this.state?.reorder_threshold : 0
          DrugData.reorder_quantity = this.state?.reorder_quantity ? +this.state?.reorder_quantity : 0
          DrugData.expiry_alert_period = this.state?.expiry_alert_period ? +this.state?.expiry_alert_period?.value : 0
          // DrugData.sales_discount = this.state?.sales_discount ? +this.state?.sales_discount : 0
          DrugData.recommended_by = this.state?.recommended_by ? this.state?.recommended_by?.name : ""
          DrugData.group = DrugData?.group ? DrugData?.group : ""
          DrugData.schedule =  this.state?.schedule ?  this.state?.schedule : ""
          DrugData.bar_code =  this.state?.bar_code ?  this.state?.bar_code : ""
          delete DrugData.genericss

          let data = {
            "drug_data" : DrugData
          }

          RestAPIService.create(data, Serviceurls.PHARMA_INVENTORY_EDIT_DRUG_POST)
            .then((response) => {
            if (response.data?.status === "success") {
              this.setState({ disableBtn: false, EditPopup: false })
              this.successMessage(response.data?.message)
              this.getActiveInventoryList()
            }
          }).catch((error) => {
            if (error?.response?.data?.status === "fail") {
              this.setState({disableBtn : false})
              this.errorMessage(error?.response?.data?.message)
            }else{
              this.errorMessage(error?.message)
              this.setState({disableBtn : false})
            }
            this.setState({ disableBtn: false })
          })
      }
    } catch (e) {
      this.errorMessage(e?.message)
      this.setState({ disableBtn: false })
    }
  }

  handleClear = () => {
    let { selectedDrug } = this.state
    let DrugData = this.state.EditDrugData
    this.setState({
      brand_name: DrugData?.drug_name ? DrugData?.drug_name : "",
      generic_name: DrugData?.generic_name ? DrugData?.generic_name : "",
      dosage_type: DrugData?.dosagetype_id ? this.state.dosageType_list.find((item)=> item?.dosagetype_id === DrugData?.dosagetype_id) : "",
      drug_strength: DrugData?.drug_strength ? DrugData?.drug_strength : "",
      physical_box_no : DrugData?.physical_box_no ? DrugData?.physical_box_no : "",
      hsn_code: DrugData?.hsn_code ? DrugData?.hsn_code : "",
      manufacturer_name : DrugData?.manufacturer_name ? DrugData?.manufacturer_name : "",
      gst_percentage: DrugData?.gst_percentage ? gstPercentage.find((item) => +item?.value === DrugData?.gst_percentage) : "",
      reorder_threshold : DrugData?.reorder_threshold ? DrugData?.reorder_threshold : "",
      reorder_quantity : DrugData?.reorder_quantity ? DrugData?.reorder_quantity : "",
      expiry_alert_period : DrugData?.expiry_alert_period ? Expiry_period.find((item)=>( +item?.value === DrugData?.expiry_alert_period)) : "",
      // sales_discount : DrugData?.sales_discount ? DrugData?.sales_discount : "",
      recommended_by : DrugData?.recommended_by ? DrugData?.recommended_by : "",
      group : DrugData?.group ? DrugData?.group : "",
      schedule : DrugData?.schedule ? DrugData?.schedule : "",
      bar_code : DrugData?.bar_code ? DrugData?.bar_code : "",
    });

  }

  renderTextBox = (label, statekey, disable = false, mandatory = false) => {
    const { t } = this.props;
    let states = this.state;
    if (mandatory && !states.mandatoryFields.includes(statekey)) {
      states.mandatoryFields = [...states.mandatoryFields, statekey];
    }
    return (
      <div className="eMed_pharmadrctpo_txt">
        <TextField
          className={'eMed_pharmaUpdatePop_txtBox'}
          label={t(label)}
          required={mandatory}
          disabled={disable}
          size="small"
          autoComplete="off"
          name={statekey}
          inputProps={{ emed_tid: label }}
          value={states[statekey] ? states[statekey] : (label === "GST%" || label === "Drug Value") ? "0" : ""}
          onChange={(e) => {
            if (statekey === "gst_percentage") {
              let isDecimalNum = CommonValidation.DecimalNumber(e.target.value)
              if (e.target.value === "" || isDecimalNum && e.target.value > 0 && e.target.value <= 100) {
                states[statekey] = e.target.value;
              }
            } else if (statekey === "reorder_quantity" || statekey === "reorder_threshold") {
              let isDecimalNum = CommonValidation.numberOnly(e.target.value)
              if (e.target.value === "" || isDecimalNum && e.target.value > 0) {
                states[statekey] = e.target.value;
              }
            } else {
              states[statekey] = e.target.value;
            }
            this.setState({
              states,
              disableBtn: !this.checkMandatoryFields()
            })
          }}
        />
        </div>
    );
}

renderAutoSlt = (label, stateKey, showkey, Data = [], disable = false, mandatory = false) => {
    const { t } = this.props;
    let states = this.state;
    if (mandatory && !states.mandatoryFields.includes(stateKey)) {
      states.mandatoryFields = [...states.mandatoryFields, stateKey];
    }
    return (
        <div className="eMed_pharmadrctpo_txt">
            <Autocomplete
                className= {'eMed_pharmaUpdatePop_txtBox'}
                size='small'
                clearIcon={false}
                options={Data}
                disabled={disable}
                freeSolo={(stateKey === "recommended_by" || stateKey === "manufacturer_name")}
                getOptionLabel={(option) => (typeof (option[showkey]) === "string" ? option[showkey] :  option?.manufacturer_name ? option?.manufacturer_name : option?.name ? option?.name : option)}
                value={states[stateKey] ? states[stateKey] : ""}
                ListboxProps={{ style: { maxHeight: 300 } }}
                onChange={(event, newValue) => {
                    states[stateKey] = newValue;
                    this.setState({ 
                      states,
                      disableBtn: !this.checkMandatoryFields()
                     })
                }}
                onInputChange={(event, newvalue) => {
                  if (stateKey === "generic_name") {
                    if (newvalue) {
                      this.getGenericNameList(newvalue);
                    } else {
                      states["genericName_list"] = []
                    }
                    states[stateKey] = newvalue
                    this.setState({
                      states,
                      disableBtn: !this.checkMandatoryFields()
                    });
                  } else if (stateKey === "manufacturer_name") {
                      states[stateKey] = newvalue
                      this.setState({ 
                          states,
                          disableBtn: !this.checkMandatoryFields()
                       });
                  }
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        required={mandatory}
                        label={t(label)}
                        inputProps={{...params.inputProps, emed_tid:label}}
                        size="small"
                        autoComplete="off"
                    />
                )}
            />
        </div>
    )
}

  closeEditDrug = () => {
    this.setState({ 
      genericName_list: [],
      brand_name: '',
      generic_name: '',
      dosage_type: '',
      dosage_strength: '',
      drug_value: null,
      hsn_code: '',
      gst_percentage: '',
      selectedDrug: {},
      EditPopup: false 
    })
  }

  renderEditDrug = () => {
    const { t } = this.props
    let states = this.state
    return (
      <Box className={"eMed_pharmaUpdatePop_Con"}>
        <Box component={'div'}>
          <Box className={"eMed_pharmaUpdatePop_Con"}>
            <Box
              className='eMed_pharmaAdd_subDiv'
            >
              <Box component={'div'} display={'flex'} flexWrap={'wrap'} >
                {this.renderTextBox("BrandName", "brand_name", this.state.isDrugisBilled, true)}
                {this.renderAutoSlt("GenericName", "generic_name", "generic_name", states.genericName_list, this.state.isDrugisBilled, true)}
                {this.renderAutoSlt("DosageType", "dosage_type", "dosage_type", states.dosageType_list, this.state.isDrugisBilled, true)}
                {this.renderTextBox("DosageStrength", "drug_strength", this.state.isDrugisBilled, true)}
                {this.renderTextBox("Phy Box No", "physical_box_no", this.state.isDrugisBilled)}
              </Box>
              <Box component={'div'} display={'flex'} flexWrap={'wrap'} marginTop={"1vw"}>
                {this.renderTextBox("HSNCode", "hsn_code", this.state.isDrugisBilled, true)}
                {this.renderAutoSlt("Manufauturer Name", "manufacturer_name", "manufacturer_name", states.ManufacturerList, false, true)}
                {/* {this.renderTextBox("GST%", "gst_percentage", false, true)} */}
                {this.renderAutoSlt("GST%", "gst_percentage", "label", gstPercentage, true)}
                {this.renderTextBox("Reorder Threshold", "reorder_threshold", false)}
                {this.renderTextBox("Reorder Quantity (ROQ)", "reorder_quantity", false)}
              </Box>
              <Box component={'div'} display={'flex'} flexWrap={'wrap'} marginTop={"1vw"}>
                {this.renderAutoSlt("Expiry Alert Period", "expiry_alert_period", "label", Expiry_period, false, false)}
                {/* {this.renderTextBox("Sales Discount", "sales_discount", false)} */}
              </Box>
              <Divider textAlign='left' sx={{ marginLeft: '0.5vw', marginTop: '1vw' }}>
                <Typography component={'p'} className='eMed_Service_title'>Additional Charges</Typography></Divider>
              <Box component={'div'} display={'flex'} flexWrap={'wrap'} marginTop={"1vw"}>
                {this.renderAutoSlt("RecommendedBy", "recommended_by", "label", this.state.DoctorsList, false)}
                {this.renderTextBox("Group", "group", false)}
                {this.renderTextBox("Schedule", "schedule", false)}
                {this.renderTextBox("UPC / EAN", "bar_code", false)}
              </Box>
            </Box>
            <Stack id={"eMed_pharmaUpdatePop_btnCont"} direction="row" alignItems="center" justifyContent={"flex-end"} spacing={3}>
              <Button emed_tid='nd_clear' variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.handleClear() }}>{t("Clear")}</Button>
              <Button emed_tid='nd_save' variant='contained' size="small" id="eMed_Config_btn" disabled={this.state.disableBtn} onClick={() => { this.setState({ disableBtn: true }, () => { this.handleSave() }) }}>{t("Update")}</Button>
            </Stack>
          </Box>
        </Box>
      </Box>
    )
  }

  // filter start
  closeFilter = () => {
    this.setState({ FilterOpen: false })
  }

  checkMandatoryFields = () => {
    const { mandatoryFields } = this.state;
    return mandatoryFields.every((field) => !!this.state[field]);
};

  renderFilterScreen = () => {
    const { t } = this.props;
    let states = this.state;
    return (
      <Box component={"div"} className='eMed_rts_filter_container'>
        <Box component={"div"} className='eMed_filter_Top'>
          <Typography variant='h6' fontWeight={600}>{t('Filter')}</Typography>
          <Button emed_tid='dt_close' onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
          </Button>
        </Box>
        <Box className='eMed_rts_middle'>
          <Box className='eMed_rts_dropdown'>
            <TextField
              label={t("BrandName")}
              sx={{ width: "20vw" }}
              size="small"
              inputProps={{emed_tid:'BrandName'}}
              autoComplete="off"
              value={states.searchkey ? states.searchkey : ""}
              onChange={(e) => {
                this.setState({
                  searchkey: e.target.value
                })
              }}
            />
          </Box>
        </Box>
        <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
          <Button emed_tid='fil_clear' variant='outlined' size="small"
            onClick={() => {
              this.setState({
                searchkey: "",
              }, () => this.getActiveInventoryList())
            }}
            id="eMed_Config_btn">{t("Clear")}</Button>
          <Button emed_tid='fil_search' variant='contained' size="small"
            onClick={() => {
              this.getActiveInventoryList()
              this.setState({ FilterOpen: false })
            }}
            id="eMed_Config_btn">{t("Search")}</Button>
        </Stack>
      </Box>
    )
  }
  // filter end

  gridToolBar = () => {
    const {t} = this.props;
    // let TextArray = [
    //   { label: "From Date", value: formatDate(this.state.FilterDatas.FromDate) },
    //   { label: "To Date", value: formatDate(this.state.FilterDatas.ToDate) }
    // ]
    return (
      <Box>
        <CommonGridToolBarWithFilterText searchHolder = {t("searchBrandName")}/>
      </Box>
    )
  }

  onFilterChange = (filterModel) => {
    if(filterModel?.quickFilterValues[0]?.length >= 2 || filterModel?.quickFilterValues?.length === 0 ){
      this.setState({
        searchkey: filterModel?.quickFilterValues[0] ? filterModel?.quickFilterValues.join() : ""
      }, () =>  {
        this.getActiveInventoryList();
      })
    }
  }

  render() {
    this.state.activeInventoryList.forEach((element, index) => { 
      let page = this.state.page 
      let pageSize = this.state.pageSize
      let serial = (page * pageSize) + (index + 1) 
      element.serial = serial
     })
     
    let states = this.state;
    // let isAdmin = states.loggedInuserInfo?.is_branch_admin || states.loggedInuserInfo?.is_super_admin;
    const { t } = this.props
    const columns = [
      { field: 'serial', headerName: t('SNo'), sortable: false, flex: 0.05 },
      { field: 'id', headerName: t("Drug ID"), sortable: false, flex: 0.120, headerAlign: "center", align: "center" },
      {
        field: 'drug_name', headerName: t('BrandName'), sortable: false, flex: 0.145,
        renderCell: (params) => (<div>{params?.row?.drug_name?.length > 14 ? <Tooltip placement="top" title={params?.row?.drug_name} arrow><div>{params?.row?.drug_name.slice(0, 10) + "..."}</div></Tooltip> : params?.row?.drug_name ? params?.row?.drug_name : "-"}</div>)
      },
      {
        field: 'generic_name', headerName: t('GenericName'), sortable: false, flex: 0.145,
        renderCell: (params) => (<div>{params?.row?.generic_name?.length > 14 ? <Tooltip placement="top" title={params?.row?.generic_name} arrow><div>{params?.row?.generic_name.slice(0, 10) + "..."}</div></Tooltip> : params?.row?.generic_name ? params?.row?.generic_name : "-"}</div>)
      },
      {
        field: 'dosage_type', headerName: t('DosageType'), sortable: false, flex: 0.130,
        renderCell: (params) => <div>{params?.row?.dosage_type?.length > 14 ? <Tooltip placement="top" title={params?.row?.dosage_type} arrow><div>{params?.row?.dosage_type.slice(0, 10) + "..."}</div></Tooltip> : params?.row?.dosage_type ? params?.row?.dosage_type : "-"}</div>
      },
      {
        field: 'drug_strength', headerName: t('DosageStrength'), sortable: false, flex: 0.125, headerAlign: "center", align: "center",
        renderCell: (params) => <div>{params?.row?.drug_strength?.length > 14 ? <Tooltip placement="top" title={params?.row?.drug_strength} arrow><div>{params?.row?.drug_strength.slice(0, 10) + "..."}</div></Tooltip> : params?.row?.drug_strength ? params?.row?.drug_strength : "-"}</div>
      },
      {
        field: 'stock_in_quantity', headerName: t('AvailableQuantity'), type: "number", sortable: false, flex: 0.125,
        renderCell: (params) => <div>{params?.row?.stock_in_quantity?.length > 14 ? <Tooltip placement="top" title={params?.row?.stock_in_quantity} arrow><div>{params?.row?.stock_in_quantity.slice(0, 10) + "..."}</div></Tooltip> : params?.row?.stock_in_quantity ? params?.row?.stock_in_quantity : "0"}</div>
      },
      {
        field: 'overall_amount', headerName: t(`Drug Value (${CurrencySymbol})`), type: "number", sortable: false, flex: 0.125,
        renderCell: (params) => <div>{AmountFormat(params?.row?.overall_amount || 0)?.replace(`${CurrencySymbol}`, "")}</div>
      },
      {
        field: 'hsn_code', headerName: t(`HSN Code`), type: "number", sortable: false, flex: 0.125,
        renderCell: (params) => (<div>{params?.row?.hsn_code?.length > 11 ? <Tooltip placement="top" title={params?.row?.hsn_code} arrow><div>{params?.row?.hsn_code.slice(0, 11) + "..."}</div></Tooltip> : params?.row?.hsn_code ? params?.row?.hsn_code : "-"}</div>)
      },
      {
        field: 'gst_percentage', headerName: t(`GST%`), headerAlign: "center", align: "center", sortable: false, flex: 0.125,
        renderCell: (params) => <div>{params?.row?.gst_percentage ? params?.row?.gst_percentage : "0"}</div>
      },
    ]

    if (userAccess?.editAccess) {
      columns.push(
        {
          field: 'action', headerName: t("Action"), type: 'actions', sortable: false, flex: 0.20, headerAlign: "center", align: "center",
          renderCell: ({ row }) => (
            <Box>
              <Button emed_tid='dl_tbl_inactive' size='small' variant="contained" className="checkbox" disabled={this.state.checkboxSelection.length > 0 ? true : false} onClick={() => this.setState({ isInActive: true, inActiveId: row.id, inActiveDrug: row.drug_name })}>{("InActive")}</Button>
              <Tooltip title={t("Edit Durg")} placement="top" arrow>
                <Button emed_tid="pharma_drugList_edit_dug" className='eMed_usrconf_btn' size='small' onClick={() => {this.handleEdit(row)}}>
                  <img src={ImagePaths.LabEditButton.default} alt="edit" className='eMed_action_img' />
                </Button>
              </Tooltip>
            </Box>
        )
        }
      );
    }
    return (
      <div className='active_tab_container'>
        <div className='eMed_Purchase_Table_top'>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', paddingY: '0.5vw', paddingLeft: "1vw" }}>
            <FormControl sx={{ marginLeft: "1.5vw", display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <RadioGroup
                value={this.state.showZeroStocks}
                sx={{ display: 'flex', flexDirection: 'row', marginLeft: '1vw' }}
                onChange={(e) => {
                  this.setState({ showZeroStocks: e.target.value }, () => {
                    this.getActiveInventoryList()
                  })
                }}
                emed_tid='zero_stock_filter'
              >
                <FormControlLabel value={""} control={<Radio size='small' />} label="All" />
                <FormControlLabel value={"false"} control={<Radio size='small' />} label="With Stock" />
                <FormControlLabel value={"true"} control={<Radio size='small' />} label="Zero Stock" />
              </RadioGroup>
            </FormControl>
          </Box>
          {userAccess?.editAccess ? <><Button emed_tid='new_drug' className='eMed_Filter_Btns' variant='contained' size='small'
            onClick={() => { this.props.history.push({ pathname: "/PharmacyInventory/StockList/AddNewDrug" }) }}
          >{t(`Add NewDrug`)}</Button>
            <Divider orientation='vertical' /></> : null}
          {userAccess?.editAccess ? <>
            <Button emed_tid='dl_inactive' className='eMed_Filter_Btns' variant='contained' size='small'
              disabled={this.state.checkboxSelection.length === 0 ? true : false} onClick={() => {this.setState({isBulkInActive: true})}}
          >{`InActive (${this.state.count})`}</Button>
          <Divider orientation='vertical' />
          </>
           : null}
          {/* <Tooltip title="Filter" placement='top' arrow>
            <Button emed_tid='dl_filter' className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
              <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
            </Button>
          </Tooltip> */}
          {/* <Tooltip title="Print" placement='top' arrow>
            <Button emed_tid='dl_print' className='eMed_usrconf_btn'>
              <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
            </Button></Tooltip> */}
          <Tooltip title="Export" placement='top' arrow>
            <Button emed_tid='dl_export' className='eMed_usrconf_btn' disabled={this.state.disableBtn} onClick = {() => this.getDrugListExcel()}>
              <img src={ImagePaths.LabUploadButton.default} alt="upload" className='eMed_action_img' />
            </Button></Tooltip>
        </div>
        <div className='active_tab_table_container'>
          <DataGrid
            checkboxSelection={userAccess?.editAccess ? true : false}
            selectionModel={states.checkboxSelection}
            onSelectionModelChange={(checkbox) => {
              this.setState({
                checkboxSelection: checkbox,
                count: checkbox.length,
                activeStatus: false
              })
            }}
            rows={this.state.activeInventoryList}
            rowCount={this.state.total}
            page={this.state.page}
            pageSize={this.state.pageSize}
            columns={columns}
            hideFooterSelectedRowCount
            getRowId={(row) => row?.id}
            onPageChange={(newPage) => { this.setState({ page: newPage }, () => this.getActiveInventoryList()) }}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize }, () => this.getActiveInventoryList())}
            rowsPerPageOptions={[10, 20, 30]}
            pagination
            paginationMode='server'
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            components={{
              Toolbar: this.gridToolBar,
              NoRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
            filterMode="server"
            onFilterModelChange={this.onFilterChange}
          />
        </div>
        {
          this.state.isBulkInActive ? <ReasonPopup
          title={`Are you sure? Do you want to InActive the Drug ?`}
          AlertPopupClose={this.handleinActiveNo.bind(this)}
          label={"Enter the Reason"}
          btntext={'Cancel'}
          btnvarient={'outlined'}
          btncolor={'error'}
          btntext1={'Confirm'}
          btnvarient1={'contained'}
          sendCmt={this.groupinActivePost.bind(this)} /> : null}
        {
          this.state.isInActive ?
          <ReasonPopup
          title={`Are you sure? Do you want to InActive the "${this.state.inActiveDrug ? this.state.inActiveDrug : "-"}" ?`}
          AlertPopupClose={this.handleinActiveNo.bind(this)}
          label={"Enter the Reason"}
          btntext={'Cancel'}
          btnvarient={'outlined'}
          btncolor={'error'}
          btntext1={'Confirm'}
          btnvarient1={'contained'}
          sendCmt={this.inActivePost.bind(this)} /> : null}
        <Drawer
          anchor={'right'}
          open={this.state.FilterOpen}
          ModalProps={{ onBackdropClick: this.closeFilter }}
        >
          {this.renderFilterScreen()}
        </Drawer>
        <Loader loaderOpen={this.state.isLoader} />
        {
          this.state.EditPopup ?
            <CommonPopUp
              width={"80vw"}
              height={"62vh"}
              testID={"uptDrug"}
              popUpClose={this.closeEditDrug.bind(this)}
              title={t("Edit Drug")}
              component={this.renderEditDrug.bind(this)} />
            : null
        }
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : this.state.successMsg ?
            <ToastMsg
              severity={'success'}
              msg={this.state.successMsgText}
              msgPop={this.msgClose.bind(this)}
            /> : null
        }
      </div>
    )
  }
}

export default withTranslation()(PharmaDrugList)