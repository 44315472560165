import React, { Component } from 'react';
import "./charts.css";
import { Box, Typography, Paper, IconButton } from '@mui/material';
import { withTranslation } from 'react-i18next';
import { Line } from 'react-chartjs-2';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { AmountFormat } from '../../../Components/CommonFunctions/CommonFunctions';
import ExpandPopUp from './ExpandPopUp';
import { CurrencySymbol } from '../../../Utility/Constants';

class LineChartView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chartItem: props,
            chartData: props.chartData?.length > 0 ? props.chartData : [], // array
            chartLabel: props.chartLabel?.length > 0 ? props.chartLabel : [], 
            isExpandClked: false,
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props && this.props) {
            this.setState({
                chartItem: this.props,
                chartData: this.props.chartData?.length > 0 ? this.props.chartData : [],
                chartLabel: this.props.chartLabel?.length > 0 ? this.props.chartLabel : [],
            })
        }
    }

    PopupClose = () => {
        this.setState({
            isExpandClked: false
        })
    }

    renderScreen = () => {
        if(this.props.isFrom === "branches"){
            this.props?.renderNextScreen("pieChart",this.props?.branchId, this.state.chartItem?.title, this.state.chartItem?.url, this.state.chartItem?.tableName, this.state.chartItem?.subTitle )
        }
    }

    render() {
        const {t} = this.props;
        let { chartItem, chartData, chartLabel } = this.state;
        let chart_data = [], chart_label = [], firstFiveValue = [], remainingValue = 0;
        let chartFont = 'Open Sans';

        
        // Extracting first five value
        let list = chartData?.length > 0 ? chartData : [];
        firstFiveValue = list?.length > 0 ? list?.slice(0, 5) : [];
        if (chartLabel?.length > 0) {
            chart_label = chartLabel?.length > 0 ? chartLabel?.slice(0, 5) : [];
        }

        // Extracting the respected values and labels to the corresponding list
        if(chartData?.length > 0 && firstFiveValue?.length > 0){
            chart_data = [...firstFiveValue]
        }

        // Adding sixth labels and values to the corresponding list
        if(chartData?.length >= 6 && chartLabel?.length >= 6){
            remainingValue = chartData.slice(5).reduce((acc, cur) => acc + cur, 0);
            chart_data.push(remainingValue);
            chart_label.push("Others");
        }
        
        const options = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false
                },
                title: {
                    display: false
                },
                tooltip: {
                    callbacks: {
                        label: function(context) {
                          return context.dataset.label + ': ' + context.parsed.y;
                        }
                      },
                    titleFont: {
                        family: chartFont,
                      },
                      bodyFont: {
                        family: chartFont,
                      },
                }
            },
            scales: {
                x: {
                    grid: {
                        color: 'rgba(0, 0, 0, 0)',
                        drawBorder: false,
                    },
                    ticks: {
                        align: 'center',
                        font: {
                            weight: 'bold',
                            family: chartFont,
                            size : 9
                        },
                        callback: function(value){
                            let label = value === 0 || value ? this.getLabelForValue(value) : "";
                            return label?.length > 10 ? label?.substring(0, 8) + '...' : label;
                        },
                    }
                },
                y: {
                    // min: 0,
                    // max: 100,
                    // stepSize: 10,
                    ticks: {
                        align: 'center',
                        font: {
                            weight: 'bold',
                            family: chartFont
                        },
                    }
                },
            },
        };

        const data = {
            labels: chart_label,
            datasets: [
                {
                    label: '',
                    data: chart_data,
                    borderColor: "#04B7B1",
                    backgroundColor: "#04B7B1",
                    cutout: "65%"
                },
            ],
        };

        // customized labels
        let pluginData = [];
        let allow = ( chartData?.length > 0);
       
        return (
            <Paper className='eMed_chart_wrapper' elevation={2} sx={{height: '20vw'}}>
                <Box className="eMed_revenueTbl_titleDiv">
                    <Typography id="eMed_chart_headTxt">{chartItem.title ? chartItem.title : ""}</Typography>
                    { allow ? <IconButton size='small' onClick={() => {this.setState({isExpandClked: true})}}>
                        <img className='emedhub_expand_img' src={ImagePaths.ExpandView.default} alt = "Expand"/>
                    </IconButton> : null} 
                </Box>
                <Box className="eMed_Line_chartDiv" height={'16vw'} sx={{cursor: this.props.isFrom === 'branches' ? "pointer" : "default"}}>
                    {allow ? <Line options={options} data={data} onClick={()=>{this.renderScreen()}}/>
                        : <Box className="eMed_noDataDiv">
                            <p id="eMed_revenueCard_Txt">{t("NoRecordsFound")}</p>
                        </Box>}
                </Box>
                {
                    this.state.isExpandClked ? 
                    <ExpandPopUp 
                    hideview = {true}
                    title = {chartItem.title}
                    tblColumn = {chartItem.tblColumn}
                    chartView = {<Line options={options} data={data} plugins={pluginData}/>}
                    popUpClose = {this.PopupClose.bind(this)}
                    /> : null
                }
            </Paper>
        )
    }
}
export default withTranslation()(LineChartView);